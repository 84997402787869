import { CheckIcon, WarningTwoIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { useCallback, useContext, useState } from 'react';

import MenuHeader from 'components/MenuHeader/MenuHeader';
import OpenDetailsModalButton from 'components/OpenDetailsModalButton/OpenDetailsModalButton';
import SearchInput from 'components/SearchInput/SearchInput';
import { SelectItem } from 'components/SelectMenu/SelectMenu';
import { DatabaseTableContext } from 'config/databaseTableContext';
import { extractEmoji } from 'helpers/emoji';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useListSearch } from 'hooks/useListSearch';
import {
  autofillDimensionalProperty,
  clearAutofillForDimensionalProperty,
} from 'reduxStore/actions/businessObjectSpecMutations';
import {
  LookupOption,
  lookupDimensionOptionsByLookupSpecIdForBlockSelector,
} from 'selectors/autofillDimensionalPropertySelector';
import LinkIcon from 'vectors/Link';

type LookupItem = SelectItem & LookupOption;

interface LookupSubMenuProps {
  onClose: () => void;
  onBack: () => void;
  dimensionalPropertyId?: string;
}

const formatEmojiLabel = (label: string, color: string, isAttrCol: boolean) => {
  const [emoji, name] = extractEmoji(label);

  const textEl = (
    <Text
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      // maxW="12rem"
      fontWeight="medium"
      color={color}
    >
      {name}
    </Text>
  );
  if (emoji == null && !isAttrCol) {
    return textEl;
  }
  return (
    <Flex alignItems="center" gap={1}>
      <Box>{isAttrCol ? <LinkIcon color="gray.500" /> : emoji}</Box>
      {textEl}
    </Flex>
  );
};
const LOOKUP_SEARCH_KEYS = [
  'lookup_result_dimension_name',
  'lookup_spec_name',
  'search_property_name',
];
const LABELS = ['Insert values of', 'from', 'Using Lookup'];

const LookupSubMenu = ({ onClose, onBack, dimensionalPropertyId }: LookupSubMenuProps) => {
  const [query, setQuery] = useState<string>('');

  const { blockId } = useBlockContext();

  const lookupOptions = useAppSelector((state) =>
    lookupDimensionOptionsByLookupSpecIdForBlockSelector(state, { blockId, dimensionalPropertyId }),
  );

  const dispatch = useAppDispatch();
  const { objectSpecId } = useContext(DatabaseTableContext);

  const filteredOptions: LookupItem[] = useListSearch({
    query,
    items: lookupOptions,
    searchKeys: LOOKUP_SEARCH_KEYS,
  });

  const onSelect = useCallback(
    (item: LookupItem) => {
      if (item == null) {
        return;
      }

      const shouldClearAutofill = item?.selected && dimensionalPropertyId != null;
      if (shouldClearAutofill) {
        dispatch(
          clearAutofillForDimensionalProperty({
            objectSpecId,
            propertyId: dimensionalPropertyId,
          }),
        );
        onClose();
        return;
      }

      // ensure we clear then re-add the lookup when swapping
      // note: there is a existing bug where the swap does not regenerate the values until you revisit the page
      const shouldSwapAutoFillProperty = item.selected === false && dimensionalPropertyId != null;
      if (shouldSwapAutoFillProperty) {
        dispatch(
          clearAutofillForDimensionalProperty(
            {
              objectSpecId,
              propertyId: dimensionalPropertyId,
            },
            () => {
              if (item.lookup_result_property_id == null || item.lookup_spec_id == null) {
                return;
              }

              dispatch(
                autofillDimensionalProperty({
                  objectSpecId,
                  // 'propertyId' refers to the dimensional property that the lookup is being added to
                  // if null then it is a new property
                  propertyId: dimensionalPropertyId,
                  lookupSpecId: item.lookup_spec_id,
                  resultPropertyId: item.lookup_result_property_id,
                  searchDimensionPropertyId: item.search_property_id,
                }),
              );
            },
          ),
        );
        onClose();
        return;
      }

      if (
        item.lookup_spec_id == null ||
        item.search_property_id == null ||
        item.lookup_result_property_id == null
      ) {
        return;
      }

      // Add the lookup selected dimension as a property
      // creates and autofills the lookup
      dispatch(
        autofillDimensionalProperty({
          objectSpecId,
          lookupSpecId: item.lookup_spec_id,
          resultPropertyId: item.lookup_result_property_id,
          searchDimensionPropertyId: item.search_property_id,
        }),
      );
      onClose();
    },
    [dimensionalPropertyId, dispatch, objectSpecId, onClose],
  );

  return (
    <Box position="relative" overflow="hidden" py={1}>
      <MenuHeader title="Select a lookup" onClose={onClose} onBack={onBack} />
      <Flex flexDir="column" overflow="hidden" w="40rem">
        <Flex padding={2}>
          <SearchInput placeholder="Search by column name" query={query} setQuery={setQuery} />
        </Flex>
        <TableContainer overflowY="scroll" overflowX="scroll" height="234px" pb={1}>
          <Table variant="unstyled" w="full" pos="relative" fontSize="xs">
            <Thead position="sticky" zIndex="1" top="0" backgroundColor="white">
              <Tr>
                {/* for the hidden checkbox */}
                <Th p={0}>
                  <Flex
                    h="25px"
                    p={1}
                    pb={1}
                    mb={0}
                    ml={1}
                    borderBottom="1px solid"
                    borderColor="gray.300"
                  />
                </Th>
                {LABELS.map((label, index) => (
                  <Th key={index} p={0}>
                    <Text
                      fontSize="xxxs"
                      fontWeight={700}
                      color="gray.500"
                      textTransform="uppercase"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      letterSpacing="normal"
                      borderBottom="1px solid"
                      borderColor="gray.300"
                      p={1}
                      pb={1}
                      mb={0}
                    >
                      {label}
                    </Text>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody pt={32}>
              {filteredOptions.map((item) => {
                const hasError =
                  item.lookup_spec_id == null ||
                  item.search_property_id == null ||
                  item.lookup_result_property_id == null;

                return (
                  <Tr
                    key={item.id}
                    borderRadius="3px"
                    cursor="pointer"
                    px={1}
                    role="group"
                    _hover={{ backgroundColor: 'gray.100', cursor: 'pointer', borderRadius: '6px' }}
                    onClick={() => onSelect(item)}
                  >
                    <Td p={1} boxSize={2} ml={2}>
                      {item.selected && <CheckIcon ml={2} mr="-1px" boxSize={3} fill="gray.600" />}
                    </Td>
                    <Td p={1} pr={3} fontWeight="medium">
                      <Tooltip
                        isDisabled={!hasError}
                        label="Could not find the lookup for this column"
                      >
                        <Flex alignItems="center" gap="1">
                          {hasError && <WarningTwoIcon color="failure" />}
                          {item.lookup_result_dimension_name}
                        </Flex>
                      </Tooltip>
                    </Td>
                    <Td p={1} pr={3}>
                      {formatEmojiLabel(item.lookup_spec_name, 'gray.500', false)}
                    </Td>
                    <Td p={1} pr={3}>
                      <Flex justifyContent="space-between" alignItems="center">
                        {formatEmojiLabel(item.search_property_name, 'gray.500', false)}
                        {item.lookup_spec_id != null && (
                          <Box visibility="hidden" _groupHover={{ visibility: 'visible' }}>
                            <OpenDetailsModalButton
                              type="database"
                              id={item.lookup_spec_id}
                              _hover={{ backgroundColor: 'gray.300' }}
                            />
                          </Box>
                        )}
                      </Flex>
                    </Td>
                  </Tr>
                );
              })}
              {filteredOptions.length === 0 && (
                <Tr>
                  <Td colSpan={12}>
                    <Text color="gray.500" textAlign="center">
                      No results found
                    </Text>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </Box>
  );
};

export default LookupSubMenu;
