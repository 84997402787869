import { BorderState } from 'components/CellBorder/types';
import { CellRef } from 'config/cells';
import { DriverPropertyColumn } from 'config/modelView';
import { ComparisonColumn } from 'generated/graphql';
import { useIsActuals } from 'hooks/useIsActuals';
import { useResolvedBlockConfiguration } from 'hooks/useResolvedBlockConfiguration';

const COLUMN_COMPACT = 'column-compact';

type ComparisonColumnType =
  | ComparisonColumn.RowVersion
  | ComparisonColumn.Variance
  | ComparisonColumn.VariancePercentage;

interface Props {
  borderState: BorderState;
  blockId: string | undefined;
  cellRef: CellRef;
  driverPropertyColumn: DriverPropertyColumn | null | undefined;
  isRowHeader?: boolean;
  lastStickyColumnType?: string | null;
  isLastStickyColumn: boolean;
}
export const useComparisonBorderState = ({
  borderState,
  blockId,
  cellRef,
  driverPropertyColumn,
  isRowHeader,
  lastStickyColumnType,
  isLastStickyColumn,
}: Props): BorderState | null => {
  const {
    comparisonLayout,
    comparisonLayerIds,
    displayedComparisonColumns: allDisplayedComparisonTypes,
    displayedComparisonTimePeriods,
  } = useResolvedBlockConfiguration();

  const displayedComparisonColumns = allDisplayedComparisonTypes.filter(
    (col) => col !== ComparisonColumn.LatestVersion,
  );

  // comparison layer
  const rowLayerId = 'layerId' in cellRef.rowKey ? cellRef.rowKey.layerId : null;
  const columnLayerId = 'layerId' in cellRef.columnKey ? cellRef.columnKey?.layerId : null;
  const isComparingLayers = comparisonLayerIds.length > 0;

  // time period comparison
  const rowTimePeriod =
    'comparisonTimePeriod' in cellRef.rowKey ? cellRef.rowKey.comparisonTimePeriod : null;
  const isComparingTimePeriods = displayedComparisonTimePeriods.length > 0;

  // comparison layout
  const isComparing = comparisonLayout != null || isComparingTimePeriods;
  const isCompactColumnView = comparisonLayout === COLUMN_COMPACT;

  // Currently forecast cells have a different color border
  const monthKey = 'monthKey' in cellRef.columnKey ? cellRef.columnKey.monthKey : '';
  const layerId = columnLayerId ?? rowLayerId ?? '';
  const isActuals = useIsActuals(monthKey, layerId);

  if (!isComparing || blockId == null) {
    return null;
  }
  const isDriverPropertyColumn = driverPropertyColumn != null;
  const isDriverPropertyColumnHeaderSticky =
    isDriverPropertyColumn && driverPropertyColumn.type === lastStickyColumnType;

  const indexOfLastComparisonType = displayedComparisonColumns.length - 1;

  // BOTTOM BORDERS
  const rowComparisonType =
    'comparisonType' in cellRef.rowKey ? cellRef.rowKey.comparisonType : null;

  const isLastComparisonRow =
    rowComparisonType != null &&
    displayedComparisonColumns.indexOf(rowComparisonType as ComparisonColumnType) ===
      indexOfLastComparisonType &&
    ![ComparisonColumn.RowVersion, ComparisonColumn.LatestVersion].includes(rowComparisonType);

  const isLastLayerRow =
    isComparingLayers && comparisonLayerIds[comparisonLayerIds.length - 1] === rowLayerId;

  const isLastTimePeriodComparisonRow =
    isComparingTimePeriods &&
    displayedComparisonTimePeriods[displayedComparisonTimePeriods.length - 1] === rowTimePeriod;

  // RIGHT BORDERS
  const columnComparisonType =
    'comparisonColumn' in cellRef.columnKey ? cellRef.columnKey?.comparisonColumn : null;

  const isLastLayerColumn =
    isComparingLayers && comparisonLayerIds[comparisonLayerIds.length - 1] === columnLayerId;

  let isLastComparisonColumn =
    columnComparisonType != null &&
    displayedComparisonColumns.indexOf(columnComparisonType as ComparisonColumnType) ===
      indexOfLastComparisonType;
  if (isCompactColumnView && columnLayerId != null) {
    isLastComparisonColumn = isLastLayerColumn;
  }

  if (isRowHeader) {
    borderState = {
      ...borderState,
      hasRightBorder: isDriverPropertyColumn ? !isDriverPropertyColumnHeaderSticky : true,
      hasSolidBottomBorder: true,
    };
  } else if (isCompactColumnView) {
    borderState = {
      ...borderState,
      hasRightBorder: true,
      hasSolidRightBorder: isDriverPropertyColumn ? isLastStickyColumn : isLastComparisonColumn,
      isForecastCell: !isActuals,
    };
  } else if (isDriverPropertyColumn) {
    borderState = {
      ...borderState,
      hasRightBorder: true,
      hasSolidBottomBorder: isLastLayerRow || isLastComparisonRow || isLastTimePeriodComparisonRow,
    };
  } else {
    borderState = {
      ...borderState,
      hasSolidRightBorder: isLastComparisonColumn || isLastLayerColumn,
      hasSolidBottomBorder: isLastLayerRow || isLastComparisonRow || isLastTimePeriodComparisonRow,
      isForecastCell: !isActuals,
    };
  }

  return borderState;
};
