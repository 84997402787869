import { useContext, useMemo } from 'react';

import { CellRef, CellType, DriverCellRef, ExtDriverCellRef } from 'config/cells';
import { DriverRowContext } from 'config/driverRowContext';
import { ExtDriverColumnType } from 'config/extDriverTable';
import { ColumnLayerId, ModelViewColumnType, NAME_COLUMN_TYPE } from 'config/modelView';
import { DriverGroupId } from 'reduxStore/models/driverGroup';

type DriverCellRefProps = {
  columnType: ModelViewColumnType;
  // columnLayerId can be undefined in two scenarios:
  // 1. There are no comparison layers, so we use undefined to represent current layer
  // 2. A columnLayerId is not needed to uniquely identify a driver cell. An example
  //    of this is the driver "Name" column - we don't show multiple subcolumns for
  //    comparison layers, so there's only one column for that columnType.
  columnLayerId: ColumnLayerId;
  groupId?: DriverGroupId;
};

// Helper to create a unique reference for a driver cell.
// Cells are uniquely identified by their rowKey and columnKey.
// It gets Row info from the row context, and Column info passed in as props.
export default function useDriverCellRef({
  columnType,
  columnLayerId,
  groupId,
}: DriverCellRefProps) {
  const {
    driverId,
    comparisonRowLayerId,
    groupId: rowGroupId,
    comparisonType,
    comparisonTimePeriod,
  } = useContext(DriverRowContext);

  const cellRef = useMemo<DriverCellRef>(
    () => ({
      type: CellType.Driver,
      rowKey: {
        driverId,
        layerId: comparisonRowLayerId,
        groupId: groupId ?? rowGroupId,
        comparisonType,
        comparisonTimePeriod,
      },
      columnKey: { columnType, columnLayerId },
    }),
    [
      driverId,
      comparisonRowLayerId,
      groupId,
      rowGroupId,
      comparisonType,
      comparisonTimePeriod,
      columnType,
      columnLayerId,
    ],
  );

  return cellRef;
}

export function useAddDriverCellRef({ groupId }: { groupId?: DriverGroupId } = {}): CellRef {
  return useMemo(
    () => ({
      type: CellType.Driver,
      rowKey: {
        driverId: undefined,
        layerId: undefined,
        groupId,
        comparisonType: undefined,
      },
      columnKey: { columnType: NAME_COLUMN_TYPE, columnLayerId: undefined },
    }),
    [groupId],
  );
}

type ExtDriverCellRefProps = {
  extDriverId: string;
  columnType: ExtDriverColumnType;
};
export function useExtDriverCellRef({ extDriverId, columnType }: ExtDriverCellRefProps) {
  const cellRef = useMemo<ExtDriverCellRef>(
    () => ({
      type: CellType.ExtDriver,
      rowKey: { extDriverId },
      columnKey: { columnType, columnLayerId: undefined },
    }),
    [extDriverId, columnType],
  );

  return cellRef;
}
