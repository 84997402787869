import { createSelector } from '@reduxjs/toolkit';
import { GridApi, PropertyKeys } from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { fromPairs, isEmpty, keyBy, pickBy } from 'lodash';
import { createCachedSelector } from 're-reselect';

import deriveColumnDef, {
  ADD_COLUMN_TYPE,
  AddColumnKey,
  ColumnDefInputParams,
  createFormulaColumnDef,
  createInitialValueColumnDef,
  createNameColumnDef,
  createObjectGroupByColumnDef,
  createObjectIdColumnDef,
  createOptionsColumnDef,
  createPropertyColumnDef,
  createTimeseriesMonthKeyColumnDef,
} from 'components/AgGridComponents/helpers/deriveColumnDef';
import { DatabaseDataSource } from 'components/AgGridComponents/helpers/gridDatasource/DatabaseDataSource';
import { TimeseriesDataSource } from 'components/AgGridComponents/helpers/gridDatasource/TimeseriesDataSource';
import {
  DatabaseDataSourceUpdateStateParams,
  TimeseriesDataSourceUpdateStateParams,
} from 'components/AgGridComponents/helpers/gridDatasource/types';
import {
  ColumnDef,
  ColumnGroupDef,
  DatabaseObjectRow,
} from 'components/AgGridComponents/types/DatabaseColumnDef';
import {
  TimeseriesColumnDef,
  TimeseriesGroupColumnDef,
} from 'components/AgGridComponents/types/TimeseriesColumnDef';
import { BusinessObjectFieldSpecColumnKey, MonthColumnKey } from 'config/cells';
import { INTEGRATION_SOURCE_FIELD_NAME } from 'config/integrations';
import {
  ACTUALS_FORMULA_COLUMN_TYPE,
  FORECAST_FORMULA_COLUMN_TYPE,
  INITIAL_VALUE_COLUMN_TYPE,
  NAME_COLUMN_TYPE,
  PROPERTY_COLUMN_TYPE,
} from 'config/modelView';
import { ObjectSpecDisplayAsType } from 'generated/graphql';
import { getCurrentMonthKey, getMonthKey } from 'helpers/dates';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { orderedObjectTableBlockColumnKeysForTimeseries } from 'helpers/objectTableBlockHelpers';
import { isNotNull } from 'helpers/typescript';
import { BlockId } from 'reduxStore/models/blocks';
import { Driver, DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import { accessResourcesByIdSelector } from 'selectors/accessResourcesByIdSelector';
import { isCurrentPageDatabasePageSelector } from 'selectors/blocksPagesSelector';
import {
  accessCapabilityAwareBlockConfigShowRestrictedSelector,
  blockConfigGroupByFieldSpecIdSelector,
  blockConfigSelector,
  blockSelector,
} from 'selectors/blocksSelector';
import { getRestrictedBusinessObjectFieldsSelector } from 'selectors/businessObjectFieldSpecRestrictedSelector';
import { isBlockBusinessObjectNameRestrictedSelector } from 'selectors/businessObjectNameRestrictionsSelector';
import { businessObjectsByIdForLayerSelector } from 'selectors/businessObjectsSelector';
import {
  calculationEngineSelector,
  enableBackendBlockEvalSelector,
} from 'selectors/calculationEngineSelector';
import { isNewDimensionalTableSelector } from 'selectors/collectionBlocksSelector';
import { dimensionalPropertyEvaluatorSelector } from 'selectors/collectionSelector';
import { blockIdSelector, paramSelector } from 'selectors/constSelectors';
import { databaseSpecIdSelector } from 'selectors/databasePageSelector';
import { databaseBlockIdSelector } from 'selectors/databaseSelector';
import { attributesByIdSelector, dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import {
  driverPropertiesByDimDriverIdForBlockSelector,
  driverPropertiesBySubDriverIdForBlockSelector,
} from 'selectors/driverPropertySelectors';
import { driversByIdForLayerSelector } from 'selectors/driversSelector';
import { fieldSpecDisplayConfigurationsByIdForBlockSelector } from 'selectors/entityDisplayConfigurationSelector';
import { impactingEventsByFormulaEntityIdForLayerSelector } from 'selectors/eventsAndGroupsSelector';
import { extObjectSpecsByKeySelector } from 'selectors/extObjectSpecsSelector';
import { extObjectsSelector } from 'selectors/extObjectsSelector';
import {
  datasetLastActualsMonthKeySelector,
  lastActualsMonthKeyForLayerSelector,
} from 'selectors/lastActualsSelector';
import { enableBvAInDatabasesSelector } from 'selectors/launchDarklySelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { loggedInUserSelector } from 'selectors/loginSelector';
import {
  orderedObjectTableBlockColumnKeysForAgGridSelector,
  orderedObjectTableBlockColumnKeysForTimeseriesAgGridSelector,
} from 'selectors/objectTableBlockSelector';
import { businessObjectSpecForBlockSelector } from 'selectors/orderedFieldSpecIdsSelector';
import { isCurrentPageWritableSelector } from 'selectors/pageAccessResourcesSelector';
import {
  blockDateRangeDateTimeSelector,
  blockDateRangeTranslatedViewAtTimeSelector,
  blockMonthKeysSelector,
} from 'selectors/pageDateRangeSelector';
import { pageGutterWidthInPxSelector } from 'selectors/pageSelector';
import { previewDatabaseSelector } from 'selectors/previewDatabaseSelector';
import { isResourceRestrictedForUser } from 'selectors/restrictedResourcesSelector';
import { orgSettingsSelector } from 'selectors/selectedOrgSelector';
import { visibleFieldSpecTimeSeriesSelector } from 'selectors/visibleFieldSpecTimeSeriesSelector';
import { ParametricSelector, Selector } from 'types/redux';

// https://github.com/ag-grid/ag-grid/issues/2320
type ColumnDefKey = keyof ColumnDef | keyof TimeseriesColumnDef;
(PropertyKeys.ALL_PROPERTIES as ColumnDefKey[]).push(
  'meta',
  'fieldSpec',
  'columnKey',
  'attributeRefData',
  'blockId',
);
LicenseManager.setLicenseKey(process.env.NEXT_PUBLIC_AG_GRID_KEY as string);

/** pertains to old-world fields */
const getRestrictedFieldIdsSelector: ParametricSelector<BlockId, string[]> = createSelector(
  getRestrictedBusinessObjectFieldsSelector,
  isBlockBusinessObjectNameRestrictedSelector,
  (restrictedFields, hasRestrictedName) => {
    const restrictedFieldIds = restrictedFields.map((field) => field.id);
    if (hasRestrictedName) {
      restrictedFieldIds.push(NAME_COLUMN_TYPE);
    }
    return restrictedFieldIds;
  },
);

/** pertains to new-world fields/dimensional properties */
const getRestrictedFieldIdsfromObjectSpec: ParametricSelector<BlockId, string[]> =
  createCachedSelector(
    businessObjectSpecForBlockSelector,
    accessCapabilitiesSelector,
    accessResourcesByIdSelector,
    accessCapabilityAwareBlockConfigShowRestrictedSelector,
    (objectSpec, accessCapabilities, accessResourcesById, showRestrictedForBlock) => {
      if (objectSpec == null) {
        return [];
      }

      const objectFieldSpecIds = [
        ...objectSpec.fields.map((field) => field.id),
        ...(objectSpec.collection?.dimensionalProperties.map((prop) => prop.id) ?? []),
        ...(objectSpec.collection?.driverProperties.map((prop) => prop.id) ?? []),
      ].filter((id) => id != null);

      const restrictedFields: string[] = [];
      objectFieldSpecIds.forEach((id) => {
        const isRestricted = isResourceRestrictedForUser(
          accessCapabilities,
          accessResourcesById,
          id,
          showRestrictedForBlock,
        );
        if (isRestricted) {
          restrictedFields.push(id);
        }
      });
      return restrictedFields;
    },
  )({ keySelector: blockIdSelector, selectorCreator: createDeepEqualSelector });

const objectGridColumnDefBaseParams1Selector: ParametricSelector<
  BlockId,
  Pick<
    ColumnDefInputParams,
    | 'blockId'
    | 'fieldSpecsByIds'
    | 'objectSpec'
    | 'groupByObjectFieldSpecId'
    | 'dimensionalPropertiesById'
    | 'driverPropertiesById'
    | 'displayAsTimeSeries'
    | 'blockConfigColumns'
    | 'blockDateRangeDateTime'
  > | null
> = createCachedSelector(
  blockIdSelector,
  blockConfigSelector,
  businessObjectSpecForBlockSelector,
  blockDateRangeDateTimeSelector,
  // eslint-disable-next-line max-params
  function objectGridColumnDefsSelector(blockId, blockConfig, objectSpec, blockDateRangeDateTime) {
    if (blockConfig == null || objectSpec == null) {
      return null;
    }

    const groupByObjectFieldSpecId =
      blockConfig.groupBy?.objectField?.businessObjectFieldId ??
      blockConfig?.groupBy?.driverProperty?.driverPropertyId;

    const fieldSpecsByIds = fromPairs(
      objectSpec.fields
        .filter((field) => field.name !== INTEGRATION_SOURCE_FIELD_NAME)
        .map((field) => [field.id, field]),
    );
    const dimensionalPropertiesById = fromPairs(
      objectSpec.collection?.dimensionalProperties.map((prop) => [prop.id, prop]),
    );
    const driverPropertiesById = fromPairs(
      objectSpec.collection?.driverProperties.map((prop) => [prop.id, prop]),
    );

    const displayAsTimeSeries =
      blockConfig.objectSpecDisplayAs === ObjectSpecDisplayAsType.Timeseries;

    const blockConfigColumns = blockConfig.columns ?? [];

    return {
      blockId,
      fieldSpecsByIds,
      objectSpec,
      groupByObjectFieldSpecId,
      dimensionalPropertiesById,
      driverPropertiesById,
      displayAsTimeSeries,
      blockConfigColumns,
      blockDateRangeDateTime,
    };
  },
)({ keySelector: blockIdSelector, selectorCreator: createDeepEqualSelector });

const canEditDatabaseBlockSelector: ParametricSelector<BlockId, boolean> = createSelector(
  blockSelector,
  isCurrentPageWritableSelector,
  previewDatabaseSelector,
  (block, canEdit, previewDatabaseBlockId) => {
    return canEdit && previewDatabaseBlockId !== block?.id;
  },
);

const objectGridColumnDefBaseParams2Selector: ParametricSelector<
  BlockId,
  Pick<
    ColumnDefInputParams,
    | 'visibleFieldSpecTimeSeries'
    | 'editable'
    | 'dimensionsById'
    | 'displayConfigurations'
    | 'blockMonthKeys'
    | 'driversById'
    | 'lastActualsMonthKey'
    | 'restrictedFieldIds'
    | 'isNewDimensionalTable'
    | 'accessResourcesById'
    | 'showRestrictedForBlock'
    | 'accessCapabilitiesProvider'
  >
> = createCachedSelector(
  visibleFieldSpecTimeSeriesSelector,
  dimensionsByIdSelector,
  fieldSpecDisplayConfigurationsByIdForBlockSelector,
  blockMonthKeysSelector,
  canEditDatabaseBlockSelector,
  datasetLastActualsMonthKeySelector,
  isNewDimensionalTableSelector,
  getRestrictedFieldIdsSelector,
  driversByIdForLayerSelector,
  accessResourcesByIdSelector,
  accessCapabilityAwareBlockConfigShowRestrictedSelector,
  accessCapabilitiesSelector,
  // eslint-disable-next-line max-params
  function objectGridColumnDefsSelector(
    visibleFieldSpecTimeSeries,
    dimensionsById,
    displayConfigurations,
    blockMonthKeys,
    editable,
    lastActualsMonthKey,
    isNewDimensionalTable,
    restrictedFieldIds,
    driversById,
    accessResourcesById,
    showRestrictedForBlock,
    accessCapabilitiesProvider,
  ) {
    return {
      visibleFieldSpecTimeSeries,
      editable,
      dimensionsById,
      displayConfigurations,
      blockMonthKeys,
      driversById,
      lastActualsMonthKey,
      restrictedFieldIds,
      isNewDimensionalTable,
      accessResourcesById,
      showRestrictedForBlock,
      accessCapabilitiesProvider,
    };
  },
)({ keySelector: blockIdSelector, selectorCreator: createDeepEqualSelector });

// This selector is split up into two parts because `createCachedSelector` has a hard limit of 13 selectors.
const objectGridColumnDefBaseParamsSelector: ParametricSelector<
  BlockId,
  Omit<ColumnDefInputParams, 'objectFieldSpecId' | 'comparisons'> | null
> = createCachedSelector(
  objectGridColumnDefBaseParams1Selector,
  objectGridColumnDefBaseParams2Selector,
  function objectGridColumnDefsSelector(params1, params2) {
    if (params1 == null || params2 == null) {
      return null;
    }

    return {
      ...params1,
      ...params2,
    };
  },
)({ keySelector: blockIdSelector, selectorCreator: createDeepEqualSelector });

const driversByIdForComparisonLayerIdsSelector: ParametricSelector<
  BlockId,
  NullableRecord<LayerId, NullableRecord<DriverId, Driver>>
> = createDeepEqualSelector(
  (state: RootState) => state,
  blockIdSelector,
  (state, blockId) => {
    const blockConfig = blockConfigSelector(state, blockId);
    const comparisonLayerIds = blockConfig?.comparisons?.layerIds ?? [];
    const currentLayerId = currentLayerIdSelector(state);
    const result: NullableRecord<LayerId, NullableRecord<DriverId, Driver>> = {};
    [currentLayerId, ...comparisonLayerIds].forEach((layerId) => {
      result[layerId] = driversByIdForLayerSelector(state, { layerId });
    });
    return result;
  },
);

export const objectGridColumnDefsSelector: ParametricSelector<
  BlockId,
  Array<ColumnDef | ColumnGroupDef>
> = createCachedSelector(
  objectGridColumnDefBaseParamsSelector,
  blockConfigSelector,
  businessObjectSpecForBlockSelector,
  orderedObjectTableBlockColumnKeysForAgGridSelector,
  visibleFieldSpecTimeSeriesSelector,
  pageGutterWidthInPxSelector,
  // eslint-disable-next-line max-params
  function objectGridColumnDefsSelector(
    baseParams,
    blockConfig,
    objectSpec,
    columnKeys,
    visibleFieldSpecTimeSeries,
    pageGutterWidthInPx,
  ) {
    if (baseParams == null || blockConfig == null || objectSpec == null) {
      return [];
    }

    const groupByObjectFieldSpecId =
      blockConfig.groupBy?.objectField?.businessObjectFieldId ??
      blockConfig?.groupBy?.driverProperty?.driverPropertyId;

    const fieldSpecsByIds = fromPairs(
      objectSpec.fields
        .filter((field) => field.name !== INTEGRATION_SOURCE_FIELD_NAME)
        .map((field) => [field.id, field]),
    );
    const driverPropertiesById = fromPairs(
      objectSpec.collection?.driverProperties.map((prop) => [prop.id, prop]),
    );

    const hasSegmentBy =
      (objectSpec.collection?.dimensionalProperties.filter((p) => p.isDatabaseKey).length ?? 0) > 0;

    // Object fields or driver properties can be viewed as a timeseries.
    const visibleTimeSeriesIsObjectField =
      visibleFieldSpecTimeSeries != null &&
      (visibleFieldSpecTimeSeries in fieldSpecsByIds ||
        visibleFieldSpecTimeSeries in driverPropertiesById);

    const blockConfigColumns = blockConfig.columns ?? [];
    const blockConfigColumnsByKey = keyBy(blockConfigColumns, (c) => c.key);

    const displayAsTimeSeries =
      blockConfig.objectSpecDisplayAs === ObjectSpecDisplayAsType.Timeseries;

    let columns: Array<BusinessObjectFieldSpecColumnKey | AddColumnKey> = columnKeys;

    if (!displayAsTimeSeries) {
      if (visibleTimeSeriesIsObjectField) {
        // ensure the timeseries column is last and add is right before it
        columns = [
          ...columns.filter(
            ({ objectFieldSpecId }) => objectFieldSpecId !== visibleFieldSpecTimeSeries,
          ),
          { objectFieldSpecId: ADD_COLUMN_TYPE },
          { objectFieldSpecId: visibleFieldSpecTimeSeries },
        ];
      } else {
        columns = [...columns, { objectFieldSpecId: ADD_COLUMN_TYPE }];
      }
    }

    // if grouped, move the group by column to the front in case it's a key column
    if (groupByObjectFieldSpecId != null) {
      columns = [
        { objectFieldSpecId: groupByObjectFieldSpecId },
        ...columns.filter(
          ({ objectFieldSpecId }) => objectFieldSpecId !== groupByObjectFieldSpecId,
        ),
      ];
    }

    const columnDefs = columns
      .flatMap(({ objectFieldSpecId }) =>
        deriveColumnDef({
          ...baseParams,
          width: blockConfigColumnsByKey[objectFieldSpecId]?.width ?? undefined,
          objectFieldSpecId,
          comparisons: blockConfig.comparisons,
        }),
      )
      .filter(isNotNull);

    return [
      createOptionsColumnDef('database', { width: pageGutterWidthInPx, ...baseParams }),
      createNameColumnDef({
        ...baseParams,
        hasSegmentBy,
        width: blockConfigColumnsByKey[NAME_COLUMN_TYPE]?.width ?? undefined,
        comparisons: blockConfig.comparisons,
      }),
      ...columnDefs,
    ];
  },
)({ keySelector: blockIdSelector, selectorCreator: createDeepEqualSelector });

export const objectGridTimeseriesColumnDefsSelector: ParametricSelector<
  BlockId,
  Array<TimeseriesColumnDef | TimeseriesGroupColumnDef>
> = createCachedSelector(
  blockConfigSelector,
  businessObjectSpecForBlockSelector,
  blockMonthKeysSelector,
  datasetLastActualsMonthKeySelector,
  isCurrentPageWritableSelector,
  function objectGridDetailColumnDefsSelector(
    blockConfig,
    objectSpec,
    blockMonthKeys,
    lastActualsMonthKey,
    editable,
  ) {
    if (blockConfig == null || objectSpec == null) {
      return [];
    }

    const blockConfigColumns = blockConfig.columns ?? [];
    const blockConfigColumnsByKey = keyBy(blockConfigColumns, (c) => c.key);
    const groupByObjectFieldSpecId = blockConfig.groupBy?.objectField?.businessObjectFieldId;

    const [_propertyColumn, _initialValueColumn, ...columns] =
      orderedObjectTableBlockColumnKeysForTimeseries({ blockMonthKeys });

    const detailColumnDefs: Array<TimeseriesColumnDef | TimeseriesGroupColumnDef> = [
      createObjectIdColumnDef(),
      createOptionsColumnDef('timeseries', { width: 32 }),
      createPropertyColumnDef({ width: blockConfigColumnsByKey[PROPERTY_COLUMN_TYPE]?.width }),
      {
        marryChildren: true,
        children: columns.map((columnKey) =>
          createTimeseriesMonthKeyColumnDef({
            monthColumnKey: columnKey as MonthColumnKey,
            lastActualsMonthKey,
            editable,
            dateRangeSpan: blockMonthKeys.length,
          }),
        ),
      },
    ];

    if (groupByObjectFieldSpecId != null) {
      detailColumnDefs.push(createObjectGroupByColumnDef());
    }

    // Only display initial value if the database is using fields.
    const hasAnyFields = objectSpec.fields.length > 0;
    if (hasAnyFields) {
      detailColumnDefs.push(
        createInitialValueColumnDef({
          editable,
          width: blockConfigColumnsByKey[INITIAL_VALUE_COLUMN_TYPE]?.width,
          visible: blockConfigColumnsByKey[INITIAL_VALUE_COLUMN_TYPE]?.visible,
        }),
      );
    }

    // Only display driver formulas if the database is using dimensional drivers.
    const hasAnyDriverProperty = !isEmpty(objectSpec.collection?.driverProperties);
    if (hasAnyDriverProperty) {
      detailColumnDefs.push(
        createFormulaColumnDef({
          editable,
          width: blockConfigColumnsByKey[ACTUALS_FORMULA_COLUMN_TYPE]?.width,
          type: 'actuals',
        }),
        createFormulaColumnDef({
          editable,
          width: blockConfigColumnsByKey[FORECAST_FORMULA_COLUMN_TYPE]?.width,
          type: 'forecast',
        }),
      );
    }

    return detailColumnDefs;
  },
)({ keySelector: blockIdSelector, selectorCreator: createDeepEqualSelector });

export const gridGroupByDimensionSelector: ParametricSelector<
  BlockId,
  Record<string, string> | null
> = createCachedSelector(
  blockConfigSelector,
  businessObjectSpecForBlockSelector,
  dimensionsByIdSelector,
  driversByIdForLayerSelector,
  (blockConfig, objectSpec, dimensionsById, driversById) => {
    const groupBy =
      blockConfig?.groupBy?.objectField?.businessObjectFieldId ??
      blockConfig?.groupBy?.driverProperty?.driverPropertyId;

    if (groupBy == null) {
      return null;
    }

    // attempt to extract dimension id from field
    let dimensionId: string | null = null;
    const field = objectSpec?.fields.find(({ id }) => id === groupBy);
    dimensionId = field?.dimensionId ?? null;

    // attempt to extract dimension id from dimensional property
    if (dimensionId == null) {
      const dimensionalProperty = objectSpec?.collection?.dimensionalProperties.find(
        (prop) => prop.id === groupBy,
      );
      dimensionId = dimensionalProperty?.dimension.id ?? null;
    }

    // attempt to extract dimension id from driver property
    if (dimensionId == null) {
      const driverProperty = objectSpec?.collection?.driverProperties?.find(
        (prop) => prop.id === groupBy,
      );

      if (driverProperty == null) {
        dimensionId = null;
      } else {
        const driver = driversById[driverProperty.driverId];
        dimensionId = driver?.dimensionId ?? null;
      }
    }

    if (dimensionId == null) {
      return null;
    }

    const dimension = fromPairs(
      dimensionsById[dimensionId].attributes.map((attr) => [attr.id, attr.value]),
    );
    return dimension as Record<string, string>;
  },
)((_state, blockId) => blockId);

const gridDatabaseDataSourceSelector: ParametricSelector<BlockId, DatabaseDataSource> =
  createCachedSelector(
    paramSelector<BlockId>(),
    // Add this selector to recreate the datasource when the group by changes.
    blockConfigGroupByFieldSpecIdSelector,
    (blockId) => {
      return new DatabaseDataSource(blockId);
    },
  )((_state, blockId) => blockId);

export const blockDatabaseDataSourceSelector: ParametricSelector<
  BlockId,
  DatabaseDataSource | null
> = createSelector(
  blockConfigSelector,
  gridDatabaseDataSourceSelector,
  businessObjectSpecForBlockSelector,
  businessObjectsByIdForLayerSelector,
  blockDateRangeDateTimeSelector,
  blockDateRangeTranslatedViewAtTimeSelector,
  objectGridColumnDefsSelector,
  dimensionalPropertyEvaluatorSelector,
  currentLayerIdSelector,
  driversByIdForComparisonLayerIdsSelector,
  accessCapabilitiesSelector,
  orgSettingsSelector,
  impactingEventsByFormulaEntityIdForLayerSelector,
  extObjectsSelector,
  extObjectSpecsByKeySelector,
  lastActualsMonthKeyForLayerSelector,
  loggedInUserSelector,
  (_state: RootState, _blockId: BlockId, gridApi: GridApi<DatabaseObjectRow>) => gridApi,
  calculationEngineSelector,
  enableBackendBlockEvalSelector,
  enableBvAInDatabasesSelector,
  // eslint-disable-next-line max-params
  function blockGridDataSourceSelector(
    blockConfig,
    datasource,
    objectSpec,
    allObjectsById,
    dateRange,
    viewAtTime,
    columnDefs,
    dimensionalPropertyEvaluator,
    currentLayerId,
    driversByIdByLayerId,
    accessCapabilities,
    orgSettings,
    eventsByEntityId,
    allExtObjects,
    extObjectSpecsByKey,
    lastActualsMonthKey,
    loggedInUser,
    gridApi,
    calculationEngine,
    enableBackendBlockEval,
    enableBvAInDatabases,
  ) {
    if (objectSpec == null || orgSettings == null) {
      return null;
    }

    // If the datasource is stale, reset it.
    // This will happen when switching between view as "Database" and "Timeseries".
    // Do not remove this.
    if (datasource.isDestroyed) {
      datasource.reset();
    }

    const objectsById = pickBy(allObjectsById, (o) => o.specId === objectSpec.id);
    const extObjectsByExtKey = keyBy(
      allExtObjects.filter((o) => objectSpec.extSpecKeys?.includes(o.extSpecKey)),
      (eo) => eo.extKey,
    );
    const params: DatabaseDataSourceUpdateStateParams = {
      blockConfig,
      objectSpec,
      objectsById,
      dateRange,
      viewAtMonthKey: viewAtTime != null ? getMonthKey(viewAtTime) : getCurrentMonthKey(),
      columnDefs,
      dimensionalPropertyEvaluator,
      currentLayerId,
      driversByIdByLayerId,
      accessCapabilities,
      orgSettings,
      eventsByEntityId,
      extObjectsByExtKey,
      extObjectSpecsByKey,
      lastActualsMonthKey,
      loggedInUser,
      gridApi,
      calculationEngine,
      enableBackendBlockEval,
      enableBvAInDatabases,
    };

    datasource.updateState(params);
    return datasource;
  },
);

const gridTimeseriesDataSourceSelector: ParametricSelector<BlockId, TimeseriesDataSource> =
  createCachedSelector(
    paramSelector<BlockId>(),
    // Add this selector to recreate the datasource when the group by changes.
    blockConfigGroupByFieldSpecIdSelector,
    (blockId) => {
      return new TimeseriesDataSource(blockId);
    },
  )((_state, blockId) => blockId);

export const blockTimeseriesDataSourceSelector: ParametricSelector<
  BlockId,
  TimeseriesDataSource | null
> = createSelector(
  blockConfigSelector,
  gridTimeseriesDataSourceSelector,
  businessObjectSpecForBlockSelector,
  businessObjectsByIdForLayerSelector,
  blockDateRangeDateTimeSelector,
  blockDateRangeTranslatedViewAtTimeSelector,
  objectGridTimeseriesColumnDefsSelector,
  orderedObjectTableBlockColumnKeysForTimeseriesAgGridSelector,
  attributesByIdSelector,
  dimensionalPropertyEvaluatorSelector,
  currentLayerIdSelector,
  driversByIdForComparisonLayerIdsSelector,
  accessCapabilitiesSelector,
  orgSettingsSelector,
  impactingEventsByFormulaEntityIdForLayerSelector,
  extObjectsSelector,
  extObjectSpecsByKeySelector,
  lastActualsMonthKeyForLayerSelector,
  loggedInUserSelector,
  calculationEngineSelector,
  enableBackendBlockEvalSelector,
  fieldSpecDisplayConfigurationsByIdForBlockSelector,
  getRestrictedFieldIdsSelector,
  getRestrictedFieldIdsfromObjectSpec,
  driverPropertiesByDimDriverIdForBlockSelector,
  driverPropertiesBySubDriverIdForBlockSelector,
  enableBvAInDatabasesSelector,
  // eslint-disable-next-line max-params
  function blockTimeseriesDataSourceSelector(
    blockConfig,
    datasource,
    objectSpec,
    allObjectsById,
    dateRange,
    viewAtTime,
    columnDefs,
    orderedProperties,
    attributesById,
    dimensionalPropertyEvaluator,
    currentLayerId,
    driversByIdByLayerId,
    accessCapabilities,
    orgSettings,
    eventsByEntityId,
    allExtObjects,
    extObjectSpecsByKey,
    lastActualsMonthKey,
    loggedInUser,
    calculationEngine,
    enableBackendBlockEval,
    fieldSpecDisplayConfigurationsById,
    restrictedFieldIds,
    restrictedDimensionPropertyIds,
    driverPropertiesByDimDriverId,
    driverPropertiesBySubDriverId,
    enableBvAInDatabases,
  ) {
    if (objectSpec == null || orgSettings == null) {
      return null;
    }

    const objectsById = pickBy(allObjectsById, (o) => o.specId === objectSpec.id);
    const extObjectsByExtKey = keyBy(
      allExtObjects.filter((o) => objectSpec.extSpecKeys?.includes(o.extSpecKey)),
      (eo) => eo.extKey,
    );

    const params: TimeseriesDataSourceUpdateStateParams = {
      blockConfig,
      objectSpec,
      objectsById,
      dateRange,
      viewAtMonthKey: viewAtTime != null ? getMonthKey(viewAtTime) : getCurrentMonthKey(),
      columnDefs,
      orderedProperties,
      attributesById,
      dimensionalPropertyEvaluator,
      currentLayerId,
      driversByIdByLayerId,
      accessCapabilities,
      orgSettings,
      eventsByEntityId,
      extObjectsByExtKey,
      extObjectSpecsByKey,
      lastActualsMonthKey,
      loggedInUser,
      calculationEngine,
      restrictedFieldIds: [...restrictedFieldIds, ...restrictedDimensionPropertyIds],
      enableBackendBlockEval,
      fieldSpecDisplayConfigurationsById,
      driverPropertiesByDimDriverId,
      driverPropertiesBySubDriverId,
      enableBvAInDatabases,
    };
    datasource.updateState(params);

    return datasource;
  },
);

export const currentBlocksPageDataSourceSelector: Selector<DatabaseDataSource | null> =
  createSelector(
    (state: RootState) => state,
    isCurrentPageDatabasePageSelector,
    databaseSpecIdSelector,
    (state, isCurrentPageDatabase, currentDatabaseSpecId) => {
      if (!isCurrentPageDatabase || currentDatabaseSpecId == null) {
        return null;
      }

      const blockId = databaseBlockIdSelector(state, currentDatabaseSpecId);
      if (blockId == null) {
        return null;
      }

      return blockDatabaseDataSourceSelector(state, blockId);
    },
  );
