import { isEmpty } from 'lodash';

import { ImpactType, ValueType } from 'generated/graphql';
import { splitAddends } from 'helpers/formula';
import {
  GENERIC_ERR,
  MULTIPLE_ADDENDS_ERR,
} from 'helpers/formulaEvaluation/ImpactParser/ImpactParser';
import { ImpactParserListener as ImpactParserListenerAntlr } from 'helpers/formulaEvaluation/ImpactParser/ImpactParserListener';
import { AtomicNumberContext } from 'helpers/formulaEvaluation/ImpactParser/ImpactParserParser';
import { getNumber } from 'helpers/number';
import { CurvePointTyped } from 'reduxStore/models/events';
import { NumberValue } from 'reduxStore/models/value';

type ParsedDeltaImpactResult = {
  impactType: ImpactType.Delta;
  values: NumberValue[];
};

type ParsedSetImpactResult = {
  impactType: ImpactType.Set;
  value: NumberValue;
};

export type ParsedImpactResult = ParsedDeltaImpactResult | ParsedSetImpactResult;

interface ImpactParserListener extends ImpactParserListenerAntlr {
  getResult: (rawFormula: string) => CurvePointTyped | undefined;
  getResultWithMultiImpactSupport: (rawFormula: string) => ParsedImpactResult | undefined;
}
export class ImpactParserListenerImpl implements ImpactParserListener {
  private atomicNumberLastCharIndex: number | undefined;

  constructor() {
    this.atomicNumberLastCharIndex = undefined;
  }

  getResult(rawFormula: string): CurvePointTyped | undefined {
    if (
      isEmpty(rawFormula) ||
      // This occurs when the entire formula is an atomic number, e.g. `atomicNumber(1)`
      this.atomicNumberLastCharIndex === rawFormula.length - 1
    ) {
      // No impact
      return undefined;
    }

    // This occurs when there is no atomic number in the formula
    if (this.atomicNumberLastCharIndex == null) {
      // Set impact
      const value = getNumber(rawFormula);

      if (isNaN(value)) {
        throw new Error(GENERIC_ERR);
      }

      return {
        impactType: ImpactType.Set,
        type: ValueType.Number,
        value,
      };
    }

    // This occurs when there is an atomic number in the formula AND a delta impact after it
    // e.g. `atomicNumber(1) + 1.01`
    // Delta impact
    const deltaFormula = rawFormula.slice(this.atomicNumberLastCharIndex + 1);
    const addends = splitAddends(deltaFormula);
    if (addends.length > 1) {
      throw new Error(MULTIPLE_ADDENDS_ERR);
    }

    const value = getNumber(deltaFormula);
    if (isNaN(value)) {
      throw new Error(GENERIC_ERR);
    }

    return {
      impactType: ImpactType.Delta,
      type: ValueType.Number,
      value,
    };
  }

  getResultWithMultiImpactSupport(rawFormula: string): ParsedImpactResult | undefined {
    if (
      isEmpty(rawFormula) ||
      // This occurs when the entire formula is an atomic number, e.g. `atomicNumber(1)`
      this.atomicNumberLastCharIndex === rawFormula.length - 1
    ) {
      // No impact
      return undefined;
    }

    // This occurs when there is no atomic number in the formula
    // Set impact
    if (this.atomicNumberLastCharIndex == null) {
      const value = getNumber(rawFormula);
      if (isNaN(value)) {
        throw new Error(GENERIC_ERR);
      }

      return {
        impactType: ImpactType.Set,
        value: {
          type: ValueType.Number,
          value,
        },
      };
    }

    // This occurs when there is an atomic number in the formula AND one or more delta impacts after it
    // e.g. `atomicNumber(1) + 1 - 2`
    // Delta impact(s)
    const deltaFormula = rawFormula.slice(this.atomicNumberLastCharIndex + 1);
    const addends = splitAddends(deltaFormula);

    const evaluatedAddends = addends.map((addend) => getNumber(addend));
    if (evaluatedAddends.some((value) => isNaN(value))) {
      throw new Error(GENERIC_ERR);
    }

    const values: NumberValue[] = evaluatedAddends.map((value) => ({
      type: ValueType.Number,
      value,
    }));

    if (values.length === 0) {
      throw new Error(GENERIC_ERR);
    }

    return {
      impactType: ImpactType.Delta,
      values,
    };
  }

  exitAtomicNumber(ctx: AtomicNumberContext): void {
    this.atomicNumberLastCharIndex = ctx.stop?.stopIndex;
  }
}
