import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';

import { WithEventGroup } from '@features/Plans';
import { GroupInfo } from 'config/businessObjects';
import { CellType, OBJECT_FIELD_NAME_COLUMN_KEY } from 'config/cells';
import {
  BusinessObjectCreateInput,
  BusinessObjectDeleteInput,
  BusinessObjectFieldInput,
  BusinessObjectFieldUpdateInput,
  BusinessObjectFieldValueInput,
  BusinessObjectUpdateInput,
  CollectionEntryCreateInput,
  DatasetMutationInput,
  DimensionalSubDriverCreateInput,
  DriverCreateInput,
  DriverFormat,
  DriverType,
  DriverUpdateInput,
  EventCreateInput,
  EventDeleteInput,
  EventUpdateInput,
  ImpactType,
  ValueType,
} from 'generated/graphql';
import {
  getDefaultFieldValuesByFieldSpecId,
  getDefaultNameForObject,
} from 'helpers/businessObjects';
import {
  START_OF_MONTH,
  extractMonthKey,
  getISOTimeWithoutMsFromMonthKey,
  getNumMonthsInclusive,
  isValidMonthKey,
} from 'helpers/dates';
import { getShiftedCustomCurve, getShiftedStartEnd } from 'helpers/events';
import { getFilterDefaultValueByFieldSpecId } from 'helpers/filtering';
import { convertTimeSeriesToGql } from 'helpers/gqlDataset';
import { mergeMutations } from 'helpers/mergeMutations';
import { getObjectFieldUUID } from 'helpers/object';
import { peekMutationStateChange } from 'helpers/sortIndex';
import { isNotNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { cellNavigate } from 'reduxStore/actions/cellNavigation';
import { getExistingSubDrivers } from 'reduxStore/actions/driverMutations';
import {
  createNewEventMutations,
  createNewEventMutationsBatched,
  updateObjectFieldForecastMutation,
} from 'reduxStore/actions/eventMutations';
import {
  updateDimensionalPropertyEntryMutation,
  updateDriversFromDimensionalPropertyChangeMutation,
} from 'reduxStore/actions/modifyDatabase';
import {
  getMutationThunkAction,
  submitAutoLayerizedMutations,
} from 'reduxStore/actions/submitDatasetMutation';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpec,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import {
  BusinessObject,
  BusinessObjectField,
  BusinessObjectFieldId,
  BusinessObjectId,
} from 'reduxStore/models/businessObjects';
import { DimensionalPropertyId } from 'reduxStore/models/collections';
import { Attribute } from 'reduxStore/models/dimensions';
import { EventGroupId, ObjectFieldEvent } from 'reduxStore/models/events';
import { ValueTimeSeriesWithEmpty, valueTimeSeriesToInput } from 'reduxStore/models/timeSeries';
import { NullableValue } from 'reduxStore/models/value';
import { bulkCreateEventsAndGroupsMutations } from 'reduxStore/reducers/helpers/bulkCreateEventsAndGroupsMutations';
import { defaultValueForField } from 'reduxStore/reducers/helpers/businessObjectSpecs';
import { createEventInputFromEvent } from 'reduxStore/reducers/helpers/events';
import { selectSingleCell, setAutoFocus } from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppThunk } from 'reduxStore/store';
import { blockConfigGroupBySelector } from 'selectors/blocksSelector';
import { businessObjectFieldSpecSelector } from 'selectors/businessObjectFieldSpecsSelector';
import {
  businessObjectSpecSelector,
  businessObjectSpecsByIdForLayerSelector,
} from 'selectors/businessObjectSpecsSelector';
import { businessObjectFieldTransitionValueSelector } from 'selectors/businessObjectTimeSeriesSelector';
import {
  businessObjectNameSelector,
  businessObjectSelector,
  businessObjectsByIdForLayerSelector,
  materializedFieldForSpecIdAndObjectIdSelector,
} from 'selectors/businessObjectsSelector';
import { businessObjectSpecDimensionalPropertyKeysSelector } from 'selectors/collectionBlocksSelector';
import {
  dimensionalPropertyEvaluatorSelector,
  dimensionalPropertySelector,
} from 'selectors/collectionSelector';
import { dimensionsByIdSelector } from 'selectors/dimensionsSelector';
import {
  driversByIdForLayerSelector,
  subDriversByDriverIdSelector,
} from 'selectors/driversSelector';
import {
  eventWithDirectImpactForObjectSelector,
  eventsForBusinessObjectSelector,
  visibleEventsByObjectFieldIdForLayerSelector,
} from 'selectors/eventsAndGroupsSelector';
import { lastActualsMonthKeyForLayerSelector } from 'selectors/lastActualsSelector';
import { enableEagerSubDriverInitializationSelector } from 'selectors/launchDarklySelector';
import { authenticatedUserSelector } from 'selectors/loginSelector';
import { MonthKey } from 'types/datetime';

const mutationActions = {
  deleteBusinessObjects: getMutationThunkAction<BusinessObjectDeleteInput[]>(
    (deleteInputs, getState) => {
      const state = getState();
      return deleteInputs
        .map((deleteInput) => deleteBusinessObjectMutations(state, deleteInput.id))
        .reduce((res, mutationInput) => {
          return mergeMutations(res, mutationInput);
        }, {} as DatasetMutationInput);
    },
  ),
  updateBusinessObject: getMutationThunkAction<BusinessObjectUpdateInput>(
    (updateInput, getState) => {
      return updateBusinessObjectMutations({ state: getState(), updateInput });
    },
  ),
  updateBusinessObjects: getMutationThunkAction<BusinessObjectUpdateInput[]>(
    (updateInputs, getState) => {
      const state = getState();
      return updateInputs
        .map((updateInput) => updateBusinessObjectMutations({ state, updateInput }))
        .reduce((res, mutationInput) => {
          return mergeMutations(res, mutationInput);
        }, {} as DatasetMutationInput);
    },
  ),
  linkExistingObjectToSyncedObject: getMutationThunkAction<{
    existingObjId: BusinessObjectId;
    syncedObjId: BusinessObjectId;
  }>(({ existingObjId, syncedObjId }, getState) => {
    const state = getState();

    const existingObj = businessObjectSelector(state, existingObjId);
    if (existingObj == null) {
      throw new Error(`existing object with id ${existingObjId} does not exist`);
    }

    const syncedObj = businessObjectSelector(state, syncedObjId);
    if (syncedObj == null) {
      throw new Error(`synced object with id ${existingObjId} does not exist`);
    }

    if (existingObj.remoteId != null) {
      throw new Error(`existing object ${existingObj.id} already has a remote id`);
    }

    if (syncedObj.remoteId == null) {
      throw new Error(`synced object ${existingObj.id} does not have a remote id`);
    }

    if (existingObj.specId !== syncedObj.specId) {
      throw new Error(
        `unable to link objects across specs ${existingObj.specId} and ${syncedObj.specId}`,
      );
    }

    // TODO: We may want to ensure that we preserve any hierarchy by
    // recursively copying event groups as well as events. But, to simplify
    // things for now, we are just going to place everything in the synced
    // groups default event group.
    const scopedEvents = eventsForBusinessObjectSelector(state, existingObjId).filter(
      // Only copy over forward looking plans
      (e) => DateTime.fromISO(e.start) >= START_OF_MONTH,
    );

    // Fields which do not come from integration should keep values from existing object
    const updatedFields: BusinessObjectFieldUpdateInput[] = [];
    const addedFields: BusinessObjectFieldInput[] = [];

    const spec = businessObjectSpecSelector(state, existingObj.specId);
    existingObj.fields.forEach((existingField) => {
      if (
        existingField.value?.actuals == null ||
        (existingField.value.actuals.formula == null &&
          Object.keys(existingField.value.actuals.timeSeries ?? {}).length === 0)
      ) {
        return;
      }
      const syncedField = syncedObj.fields.find(
        (field) => field.fieldSpecId === existingField.fieldSpecId,
      );
      const specField = spec?.fields.find((field) => field.id === existingField.fieldSpecId);
      // Field is not linked
      if (specField != null && specField.extFieldSpecKey == null) {
        const valueInput = {
          type: existingField.value.type,
          actuals: {
            formula: existingField.value.actuals.formula,
            timeSeries: valueTimeSeriesToInput(existingField.value.actuals.timeSeries),
          },
        };
        if (syncedField == null) {
          addedFields.push({
            id: getObjectFieldUUID(syncedObjId, existingField.fieldSpecId),
            fieldSpecId: existingField.fieldSpecId,
            value: valueInput,
          });
        } else if (
          syncedField.value?.actuals.formula == null &&
          Object.keys(syncedField.value?.actuals.timeSeries ?? {}).length === 0
        ) {
          updatedFields.push({
            id: syncedField.id,
            value: valueInput,
          });
        }
      }
    });

    const eventInputs = scopedEvents.reduce((curr, e) => {
      const existingField = existingObj.fields.find((f) => f.id === e.businessObjectFieldId);
      if (existingField == null) {
        return curr;
      }

      let syncedField: BusinessObjectField | BusinessObjectFieldInput | undefined =
        syncedObj.fields.find((f) => f.fieldSpecId === existingField.fieldSpecId);
      if (syncedField == null) {
        let addedField = addedFields.find(
          (field) => field.fieldSpecId === existingField.fieldSpecId,
        );
        // Add fields for events
        if (addedField == null && existingField.value != null) {
          addedField = {
            id: getObjectFieldUUID(syncedObjId, existingField.fieldSpecId),
            fieldSpecId: existingField.fieldSpecId,
            value: {
              type: existingField.value.type,
              actuals: {
                formula: existingField.value.actuals.formula,
                timeSeries: valueTimeSeriesToInput(existingField.value.actuals.timeSeries),
              },
            },
          };

          addedFields.push(addedField);
        }
        syncedField = addedField;
      }

      if (syncedField == null) {
        return curr;
      }

      return [
        ...curr,
        {
          id: uuidv4(),
          businessObjectFieldId: syncedField.id,
          setValueType: e.valueType,
          customCurvePoints: convertTimeSeriesToGql(e.customCurvePoints),
          start: e.start,
          end: e.end,
          ownerName: e.ownerName,
          parentId: syncedObj.defaultEventGroupId,
        },
      ];
    }, [] as EventCreateInput[]);

    const copyEventsMutations = bulkCreateEventsAndGroupsMutations(state, {
      events: eventInputs,
      groups: [],
    });

    // Will delete the object and all events/groups associated with it
    const deleteObjMutations = deleteBusinessObjectMutations(state, existingObj.id);

    return mergeMutations(mergeMutations(copyEventsMutations, deleteObjMutations), {
      updateBusinessObjects: [{ id: syncedObjId, addFields: addedFields, fields: updatedFields }],
    });
  }),
  // Push out all events on a field such that the earliest event lands on the
  // monthKey month. Does nothing if there are no events before this month.
  reforecastEventsToMonth: getMutationThunkAction<{
    objectId: BusinessObjectId;
    monthKey: MonthKey;
  }>(({ objectId, monthKey }, getState) => {
    const state = getState();

    const objsById = businessObjectsByIdForLayerSelector(state);
    const specsById = businessObjectSpecsByIdForLayerSelector(state);
    const obj = objsById[objectId];
    const spec = obj != null ? specsById[obj.specId] : null;
    if (spec == null) {
      return null;
    }

    const objEvents = eventsForBusinessObjectSelector(state, objectId);

    const earliestStart = sortBy(objEvents.map((e) => e.start))[0];
    if (earliestStart == null) {
      return null;
    }
    const earliestStartMonthKey = extractMonthKey(earliestStart);

    if (earliestStartMonthKey >= monthKey) {
      return null;
    }

    // Inclusive, so subtract 1 to get the diff
    const numMonthsOffset = getNumMonthsInclusive(earliestStartMonthKey, monthKey) - 1;

    const updateEvents: EventUpdateInput[] = objEvents.map((e) => {
      const { newStart, newEnd } = getShiftedStartEnd(e, numMonthsOffset);
      const shiftedCurve = getShiftedCustomCurve(e, numMonthsOffset);
      return { id: e.id, start: newStart, end: newEnd, customCurvePoints: shiftedCurve };
    });

    return { updateEvents };
  }),
  updateBusinessObjectFieldValueCumulative:
    getMutationThunkAction<ObjectFieldValueCumulativeUpdate>((input, getState) => {
      // N.B. we ignore the default layer mutation here since it only applies for new dimensional
      // property entries, which do not have cumulative value cells. This is an artifact of the
      // migration to dimensional properties, and this exception should be removed once we
      // deprecate the old object fields.
      const { draftMutation } =
        updateBusinessObjectFieldValueCumulativeMutation(getState(), input) ?? {};
      return draftMutation ?? null;
    }),
};

const defaultEventForNewObject = (
  state: RootState,
  startDateFieldId: BusinessObjectFieldId,
  startDate: DateTime | undefined,
): EventCreateInput | undefined => {
  const user = authenticatedUserSelector(state);
  if (user == null || startDate == null) {
    return undefined;
  }

  const startDateISO = startDate.toISO();
  return {
    id: uuidv4(),
    name: 'Start Event',
    businessObjectFieldId: startDateFieldId,
    start: startDateISO,
    end: startDate.endOf('month').toISO(),
    customCurvePoints: [{ time: startDateISO, value: startDateISO }],
    setValueType: ValueType.Timestamp,
    ownerName: user.name,
  };
};

export const updateBusinessObjectMutations = ({
  state,
  updateInput,
}: {
  state: RootState;
  updateInput: BusinessObjectUpdateInput;
}) => {
  const objectId = updateInput.id;
  const objectsById = businessObjectsByIdForLayerSelector(state);
  const obj = objectsById[objectId];
  if (obj == null) {
    throw new Error('Attempted to update a non-existent object');
  }

  const spec = businessObjectSpecsByIdForLayerSelector(state)[obj.specId];
  const startDateFieldSpecId = spec.startFieldId;
  const startDateField = obj.fields?.find((f) => f.fieldSpecId === spec?.startFieldId);

  // Get the field update associated with the field that represents the
  // start date in the spec.
  const updateStartDateField = updateInput?.fields?.find((f) => f.id === startDateField?.id);

  // This currently just ignores any updates to the actuals times series
  const isUnsetStartDateFormula =
    updateStartDateField?.value?.actuals?.formula === '' &&
    startDateField?.value?.actuals.formula != null &&
    startDateField?.value?.actuals.formula !== '';
  const isUpdateStartDateFormula =
    updateStartDateField?.value?.actuals?.formula != null &&
    updateStartDateField?.value?.actuals?.formula !== '' &&
    startDateField?.value?.actuals.formula != null &&
    startDateField?.value?.actuals.formula !== '';
  const isUnsetStartDateInitialValue =
    updateStartDateField?.value?.initialValue === '' &&
    startDateField?.value?.initialValue != null &&
    startDateField?.value?.initialValue !== '';

  // We just want there to be a single event for the start field
  const existingEventsByFieldId = visibleEventsByObjectFieldIdForLayerSelector(state);
  const eventsOnStartDateField: ObjectFieldEvent[] =
    startDateField != null ? existingEventsByFieldId[startDateField.id] : [];
  const existingStartEvent =
    eventsOnStartDateField != null && eventsOnStartDateField.length > 0
      ? eventsOnStartDateField[0]
      : null;

  // Don't update the start field value for all time. We only want it to
  // update with a single event.
  const updateBusinessObject: BusinessObjectUpdateInput = {
    ...updateInput,
    fields: updateInput.fields?.filter(
      (f) =>
        f.id !== updateStartDateField?.id ||
        isUnsetStartDateFormula ||
        isUpdateStartDateFormula ||
        isUnsetStartDateInitialValue,
    ),
  };

  let updatedStartDateValue: string | undefined;
  // Don't set values for start date formula or initial value. Only use event
  let startDateFieldId = existingStartEvent == null ? startDateField?.id : undefined;
  if (startDateFieldSpecId != null) {
    const newStartDateField = updateInput.addFields?.find(
      (f) => f.fieldSpecId === startDateFieldSpecId,
    );
    const startDateFieldUpdates = [updateStartDateField, newStartDateField].filter(isNotNull);
    startDateFieldUpdates.forEach((update) => {
      if (update?.value?.actuals?.formula != null && update.value.actuals.formula !== '') {
        updatedStartDateValue = update.value.actuals.formula;
        if (
          startDateField?.value?.actuals.formula == null ||
          startDateField?.value?.actuals.formula === ''
        ) {
          update.value.actuals.formula = undefined;
        }
      }
      if (update?.value?.initialValue != null && update.value.initialValue !== '') {
        updatedStartDateValue = update?.value?.initialValue;
        update.value.initialValue = undefined;
      }
      startDateFieldId = update?.id;
    });
  }

  // MonthKey cannot be used as event start
  if (updatedStartDateValue != null && isValidMonthKey(updatedStartDateValue)) {
    updatedStartDateValue = getISOTimeWithoutMsFromMonthKey(updatedStartDateValue);
  }
  // If a field is marked as representing the "start" of an object, we
  // create a special event for it on the timeline. We need to ensure that
  // it exists or update it when the object field is updated.
  let updateEvent: EventUpdateInput | undefined;
  let deleteEvent: EventDeleteInput | undefined;
  if (existingStartEvent != null && updateStartDateField != null) {
    if (updatedStartDateValue != null) {
      const updatedStartDateTime = DateTime.fromISO(updatedStartDateValue);
      updateEvent = {
        id: existingStartEvent.id,
        customCurvePoints: [{ time: updatedStartDateTime.toISO(), value: updatedStartDateValue }],
        start: updatedStartDateValue,
        end: updatedStartDateTime.endOf('month').toISO(),
        impactType: ImpactType.Set,
      };
    } else {
      deleteEvent = { id: existingStartEvent.id };
    }
  }
  // Object field plans are wrapped in the same event group by default. We
  // ensure that it exists in the case where the above event is created.
  const mutationsToCreate = eventAndEventGroupMutationsForObject({
    state: peekMutationStateChange(state, { updateBusinessObjects: [updateBusinessObject] }),
    newEvent: {
      startDateFieldId,
      startDateValue:
        updatedStartDateValue != null ? DateTime.fromISO(updatedStartDateValue) : undefined,
    },
    withEventGroup: { type: 'default' },
  });

  const mutations = mergeMutations(
    {
      updateBusinessObjects: [updateBusinessObject],
      updateEvents: updateEvent != null ? [updateEvent] : [],
      deleteEvents: deleteEvent != null ? [deleteEvent] : [],
    },
    mutationsToCreate ?? {},
  );
  return mutations;
};

/**
 * This function takes a target business object and a reference for the new intended business object and clones
 * all events and event groups and assigns them to the new business object
 *
 * @param {state} state - The state to work from (contains the draft new business object)
 * @param {clonedObject} businessObject - the new business object you are copying the events/groups to
 * @param {targetObject} targetObject - The target business object you are grabing all events from and creating copies for the cloned object
 */
export const cloneEventsAndEventGroupFromObject = ({
  state,
  clonedObject,
  targetObject,
}: {
  state: RootState;
  clonedObject: BusinessObject;
  targetObject: BusinessObjectCreateInput;
}): {
  newDefaultEventGroupForObjectUpdates?: EventGroupId;
  mutationsToCreate: Partial<DatasetMutationInput>;
} => {
  const events = eventsForBusinessObjectSelector(state, clonedObject.id);
  const user = authenticatedUserSelector(state);

  if (user == null) {
    throw new Error(`Expected logged in user`);
  }

  /**
   * The original objects events all point to fields on the original object
   * We need to create a mapping of original field id -> specId -> new field id
   **/
  const specToFieldLookup: { [key: string]: string } = {};

  clonedObject.fields.forEach((field) => {
    const { id, fieldSpecId } = field;
    const matchingField = targetObject.fields.find(
      (targetField) => targetField.fieldSpecId === fieldSpecId,
    );

    if (matchingField) {
      specToFieldLookup[id] = matchingField.id;
    }
  });

  // Update relevent fields and convert the event into a event input for backend
  const newEvents: EventCreateInput[] = events.map((event) =>
    createEventInputFromEvent({
      ...event,
      ownerName: user.name,
      id: uuidv4(),
      businessObjectFieldId: specToFieldLookup[event.businessObjectFieldId],
    }),
  );

  const mutations = createNewEventMutationsBatched({
    state,
    newEvents,
    withEventGroup: {
      type: 'default',
    },
  });

  return {
    mutationsToCreate: {
      ...mutations,
    },
  };
};

/**
 * This function generates a set of additional mutations that are necessary when creating or updating a business object.
 * These mutations ensure the following properties remain consistent:
 *
 * Impacts on business objects are organized under a hierarchy of event groups by default.
 * All impacts to a single object's fields are organized under a single event group for that object.
 * All of the event groups for objects belonging to a single spec are organized under that spec's default event group.
 *
 * @param {BusinessObjectSpec} spec - The object spec for the object being mutated
 * @param {BusinessObject} businessObject - An optional param for the object being mutated if it already exists
 * @param {BusinessObjectFieldId} startDateFieldId - An optional param for the object's start date field, if it exists.
 * Note that not all objects have a start date field. This should only be passed to create a new event on the start date field.
 * @param {DateTime} startDateValue - An optional param for the value of the object's start date field, if it both exists and is populated.
 */
const eventAndEventGroupMutationsForObject = ({
  state,
  newEvent,
  withEventGroup,
}: {
  state: RootState;
  newEvent: {
    startDateFieldId?: BusinessObjectFieldId;
    startDateValue?: DateTime;
  };
  withEventGroup: WithEventGroup;
}): DatasetMutationInput | undefined => {
  const user = authenticatedUserSelector(state);
  const eventsByFieldId = visibleEventsByObjectFieldIdForLayerSelector(state);
  if (user == null) {
    throw new Error(`Expected logged in user`);
  }

  const { startDateFieldId, startDateValue } = newEvent;

  let startDateEvent: EventCreateInput | undefined;
  if (startDateFieldId != null && eventsByFieldId[startDateFieldId] == null) {
    startDateEvent = defaultEventForNewObject(state, startDateFieldId, startDateValue);
  }

  const mutations =
    startDateEvent != null
      ? createNewEventMutations({
          state,
          newEvent: startDateEvent,
          withEventGroup,
        })
      : {};

  return mutations;
};

// Returned mutation creates a new business object for each given spec
export const createBusinessObjectsWithEventsForSpecsMutations = ({
  state,
  specIds,
  defaultFieldValuesByFieldSpecId,
  withEventGroup,
}: {
  state: RootState;
  specIds: BusinessObjectSpecId[];
  defaultFieldValuesByFieldSpecId: Record<BusinessObjectFieldSpecId, NullableValue>;
  withEventGroup: WithEventGroup;
}): Partial<DatasetMutationInput> => {
  let newMutations: DatasetMutationInput = {};

  specIds.forEach((specId) => {
    newMutations = mergeMutations(
      createBusinessObjectWithEventsMutations({
        state,
        withEventGroup,
        newObject: {
          specId,
          objectId: uuidv4(),
          objectName: getDefaultNameForObject({ state, objectSpecId: specId }),
          defaultFieldValuesByFieldSpecId,
        },
      }),
      newMutations,
    );
  });

  return newMutations;
};

export const createBusinessObjectWithEventsMutations = ({
  state,
  newObject,
  withEventGroup,
}: {
  state: RootState;
  newObject: {
    specId: BusinessObjectSpecId;
    objectId: BusinessObjectId;
    objectName: string;
    defaultFieldValuesByFieldSpecId: Record<BusinessObjectFieldSpecId, NullableValue>;
  };
  withEventGroup: WithEventGroup;
}): Partial<DatasetMutationInput> => {
  const spec = businessObjectSpecSelector(state, newObject.specId);
  if (spec == null) {
    throw new Error(`Attempted to create object with invalid specId: ${newObject.specId}`);
  }

  const fields = initialValuesForFields(spec, newObject.defaultFieldValuesByFieldSpecId);

  // there isn't a way to create driver properties with default values yet
  const collectionEntry = valuesForDimensionalProperties(
    spec,
    newObject.defaultFieldValuesByFieldSpecId ?? {},
  );

  return getMutationInputForCreateBusinessObject({
    state,
    newObject: {
      id: newObject.objectId,
      name: newObject.objectName,
      fields,
      specId: spec.id,
      collectionEntry,
    },
    withEventGroup,
  });
};

export const getMutationInputForCreateBusinessObject = ({
  state,
  newObject,
  withEventGroup,
}: {
  state: RootState;
  newObject: BusinessObjectCreateInput;
  withEventGroup: WithEventGroup;
}): Partial<DatasetMutationInput> => {
  const specsById = businessObjectSpecsByIdForLayerSelector(state);
  const spec = specsById[newObject.specId];

  const startDateField = newObject.fields.find((f) => f.fieldSpecId === spec.startFieldId);
  const startDateStringValue = startDateField?.value.initialValue;
  const startDateValue =
    startDateStringValue != null ? DateTime.fromISO(startDateStringValue) : undefined;

  const mutationsToCreate = eventAndEventGroupMutationsForObject({
    state: peekMutationStateChange(state, { newBusinessObjects: [{ ...newObject }] }),
    newEvent: {
      startDateFieldId: startDateField?.id,
      startDateValue,
    },
    withEventGroup,
  });

  const newMutations = mergeMutations(
    {
      newBusinessObjects: [
        {
          ...newObject,
        },
      ],
    },
    mutationsToCreate ?? {},
  );

  return newMutations;
};

const deleteBusinessObjectMutations = (
  state: RootState,
  id: BusinessObjectId,
): DatasetMutationInput => {
  const obj = businessObjectsByIdForLayerSelector(state)[id];
  const events = eventWithDirectImpactForObjectSelector(state, id);
  const eventGroup = obj != null ? obj.defaultEventGroupId : undefined;
  return {
    deleteBusinessObjects: [{ id }],
    deleteEventGroups: eventGroup != null ? [{ id: eventGroup }] : undefined,
    deleteEvents: [
      ...events.map((e) => {
        return { id: e.id };
      }),
    ],
  };
};

const initialValuesForFields = (
  spec: BusinessObjectSpec,
  defaultFieldValuesById: Record<BusinessObjectFieldSpecId, NullableValue>,
): BusinessObjectFieldInput[] =>
  spec.fields
    .map((fieldSpec) => {
      const defaultValue =
        defaultFieldValuesById[fieldSpec.id] != null
          ? defaultFieldValuesById[fieldSpec.id]
          : defaultValueForField(fieldSpec);
      const initialValue = defaultValue.value != null ? String(defaultValue.value) : undefined;
      if (initialValue == null && !fieldSpec.isFormula) {
        return null;
      }
      return {
        id: uuidv4(),
        fieldSpecId: fieldSpec.id,
        value: {
          type: fieldSpec.type,
          initialValue,
        },
      };
    })
    .filter(isNotNull);

const valuesForDimensionalProperties = (
  spec: BusinessObjectSpec,
  defaultFieldValuesById: Record<BusinessObjectFieldSpecId, NullableValue>,
): CollectionEntryCreateInput => ({
  attributeProperties: (spec.collection?.dimensionalProperties ?? [])
    .map((fieldSpec) => {
      const defaultValue = defaultFieldValuesById[fieldSpec.id];
      const value = defaultValue?.value != null ? String(defaultValue.value) : undefined;
      if (value == null) {
        return null;
      }
      return {
        attributeId: value,
        dimensionalPropertyId: fieldSpec.id,
      };
    })
    .filter(isNotNull),
});

export const renameBusinessObject =
  ({ id, newName }: { id: BusinessObjectId; newName: string }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const name = businessObjectNameSelector(state, id);
    if (name !== newName && newName !== '') {
      dispatch(updateBusinessObject({ id, name: newName }));
    }
  };

export interface ObjectFieldValueCumulativeUpdate {
  newValue: NullableValue;
  objectSpecId: BusinessObjectSpecId;
  objectId: BusinessObjectId;
  fieldSpecId: BusinessObjectFieldSpecId;
  blockId: BlockId;
  shouldSkipDriverUpdates?: boolean;
}

type BusinessObjectFieldValueCumulativeMutation = {
  draftMutation: DatasetMutationInput;
  defaultLayerMutation?: DatasetMutationInput;
};

export const updateBusinessObjectFieldValueCumulativeMutation = (
  state: RootState,
  {
    newValue,
    objectId,
    fieldSpecId,
    blockId,
    shouldSkipDriverUpdates = false,
  }: ObjectFieldValueCumulativeUpdate,
): BusinessObjectFieldValueCumulativeMutation | null => {
  const field = materializedFieldForSpecIdAndObjectIdSelector(state, {
    businessObjectFieldSpecId: fieldSpecId,
    businessObjectId: objectId,
  });
  const obj = businessObjectSelector(state, objectId);
  if (obj == null) {
    throw new Error('Attempted to update a non-existent object');
  }
  const spec = businessObjectSpecsByIdForLayerSelector(state)[obj.specId];

  const dimProperty = dimensionalPropertySelector(state, fieldSpecId);
  if (dimProperty != null) {
    if (newValue.type !== ValueType.Attribute) {
      return null;
    }
    const input = {
      objectId,
      newAttributeId: isEmpty(newValue.value) ? null : newValue.value ?? null,
      dimensionalPropertyId: fieldSpecId,
    };
    const mutation: BusinessObjectFieldValueCumulativeMutation = {
      defaultLayerMutation: undefined,
      draftMutation: {
        updateBusinessObjects: [updateDimensionalPropertyEntryMutation(state, input)],
      },
    };

    if (shouldSkipDriverUpdates || !dimProperty.isDatabaseKey) {
      return mutation;
    }

    // Only want to update the database property subdrivers when editing dimension property keys
    const updateDrivers = updateDriversFromDimensionalPropertyChangeMutation(state, {
      ...input,
      objectSpecId: obj.specId,
      shouldSkipAttrValidation: false,
      isNewDBKey: true,
    });

    mutation.draftMutation = mergeMutations(mutation.draftMutation, { updateDrivers });
    return mutation;
  }

  const startDateFieldSpecId = spec.startFieldId;
  const transitionValue = businessObjectFieldTransitionValueSelector(state, {
    businessObjectId: objectId,
    businessObjectFieldSpecId: fieldSpecId,
    blockId,
  });
  if (transitionValue == null) {
    return null;
  }
  const fieldValue = transitionValue.originalValue;
  if (newValue.type !== fieldValue.type) {
    return null;
  }

  let initialValue: string | undefined;
  const { type } = newValue;

  if (type === ValueType.Attribute || type === ValueType.Timestamp) {
    initialValue = newValue.value;
  } else if (type === ValueType.Number && newValue.value != null) {
    initialValue = String(newValue.value);
  }

  if (field == null) {
    const value: BusinessObjectFieldValueInput = {
      // '' is not a valid value for initial value - it is used in updates to specify that the value is being unset
      initialValue: initialValue == null || initialValue === '' ? undefined : initialValue,
      type,
    };
    return {
      draftMutation: updateBusinessObjectMutations({
        state,
        updateInput: {
          id: objectId,
          addFields: [
            {
              id: getObjectFieldUUID(obj.id, fieldSpecId),
              fieldSpecId,
              value,
            },
          ],
        },
      }),
    };
  } else {
    if (fieldSpecId === startDateFieldSpecId && initialValue == null) {
      initialValue = '';
    }
    // Start date updates will end up adding an event. Set formula to trigger that
    // If has formula update formula otherwise set intial value
    if (field.value?.actuals.formula != null && field.value.actuals.formula !== '') {
      return {
        draftMutation: updateBusinessObjectMutations({
          state,
          updateInput: {
            id: objectId,
            fields: [{ id: field.id, value: { actuals: { formula: initialValue }, type } }],
          },
        }),
      };
    }
    return {
      draftMutation: updateBusinessObjectMutations({
        state,
        updateInput: {
          id: objectId,
          fields: [{ id: field.id, value: { initialValue: initialValue ?? '', type } }],
        },
      }),
    };
  }
};

export const deleteSelectedBusinessObjects =
  (ids: BusinessObjectId[]): AppThunk =>
  (dispatch) => {
    dispatch(cellNavigate('down'));
    dispatch(
      deleteBusinessObjects(
        ids.map((id) => {
          return { id };
        }),
      ),
    );
  };

export const updateBusinessObjectFieldInitialValue =
  ({
    objectId,
    fieldSpecId,
    value,
  }: {
    objectId: BusinessObjectId;
    fieldSpecId: BusinessObjectFieldSpecId;
    value: NullableValue;
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const field = materializedFieldForSpecIdAndObjectIdSelector(state, {
      businessObjectId: objectId,
      businessObjectFieldSpecId: fieldSpecId,
    });
    if (field == null) {
      const initialVaue = value.value?.toString();
      const newField: BusinessObjectFieldInput = {
        fieldSpecId,
        id: uuidv4(),
        value: {
          // '' is not a valid value for initial value - it is used in updates to specify that the value is being unset
          initialValue: initialVaue == null || initialVaue === '' ? undefined : initialVaue,
          type: value.type,
        },
      };
      dispatch(
        updateBusinessObject({
          id: objectId,
          addFields: [newField],
        }),
      );
    } else {
      const fieldUpdate: BusinessObjectFieldUpdateInput = {
        id: field.id,
        value: {
          initialValue: value.value?.toString(),
          type: value.type,
        },
      };
      dispatch(
        updateBusinessObject({
          id: objectId,
          fields: [fieldUpdate],
        }),
      );
    }
  };

export const deleteBusinessObjectFieldInitialValues =
  (input: { objectId: BusinessObjectId; fieldSpecId: BusinessObjectFieldSpecId }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const field = materializedFieldForSpecIdAndObjectIdSelector(state, {
      businessObjectId: input.objectId,
      businessObjectFieldSpecId: input.fieldSpecId,
    });
    const fieldSpec = businessObjectFieldSpecSelector(state, { id: input.fieldSpecId });
    if (field == null || fieldSpec == null) {
      return;
    }
    const fieldUpdate: BusinessObjectFieldUpdateInput = {
      id: field.id,
      value: {
        initialValue: '',
        type: fieldSpec.type,
      },
    };
    dispatch(
      updateBusinessObject({
        id: input.objectId,
        fields: [fieldUpdate],
      }),
    );
  };

export const deleteBusinessObjectFieldValues =
  (
    inputs: Array<{
      objectId: BusinessObjectId;
      fieldSpecId: BusinessObjectFieldSpecId;
      monthKey: MonthKey;
    }>,
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const updates = inputs
      .map((input) => {
        const field = materializedFieldForSpecIdAndObjectIdSelector(state, {
          businessObjectId: input.objectId,
          businessObjectFieldSpecId: input.fieldSpecId,
        });
        if (field == null) {
          return undefined;
        }
        return {
          objectId: input.objectId,
          fieldSpecId: input.fieldSpecId,
          values: { [input.monthKey]: undefined },
        };
      })
      .filter(isNotNull);

    dispatch(updateBusinessObjectFieldActuals(updates));
  };

export const updateBusinessObjectFieldTimeSeriesMutation = (
  state: RootState,
  inputs: Array<{
    fieldSpecId: BusinessObjectFieldSpecId;
    objectId: BusinessObjectId;
    values: ValueTimeSeriesWithEmpty;
    eventGroupId?: EventGroupId;
  }>,
): DatasetMutationInput | null => {
  const mergedMutation: DatasetMutationInput = {};
  return inputs.reduce((mutation, input) => {
    const { fieldSpecId, objectId, values, eventGroupId } = input;
    const lastActualsMonthKey = lastActualsMonthKeyForLayerSelector(state);

    const field = materializedFieldForSpecIdAndObjectIdSelector(state, {
      businessObjectId: objectId,
      businessObjectFieldSpecId: fieldSpecId,
    });
    const fieldSpec = businessObjectFieldSpecSelector(state, { id: fieldSpecId });
    if (fieldSpec == null) {
      return mutation;
    }

    let updateBusinessObject: BusinessObjectUpdateInput | undefined;
    const actualsValues: ValueTimeSeriesWithEmpty = pickBy(
      values,
      (_value, monthKey) => monthKey <= lastActualsMonthKey,
    );
    const id = field?.id ?? getObjectFieldUUID(objectId, fieldSpecId);
    let peekState = state;
    if (field == null) {
      updateBusinessObject = {
        id: objectId,
        addFields: [
          {
            id,
            fieldSpecId,
            value: {
              type: fieldSpec.type,
              actuals: {
                timeSeries: convertTimeSeriesToGql(actualsValues),
              },
            },
          },
        ],
      };
      peekState = peekMutationStateChange(peekState, {
        updateBusinessObjects: [updateBusinessObject],
      });
    } else {
      const updatedActualsTimeSeries = Object.entries(actualsValues)
        .map(([monthKey, value]) => {
          const originalPoint = field.value?.actuals.timeSeries?.[monthKey];
          // Updating to existing value
          if (originalPoint === value) {
            return null;
          }
          if (value == null) {
            return {
              time: getISOTimeWithoutMsFromMonthKey(monthKey),
              value: '',
            };
          }
          return {
            time: getISOTimeWithoutMsFromMonthKey(monthKey),
            value: String(value.value),
          };
        })
        .filter(isNotNull);

      if (updatedActualsTimeSeries.length > 0) {
        const fieldUpdate: BusinessObjectFieldUpdateInput = {
          id,
          value: {
            type: fieldSpec.type,
            actuals: {
              ...field.value?.actuals,
              timeSeries: updatedActualsTimeSeries,
            },
          },
        };

        updateBusinessObject = {
          id: objectId,
          fields: [fieldUpdate],
        };
      }
    }
    let eventUpdate: DatasetMutationInput | undefined;
    const futureValues = pickBy(values, (_value, monthKey) => monthKey > lastActualsMonthKey);
    if (!isEmpty(futureValues)) {
      // Use peekState when adding event because you need newly created fields
      eventUpdate = updateObjectFieldForecastMutation(peekState, id, futureValues, eventGroupId);
      mutation = mergeMutations(mutation, eventUpdate);
    }

    return mergeMutations(mutation, {
      updateBusinessObjects: updateBusinessObject != null ? [updateBusinessObject] : undefined,
    });
  }, mergedMutation);
};

export const updateBusinessObjectFieldActuals =
  (
    inputs: Array<{
      objectId: BusinessObjectId;
      fieldSpecId: BusinessObjectFieldSpecId;
      values: ValueTimeSeriesWithEmpty;
    }>,
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const mutation = updateBusinessObjectFieldTimeSeriesMutation(state, inputs);

    if (mutation == null) {
      return;
    }

    dispatch(submitAutoLayerizedMutations('update-business-object-field-actuals', [mutation]));
  };

export const createBusinessObjects = ({
  objects,
}: {
  objects: BusinessObjectCreateInput[];
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    let newMutations: DatasetMutationInput = {};
    objects.forEach((input) => {
      newMutations = mergeMutations(
        getMutationInputForCreateBusinessObject({
          state,
          newObject: input,
          withEventGroup: { type: 'default' },
        }),
        newMutations,
      );
    });

    submitAutoLayerizedMutations('create-business-objects', [newMutations]);
  };
};

const createBusinessObjectDriverUpdates = (
  state: RootState,
  {
    objectSpec,
    defaultFieldValuesByFieldSpecId,
  }: {
    objectSpec: BusinessObjectSpec;
    defaultFieldValuesByFieldSpecId: Record<DimensionalPropertyId, NullableValue>;
  },
): DriverUpdateInput[] => {
  const specId = objectSpec.id;
  const dimDriverUpdates: Record<string, DriverUpdateInput> = {};

  const dimensionalPropertyEvaluator = dimensionalPropertyEvaluatorSelector(state);
  const dimensionalProperties = businessObjectSpecDimensionalPropertyKeysSelector(state, specId);
  const driverProperties = objectSpec.collection?.driverProperties ?? [];
  const dimensionsById = dimensionsByIdSelector(state);

  if (dimensionalProperties.length > 0 && driverProperties.length > 0) {
    const keyAttributes: Attribute[] = [];

    for (const keyProperty of dimensionalProperties) {
      if (keyProperty.dimension.deleted) {
        continue;
      }

      const defaultValue = defaultFieldValuesByFieldSpecId[keyProperty.id];
      if (defaultValue.type === ValueType.Attribute && defaultValue.value != null) {
        const dimension = dimensionsById[keyProperty.dimension.id];
        const attribute = dimension.attributes.find((attr) => attr.id === defaultValue.value);
        if (attribute == null || attribute.deleted) {
          continue;
        }

        keyAttributes.push(attribute);
      }
    }

    for (const driverProperty of driverProperties) {
      const dimDriver = driversByIdForLayerSelector(state)[driverProperty.driverId];
      if (dimDriver == null) {
        continue;
      }

      const { existingTargetSubDriverId } = getExistingSubDrivers({
        targetDriver: dimDriver,
        dimensionalPropertyEvaluator,
        subDriversByDriverId: subDriversByDriverIdSelector(state),
        attributes: keyAttributes,
      });
      if (existingTargetSubDriverId != null) {
        continue;
      }

      // TOOD: is there a default formula that should be applied?
      // See formula editor wrapper.
      const subDriverId = uuidv4();
      const newSubDriverInput: DriverCreateInput = {
        id: subDriverId,
        name: dimDriver.name,
        valueType: ValueType.Number,
        driverType: DriverType.Basic,
        format: DriverFormat.Number,
        basic: {
          actuals: {
            formula: '',
          },
          forecast: {
            formula: '',
          },
        },
      };

      // Add subdriver to dimensional driver.
      const newSubDriverCreateInput: DimensionalSubDriverCreateInput = {
        attributeIds: keyAttributes.map((attr) => attr.id),
        driver: newSubDriverInput,
      };
      dimDriverUpdates[dimDriver.id] ??= { id: dimDriver.id, newSubDrivers: [] };
      dimDriverUpdates[dimDriver.id].newSubDrivers!.push(newSubDriverCreateInput);
    }
  }

  return Object.values(dimDriverUpdates);
};

export const createBusinessObjectWithDefaults = ({
  objectId,
  blockId,
  specId,
  groupInfo,
}: {
  objectId: BusinessObjectId;
  blockId: BlockId;
  specId: BusinessObjectSpecId;
  groupInfo: GroupInfo;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    const objectSpec = businessObjectSpecSelector(state, specId);
    if (objectSpec == null) {
      throw new Error('expected object spec to exist');
    }

    const blockConfigGroupBy = blockConfigGroupBySelector(state, blockId);
    const groupByFieldSpecId = blockConfigGroupBy?.objectField?.businessObjectFieldId;
    const objectName = getDefaultNameForObject({
      state,
      objectSpecId: objectSpec.id,
      groupInfo,
    });

    const defaultFieldValuesByFieldSpecId = getDefaultFieldValuesByFieldSpecId({
      objectSpec,
      groupInfo,
      objectTableGroupByFieldSpecId: groupByFieldSpecId,
      objectTableFilterDefaultValueByFieldSpecId: getFilterDefaultValueByFieldSpecId(getState(), {
        blockId,
        objectSpecId: objectSpec.id,
      }),
    });

    const createObjectMutation = createBusinessObjectWithEventsMutations({
      state,
      newObject: {
        specId,
        objectId,
        objectName,
        defaultFieldValuesByFieldSpecId,
      },
      withEventGroup: {
        type: 'default',
      },
    });

    const updateDrivers = enableEagerSubDriverInitializationSelector(state)
      ? createBusinessObjectDriverUpdates(state, {
          objectSpec,
          defaultFieldValuesByFieldSpecId,
        })
      : [];

    dispatch(
      submitAutoLayerizedMutations('create-business-object', [
        { updateDrivers },
        createObjectMutation,
      ]),
    );

    dispatch(setAutoFocus({ type: 'object', id: objectId, blockId }));
    dispatch(
      selectSingleCell({
        blockId,
        cellRef: {
          rowKey: { objectId, groupKey: groupInfo.key },
          type: CellType.ObjectField,
          columnKey: OBJECT_FIELD_NAME_COLUMN_KEY,
        },
      }),
    );
  };
};

export const {
  deleteBusinessObjects,
  updateBusinessObject,
  updateBusinessObjects,
  linkExistingObjectToSyncedObject,
  reforecastEventsToMonth,
  updateBusinessObjectFieldValueCumulative,
} = mutationActions;
