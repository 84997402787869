import { Flex, Text } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import { useMemo } from 'react';

import { useComparedLayers } from '@features/CompareScenarios';
import {
  STATUS_TO_COLOR,
  ToggleSection,
} from '@features/CompareScenarios/blocks/common/ToggleSection';
import { useCalculations } from '@features/CompareScenarios/blocks/common/useCalculations';
import { ConfigChangeBlock } from '@features/CompareScenarios/blocks/DatabaseBlock/ConfigChangeBlock';
import {
  MAX_BUSINESS_OBJECTS,
  RowChangesByStatusBlock,
} from '@features/CompareScenarios/blocks/DatabaseBlock/RowChangesBlock';
import { SchemaChangesBlock } from '@features/CompareScenarios/blocks/DatabaseBlock/SchemaChangesBlock';
import {
  BusinessObjectChange,
  DatabaseChange,
} from '@features/CompareScenarios/comparators/DatabaseComparator';
import { ChangeStatus } from '@features/CompareScenarios/compareLayers';
import Badge from 'components/Badge/Badge';
import BlockContext from 'components/BlockContainer/BlockContext';
import EmojiIcon from 'components/EmojiWidget/EmojiIcon';
import { FormulaEntityType } from 'generated/graphql';
import { extractEmoji } from 'helpers/emoji';
import useAppSelector from 'hooks/useAppSelector';
import { useScenarioComparisonBlock } from 'hooks/useScenarioComparisonBlock';
import { BusinessObjectFieldId } from 'reduxStore/models/businessObjects';
import { scenarioComparisonPageDatabaseBlockIdSelector } from 'selectors/scenarioComparisonSelector';
import { Group, Table } from 'vectors';

export const DatabasesBlock = ({ changes }: { changes: DatabaseChange[] }) => {
  const { currentLayer, mergeLayer } = useComparedLayers();
  const changedObjectFieldIds = useChangedObjectFieldIds(changes);
  useCalculations(
    { type: FormulaEntityType.ObjectField, ids: changedObjectFieldIds },
    currentLayer,
    mergeLayer,
  );
  const databaseBlockId = useAppSelector(scenarioComparisonPageDatabaseBlockIdSelector);
  const databaseBlockCtx = useScenarioComparisonBlock(databaseBlockId ?? '');

  if (changes.length === 0) {
    return null;
  }

  return (
    <ToggleSection
      heading="Database updates"
      textProps={{ fontSize: 'md', fontWeight: '600' }}
      sectionProps={{ marginY: 2 }}
      isDefaultOpen
      data-testid="databases-toggle-section"
    >
      <BlockContext {...databaseBlockCtx}>
        <Flex flexDirection="column">
          {changes.map((change, i) => {
            return (
              <DatabaseBlock
                key={`compare_scenarios_modal-${change.object.id}`}
                change={change}
                index={i}
              />
            );
          })}
        </Flex>
      </BlockContext>
    </ToggleSection>
  );
};

// NOTE: we want to issue calculations for only the fields that we'll be seeing across all databases
function useChangedObjectFieldIds(changes: DatabaseChange[]): BusinessObjectFieldId[] {
  return useMemo(() => {
    const fieldIds = new Set<BusinessObjectFieldId>();
    for (const change of changes) {
      const changesByStatus: Record<ChangeStatus, BusinessObjectChange[]> = {
        created: [],
        updated: [],
        deleted: [],
      };

      for (const rowChange of change.updatesByField.businessObjects?.changes ?? []) {
        if (
          rowChange.status != null &&
          changesByStatus[rowChange.status].length < MAX_BUSINESS_OBJECTS
        ) {
          changesByStatus[rowChange.status].push(rowChange);
        }
      }

      for (const rowChange of [
        ...changesByStatus.created,
        ...changesByStatus.updated,
        ...changesByStatus.deleted,
      ]) {
        for (const fieldChange of rowChange.updatesByField.fields?.changes ?? []) {
          fieldIds.add(fieldChange.object.field.id);
        }
      }
    }
    return [...fieldIds];
  }, [changes]);
}

const DatabaseBlock = ({ change, index }: { change: DatabaseChange; index: number }) => {
  const [emoji, name] = extractEmoji(change.object.name);
  const rowChangeCount = change.updatesByField?.businessObjects?.changes?.length;

  return (
    <ToggleSection
      isToggleable={Object.keys(change.updatesByField).length > 0}
      heading={
        <>
          <Flex>
            <EmojiIcon emoji={emoji} size="sm" />
            <Text fontSize="sm" fontWeight="600" marginLeft="1">
              {name}
            </Text>
          </Flex>
          <Flex gap="1" alignItems="center">
            {change.status != null && change.status !== 'updated' && (
              <Badge
                size="xs"
                fontSize="xxs"
                borderRadius="md"
                theme={STATUS_TO_COLOR[change.status]}
                text={change.status === 'created' ? 'New' : capitalize(change.status)}
              />
            )}
            {change.updatesByField.schema != null && (
              <Flex alignItems="center">
                <Table color="gray.500" />
              </Flex>
            )}
            {rowChangeCount != null && (
              <Flex alignItems="center">
                <Group color="gray.500" />
                <Text fontSize="xs" fontWeight="500" color="gray.500" marginLeft="1">
                  {rowChangeCount}
                </Text>
              </Flex>
            )}
          </Flex>
        </>
      }
      showBorderTop={index === 0}
      showBorderBottom
      sectionProps={{ paddingLeft: 6 }}
    >
      {(change.updatesByField.config != null || change.updatesByField.schema != null) && (
        <Flex flexDirection="column" rowGap={3} overflowX="scroll">
          {change.updatesByField.config != null && (
            <ConfigChangeBlock change={change.updatesByField.config.change} />
          )}
          {change.updatesByField.schema != null && (
            <SchemaChangesBlock changes={change.updatesByField.schema.changes} />
          )}
        </Flex>
      )}
      {change.updatesByField.businessObjects != null && (
        <RowChangesByStatusBlock changes={change.updatesByField.businessObjects.changes} />
      )}
    </ToggleSection>
  );
};
