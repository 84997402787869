import { usePopoverContext } from '@chakra-ui/react';
import React, { useCallback } from 'react';

import { DriverPropertyContextMenuContent } from 'components/DriverContextMenu/DriverContextMenu';
import RowOptions from 'components/RowOptions/RowOptions';
import { DriverCellRef } from 'config/cells';
import { NAME_COLUMN_TYPE } from 'config/modelView';
import useAppDispatch from 'hooks/useAppDispatch';
import useBlockContext from 'hooks/useBlockContext';
import useDriverCellRef from 'hooks/useDriverCellRef';
import { selectCellIfUnselected } from 'reduxStore/actions/cellSelection';
import { BlockId } from 'reduxStore/models/blocks';

interface DriverRowOptionsContentProps {
  cellRef: DriverCellRef;
  blockId: BlockId;
}

const DriverRowOptionsContent: React.FC<DriverRowOptionsContentProps> = ({ cellRef, blockId }) => {
  const { onClose, isOpen } = usePopoverContext();
  if (!isOpen) {
    return null;
  }

  return (
    <DriverPropertyContextMenuContent cellRef={cellRef} closeMenu={onClose} blockId={blockId} />
  );
};

const DriverRowOptions: React.FC<{ draggable?: boolean }> = ({ draggable = true }) => {
  const dispatch = useAppDispatch();
  const cellRef = useDriverCellRef({ columnType: NAME_COLUMN_TYPE, columnLayerId: undefined });
  const { blockId } = useBlockContext();
  const onOpen = useCallback(() => {
    dispatch(selectCellIfUnselected(blockId, cellRef));
  }, [blockId, dispatch, cellRef]);

  return (
    <RowOptions
      draggable={draggable}
      onOpen={onOpen}
      ariaLabel="Drag to move or click to open menu"
    >
      <DriverRowOptionsContent cellRef={cellRef} blockId={blockId} />
    </RowOptions>
  );
};

export default React.memo(DriverRowOptions);
