import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';
import { useMemo } from 'react';

import { useComparedLayers } from '@features/CompareScenarios';
import { ChangeCell } from '@features/CompareScenarios/blocks/common/ChangeCell';
import {
  STATUS_TO_COLOR,
  ToggleSection,
} from '@features/CompareScenarios/blocks/common/ToggleSection';
import { LayerName } from '@features/CompareScenarios/common/LayerName';
import {
  DriverChange,
  DriverPropertyChange,
} from '@features/CompareScenarios/comparators/DriverComparator';
import AttributeBadge from 'components/AttributeBadge/AttributeBadge';
import Badge from 'components/Badge/Badge';
import { safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import { Attribute } from 'reduxStore/models/dimensions';
import {
  attributesBySubDriverIdSelector,
  pseudoAttributeSelectorBySubDriverIdSelector,
} from 'selectors/driversSelector';
import { Delta } from 'vectors';

const MAX_DRIVERS_TO_SHOW_IN_PROPERTIES = 50;

export const DriverPropertyChangesBlock = ({ changes }: { changes: DriverChange[] }) => {
  const { currentLayer, mergeLayer } = useComparedLayers();
  const { currentLayerId, mergeLayerId } = useMemo(() => {
    return {
      currentLayerId: { layerId: currentLayer.id },
      mergeLayerId: { layerId: mergeLayer.id },
    };
  }, [currentLayer.id, mergeLayer.id]);

  const attributesByLayerAndDriverId = useAppSelector((state) => {
    return {
      currentLayer: attributesBySubDriverIdSelector(state, currentLayerId),
      mergeLayer: attributesBySubDriverIdSelector(state, mergeLayerId),
    };
  });

  const driverChanges = useMemo(() => {
    const relevantDriverChanges: DriverChange[] = [];
    for (const change of changes) {
      if (change.updatesByField.properties != null || change.status === 'deleted') {
        relevantDriverChanges.push(change);
      }
    }
    return relevantDriverChanges;
  }, [changes]);

  if (driverChanges.length === 0) {
    return null;
  }

  return (
    <ToggleSection
      heading={
        <Flex alignItems="baseline">
          <Text fontSize="sm" fontWeight="600" marginRight="2">
            Property changes
          </Text>
          <Flex fontSize="xs" fontWeight="medium" color="gray.500" gap="2px" alignItems="baseline">
            <Delta alignSelf="center" />
            <Text>{driverChanges.length}</Text>
          </Flex>
        </Flex>
      }
      fontSize="sm"
      sectionProps={{ paddingLeft: 6 }}
      data-testid="property-changes-toggle-section"
    >
      <Flex flexDirection="column" marginY="1">
        {driverChanges.map((change, i) => {
          const remaining = driverChanges.length - i;
          if (i + 1 === MAX_DRIVERS_TO_SHOW_IN_PROPERTIES) {
            return (
              <Text
                key={`compare_scenarios_modal-drivers-${change.object.id}`}
                fontSize="xs"
                color="gray.500"
                fontWeight="medium"
              >
                {remaining} {pluralize('driver', remaining)} are not shown
              </Text>
            );
          }

          if (i + 1 > MAX_DRIVERS_TO_SHOW_IN_PROPERTIES) {
            return null;
          }

          const attributes =
            safeObjGet(attributesByLayerAndDriverId.currentLayer[change.object.id]) ??
            safeObjGet(attributesByLayerAndDriverId.mergeLayer[change.object.id]) ??
            [];

          return (
            <PropertyChangesToggleBlock
              key={`compare_scenarios_modal-drivers-${change.object.id}`}
              change={change}
              showBorderTop={i === 0}
              attributes={attributes}
            />
          );
        })}
      </Flex>
    </ToggleSection>
  );
};

const PropertyChangesToggleBlock = ({
  change,
  showBorderTop,
  attributes,
}: {
  change: DriverChange;
  showBorderTop: boolean;
  attributes: Attribute[];
}) => {
  const pseudoAttributes =
    useAppSelector((state) =>
      pseudoAttributeSelectorBySubDriverIdSelector(state, change.object.id),
    ) ?? [];
  const allAttributes = [...attributes, ...pseudoAttributes];

  return (
    <ToggleSection
      isDefaultOpen
      heading={
        <>
          <Flex fontSize="xs" fontWeight="600" marginRight="2" gap="1" align="center">
            {change.object.name}
            {allAttributes.length > 0 && (
              <Flex gap="1" alignItems="center">
                {allAttributes.map((attribute) => {
                  return (
                    <AttributeBadge
                      key={`compare_scenarios_modal-drivers-${change.object.id}-${attribute.id}`}
                      attribute={attribute}
                    />
                  );
                })}
              </Flex>
            )}
          </Flex>
          {change.status != null && change.status !== 'updated' && (
            <Badge
              theme={STATUS_TO_COLOR[change.status]}
              borderRadius="md"
              size="xs"
              text={change.status === 'created' ? 'New' : capitalize(change.status)}
            />
          )}
        </>
      }
      fontSize="xs"
      sectionProps={{ overflowX: 'scroll', paddingLeft: 2 }}
      isNotRenderedUntilOpen
      isToggleable={change.updatesByField.properties != null}
      showBorderTop={showBorderTop}
      showBorderBottom
    >
      {change.updatesByField.properties != null && (
        <PropertyChangesBlock change={change.updatesByField.properties.change} />
      )}
    </ToggleSection>
  );
};

const PropertyChangesBlock = ({ change }: { change: DriverPropertyChange }) => {
  const updates = useMemo(() => Object.values(change.updatesByField).flat(), [change]);
  const { currentLayer, mergeLayer } = useComparedLayers();

  return (
    <Box borderColor="gray.400" marginLeft="0" marginBottom="3" width="fit-content">
      <Table>
        <Thead>
          <Tr>
            <Th
              borderRight="1px solid"
              borderColor="gray.300"
              padding="0"
              width="160px"
              pl={0}
              fontWeight="600"
              letterSpacing="none"
              textTransform="none"
              color="gray.500"
            />
            {updates.map((update, i) => {
              return (
                <Th
                  key={`compare_scenarios_modal-header-${change.object}-field-${update.field}`}
                  backgroundColor={i % 2 === 0 ? undefined : 'gray.50'}
                  borderBottom="1px solid"
                  borderRight={i !== updates.length - 1 ? '1px dashed' : undefined}
                  borderColor="gray.300"
                  paddingX="3"
                  paddingY="2"
                  fontWeight="600"
                  letterSpacing="none"
                  whiteSpace="nowrap"
                  textTransform="none"
                  color="gray.500"
                >
                  {update.field}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td
              borderRight="1px solid"
              borderBottom="1px dashed"
              borderColor="gray.300"
              paddingX="3"
              pl={0}
              paddingY="2"
            >
              <LayerName layerType="merge" layer={mergeLayer} fontSize="xs" color="gray.600" />
            </Td>
            {updates.map((update, i) => {
              return (
                <Td
                  key={`compare_scenarios_modal-${change.object}-${mergeLayer.id}-${update.field}`}
                  backgroundColor={i % 2 === 0 ? undefined : 'gray.50'}
                  borderBottom="1px dashed"
                  _notLast={{
                    borderRight: '1px dashed',
                    borderRightColor: 'gray.300',
                  }}
                  borderColor="gray.300"
                  paddingX="3"
                  paddingY="2"
                  fontWeight="500"
                  fontSize="xs"
                  textAlign="left"
                >
                  <ChangeCell value={update.from} />
                </Td>
              );
            })}
          </Tr>
          <Tr>
            <Td
              borderRight="1px solid"
              borderColor="gray.300"
              paddingX="3"
              paddingY="2"
              pl={0}
              borderBottom="0px dashed"
            >
              <LayerName layerType="current" layer={currentLayer} fontSize="xs" color="gray.600" />
            </Td>
            {updates.map((update, i) => {
              return (
                <Td
                  key={`compare_scenarios_modal-${change.object}-${currentLayer.id}-${update.field}`}
                  borderColor="gray.300"
                  backgroundColor={i % 2 === 0 ? undefined : 'gray.50'}
                  _notLast={{
                    borderRight: '1px dashed',
                    borderRightColor: 'gray.300',
                  }}
                  borderBottom="0px dashed"
                  paddingX="3"
                  paddingY="2"
                  fontWeight="500"
                  fontSize="xs"
                  textAlign="left"
                >
                  <ChangeCell value={update.to} />
                </Td>
              );
            })}
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};
