import { Box, Flex } from '@chakra-ui/react';
import React, { useContext, useMemo } from 'react';

import CellContextProviderLight from 'components/CellContextProvider/CellContextProviderLight';
import ComparisonNameCell from 'components/DriverNameTableCell/ColumnLayoutComparisonTypeCell';
import DriverNameLight from 'components/DriverNameTableCell/DriverNameLight';
import { useDriverName } from 'components/DriverNameTableCell/useDriverName';
import LayerNameCellContents from 'components/LayerNameCellContents/LayerNameCellContents';
import NameCellWrapper from 'components/NameCellWrapper/NameCellWrapper';
import TableCellLight from 'components/Table/TableCellLight';
import { DriverRowContext } from 'config/driverRowContext';
import { NAME_COLUMN_TYPE } from 'config/modelView';
import { ComparisonColumn } from 'generated/graphql';
import { ColorLocation, formatColorForUsage } from 'helpers/color';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useCellSelectionStateContext } from 'hooks/useCellSelectionStateContext';
import useDriverCellRef from 'hooks/useDriverCellRef';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { baselineLayerIdForBlockSelector } from 'selectors/baselineLayerSelector';
import { blockConfigDriverIndentsSelector } from 'selectors/blocksSelector';
import { comparisonTimePeriodsForBlockSelector } from 'selectors/comparisonTimePeriodsSelector';
import { driverRowColorSelector } from 'selectors/driversSelector';
import { isBlockRowComparisonLayoutSelector } from 'selectors/rollupSelector';
import {
  comparisonLayerIdsForBlockSelector,
  scenarioComparisonShouldShowDriverDiffs,
} from 'selectors/scenarioComparisonSelector';
import { columnOffsetSelector, columnWidthSelector } from 'selectors/tableColumnsSelector';

interface Props {
  groupId?: DriverGroupId;
  isLast: boolean;
}

const DriverNameTableCellLight = React.forwardRef<HTMLDivElement, Props>((_props, passedRef) => {
  const { blockId, gutterWidthInPxString } = useBlockContext();
  const { driverId, comparisonRowLayerId, comparisonType, comparisonTimePeriod } =
    useContext(DriverRowContext);

  const isRowComparisonLayout = useAppSelector((state) =>
    isBlockRowComparisonLayoutSelector(state, blockId),
  );

  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );

  const comparisonTimePeriods = useAppSelector((state) =>
    comparisonTimePeriodsForBlockSelector(state, blockId),
  );

  const isComparisonLayout = comparisonLayerIds.length > 0;
  const isComparingTimePeriods = comparisonTimePeriods.length > 0;

  const indentations = useAppSelector((state) => blockConfigDriverIndentsSelector(state, blockId));
  const driverIndentLevel =
    indentations.find((indentation) => indentation.driverId === driverId)?.level ?? 0;

  const baselineLayerId = useAppSelector((state) =>
    baselineLayerIdForBlockSelector(state, blockId),
  );

  const shouldShowDriverDiffs = useAppSelector((state) =>
    scenarioComparisonShouldShowDriverDiffs(state, blockId),
  );

  const { baselineDriverName, updatedDriverNamesByLayerId } = useDriverName({
    driverId,
    baselineLayerId,
    comparisonLayerIds,
    shouldShowDriverDiffs,
  });

  const numUpdatedDriverNames = Object.keys(updatedDriverNamesByLayerId).length;

  const { isSelected } = useCellSelectionStateContext();
  const width = useAppSelector((state) =>
    columnWidthSelector(state, { columnType: NAME_COLUMN_TYPE, blockId }),
  );
  const left = useAppSelector((state) =>
    columnOffsetSelector(state, { columnType: NAME_COLUMN_TYPE, blockId }),
  );

  const DriverName = useMemo(
    () => (
      <>
        <Box flex={numUpdatedDriverNames > 0 ? 0 : 1} minWidth={0}>
          <DriverNameLight
            name={baselineDriverName}
            indents={driverIndentLevel}
            fontSize={isComparisonLayout ? 'xxs' : 'xs'}
            as={
              // strikethrough driver name if it was updated in other layers
              numUpdatedDriverNames > 0 ? 's' : undefined
            }
          />
        </Box>
        {/* Show the baseline driver name and what it was updated to in other layers */}
        {Object.entries(updatedDriverNamesByLayerId).map(([lId, name], i) => (
          <Flex key={lId} marginLeft="8px">
            <DriverNameLight
              fontSize={isComparisonLayout ? 'xxs' : 'xs'}
              name={`${name}${i !== numUpdatedDriverNames - 1 ? ',' : ''}`}
              indents={driverIndentLevel}
            />
          </Flex>
        ))}
      </>
    ),
    [
      baselineDriverName,
      driverIndentLevel,
      isComparisonLayout,
      numUpdatedDriverNames,
      updatedDriverNamesByLayerId,
    ],
  );

  const DriverOrLayerNameCell = useMemo(() => {
    if (isComparisonLayout && comparisonRowLayerId != null) {
      const isBaselineLayer = comparisonRowLayerId === baselineLayerId;
      return (
        <LayerNameCellContents
          layerId={comparisonRowLayerId}
          driverId={driverId}
          isBaselineLayer={isBaselineLayer}
          isComparisonLayout={isComparisonLayout}
        />
      );
    }
    return DriverName;
  }, [isComparisonLayout, comparisonRowLayerId, DriverName, baselineLayerId, driverId]);

  const DriverOrComparisonTypeCell = useMemo(() => {
    // We in-line the RowVersion values next to the driver name, so for this row just render the driver name.
    if (
      (comparisonType != null && comparisonType !== ComparisonColumn.RowVersion) ||
      comparisonTimePeriod != null
    ) {
      return <ComparisonNameCell />;
    }
    return DriverName;
  }, [DriverName, comparisonTimePeriod, comparisonType]);

  return (
    <NameCellWrapper ref={passedRef} testId={`driver-name-${baselineDriverName}-light`}>
      <Box height="full" marginLeft={gutterWidthInPxString} position="relative">
        {!isSelected && <DriverRowBackgroundColorIndicator />}
        <TableCellLight isSticky left={left} width={width}>
          <Box
            height="full"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="full"
            overflow="hidden"
            position="relative"
          >
            {isComparingTimePeriods
              ? DriverOrComparisonTypeCell
              : isRowComparisonLayout
                ? DriverOrLayerNameCell
                : DriverOrComparisonTypeCell}
          </Box>
        </TableCellLight>
      </Box>
    </NameCellWrapper>
  );
});

const DriverRowBackgroundColorIndicator: React.FC = () => {
  const { driverId } = useContext(DriverRowContext);
  const rowBgColor = useAppSelector((state) => driverRowColorSelector(state, { id: driverId }));
  return rowBgColor != null ? (
    <Box
      position="absolute"
      top="0"
      bottom="0"
      left="0"
      width="2px"
      zIndex="12"
      background={formatColorForUsage(rowBgColor, ColorLocation.Accessory)}
    />
  ) : null;
};

const DriverNameTableCellLightWrapper = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const cellRef = useDriverCellRef({ columnType: NAME_COLUMN_TYPE, columnLayerId: undefined });
  return (
    <CellContextProviderLight cellRef={cellRef}>
      <DriverNameTableCellLight ref={ref} {...props} />
    </CellContextProviderLight>
  );
});

export default React.memo(DriverNameTableCellLightWrapper);
