import isNumber from 'lodash/isNumber';

import { ComparisonColumn, DriverFormat, ThresholdDirection } from 'generated/graphql';
import { TimeSeriesColumn, TimeSeriesComparisonSubColumn } from 'helpers/rollups';
import { nullSafeEqual } from 'helpers/typescript';
import { LayerId } from 'reduxStore/models/layers';
import { CalculationError } from 'types/dataset';
import { MonthKey } from 'types/datetime';

export type ComparisonLayout = 'row' | 'column-default' | 'column-compact' | null;

const comparisonColumnToNameForNamedVersion: Record<ComparisonColumn, string> = {
  [ComparisonColumn.Variance]: 'Variance',
  [ComparisonColumn.VariancePercentage]: 'Variance %',
  [ComparisonColumn.BaselineVersion]: 'Actual',
  [ComparisonColumn.LatestVersion]: 'Actual',
  [ComparisonColumn.RowVersion]: 'Forecast',
};

export const comparisonColumnToNameForScenarios: Record<ComparisonColumn, string> = {
  [ComparisonColumn.Variance]: 'Variance',
  [ComparisonColumn.VariancePercentage]: 'Variance %',
  [ComparisonColumn.BaselineVersion]: 'Current Scenario',
  [ComparisonColumn.LatestVersion]: 'Current Scenario',
  [ComparisonColumn.RowVersion]: 'Value',
};

export const getComparisonColumnName = ({
  subLabel,
  layerNameById,
  areNamedVersions,
}: {
  subLabel: Pick<TimeSeriesComparisonSubColumn, 'column' | 'layerId'> | undefined;
  layerNameById: Record<LayerId, string>;
  areNamedVersions: boolean;
}) => {
  if (subLabel?.layerId != null) {
    return layerNameById[subLabel.layerId];
  }

  if (subLabel?.column != null) {
    return areNamedVersions
      ? comparisonColumnToNameForNamedVersion[subLabel.column]
      : comparisonColumnToNameForScenarios[subLabel.column];
  }

  return '';
};

const comparisonBlockColumnSortOrder: Record<ComparisonColumn, number> = {
  [ComparisonColumn.RowVersion]: 1,
  [ComparisonColumn.BaselineVersion]: 2,
  [ComparisonColumn.LatestVersion]: 2,
  [ComparisonColumn.Variance]: 3,
  [ComparisonColumn.VariancePercentage]: 4,
};

export const comparisonColumnSort = (a: ComparisonColumn, b: ComparisonColumn): number => {
  return comparisonBlockColumnSortOrder[a] - comparisonBlockColumnSortOrder[b];
};

export const DEFAULT_COMPARISON_COLUMNS = [
  ComparisonColumn.BaselineVersion, // displayed as the baseline layer row/column
  ComparisonColumn.RowVersion, // displayed as the Value column
];

const getIsActuals = (
  mks: MonthKey[],
  actualsEndMonthKey: MonthKey,
  namedVersionActualsEndMonthKey: MonthKey | undefined,
  comparison: ComparisonColumn,
): boolean => {
  const latestIsActual = mks.every((mk) => mk <= actualsEndMonthKey);

  if (namedVersionActualsEndMonthKey == null) {
    return latestIsActual;
  }

  const namedVersionIsActual = mks.every((mk) => mk <= namedVersionActualsEndMonthKey);

  switch (comparison) {
    case ComparisonColumn.LatestVersion:
    case ComparisonColumn.BaselineVersion:
      return latestIsActual;
    case ComparisonColumn.RowVersion:
      return namedVersionIsActual;
    default:
      return latestIsActual && namedVersionIsActual;
  }
};

export function addComparisonColumns({
  oldColumns,
  actualsEndMonthKey,
  namedVersionActualsEndMonthKey,
  comparisons,
  compareLayerIds,
  comparisonLayout,
  currentLayerId,
  comparisonTimePeriods,
}: {
  oldColumns: TimeSeriesColumn[];
  actualsEndMonthKey: MonthKey;
  namedVersionActualsEndMonthKey: MonthKey | undefined;
  comparisons: ComparisonColumn[];
  compareLayerIds: LayerId[];
  comparisonLayout: ComparisonLayout;
  currentLayerId: LayerId;
  comparisonTimePeriods?: MonthKey[];
}) {
  const isComparingTimePeriods = comparisonTimePeriods != null && comparisonTimePeriods.length > 0;
  const isComparingLayers = compareLayerIds.length > 0;

  // Same short-circuit logic as `timeSeriesColumnsForBlockSelector`
  if (!isComparingTimePeriods && (!isComparingLayers || namedVersionActualsEndMonthKey == null)) {
    return oldColumns;
  }

  const getComparisonTypeColumns = (
    column: TimeSeriesColumn,
    comparisonColumns: ComparisonColumn[],
  ) =>
    comparisonColumns
      .filter(
        // If we turn the feature flag on for users, we should hide the "Current Value" column, as they
        // might still have it toggled on.
        (c) => c !== ComparisonColumn.BaselineVersion,
      )
      .map((comparisonType) => ({
        ...column,
        subLabel: {
          column: comparisonType,
          isActuals: getIsActuals(
            column.mks,
            actualsEndMonthKey,
            namedVersionActualsEndMonthKey,
            comparisonType,
          ),
        },
      }));

  const getComparisonColumns = () =>
    oldColumns.flatMap((column) => getComparisonTypeColumns(column, comparisons));

  const getLayerColumns = () =>
    oldColumns.flatMap((column) => [
      ...(compareLayerIds
        ?.filter(
          // We allow the user to toggle "Baseline Version" on and off in order to support
          // only viewing snapshot data (without the current layer's data). However,
          // we don't want to render an actual row for "Baseline Version".
          (lId) => lId !== currentLayerId || comparisons.includes(ComparisonColumn.BaselineVersion),
        )
        .map((layerId) => ({
          ...column,
          subLabel: {
            layerId,
            isActuals: getIsActuals(
              column.mks,
              actualsEndMonthKey,
              namedVersionActualsEndMonthKey,
              ComparisonColumn.BaselineVersion,
            ),
          },
        })) ?? []),
      // In addition to the layer subcolumns, add the comparison type subcolumns.
      // No need to add the "Current Value" subcolumn.
      ...(comparisonLayout === 'column-compact'
        ? getComparisonTypeColumns(
            column,
            comparisons.filter((c) => c !== ComparisonColumn.RowVersion),
          )
        : []),
    ]);

  return comparisonLayout === 'row' ? getComparisonColumns() : getLayerColumns();
}

export function getValueColor(
  monthKeys: MonthKey[],
  timeSeriesSourceByMonthKey: Record<MonthKey, 'actuals' | 'forecast'>,
) {
  const isActual = monthKeys.every((mk) => timeSeriesSourceByMonthKey[mk] === 'actuals');
  return isActual ? 'actuals' : 'forecast';
}

export function getColorWithComparisonSources({
  monthKeys,
  value,
  baselineValue,
  rowValue,
  subLabel,
  thresholdDirection = ThresholdDirection.AboveOrEqual,
  baselineVersionTimeSeriesSourceByMonthKey,
  compareVersionTimeSeriesSourceByMonthKey,
}: {
  monthKeys: MonthKey[];
  value?: number;
  baselineValue?: number;
  rowValue?: number;
  subLabel?: ComparisonColumn;
  thresholdDirection?: ThresholdDirection;
  baselineVersionTimeSeriesSourceByMonthKey: Record<MonthKey, 'actuals' | 'forecast'>;
  compareVersionTimeSeriesSourceByMonthKey?: Record<MonthKey, 'actuals' | 'forecast'>;
}): 'actuals' | 'forecast' | 'red.600' | 'green.600' {
  const timeSeriesSourceByMonthKey =
    subLabel === ComparisonColumn.RowVersion && compareVersionTimeSeriesSourceByMonthKey != null
      ? compareVersionTimeSeriesSourceByMonthKey
      : baselineVersionTimeSeriesSourceByMonthKey;
  const isActual = monthKeys.every((mk) => timeSeriesSourceByMonthKey[mk] === 'actuals');

  if (subLabel === ComparisonColumn.Variance || subLabel === ComparisonColumn.VariancePercentage) {
    if (value === 0) {
      return isActual ? 'actuals' : 'forecast';
    }

    if (rowValue == null || baselineValue == null) {
      return isActual ? 'actuals' : 'forecast';
    }

    if (baselineValue < rowValue) {
      return thresholdDirection === ThresholdDirection.AboveOrEqual ? 'red.600' : 'green.600';
    }

    if (baselineValue > rowValue) {
      return thresholdDirection === ThresholdDirection.AboveOrEqual ? 'green.600' : 'red.600';
    }
  }
  return isActual ? 'actuals' : 'forecast';
}

export function shouldShowComparisonHighlight({
  rowValue,
  baselineValue,
  layerId,
  baselineLayerId,
  column,
}: {
  rowValue?: number | string;
  baselineValue?: number | string;
  layerId?: LayerId;
  baselineLayerId: LayerId;
  column?: ComparisonColumn;
}) {
  if (column != null && column !== ComparisonColumn.RowVersion) {
    return false;
  }
  if (layerId == null || layerId === baselineLayerId) {
    return false;
  }
  if (
    nullSafeEqual(rowValue, baselineValue) ||
    (isNumber(rowValue) &&
      Number.isNaN(rowValue) &&
      isNumber(baselineValue) &&
      Number.isNaN(baselineValue))
  ) {
    return false;
  }
  return true;
}

export function getValueForColumn({
  rowValue,
  baselineValue,
  column,
}: {
  rowValue: number | undefined;
  baselineValue: number | undefined;
  column: ComparisonColumn | undefined;
}) {
  switch (column) {
    case ComparisonColumn.LatestVersion:
    case ComparisonColumn.BaselineVersion:
      return baselineValue;
    case ComparisonColumn.Variance:
      return getVariance(baselineValue, rowValue);
    case ComparisonColumn.VariancePercentage:
      return getVariancePercentage(baselineValue, rowValue);
    default:
      return rowValue;
  }
}

export function getErrorForColumn({
  rowError,
  baselineError,
  column,
}: {
  rowError: CalculationError | undefined;
  baselineError: CalculationError | undefined;
  column: ComparisonColumn | undefined;
}) {
  switch (column) {
    case ComparisonColumn.LatestVersion:
    case ComparisonColumn.BaselineVersion:
      return baselineError;
    case ComparisonColumn.Variance:
      return baselineError ?? rowError;
    case ComparisonColumn.VariancePercentage:
      return baselineError ?? rowError;
    default:
      return rowError;
  }
}

function getVariance(baselineValue?: number, rowValue?: number) {
  if (baselineValue == null || rowValue == null) {
    return undefined;
  }
  return baselineValue - rowValue;
}

function getVariancePercentage(baselineValue?: number, rowValue?: number) {
  if (baselineValue == null || rowValue == null) {
    return undefined;
  }
  return (baselineValue - rowValue) / rowValue;
}

export function comparisonColumnFormatOverride(
  format: DriverFormat,
  column: ComparisonColumn | undefined,
): DriverFormat {
  return column === ComparisonColumn.VariancePercentage ? DriverFormat.Percentage : format;
}
