import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import min from 'lodash/min';
import minBy from 'lodash/minBy';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';
import { createCachedSelector } from 're-reselect';

import { MAX_DATE } from 'config/datetime';
import { ValueType } from 'generated/graphql';
import { getMonthKey, getMonthKeysForRange } from 'helpers/dates';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { calculate } from 'helpers/formulaEvaluation/ForecastCalculator/ForecastCalculator';
import { CacheKey } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCache';
import { FormulaCalculationContext } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaCalculationContext';
import { FormulaEvaluator } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaEvaluator';
import { layerParamMemo } from 'helpers/layerSelectorFactory';
import { getObjectFieldUUID } from 'helpers/object';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpecId,
  BusinessObjectSpecId,
} from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectFieldId, BusinessObjectId } from 'reduxStore/models/businessObjects';
import { EventId, ObjectFieldEvent } from 'reduxStore/models/events';
import { LayerId } from 'reduxStore/models/layers';
import { ValueTimeSeries } from 'reduxStore/models/timeSeries';
import {
  NullableValue,
  TimestampValue,
  Value,
  isTimestampValue,
  toNullableValue,
} from 'reduxStore/models/value';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { blockConfigSelector } from 'selectors/blocksSelector';
import {
  businessObjectFieldByObjectSpecIdForLayerSelector,
  businessObjectFieldSpecSelector,
} from 'selectors/businessObjectFieldSpecsSelector';
import { businessObjectSpecsByIdForLayerSelector } from 'selectors/businessObjectSpecsSelector';
import {
  businessObjectsByIdForLayerSelector,
  businessObjectsBySpecIdForLayerSelector,
  fieldIdForFieldSpecIdAndObjectIdSelector,
  materializedFieldForSpecIdAndObjectIdSelector,
} from 'selectors/businessObjectsSelector';
import { entityTimeSeriesSelector } from 'selectors/calculationsSelector';
import {
  computedAttributePropertySelector,
  dimensionalPropertiesForBusinessObjectSpecIdSelector,
  driverPropertiesForBusinessObjectSpecSelector,
  subdriversByDimDriverIdForBusinessObjectSelector,
} from 'selectors/collectionSelector';
import { fieldSelector } from 'selectors/constSelectors';
import { driversByIdForCurrentLayerSelector } from 'selectors/driversSelector';
import { visibleEventsByObjectFieldIdForLayerSelector } from 'selectors/eventsAndGroupsSelector';
import { formulaCalculationContextSelector } from 'selectors/formulaCalculationContextSelector';
import { formulaEvaluatorForLayerSelector } from 'selectors/formulaEvaluatorSelector';
import { shouldDoSynchronousCalculationsSelector } from 'selectors/inspectorSelector';
import { getGivenOrCurrentLayerId } from 'selectors/layerSelector';
import {
  blockDateRangeDateTimeSelector,
  blockDateRangeTranslatedViewAtTimeSelector,
  pageDateRangeDateTimeSelector,
} from 'selectors/pageDateRangeSelector';
import { isCalculationError, isCalculationValue } from 'types/dataset';
import { MonthKey } from 'types/datetime';
import { ParametricSelector } from 'types/redux';

const EMPTY_TIMESERIES: ValueTimeSeries = {};

export function runObjectCalculations({
  objectFieldId,
  dateRange,
  evaluator,
  newlyAddedCacheKeys,
  context,
  options: { stopAfterFirstNonNullValue = false } = {},
}: {
  objectFieldId: BusinessObjectFieldId;
  dateRange: DateTime[];
  evaluator: FormulaEvaluator;
  context?: FormulaCalculationContext;
  newlyAddedCacheKeys: Set<CacheKey>;
  options?: {
    stopAfterFirstNonNullValue?: boolean;
  };
}) {
  const [startDateTime, endDateTime] = dateRange;
  const monthKeys = getMonthKeysForRange(startDateTime, endDateTime);

  const ignoreEventIds = new Set<EventId>();

  evaluator.invalidateCache();
  const foundNonNullValue = false;
  return monthKeys.reduce((values: ValueTimeSeries, monthKey: MonthKey) => {
    if (foundNonNullValue && stopAfterFirstNonNullValue) {
      return values;
    }
    const calcResult = calculate({
      evaluator,
      context,
      entityId: { type: 'objectField', id: objectFieldId },
      monthKey,
      visited: new Set(),
      ignoreEventIds,
      newlyAddedCacheKeys,
    });

    const value = isCalculationValue(calcResult) ? calcResult : undefined;

    if (value == null) {
      return values;
    } else {
      return { [monthKey]: value, ...values };
    }
  }, EMPTY_TIMESERIES);
}

const getCacheKeyForFieldTimeSeriesSelector = (
  state: RootState,
  props: BusinessObjectFieldTimeSeriesSelectorProps,
) => {
  return `${getGivenOrCurrentLayerId(state, props)},${props.businessObjectId},${
    props.businessObjectFieldSpecId
  },${props.blockId}`;
};

export const businessObjectFieldForecastTimeSeriesSyncCalculationsSelector: ParametricSelector<
  BusinessObjectFieldTimeSeriesSelectorProps,
  ValueTimeSeries
> = createCachedSelector(
  fieldSelector('businessObjectId'),
  fieldSelector('businessObjectFieldSpecId'),
  (state: RootState, props: BusinessObjectFieldTimeSeriesSelectorProps) =>
    materializedFieldForSpecIdAndObjectIdSelector(state, props),
  (state: RootState, props: BusinessObjectFieldTimeSeriesSelectorProps) =>
    blockDateRangeDateTimeSelector(state, props.blockId),
  (state: RootState, props: BusinessObjectFieldTimeSeriesSelectorProps) =>
    blockDateRangeTranslatedViewAtTimeSelector(state, props.blockId),
  (state: RootState, props: BusinessObjectFieldTimeSeriesSelectorProps) =>
    formulaEvaluatorForLayerSelector(state, layerParamMemo(props.layerId)),
  (state: RootState) => formulaCalculationContextSelector(state),
  // eslint-disable-next-line max-params
  function businessObjectFieldForecastTimeSeriesSyncCalculationsSelector(
    objectId,
    fieldSpecId,
    field,
    dateRange,
    startOverride,
    evaluator,
    context,
  ) {
    const fieldId = field?.id ?? getObjectFieldUUID(objectId, fieldSpecId);
    // When viewing the graph, the line should extend to the y-axis line, so we
    // need to ensure we have one data point preceding the start month.
    const modifiedDateRange = [
      dateRange[0].minus({ months: 1 }),
      startOverride ? MAX_DATE : dateRange[1],
    ];

    return runObjectCalculations({
      objectFieldId: fieldId,
      dateRange: modifiedDateRange,
      evaluator,
      context,
      newlyAddedCacheKeys: new Set(),
    });
  },
)(getCacheKeyForFieldTimeSeriesSelector);

export const businessObjectFieldForecastTimeSeriesSelector: ParametricSelector<
  BusinessObjectFieldTimeSeriesSelectorProps,
  ValueTimeSeries
> = function businessObjectFieldForecastTimeSeriesSelector(state, props) {
  const shouldDoSynchronousCalculations = shouldDoSynchronousCalculationsSelector(state);
  if (!shouldDoSynchronousCalculations) {
    const fieldId = fieldIdForFieldSpecIdAndObjectIdSelector(state, props);
    const dateRange = pageDateRangeDateTimeSelector(state);
    const start = getMonthKey(dateRange[0]);
    const end = getMonthKey(dateRange[1]);
    return entityTimeSeriesSelector(state, {
      id: fieldId,
      start,
      end,
      layerId: getGivenOrCurrentLayerId(state, props),
    });
  }
  return businessObjectFieldForecastTimeSeriesSyncCalculationsSelector(state, props);
};

type BusinessObjectFieldValueForMonthKeyProps = BusinessObjectFieldTimeSeriesSelectorProps & {
  monthKey: MonthKey;
};
export const businessObjectFieldValueForMonthKeySelector: ParametricSelector<
  BusinessObjectFieldValueForMonthKeyProps,
  Value | undefined
> = createCachedSelector(
  (state: RootState, { monthKey: _, ...props }: BusinessObjectFieldValueForMonthKeyProps) =>
    businessObjectFieldForecastTimeSeriesSelector(state, props),
  fieldSelector('monthKey'),
  (timeSeries, mk) => timeSeries[mk],
)(getCacheKeyForFieldTimeSeriesSelector);

type BusinessObjectSpecIdFieldSpecId = {
  fieldSpecId: BusinessObjectFieldSpecId;
  objectSpecId: BusinessObjectSpecId;
};
/**
 * BusinessObject First Non-Null Value By FieldId
 *
 * Given a BusinessObjectFieldSpecId and BusinessObjectSpecId
 * Gets the first non-null value for each object in the Object Spec for the field
 * corresponding to the Field Spec Id.
 */
export const businessObjectFirstNonNullValueByFieldIdSelector: ParametricSelector<
  BusinessObjectSpecIdFieldSpecId,
  Record<BusinessObjectFieldId, Value>
> = createCachedSelector(
  fieldSelector<BusinessObjectSpecIdFieldSpecId, 'fieldSpecId'>('fieldSpecId'),
  fieldSelector('objectSpecId'),
  (state: RootState) => pageDateRangeDateTimeSelector(state),
  (state: RootState) => businessObjectFieldByObjectSpecIdForLayerSelector(state),
  (state: RootState) => formulaEvaluatorForLayerSelector(state),
  (state: RootState) => formulaCalculationContextSelector(state),
  // eslint-disable-next-line max-params
  function businessObjectFirstNonNullValueByFieldIdSelector(
    fieldSpecId,
    objectSpecId,
    dateRange,
    objectFieldsBySpecId,
    evaluator,
    context,
  ) {
    if (objectSpecId == null || dateRange == null) {
      return {};
    }

    const objectFieldsForBlock = objectFieldsBySpecId[objectSpecId];
    if (objectFieldsForBlock == null) {
      return {};
    }

    const fields = objectFieldsForBlock.filter((f) => f.fieldSpecId === fieldSpecId);

    const fieldIdAndValueEntries = fields
      .map((field) => {
        const values = runObjectCalculations({
          objectFieldId: field.id,
          dateRange,
          evaluator,
          context,
          newlyAddedCacheKeys: new Set(),
          options: {
            stopAfterFirstNonNullValue: true,
          },
        });
        const firstMonth = min(Object.keys(values));
        if (firstMonth == null) {
          return undefined;
        }

        return [field.id, values[firstMonth]] as const;
      })
      .filter(isNotNull);
    return Object.fromEntries(fieldIdAndValueEntries);
  },
)({
  keySelector: (_state, { fieldSpecId, objectSpecId }) => `${objectSpecId}/${fieldSpecId}`,
  selectorCreator: createDeepEqualSelector,
});

export type BusinessObjectFieldValueSelectorProps = {
  businessObjectId: BusinessObjectId;
  businessObjectFieldSpecId: BusinessObjectFieldSpecId;
  layerId?: LayerId;
};

type BusinessObjectFieldTimeSeriesSelectorProps = BusinessObjectFieldValueSelectorProps & {
  blockId: BlockId;
};

export const getCacheKeyForFieldSelector = (
  state: RootState,
  props: BusinessObjectFieldValueSelectorProps,
) => {
  return `${props.businessObjectId},${props.businessObjectFieldSpecId},${getGivenOrCurrentLayerId(
    state,
    props,
  )}`;
};

type BusinessObjectFieldTransitionValue<V extends Value = Value> = {
  originalValue: NullableValue<V>;
  newValue?: V;
};

const getStartDateFromEvents = (events: ObjectFieldEvent[]): TimestampValue | undefined => {
  const eventTimestampValues = events
    .flatMap((e) => (e.customCurvePoints != null ? Object.values(e.customCurvePoints) : []))
    .filter((e) => e.value != null && e.value !== '' && isTimestampValue(e)) as TimestampValue[];

  // Since the timestamp strings are all the same format a simple string comparison
  // will return the smallest date
  return minBy(eventTimestampValues, 'value');
};

export const businessObjectFieldTransitionValueSelector: ParametricSelector<
  BusinessObjectFieldTimeSeriesSelectorProps,
  BusinessObjectFieldTransitionValue | undefined
> = createCachedSelector(
  fieldSelector('businessObjectId'),
  (state: RootState, props: BusinessObjectFieldTimeSeriesSelectorProps) =>
    businessObjectFieldSpecSelector(state, { id: props.businessObjectFieldSpecId }),
  (state: RootState, props: BusinessObjectFieldTimeSeriesSelectorProps) =>
    materializedFieldForSpecIdAndObjectIdSelector(state, props),
  (state: RootState, props: BusinessObjectFieldTimeSeriesSelectorProps) =>
    businessObjectFieldForecastTimeSeriesSelector(state, props),
  (state: RootState, props: BusinessObjectFieldTimeSeriesSelectorProps) =>
    visibleEventsByObjectFieldIdForLayerSelector(state, layerParamMemo(props.layerId)),
  (state: RootState, props: BusinessObjectFieldTimeSeriesSelectorProps) =>
    businessObjectsByIdForLayerSelector(state, layerParamMemo(props.layerId)),
  (state: RootState, props: BusinessObjectFieldTimeSeriesSelectorProps) =>
    businessObjectSpecsByIdForLayerSelector(state, layerParamMemo(props.layerId)),
  (state: RootState, props: BusinessObjectFieldTimeSeriesSelectorProps) =>
    blockDateRangeDateTimeSelector(state, props.blockId),
  (state: RootState, props: BusinessObjectFieldTimeSeriesSelectorProps) =>
    blockDateRangeTranslatedViewAtTimeSelector(state, props.blockId),
  // eslint-disable-next-line max-params
  function businessObjectFieldTransitionValueSelector(
    objectId,
    fieldSpec,
    field,
    valuesForFieldId,
    eventsByFieldId,
    objectsById,
    specsById,
    dateRange,
    startOverride,
  ) {
    const [startMonth, endMonth] = dateRange;
    const startMonthKey = getMonthKey(startOverride || startMonth);
    const endMonthKey = getMonthKey(startOverride ? MAX_DATE : endMonth);
    const eventsForField = field != null ? eventsByFieldId[field.id] : [];

    // Time series returns extra values for plotting graphs.
    // Transition value should not include these values
    const values = sortBy(
      Object.entries(valuesForFieldId ?? {}).filter(
        ([mk]) => mk >= startMonthKey && mk <= endMonthKey,
      ),
      ([mk]) => mk,
    );
    const obj = objectsById[objectId];
    const spec = obj != null ? specsById[obj.specId] : undefined;
    if (obj == null || spec == null || fieldSpec == null) {
      return undefined;
    }
    if (values.length <= 1) {
      const value = values.length > 0 ? values[0][1] : undefined;
      const cumulativeValue: BusinessObjectFieldTransitionValue = {
        originalValue: toNullableValue(value, fieldSpec.type),
      };
      return cumulativeValue;
    }

    let fieldValue: Value | undefined = values[0][1];
    const firstValueDate = values[0][0];
    const lastValue: Value = values[values.length - 1][1];

    // Disallow planning on start field
    const isStartField = spec.startFieldId === fieldSpec.id;
    let updatedValue: Value | undefined;

    // For the start field, just take the start date of the event associated with it
    if (isStartField) {
      // if the start date has been modified via the event timeline, use the value of the event,
      //  otherwise if there is no event, set the field value to undefined
      if (eventsForField != null && eventsForField.length > 0) {
        const newValue = getStartDateFromEvents(eventsForField);

        if (newValue != null && newValue.type === fieldSpec.type) {
          fieldValue = newValue;
          updatedValue = undefined;
        }
      } else {
        fieldValue = undefined;
      }
    } else {
      // if filtering is set before the object's first value, return undefined
      if (startOverride != null && firstValueDate > startMonthKey) {
        return {
          originalValue: toNullableValue(undefined, fieldSpec.type),
        };
      }

      if (lastValue.value !== fieldValue.value) {
        updatedValue = lastValue;
      }
    }

    const retValue: BusinessObjectFieldTransitionValue =
      // if startOverride is null, it means no time period filter has been applied (showing all time) and we can thus show a value transition cell
      updatedValue != null && fieldValue != null && startOverride === null
        ? { originalValue: fieldValue, newValue: updatedValue }
        : { originalValue: toNullableValue(fieldValue, fieldSpec.type) };
    return retValue;
  },
)(getCacheKeyForFieldTimeSeriesSelector);

type BusinessObjectFieldTransitionValuesByFieldSpecIdSelectorProps = {
  businessObjectFieldSpecIds: string[];
  blockId: string;
  layerId?: string;
};
export type BusinessObjectFieldTransitionValuesByFieldId = { objectId: string } & Record<
  BusinessObjectFieldSpecId,
  BusinessObjectFieldTransitionValue
>;

export const businessObjectFieldTransitionValuesByFieldSpecIdSelector: ParametricSelector<
  BusinessObjectFieldTransitionValuesByFieldSpecIdSelectorProps,
  BusinessObjectFieldTransitionValuesByFieldId[]
> = createSelector(
  (state: RootState) => state,
  fieldSelector<BusinessObjectFieldTransitionValuesByFieldSpecIdSelectorProps, 'blockId'>(
    'blockId',
  ),
  fieldSelector<BusinessObjectFieldTransitionValuesByFieldSpecIdSelectorProps, 'layerId'>(
    'layerId',
  ),
  fieldSelector<
    BusinessObjectFieldTransitionValuesByFieldSpecIdSelectorProps,
    'businessObjectFieldSpecIds'
  >('businessObjectFieldSpecIds'),
  (state, blockId, layerId, businessObjectFieldSpecIds) => {
    const blockConfig = blockConfigSelector(state, blockId);
    if (!blockConfig) {
      return [];
    }

    const { businessObjectSpecId } = blockConfig;
    const objectsBySpecId = businessObjectsBySpecIdForLayerSelector(state, layerParamMemo(layerId));
    const objects =
      businessObjectSpecId == null ? null : safeObjGet(objectsBySpecId[businessObjectSpecId]);
    if (objects == null) {
      return [];
    }

    const fieldSpecsById = keyBy(
      businessObjectFieldSpecIds.map((id) => businessObjectFieldSpecSelector(state, { id })),
      'id',
    );

    const dimensionalProperties =
      businessObjectSpecId == null
        ? null
        : dimensionalPropertiesForBusinessObjectSpecIdSelector(state, businessObjectSpecId);

    const driverProperties =
      businessObjectSpecId == null
        ? null
        : driverPropertiesForBusinessObjectSpecSelector(state, businessObjectSpecId);

    const eventsByFieldId = visibleEventsByObjectFieldIdForLayerSelector(
      state,
      layerParamMemo(layerId),
    );
    const specsById = businessObjectSpecsByIdForLayerSelector(state, layerParamMemo(layerId));
    const driversById = driversByIdForCurrentLayerSelector(state);

    const dateRange = blockDateRangeDateTimeSelector(state, blockId);
    const startOverride = blockDateRangeTranslatedViewAtTimeSelector(state, blockId);

    const [startMonth, endMonth] = dateRange;
    const startMonthKey = getMonthKey(startOverride || startMonth);
    const endMonthKey = getMonthKey(startOverride ? MAX_DATE : endMonth);

    const out: BusinessObjectFieldTransitionValuesByFieldId[] = [];

    for (const object of objects) {
      const objectId = object.id;

      const objectTransitionValues: Record<
        BusinessObjectFieldSpecId,
        BusinessObjectFieldTransitionValue
      > = {};

      for (const fieldSpecId of businessObjectFieldSpecIds) {
        const fieldSpec = fieldSpecsById[fieldSpecId];
        const isDimProp = dimensionalProperties?.find((d) => d.id === fieldSpecId);
        const isDriverProp = driverProperties?.find((d) => d.id === fieldSpecId);

        if (isDimProp) {
          const computedProp = computedAttributePropertySelector(state, {
            objectId,
            dimensionalPropertyId: fieldSpecId,
          });
          const cumulativeValue: BusinessObjectFieldTransitionValue = {
            originalValue: { type: ValueType.Attribute, value: computedProp?.attribute.id },
          };
          objectTransitionValues[fieldSpecId] = cumulativeValue;

          continue;
        }
        if (isDriverProp) {
          const computedSubdriver = subdriversByDimDriverIdForBusinessObjectSelector(
            state,
            objectId,
            fieldSpecId,
          );

          const subdriverId = computedSubdriver[isDriverProp.driverId].driverId;
          const driver = driversById[subdriverId];

          if (!driver) {
            continue;
          }

          const result = calculate({
            evaluator: formulaEvaluatorForLayerSelector(state, layerParamMemo(layerId)),
            context: formulaCalculationContextSelector(state),
            entityId: { type: 'driver', id: driver.id },
            monthKey: startMonthKey,
            visited: new Set(),
            ignoreEventIds: new Set(),
            newlyAddedCacheKeys: new Set(),
          });

          if (result == null || isCalculationError(result)) {
            continue;
          }

          const cumulativeValue: BusinessObjectFieldTransitionValue = {
            originalValue: {
              type: driver?.valueType ?? ValueType.Number,
              value: result.value,
            } as NullableValue<Value>,
          };

          objectTransitionValues[fieldSpecId] = cumulativeValue;

          continue;
        }

        if (!fieldSpec) {
          // TODO: should probably throw
          continue;
        }

        const spec = specsById[object.specId];
        const field = object.fields.find((f) => f.fieldSpecId === fieldSpecId);
        const eventsForField = field != null ? eventsByFieldId[field.id] : [];

        const valuesForFieldId = businessObjectFieldForecastTimeSeriesSelector(state, {
          blockId,
          businessObjectId: objectId,
          businessObjectFieldSpecId: fieldSpecId,
          layerId,
        });

        // Time series returns extra values for plotting graphs.
        // Transition value should not include these values
        const values = sortBy(
          Object.entries(valuesForFieldId ?? {}).filter(
            ([mk]) => mk >= startMonthKey && mk <= endMonthKey,
          ),
          ([mk]) => mk,
        );

        if (values.length <= 1) {
          const value = values.length > 0 ? values[0][1] : undefined;
          const cumulativeValue: BusinessObjectFieldTransitionValue = {
            originalValue: toNullableValue(value, fieldSpec.type),
          };
          objectTransitionValues[fieldSpecId] = cumulativeValue;
          continue;
        }

        let fieldValue: Value | undefined = values[0][1];
        const firstValueDate = values[0][0];
        const lastValue: Value = values[values.length - 1][1];

        // Disallow planning on start field
        const isStartField = spec.startFieldId === fieldSpec.id;
        let updatedValue: Value | undefined;

        // For the start field, just take the start date of the event associated with it
        if (isStartField) {
          // if the start date has been modified via the event timeline, use the value of the event,
          //  otherwise if there is no event, set the field value to undefined
          if (eventsForField != null && eventsForField.length > 0) {
            const newValue = getStartDateFromEvents(eventsForField);

            if (newValue != null && newValue.type === fieldSpec.type) {
              fieldValue = newValue;
              updatedValue = undefined;
            }
          } else {
            fieldValue = undefined;
          }
        } else {
          // if filtering is set before the object's first value, return undefined
          if (startOverride != null && firstValueDate > startMonthKey) {
            objectTransitionValues[fieldSpecId] = {
              originalValue: toNullableValue(undefined, fieldSpec.type),
            };
            continue;
          }

          if (lastValue.value !== fieldValue.value) {
            updatedValue = lastValue;
          }
        }

        const transitionValue: BusinessObjectFieldTransitionValue =
          // if startOverride is null, it means no time period filter has been applied (showing all time) and we can thus show a value transition cell
          updatedValue != null && fieldValue != null && startOverride === null
            ? { originalValue: fieldValue, newValue: updatedValue }
            : { originalValue: toNullableValue(fieldValue, fieldSpec.type) };

        objectTransitionValues[fieldSpecId] = transitionValue;
      }

      out.push({
        ...objectTransitionValues,
        objectId,
      } as BusinessObjectFieldTransitionValuesByFieldId);
    }

    return out;
  },
);

export const businessObjectStartFieldValueSelector: ParametricSelector<
  BusinessObjectFieldValueSelectorProps,
  TimestampValue | undefined
> = createCachedSelector(
  (state: RootState, props: BusinessObjectFieldValueSelectorProps) =>
    visibleEventsByObjectFieldIdForLayerSelector(state, layerParamMemo(props.layerId)),
  (state: RootState, props: BusinessObjectFieldValueSelectorProps) =>
    businessObjectsByIdForLayerSelector(state, layerParamMemo(props.layerId))[
      props.businessObjectId
    ],
  (state: RootState, props: BusinessObjectFieldValueSelectorProps) =>
    businessObjectSpecsByIdForLayerSelector(state, layerParamMemo(props.layerId)),
  (valuesByFieldId, businessObject, objectSpecsById) => {
    if (businessObject == null) {
      return undefined;
    }

    const spec = objectSpecsById[businessObject.specId];
    const startField = businessObject.fields.find((f) => f.fieldSpecId === spec?.startFieldId);
    if (startField == null) {
      return undefined;
    }

    const events = valuesByFieldId[startField.id];
    if (events == null) {
      return undefined;
    }
    return getStartDateFromEvents(events);
  },
)(getCacheKeyForFieldSelector);
