import groupBy from 'lodash/groupBy';
import mean from 'lodash/mean';
import sum from 'lodash/sum';
import { DateTime, DateTimeUnit } from 'luxon';

import {
  ComparisonColumn,
  DriverFormat,
  RollupReducer,
  RollupSortType,
  RollupType,
} from 'generated/graphql';
import {
  TODAY,
  extractMonthKey,
  getDateTimeFromMonthKey,
  getISOTimeWithoutMs,
  getMonthKey,
  getMonthKeysForHYTD,
  getMonthKeysForQLC,
  getMonthKeysForQTD,
  getMonthKeysForRange,
  getMonthKeysForYearTo,
  isEndOfQuarter,
  isEndOfYear,
  shortFiscalQuarterFormat,
  shortMonthFormat,
  shortYearFormat,
} from 'helpers/dates';
import { LayerId } from 'reduxStore/models/layers';
import { ErrorTimeSeries, NumericTimeSeriesWithEmpty } from 'reduxStore/models/timeSeries';
import { CalculationError } from 'types/dataset';
import { MonthKey } from 'types/datetime';

import { isNotNull } from './typescript';

const UNIQUE_IDENTIFIERS = {
  [RollupType.CurrentMonth]: 'CurrentMonth',
  [RollupType.LastClose]: 'LastClose',
};

const editableRollupTypes = [RollupType.Month, RollupType.CurrentMonth, RollupType.LastClose];

export const isEditableRollupType = (rollupType: RollupType) => {
  return editableRollupTypes.includes(rollupType);
};

export function applyRollupReducer({
  monthKeys,
  values,
  errors,
  reducer,
}: {
  monthKeys: MonthKey[];
  values: NumericTimeSeriesWithEmpty;
  reducer: RollupReducer;

  // Not every caller will want to handle errors
  errors?: ErrorTimeSeries;
}): { value?: number; error?: CalculationError } {
  if (monthKeys.length === 0) {
    return {};
  }

  const forMonth = (mk: MonthKey) => {
    const error = errors?.[mk];
    const value = values[mk];
    if (error != null) {
      return { error };
    }
    return { value };
  };

  if (monthKeys.length === 1) {
    return forMonth(monthKeys[0]);
  }

  const orderedValues = monthKeys.map((mk) => values[mk]);
  const orderedErrors = monthKeys.map((mk) => errors?.[mk]);

  const firstErr = orderedErrors.find(isNotNull);
  const nonNullValues = orderedValues.filter(isNotNull);

  switch (reducer) {
    case RollupReducer.Avg: {
      if (firstErr != null) {
        return { error: firstErr };
      }
      // If the mean has all undefined values, return undefined. Otherwise,
      // treat undefined values as 0s.
      const avg = nonNullValues.length === 0 ? undefined : mean(nonNullValues);

      return { value: avg };
    }
    case RollupReducer.Sum:
      if (firstErr != null) {
        return { error: firstErr };
      }
      return { value: sum(nonNullValues) };
    case RollupReducer.First:
      return forMonth(monthKeys[0]);
    case RollupReducer.Last:
      return forMonth(monthKeys[monthKeys.length - 1]);
    default:
      throw new Error(`Unknown rollup reducer: ${reducer}`);
  }
}

function timePeriodForMonthKey(
  monthKey: MonthKey,
  rollupType: RollupType,
  fiscalYearStartMonth: number,
) {
  // If the monthKey is "2022-04", and their fiscal year starts in April, their
  // startMonth would be "4". 3 months back from 2022-04 is January, so it gets
  // treated as the start of Q1 and start of the year.
  const originalDateTime = getDateTimeFromMonthKey(monthKey);
  const dt = originalDateTime.minus({ months: fiscalYearStartMonth - 1 });

  switch (rollupType) {
    case RollupType.Quarter:
      return shortFiscalQuarterFormat(dt, fiscalYearStartMonth);
    case RollupType.Annual:
      return shortYearFormat(dt);
    case RollupType.Month:
      return shortMonthFormat(originalDateTime);
    default:
      throw new Error(`Unknown rollup: ${rollupType}`);
  }
}

function getRollupTimePeriodColumns(
  monthKeys: MonthKey[],
  fiscalYearStartMonth: number,
  rollupType: RollupType,
): TimeSeriesColumn[] {
  let includeQuarters = false;
  let includeYears = false;
  let includeMonths = true;
  switch (rollupType) {
    case RollupType.MonthlyAnnually:
      includeQuarters = false;
      includeYears = true;
      break;
    case RollupType.MonthlyQuarterly:
      includeQuarters = true;
      includeYears = false;
      break;
    case RollupType.MonthlyQuarterlyAnnually:
      includeQuarters = true;
      includeYears = true;
      break;
    case RollupType.Quarter:
      includeQuarters = true;
      includeYears = false;
      includeMonths = false;
      break;
    case RollupType.Annual:
      includeQuarters = false;
      includeYears = true;
      includeMonths = false;
      break;
    default:
      break;
  }
  const uniqueIdentifier = `-m${includeQuarters ? 'q' : ''}${includeYears ? 'y' : ''}`;
  const columns: TimeSeriesColumn[] = [];

  for (let i = 0; i < monthKeys.length; i++) {
    const dt = getDateTimeFromMonthKey(monthKeys[i]);
    const label = shortMonthFormat(dt);
    if (includeMonths) {
      columns.push({
        label,
        uniqueLabel: label + uniqueIdentifier,
        mks: [monthKeys[i]],
        rollupType: RollupType.Month,
      });
    }

    if (includeQuarters && isEndOfQuarter(dt, fiscalYearStartMonth)) {
      const quarterLabel = shortFiscalQuarterFormat(dt, fiscalYearStartMonth);
      columns.push({
        label: quarterLabel,
        uniqueLabel: quarterLabel + uniqueIdentifier,
        mks: [
          getMonthKey(dt.minus({ months: 2 })),
          getMonthKey(dt.minus({ months: 1 })),
          monthKeys[i],
        ],
        rollupType: RollupType.Quarter,
      });
    }

    if (includeYears && isEndOfYear(dt, fiscalYearStartMonth)) {
      const yearLabel = shortYearFormat(dt);
      columns.push({
        label: yearLabel,
        uniqueLabel: yearLabel + uniqueIdentifier,
        mks: getMonthKeysForRange(dt.minus({ months: 11 }), dt),
        rollupType: RollupType.YearToDate,
      });
    }
  }

  return columns;
}

function getSingleMonthColumn(
  dt: DateTime,
  rollupType: keyof typeof UNIQUE_IDENTIFIERS,
): TimeSeriesColumn[] {
  return [
    {
      label: shortMonthFormat(dt),
      uniqueLabel: UNIQUE_IDENTIFIERS[rollupType],
      mks: [getMonthKey(dt)],
      rollupType,
    },
  ];
}

const ROLLUP_ORDER_IF_SAME_MONTH = [
  RollupType.Month,
  RollupType.CurrentMonth,
  RollupType.LastClose,
  RollupType.QuarterToLastClose,
  RollupType.HalfYearToLastClose,
  RollupType.YearToLastClose,
  RollupType.Quarter,
  RollupType.QuarterToDate,
  RollupType.HalfYearToDate,
  RollupType.Trailing_3Months,
  RollupType.Trailing_6Months,
  RollupType.Trailing_12Months,
  RollupType.YearToDate,
];

/**
 * Returns a list of columns for the given monthKeys and rollupType,
 * sorted by the last month key of each rollup type to keep columns chronological
 * i.e. Jan '24, Feb '24, Mar '24, Q1 '24, April '24, YTD, etc.
 */
export function rollupTimePeriod(
  monthKeys: MonthKey[],
  rollupType: RollupType[],
  fiscalYearStartMonth: number,
  lastActualsTime?: string,
  rollupSortType?: RollupSortType,
): TimeSeriesColumn[] {
  const rollupColumns = rollupType.flatMap((rt) => {
    switch (rt) {
      case RollupType.YearToDate:
        return [
          {
            label: 'YTD',
            mks: getMonthKeysForYearTo(fiscalYearStartMonth, TODAY),
            rollupType: rt,
          },
        ];

      case RollupType.QuarterToDate:
        return [{ label: 'QTD', mks: getMonthKeysForQTD(fiscalYearStartMonth), rollupType: rt }];
      case RollupType.QuarterToLastClose:
        if (lastActualsTime == null) {
          throw new Error('lastActualsTime must be provided for Quarter to Last Close rollup');
        }
        return [
          {
            label: 'QLC',
            mks: getMonthKeysForQLC(fiscalYearStartMonth, lastActualsTime),
            rollupType: rt,
          },
        ];
      case RollupType.HalfYearToLastClose:
        if (lastActualsTime == null) {
          throw new Error('lastActualsTime must be provided for Half Year to Last Close rollup');
        }
        return [
          {
            label: 'HYLC',
            mks: getMonthKeysForHYTD(fiscalYearStartMonth, DateTime.fromISO(lastActualsTime)),
            rollupType: rt,
          },
        ];
      case RollupType.YearToLastClose:
        if (lastActualsTime == null) {
          throw new Error('lastActualsTime must be provided for Year to Last Close rollup');
        }
        return [
          {
            label: 'YLC',
            mks: getMonthKeysForYearTo(fiscalYearStartMonth, DateTime.fromISO(lastActualsTime)),
            rollupType: rt,
          },
        ];
      case RollupType.HalfYearToDate:
        return [
          {
            label: 'HYTD',
            mks: getMonthKeysForHYTD(fiscalYearStartMonth, TODAY),
            rollupType: rt,
          },
        ];
      case RollupType.LastClose:
        if (lastActualsTime == null) {
          throw new Error('lastActualsTime must be provided for Last Close rollup');
        }
        return getSingleMonthColumn(getDateTimeFromMonthKey(extractMonthKey(lastActualsTime)), rt);
      case RollupType.CurrentMonth:
        return getSingleMonthColumn(TODAY, rt);
      case RollupType.MonthlyAnnually:
      case RollupType.MonthlyQuarterly:
      case RollupType.MonthlyQuarterlyAnnually:
      case RollupType.Quarter:
      case RollupType.Annual:
        return getRollupTimePeriodColumns(monthKeys, fiscalYearStartMonth, rt);
      case RollupType.Month:
        return Object.entries(
          groupBy(monthKeys, (mk) => timePeriodForMonthKey(mk, rt, fiscalYearStartMonth)),
        ).map(([label, mks]) => ({
          label,
          mks,
          rollupType: rt,
        }));
      case RollupType.Trailing_3Months:
        return [
          {
            label: 'T3M',
            mks: monthKeys.slice(-3),
            rollupType: rt,
          },
        ];
      case RollupType.Trailing_6Months:
        return [
          {
            label: 'T6M',
            mks: monthKeys.slice(-6),
            rollupType: rt,
          },
        ];
      case RollupType.Trailing_12Months:
        return [
          {
            label: 'TTM',
            mks: monthKeys.slice(-12),
            rollupType: rt,
          },
        ];

      default:
        throw new Error(`Unknown rollup: ${rt}`);
    }
  });

  const uniqueRollupColumnLabels = new Set();
  const uniqueRollupColumns: TimeSeriesColumn[] = [];
  rollupColumns.forEach((col) => {
    if (col.label != null && !uniqueRollupColumnLabels.has(col.label)) {
      uniqueRollupColumnLabels.add(col.label);
      uniqueRollupColumns.push(col);
    }
  });
  if (rollupSortType === RollupSortType.Manual) {
    return uniqueRollupColumns;
  }
  // default to chronological
  return uniqueRollupColumns.sort((a, b) => {
    const aLastMonth = a.mks[a.mks.length - 1];
    const bLastMonth = b.mks[b.mks.length - 1];
    if (aLastMonth === bLastMonth) {
      if (a.rollupType === RollupType.Month) {
        return -1;
      }
      if (b.rollupType === RollupType.Month) {
        return 1;
      }
      return (
        ROLLUP_ORDER_IF_SAME_MONTH.indexOf(a.rollupType) -
        ROLLUP_ORDER_IF_SAME_MONTH.indexOf(b.rollupType)
      );
    }
    return aLastMonth?.localeCompare(bLastMonth);
  });
}

export type TimeSeriesComparisonSubColumn = {
  // A subcolumn will have either a column or a layerId, and not both.
  // TODO: In the future, we could make this type stricter to reflect ^.
  column?: ComparisonColumn;
  layerId?: LayerId;
  isActuals: boolean;
};

export type TimeSeriesColumn = {
  label: string;
  rollupType: RollupType;
  uniqueLabel?: string;
  subLabel?: TimeSeriesComparisonSubColumn;
  mks: MonthKey[];
};

export function defaultRollupReducerForFormat(driverFormat: DriverFormat) {
  switch (driverFormat) {
    case DriverFormat.Percentage:
      return RollupReducer.Avg;
    default:
      return RollupReducer.Sum;
  }
}

// modifies and aligns the date range to include the selected rollup data
export function alignDatesWithRollupOverride(
  dateRange: DateTime[],
  rollupTypes: RollupType[],
  fiscalYearStartMonth: number,
  lastActualsTime?: DateTime,
) {
  const relativeToCurrentYear = [
    RollupType.CurrentMonth,
    RollupType.QuarterToDate,
    RollupType.YearToDate,
  ].some((rt) => rollupTypes.includes(rt));

  const end = relativeToCurrentYear ? DateTime.now() : dateRange[1];
  let start = end < dateRange[0] ? end : dateRange[0];

  if (lastActualsTime != null && rollupTypes.includes(RollupType.LastClose)) {
    start = lastActualsTime < start ? lastActualsTime : start;
  }

  const [alignedStart, alignedEnd] = alignDates(start, end, rollupTypes, fiscalYearStartMonth);

  return {
    start: getISOTimeWithoutMs(alignedStart.toISO()),
    end: getISOTimeWithoutMs(alignedEnd.toISO()),
  };
}

// returns the largest unit in the rollupType array
export function unitForRollupType(rollupType: RollupType[]): DateTimeUnit {
  const contains = (rt: RollupType) => rollupType.includes(rt);
  if (
    [
      RollupType.MonthlyAnnually,
      RollupType.MonthlyQuarterlyAnnually,
      RollupType.YearToDate,
      RollupType.YearToLastClose,
      RollupType.Annual,
      RollupType.HalfYearToDate,
      RollupType.HalfYearToLastClose,
      RollupType.Trailing_6Months,
      RollupType.Trailing_12Months,
    ].some(contains)
  ) {
    return 'year';
  } else if (
    [
      RollupType.MonthlyQuarterly,
      RollupType.QuarterToDate,
      RollupType.Quarter,
      RollupType.QuarterToLastClose,
      RollupType.Trailing_3Months,
    ].some(contains)
  ) {
    return 'quarter';
  } else if ([RollupType.Month, RollupType.CurrentMonth, RollupType.LastClose].some(contains)) {
    return 'month';
  } else {
    throw new Error(`Unknown rollup: [${rollupType.join(',')}]`);
  }
}

export function alignDate(
  date: DateTime,
  isStart: boolean,
  rollupType: RollupType[],
  fiscalYearStartMonth: number,
): DateTime {
  const unit = unitForRollupType(rollupType);
  const subtractedFiscalYearStart = date.plus({ months: -(fiscalYearStartMonth - 1) });
  const startOfOrEndOf = isStart
    ? subtractedFiscalYearStart.startOf(unit)
    : subtractedFiscalYearStart.endOf(unit);
  return startOfOrEndOf.plus({ months: fiscalYearStartMonth - 1 }).startOf('month');
}

export function alignDates(
  start: DateTime,
  end: DateTime,
  rollupType: RollupType[],
  fiscalYearStartMonth: number,
) {
  return [
    alignDate(start, true, rollupType, fiscalYearStartMonth),
    alignDate(end, false, rollupType, fiscalYearStartMonth),
  ];
}

export function offsetDateTimeForFY(
  dt: DateTime,
  rollupType: RollupType[],
  fiscalYearStartMonth: number,
  dir: 'in' | 'out',
) {
  if (unitForRollupType(rollupType) === 'month') {
    return dt;
  }
  return dt.plus({ months: (dir === 'in' ? -1 : 1) * (fiscalYearStartMonth - 1) });
}
