import React from 'react';

import { DATABASE_PROPERTY_TYPE_ICONS } from 'config/businessObjects';
import { DRIVER_FORMAT_ICONS } from 'config/drivers';
import { ValueType } from 'generated/graphql';
import useAppSelector from 'hooks/useAppSelector';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { businessObjectFieldSpecSelector } from 'selectors/businessObjectFieldSpecsSelector';
import { dimensionalPropertySelector, driverPropertySelector } from 'selectors/collectionSelector';
import { driversByIdForLayerSelector } from 'selectors/driversSelector';
import { resolvedBusinessObjectFieldSpecFormatSelector } from 'selectors/resolvedEntityFormatSelector';
import LinkIcon from 'vectors/Link';

interface Props {
  fieldSpecId: BusinessObjectFieldSpecId;
}

const ObjectFieldTypeIcon: React.FC<Props> = ({ fieldSpecId }) => {
  const driversById = useAppSelector(driversByIdForLayerSelector);
  const fieldSpec = useAppSelector((state) =>
    businessObjectFieldSpecSelector(state, { id: fieldSpecId }),
  );
  const format = useAppSelector((state) =>
    resolvedBusinessObjectFieldSpecFormatSelector(state, fieldSpecId),
  );
  const dimDriverProperty = useAppSelector((state) => driverPropertySelector(state, fieldSpecId));
  const dimensionalProperty = useAppSelector((state) =>
    dimensionalPropertySelector(state, fieldSpecId),
  );

  if (dimensionalProperty != null) {
    return DATABASE_PROPERTY_TYPE_ICONS[ValueType.Attribute];
  }

  if (fieldSpec == null && dimDriverProperty == null) {
    return null;
  }

  let type;
  if (dimDriverProperty != null) {
    const driver = driversById[dimDriverProperty.driverId];
    if (driver?.valueType != null) {
      type = driver.valueType;
    } else {
      type = driver != null ? ValueType.Number : fieldSpec?.type;
    }
  } else if (fieldSpec != null) {
    type = fieldSpec.type;
  }

  switch (type) {
    case ValueType.Attribute:
    case ValueType.Timestamp: {
      return DATABASE_PROPERTY_TYPE_ICONS[type];
    }
    case ValueType.Number: {
      return DRIVER_FORMAT_ICONS[format];
    }
    default: {
      return <LinkIcon />;
    }
  }
};

export default ObjectFieldTypeIcon;
