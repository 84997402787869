import { gql } from '@urql/core';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  LooseTime: string;
  Time: string;
};

export type AiDriver = {
  __typename?: 'AIDriver';
  forecast?: Maybe<AiForecast>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AiDriverGroup = {
  __typename?: 'AIDriverGroup';
  drivers: Array<AiDriver>;
  name: Scalars['String'];
};

export type AiDriverGroupInput = {
  drivers: Array<AiDriverInput>;
  name: Scalars['String'];
};

export type AiDriverGroupResponse = AiResponse & {
  __typename?: 'AIDriverGroupResponse';
  cursor: Scalars['String'];
  groups: Array<AiDriverGroup>;
};

export type AiDriverInput = {
  forecast?: InputMaybe<Array<AiForecastInput>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AiEvent = {
  __typename?: 'AIEvent';
  customCurvePoints: Array<TimeSeriesPoint>;
  driver?: Maybe<AiDriver>;
  id: Scalars['ID'];
  impactType: ImpactType;
  object?: Maybe<AiObjectField>;
};

export type AiEventGroup = {
  __typename?: 'AIEventGroup';
  events: Array<AiEvent>;
  name: Scalars['String'];
};

export type AiEventGroupInput = {
  events: Array<AiEventInput>;
  name: Scalars['String'];
};

export type AiEventGroupResponse = AiResponse & {
  __typename?: 'AIEventGroupResponse';
  cursor: Scalars['String'];
  groups: Array<AiEventGroup>;
};

export type AiEventGroupsInput = {
  groups: Array<AiEventGroupInput>;
};

export type AiEventInput = {
  customCurvePoints: Array<TimeSeriesPointInput>;
  driver?: InputMaybe<AiDriverInput>;
  id: Scalars['ID'];
  impactType: ImpactType;
  object?: InputMaybe<AiObjectFieldInput>;
};

export type AiForecast = {
  __typename?: 'AIForecast';
  formula: Scalars['String'];
};

export type AiForecastInput = {
  formula: Scalars['String'];
};

export type AiGroupDriversInput = {
  context?: InputMaybe<Array<AiModelInput>>;
  orgId: Scalars['String'];
  prompt?: InputMaybe<Array<AiDriverInput>>;
  refinement?: InputMaybe<AiRefinementInput>;
};

export type AiGroupEventsInput = {
  context?: InputMaybe<Array<AiEventGroupInput>>;
  orgId: Scalars['String'];
  prompt?: InputMaybe<Array<AiEventInput>>;
  refinement?: InputMaybe<AiRefinementInput>;
};

export type AiModel = {
  __typename?: 'AIModel';
  groups: Array<AiDriverGroup>;
  name: Scalars['String'];
};

export type AiModelInput = {
  groups: Array<AiDriverGroupInput>;
  name: Scalars['String'];
};

export type AiObjectField = {
  __typename?: 'AIObjectField';
  fieldName: Scalars['String'];
  fieldSpecId: Scalars['ID'];
  objectFields?: Maybe<Array<AiObjectFieldValue>>;
  objectId: Scalars['ID'];
  objectName: Scalars['String'];
  objectSpecName: Scalars['String'];
};

export type AiObjectFieldInput = {
  fieldName: Scalars['String'];
  fieldSpecId: Scalars['String'];
  objectFields?: InputMaybe<Array<AiObjectFieldValueInput>>;
  objectId: Scalars['ID'];
  objectName: Scalars['String'];
  objectSpecName: Scalars['String'];
};

export type AiObjectFieldValue = {
  __typename?: 'AIObjectFieldValue';
  fieldName: Scalars['String'];
  fieldValue: Scalars['String'];
};

export type AiObjectFieldValueInput = {
  fieldName: Scalars['String'];
  fieldValue: Scalars['String'];
};

export type AiRefinementInput = {
  cursor: Scalars['String'];
  refinementText?: InputMaybe<Scalars['String']>;
  refinementType: AiRefinementType;
};

export enum AiRefinementType {
  GenericRerun = 'GENERIC_RERUN',
  UserSpecified = 'USER_SPECIFIED',
}

export type AiResponse = {
  cursor: Scalars['String'];
};

export type AccessControlEntry = {
  __typename?: 'AccessControlEntry';
  accessRule: AccessRule;
  entityWithAccess: AccessorEntity;
  grantedAt: Scalars['Time'];
  grantedBy: Scalars['String'];
  id: Scalars['ID'];
};

export type AccessControlEntryInput = {
  accessRule: AccessRule;
  entityWithAccess: AccessorEntityInput;
};

export type AccessResource = Node & {
  __typename?: 'AccessResource';
  accessControlList: Array<AccessControlEntry>;
  id: Scalars['ID'];
  orgId: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
  resourceId: Scalars['ID'];
  shouldInherit: Scalars['Boolean'];
  type: AccessResourceType;
};

export type AccessResourceInput = {
  parent?: InputMaybe<AccessResourceInput>;
  resourceId: Scalars['ID'];
  type: AccessResourceType;
};

export enum AccessResourceType {
  Dataset = 'DATASET',
  Driver = 'DRIVER',
  DriverGroup = 'DRIVER_GROUP',
  Layer = 'LAYER',
  ObjectFieldSpec = 'OBJECT_FIELD_SPEC',
  Page = 'PAGE',
  Submodel = 'SUBMODEL',
}

export enum AccessRule {
  Full = 'FULL',
  Read = 'READ',
  Revoked = 'REVOKED',
  Write = 'WRITE',
}

export type AccessorEntity = {
  __typename?: 'AccessorEntity';
  id: Scalars['String'];
  type: AccessorEntityType;
};

export type AccessorEntityInput = {
  id: Scalars['String'];
  type: AccessorEntityType;
};

export enum AccessorEntityType {
  User = 'USER',
  UserGroup = 'USER_GROUP',
}

export type AccountingAccount = {
  __typename?: 'AccountingAccount';
  linkedAccountId: Scalars['String'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  remoteId: Scalars['String'];
};

export type AccountingContact = {
  __typename?: 'AccountingContact';
  linkedAccountId: Scalars['String'];
  name: Scalars['String'];
  remoteId: Scalars['String'];
};

export type AccountingProperties = {
  __typename?: 'AccountingProperties';
  accounts: Array<AccountingAccount>;
  contacts: Array<AccountingContact>;
  trackingCategories: Array<AccountingTrackingCategory>;
};

export type AccountingTrackingCategory = {
  __typename?: 'AccountingTrackingCategory';
  linkedAccountId: Scalars['String'];
  name: Scalars['String'];
  remoteId: Scalars['String'];
};

export type AccountingTransaction = {
  __typename?: 'AccountingTransaction';
  accountRemoteId: Scalars['String'];
  amount: Scalars['String'];
  contactRemoteId?: Maybe<Scalars['String']>;
  date: Scalars['Time'];
  linkedAccountId: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  remoteId: Scalars['String'];
  trackingCategoryRemoteIds?: Maybe<Array<Scalars['String']>>;
};

export type Actuals = {
  __typename?: 'Actuals';
  formula?: Maybe<Scalars['String']>;
  timeSeries?: Maybe<Array<TimeSeriesPoint>>;
};

export type ActualsInput = {
  formula?: InputMaybe<Scalars['String']>;
  timeSeries?: InputMaybe<Array<TimeSeriesPointInput>>;
};

export type ActualsWithNullableTimeSeries = {
  __typename?: 'ActualsWithNullableTimeSeries';
  formula?: Maybe<Scalars['String']>;
  timeSeries?: Maybe<Array<NullableTimeSeriesPoint>>;
};

export type AddControlEntryInput = {
  accessRule: AccessRule;
  accessor: AccessorEntityInput;
  resourceId: Scalars['ID'];
  resourceParent?: InputMaybe<AccessResourceInput>;
  resourceType: AccessResourceType;
};

export type AggregatedTransactionsTimeSeriesInput = {
  query?: InputMaybe<TransactionFilterQueryInput>;
};

export type ApiSecret = {
  __typename?: 'ApiSecret';
  apiSecret: Scalars['String'];
};

export type Attribute = Node & {
  __typename?: 'Attribute';
  deleted: Scalars['Boolean'];
  dimensionId: Scalars['ID'];
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type AttributeCreateData = {
  __typename?: 'AttributeCreateData';
  dimensionId: Scalars['String'];
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type AttributeCreateInput = {
  dimensionId: Scalars['ID'];
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type AttributeDeleteData = {
  __typename?: 'AttributeDeleteData';
  id: Scalars['ID'];
};

export type AttributeDeleteInput = {
  id: Scalars['ID'];
};

export type AttributeProperty = {
  __typename?: 'AttributeProperty';
  attributeId: Scalars['String'];
  dimensionalPropertyId: Scalars['String'];
};

export type AttributePropertyCreateData = {
  __typename?: 'AttributePropertyCreateData';
  attributeId: Scalars['String'];
  dimensionalPropertyId: Scalars['String'];
};

export type AttributePropertyCreateInput = {
  attributeId: Scalars['String'];
  dimensionalPropertyId: Scalars['String'];
};

export type AttributePropertyUpdateData = {
  __typename?: 'AttributePropertyUpdateData';
  attributeId?: Maybe<Scalars['String']>;
  dimensionalPropertyId: Scalars['String'];
};

export type AttributePropertyUpdateInput = {
  attributeId?: InputMaybe<Scalars['String']>;
  dimensionalPropertyId: Scalars['String'];
};

export type AttributeRestoreData = {
  __typename?: 'AttributeRestoreData';
  id: Scalars['ID'];
};

export type AttributeRestoreInput = {
  id: Scalars['ID'];
};

export type AttributeUpdateData = {
  __typename?: 'AttributeUpdateData';
  id: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
};

export type AttributeUpdateInput = {
  id: Scalars['ID'];
  value?: InputMaybe<Scalars['String']>;
};

export type AuthenticatedUser = {
  __typename?: 'AuthenticatedUser';
  createdAt: Scalars['Time'];
  email: Scalars['String'];
  hasSuperPermission: Scalars['Boolean'];
  id: Scalars['ID'];
  intercomHash?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  profileImage?: Maybe<Scalars['String']>;
};

export type BasicDriverCreateData = {
  __typename?: 'BasicDriverCreateData';
  actuals: Actuals;
  forecast: Forecast;
  rollup?: Maybe<Rollup>;
};

export type BasicDriverCreateInput = {
  actuals: ActualsInput;
  forecast: ForecastInput;
  rollup?: InputMaybe<RollupInput>;
};

export type BasicDriverExtension = {
  __typename?: 'BasicDriverExtension';
  actuals: Actuals;
  forecast: Forecast;
  rollup?: Maybe<Rollup>;
  trackParentLayerFields?: Maybe<TrackParentLayerFields>;
};

export type BasicResponse = {
  __typename?: 'BasicResponse';
  success: Scalars['Boolean'];
};

/** A Block represents a UI component within a configurable Page. */
export type Block = {
  __typename?: 'Block';
  blockConfig: BlockConfig;
  explanation?: Maybe<BlockExplanation>;
  id: Scalars['ID'];
  name: Scalars['String'];
  pageId: Scalars['String'];
  sortIndex?: Maybe<Scalars['Int']>;
  type: BlockType;
};

export enum BlockAlignment {
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT',
}

export type BlockColumn = {
  __typename?: 'BlockColumn';
  blockIds: Array<Scalars['ID']>;
  id: Scalars['ID'];
};

export type BlockColumnInput = {
  blockIds: Array<Scalars['ID']>;
  id: Scalars['ID'];
};

export type BlockColumnOptions = {
  __typename?: 'BlockColumnOptions';
  key: Scalars['String'];
  visible?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['Int']>;
};

export type BlockColumnOptionsInput = {
  key: Scalars['String'];
  visible?: InputMaybe<Scalars['Boolean']>;
  width?: InputMaybe<Scalars['Int']>;
};

export enum BlockComparisonLayout {
  Columns = 'COLUMNS',
  Rows = 'ROWS',
}

export type BlockComparisons = {
  __typename?: 'BlockComparisons';
  baselineLayerId?: Maybe<Scalars['String']>;
  columns?: Maybe<Array<ComparisonColumn>>;
  layerIds: Array<Scalars['String']>;
  layout?: Maybe<BlockComparisonLayout>;
  timePeriods?: Maybe<Array<ComparisonTimePeriod>>;
};

export type BlockComparisonsInput = {
  baselineLayerId?: InputMaybe<Scalars['String']>;
  columns?: InputMaybe<Array<ComparisonColumn>>;
  layerIds: Array<Scalars['String']>;
  layout?: InputMaybe<BlockComparisonLayout>;
  timePeriods?: InputMaybe<Array<ComparisonTimePeriod>>;
};

export type BlockConfig = {
  __typename?: 'BlockConfig';
  blockAlignment?: Maybe<BlockAlignment>;
  blockViewOptions?: Maybe<BlockViewOptions>;
  businessObjectSpecId?: Maybe<Scalars['String']>;
  columns?: Maybe<Array<BlockColumnOptions>>;
  comparisons?: Maybe<BlockComparisons>;
  dateRange?: Maybe<DateRange>;
  driverIndentations?: Maybe<Array<DriverIndentation>>;
  /** @deprecated Expanded IDs is no longer saved to block config, its user-local now */
  expandedIds?: Maybe<Array<Scalars['String']>>;
  fileMetadata?: Maybe<FileMetadata>;
  filterBy?: Maybe<Array<BlockFilter>>;
  fiscalYearStartMonth?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<BlockGroupBy>;
  hideHeader?: Maybe<Scalars['Boolean']>;
  idFilter?: Maybe<Array<Scalars['String']>>;
  imageBlockUrl?: Maybe<Scalars['String']>;
  impactDisplay?: Maybe<ImpactDisplay>;
  mediaWidth?: Maybe<Scalars['Int']>;
  objectFieldSpecAsTimeSeriesId?: Maybe<Scalars['String']>;
  objectSpecDisplayAs?: Maybe<ObjectSpecDisplayAsType>;
  rollupSortType?: Maybe<RollupSortType>;
  /** @deprecated rollupType is no longer a single value, use rollupTypes instead */
  rollupType?: Maybe<RollupType>;
  rollupTypes?: Maybe<Array<RollupType>>;
  showRestricted?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<BlockSortBy>;
  textBlockBody?: Maybe<Scalars['String']>;
  videoBlockUrl?: Maybe<Scalars['String']>;
  viewAtTime?: Maybe<TimePeriod>;
};

export type BlockConfigInput = {
  blockAlignment?: InputMaybe<BlockAlignment>;
  blockViewOptions?: InputMaybe<BlockViewOptionsInput>;
  businessObjectSpecId?: InputMaybe<Scalars['String']>;
  columns?: InputMaybe<Array<BlockColumnOptionsInput>>;
  comparisons?: InputMaybe<BlockComparisonsInput>;
  dateRange?: InputMaybe<DateRangeInput>;
  driverIndentations?: InputMaybe<Array<DriverIndentationInput>>;
  /** @deprecated Expanded IDs is no longer saved to block config, its user-local now */
  expandedIds?: InputMaybe<Array<Scalars['String']>>;
  fileMetadata?: InputMaybe<FileMetadataInput>;
  filterBy?: InputMaybe<Array<BlockFilterInput>>;
  fiscalYearStartMonth?: InputMaybe<Scalars['Int']>;
  groupBy?: InputMaybe<BlockGroupByInput>;
  hideHeader?: InputMaybe<Scalars['Boolean']>;
  idFilter?: InputMaybe<Array<Scalars['String']>>;
  imageBlockUrl?: InputMaybe<Scalars['String']>;
  impactDisplay?: InputMaybe<ImpactDisplayInput>;
  mediaWidth?: InputMaybe<Scalars['Int']>;
  objectFieldSpecAsTimeSeriesId?: InputMaybe<Scalars['String']>;
  objectSpecDisplayAs?: InputMaybe<ObjectSpecDisplayAsType>;
  rollupSortType?: InputMaybe<RollupSortType>;
  /** @deprecated rollupType is no longer a single value, use rollupTypes instead */
  rollupType?: InputMaybe<RollupType>;
  rollupTypes?: InputMaybe<Array<RollupType>>;
  showRestricted?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<BlockSortByInput>;
  textBlockBody?: InputMaybe<Scalars['String']>;
  videoBlockUrl?: InputMaybe<Scalars['String']>;
  viewAtTime?: InputMaybe<TimePeriodInput>;
};

export type BlockCreateData = {
  __typename?: 'BlockCreateData';
  blockConfig: BlockConfig;
  id: Scalars['ID'];
  name: Scalars['String'];
  pageId: Scalars['String'];
  sortIndex?: Maybe<Scalars['Int']>;
  type: BlockType;
};

export type BlockCreateInput = {
  blockConfig: BlockConfigInput;
  id: Scalars['ID'];
  name: Scalars['String'];
  pageId: Scalars['String'];
  sortIndex?: InputMaybe<Scalars['Int']>;
  type: BlockType;
};

export type BlockDeleteData = {
  __typename?: 'BlockDeleteData';
  id: Scalars['ID'];
};

export type BlockDeleteInput = {
  id: Scalars['ID'];
};

export type BlockDriverGridComparisonExplanation = {
  __typename?: 'BlockDriverGridComparisonExplanation';
  driverId: Scalars['String'];
  explanation: Scalars['String'];
};

export type BlockEvalGroup = {
  __typename?: 'BlockEvalGroup';
  ids: Array<Scalars['String']>;
  meta: BlockEvalGroupMeta;
};

export type BlockEvalGroupMeta = {
  __typename?: 'BlockEvalGroupMeta';
  attributeId: Scalars['String'];
  dimensionId: Scalars['String'];
  fieldSpecId: Scalars['String'];
  key: Scalars['String'];
};

export type BlockEvalRequest = {
  blockId: Scalars['String'];
  dateRange: FormulaDateRangeInput;
  layerId: Scalars['String'];
  monthKey: Scalars['String'];
  requestId: Scalars['String'];
};

export type BlockEvalResult = {
  __typename?: 'BlockEvalResult';
  blockId: Scalars['String'];
  cacheKey: Scalars['String'];
  data: Array<BlockEvalGroup>;
  layerId: Scalars['String'];
  requestId: Scalars['String'];
};

export type BlockExplanation = {
  __typename?: 'BlockExplanation';
  driverGridComparisonExplanations: Array<BlockDriverGridComparisonExplanation>;
};

export type BlockFilter = {
  __typename?: 'BlockFilter';
  businessObjectSpecId?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  filterKey?: Maybe<Scalars['String']>;
  filterOp?: Maybe<BlockFilterOperator>;
  filterType: BlockFilterType;
  filterValues?: Maybe<Array<Scalars['String']>>;
};

export type BlockFilterInput = {
  businessObjectSpecId?: InputMaybe<Scalars['String']>;
  expression?: InputMaybe<Scalars['String']>;
  filterKey?: InputMaybe<Scalars['String']>;
  filterOp?: InputMaybe<BlockFilterOperator>;
  filterType: BlockFilterType;
  filterValues?: InputMaybe<Array<Scalars['String']>>;
};

export enum BlockFilterOperator {
  Equals = 'EQUALS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  NotEquals = 'NOT_EQUALS',
}

export enum BlockFilterType {
  /** @deprecated Use the generalized `KEY_VALUE` type instead */
  BusinessObject = 'BUSINESS_OBJECT',
  Expression = 'EXPRESSION',
  KeyValue = 'KEY_VALUE',
}

export type BlockGroupBy = {
  __typename?: 'BlockGroupBy';
  /** @deprecated Date grouping was not used by active customers */
  date?: Maybe<DateGroupByExtension>;
  driverField?: Maybe<DriverFieldGroupByExtension>;
  driverProperty?: Maybe<DriverPropertyGroupByExtension>;
  groupByType: BlockGroupByType;
  objectField?: Maybe<ObjectFieldGroupByExtension>;
};

export type BlockGroupByInput = {
  /** @deprecated Date grouping was not used by active customers */
  date?: InputMaybe<DateGroupByExtensionInput>;
  driverField?: InputMaybe<DriverFieldGroupByExtensionInput>;
  driverProperty?: InputMaybe<DriverPropertyGroupByExtensionInput>;
  groupByType: BlockGroupByType;
  objectField?: InputMaybe<ObjectFieldGroupByExtensionInput>;
};

export enum BlockGroupByType {
  /** @deprecated Date grouping was not used by active customers */
  Date = 'DATE',
  DriverField = 'DRIVER_FIELD',
  DriverProperty = 'DRIVER_PROPERTY',
  None = 'NONE',
  ObjectField = 'OBJECT_FIELD',
}

export type BlockRow = {
  __typename?: 'BlockRow';
  columns: Array<BlockColumn>;
  id: Scalars['ID'];
};

export type BlockRowInput = {
  columns: Array<BlockColumnInput>;
  id: Scalars['ID'];
};

export type BlockSortBy = {
  __typename?: 'BlockSortBy';
  impact?: Maybe<ImpactSortExtension>;
  object?: Maybe<ObjectSortExtension>;
  sortType: BlockSortType;
};

export type BlockSortByInput = {
  impact?: InputMaybe<ImpactSortExtensionInput>;
  object?: InputMaybe<ObjectSortExtensionInput>;
  sortType: BlockSortType;
};

export enum BlockSortType {
  Impact = 'IMPACT',
  Manual = 'MANUAL',
  Object = 'OBJECT',
  StartDate = 'START_DATE',
}

export enum BlockType {
  DriverCharts = 'DRIVER_CHARTS',
  DriverGrid = 'DRIVER_GRID',
  Image = 'IMAGE',
  /** @deprecated Users can no longer create this BlockType. Use OBJECT_TABLE instead */
  ObjectGrid = 'OBJECT_GRID',
  ObjectTable = 'OBJECT_TABLE',
  PlanTimeline = 'PLAN_TIMELINE',
  Text = 'TEXT',
  Video = 'VIDEO',
}

export type BlockUpdateData = {
  __typename?: 'BlockUpdateData';
  blockConfig?: Maybe<BlockConfig>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
  type?: Maybe<BlockType>;
};

export type BlockUpdateInput = {
  blockConfig?: InputMaybe<BlockConfigInput>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  sortIndex?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<BlockType>;
};

export enum BlockViewAsType {
  AreaChart = 'AREA_CHART',
  BarChart = 'BAR_CHART',
  /** @deprecated use LINE_CHART */
  Chart = 'CHART',
  ComboChart = 'COMBO_CHART',
  CurrentValue = 'CURRENT_VALUE',
  DonutChart = 'DONUT_CHART',
  LineChart = 'LINE_CHART',
  NightingaleChart = 'NIGHTINGALE_CHART',
  PieChart = 'PIE_CHART',
  TreemapChart = 'TREEMAP_CHART',
}

export type BlockViewOptions = {
  __typename?: 'BlockViewOptions';
  aggregateValues?: Maybe<Scalars['Boolean']>;
  chartDisplay?: Maybe<ChartDisplay>;
  chartGroupingType?: Maybe<ChartGroupingType>;
  chartSize?: Maybe<ChartSize>;
  dateRangeComparison?: Maybe<DateRangeComparison>;
  showMilestones?: Maybe<Scalars['Boolean']>;
  viewAsType?: Maybe<BlockViewAsType>;
};

export type BlockViewOptionsInput = {
  aggregateValues?: InputMaybe<Scalars['Boolean']>;
  chartDisplay?: InputMaybe<ChartDisplayInput>;
  chartGroupingType?: InputMaybe<ChartGroupingType>;
  chartSize?: InputMaybe<ChartSize>;
  dateRangeComparison?: InputMaybe<DateRangeComparisonInput>;
  showMilestones?: InputMaybe<Scalars['Boolean']>;
  viewAsType?: InputMaybe<BlockViewAsType>;
};

/**
 * BlocksPage is a configurable page composed of Blocks. Each org can have several BlocksPages.
 * TODO: Rename BlocksPage to Page once we can deprecate the existing Page type
 */
export type BlocksPage = {
  __typename?: 'BlocksPage';
  blockIds: Array<Scalars['String']>;
  createdByUserId?: Maybe<Scalars['String']>;
  dateRange?: Maybe<DateRange>;
  id: Scalars['ID'];
  internalPageType?: Maybe<Scalars['String']>;
  internalPageVersion?: Maybe<Scalars['Int']>;
  layout?: Maybe<Array<BlockRow>>;
  name: Scalars['String'];
  parent?: Maybe<Scalars['ID']>;
  sortIndex?: Maybe<Scalars['Int']>;
};

export type BlocksPageCreateData = {
  __typename?: 'BlocksPageCreateData';
  createdByUserId?: Maybe<Scalars['String']>;
  dateRange?: Maybe<DateRange>;
  id: Scalars['ID'];
  internalPageType?: Maybe<Scalars['String']>;
  internalPageVersion?: Maybe<Scalars['Int']>;
  layout?: Maybe<Array<BlockRow>>;
  name: Scalars['String'];
  parent?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
};

export type BlocksPageCreateInput = {
  createdByUserId?: InputMaybe<Scalars['String']>;
  dateRange?: InputMaybe<DateRangeInput>;
  id: Scalars['ID'];
  internalPageType?: InputMaybe<Scalars['String']>;
  internalPageVersion?: InputMaybe<Scalars['Int']>;
  layout?: InputMaybe<Array<BlockRowInput>>;
  name: Scalars['String'];
  parent?: InputMaybe<Scalars['String']>;
  sortIndex?: InputMaybe<Scalars['Int']>;
};

export type BlocksPageDeleteData = {
  __typename?: 'BlocksPageDeleteData';
  id: Scalars['ID'];
};

export type BlocksPageDeleteInput = {
  id: Scalars['ID'];
};

export type BlocksPageUpdateData = {
  __typename?: 'BlocksPageUpdateData';
  dateRange?: Maybe<DateRange>;
  id: Scalars['ID'];
  layout?: Maybe<Array<BlockRow>>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
};

export type BlocksPageUpdateInput = {
  dateRange?: InputMaybe<DateRangeInput>;
  deleteLayout?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  layout?: InputMaybe<Array<BlockRowInput>>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['String']>;
  sortIndex?: InputMaybe<Scalars['Int']>;
};

export type BuiltInAttributeData = {
  __typename?: 'BuiltInAttributeData';
  type: BuiltInDimensionType;
  value: Scalars['String'];
};

export type BuiltInAttributeInput = {
  type: BuiltInDimensionType;
  value: Scalars['String'];
};

export enum BuiltInDimensionType {
  CalendarTime = 'CALENDAR_TIME',
  RelativeTime = 'RELATIVE_TIME',
}

/** BusinessObjects */
export type BusinessObject = Node & {
  __typename?: 'BusinessObject';
  collectionEntry?: Maybe<CollectionEntry>;
  defaultEventGroupId?: Maybe<Scalars['String']>;
  fields: Array<BusinessObjectField>;
  id: Scalars['ID'];
  metadata?: Maybe<BusinessObjectMetadata>;
  name: Scalars['String'];
  specId: Scalars['String'];
};

export type BusinessObjectCreateData = {
  __typename?: 'BusinessObjectCreateData';
  collectionEntry?: Maybe<CollectionEntryCreateData>;
  defaultEventGroupId?: Maybe<Scalars['String']>;
  fields: Array<BusinessObjectFieldData>;
  id: Scalars['ID'];
  metadata?: Maybe<BusinessObjectMetadataData>;
  name: Scalars['String'];
  specId: Scalars['String'];
};

export type BusinessObjectCreateInput = {
  collectionEntry?: InputMaybe<CollectionEntryCreateInput>;
  defaultEventGroupId?: InputMaybe<Scalars['String']>;
  fields: Array<BusinessObjectFieldInput>;
  id: Scalars['ID'];
  isRestricted?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<BusinessObjectMetadataInput>;
  name: Scalars['String'];
  specId: Scalars['String'];
};

export type BusinessObjectDefaultNameEntry = {
  __typename?: 'BusinessObjectDefaultNameEntry';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type BusinessObjectDefaultNameEntryInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type BusinessObjectDeleteData = {
  __typename?: 'BusinessObjectDeleteData';
  id: Scalars['ID'];
};

export type BusinessObjectDeleteInput = {
  id: Scalars['ID'];
};

/** BusinessObject Field */
export type BusinessObjectField = Node & {
  __typename?: 'BusinessObjectField';
  fieldSpecId: Scalars['String'];
  id: Scalars['ID'];
  value?: Maybe<BusinessObjectFieldValue>;
};

export type BusinessObjectFieldData = {
  __typename?: 'BusinessObjectFieldData';
  fieldSpecId: Scalars['String'];
  id: Scalars['ID'];
  value: BusinessObjectFieldValue;
};

export type BusinessObjectFieldDefaultValueEntry = {
  __typename?: 'BusinessObjectFieldDefaultValueEntry';
  key: Scalars['String'];
  value: BusinessObjectFieldValue;
};

export type BusinessObjectFieldDefaultValueEntryInput = {
  key: Scalars['String'];
  value: BusinessObjectFieldValueInput;
};

export type BusinessObjectFieldInput = {
  fieldSpecId: Scalars['String'];
  id: Scalars['ID'];
  isRestricted?: InputMaybe<Scalars['Boolean']>;
  value: BusinessObjectFieldValueInput;
};

/** BusinessObject Spec Fields */
export type BusinessObjectFieldSpec = {
  __typename?: 'BusinessObjectFieldSpec';
  currencyISOCode?: Maybe<Scalars['String']>;
  decimalPlaces?: Maybe<Scalars['Int']>;
  defaultForecast?: Maybe<Forecast>;
  defaultValues?: Maybe<Array<BusinessObjectFieldDefaultValueEntry>>;
  dimensionId?: Maybe<Scalars['String']>;
  extFieldSpecKey?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integrationDataOverridesForecast?: Maybe<Scalars['Boolean']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  numericFormat?: Maybe<DriverFormat>;
  propagateIntegrationData?: Maybe<Scalars['Boolean']>;
  type: ValueType;
};

export type BusinessObjectFieldSpecData = {
  __typename?: 'BusinessObjectFieldSpecData';
  currencyISOCode?: Maybe<Scalars['String']>;
  decimalPlaces?: Maybe<Scalars['Int']>;
  defaultForecast?: Maybe<Forecast>;
  defaultValues?: Maybe<Array<BusinessObjectFieldDefaultValueEntry>>;
  dimensionId?: Maybe<Scalars['String']>;
  extFieldSpecKey?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integrationDataOverridesForecast?: Maybe<Scalars['Boolean']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  numericFormat?: Maybe<DriverFormat>;
  propagateIntegrationData?: Maybe<Scalars['Boolean']>;
  type: ValueType;
};

export type BusinessObjectFieldSpecInput = {
  currencyISOCode?: InputMaybe<Scalars['String']>;
  decimalPlaces?: InputMaybe<Scalars['Int']>;
  defaultForecast?: InputMaybe<ForecastInput>;
  defaultValues?: InputMaybe<Array<BusinessObjectFieldDefaultValueEntryInput>>;
  dimensionId?: InputMaybe<Scalars['String']>;
  extFieldSpecKey?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  integrationDataOverridesForecast?: InputMaybe<Scalars['Boolean']>;
  isRestricted?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  numericFormat?: InputMaybe<DriverFormat>;
  propagateIntegrationData?: InputMaybe<Scalars['Boolean']>;
  type: ValueType;
};

export type BusinessObjectFieldSpecUpdateData = {
  __typename?: 'BusinessObjectFieldSpecUpdateData';
  currencyISOCode?: Maybe<Scalars['String']>;
  decimalPlaces?: Maybe<Scalars['Int']>;
  defaultForecast?: Maybe<Forecast>;
  dimensionId?: Maybe<Scalars['String']>;
  extFieldSpecKey?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integrationDataOverridesForecast?: Maybe<Scalars['Boolean']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  numericFormat?: Maybe<DriverFormat>;
  propagateIntegrationData?: Maybe<Scalars['Boolean']>;
  removeDefaultForecast?: Maybe<Scalars['Boolean']>;
  removeDefaultValues?: Maybe<Array<Scalars['String']>>;
  setDefaultValues?: Maybe<Array<BusinessObjectFieldDefaultValueEntry>>;
  type?: Maybe<ValueType>;
};

export type BusinessObjectFieldSpecUpdateInput = {
  currencyISOCode?: InputMaybe<Scalars['String']>;
  decimalPlaces?: InputMaybe<Scalars['Int']>;
  defaultForecast?: InputMaybe<ForecastInput>;
  dimensionId?: InputMaybe<Scalars['String']>;
  extFieldSpecKey?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  integrationDataOverridesForecast?: InputMaybe<Scalars['Boolean']>;
  isRestricted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  numericFormat?: InputMaybe<DriverFormat>;
  propagateIntegrationData?: InputMaybe<Scalars['Boolean']>;
  removeDefaultForecast?: InputMaybe<Scalars['Boolean']>;
  removeDefaultValues?: InputMaybe<Array<Scalars['String']>>;
  setDefaultValues?: InputMaybe<Array<BusinessObjectFieldDefaultValueEntryInput>>;
  type?: InputMaybe<ValueType>;
};

export type BusinessObjectFieldUpdateData = {
  __typename?: 'BusinessObjectFieldUpdateData';
  id: Scalars['ID'];
  value?: Maybe<BusinessObjectFieldValueWithNullableTimeseries>;
};

export type BusinessObjectFieldUpdateInput = {
  id: Scalars['ID'];
  value?: InputMaybe<BusinessObjectFieldValueUpdateInput>;
};

export type BusinessObjectFieldValue = {
  __typename?: 'BusinessObjectFieldValue';
  actuals?: Maybe<Actuals>;
  initialValue?: Maybe<Scalars['String']>;
  type: ValueType;
};

export type BusinessObjectFieldValueInput = {
  actuals?: InputMaybe<ActualsInput>;
  initialValue?: InputMaybe<Scalars['String']>;
  type: ValueType;
};

export type BusinessObjectFieldValueUpdateInput = {
  actuals?: InputMaybe<ActualsInput>;
  initialValue?: InputMaybe<Scalars['String']>;
  type: ValueType;
};

export type BusinessObjectFieldValueWithNullableTimeseries = {
  __typename?: 'BusinessObjectFieldValueWithNullableTimeseries';
  actuals?: Maybe<ActualsWithNullableTimeSeries>;
  initialValue?: Maybe<Scalars['String']>;
  type: ValueType;
};

export type BusinessObjectMetadata = {
  __typename?: 'BusinessObjectMetadata';
  databaseConfigId?: Maybe<Scalars['String']>;
  extKey?: Maybe<Scalars['String']>;
  remoteId?: Maybe<Scalars['String']>;
};

export type BusinessObjectMetadataData = {
  __typename?: 'BusinessObjectMetadataData';
  databaseConfigId?: Maybe<Scalars['String']>;
  extKey?: Maybe<Scalars['String']>;
  remoteId?: Maybe<Scalars['String']>;
};

export type BusinessObjectMetadataInput = {
  databaseConfigId?: InputMaybe<Scalars['String']>;
  extKey?: InputMaybe<Scalars['String']>;
  remoteId?: InputMaybe<Scalars['String']>;
};

/** BusinessObject Spec */
export type BusinessObjectSpec = Node & {
  __typename?: 'BusinessObjectSpec';
  collection?: Maybe<Collection>;
  databaseConfigId?: Maybe<Scalars['ID']>;
  defaultNameEntries?: Maybe<Array<BusinessObjectDefaultNameEntry>>;
  /** @deprecated extSource is deprecated. Use extSpecKeys instead. */
  extSource?: Maybe<Scalars['String']>;
  /** @deprecated extSpecKey is deprecated. Use extSpecKeys instead. */
  extSpecKey?: Maybe<Scalars['String']>;
  extSpecKeys?: Maybe<Array<Scalars['String']>>;
  fields?: Maybe<Array<BusinessObjectFieldSpec>>;
  id: Scalars['ID'];
  isRestricted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  startFieldId?: Maybe<Scalars['String']>;
};

export type BusinessObjectSpecCreateData = {
  __typename?: 'BusinessObjectSpecCreateData';
  collection?: Maybe<CollectionCreateData>;
  databaseConfigId?: Maybe<Scalars['ID']>;
  defaultNameEntries?: Maybe<Array<BusinessObjectDefaultNameEntry>>;
  /** @deprecated extSource is deprecated. Use extSpecKeys instead. */
  extSource?: Maybe<Scalars['String']>;
  /** @deprecated extSource is deprecated. Use extSpecKeys instead. */
  extSpecKey?: Maybe<Scalars['String']>;
  extSpecKeys?: Maybe<Array<Scalars['String']>>;
  fields: Array<BusinessObjectFieldSpec>;
  id: Scalars['ID'];
  isRestricted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  startFieldId?: Maybe<Scalars['String']>;
};

export type BusinessObjectSpecCreateInput = {
  collection?: InputMaybe<CollectionCreateInput>;
  databaseConfigId?: InputMaybe<Scalars['ID']>;
  defaultNameEntries?: InputMaybe<Array<BusinessObjectDefaultNameEntryInput>>;
  /** @deprecated extSource is deprecated. Use extSpecKeys instead. */
  extSource?: InputMaybe<Scalars['String']>;
  /** @deprecated extSource is deprecated. Use extSpecKeys instead. */
  extSpecKey?: InputMaybe<Scalars['String']>;
  extSpecKeys?: InputMaybe<Array<Scalars['String']>>;
  fields: Array<BusinessObjectFieldSpecInput>;
  id: Scalars['ID'];
  isRestricted?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  startFieldId?: InputMaybe<Scalars['String']>;
};

export type BusinessObjectSpecDeleteData = {
  __typename?: 'BusinessObjectSpecDeleteData';
  id: Scalars['ID'];
};

export type BusinessObjectSpecDeleteInput = {
  id: Scalars['ID'];
};

export type BusinessObjectSpecUpdateData = {
  __typename?: 'BusinessObjectSpecUpdateData';
  addFields?: Maybe<Array<BusinessObjectFieldSpecData>>;
  databaseConfigId?: Maybe<Scalars['ID']>;
  deleteDefaultNameEntries?: Maybe<Array<Scalars['String']>>;
  deleteFields?: Maybe<Array<Scalars['String']>>;
  extSpecKey?: Maybe<Scalars['String']>;
  extSpecKeys?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  isRestricted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  setDefaultNameEntries?: Maybe<Array<BusinessObjectDefaultNameEntry>>;
  updateCollection?: Maybe<CollectionData>;
  updateFields?: Maybe<Array<BusinessObjectFieldSpecUpdateData>>;
  updateStartFieldId?: Maybe<Scalars['String']>;
};

export type BusinessObjectSpecUpdateInput = {
  ExtObjectSpecsToRemove?: InputMaybe<Array<Scalars['String']>>;
  addFields?: InputMaybe<Array<BusinessObjectFieldSpecInput>>;
  databaseConfigId?: InputMaybe<Scalars['ID']>;
  deleteDefaultNameEntries?: InputMaybe<Array<Scalars['String']>>;
  deleteFields?: InputMaybe<Array<Scalars['String']>>;
  extSpecKey?: InputMaybe<Scalars['String']>;
  extSpecKeys?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  isRestricted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  setDefaultNameEntries?: InputMaybe<Array<BusinessObjectDefaultNameEntryInput>>;
  updateCollection?: InputMaybe<CollectionInput>;
  updateFields?: InputMaybe<Array<BusinessObjectFieldSpecUpdateInput>>;
  updateStartFieldId?: InputMaybe<Scalars['String']>;
};

export type BusinessObjectUpdateData = {
  __typename?: 'BusinessObjectUpdateData';
  addFields?: Maybe<Array<BusinessObjectFieldData>>;
  defaultEventGroupId?: Maybe<Scalars['String']>;
  deleteFields?: Maybe<Array<Scalars['String']>>;
  fields?: Maybe<Array<BusinessObjectFieldUpdateData>>;
  id: Scalars['ID'];
  metadata?: Maybe<BusinessObjectMetadataData>;
  name?: Maybe<Scalars['String']>;
  updateCollectionEntry?: Maybe<CollectionEntryUpdateData>;
};

export type BusinessObjectUpdateInput = {
  addFields?: InputMaybe<Array<BusinessObjectFieldInput>>;
  defaultEventGroupId?: InputMaybe<Scalars['String']>;
  deleteFields?: InputMaybe<Array<Scalars['String']>>;
  fields?: InputMaybe<Array<BusinessObjectFieldUpdateInput>>;
  id: Scalars['ID'];
  metadata?: InputMaybe<BusinessObjectMetadataInput>;
  name?: InputMaybe<Scalars['String']>;
  updateCollectionEntry?: InputMaybe<CollectionEntryUpdateInput>;
};

export type CalculationError = {
  __typename?: 'CalculationError';
  originEntity?: Maybe<ErrorOriginEntity>;
  type: CalculationErrorType;
};

export enum CalculationErrorType {
  CircularDependency = 'CircularDependency',
  DivByZero = 'DivByZero',
  Formula = 'Formula',
  InvalidType = 'InvalidType',
  MissingEntity = 'MissingEntity',
  Unexpected = 'Unexpected',
}

export type CalculationOpts = {
  ignoreAllCaches?: InputMaybe<Scalars['Boolean']>;
  ignoreFilterCache?: InputMaybe<Scalars['Boolean']>;
  ignoreValueCache?: InputMaybe<Scalars['Boolean']>;
};

export type CalculationRequest = {
  calculationOpts?: InputMaybe<CalculationOpts>;
  dateRange: FormulaDateRangeInput;
  entityId: Scalars['String'];
  entityType: FormulaEntityType;
  ignoreEventIDs?: InputMaybe<Array<Scalars['String']>>;
  includeObjectSpecEvaluations?: InputMaybe<Scalars['Boolean']>;
  layerId: Scalars['String'];
  requestId: Scalars['String'];
};

export type CalculationRequestBatch = {
  requests: Array<CalculationRequest>;
};

export type CalculationResult = {
  __typename?: 'CalculationResult';
  date: Scalars['Time'];
  entityId: Scalars['String'];
  entityType: FormulaEntityType;
  error?: Maybe<CalculationError>;
  ignoreEventIDs?: Maybe<Array<Scalars['String']>>;
  layerId: Scalars['String'];
  objectSpecEvaluations?: Maybe<Array<ObjectSpecEvaluation>>;
  requestId: Scalars['String'];
  value?: Maybe<CalculationValue>;
};

export type CalculationUpdate = {
  __typename?: 'CalculationUpdate';
  blockEvalResults?: Maybe<Array<BlockEvalResult>>;
  calculationResults?: Maybe<Array<CalculationResult>>;
  clientId: Scalars['String'];
  type: CalculationUpdateType;
};

export enum CalculationUpdateType {
  ForceRefresh = 'ForceRefresh',
  Init = 'Init',
  UpdateData = 'UpdateData',
}

export type CalculationValue = {
  __typename?: 'CalculationValue';
  attributeId?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['Time']>;
  type: ValueType;
};

export enum CalculatorStatus {
  Disabled = 'Disabled',
  Error = 'Error',
  Initializing = 'Initializing',
  Ready = 'Ready',
}

export type ChartAxis = {
  __typename?: 'ChartAxis';
  driver?: Maybe<DriverAxisExtension>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  position: ChartElementPosition;
  showLabel?: Maybe<Scalars['Boolean']>;
  time?: Maybe<TimeAxisExtension>;
  type: ChartAxisType;
};

export type ChartAxisInput = {
  driver?: InputMaybe<DriverAxisExtensionInput>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  position: ChartElementPosition;
  showLabel?: InputMaybe<Scalars['Boolean']>;
  time?: InputMaybe<TimeAxisExtensionInput>;
  type: ChartAxisType;
};

export enum ChartAxisType {
  Driver = 'DRIVER',
  Time = 'TIME',
}

export type ChartDisplay = {
  __typename?: 'ChartDisplay';
  axes: Array<ChartAxis>;
  groups: Array<ChartGroup>;
  legend?: Maybe<ChartLegend>;
  series: Array<ChartSeries>;
};

export type ChartDisplayInput = {
  axes: Array<ChartAxisInput>;
  groups: Array<ChartGroupInput>;
  legend?: InputMaybe<ChartLegendInput>;
  series: Array<ChartSeriesInput>;
};

export enum ChartElementPosition {
  Bottom = 'BOTTOM',
  Left = 'LEFT',
  Right = 'RIGHT',
  Top = 'TOP',
}

export type ChartGroup = {
  __typename?: 'ChartGroup';
  dateRange?: Maybe<DateRange>;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  layerId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  seriesIds: Array<Scalars['ID']>;
  showLabels?: Maybe<Scalars['Boolean']>;
};

export type ChartGroupInput = {
  dateRange?: InputMaybe<DateRangeInput>;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  layerId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  seriesIds: Array<Scalars['ID']>;
  showLabels?: InputMaybe<Scalars['Boolean']>;
};

export enum ChartGroupingType {
  Multi = 'MULTI',
  Single = 'SINGLE',
  /** @deprecated Stacked charts to be replaced with summed chart type */
  Stacked = 'STACKED',
}

export type ChartLegend = {
  __typename?: 'ChartLegend';
  container?: Maybe<LegendContainer>;
  item?: Maybe<LegendItem>;
  position?: Maybe<ChartElementPosition>;
  showLegend?: Maybe<Scalars['Boolean']>;
};

export type ChartLegendInput = {
  container?: InputMaybe<LegendContainerInput>;
  item?: InputMaybe<LegendItemInput>;
  position?: InputMaybe<ChartElementPosition>;
  showLegend?: InputMaybe<Scalars['Boolean']>;
};

export enum ChartPlotByType {
  Months = 'MONTHS',
  Quarters = 'QUARTERS',
  Time = 'TIME',
  Years = 'YEARS',
}

export enum ChartRollupType {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
}

export type ChartSeries = {
  __typename?: 'ChartSeries';
  color?: Maybe<Scalars['String']>;
  driverId: Scalars['ID'];
  id: Scalars['ID'];
  showLabels?: Maybe<Scalars['Boolean']>;
  type: ChartSeriesType;
};

export type ChartSeriesInput = {
  color?: InputMaybe<Scalars['String']>;
  driverId: Scalars['ID'];
  id: Scalars['ID'];
  showLabels?: InputMaybe<Scalars['Boolean']>;
  type: ChartSeriesType;
};

export enum ChartSeriesType {
  Area = 'AREA',
  Bar = 'BAR',
  Donut = 'DONUT',
  Line = 'LINE',
  Nightingale = 'NIGHTINGALE',
  Pie = 'PIE',
  Treemap = 'TREEMAP',
}

export enum ChartSize {
  ExtraLarge = 'EXTRA_LARGE',
  Large = 'LARGE',
  Medium = 'MEDIUM',
}

export type ClientInfo = {
  bootTime: Scalars['Time'];
  name: Scalars['String'];
  version: Scalars['String'];
};

export type ClientOutOfSyncMutationError = MutationError & {
  __typename?: 'ClientOutOfSyncMutationError';
  message: Scalars['String'];
};

export type Collection = {
  __typename?: 'Collection';
  dimensionalProperties?: Maybe<Array<DimensionalProperty>>;
  driverProperties?: Maybe<Array<DriverProperty>>;
};

export type CollectionCreateData = {
  __typename?: 'CollectionCreateData';
  dimensionalProperties?: Maybe<Array<DimensionalPropertyCreateData>>;
  driverProperties?: Maybe<Array<DriverPropertyCreateData>>;
};

export type CollectionCreateInput = {
  dimensionalProperties?: InputMaybe<Array<DimensionalPropertyCreateInput>>;
  driverProperties?: InputMaybe<Array<DriverPropertyCreateInput>>;
};

export type CollectionData = {
  __typename?: 'CollectionData';
  addDimensionalProperties?: Maybe<Array<DimensionalPropertyCreateData>>;
  addDriverProperties?: Maybe<Array<DriverPropertyCreateData>>;
  removeDimensionalProperties?: Maybe<Array<Scalars['String']>>;
  removeDriverProperties?: Maybe<Array<Scalars['String']>>;
  updateDimensionalProperties?: Maybe<Array<DimensionalPropertyUpdateData>>;
};

export type CollectionEntry = {
  __typename?: 'CollectionEntry';
  attributeProperties?: Maybe<Array<AttributeProperty>>;
};

export type CollectionEntryCreateData = {
  __typename?: 'CollectionEntryCreateData';
  attributeProperties?: Maybe<Array<AttributePropertyCreateData>>;
};

export type CollectionEntryCreateInput = {
  attributeProperties?: InputMaybe<Array<AttributePropertyCreateInput>>;
};

export type CollectionEntryUpdateData = {
  __typename?: 'CollectionEntryUpdateData';
  addAttributeProperties?: Maybe<Array<AttributePropertyCreateData>>;
  removeAttributeProperties?: Maybe<Array<Scalars['String']>>;
  updateAttributeProperties?: Maybe<Array<AttributePropertyUpdateData>>;
};

export type CollectionEntryUpdateInput = {
  addAttributeProperties?: InputMaybe<Array<AttributePropertyCreateInput>>;
  removeAttributeProperties?: InputMaybe<Array<Scalars['String']>>;
  updateAttributeProperties?: InputMaybe<Array<AttributePropertyUpdateInput>>;
};

export type CollectionInput = {
  addDimensionalProperties?: InputMaybe<Array<DimensionalPropertyCreateInput>>;
  addDriverProperties?: InputMaybe<Array<DriverPropertyCreateInput>>;
  removeDimensionalProperties?: InputMaybe<Array<Scalars['String']>>;
  removeDriverProperties?: InputMaybe<Array<Scalars['String']>>;
  updateDimensionalProperties?: InputMaybe<Array<DimensionalPropertyUpdateInput>>;
};

export type ColoringData = {
  __typename?: 'ColoringData';
  cells?: Maybe<Array<TimeSeriesPoint>>;
  row?: Maybe<Scalars['String']>;
};

export type ColoringInput = {
  cells?: InputMaybe<Array<TimeSeriesPointInput>>;
  row?: InputMaybe<Scalars['String']>;
};

export enum ComparatorType {
  Eq = 'EQ',
  Gt = 'GT',
  Gte = 'GTE',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  Lt = 'LT',
  Lte = 'LTE',
  Neq = 'NEQ',
}

export enum ComparisonColumn {
  BaselineVersion = 'BASELINE_VERSION',
  /** @deprecated use BASELINE_VERSION */
  LatestVersion = 'LATEST_VERSION',
  RowVersion = 'ROW_VERSION',
  Variance = 'VARIANCE',
  VariancePercentage = 'VARIANCE_PERCENTAGE',
}

export enum ComparisonTimePeriod {
  CurrentPeriod = 'CURRENT_PERIOD',
  PreviousPeriod = 'PREVIOUS_PERIOD',
  SixMonthsAgo = 'SIX_MONTHS_AGO',
  ThreeMonthsAgo = 'THREE_MONTHS_AGO',
  TwelveMonthsAgo = 'TWELVE_MONTHS_AGO',
}

export enum CurveType {
  Custom = 'CUSTOM',
  Exponential = 'EXPONENTIAL',
  Linear = 'LINEAR',
  Logarithmic = 'LOGARITHMIC',
}

export type DatabaseConfig = {
  __typename?: 'DatabaseConfig';
  businessObjectSpecId: Scalars['ID'];
  database?: Maybe<DatabaseConfigForDatabaseSource>;
  extTable?: Maybe<DatabaseConfigForExtTableSource>;
  id: Scalars['ID'];
  refreshInfo?: Maybe<DatabaseConfigRefreshInfo>;
  source: DatabaseConfigSourceType;
};

export enum DatabaseConfigAggregationType {
  Count = 'COUNT',
  Max = 'MAX',
  Mean = 'MEAN',
  Min = 'MIN',
  Sum = 'SUM',
}

export type DatabaseConfigDeleteData = {
  __typename?: 'DatabaseConfigDeleteData';
  id: Scalars['ID'];
};

export type DatabaseConfigDeleteInput = {
  id: Scalars['ID'];
};

export type DatabaseConfigForDatabaseSource = {
  __typename?: 'DatabaseConfigForDatabaseSource';
  driverColumns?: Maybe<Array<DatabaseDriverColumn>>;
  filters?: Maybe<Array<DatabaseFilter>>;
  metadataColumns?: Maybe<Array<DatabaseDimensionColumn>>;
  segmentedByColumns?: Maybe<Array<DatabaseDimensionColumn>>;
  sourceBusinessObjectSpecId: Scalars['String'];
};

export type DatabaseConfigForDatabaseSourceInput = {
  driverColumns?: InputMaybe<Array<DatabaseDriverColumnInput>>;
  filters?: InputMaybe<Array<DatabaseFilterInput>>;
  metadataColumns?: InputMaybe<Array<DatabaseDimensionColumnInput>>;
  segmentedByColumns?: InputMaybe<Array<DatabaseDimensionColumnInput>>;
  sourceBusinessObjectSpecId: Scalars['String'];
};

export type DatabaseConfigForExtTableSource = {
  __typename?: 'DatabaseConfigForExtTableSource';
  driverColumns?: Maybe<Array<ExtTableDriverColumn>>;
  extTableSourceKey: Scalars['String'];
  filters?: Maybe<Array<ExtTableFilter>>;
  metadataColumns?: Maybe<Array<ExtTableDimensionColumn>>;
  segmentedByColumns?: Maybe<Array<ExtTableDimensionColumn>>;
  timeColumn?: Maybe<TimeColumn>;
};

export type DatabaseConfigForExtTableSourceInput = {
  driverColumns?: InputMaybe<Array<ExtTableDriverColumnInput>>;
  extTableSourceKey: Scalars['String'];
  filters?: InputMaybe<Array<ExtTableFilterInput>>;
  metadataColumns?: InputMaybe<Array<ExtTableDimensionColumnInput>>;
  segmentedByColumns?: InputMaybe<Array<ExtTableDimensionColumnInput>>;
  timeColumn?: InputMaybe<TimeColumnInput>;
};

export type DatabaseConfigInput = {
  businessObjectSpecId: Scalars['ID'];
  database?: InputMaybe<DatabaseConfigForDatabaseSourceInput>;
  extTable?: InputMaybe<DatabaseConfigForExtTableSourceInput>;
  id: Scalars['ID'];
  refreshInfo?: InputMaybe<DatabaseConfigRefreshInfoInput>;
  source: DatabaseConfigSourceType;
};

export type DatabaseConfigRefreshError = {
  __typename?: 'DatabaseConfigRefreshError';
  reason?: Maybe<Scalars['String']>;
};

export type DatabaseConfigRefreshErrorInput = {
  reason?: InputMaybe<Scalars['String']>;
};

export type DatabaseConfigRefreshInfo = {
  __typename?: 'DatabaseConfigRefreshInfo';
  error?: Maybe<DatabaseConfigRefreshError>;
  status: DatabaseConfigRefreshStatus;
  updatedAt: Scalars['Time'];
};

export type DatabaseConfigRefreshInfoInput = {
  error?: InputMaybe<DatabaseConfigRefreshErrorInput>;
  status: DatabaseConfigRefreshStatus;
  updatedAt: Scalars['Time'];
};

export enum DatabaseConfigRefreshStatus {
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Success = 'SUCCESS',
}

export enum DatabaseConfigSourceType {
  Database = 'DATABASE',
  ExtTable = 'EXT_TABLE',
}

export type DatabaseDimensionColumn = {
  __typename?: 'DatabaseDimensionColumn';
  dimensionalPropertyId?: Maybe<Scalars['ID']>;
  sourceDimensionalPropertyId: Scalars['String'];
};

export type DatabaseDimensionColumnInput = {
  dimensionalPropertyId?: InputMaybe<Scalars['ID']>;
  sourceDimensionalPropertyId: Scalars['String'];
};

export type DatabaseDriverColumn = {
  __typename?: 'DatabaseDriverColumn';
  aggregationType: DatabaseConfigAggregationType;
  driverPropertyId?: Maybe<Scalars['ID']>;
  sourceDriverPropertyId: Scalars['ID'];
};

export type DatabaseDriverColumnInput = {
  aggregationType: DatabaseConfigAggregationType;
  driverPropertyId?: InputMaybe<Scalars['ID']>;
  sourceDriverPropertyId: Scalars['ID'];
};

export type DatabaseFilter = {
  __typename?: 'DatabaseFilter';
  comparator: ComparatorType;
  compareTo: Array<Scalars['String']>;
  dimensionalPropertyId: Scalars['ID'];
};

export type DatabaseFilterInput = {
  comparator: ComparatorType;
  compareTo: Array<Scalars['String']>;
  dimensionalPropertyId: Scalars['ID'];
};

/** Dataset captures all primitives used to model the specifications of a business. */
export type Dataset = {
  __typename?: 'Dataset';
  blocks: Array<Block>;
  blocksPages: Array<BlocksPage>;
  businessObjectSpecs: Array<BusinessObjectSpec>;
  businessObjects: Array<BusinessObject>;
  databaseConfigs: Array<DatabaseConfig>;
  deletedIdentifiers?: Maybe<Array<DeletedIdentifier>>;
  description?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Array<Dimension>>;
  driverFieldSpecs: Array<DriverFieldSpec>;
  driverFields: Array<DriverField>;
  driverGroups: Array<DriverGroup>;
  drivers: Array<Driver>;
  eventGroups: Array<EventGroup>;
  events: Array<Event>;
  exports: Array<Export>;
  extDrivers: Array<ExtDriver>;
  extObjectSpecs: Array<ExtObjectSpec>;
  extObjects: Array<ExtObject>;
  extQueries: Array<ExtQuery>;
  extTables: Array<ExtTable>;
  folders: Array<Folder>;
  integrationQueries: Array<IntegrationQuery>;
  lastActualsTime?: Maybe<Scalars['Time']>;
  layers: Array<Layer>;
  metadata?: Maybe<DatasetMetadata>;
  milestones: Array<Milestone>;
  name?: Maybe<Scalars['String']>;
  namedVersions: Array<NamedDatasetVersion>;
  orgId: Scalars['String'];
  submodels: Array<Submodel>;
};

export type DatasetMetadata = {
  __typename?: 'DatasetMetadata';
  lastMutationId?: Maybe<Scalars['String']>;
  lastUpdatedAt?: Maybe<Scalars['Time']>;
};

export type DatasetMutationData = {
  __typename?: 'DatasetMutationData';
  changeId?: Maybe<Scalars['String']>;
  commitLayerId?: Maybe<Scalars['String']>;
  createDatabaseConfigs?: Maybe<Array<DatabaseConfig>>;
  createExtDrivers?: Maybe<Array<ExtDriverCreateData>>;
  createExtObjectSpecs?: Maybe<Array<ExtObjectSpecCreateData>>;
  createExtObjects?: Maybe<Array<ExtObjectCreateData>>;
  createExtQueries?: Maybe<Array<ExtQueryCreateData>>;
  createExtTableRuns?: Maybe<Array<ExtTableRunCreateData>>;
  createExtTables?: Maybe<Array<ExtTableCreateData>>;
  createIntegrationQueries?: Maybe<Array<IntegrationQueryCreateData>>;
  createNamedDatasetVersions?: Maybe<Array<NamedDatasetVersionCreateData>>;
  createdAt: Scalars['Time'];
  deleteBlocks?: Maybe<Array<BlockDeleteData>>;
  deleteBlocksPages?: Maybe<Array<BlocksPageDeleteData>>;
  deleteBusinessObjectSpecs?: Maybe<Array<BusinessObjectSpecDeleteData>>;
  deleteBusinessObjects?: Maybe<Array<BusinessObjectDeleteData>>;
  deleteDatabaseConfigs?: Maybe<Array<DatabaseConfigDeleteData>>;
  deleteDimensions?: Maybe<Array<DimensionDeleteData>>;
  deleteDriverGroups?: Maybe<Array<DriverGroupDelete>>;
  deleteDrivers?: Maybe<Array<DriverDeleteData>>;
  deleteEventGroups?: Maybe<Array<EventGroupDeleteData>>;
  deleteEvents?: Maybe<Array<EventDeleteData>>;
  deleteExports?: Maybe<Array<ExportDeleteData>>;
  deleteExtDrivers?: Maybe<Array<ExtDriverDeleteData>>;
  deleteExtObjectSpecs?: Maybe<Array<ExtObjectSpecDeleteData>>;
  deleteExtObjects?: Maybe<Array<ExtObjectDeleteData>>;
  deleteExtQueries?: Maybe<Array<ExtQueryDeleteData>>;
  deleteExtTables?: Maybe<Array<ExtTableDeleteData>>;
  deleteFolders?: Maybe<Array<FolderDeleteData>>;
  deleteIntegrationQueries?: Maybe<Array<IntegrationQueryDeleteData>>;
  deleteLayers?: Maybe<Array<LayerDeleteData>>;
  deleteMilestones?: Maybe<Array<MilestoneDeleteData>>;
  deleteNamedDatasetVersions?: Maybe<Array<NamedDatasetVersionDeleteData>>;
  deleteSubmodels?: Maybe<Array<SubmodelDeleteData>>;
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  importSnapshot?: Maybe<ImportSnapshotData>;
  layerId?: Maybe<Scalars['String']>;
  newBlocks?: Maybe<Array<BlockCreateData>>;
  newBlocksPages?: Maybe<Array<BlocksPageCreateData>>;
  newBusinessObjectSpecs?: Maybe<Array<BusinessObjectSpecCreateData>>;
  newBusinessObjects?: Maybe<Array<BusinessObjectCreateData>>;
  newDimensions?: Maybe<Array<DimensionCreateData>>;
  newDriverFieldSpecs?: Maybe<Array<DriverFieldSpecCreateData>>;
  newDriverGroups?: Maybe<Array<DriverGroupCreate>>;
  newDrivers?: Maybe<Array<DriverCreateData>>;
  newEventGroups?: Maybe<Array<EventGroupCreateData>>;
  newEvents?: Maybe<Array<EventCreateData>>;
  newExports?: Maybe<Array<ExportCreateData>>;
  newFolders?: Maybe<Array<FolderCreateData>>;
  newLayers?: Maybe<Array<LayerCreateData>>;
  newMilestones?: Maybe<Array<MilestoneCreateData>>;
  newSubmodels?: Maybe<Array<SubmodelCreateData>>;
  prevMutationId?: Maybe<Scalars['String']>;
  renameDriverGroups?: Maybe<Array<DriverGroupRename>>;
  restoreDimensions?: Maybe<Array<DimensionRestoreData>>;
  setDriverFields?: Maybe<Array<DriverFieldSetData>>;
  undoMutationId?: Maybe<Scalars['String']>;
  updateBlocks?: Maybe<Array<BlockUpdateData>>;
  updateBlocksPages?: Maybe<Array<BlocksPageUpdateData>>;
  updateBusinessObjectSpecs?: Maybe<Array<BusinessObjectSpecUpdateData>>;
  updateBusinessObjects?: Maybe<Array<BusinessObjectUpdateData>>;
  updateDatabaseConfigs?: Maybe<Array<DatabaseConfig>>;
  updateDimensions?: Maybe<Array<DimensionUpdateData>>;
  updateDriverFieldSpecs?: Maybe<Array<DriverFieldSpecUpdateData>>;
  updateDrivers?: Maybe<Array<DriverUpdateData>>;
  updateEventGroups?: Maybe<Array<EventGroupUpdateData>>;
  updateEvents?: Maybe<Array<EventUpdateData>>;
  updateExports?: Maybe<Array<ExportUpdateData>>;
  updateExtDrivers?: Maybe<Array<ExtDriverUpdateData>>;
  updateExtObjectSpecs?: Maybe<Array<ExtObjectSpecUpdateData>>;
  updateExtQueries?: Maybe<Array<ExtQueryUpdateData>>;
  updateExtTables?: Maybe<Array<ExtTableUpdateData>>;
  updateFolders?: Maybe<Array<FolderUpdateData>>;
  updateIntegrationQueries?: Maybe<Array<IntegrationQueryUpdateData>>;
  updateLastActualsTime?: Maybe<Scalars['Time']>;
  updateLayers?: Maybe<Array<LayerUpdateData>>;
  updateMilestones?: Maybe<Array<MilestoneUpdateData>>;
  updateNamedDatasetVersions?: Maybe<Array<NamedDatasetVersionUpdateData>>;
  updateSubmodels?: Maybe<Array<SubmodelUpdateData>>;
  userId: Scalars['String'];
};

export type DatasetMutationError = ClientOutOfSyncMutationError | UnableToApplyMutationError;

export type DatasetMutationHistoryInput = {
  beforeMutationId?: InputMaybe<Scalars['ID']>;
  includeUndo: Scalars['Boolean'];
  layerId?: InputMaybe<Scalars['String']>;
  /** Max number of results to return. Default is 20. Max is 50. */
  limit?: InputMaybe<Scalars['Int']>;
};

export type DatasetMutationInput = {
  changeId?: InputMaybe<Scalars['String']>;
  commitLayerId?: InputMaybe<Scalars['String']>;
  createDatabaseConfigs?: InputMaybe<Array<DatabaseConfigInput>>;
  createExtDrivers?: InputMaybe<Array<ExtDriverCreateInput>>;
  createExtObjectSpecs?: InputMaybe<Array<ExtObjectSpecCreateInput>>;
  createExtObjects?: InputMaybe<Array<ExtObjectCreateInput>>;
  createExtQueries?: InputMaybe<Array<ExtQueryCreateInput>>;
  createExtTableRuns?: InputMaybe<Array<ExtTableRunCreateInput>>;
  createExtTables?: InputMaybe<Array<ExtTableCreateInput>>;
  createIntegrationQueries?: InputMaybe<Array<IntegrationQueryCreateInput>>;
  createNamedDatasetVersions?: InputMaybe<Array<NamedDatasetVersionCreateInput>>;
  deleteBlocks?: InputMaybe<Array<BlockDeleteInput>>;
  deleteBlocksPages?: InputMaybe<Array<BlocksPageDeleteInput>>;
  deleteBusinessObjectSpecs?: InputMaybe<Array<BusinessObjectSpecDeleteInput>>;
  deleteBusinessObjects?: InputMaybe<Array<BusinessObjectDeleteInput>>;
  deleteDatabaseConfigs?: InputMaybe<Array<DatabaseConfigDeleteInput>>;
  deleteDimensions?: InputMaybe<Array<DimensionDeleteInput>>;
  deleteDriverGroups?: InputMaybe<Array<DriverGroupDeleteInput>>;
  deleteDrivers?: InputMaybe<Array<DriverDeleteInput>>;
  deleteEventGroups?: InputMaybe<Array<EventGroupDeleteInput>>;
  deleteEvents?: InputMaybe<Array<EventDeleteInput>>;
  deleteExports?: InputMaybe<Array<ExportDeleteInput>>;
  deleteExtDrivers?: InputMaybe<Array<ExtDriverDeleteInput>>;
  deleteExtObjectSpecs?: InputMaybe<Array<ExtObjectSpecDeleteInput>>;
  deleteExtObjects?: InputMaybe<Array<ExtObjectDeleteInput>>;
  deleteExtQueries?: InputMaybe<Array<ExtQueryDeleteInput>>;
  deleteExtTables?: InputMaybe<Array<ExtTableDeleteInput>>;
  deleteFolders?: InputMaybe<Array<FolderDeleteInput>>;
  deleteIntegrationQueries?: InputMaybe<Array<IntegrationQueryDeleteInput>>;
  deleteLayers?: InputMaybe<Array<LayerDeleteInput>>;
  deleteMilestones?: InputMaybe<Array<MilestoneDeleteInput>>;
  deleteNamedDatasetVersions?: InputMaybe<Array<NamedDatasetVersionDeleteInput>>;
  deleteSubmodels?: InputMaybe<Array<SubmodelDeleteInput>>;
  importNamedVersionSnapshot?: InputMaybe<ImportNamedSnapshotInput>;
  importSnapshot?: InputMaybe<ImportSnapshotInput>;
  newBlocks?: InputMaybe<Array<BlockCreateInput>>;
  newBlocksPages?: InputMaybe<Array<BlocksPageCreateInput>>;
  newBusinessObjectSpecs?: InputMaybe<Array<BusinessObjectSpecCreateInput>>;
  newBusinessObjects?: InputMaybe<Array<BusinessObjectCreateInput>>;
  newDimensions?: InputMaybe<Array<DimensionCreateInput>>;
  newDriverFieldSpecs?: InputMaybe<Array<DriverFieldSpecCreateInput>>;
  newDriverGroups?: InputMaybe<Array<DriverGroupCreateInput>>;
  newDrivers?: InputMaybe<Array<DriverCreateInput>>;
  newEventGroups?: InputMaybe<Array<EventGroupCreateInput>>;
  newEvents?: InputMaybe<Array<EventCreateInput>>;
  newExports?: InputMaybe<Array<ExportCreateInput>>;
  newFolders?: InputMaybe<Array<FolderCreateInput>>;
  newLayers?: InputMaybe<Array<LayerCreateInput>>;
  newMilestones?: InputMaybe<Array<MilestoneCreateInput>>;
  newSubmodels?: InputMaybe<Array<SubmodelCreateInput>>;
  renameDriverGroups?: InputMaybe<Array<DriverGroupRenameInput>>;
  restoreDimensions?: InputMaybe<Array<DimensionRestoreInput>>;
  setDriverFields?: InputMaybe<Array<DriverFieldSetInput>>;
  updateBlocks?: InputMaybe<Array<BlockUpdateInput>>;
  updateBlocksPages?: InputMaybe<Array<BlocksPageUpdateInput>>;
  updateBusinessObjectSpecs?: InputMaybe<Array<BusinessObjectSpecUpdateInput>>;
  updateBusinessObjects?: InputMaybe<Array<BusinessObjectUpdateInput>>;
  updateDatabaseConfigs?: InputMaybe<Array<DatabaseConfigInput>>;
  updateDimensions?: InputMaybe<Array<DimensionUpdateInput>>;
  updateDriverFieldSpecs?: InputMaybe<Array<DriverFieldSpecUpdateInput>>;
  updateDrivers?: InputMaybe<Array<DriverUpdateInput>>;
  updateEventGroups?: InputMaybe<Array<EventGroupUpdateInput>>;
  updateEvents?: InputMaybe<Array<EventUpdateInput>>;
  updateExports?: InputMaybe<Array<ExportUpdateInput>>;
  updateExtDrivers?: InputMaybe<Array<ExtDriverUpdateInput>>;
  updateExtObjectSpecs?: InputMaybe<Array<ExtObjectSpecUpdateInput>>;
  updateExtQueries?: InputMaybe<Array<ExtQueryUpdateInput>>;
  updateExtTables?: InputMaybe<Array<ExtTableUpdateInput>>;
  updateFolders?: InputMaybe<Array<FolderUpdateInput>>;
  updateIntegrationQueries?: InputMaybe<Array<IntegrationQueryUpdateInput>>;
  updateLastActualsTime?: InputMaybe<Scalars['Time']>;
  updateLayers?: InputMaybe<Array<LayerUpdateInput>>;
  updateMilestones?: InputMaybe<Array<MilestoneUpdateInput>>;
  updateNamedDatasetVersions?: InputMaybe<Array<NamedDatasetVersionUpdateInput>>;
  updateSubmodels?: InputMaybe<Array<SubmodelUpdateInput>>;
};

export type DatasetMutationMacro = {
  __typename?: 'DatasetMutationMacro';
  id: Scalars['ID'];
  type: DatasetMutationMacroType;
};

export type DatasetMutationMacroInput = {
  id: Scalars['ID'];
  type: DatasetMutationMacroType;
};

export enum DatasetMutationMacroType {
  ExtTableRunData = 'ExtTableRunData',
}

export type DatasetMutationWithLastMutationIdResponse = {
  __typename?: 'DatasetMutationWithLastMutationIdResponse';
  errors?: Maybe<Array<Maybe<DatasetMutationError>>>;
  lastMutationId?: Maybe<Scalars['ID']>;
  success: Scalars['Boolean'];
};

export enum DatasetPresetType {
  AnonymizedLarge = 'ANONYMIZED_LARGE',
  Empty = 'EMPTY',
  SnapshotTest = 'SNAPSHOT_TEST',
  Test = 'TEST',
}

export type DateGroupByExtension = {
  __typename?: 'DateGroupByExtension';
  collapsedMonthKeys?: Maybe<Array<Scalars['String']>>;
};

export type DateGroupByExtensionInput = {
  collapsedMonthKeys?: InputMaybe<Array<Scalars['String']>>;
};

export type DateRange = {
  __typename?: 'DateRange';
  end?: Maybe<Scalars['LooseTime']>;
  start?: Maybe<Scalars['LooseTime']>;
};

export type DateRangeComparison = {
  __typename?: 'DateRangeComparison';
  comparisonPeriod: DateRange;
  selectedPeriod: DateRange;
  type: DateRangeComparisonType;
};

export type DateRangeComparisonInput = {
  comparisonPeriod: DateRangeInput;
  selectedPeriod: DateRangeInput;
  type: DateRangeComparisonType;
};

export enum DateRangeComparisonType {
  CurrentMonth = 'CURRENT_MONTH',
  CurrentQuarter = 'CURRENT_QUARTER',
  CurrentYear = 'CURRENT_YEAR',
  CustomMonth = 'CUSTOM_MONTH',
  LastClose = 'LAST_CLOSE',
  LastMonth = 'LAST_MONTH',
  LastQuarter = 'LAST_QUARTER',
  LastYear = 'LAST_YEAR',
}

export type DateRangeInput = {
  end?: InputMaybe<Scalars['LooseTime']>;
  start?: InputMaybe<Scalars['LooseTime']>;
};

export type DefaultFormula = {
  __typename?: 'DefaultFormula';
  formula: Scalars['String'];
  /** @deprecated variables is not used */
  variables?: Maybe<Array<Scalars['String']>>;
};

export type DefaultFormulaInput = {
  formula: Scalars['String'];
  /** @deprecated variables is not used */
  variables?: InputMaybe<Array<Scalars['String']>>;
};

export type DeletedIdentifier = {
  __typename?: 'DeletedIdentifier';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DependencyGraph = {
  __typename?: 'DependencyGraph';
  edges: Array<DependencyGraphEdge>;
  nodes: Array<DependencyGraphNode>;
};

export type DependencyGraphEdge = {
  __typename?: 'DependencyGraphEdge';
  dateRange?: Maybe<Scalars['String']>;
  layerId?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  target: Scalars['String'];
  type?: Maybe<DependencyGraphEdgeType>;
};

export enum DependencyGraphEdgeType {
  Evaluated = 'EVALUATED',
  Static = 'STATIC',
}

export type DependencyGraphNode = {
  __typename?: 'DependencyGraphNode';
  id: Scalars['ID'];
  key: Scalars['String'];
  label: Scalars['String'];
  type: DependencyGraphNodeType;
};

export enum DependencyGraphNodeType {
  Dependency = 'DEPENDENCY',
  Entity = 'ENTITY',
}

export type Dimension = Node & {
  __typename?: 'Dimension';
  attributes: Array<Attribute>;
  color?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DimensionCreateData = {
  __typename?: 'DimensionCreateData';
  attributes?: Maybe<Array<AttributeCreateData>>;
  color?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DimensionCreateInput = {
  attributes?: InputMaybe<Array<AttributeCreateInput>>;
  color?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DimensionDeleteData = {
  __typename?: 'DimensionDeleteData';
  id: Scalars['ID'];
};

export type DimensionDeleteInput = {
  id: Scalars['ID'];
};

export type DimensionRestoreData = {
  __typename?: 'DimensionRestoreData';
  id: Scalars['ID'];
};

export type DimensionRestoreInput = {
  id: Scalars['ID'];
};

export type DimensionUpdateData = {
  __typename?: 'DimensionUpdateData';
  color?: Maybe<Scalars['String']>;
  deleteAttributes?: Maybe<Array<AttributeDeleteData>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  newAttributes?: Maybe<Array<AttributeCreateData>>;
  restoreAttributes?: Maybe<Array<AttributeRestoreData>>;
  updateAttributes?: Maybe<Array<AttributeUpdateData>>;
};

export type DimensionUpdateInput = {
  color?: InputMaybe<Scalars['String']>;
  deleteAttributes?: InputMaybe<Array<AttributeDeleteInput>>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  newAttributes?: InputMaybe<Array<AttributeCreateInput>>;
  restoreAttributes?: InputMaybe<Array<AttributeRestoreInput>>;
  updateAttributes?: InputMaybe<Array<AttributeUpdateInput>>;
};

export type DimensionalDriverCreateData = {
  __typename?: 'DimensionalDriverCreateData';
  builtInDimensions?: Maybe<Array<BuiltInDimensionType>>;
  defaultActuals?: Maybe<DefaultFormula>;
  defaultForecast?: Maybe<DefaultFormula>;
  dimensionIds?: Maybe<Array<Scalars['String']>>;
  subDrivers?: Maybe<Array<DimensionalSubDriverCreateData>>;
};

export type DimensionalDriverCreateInput = {
  builtInDimensions?: InputMaybe<Array<BuiltInDimensionType>>;
  defaultActuals?: InputMaybe<DefaultFormulaInput>;
  defaultForecast?: InputMaybe<DefaultFormulaInput>;
  dimensionIds?: InputMaybe<Array<Scalars['String']>>;
  subDrivers?: InputMaybe<Array<DimensionalSubDriverCreateInput>>;
};

/**
 * DimensionalDriverExtension captures all data required to extend Driver into a DimensionalDriver, which encapsulates a collection of subdrivers varying by Attribute.
 *
 * These subdrivers represent the same business metric split along the given set of Dimensions.
 */
export type DimensionalDriverExtension = {
  __typename?: 'DimensionalDriverExtension';
  builtInDimensions?: Maybe<Array<BuiltInDimensionType>>;
  defaultActuals?: Maybe<DefaultFormula>;
  defaultForecast?: Maybe<DefaultFormula>;
  dimensions?: Maybe<Array<Dimension>>;
  subDrivers: Array<DimensionalSubDriver>;
};

export type DimensionalDriverUpdateData = {
  __typename?: 'DimensionalDriverUpdateData';
  builtInDimensions?: Maybe<Array<BuiltInDimensionType>>;
  defaultActuals?: Maybe<DefaultFormula>;
  defaultForecast?: Maybe<DefaultFormula>;
  deleteSubDrivers?: Maybe<Array<DimensionalSubDriverDeleteData>>;
  dimensionIds?: Maybe<Array<Scalars['String']>>;
  updateSubDrivers?: Maybe<Array<DimensionalSubDriverUpdateData>>;
};

export type DimensionalDriverUpdateInput = {
  builtInDimensions?: InputMaybe<Array<BuiltInDimensionType>>;
  defaultActuals?: InputMaybe<DefaultFormulaInput>;
  defaultForecast?: InputMaybe<DefaultFormulaInput>;
  deleteSubDrivers?: InputMaybe<Array<DimensionalSubDriverDeleteInput>>;
  dimensionIds?: InputMaybe<Array<Scalars['String']>>;
  updateSubDrivers?: InputMaybe<Array<DimensionalSubDriverUpdateInput>>;
};

export type DimensionalProperty = {
  __typename?: 'DimensionalProperty';
  dimensionId: Scalars['String'];
  extFieldSpecKey?: Maybe<Scalars['String']>;
  extQueryId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDatabaseKey?: Maybe<Scalars['Boolean']>;
  mapping?: Maybe<DimensionalPropertyMapping>;
  name: Scalars['String'];
};

export type DimensionalPropertyCreateData = {
  __typename?: 'DimensionalPropertyCreateData';
  dimensionId: Scalars['String'];
  extFieldSpecKey?: Maybe<Scalars['String']>;
  extQueryId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDatabaseKey?: Maybe<Scalars['Boolean']>;
  mapping?: Maybe<DimensionalPropertyMapping>;
  name: Scalars['String'];
};

export type DimensionalPropertyCreateInput = {
  dimensionId: Scalars['String'];
  extFieldSpecKey?: InputMaybe<Scalars['String']>;
  extQueryId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isDatabaseKey?: InputMaybe<Scalars['Boolean']>;
  mapping?: InputMaybe<DimensionalPropertyMappingInput>;
  name: Scalars['String'];
};

export type DimensionalPropertyMapping = {
  __typename?: 'DimensionalPropertyMapping';
  lookupSpecId: Scalars['String'];
  resultPropertyId: Scalars['String'];
  searchDimensionPropertyId?: Maybe<Scalars['String']>;
};

export type DimensionalPropertyMappingInput = {
  lookupSpecId: Scalars['String'];
  resultPropertyId: Scalars['String'];
  searchDimensionPropertyId?: InputMaybe<Scalars['String']>;
};

export type DimensionalPropertyUpdateData = {
  __typename?: 'DimensionalPropertyUpdateData';
  dimensionId?: Maybe<Scalars['String']>;
  extFieldSpecKey?: Maybe<Scalars['String']>;
  extQueryId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDatabaseKey?: Maybe<Scalars['Boolean']>;
  mapping?: Maybe<DimensionalPropertyMapping>;
  name?: Maybe<Scalars['String']>;
  removeMapping?: Maybe<Scalars['Boolean']>;
};

export type DimensionalPropertyUpdateInput = {
  dimensionId?: InputMaybe<Scalars['String']>;
  extFieldSpecKey?: InputMaybe<Scalars['String']>;
  extQueryId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isDatabaseKey?: InputMaybe<Scalars['Boolean']>;
  mapping?: InputMaybe<DimensionalPropertyMappingInput>;
  name?: InputMaybe<Scalars['String']>;
  removeMapping?: InputMaybe<Scalars['Boolean']>;
};

/** DimensionalSubDriver represents a key-value pair in the collection of a DimensionalDriver, which keys a list of Attributes to a subdriver. */
export type DimensionalSubDriver = {
  __typename?: 'DimensionalSubDriver';
  attributes?: Maybe<Array<Attribute>>;
  builtInAttributes?: Maybe<Array<BuiltInAttributeData>>;
  driver: Driver;
  source?: Maybe<DimensionalSubDriverSource>;
};

export type DimensionalSubDriverCreateData = {
  __typename?: 'DimensionalSubDriverCreateData';
  attributeIds?: Maybe<Array<Scalars['String']>>;
  builtInAttributes?: Maybe<Array<BuiltInAttributeData>>;
  driver?: Maybe<DriverCreateData>;
  existingDriverId?: Maybe<Scalars['String']>;
  source?: Maybe<DimensionalSubDriverSourceData>;
};

export type DimensionalSubDriverCreateInput = {
  attributeIds?: InputMaybe<Array<Scalars['String']>>;
  builtInAttributes?: InputMaybe<Array<BuiltInAttributeInput>>;
  driver?: InputMaybe<DriverCreateInput>;
  existingDriverId?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<DimensionalSubDriverSourceInput>;
};

export type DimensionalSubDriverDeleteData = {
  __typename?: 'DimensionalSubDriverDeleteData';
  attributeIds?: Maybe<Array<Scalars['String']>>;
  builtInAttributes?: Maybe<Array<BuiltInAttributeData>>;
};

export type DimensionalSubDriverDeleteInput = {
  attributeIds?: InputMaybe<Array<Scalars['String']>>;
  builtInAttributes?: InputMaybe<Array<BuiltInAttributeInput>>;
};

export type DimensionalSubDriverSource = {
  __typename?: 'DimensionalSubDriverSource';
  extQueryId?: Maybe<Scalars['ID']>;
  type: DimensionalSubDriverSourceType;
};

export type DimensionalSubDriverSourceData = {
  __typename?: 'DimensionalSubDriverSourceData';
  extQueryId?: Maybe<Scalars['ID']>;
  type: DimensionalSubDriverSourceType;
};

export type DimensionalSubDriverSourceInput = {
  extQueryId?: InputMaybe<Scalars['ID']>;
  type: DimensionalSubDriverSourceType;
};

export enum DimensionalSubDriverSourceType {
  DatabaseSource = 'DATABASE_SOURCE',
  ExtQuery = 'EXT_QUERY',
  UserCreated = 'USER_CREATED',
}

export type DimensionalSubDriverUpdateData = {
  __typename?: 'DimensionalSubDriverUpdateData';
  attributeIds?: Maybe<Array<Scalars['String']>>;
  builtInAttributes?: Maybe<Array<BuiltInAttributeData>>;
  newAttributeIds?: Maybe<Array<Scalars['String']>>;
  newBuiltInAttributes?: Maybe<Array<BuiltInAttributeData>>;
  removeAllAttributes?: Maybe<Scalars['Boolean']>;
  source?: Maybe<DimensionalSubDriverSourceData>;
  unlink?: Maybe<Scalars['Boolean']>;
};

export type DimensionalSubDriverUpdateInput = {
  attributeIds?: InputMaybe<Array<Scalars['String']>>;
  builtInAttributes?: InputMaybe<Array<BuiltInAttributeInput>>;
  newAttributeIds?: InputMaybe<Array<Scalars['String']>>;
  newBuiltInAttributes?: InputMaybe<Array<BuiltInAttributeInput>>;
  removeAllAttributes?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<DimensionalSubDriverSourceInput>;
  unlink?: InputMaybe<Scalars['Boolean']>;
};

export type DownloadStorageSignedUrlResponse = {
  __typename?: 'DownloadStorageSignedUrlResponse';
  signedUrl: Scalars['String'];
};

/**
 * A driver is a core primitive used primarily to model a metric in a business. It is specified as a formula defining how it is affected by other drivers.
 *
 * Drivers can be extended based on its list of driver types.
 */
export type Driver = Node & {
  __typename?: 'Driver';
  basic?: Maybe<BasicDriverExtension>;
  coloring?: Maybe<ColoringData>;
  currencyISOCode?: Maybe<Scalars['String']>;
  decimalPlaces?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  dimensionId?: Maybe<Scalars['ID']>;
  dimensional?: Maybe<DimensionalDriverExtension>;
  driverMapping?: Maybe<DriverMapping>;
  driverReferences?: Maybe<Array<DriverReference>>;
  driverType: DriverType;
  format: DriverFormat;
  generatedExplanation?: Maybe<Scalars['String']>;
  /** @deprecated Use groupId in driverReferences for corresponding blockId instead */
  groupId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  leverType?: Maybe<LeverType>;
  name: Scalars['String'];
  /** @deprecated Use sortIndex in driverReferences for corresponding blockId instead */
  sortIndex?: Maybe<Scalars['Int']>;
  /** @deprecated Determine submodelIds corresponding to blockIds in driverReferences instead */
  submodelId?: Maybe<Scalars['String']>;
  valueType: ValueType;
};

export type DriverAttributeFieldExtensionSpec = {
  __typename?: 'DriverAttributeFieldExtensionSpec';
  options: Array<DriverAttributeFieldOption>;
};

export type DriverAttributeFieldExtensionSpecInput = {
  options: Array<DriverAttributeFieldOptionInput>;
};

export type DriverAttributeFieldOption = {
  __typename?: 'DriverAttributeFieldOption';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DriverAttributeFieldOptionInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DriverAttributeFieldOptionUpdateData = {
  __typename?: 'DriverAttributeFieldOptionUpdateData';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type DriverAttributeFieldOptionUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type DriverAttributeFieldUpdateExtensionData = {
  __typename?: 'DriverAttributeFieldUpdateExtensionData';
  deleteOptions?: Maybe<Array<Scalars['String']>>;
  newOptions?: Maybe<Array<DriverAttributeFieldOption>>;
  updateOptions?: Maybe<Array<DriverAttributeFieldOptionUpdateData>>;
};

export type DriverAttributeFieldUpdateExtensionInput = {
  deleteOptions?: InputMaybe<Array<Scalars['String']>>;
  newOptions?: InputMaybe<Array<DriverAttributeFieldOptionInput>>;
  updateOptions?: InputMaybe<Array<DriverAttributeFieldOptionUpdateInput>>;
};

export type DriverAxisExtension = {
  __typename?: 'DriverAxisExtension';
  format?: Maybe<DriverAxisFormat>;
  groupIds: Array<Scalars['ID']>;
  isNormalized?: Maybe<Scalars['Boolean']>;
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  round?: Maybe<Scalars['Boolean']>;
  showTotals?: Maybe<Scalars['Boolean']>;
};

export type DriverAxisExtensionInput = {
  format?: InputMaybe<DriverAxisFormatInput>;
  groupIds: Array<Scalars['ID']>;
  isNormalized?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  round?: InputMaybe<Scalars['Boolean']>;
  showTotals?: InputMaybe<Scalars['Boolean']>;
};

export type DriverAxisFormat = {
  __typename?: 'DriverAxisFormat';
  currencyISOCode?: Maybe<Scalars['String']>;
  decimalPlaces?: Maybe<Scalars['Int']>;
  type: DriverFormat;
};

export type DriverAxisFormatInput = {
  currencyISOCode?: InputMaybe<Scalars['String']>;
  decimalPlaces?: InputMaybe<Scalars['Int']>;
  type: DriverFormat;
};

export type DriverCreateData = {
  __typename?: 'DriverCreateData';
  basic?: Maybe<BasicDriverCreateData>;
  coloring?: Maybe<ColoringData>;
  currencyISOCode?: Maybe<Scalars['String']>;
  decimalPlaces?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  dimensional?: Maybe<DimensionalDriverCreateData>;
  driverMapping?: Maybe<DriverMapping>;
  driverReferences?: Maybe<Array<DriverReferenceData>>;
  driverType: DriverType;
  format: DriverFormat;
  /** @deprecated Use groupId in driverReferences for corresponding blockId instead */
  groupId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  leverType?: Maybe<LeverType>;
  name: Scalars['String'];
  /** @deprecated Use sortIndex in driverReferences for corresponding blockId instead */
  sortIndex?: Maybe<Scalars['Int']>;
  /** @deprecated Determine submodelIds corresponding to blockIds in driverReferences instead */
  submodelId?: Maybe<Scalars['String']>;
  valueType: ValueType;
};

export type DriverCreateInput = {
  basic?: InputMaybe<BasicDriverCreateInput>;
  coloring?: InputMaybe<ColoringInput>;
  currencyISOCode?: InputMaybe<Scalars['String']>;
  decimalPlaces?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  dimensional?: InputMaybe<DimensionalDriverCreateInput>;
  driverMapping?: InputMaybe<DriverMappingInput>;
  driverReferences?: InputMaybe<Array<DriverReferenceInput>>;
  driverType: DriverType;
  format: DriverFormat;
  /** @deprecated Use groupId in driverReferences for corresponding blockId instead */
  groupId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  leverType?: InputMaybe<LeverType>;
  name: Scalars['String'];
  /** @deprecated Use sortIndex in driverReferences for corresponding blockId instead */
  sortIndex?: InputMaybe<Scalars['Int']>;
  /** @deprecated Determine submodelIds corresponding to blockIds in driverReferences instead */
  submodelId?: InputMaybe<Scalars['String']>;
  valueType: ValueType;
};

export type DriverDeleteData = {
  __typename?: 'DriverDeleteData';
  id: Scalars['ID'];
};

export type DriverDeleteInput = {
  id: Scalars['ID'];
};

export type DriverField = {
  __typename?: 'DriverField';
  driverId: Scalars['String'];
  fieldSpecId: Scalars['String'];
  value: DriverFieldValue;
};

export type DriverFieldGroupByExtension = {
  __typename?: 'DriverFieldGroupByExtension';
  collapsedAttributeOptionIds?: Maybe<Array<Scalars['String']>>;
  driverFieldSpecId: Scalars['String'];
};

export type DriverFieldGroupByExtensionInput = {
  collapsedAttributeOptionIds?: InputMaybe<Array<Scalars['String']>>;
  driverFieldSpecId: Scalars['String'];
};

export type DriverFieldSetData = {
  __typename?: 'DriverFieldSetData';
  driverId: Scalars['String'];
  fieldSpecId: Scalars['String'];
  value: DriverFieldValue;
};

export type DriverFieldSetInput = {
  driverId: Scalars['String'];
  fieldSpecId: Scalars['String'];
  value: DriverFieldValueInput;
};

export type DriverFieldSpec = {
  __typename?: 'DriverFieldSpec';
  attribute?: Maybe<DriverAttributeFieldExtensionSpec>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: DriverFieldValueType;
};

export type DriverFieldSpecCreateData = {
  __typename?: 'DriverFieldSpecCreateData';
  attribute?: Maybe<DriverAttributeFieldExtensionSpec>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: DriverFieldValueType;
};

export type DriverFieldSpecCreateInput = {
  attribute?: InputMaybe<DriverAttributeFieldExtensionSpecInput>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: DriverFieldValueType;
};

export type DriverFieldSpecUpdateData = {
  __typename?: 'DriverFieldSpecUpdateData';
  attribute?: Maybe<DriverAttributeFieldUpdateExtensionData>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type DriverFieldSpecUpdateInput = {
  attribute?: InputMaybe<DriverAttributeFieldUpdateExtensionInput>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type DriverFieldValue = {
  __typename?: 'DriverFieldValue';
  optionId?: Maybe<Scalars['String']>;
};

export type DriverFieldValueInput = {
  optionId?: InputMaybe<Scalars['String']>;
};

export enum DriverFieldValueType {
  Attribute = 'ATTRIBUTE',
}

export enum DriverFormat {
  Auto = 'AUTO',
  Currency = 'CURRENCY',
  Integer = 'INTEGER',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
}

export type DriverGroup = Node & {
  __typename?: 'DriverGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DriverGroupCreate = {
  __typename?: 'DriverGroupCreate';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DriverGroupCreateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DriverGroupDelete = {
  __typename?: 'DriverGroupDelete';
  id: Scalars['ID'];
};

export type DriverGroupDeleteInput = {
  id: Scalars['ID'];
};

export type DriverGroupRename = {
  __typename?: 'DriverGroupRename';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DriverGroupRenameInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DriverGroupUpdateIndentInput = {
  id: Scalars['ID'];
};

export type DriverIndentation = {
  __typename?: 'DriverIndentation';
  driverId: Scalars['ID'];
  level: Scalars['Int'];
};

export type DriverIndentationInput = {
  driverId: Scalars['ID'];
  level: Scalars['Int'];
};

export type DriverMapping = {
  __typename?: 'DriverMapping';
  driverId: Scalars['ID'];
};

export type DriverMappingInput = {
  driverId: Scalars['String'];
};

export type DriverPivot = {
  __typename?: 'DriverPivot';
  dimensionId: Scalars['ID'];
  driverPropertyIds: Array<Scalars['ID']>;
};

export type DriverProperty = {
  __typename?: 'DriverProperty';
  driverId: Scalars['String'];
  extQueryId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DriverPropertyCreateData = {
  __typename?: 'DriverPropertyCreateData';
  driverId: Scalars['String'];
  extQueryId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DriverPropertyCreateInput = {
  driverId: Scalars['String'];
  extQueryId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DriverPropertyGroupByExtension = {
  __typename?: 'DriverPropertyGroupByExtension';
  collapsedAttributeIds?: Maybe<Array<Scalars['String']>>;
  driverPropertyId: Scalars['String'];
};

export type DriverPropertyGroupByExtensionInput = {
  collapsedAttributeIds?: InputMaybe<Array<Scalars['String']>>;
  driverPropertyId: Scalars['String'];
};

export type DriverReference = {
  __typename?: 'DriverReference';
  blockId: Scalars['String'];
  groupId?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
};

export type DriverReferenceData = {
  __typename?: 'DriverReferenceData';
  blockId: Scalars['String'];
  groupId?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
};

export type DriverReferenceInput = {
  blockId: Scalars['String'];
  groupId?: InputMaybe<Scalars['String']>;
  sortIndex?: InputMaybe<Scalars['Int']>;
};

export enum DriverType {
  Basic = 'BASIC',
  Dimensional = 'DIMENSIONAL',
}

export type DriverUpdateData = {
  __typename?: 'DriverUpdateData';
  actuals?: Maybe<ActualsWithNullableTimeSeries>;
  coloring?: Maybe<ColoringData>;
  currencyISOCode?: Maybe<Scalars['String']>;
  decimalPlaces?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  dimensional?: Maybe<DimensionalDriverUpdateData>;
  driverMapping?: Maybe<DriverMapping>;
  driverReferences?: Maybe<Array<DriverReferenceData>>;
  forecast?: Maybe<Forecast>;
  format?: Maybe<DriverFormat>;
  /** @deprecated Use groupId in driverReferences for corresponding blockId instead */
  groupId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  leverType?: Maybe<LeverType>;
  name?: Maybe<Scalars['String']>;
  newSubDrivers?: Maybe<Array<DimensionalSubDriverCreateData>>;
  removeAllDriverReferences?: Maybe<Scalars['Boolean']>;
  removeDriverMapping?: Maybe<Scalars['Boolean']>;
  removeSubmodelId?: Maybe<Scalars['Boolean']>;
  rollup?: Maybe<Rollup>;
  /** @deprecated Use sortIndex in driverReferences for corresponding blockId instead */
  sortIndex?: Maybe<Scalars['Int']>;
  /** @deprecated Determine submodelIds corresponding to blockIds in driverReferences instead */
  submodelId?: Maybe<Scalars['String']>;
};

export type DriverUpdateInput = {
  actuals?: InputMaybe<ActualsInput>;
  coloring?: InputMaybe<ColoringInput>;
  currencyISOCode?: InputMaybe<Scalars['String']>;
  decimalPlaces?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  dimensional?: InputMaybe<DimensionalDriverUpdateInput>;
  driverMapping?: InputMaybe<DriverMappingInput>;
  driverReferences?: InputMaybe<Array<DriverReferenceInput>>;
  forecast?: InputMaybe<ForecastInput>;
  format?: InputMaybe<DriverFormat>;
  /** @deprecated Use groupId in driverReferences for corresponding blockId instead */
  groupId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  leverType?: InputMaybe<LeverType>;
  name?: InputMaybe<Scalars['String']>;
  newSubDrivers?: InputMaybe<Array<DimensionalSubDriverCreateInput>>;
  removeAllDriverReferences?: InputMaybe<Scalars['Boolean']>;
  removeDriverMapping?: InputMaybe<Scalars['Boolean']>;
  removeSubmodelId?: InputMaybe<Scalars['Boolean']>;
  rollup?: InputMaybe<RollupInput>;
  /** @deprecated Use sortIndex in driverReferences for corresponding blockId instead */
  sortIndex?: InputMaybe<Scalars['Int']>;
  /** @deprecated Determine submodelIds corresponding to blockIds in driverReferences instead */
  submodelId?: InputMaybe<Scalars['String']>;
  valueType?: InputMaybe<ValueType>;
};

export enum DynamicDate {
  LastClose = 'LAST_CLOSE',
  ThisMonth = 'THIS_MONTH',
}

export type ErrorOriginEntity = {
  __typename?: 'ErrorOriginEntity';
  id: Scalars['String'];
  type: FormulaEntityType;
};

/**
 * An Event is an entity that represents a future change that is expected to impact the metric of a single Driver or BusinessObjectField.
 *
 * This change can either occur at a single point-in-time or over a time span.
 */
export type Event = Node & {
  __typename?: 'Event';
  businessObjectField?: Maybe<BusinessObjectField>;
  curveType?: Maybe<CurveType>;
  customCurvePoints?: Maybe<Array<TimeSeriesPoint>>;
  description: Scalars['String'];
  driver?: Maybe<Driver>;
  end: Scalars['Time'];
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  impactType: ImpactType;
  modifierType?: Maybe<ModifierType>;
  name?: Maybe<Scalars['String']>;
  ownerName: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  /** @deprecated Use `totalImpact` or `customCurvePoints` instead. */
  setValue?: Maybe<Scalars['String']>;
  setValueType?: Maybe<ValueType>;
  sortIndex?: Maybe<Scalars['Int']>;
  start: Scalars['Time'];
  time?: Maybe<Scalars['Time']>;
  totalImpact?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type EventCreateData = {
  __typename?: 'EventCreateData';
  businessObjectFieldId?: Maybe<Scalars['String']>;
  curveType?: Maybe<CurveType>;
  customCurvePoints?: Maybe<Array<TimeSeriesPoint>>;
  description?: Maybe<Scalars['String']>;
  driverId?: Maybe<Scalars['String']>;
  end: Scalars['Time'];
  id: Scalars['ID'];
  impactType?: Maybe<ImpactType>;
  modifierType?: Maybe<ModifierType>;
  name?: Maybe<Scalars['String']>;
  ownerName: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  /** @deprecated see `Event` */
  setValue?: Maybe<Scalars['String']>;
  setValueType?: Maybe<ValueType>;
  sortIndex?: Maybe<Scalars['Int']>;
  start: Scalars['Time'];
  time?: Maybe<Scalars['Time']>;
  totalImpact?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type EventCreateInput = {
  businessObjectFieldId?: InputMaybe<Scalars['String']>;
  curveType?: InputMaybe<CurveType>;
  customCurvePoints?: InputMaybe<Array<TimeSeriesPointInput>>;
  description?: InputMaybe<Scalars['String']>;
  driverId?: InputMaybe<Scalars['String']>;
  end: Scalars['Time'];
  hidden?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  impactType?: InputMaybe<ImpactType>;
  modifierType?: InputMaybe<ModifierType>;
  name?: InputMaybe<Scalars['String']>;
  ownerName: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  /** @deprecated see `Event` */
  setValue?: InputMaybe<Scalars['String']>;
  setValueType?: InputMaybe<ValueType>;
  sortIndex?: InputMaybe<Scalars['Int']>;
  start: Scalars['Time'];
  time?: InputMaybe<Scalars['Time']>;
  totalImpact?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type EventDeleteData = {
  __typename?: 'EventDeleteData';
  id: Scalars['ID'];
};

export type EventDeleteInput = {
  id: Scalars['ID'];
};

export type EventGroup = Node & {
  __typename?: 'EventGroup';
  defaultEnd?: Maybe<Scalars['Time']>;
  defaultStart?: Maybe<Scalars['Time']>;
  description: Scalars['String'];
  eventGroupIds: Array<Scalars['String']>;
  eventIds: Array<Scalars['String']>;
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  ownerId: Scalars['String'];
  /** @deprecated ownerName is deprecated. Use ownerId instead. */
  ownerName: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
};

export type EventGroupCreateData = {
  __typename?: 'EventGroupCreateData';
  defaultEnd?: Maybe<Scalars['Time']>;
  defaultStart?: Maybe<Scalars['Time']>;
  description?: Maybe<Scalars['String']>;
  eventGroupIds?: Maybe<Array<Scalars['String']>>;
  eventIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  ownerId: Scalars['String'];
  /** @deprecated ownerName is deprecated. Use ownerId instead. */
  ownerName: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
};

export type EventGroupCreateInput = {
  defaultEnd?: InputMaybe<Scalars['Time']>;
  defaultStart?: InputMaybe<Scalars['Time']>;
  description?: InputMaybe<Scalars['String']>;
  eventGroupIds?: InputMaybe<Array<Scalars['String']>>;
  eventIds?: InputMaybe<Array<Scalars['String']>>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  ownerId: Scalars['String'];
  ownerName: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  sortIndex?: InputMaybe<Scalars['Int']>;
};

export type EventGroupDeleteData = {
  __typename?: 'EventGroupDeleteData';
  id: Scalars['ID'];
};

export type EventGroupDeleteInput = {
  id: Scalars['ID'];
};

export type EventGroupUpdateData = {
  __typename?: 'EventGroupUpdateData';
  defaultEnd?: Maybe<Scalars['Time']>;
  defaultStart?: Maybe<Scalars['Time']>;
  description?: Maybe<Scalars['String']>;
  eventGroupIds?: Maybe<Array<Scalars['String']>>;
  eventIds?: Maybe<Array<Scalars['String']>>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  /** @deprecated ownerName is deprecated. Use ownerId instead. */
  ownerName?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
};

export type EventGroupUpdateInput = {
  defaultEnd?: InputMaybe<Scalars['Time']>;
  defaultStart?: InputMaybe<Scalars['Time']>;
  description?: InputMaybe<Scalars['String']>;
  eventGroupIds?: InputMaybe<Array<Scalars['String']>>;
  eventIds?: InputMaybe<Array<Scalars['String']>>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  ownerName?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  sortIndex?: InputMaybe<Scalars['Int']>;
};

export type EventUpdateData = {
  __typename?: 'EventUpdateData';
  businessObjectFieldId?: Maybe<Scalars['String']>;
  curveType?: Maybe<CurveType>;
  customCurvePoints?: Maybe<Array<NullableTimeSeriesPoint>>;
  description?: Maybe<Scalars['String']>;
  driverId?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['Time']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  impactType?: Maybe<ImpactType>;
  name?: Maybe<Scalars['String']>;
  ownerName?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  /** @deprecated see `Event` */
  setValue?: Maybe<Scalars['String']>;
  setValueType?: Maybe<ValueType>;
  sortIndex?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Time']>;
  time?: Maybe<Scalars['Time']>;
  totalImpact?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type EventUpdateInput = {
  businessObjectFieldId?: InputMaybe<Scalars['String']>;
  curveType?: InputMaybe<CurveType>;
  customCurvePoints?: InputMaybe<Array<TimeSeriesPointInput>>;
  description?: InputMaybe<Scalars['String']>;
  driverId?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['Time']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  impactType?: InputMaybe<ImpactType>;
  name?: InputMaybe<Scalars['String']>;
  ownerName?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  /** @deprecated see `Event` */
  setValue?: InputMaybe<Scalars['String']>;
  setValueType?: InputMaybe<ValueType>;
  sortIndex?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['Time']>;
  time?: InputMaybe<Scalars['Time']>;
  totalImpact?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** An Export represents a saved slice of the dataset that should be exported. */
export type Export = {
  __typename?: 'Export';
  drivers: Array<Driver>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ExportCreateData = {
  __typename?: 'ExportCreateData';
  driverIds: Array<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ExportCreateInput = {
  driverIds: Array<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ExportDeleteData = {
  __typename?: 'ExportDeleteData';
  id: Scalars['ID'];
};

export type ExportDeleteInput = {
  id: Scalars['ID'];
};

export type ExportMutationInput = {
  includeHistory: Scalars['Boolean'];
  isAnonymized: Scalars['Boolean'];
  upToMutationId?: InputMaybe<Scalars['String']>;
};

export type ExportUpdateData = {
  __typename?: 'ExportUpdateData';
  driverIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type ExportUpdateInput = {
  driverIds?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type ExtDriver = {
  __typename?: 'ExtDriver';
  accountId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integrationQueryId?: Maybe<Scalars['String']>;
  model: ExtModel;
  path: Array<Scalars['String']>;
  query?: Maybe<TransactionFilterQuery>;
  remoteId?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  timeSeries?: Maybe<Array<TimeSeriesPoint>>;
};

export type ExtDriverCreateData = {
  __typename?: 'ExtDriverCreateData';
  accountId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integrationQueryId?: Maybe<Scalars['String']>;
  model: ExtModel;
  path: Array<Scalars['String']>;
  query?: Maybe<TransactionFilterQuery>;
  remoteId?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  timeSeries?: Maybe<Array<TimeSeriesPoint>>;
};

export type ExtDriverCreateInput = {
  accountId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  integrationQueryId?: InputMaybe<Scalars['String']>;
  model: ExtModel;
  path: Array<Scalars['String']>;
  query?: InputMaybe<TransactionFilterQueryInput>;
  remoteId?: InputMaybe<Scalars['String']>;
  source: Scalars['String'];
  timeSeries?: InputMaybe<Array<TimeSeriesPointInput>>;
};

export type ExtDriverDeleteData = {
  __typename?: 'ExtDriverDeleteData';
  id: Scalars['ID'];
};

export type ExtDriverDeleteInput = {
  id: Scalars['ID'];
};

export type ExtDriverUpdateData = {
  __typename?: 'ExtDriverUpdateData';
  accountId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  path?: Maybe<Array<Scalars['String']>>;
  query?: Maybe<TransactionFilterQuery>;
  timeSeries?: Maybe<Array<NullableTimeSeriesPoint>>;
};

export type ExtDriverUpdateInput = {
  accountId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  path?: InputMaybe<Array<Scalars['String']>>;
  query?: InputMaybe<TransactionFilterQueryInput>;
  timeSeries?: InputMaybe<Array<TimeSeriesPointInput>>;
};

export enum ExtModel {
  /** Accounting */
  Account = 'Account',
  AccountingAttachment = 'AccountingAttachment',
  AccountingPhoneNumber = 'AccountingPhoneNumber',
  Address = 'Address',
  BalanceSheet = 'BalanceSheet',
  /** HRIS */
  BankInfo = 'BankInfo',
  /** CRM */
  CrmAccount = 'CRMAccount',
  CrmContact = 'CRMContact',
  CrmUser = 'CRMUser',
  CashFlowStatement = 'CashFlowStatement',
  /** Ticketing */
  Comment = 'Comment',
  CompanyInfo = 'CompanyInfo',
  Contact = 'Contact',
  CreditNote = 'CreditNote',
  /** ATS */
  Department = 'Department',
  Employee = 'Employee',
  Employment = 'Employment',
  Expense = 'Expense',
  ExpenseLine = 'ExpenseLine',
  Group = 'Group',
  IncomeStatement = 'IncomeStatement',
  Invoice = 'Invoice',
  InvoiceLineItem = 'InvoiceLineItem',
  Item = 'Item',
  Job = 'Job',
  JournalEntry = 'JournalEntry',
  JournalLine = 'JournalLine',
  /** Custom */
  LeadSource = 'LeadSource',
  Location = 'Location',
  Note = 'Note',
  Office = 'Office',
  Opportunity = 'Opportunity',
  PayGroup = 'PayGroup',
  Payment = 'Payment',
  ProfitAndLossStatement = 'ProfitAndLossStatement',
  Project = 'Project',
  PurchaseOrder = 'PurchaseOrder',
  PurchaseOrderLineItem = 'PurchaseOrderLineItem',
  RemoteUser = 'RemoteUser',
  ReportItem = 'ReportItem',
  /** Runway Custom */
  RunwayCustomTransactionDrivers = 'RunwayCustomTransactionDrivers',
  Stage = 'Stage',
  TaxRate = 'TaxRate',
  Team = 'Team',
  Ticket = 'Ticket',
  TicketingAttachment = 'TicketingAttachment',
  TicketingTag = 'TicketingTag',
  TicketingTeam = 'TicketingTeam',
  TimeOff = 'TimeOff',
  TrackingCategory = 'TrackingCategory',
  Unknown = 'Unknown',
  User = 'User',
  VendorCredit = 'VendorCredit',
  VendorCreditLine = 'VendorCreditLine',
}

export type ExtObject = {
  __typename?: 'ExtObject';
  extKey: Scalars['String'];
  extSpecKey: Scalars['String'];
  fields: Array<ExtObjectField>;
  name: Scalars['String'];
  remoteId: Scalars['String'];
};

export type ExtObjectCreateData = {
  __typename?: 'ExtObjectCreateData';
  extKey: Scalars['String'];
  extSpecKey: Scalars['String'];
  fields: Array<ExtObjectFieldCreateData>;
  name: Scalars['String'];
  remoteId: Scalars['String'];
};

export type ExtObjectCreateInput = {
  extKey: Scalars['String'];
  extSpecKey: Scalars['String'];
  fields: Array<ExtObjectFieldCreateInput>;
  name: Scalars['String'];
  remoteId: Scalars['String'];
};

export type ExtObjectDeleteData = {
  __typename?: 'ExtObjectDeleteData';
  extKey: Scalars['String'];
};

export type ExtObjectDeleteInput = {
  extKey: Scalars['String'];
};

export type ExtObjectField = {
  __typename?: 'ExtObjectField';
  extFieldSpecKey: Scalars['String'];
  timeSeries: Array<TimeSeriesPoint>;
};

export type ExtObjectFieldCreateData = {
  __typename?: 'ExtObjectFieldCreateData';
  extFieldSpecKey: Scalars['String'];
  timeSeries: Array<TimeSeriesPoint>;
};

export type ExtObjectFieldCreateInput = {
  extFieldSpecKey: Scalars['String'];
  timeSeries: Array<TimeSeriesPointInput>;
};

export type ExtObjectFieldSpec = {
  __typename?: 'ExtObjectFieldSpec';
  dimensionId?: Maybe<Scalars['String']>;
  extKey: Scalars['String'];
  name: Scalars['String'];
  numericFormat?: Maybe<DriverFormat>;
  type: ValueType;
};

export type ExtObjectFieldSpecInput = {
  dimensionId?: InputMaybe<Scalars['String']>;
  extKey: Scalars['String'];
  name: Scalars['String'];
  numericFormat?: InputMaybe<DriverFormat>;
  type: ValueType;
};

export type ExtObjectSpec = {
  __typename?: 'ExtObjectSpec';
  extKey: Scalars['String'];
  fields: Array<ExtObjectFieldSpec>;
  integrationQueryId?: Maybe<Scalars['String']>;
  model: ExtModel;
  overrideForecastData: Scalars['Boolean'];
  propagateDataForward: Scalars['Boolean'];
  source: Scalars['String'];
  startFieldExtKey?: Maybe<Scalars['String']>;
};

export type ExtObjectSpecCreateData = {
  __typename?: 'ExtObjectSpecCreateData';
  extKey: Scalars['String'];
  fields: Array<ExtObjectFieldSpec>;
  integrationQueryId?: Maybe<Scalars['String']>;
  model: ExtModel;
  overrideForecastData: Scalars['Boolean'];
  propagateDataForward: Scalars['Boolean'];
  source: Scalars['String'];
  startFieldExtKey?: Maybe<Scalars['String']>;
};

export type ExtObjectSpecCreateInput = {
  extKey: Scalars['String'];
  fields: Array<ExtObjectFieldSpecInput>;
  integrationQueryId?: InputMaybe<Scalars['String']>;
  model: ExtModel;
  overrideForecastData: Scalars['Boolean'];
  propagateDataForward: Scalars['Boolean'];
  source: Scalars['String'];
  startFieldExtKey?: InputMaybe<Scalars['String']>;
};

export type ExtObjectSpecDeleteData = {
  __typename?: 'ExtObjectSpecDeleteData';
  extKey: Scalars['String'];
};

export type ExtObjectSpecDeleteInput = {
  extKey: Scalars['String'];
};

export type ExtObjectSpecUpdateData = {
  __typename?: 'ExtObjectSpecUpdateData';
  extKey: Scalars['String'];
  overrideForecastData?: Maybe<Scalars['Boolean']>;
  propagateDataForward?: Maybe<Scalars['Boolean']>;
};

export type ExtObjectSpecUpdateInput = {
  extKey: Scalars['String'];
  overrideForecastData?: InputMaybe<Scalars['Boolean']>;
  propagateDataForward?: InputMaybe<Scalars['Boolean']>;
};

export type ExtQuery = {
  __typename?: 'ExtQuery';
  id: Scalars['ID'];
  raw: Scalars['String'];
  source: ExtQuerySource;
};

export type ExtQueryCreateData = {
  __typename?: 'ExtQueryCreateData';
  id: Scalars['ID'];
  raw: Scalars['String'];
  source: ExtQuerySource;
};

export type ExtQueryCreateInput = {
  id: Scalars['ID'];
  raw: Scalars['String'];
  source: ExtQuerySource;
};

export type ExtQueryDeleteData = {
  __typename?: 'ExtQueryDeleteData';
  id: Scalars['ID'];
};

export type ExtQueryDeleteInput = {
  id: Scalars['ID'];
};

export enum ExtQuerySource {
  ExtTable = 'ExtTable',
}

export type ExtQueryUpdateData = {
  __typename?: 'ExtQueryUpdateData';
  id: Scalars['ID'];
  raw?: Maybe<Scalars['String']>;
  source?: Maybe<ExtQuerySource>;
};

export type ExtQueryUpdateInput = {
  id: Scalars['ID'];
  raw?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<ExtQuerySource>;
};

export enum ExtStaticSource {
  Amplitude = 'AMPLITUDE',
  MergeActivecampaign = 'MERGE_ACTIVECAMPAIGN',
  MergeAdpWorkforceNow = 'MERGE_ADP_WORKFORCE_NOW',
  MergeAlexishr = 'MERGE_ALEXISHR',
  MergeAlteraPayroll = 'MERGE_ALTERA_PAYROLL',
  MergeApplicantstack = 'MERGE_APPLICANTSTACK',
  MergeAsana = 'MERGE_ASANA',
  MergeAshby = 'MERGE_ASHBY',
  MergeBamboohr = 'MERGE_BAMBOOHR',
  MergeBizmerlinhr = 'MERGE_BIZMERLINHR',
  MergeBreathe = 'MERGE_BREATHE',
  MergeBreezyHr = 'MERGE_BREEZY_HR',
  MergeCapsule = 'MERGE_CAPSULE',
  MergeCats = 'MERGE_CATS',
  MergeCeridianDayforce = 'MERGE_CERIDIAN_DAYFORCE',
  MergeCharliehr = 'MERGE_CHARLIEHR',
  MergeCharthop = 'MERGE_CHARTHOP',
  MergeClearBooks = 'MERGE_CLEAR_BOOKS',
  MergeClickup = 'MERGE_CLICKUP',
  MergeClockwork = 'MERGE_CLOCKWORK',
  MergeClose = 'MERGE_CLOSE',
  MergeComeet = 'MERGE_COMEET',
  MergeCopper = 'MERGE_COPPER',
  MergeCornerstoneTalentlink = 'MERGE_CORNERSTONE_TALENTLINK',
  MergeDeel = 'MERGE_DEEL',
  MergeEploy = 'MERGE_EPLOY',
  MergeFactorial = 'MERGE_FACTORIAL',
  MergeFountain = 'MERGE_FOUNTAIN',
  MergeFreshbooks = 'MERGE_FRESHBOOKS',
  MergeFreshdesk = 'MERGE_FRESHDESK',
  MergeFreshteam = 'MERGE_FRESHTEAM',
  MergeFront = 'MERGE_FRONT',
  MergeGithubIssues = 'MERGE_GITHUB_ISSUES',
  MergeGitlab = 'MERGE_GITLAB',
  MergeGreenhouse = 'MERGE_GREENHOUSE',
  MergeGreenhouseJobBoardsApi = 'MERGE_GREENHOUSE_JOB_BOARDS_API',
  MergeGusto = 'MERGE_GUSTO',
  MergeHibob = 'MERGE_HIBOB',
  MergeHive = 'MERGE_HIVE',
  MergeHomerun = 'MERGE_HOMERUN',
  MergeHrCloud = 'MERGE_HR_CLOUD',
  MergeHrPartner = 'MERGE_HR_PARTNER',
  MergeHubspot = 'MERGE_HUBSPOT',
  MergeHumaans = 'MERGE_HUMAANS',
  MergeHumaansio = 'MERGE_HUMAANSIO',
  MergeIntellihr = 'MERGE_INTELLIHR',
  MergeIntercom = 'MERGE_INTERCOM',
  MergeJazzhr = 'MERGE_JAZZHR',
  MergeJiraServiceManagement = 'MERGE_JIRA_SERVICE_MANAGEMENT',
  MergeJiraSoftware = 'MERGE_JIRA_SOFTWARE',
  MergeJobscore = 'MERGE_JOBSCORE',
  MergeJobsoid = 'MERGE_JOBSOID',
  MergeJobvite = 'MERGE_JOBVITE',
  MergeJustworks = 'MERGE_JUSTWORKS',
  MergeKeka = 'MERGE_KEKA',
  MergeLano = 'MERGE_LANO',
  MergeLever = 'MERGE_LEVER',
  MergeLinear = 'MERGE_LINEAR',
  MergeLucca = 'MERGE_LUCCA',
  MergeMicrosoftDynamics_365 = 'MERGE_MICROSOFT_DYNAMICS_365',
  MergeNamely = 'MERGE_NAMELY',
  MergeNetsuite = 'MERGE_NETSUITE',
  MergeNmbrs = 'MERGE_NMBRS',
  MergeOracleTaleo = 'MERGE_ORACLE_TALEO',
  MergePaychex = 'MERGE_PAYCHEX',
  MergePaylocity = 'MERGE_PAYLOCITY',
  MergePeoplehr = 'MERGE_PEOPLEHR',
  MergePersonio = 'MERGE_PERSONIO',
  MergePipedrive = 'MERGE_PIPEDRIVE',
  MergePivotalTracker = 'MERGE_PIVOTAL_TRACKER',
  MergePolymer = 'MERGE_POLYMER',
  MergeProliant = 'MERGE_PROLIANT',
  MergeQuickbooksOnline = 'MERGE_QUICKBOOKS_ONLINE',
  MergeRecruitee = 'MERGE_RECRUITEE',
  MergeRecruiterflow = 'MERGE_RECRUITERFLOW',
  MergeSageBusinessCloudAccounting = 'MERGE_SAGE_BUSINESS_CLOUD_ACCOUNTING',
  MergeSageHr = 'MERGE_SAGE_HR',
  MergeSageIntacct = 'MERGE_SAGE_INTACCT',
  MergeSalesflare = 'MERGE_SALESFLARE',
  /** @deprecated Use WORKATO_SALESFORCE instead */
  MergeSalesforce = 'MERGE_SALESFORCE',
  MergeSapling = 'MERGE_SAPLING',
  MergeSapSuccessfactors = 'MERGE_SAP_SUCCESSFACTORS',
  MergeServicenow = 'MERGE_SERVICENOW',
  MergeSesame = 'MERGE_SESAME',
  MergeSharpspring = 'MERGE_SHARPSPRING',
  MergeShortcut = 'MERGE_SHORTCUT',
  MergeSmartrecruiters = 'MERGE_SMARTRECRUITERS',
  MergeSquarePayroll = 'MERGE_SQUARE_PAYROLL',
  MergeTalentlyft = 'MERGE_TALENTLYFT',
  MergeTalentreef = 'MERGE_TALENTREEF',
  MergeTeamleader = 'MERGE_TEAMLEADER',
  MergeTeamtailor = 'MERGE_TEAMTAILOR',
  MergeTeamwork = 'MERGE_TEAMWORK',
  MergeTeamworkCrm = 'MERGE_TEAMWORK_CRM',
  MergeTrello = 'MERGE_TRELLO',
  MergeTrinet = 'MERGE_TRINET',
  MergeTrinetHrPlatform = 'MERGE_TRINET_HR_PLATFORM',
  MergeUkgPro = 'MERGE_UKG_PRO',
  MergeUkgProRecruiting = 'MERGE_UKG_PRO_RECRUITING',
  MergeUkgReady = 'MERGE_UKG_READY',
  MergeWorkable = 'MERGE_WORKABLE',
  MergeWorkday = 'MERGE_WORKDAY',
  MergeWrike = 'MERGE_WRIKE',
  MergeXero = 'MERGE_XERO',
  MergeZendesk = 'MERGE_ZENDESK',
  MergeZendeskSell = 'MERGE_ZENDESK_SELL',
  MergeZenefits = 'MERGE_ZENEFITS',
  MergeZohoBooks = 'MERGE_ZOHO_BOOKS',
  MergeZohoCrm = 'MERGE_ZOHO_CRM',
  PuzzleAccounting = 'PUZZLE_ACCOUNTING',
  Rippling = 'RIPPLING',
  RunwayApi = 'RUNWAY_API',
  WorkatoAirtable = 'WORKATO_AIRTABLE',
  WorkatoAmazonS3 = 'WORKATO_AMAZON_S3',
  WorkatoAwsCostExplorer = 'WORKATO_AWS_COST_EXPLORER',
  WorkatoAzureBlobStorage = 'WORKATO_AZURE_BLOB_STORAGE',
  WorkatoChargebeeAdmin = 'WORKATO_CHARGEBEE_ADMIN',
  WorkatoCoupa = 'WORKATO_COUPA',
  WorkatoFacebookLeadAds = 'WORKATO_FACEBOOK_LEAD_ADS',
  WorkatoGoogleAds = 'WORKATO_GOOGLE_ADS',
  WorkatoGoogleAnalytics = 'WORKATO_GOOGLE_ANALYTICS',
  WorkatoGoogleBigQuery = 'WORKATO_GOOGLE_BIG_QUERY',
  WorkatoGoogleSheets = 'WORKATO_GOOGLE_SHEETS',
  WorkatoHubspot = 'WORKATO_HUBSPOT',
  WorkatoHubspotDefault = 'WORKATO_HUBSPOT_DEFAULT',
  WorkatoLooker = 'WORKATO_LOOKER',
  WorkatoNetsuite = 'WORKATO_NETSUITE',
  WorkatoOracle = 'WORKATO_ORACLE',
  WorkatoOracleFinancialsCloud = 'WORKATO_ORACLE_FINANCIALS_CLOUD',
  WorkatoPipedrive = 'WORKATO_PIPEDRIVE',
  WorkatoRamp = 'WORKATO_RAMP',
  WorkatoRedshift = 'WORKATO_REDSHIFT',
  WorkatoRest = 'WORKATO_REST',
  WorkatoSalesforce = 'WORKATO_SALESFORCE',
  WorkatoSnowflake = 'WORKATO_SNOWFLAKE',
  WorkatoStripe = 'WORKATO_STRIPE',
  WorkatoTableau = 'WORKATO_TABLEAU',
  WorkatoZuora = 'WORKATO_ZUORA',
}

export type ExtTable = {
  __typename?: 'ExtTable';
  computedSnapshot?: Maybe<ExtTableSnapshot>;
  queryId?: Maybe<Scalars['String']>;
  runs: Array<ExtTableRun>;
  schema: ExtTableSchema;
  snapshotStrategy: ExtTableSnapshotStrategy;
  sourceKey: Scalars['String'];
};

export type ExtTableColumn = {
  __typename?: 'ExtTableColumn';
  id: Scalars['String'];
};

export type ExtTableColumnInput = {
  id: Scalars['String'];
};

export type ExtTableCreateData = {
  __typename?: 'ExtTableCreateData';
  queryId?: Maybe<Scalars['String']>;
  schema: ExtTableSchema;
  sourceKey: Scalars['String'];
};

export type ExtTableCreateInput = {
  queryId?: InputMaybe<Scalars['String']>;
  schema: ExtTableSchemaInput;
  sourceKey: Scalars['String'];
};

export type ExtTableDeleteData = {
  __typename?: 'ExtTableDeleteData';
  sourceKey: Scalars['String'];
};

export type ExtTableDeleteInput = {
  sourceKey: Scalars['String'];
};

export type ExtTableDimensionColumn = {
  __typename?: 'ExtTableDimensionColumn';
  dimensionId?: Maybe<Scalars['ID']>;
  dimensionalPropertyId?: Maybe<Scalars['ID']>;
  extQueryId?: Maybe<Scalars['ID']>;
  extTableColumnId: Scalars['String'];
};

export type ExtTableDimensionColumnInput = {
  dimensionId?: InputMaybe<Scalars['ID']>;
  dimensionalPropertyId?: InputMaybe<Scalars['ID']>;
  extQueryId?: InputMaybe<Scalars['ID']>;
  extTableColumnId: Scalars['String'];
};

export type ExtTableDriverColumn = {
  __typename?: 'ExtTableDriverColumn';
  aggregationType: DatabaseConfigAggregationType;
  driverPropertyId?: Maybe<Scalars['ID']>;
  extQueryId?: Maybe<Scalars['ID']>;
  extTableColumnId: Scalars['String'];
};

export type ExtTableDriverColumnInput = {
  aggregationType: DatabaseConfigAggregationType;
  driverPropertyId?: InputMaybe<Scalars['ID']>;
  extQueryId?: InputMaybe<Scalars['ID']>;
  extTableColumnId: Scalars['String'];
};

export type ExtTableFilter = {
  __typename?: 'ExtTableFilter';
  comparator: ComparatorType;
  compareTo: Scalars['String'];
  extTableColumnId: Scalars['String'];
};

export type ExtTableFilterInput = {
  comparator: ComparatorType;
  compareTo: Scalars['String'];
  extTableColumnId: Scalars['String'];
};

export type ExtTableRun = {
  __typename?: 'ExtTableRun';
  dataMacro: DatasetMutationMacro;
  runId: Scalars['ID'];
  schemaVersion: Scalars['Int'];
  sourceKey: Scalars['String'];
};

export type ExtTableRunCreateData = {
  __typename?: 'ExtTableRunCreateData';
  dataMacro: DatasetMutationMacro;
  runId: Scalars['ID'];
  schemaVersion: Scalars['Int'];
  sourceKey: Scalars['String'];
};

export type ExtTableRunCreateInput = {
  dataMacro: DatasetMutationMacroInput;
  runId: Scalars['ID'];
  schemaVersion: Scalars['Int'];
  sourceKey: Scalars['String'];
};

export type ExtTableSchema = {
  __typename?: 'ExtTableSchema';
  columns: Array<ExtTableColumn>;
  version: Scalars['Int'];
};

export type ExtTableSchemaInput = {
  columns: Array<ExtTableColumnInput>;
  version: Scalars['Int'];
};

export type ExtTableSnapshot = {
  __typename?: 'ExtTableSnapshot';
  data: SqlQueryResult;
  schema: ExtTableSchema;
  scopedRunIds: Array<Scalars['String']>;
};

export type ExtTableSnapshotEntry = {
  __typename?: 'ExtTableSnapshotEntry';
  snapshot: ExtTableSnapshot;
  sourceKey: Scalars['String'];
};

export enum ExtTableSnapshotStrategy {
  LatestRun = 'LatestRun',
}

export type ExtTableUpdateData = {
  __typename?: 'ExtTableUpdateData';
  schema?: Maybe<ExtTableSchema>;
  sourceKey: Scalars['String'];
};

export type ExtTableUpdateInput = {
  schema?: InputMaybe<ExtTableSchemaInput>;
  sourceKey: Scalars['String'];
};

export type FileMetadata = {
  __typename?: 'FileMetadata';
  id: Scalars['ID'];
  name: Scalars['String'];
  path: Scalars['String'];
  size: Scalars['Int'];
  type: Scalars['String'];
};

export type FileMetadataInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  path: Scalars['String'];
  size: Scalars['Int'];
  type: Scalars['String'];
};

export type FiveTran = {
  __typename?: 'FiveTran';
  connectCardURI: Scalars['String'];
};

export type FiveTranConnectCardUriArgs = {
  linkedAccountId: Scalars['String'];
  orgId: Scalars['ID'];
};

export enum FiveTranConfigField {
  Schema = 'SCHEMA',
  SchemaPrefix = 'SCHEMA_PREFIX',
  Table = 'TABLE',
}

export type FiveTranCreateConnectorResponse = {
  __typename?: 'FiveTranCreateConnectorResponse';
  connectorCardURI: Scalars['String'];
};

export type FiveTranLinkAccountParams = {
  __typename?: 'FiveTranLinkAccountParams';
  docsLink: Scalars['String'];
  isLiteConnector: Scalars['Boolean'];
  requiredFields: Array<FiveTranConfigField>;
  schemaInfo: Scalars['String'];
  serviceStatus: FiveTranServiceStatus;
};

export enum FiveTranServiceStatus {
  Beta = 'beta',
  Development = 'development',
  GeneralAvailability = 'general_availability',
  PrivatePreview = 'private_preview',
  Sunset = 'sunset',
}

export type Folder = {
  __typename?: 'Folder';
  id: Scalars['ID'];
  name: Scalars['String'];
  parent?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
  type: FolderType;
};

export type FolderCreateData = {
  __typename?: 'FolderCreateData';
  id: Scalars['ID'];
  name: Scalars['String'];
  parent?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
  type: FolderType;
};

export type FolderCreateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  parent?: InputMaybe<Scalars['String']>;
  sortIndex?: InputMaybe<Scalars['Int']>;
  type: FolderType;
};

export type FolderDeleteData = {
  __typename?: 'FolderDeleteData';
  id: Scalars['ID'];
};

export type FolderDeleteInput = {
  id: Scalars['ID'];
};

export enum FolderType {
  Database = 'database',
  Model = 'model',
  Page = 'page',
  Uncategorized = 'uncategorized',
  Workspace = 'workspace',
}

export type FolderUpdateData = {
  __typename?: 'FolderUpdateData';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
  type?: Maybe<FolderType>;
};

export type FolderUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['String']>;
  sortIndex?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<FolderType>;
};

export type Forecast = {
  __typename?: 'Forecast';
  formula: Scalars['String'];
};

export type ForecastInput = {
  formula: Scalars['String'];
};

export type FormulaDateRangeInput = {
  end: Scalars['Time'];
  start: Scalars['Time'];
};

export enum FormulaEntityType {
  DatabaseProperty = 'DatabaseProperty',
  Driver = 'Driver',
  ExtDriver = 'ExtDriver',
  ExtObjectField = 'ExtObjectField',
  ObjectField = 'ObjectField',
}

export type GeneralSettings = {
  __typename?: 'GeneralSettings';
  defaultCurrencyISOCode: Scalars['String'];
  defaultDecimalPrecision?: Maybe<Scalars['Int']>;
  negativeDisplay: NegativeDisplay;
};

export type GeneralSettingsUpdateInput = {
  defaultCurrencyISOCode?: InputMaybe<Scalars['String']>;
  defaultDecimalPrecision?: InputMaybe<Scalars['Int']>;
  negativeDisplay?: InputMaybe<NegativeDisplay>;
};

export type GetAccountingTransactionsInput = {
  endTime?: InputMaybe<Scalars['Time']>;
  query?: InputMaybe<TransactionFilterQueryInput>;
  startTime?: InputMaybe<Scalars['Time']>;
};

export type ImpactDisplay = {
  __typename?: 'ImpactDisplay';
  displayType: ImpactDisplayType;
};

export type ImpactDisplayInput = {
  displayType: ImpactDisplayType;
};

export enum ImpactDisplayType {
  BeforeAfter = 'BEFORE_AFTER',
  Total = 'TOTAL',
}

export type ImpactSortExtension = {
  __typename?: 'ImpactSortExtension';
  driverId: Scalars['String'];
  endMonthKey: Scalars['String'];
  startMonthKey: Scalars['String'];
};

export type ImpactSortExtensionInput = {
  driverId: Scalars['String'];
  endMonthKey: Scalars['String'];
  startMonthKey: Scalars['String'];
};

export enum ImpactType {
  Delta = 'DELTA',
  Set = 'SET',
}

export type ImportNamedSnapshotData = {
  mutationId: Scalars['ID'];
  snapshot: Scalars['String'];
};

export type ImportNamedSnapshotInput = {
  mutationId: Scalars['ID'];
  snapshot: Scalars['String'];
};

export type ImportSnapshotData = {
  __typename?: 'ImportSnapshotData';
  snapshot: Scalars['String'];
};

export type ImportSnapshotInput = {
  snapshot: Scalars['String'];
};

export type Integration = {
  __typename?: 'Integration';
  categories: Array<IntegrationCategory>;
  image?: Maybe<Scalars['String']>;
  linkParams?: Maybe<LinkAccountParams>;
  name: Scalars['String'];
  provider: IntegrationProvider;
  slug: Scalars['String'];
};

export enum IntegrationCategory {
  Accounting = 'accounting',
  Analytics = 'analytics',
  Ats = 'ats',
  Billing = 'billing',
  Bisolutions = 'bisolutions',
  Crm = 'crm',
  Datawarehouse = 'datawarehouse',
  Filestorage = 'filestorage',
  Hris = 'hris',
  Spreadsheet = 'spreadsheet',
  Ticketing = 'ticketing',
  Uncategorized = 'uncategorized',
}

export type IntegrationMetadata = {
  __typename?: 'IntegrationMetadata';
  fivetranGroupId?: Maybe<Scalars['String']>;
  workatoExternalId?: Maybe<Scalars['String']>;
  workatoId?: Maybe<Scalars['String']>;
};

export enum IntegrationProvider {
  Fivetran = 'FIVETRAN',
  Merge = 'MERGE',
  Puzzle = 'PUZZLE',
  Rippling = 'RIPPLING',
  RunwayApi = 'RUNWAY_API',
  Workato = 'WORKATO',
}

export type IntegrationQuery = {
  __typename?: 'IntegrationQuery';
  content: IntegrationQueryContent;
  createdAt?: Maybe<Scalars['Time']>;
  enableExtTable: Scalars['Boolean'];
  id: Scalars['ID'];
  isScheduled: Scalars['Boolean'];
  isScheduledExtTable: Scalars['Boolean'];
  lastSyncedAt?: Maybe<Scalars['Time']>;
  linkedAccountId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  source: Scalars['String'];
};

export type IntegrationQueryContent = {
  __typename?: 'IntegrationQueryContent';
  rawQuery?: Maybe<Scalars['String']>;
  /** @deprecated We've expanded past only supporting SQL. Now, we deserialize the query from the rawQuery, based on the Integration Source */
  sqlQuery?: Maybe<Scalars['String']>;
};

export type IntegrationQueryContentInput = {
  rawQuery?: InputMaybe<Scalars['String']>;
  /** @deprecated We've expanded past only supporting SQL. Now, we deserialize the query from the rawQuery, based on the Integration Source */
  sqlQuery?: InputMaybe<Scalars['String']>;
};

export type IntegrationQueryCreateData = {
  __typename?: 'IntegrationQueryCreateData';
  content: IntegrationQueryContent;
  createdAt: Scalars['Time'];
  enableExtTable?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  linkedAccountId: Scalars['String'];
  name: Scalars['String'];
  source: Scalars['String'];
};

export type IntegrationQueryCreateInput = {
  content: IntegrationQueryContentInput;
  createdAt: Scalars['Time'];
  enableExtTable?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  linkedAccountId: Scalars['String'];
  name: Scalars['String'];
  source: Scalars['String'];
};

export type IntegrationQueryDeleteData = {
  __typename?: 'IntegrationQueryDeleteData';
  id: Scalars['ID'];
};

export type IntegrationQueryDeleteInput = {
  id: Scalars['ID'];
};

export type IntegrationQueryRun = {
  __typename?: 'IntegrationQueryRun';
  error?: Maybe<IntegrationQueryRunError>;
  id: Scalars['ID'];
  queryId: Scalars['String'];
  result?: Maybe<IntegrationQueryRunResult>;
  startedAt?: Maybe<Scalars['Time']>;
  status: IntegrationQueryRunStatus;
};

export type IntegrationQueryRunError = {
  __typename?: 'IntegrationQueryRunError';
  code: IntegrationQueryRunErrorCode;
  message: Scalars['String'];
};

export enum IntegrationQueryRunErrorCode {
  BadSqlQuery = 'BAD_SQL_QUERY',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  OverRowLimit = 'OVER_ROW_LIMIT',
  QueryTransformationFailure = 'QUERY_TRANSFORMATION_FAILURE',
  Unknown = 'UNKNOWN',
}

export type IntegrationQueryRunResult = {
  __typename?: 'IntegrationQueryRunResult';
  sqlQueryResult?: Maybe<SqlQueryResult>;
};

export enum IntegrationQueryRunStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  NotStarted = 'NOT_STARTED',
  Running = 'RUNNING',
}

export type IntegrationQueryUpdateData = {
  __typename?: 'IntegrationQueryUpdateData';
  content?: Maybe<IntegrationQueryContent>;
  enableExtTable?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isScheduled?: Maybe<Scalars['Boolean']>;
  isScheduledExtTable?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['Time']>;
  linkedAccountId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IntegrationQueryUpdateInput = {
  content?: InputMaybe<IntegrationQueryContentInput>;
  enableExtTable?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isScheduled?: InputMaybe<Scalars['Boolean']>;
  isScheduledExtTable?: InputMaybe<Scalars['Boolean']>;
  lastSyncedAt?: InputMaybe<Scalars['Time']>;
  linkedAccountId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export enum IntegrationStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE',
  RelinkNeeded = 'RELINK_NEEDED',
  Unknown = 'UNKNOWN',
}

export type Integrations = {
  __typename?: 'Integrations';
  availableIntegrations: Array<Integration>;
  fivetran?: Maybe<FiveTran>;
  linkedAccounts: Array<LinkedAccount>;
  merge?: Maybe<Merge>;
  metadata?: Maybe<IntegrationMetadata>;
  syncStatus?: Maybe<SyncStatus>;
};

export type IntegrationsAvailableIntegrationsArgs = {
  orgId: Scalars['ID'];
};

export type IntegrationsLinkedAccountsArgs = {
  orgId: Scalars['ID'];
};

export type IntegrationsMetadataArgs = {
  orgId: Scalars['ID'];
};

export type IntegrationsSyncStatusArgs = {
  linkedAccountId: Scalars['String'];
  orgId: Scalars['ID'];
  provider: IntegrationProvider;
};

export type InviteResourceInput = {
  accessResource: AccessResourceInput;
  accessRule: AccessRule;
  email: Scalars['String'];
};

export type InviteRoleInput = {
  email: Scalars['String'];
  role: OrgRole;
};

export enum ItemType {
  Driver = 'DRIVER',
  Event = 'EVENT',
  EventGroup = 'EVENT_GROUP',
}

export type Layer = {
  __typename?: 'Layer';
  createdAt: Scalars['Time'];
  createdByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDraft: Scalars['Boolean'];
  lockedMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentLayerId?: Maybe<Scalars['String']>;
  scopedMutations: Array<DatasetMutationData>;
};

export type LayerCreateData = {
  __typename?: 'LayerCreateData';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDraft: Scalars['Boolean'];
  name: Scalars['String'];
  parentLayerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type LayerCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isDraft: Scalars['Boolean'];
  name: Scalars['String'];
  parentLayerId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type LayerDeleteData = {
  __typename?: 'LayerDeleteData';
  layerId: Scalars['ID'];
};

export type LayerDeleteInput = {
  layerId: Scalars['ID'];
};

export type LayerUpdateData = {
  __typename?: 'LayerUpdateData';
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  layerId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type LayerUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  layerId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type LegendContainer = {
  __typename?: 'LegendContainer';
  maxHeight?: Maybe<Scalars['Int']>;
  maxWidth?: Maybe<Scalars['Int']>;
};

export type LegendContainerInput = {
  maxHeight?: InputMaybe<Scalars['Int']>;
  maxWidth?: InputMaybe<Scalars['Int']>;
};

export type LegendItem = {
  __typename?: 'LegendItem';
  maxWidth?: Maybe<Scalars['Int']>;
  paddingX?: Maybe<Scalars['Int']>;
  paddingY?: Maybe<Scalars['Int']>;
};

export type LegendItemInput = {
  maxWidth?: InputMaybe<Scalars['Int']>;
  paddingX?: InputMaybe<Scalars['Int']>;
  paddingY?: InputMaybe<Scalars['Int']>;
};

export enum LeverType {
  Assumption = 'ASSUMPTION',
  Kpi = 'KPI',
  MessyMath = 'MESSY_MATH',
  Unknown = 'UNKNOWN',
}

export type LinkAccountParams = {
  __typename?: 'LinkAccountParams';
  currencyISOCode?: Maybe<Scalars['String']>;
  fiveTran?: Maybe<FiveTranLinkAccountParams>;
  workato?: Maybe<WorkatoLinkAccountParams>;
};

export type LinkedAccount = {
  __typename?: 'LinkedAccount';
  alias?: Maybe<Scalars['String']>;
  category: IntegrationCategory;
  id: Scalars['String'];
  integration: Integration;
  lastSyncedAt?: Maybe<Scalars['Time']>;
  overrideForecastData: Scalars['Boolean'];
  propagateDataForward: Scalars['Boolean'];
  status: IntegrationStatus;
};

export type Merge = {
  __typename?: 'Merge';
  accountingProperties: AccountingProperties;
};

export type MergeAccountingPropertiesArgs = {
  orgId: Scalars['ID'];
};

export type MergeLinkTokenResponse = {
  __typename?: 'MergeLinkTokenResponse';
  endUserOriginId: Scalars['String'];
  token: Scalars['String'];
};

export type Milestone = Node & {
  __typename?: 'Milestone';
  driver: Driver;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  thresholdDirection: ThresholdDirection;
  time?: Maybe<Scalars['Time']>;
  type: MilestoneType;
  value: Scalars['String'];
};

export type MilestoneCreateData = {
  __typename?: 'MilestoneCreateData';
  driverId: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  thresholdDirection?: Maybe<ThresholdDirection>;
  time?: Maybe<Scalars['Time']>;
  type?: Maybe<MilestoneType>;
  value: Scalars['String'];
};

export type MilestoneCreateInput = {
  driverId: Scalars['String'];
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  thresholdDirection?: InputMaybe<ThresholdDirection>;
  time?: InputMaybe<Scalars['Time']>;
  type?: InputMaybe<MilestoneType>;
  value: Scalars['String'];
};

export type MilestoneDeleteData = {
  __typename?: 'MilestoneDeleteData';
  id: Scalars['ID'];
};

export type MilestoneDeleteInput = {
  id: Scalars['ID'];
};

export enum MilestoneType {
  Value = 'VALUE',
  ValueWithTime = 'VALUE_WITH_TIME',
}

export type MilestoneUpdateData = {
  __typename?: 'MilestoneUpdateData';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  thresholdDirection?: Maybe<ThresholdDirection>;
  time?: Maybe<Scalars['Time']>;
  type?: Maybe<MilestoneType>;
  value?: Maybe<Scalars['String']>;
};

export type MilestoneUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  thresholdDirection?: InputMaybe<ThresholdDirection>;
  time?: InputMaybe<Scalars['Time']>;
  type?: InputMaybe<MilestoneType>;
  value?: InputMaybe<Scalars['String']>;
};

export enum ModifierType {
  Add = 'ADD',
  Mul = 'MUL',
}

export type Mutation = {
  __typename?: 'Mutation';
  accessShareLink?: Maybe<ShareLinkRedirect>;
  addControlEntries: Array<AccessResource>;
  cancelIntegrationQueryRun: BasicResponse;
  completePuzzleLink: BasicResponse;
  createFiveTranConnector: FiveTranCreateConnectorResponse;
  createMergeLinkToken?: Maybe<MergeLinkTokenResponse>;
  createPuzzleLinkToken?: Maybe<PuzzleLinkTokenResponse>;
  createRipplingLinkToken: RipplingLinkTokenResponse;
  createWorkatoLinkToken: WorkatoLinkTokenResponse;
  datasetMutation?: Maybe<DatasetMutationWithLastMutationIdResponse>;
  deleteAllMergeLinkedAccountsForIntegration: BasicResponse;
  deleteFiveTranConnector: BasicResponse;
  deleteMergeLinkedAccount: BasicResponse;
  deleteShareLinks?: Maybe<BasicResponse>;
  deleteWorkatoLinkedAccount: BasicResponse;
  doesShareLinkExist: Scalars['Boolean'];
  enqueueBlockEval: BasicResponse;
  enqueueCalculations: BasicResponse;
  exchangeMergePublicToken: BasicResponse;
  forceResync: BasicResponse;
  generateApiSecret: ApiSecret;
  generateDownloadStorageSignedUrl?: Maybe<DownloadStorageSignedUrlResponse>;
  generateShareLink?: Maybe<ShareLink>;
  generateUploadStorageSignedUrl?: Maybe<UploadStorageSignedUrlResponse>;
  importDatasetForPresetType?: Maybe<BasicResponse>;
  importMutations?: Maybe<BasicResponse>;
  logout?: Maybe<BasicResponse>;
  orgCreate?: Maybe<Org>;
  /** Delete user invitation */
  orgDeleteUserResourceInvite: Array<UserInvite>;
  orgInviteUserToResource: Array<UserInvite>;
  /** Invite a user to an org */
  orgInviteUserToRole: Array<UserInvite>;
  publishScenario: Array<AccessResource>;
  removeControlEntries: Array<AccessResource>;
  removeIntegration: BasicResponse;
  removeInvitedUser?: Maybe<BasicResponse>;
  removeOrgUser?: Maybe<BasicResponse>;
  restoreToMutation?: Maybe<BasicResponse>;
  setLinkedIntegrationMetadata: BasicResponse;
  undo?: Maybe<BasicResponse>;
  unpublishScenario?: Maybe<BasicResponse>;
  updateOrgSettings?: Maybe<BasicResponse>;
  updateUserOrgRole?: Maybe<BasicResponse>;
};

export type MutationAccessShareLinkArgs = {
  nonce: Scalars['String'];
};

export type MutationAddControlEntriesArgs = {
  controlEntries: Array<AddControlEntryInput>;
  orgId: Scalars['ID'];
};

export type MutationCancelIntegrationQueryRunArgs = {
  orgId: Scalars['ID'];
  queryId: Scalars['String'];
  runId: Scalars['String'];
};

export type MutationCompletePuzzleLinkArgs = {
  orgId: Scalars['ID'];
};

export type MutationCreateFiveTranConnectorArgs = {
  orgId: Scalars['ID'];
  schema?: InputMaybe<Scalars['String']>;
  schemaPrefix?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  table?: InputMaybe<Scalars['String']>;
};

export type MutationCreateMergeLinkTokenArgs = {
  category: IntegrationCategory;
  integrationSlug: Scalars['String'];
  linkedAccountId?: InputMaybe<Scalars['String']>;
  orgId: Scalars['ID'];
};

export type MutationCreatePuzzleLinkTokenArgs = {
  orgId: Scalars['ID'];
};

export type MutationCreateRipplingLinkTokenArgs = {
  authCode: Scalars['String'];
  orgId: Scalars['ID'];
};

export type MutationCreateWorkatoLinkTokenArgs = {
  orgId: Scalars['ID'];
};

export type MutationDatasetMutationArgs = {
  layerId?: InputMaybe<Scalars['ID']>;
  mutation: DatasetMutationInput;
  mutationId: Scalars['ID'];
  orgId: Scalars['ID'];
  prevMutationId?: InputMaybe<Scalars['ID']>;
};

export type MutationDeleteAllMergeLinkedAccountsForIntegrationArgs = {
  category: IntegrationCategory;
  integrationSlug: Scalars['String'];
  orgId: Scalars['ID'];
};

export type MutationDeleteFiveTranConnectorArgs = {
  connectorId: Scalars['String'];
  orgId: Scalars['ID'];
};

export type MutationDeleteMergeLinkedAccountArgs = {
  endUserOriginId: Scalars['String'];
  orgId: Scalars['ID'];
};

export type MutationDeleteShareLinksArgs = {
  orgId: Scalars['ID'];
  pageId: Scalars['String'];
};

export type MutationDeleteWorkatoLinkedAccountArgs = {
  linkedAccountId: Scalars['String'];
  orgId: Scalars['ID'];
};

export type MutationDoesShareLinkExistArgs = {
  nonce: Scalars['String'];
};

export type MutationEnqueueBlockEvalArgs = {
  clientId: Scalars['String'];
  orgId: Scalars['ID'];
  request: BlockEvalRequest;
};

export type MutationEnqueueCalculationsArgs = {
  clientId: Scalars['String'];
  orgId: Scalars['ID'];
  requestBatch: CalculationRequestBatch;
};

export type MutationExchangeMergePublicTokenArgs = {
  endUserOriginId: Scalars['String'];
  orgId: Scalars['ID'];
  publicToken: Scalars['String'];
};

export type MutationForceResyncArgs = {
  linkedAccountId: Scalars['String'];
  orgId: Scalars['ID'];
  provider: IntegrationProvider;
};

export type MutationGenerateApiSecretArgs = {
  orgId: Scalars['ID'];
};

export type MutationGenerateDownloadStorageSignedUrlArgs = {
  filePath: Scalars['String'];
  orgId: Scalars['ID'];
};

export type MutationGenerateShareLinkArgs = {
  layerIds?: InputMaybe<Array<Scalars['String']>>;
  orgId: Scalars['ID'];
  pageId: Scalars['String'];
};

export type MutationGenerateUploadStorageSignedUrlArgs = {
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  orgId: Scalars['ID'];
  pageId: Scalars['String'];
};

export type MutationImportDatasetForPresetTypeArgs = {
  datasetPresetType: DatasetPresetType;
  orgId: Scalars['ID'];
};

export type MutationImportMutationsArgs = {
  append: Scalars['Boolean'];
  exportedMutations?: InputMaybe<Scalars['String']>;
  orgId: Scalars['ID'];
  signedUrl?: InputMaybe<Scalars['String']>;
};

export type MutationOrgCreateArgs = {
  isDemoOrg?: InputMaybe<Scalars['Boolean']>;
  orgName: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
};

export type MutationOrgDeleteUserResourceInviteArgs = {
  accessResource: AccessResourceInput;
  email: Scalars['String'];
  orgId: Scalars['ID'];
};

export type MutationOrgInviteUserToResourceArgs = {
  invites: Array<InviteResourceInput>;
  orgId: Scalars['ID'];
};

export type MutationOrgInviteUserToRoleArgs = {
  invites: Array<InviteRoleInput>;
  orgId: Scalars['ID'];
};

export type MutationPublishScenarioArgs = {
  layerId: Scalars['String'];
  orgId: Scalars['ID'];
};

export type MutationRemoveControlEntriesArgs = {
  controlEntries: Array<RemoveControlEntryInput>;
  orgId: Scalars['ID'];
};

export type MutationRemoveIntegrationArgs = {
  linkedAccountId: Scalars['String'];
  orgId: Scalars['ID'];
  provider: IntegrationProvider;
};

export type MutationRemoveInvitedUserArgs = {
  email: Scalars['String'];
  orgId: Scalars['ID'];
};

export type MutationRemoveOrgUserArgs = {
  orgId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationRestoreToMutationArgs = {
  orgId: Scalars['ID'];
  restoreToMutationId: Scalars['ID'];
};

export type MutationSetLinkedIntegrationMetadataArgs = {
  linkedAccountId: Scalars['String'];
  orgId: Scalars['ID'];
  overrideForecastData: Scalars['Boolean'];
  propagateDataForward: Scalars['Boolean'];
  provider: IntegrationProvider;
};

export type MutationUndoArgs = {
  mutationId: Scalars['ID'];
  orgId: Scalars['ID'];
  prevMutationId: Scalars['ID'];
  undoMutationId: Scalars['ID'];
  undoMutationInput?: InputMaybe<UndoMutationInput>;
};

export type MutationUnpublishScenarioArgs = {
  layerId: Scalars['String'];
  orgId: Scalars['ID'];
};

export type MutationUpdateOrgSettingsArgs = {
  orgId: Scalars['ID'];
  update: GeneralSettingsUpdateInput;
};

export type MutationUpdateUserOrgRoleArgs = {
  orgId: Scalars['ID'];
  role: OrgRole;
  userId: Scalars['ID'];
};

export type MutationError = {
  message: Scalars['String'];
};

export type NamedDatasetVersion = {
  __typename?: 'NamedDatasetVersion';
  mutationId: Scalars['ID'];
  name: Scalars['String'];
};

export type NamedDatasetVersionCreateData = {
  __typename?: 'NamedDatasetVersionCreateData';
  mutationId: Scalars['ID'];
  name: Scalars['String'];
};

export type NamedDatasetVersionCreateInput = {
  mutationId: Scalars['ID'];
  name: Scalars['String'];
};

export type NamedDatasetVersionDeleteData = {
  __typename?: 'NamedDatasetVersionDeleteData';
  mutationId: Scalars['ID'];
};

export type NamedDatasetVersionDeleteInput = {
  mutationId: Scalars['ID'];
};

export type NamedDatasetVersionUpdateData = {
  __typename?: 'NamedDatasetVersionUpdateData';
  mutationId: Scalars['ID'];
  name: Scalars['String'];
};

export type NamedDatasetVersionUpdateInput = {
  mutationId: Scalars['ID'];
  name: Scalars['String'];
};

export enum NegativeDisplay {
  NegativeSign = 'NEGATIVE_SIGN',
  Parentheses = 'PARENTHESES',
}

export type Node = {
  id: Scalars['ID'];
};

export type NullableTimeSeriesPoint = {
  __typename?: 'NullableTimeSeriesPoint';
  time: Scalars['Time'];
  value?: Maybe<Scalars['String']>;
};

export type ObjectFieldEvaluation = {
  __typename?: 'ObjectFieldEvaluation';
  evaluation: Array<CalculationValue>;
  fieldSpecId?: Maybe<Scalars['String']>;
  objectId: Scalars['String'];
  subDriverId?: Maybe<Scalars['String']>;
};

export type ObjectFieldGroupByExtension = {
  __typename?: 'ObjectFieldGroupByExtension';
  businessObjectFieldId: Scalars['String'];
  collapsedAttributeIds?: Maybe<Array<Scalars['String']>>;
};

export type ObjectFieldGroupByExtensionInput = {
  businessObjectFieldId: Scalars['String'];
  collapsedAttributeIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ObjectSortExtension = {
  __typename?: 'ObjectSortExtension';
  ops: Array<ObjectSortOp>;
};

export type ObjectSortExtensionInput = {
  ops: Array<ObjectSortOpInput>;
};

export type ObjectSortOp = {
  __typename?: 'ObjectSortOp';
  manualOrdering?: Maybe<Array<Scalars['String']>>;
  order: ObjectSortOrder;
  propertyId: Scalars['String'];
};

export type ObjectSortOpInput = {
  manualOrdering?: InputMaybe<Array<Scalars['String']>>;
  order: ObjectSortOrder;
  propertyId: Scalars['String'];
};

export enum ObjectSortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
  Manual = 'MANUAL',
}

export enum ObjectSpecDisplayAsType {
  Database = 'DATABASE',
  Timeseries = 'TIMESERIES',
}

export type ObjectSpecEvaluation = {
  __typename?: 'ObjectSpecEvaluation';
  evaluation: CalculationValue;
  fieldEvaluations: Array<ObjectFieldEvaluation>;
  specId: Scalars['String'];
};

export type Org = {
  __typename?: 'Org';
  apiSecret?: Maybe<ApiSecret>;
  createdAt: Scalars['Time'];
  dataset: Dataset;
  generalSettings: GeneralSettings;
  hasSuperPermission?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  importStartTime?: Maybe<Scalars['Time']>;
  invitedUsers?: Maybe<Array<UserInvite>>;
  isActive: Scalars['Boolean'];
  isTemplateOrg: Scalars['Boolean'];
  myOrgRole: OrgRole;
  name: Scalars['String'];
  shareLinks: Array<ShareLink>;
  slug: Scalars['String'];
  ssoEnabled: Scalars['Boolean'];
  ssoExternalOrgId: Scalars['String'];
  stripeCustomerId?: Maybe<Scalars['String']>;
  subscriptionType?: Maybe<OrgSubscriptionType>;
  /** @deprecated No longer used for customer trials */
  trialEndAt?: Maybe<Scalars['Time']>;
  users?: Maybe<Array<OrgUser>>;
};

export enum OrgRole {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  Manager = 'MANAGER',
  Member = 'MEMBER',
  Owner = 'OWNER',
}

export enum OrgSubscriptionType {
  Internal = 'INTERNAL',
  Paid = 'PAID',
  /** @deprecated No longer used for customer trials */
  Trial = 'TRIAL',
}

export type OrgUser = {
  __typename?: 'OrgUser';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  orgRole: OrgRole;
  profileImage?: Maybe<Scalars['String']>;
};

export type PuzzleLinkTokenResponse = {
  __typename?: 'PuzzleLinkTokenResponse';
  token: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  accessResources: Array<AccessResource>;
  aggregatedTransactionsTimeSeries: Array<TimeSeriesPoint>;
  aiGroupDrivers: AiDriverGroupResponse;
  aiGroupEvents: AiEventGroupResponse;
  calculatorStatus: CalculatorStatus;
  dataset?: Maybe<Dataset>;
  datasetMutationHistory: Array<DatasetMutationData>;
  exportMutations: BasicResponse;
  getAccountingTransactions: Array<AccountingTransaction>;
  integrations?: Maybe<Integrations>;
  me?: Maybe<User>;
  org?: Maybe<Org>;
  snapshotForExtTables: Array<ExtTableSnapshotEntry>;
  templateOrgs: Array<TemplateOrg>;
  veltToken?: Maybe<Scalars['String']>;
};

export type QueryAccessResourcesArgs = {
  orgId: Scalars['ID'];
};

export type QueryAggregatedTransactionsTimeSeriesArgs = {
  accountId?: InputMaybe<Scalars['String']>;
  input: AggregatedTransactionsTimeSeriesInput;
  orgId: Scalars['String'];
};

export type QueryAiGroupDriversArgs = {
  input: AiGroupDriversInput;
};

export type QueryAiGroupEventsArgs = {
  input: AiGroupEventsInput;
};

export type QueryCalculatorStatusArgs = {
  orgId: Scalars['ID'];
};

export type QueryDatasetArgs = {
  layerId?: InputMaybe<Scalars['ID']>;
  mutationId?: InputMaybe<Scalars['ID']>;
  orgId: Scalars['String'];
};

export type QueryDatasetMutationHistoryArgs = {
  orgId: Scalars['String'];
  query: DatasetMutationHistoryInput;
};

export type QueryExportMutationsArgs = {
  orgId: Scalars['String'];
  query: ExportMutationInput;
};

export type QueryGetAccountingTransactionsArgs = {
  accountId?: InputMaybe<Scalars['String']>;
  input: GetAccountingTransactionsInput;
  orgId: Scalars['String'];
};

export type QueryOrgArgs = {
  orgId: Scalars['ID'];
};

export type QuerySnapshotForExtTablesArgs = {
  orgId: Scalars['String'];
  sourceKeys: Array<Scalars['String']>;
};

export type QueryVeltTokenArgs = {
  orgId: Scalars['String'];
};

export type RemoveControlEntryInput = {
  accessor: AccessorEntityInput;
  parentId?: InputMaybe<Scalars['ID']>;
  resourceId: Scalars['ID'];
  resourceType: AccessResourceType;
};

export type RipplingLinkTokenResponse = {
  __typename?: 'RipplingLinkTokenResponse';
  redirectURL: Scalars['String'];
};

export type Rollup = {
  __typename?: 'Rollup';
  reducer: RollupReducer;
};

export type RollupInput = {
  reducer?: InputMaybe<RollupReducer>;
};

export enum RollupReducer {
  Avg = 'AVG',
  First = 'FIRST',
  Last = 'LAST',
  Sum = 'SUM',
}

export enum RollupSortType {
  Chronological = 'CHRONOLOGICAL',
  Manual = 'MANUAL',
}

export enum RollupType {
  Annual = 'ANNUAL',
  CurrentMonth = 'CURRENT_MONTH',
  HalfYearToDate = 'HALF_YEAR_TO_DATE',
  HalfYearToLastClose = 'HALF_YEAR_TO_LAST_CLOSE',
  LastClose = 'LAST_CLOSE',
  Month = 'MONTH',
  MonthlyAnnually = 'MONTHLY_ANNUALLY',
  MonthlyQuarterly = 'MONTHLY_QUARTERLY',
  MonthlyQuarterlyAnnually = 'MONTHLY_QUARTERLY_ANNUALLY',
  Quarter = 'QUARTER',
  QuarterToDate = 'QUARTER_TO_DATE',
  QuarterToLastClose = 'QUARTER_TO_LAST_CLOSE',
  Trailing_3Months = 'TRAILING_3_MONTHS',
  Trailing_6Months = 'TRAILING_6_MONTHS',
  Trailing_12Months = 'TRAILING_12_MONTHS',
  YearToDate = 'YEAR_TO_DATE',
  YearToLastClose = 'YEAR_TO_LAST_CLOSE',
}

export type SqlColumnValueTuple = {
  __typename?: 'SQLColumnValueTuple';
  column: Scalars['String'];
  value: Scalars['String'];
};

export type SqlColumnValueTupleInput = {
  column: Scalars['String'];
  value: Scalars['String'];
};

export type ShareLink = {
  __typename?: 'ShareLink';
  id: Scalars['ID'];
  layerIds?: Maybe<Array<Scalars['String']>>;
  nonce: Scalars['String'];
  pageId: Scalars['String'];
};

export type ShareLinkRedirect = {
  __typename?: 'ShareLinkRedirect';
  layerIds?: Maybe<Array<Scalars['String']>>;
  orgSlug: Scalars['String'];
  pageId: Scalars['String'];
};

export type SqlQueryResult = {
  __typename?: 'SqlQueryResult';
  rows: Array<Maybe<SqlQueryResultRow>>;
};

export type SqlQueryResultInput = {
  rows: Array<InputMaybe<SqlQueryResultRowInput>>;
};

export type SqlQueryResultRow = {
  __typename?: 'SqlQueryResultRow';
  columnValues: Array<SqlColumnValueTuple>;
};

export type SqlQueryResultRowInput = {
  columnValues: Array<SqlColumnValueTupleInput>;
};

export type Submodel = {
  __typename?: 'Submodel';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Submodel name is not longer saved to the submodel, use the attached page instead */
  name?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
  sortedDriverGroupIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type SubmodelCreateData = {
  __typename?: 'SubmodelCreateData';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Submodel name is not longer saved to the submodel, use the attached page instead */
  name?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
  sortedDriverGroupIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type SubmodelCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Submodel name is not longer saved to the submodel, use the attached page instead */
  name?: InputMaybe<Scalars['String']>;
  sortIndex?: InputMaybe<Scalars['Int']>;
  sortedDriverGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type SubmodelDeleteData = {
  __typename?: 'SubmodelDeleteData';
  /** @deprecated Determine submodelIds corresponding to blockIds in driverReferences instead */
  deleteDrivers?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type SubmodelDeleteInput = {
  /** @deprecated Determine submodelIds corresponding to blockIds in driverReferences instead */
  deleteDrivers?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type SubmodelUpdateData = {
  __typename?: 'SubmodelUpdateData';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Submodel name is not longer saved to the submodel, use the attached page instead */
  name?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
  sortedDriverGroupIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type SubmodelUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Submodel name is not longer saved to the submodel, use the attached page instead */
  name?: InputMaybe<Scalars['String']>;
  sortIndex?: InputMaybe<Scalars['Int']>;
  sortedDriverGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  calculationUpdates: CalculationUpdate;
  datasetMutated: DatasetMutationData;
  startIntegrationQueryRun: IntegrationQueryRun;
};

export type SubscriptionCalculationUpdatesArgs = {
  orgId: Scalars['ID'];
};

export type SubscriptionDatasetMutatedArgs = {
  clientInfo?: InputMaybe<ClientInfo>;
  lastMutationId: Scalars['String'];
  orgId: Scalars['ID'];
};

export type SubscriptionStartIntegrationQueryRunArgs = {
  orgId: Scalars['ID'];
  queryId: Scalars['String'];
  runId: Scalars['String'];
};

export type SyncStatus = SyncStatusError | SyncStatusResult;

export type SyncStatusError = {
  __typename?: 'SyncStatusError';
  error: Scalars['String'];
};

export type SyncStatusResult = {
  __typename?: 'SyncStatusResult';
  lastRemoteSyncTime?: Maybe<Scalars['Time']>;
  lastSyncStartedTime?: Maybe<Scalars['Time']>;
  lastSyncTime?: Maybe<Scalars['Time']>;
  status: SyncStatusValue;
};

export enum SyncStatusValue {
  Done = 'DONE',
  Syncing = 'SYNCING',
  SyncPending = 'SYNC_PENDING',
}

export type TemplateOrg = {
  __typename?: 'TemplateOrg';
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  orgId: Scalars['String'];
  slug: Scalars['String'];
};

export enum ThresholdDirection {
  AboveOrEqual = 'ABOVE_OR_EQUAL',
  BelowOrEqual = 'BELOW_OR_EQUAL',
}

export type TimeAxisExtension = {
  __typename?: 'TimeAxisExtension';
  plotBy: ChartPlotByType;
  rollup: ChartRollupType;
};

export type TimeAxisExtensionInput = {
  plotBy: ChartPlotByType;
  rollup: ChartRollupType;
};

export type TimeColumn = {
  __typename?: 'TimeColumn';
  extTableColumnId: Scalars['String'];
};

export type TimeColumnInput = {
  extTableColumnId: Scalars['String'];
};

export type TimePeriod = {
  __typename?: 'TimePeriod';
  dynamicDateType?: Maybe<DynamicDate>;
  time?: Maybe<Scalars['LooseTime']>;
};

export type TimePeriodInput = {
  dynamicDateType?: InputMaybe<DynamicDate>;
  time?: InputMaybe<Scalars['LooseTime']>;
};

export type TimeSeriesPoint = {
  __typename?: 'TimeSeriesPoint';
  time: Scalars['Time'];
  value: Scalars['String'];
};

export type TimeSeriesPointInput = {
  time: Scalars['Time'];
  value?: InputMaybe<Scalars['String']>;
};

export type TrackParentLayerFields = {
  __typename?: 'TrackParentLayerFields';
  actualsFormula?: Maybe<Scalars['Boolean']>;
  forecastFormula?: Maybe<Scalars['Boolean']>;
};

export enum TransactionField {
  AccountId = 'AccountId',
  Amount = 'Amount',
  CategoryId = 'CategoryId',
  ContactId = 'ContactId',
  Memo = 'Memo',
}

export type TransactionFieldValue = {
  __typename?: 'TransactionFieldValue';
  stringValue?: Maybe<Scalars['String']>;
  type: TransactionFieldValueType;
};

export type TransactionFieldValueInput = {
  stringValue?: InputMaybe<Scalars['String']>;
  type: TransactionFieldValueType;
};

export enum TransactionFieldValueType {
  NullValue = 'NullValue',
  StringValue = 'StringValue',
}

export enum TransactionFilterComparisonOperator {
  Eq = 'EQ',
  Gt = 'GT',
  Gte = 'GTE',
  Lt = 'LT',
  Lte = 'LTE',
  Neq = 'NEQ',
}

export type TransactionFilterComparisonPredicate = {
  __typename?: 'TransactionFilterComparisonPredicate';
  field: TransactionField;
  operator: TransactionFilterComparisonOperator;
  value: TransactionFieldValue;
};

export type TransactionFilterComparisonPredicateInput = {
  field: TransactionField;
  operator: TransactionFilterComparisonOperator;
  value: TransactionFieldValueInput;
};

export enum TransactionFilterMultiSelectOperator {
  In = 'IN',
  NotIn = 'NOT_IN',
}

export type TransactionFilterMultiSelectPredicate = {
  __typename?: 'TransactionFilterMultiSelectPredicate';
  field: TransactionField;
  operator: TransactionFilterMultiSelectOperator;
  values: Array<TransactionFieldValue>;
};

export type TransactionFilterMultiSelectPredicateInput = {
  field: TransactionField;
  operator: TransactionFilterMultiSelectOperator;
  values: Array<TransactionFieldValueInput>;
};

export type TransactionFilterPredicate = {
  __typename?: 'TransactionFilterPredicate';
  comparison?: Maybe<TransactionFilterComparisonPredicate>;
  multiSelect?: Maybe<TransactionFilterMultiSelectPredicate>;
  type: TransactionFilterPredicateType;
};

export type TransactionFilterPredicateInput = {
  comparison?: InputMaybe<TransactionFilterComparisonPredicateInput>;
  multiSelect?: InputMaybe<TransactionFilterMultiSelectPredicateInput>;
  type: TransactionFilterPredicateType;
};

export enum TransactionFilterPredicateType {
  Comparison = 'Comparison',
  MultiSelect = 'MultiSelect',
}

export type TransactionFilterQuery = {
  __typename?: 'TransactionFilterQuery';
  predicates: Array<TransactionFilterPredicate>;
};

export type TransactionFilterQueryInput = {
  predicates: Array<TransactionFilterPredicateInput>;
};

export type UnableToApplyMutationError = MutationError & {
  __typename?: 'UnableToApplyMutationError';
  message: Scalars['String'];
  ops: Array<Scalars['String']>;
};

export type UndoMutationInput = {
  changeId: Scalars['String'];
  shouldUndo: Scalars['Boolean'];
};

export type UploadStorageSignedUrlResponse = {
  __typename?: 'UploadStorageSignedUrlResponse';
  fileId: Scalars['String'];
  filePath: Scalars['String'];
  signedUrl: Scalars['String'];
};

export type User = Node & {
  __typename?: 'User';
  authenticatedUser?: Maybe<AuthenticatedUser>;
  createdAt: Scalars['Time'];
  email: Scalars['String'];
  hasSuperPermission: Scalars['Boolean'];
  id: Scalars['ID'];
  intercomHash?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orgs?: Maybe<Array<Org>>;
  profileImage?: Maybe<Scalars['String']>;
};

export type UserOrgsArgs = {
  orgSlug?: InputMaybe<Scalars['String']>;
};

export type UserInvite = {
  __typename?: 'UserInvite';
  accessResource?: Maybe<AccessResource>;
  accessRule?: Maybe<AccessRule>;
  createdAt: Scalars['Time'];
  email: Scalars['String'];
  invitedByUser?: Maybe<User>;
  role?: Maybe<OrgRole>;
};

/** ValueType represents the range of primitive types that can exist in a time series. */
export enum ValueType {
  Attribute = 'ATTRIBUTE',
  Boolean = 'BOOLEAN',
  Number = 'NUMBER',
  Timestamp = 'TIMESTAMP',
}

export type WorkatoLinkAccountParams = {
  __typename?: 'WorkatoLinkAccountParams';
  connectionId: Scalars['String'];
};

export type WorkatoLinkTokenResponse = {
  __typename?: 'WorkatoLinkTokenResponse';
  token: Scalars['String'];
};

export type AggregatedTransactionsTimeSeriesQueryVariables = Exact<{
  orgId: Scalars['String'];
  accountId?: InputMaybe<Scalars['String']>;
  input: AggregatedTransactionsTimeSeriesInput;
}>;

export type AggregatedTransactionsTimeSeriesQuery = {
  __typename?: 'Query';
  aggregatedTransactionsTimeSeries: Array<{
    __typename?: 'TimeSeriesPoint';
    time: string;
    value: string;
  }>;
};

export type GetAccountingTransactionsQueryVariables = Exact<{
  orgId: Scalars['String'];
  accountId?: InputMaybe<Scalars['String']>;
  input: GetAccountingTransactionsInput;
}>;

export type GetAccountingTransactionsQuery = {
  __typename?: 'Query';
  getAccountingTransactions: Array<{
    __typename?: 'AccountingTransaction';
    remoteId: string;
    amount: string;
    date: string;
    memo?: string | null;
    accountRemoteId: string;
    contactRemoteId?: string | null;
    trackingCategoryRemoteIds?: Array<string> | null;
    linkedAccountId: string;
  }>;
};

export type AiGroupDriversQueryVariables = Exact<{
  input: AiGroupDriversInput;
}>;

export type AiGroupDriversQuery = {
  __typename?: 'Query';
  aiGroupDrivers: {
    __typename: 'AIDriverGroupResponse';
    cursor: string;
    groups: Array<{
      __typename?: 'AIDriverGroup';
      name: string;
      drivers: Array<{ __typename?: 'AIDriver'; id: string; name: string }>;
    }>;
  };
};

export type AiGroupEventsQueryVariables = Exact<{
  input: AiGroupEventsInput;
}>;

export type AiGroupEventsQuery = {
  __typename?: 'Query';
  aiGroupEvents: {
    __typename: 'AIEventGroupResponse';
    cursor: string;
    groups: Array<{
      __typename?: 'AIEventGroup';
      name: string;
      events: Array<{ __typename?: 'AIEvent'; id: string }>;
    }>;
  };
};

export type ApiSecretQueryVariables = Exact<{
  orgSlug?: InputMaybe<Scalars['String']>;
}>;

export type ApiSecretQuery = {
  __typename?: 'Query';
  me?: {
    __typename: 'User';
    orgs?: Array<{
      __typename?: 'Org';
      apiSecret?: { __typename: 'ApiSecret'; apiSecret: string } | null;
    }> | null;
  } | null;
};

export type GenerateApiSecretMutationVariables = Exact<{
  orgId: Scalars['ID'];
}>;

export type GenerateApiSecretMutation = {
  __typename?: 'Mutation';
  generateApiSecret: { __typename?: 'ApiSecret'; apiSecret: string };
};

export type GenerateUploadStorageSignedUrlMutationVariables = Exact<{
  orgId: Scalars['ID'];
  pageId: Scalars['String'];
  fileName: Scalars['String'];
  fileType: Scalars['String'];
}>;

export type GenerateUploadStorageSignedUrlMutation = {
  __typename?: 'Mutation';
  generateUploadStorageSignedUrl?: {
    __typename?: 'UploadStorageSignedUrlResponse';
    signedUrl: string;
    fileId: string;
    filePath: string;
  } | null;
};

export type GenerateDownloadStorageSignedUrlMutationVariables = Exact<{
  orgId: Scalars['ID'];
  filePath: Scalars['String'];
}>;

export type GenerateDownloadStorageSignedUrlMutation = {
  __typename?: 'Mutation';
  generateDownloadStorageSignedUrl?: {
    __typename?: 'DownloadStorageSignedUrlResponse';
    signedUrl: string;
  } | null;
};

export type CalculationUpdatesSubscriptionVariables = Exact<{
  orgId: Scalars['ID'];
}>;

export type CalculationUpdatesSubscription = {
  __typename?: 'Subscription';
  calculationUpdates: {
    __typename?: 'CalculationUpdate';
    clientId: string;
    type: CalculationUpdateType;
    calculationResults?: Array<{
      __typename?: 'CalculationResult';
      requestId: string;
      layerId: string;
      entityId: string;
      entityType: FormulaEntityType;
      date: string;
      ignoreEventIDs?: Array<string> | null;
      value?: {
        __typename?: 'CalculationValue';
        type: ValueType;
        number?: number | null;
        attributeId?: string | null;
        timestamp?: string | null;
      } | null;
      objectSpecEvaluations?: Array<{
        __typename?: 'ObjectSpecEvaluation';
        specId: string;
        evaluation: {
          __typename?: 'CalculationValue';
          type: ValueType;
          number?: number | null;
          attributeId?: string | null;
          timestamp?: string | null;
        };
        fieldEvaluations: Array<{
          __typename?: 'ObjectFieldEvaluation';
          objectId: string;
          fieldSpecId?: string | null;
          subDriverId?: string | null;
          evaluation: Array<{
            __typename?: 'CalculationValue';
            type: ValueType;
            number?: number | null;
            attributeId?: string | null;
            timestamp?: string | null;
          }>;
        }>;
      }> | null;
      error?: {
        __typename?: 'CalculationError';
        type: CalculationErrorType;
        originEntity?: {
          __typename?: 'ErrorOriginEntity';
          id: string;
          type: FormulaEntityType;
        } | null;
      } | null;
    }> | null;
    blockEvalResults?: Array<{
      __typename?: 'BlockEvalResult';
      requestId: string;
      layerId: string;
      blockId: string;
      cacheKey: string;
      data: Array<{
        __typename?: 'BlockEvalGroup';
        ids: Array<string>;
        meta: {
          __typename?: 'BlockEvalGroupMeta';
          key: string;
          attributeId: string;
          dimensionId: string;
          fieldSpecId: string;
        };
      }>;
    }> | null;
  };
};

export type EnqueueCalculationsMutationVariables = Exact<{
  orgId: Scalars['ID'];
  clientId: Scalars['String'];
  requestBatch: CalculationRequestBatch;
}>;

export type EnqueueCalculationsMutation = {
  __typename?: 'Mutation';
  enqueueCalculations: { __typename?: 'BasicResponse'; success: boolean };
};

export type EnqueueBlockEvalMutationVariables = Exact<{
  orgId: Scalars['ID'];
  clientId: Scalars['String'];
  request: BlockEvalRequest;
}>;

export type EnqueueBlockEvalMutation = {
  __typename?: 'Mutation';
  enqueueBlockEval: { __typename?: 'BasicResponse'; success: boolean };
};

export type CalculatorStatusQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;

export type CalculatorStatusQuery = { __typename?: 'Query'; calculatorStatus: CalculatorStatus };

export type ExportMutationsQueryVariables = Exact<{
  orgId: Scalars['String'];
  query: ExportMutationInput;
}>;

export type ExportMutationsQuery = {
  __typename?: 'Query';
  exportMutations: { __typename?: 'BasicResponse'; success: boolean };
};

export type ImportMutationsMutationVariables = Exact<{
  orgId: Scalars['ID'];
  append: Scalars['Boolean'];
  exportedMutations?: InputMaybe<Scalars['String']>;
  signedUrl?: InputMaybe<Scalars['String']>;
}>;

export type ImportMutationsMutation = {
  __typename?: 'Mutation';
  importMutations?: { __typename?: 'BasicResponse'; success: boolean } | null;
};

export type ImportDatasetForPresetTypeMutationVariables = Exact<{
  orgId: Scalars['ID'];
  datasetPresetType: DatasetPresetType;
}>;

export type ImportDatasetForPresetTypeMutation = {
  __typename?: 'Mutation';
  importDatasetForPresetType?: { __typename?: 'BasicResponse'; success: boolean } | null;
};

export type RestoreToMutationMutationVariables = Exact<{
  orgId: Scalars['ID'];
  mutationId: Scalars['ID'];
}>;

export type RestoreToMutationMutation = {
  __typename?: 'Mutation';
  restoreToMutation?: { __typename?: 'BasicResponse'; success: boolean } | null;
};

export type BlockConfigFragmentFragment = {
  __typename?: 'BlockConfig';
  idFilter?: Array<string> | null;
  textBlockBody?: string | null;
  videoBlockUrl?: string | null;
  imageBlockUrl?: string | null;
  mediaWidth?: number | null;
  blockAlignment?: BlockAlignment | null;
  hideHeader?: boolean | null;
  businessObjectSpecId?: string | null;
  showRestricted?: boolean | null;
  rollupTypes?: Array<RollupType> | null;
  rollupType?: RollupType | null;
  fiscalYearStartMonth?: number | null;
  objectFieldSpecAsTimeSeriesId?: string | null;
  objectSpecDisplayAs?: ObjectSpecDisplayAsType | null;
  expandedIds?: Array<string> | null;
  fileMetadata?: {
    __typename?: 'FileMetadata';
    id: string;
    name: string;
    type: string;
    path: string;
    size: number;
  } | null;
  blockViewOptions?: {
    __typename?: 'BlockViewOptions';
    showMilestones?: boolean | null;
    viewAsType?: BlockViewAsType | null;
    chartSize?: ChartSize | null;
    chartGroupingType?: ChartGroupingType | null;
    aggregateValues?: boolean | null;
    chartDisplay?: {
      __typename?: 'ChartDisplay';
      axes: Array<{
        __typename?: 'ChartAxis';
        id: string;
        position: ChartElementPosition;
        name?: string | null;
        showLabel?: boolean | null;
        type: ChartAxisType;
        time?: {
          __typename?: 'TimeAxisExtension';
          rollup: ChartRollupType;
          plotBy: ChartPlotByType;
        } | null;
        driver?: {
          __typename?: 'DriverAxisExtension';
          name?: string | null;
          groupIds: Array<string>;
          showTotals?: boolean | null;
          isNormalized?: boolean | null;
          format?: {
            __typename?: 'DriverAxisFormat';
            type: DriverFormat;
            decimalPlaces?: number | null;
            currencyISOCode?: string | null;
          } | null;
        } | null;
      }>;
      series: Array<{
        __typename?: 'ChartSeries';
        id: string;
        driverId: string;
        type: ChartSeriesType;
        color?: string | null;
        showLabels?: boolean | null;
      }>;
      groups: Array<{
        __typename?: 'ChartGroup';
        id: string;
        seriesIds: Array<string>;
        isDefault: boolean;
        layerId?: string | null;
        name?: string | null;
        showLabels?: boolean | null;
        dateRange?: { __typename?: 'DateRange'; start?: string | null; end?: string | null } | null;
      }>;
      legend?: {
        __typename?: 'ChartLegend';
        showLegend?: boolean | null;
        position?: ChartElementPosition | null;
        container?: {
          __typename?: 'LegendContainer';
          maxWidth?: number | null;
          maxHeight?: number | null;
        } | null;
        item?: {
          __typename?: 'LegendItem';
          maxWidth?: number | null;
          paddingX?: number | null;
          paddingY?: number | null;
        } | null;
      } | null;
    } | null;
    dateRangeComparison?: {
      __typename?: 'DateRangeComparison';
      type: DateRangeComparisonType;
      selectedPeriod: { __typename?: 'DateRange'; start?: string | null; end?: string | null };
      comparisonPeriod: { __typename?: 'DateRange'; start?: string | null; end?: string | null };
    } | null;
  } | null;
  sortBy?: {
    __typename?: 'BlockSortBy';
    sortType: BlockSortType;
    impact?: {
      __typename?: 'ImpactSortExtension';
      driverId: string;
      startMonthKey: string;
      endMonthKey: string;
    } | null;
    object?: {
      __typename?: 'ObjectSortExtension';
      ops: Array<{
        __typename?: 'ObjectSortOp';
        propertyId: string;
        order: ObjectSortOrder;
        manualOrdering?: Array<string> | null;
      }>;
    } | null;
  } | null;
  filterBy?: Array<{
    __typename?: 'BlockFilter';
    filterType: BlockFilterType;
    businessObjectSpecId?: string | null;
    expression?: string | null;
    filterKey?: string | null;
    filterValues?: Array<string> | null;
    filterOp?: BlockFilterOperator | null;
  }> | null;
  groupBy?: {
    __typename?: 'BlockGroupBy';
    groupByType: BlockGroupByType;
    driverField?: {
      __typename?: 'DriverFieldGroupByExtension';
      driverFieldSpecId: string;
      collapsedAttributeOptionIds?: Array<string> | null;
    } | null;
    objectField?: {
      __typename?: 'ObjectFieldGroupByExtension';
      businessObjectFieldId: string;
      collapsedAttributeIds?: Array<string> | null;
    } | null;
    driverProperty?: {
      __typename?: 'DriverPropertyGroupByExtension';
      driverPropertyId: string;
      collapsedAttributeIds?: Array<string> | null;
    } | null;
    date?: {
      __typename?: 'DateGroupByExtension';
      collapsedMonthKeys?: Array<string> | null;
    } | null;
  } | null;
  driverIndentations?: Array<{
    __typename?: 'DriverIndentation';
    level: number;
    driverId: string;
  }> | null;
  dateRange?: { __typename?: 'DateRange'; start?: string | null; end?: string | null } | null;
  viewAtTime?: {
    __typename?: 'TimePeriod';
    time?: string | null;
    dynamicDateType?: DynamicDate | null;
  } | null;
  comparisons?: {
    __typename?: 'BlockComparisons';
    layerIds: Array<string>;
    columns?: Array<ComparisonColumn> | null;
    layout?: BlockComparisonLayout | null;
    baselineLayerId?: string | null;
  } | null;
  columns?: Array<{
    __typename?: 'BlockColumnOptions';
    key: string;
    width?: number | null;
    visible?: boolean | null;
  }> | null;
  impactDisplay?: { __typename?: 'ImpactDisplay'; displayType: ImpactDisplayType } | null;
};

export type TransactionFieldValueFragmentFragment = {
  __typename?: 'TransactionFieldValue';
  type: TransactionFieldValueType;
  stringValue?: string | null;
};

export type TransactionFilterQueryFragmentFragment = {
  __typename?: 'TransactionFilterQuery';
  predicates: Array<{
    __typename?: 'TransactionFilterPredicate';
    type: TransactionFilterPredicateType;
    multiSelect?: {
      __typename?: 'TransactionFilterMultiSelectPredicate';
      field: TransactionField;
      operator: TransactionFilterMultiSelectOperator;
      values: Array<{
        __typename?: 'TransactionFieldValue';
        type: TransactionFieldValueType;
        stringValue?: string | null;
      }>;
    } | null;
    comparison?: {
      __typename?: 'TransactionFilterComparisonPredicate';
      field: TransactionField;
      operator: TransactionFilterComparisonOperator;
      value: {
        __typename?: 'TransactionFieldValue';
        type: TransactionFieldValueType;
        stringValue?: string | null;
      };
    } | null;
  }>;
};

export type DatasetMutationDataFragmentFragment = {
  __typename?: 'DatasetMutationData';
  id: string;
  layerId?: string | null;
  userId: string;
  createdAt: string;
  deletedAt?: string | null;
  changeId?: string | null;
  prevMutationId?: string | null;
  undoMutationId?: string | null;
  commitLayerId?: string | null;
  updateLastActualsTime?: string | null;
  newDimensions?: Array<{
    __typename?: 'DimensionCreateData';
    id: string;
    name: string;
    color?: string | null;
    attributes?: Array<{
      __typename?: 'AttributeCreateData';
      dimensionId: string;
      id: string;
      value: string;
    }> | null;
  }> | null;
  updateDimensions?: Array<{
    __typename?: 'DimensionUpdateData';
    id: string;
    name?: string | null;
    color?: string | null;
    newAttributes?: Array<{
      __typename?: 'AttributeCreateData';
      id: string;
      dimensionId: string;
      value: string;
    }> | null;
    updateAttributes?: Array<{
      __typename?: 'AttributeUpdateData';
      id: string;
      value?: string | null;
    }> | null;
    deleteAttributes?: Array<{ __typename?: 'AttributeDeleteData'; id: string }> | null;
    restoreAttributes?: Array<{ __typename?: 'AttributeRestoreData'; id: string }> | null;
  }> | null;
  deleteDimensions?: Array<{ __typename?: 'DimensionDeleteData'; id: string }> | null;
  restoreDimensions?: Array<{ __typename?: 'DimensionRestoreData'; id: string }> | null;
  newDrivers?: Array<{
    __typename?: 'DriverCreateData';
    id: string;
    name: string;
    format: DriverFormat;
    driverType: DriverType;
    submodelId?: string | null;
    valueType: ValueType;
    leverType?: LeverType | null;
    groupId?: string | null;
    description?: string | null;
    sortIndex?: number | null;
    currencyISOCode?: string | null;
    decimalPlaces?: number | null;
    driverReferences?: Array<{
      __typename?: 'DriverReferenceData';
      blockId: string;
      sortIndex?: number | null;
      groupId?: string | null;
    }> | null;
    coloring?: {
      __typename?: 'ColoringData';
      row?: string | null;
      cells?: Array<{ __typename?: 'TimeSeriesPoint'; time: string; value: string }> | null;
    } | null;
    driverMapping?: { __typename?: 'DriverMapping'; driverId: string } | null;
    basic?: {
      __typename?: 'BasicDriverCreateData';
      forecast: { __typename?: 'Forecast'; formula: string };
      actuals: {
        __typename?: 'Actuals';
        formula?: string | null;
        timeSeries?: Array<{ __typename: 'TimeSeriesPoint'; time: string; value: string }> | null;
      };
      rollup?: { __typename?: 'Rollup'; reducer: RollupReducer } | null;
    } | null;
    dimensional?: {
      __typename?: 'DimensionalDriverCreateData';
      dimensionIds?: Array<string> | null;
      builtInDimensions?: Array<BuiltInDimensionType> | null;
      subDrivers?: Array<{
        __typename?: 'DimensionalSubDriverCreateData';
        attributeIds?: Array<string> | null;
        existingDriverId?: string | null;
        builtInAttributes?: Array<{
          __typename?: 'BuiltInAttributeData';
          type: BuiltInDimensionType;
          value: string;
        }> | null;
        source?: {
          __typename?: 'DimensionalSubDriverSourceData';
          type: DimensionalSubDriverSourceType;
          extQueryId?: string | null;
        } | null;
        driver?: {
          __typename?: 'DriverCreateData';
          id: string;
          name: string;
          format: DriverFormat;
          valueType: ValueType;
          driverType: DriverType;
          submodelId?: string | null;
          leverType?: LeverType | null;
          groupId?: string | null;
          description?: string | null;
          sortIndex?: number | null;
          currencyISOCode?: string | null;
          decimalPlaces?: number | null;
          driverReferences?: Array<{
            __typename?: 'DriverReferenceData';
            blockId: string;
            sortIndex?: number | null;
            groupId?: string | null;
          }> | null;
          coloring?: {
            __typename?: 'ColoringData';
            row?: string | null;
            cells?: Array<{ __typename?: 'TimeSeriesPoint'; time: string; value: string }> | null;
          } | null;
          basic?: {
            __typename?: 'BasicDriverCreateData';
            forecast: { __typename?: 'Forecast'; formula: string };
            actuals: {
              __typename?: 'Actuals';
              formula?: string | null;
              timeSeries?: Array<{
                __typename: 'TimeSeriesPoint';
                time: string;
                value: string;
              }> | null;
            };
            rollup?: { __typename?: 'Rollup'; reducer: RollupReducer } | null;
          } | null;
        } | null;
      }> | null;
      defaultForecast?: { __typename?: 'DefaultFormula'; formula: string } | null;
      defaultActuals?: { __typename?: 'DefaultFormula'; formula: string } | null;
    } | null;
  }> | null;
  updateDrivers?: Array<{
    __typename?: 'DriverUpdateData';
    id: string;
    name?: string | null;
    description?: string | null;
    format?: DriverFormat | null;
    submodelId?: string | null;
    removeSubmodelId?: boolean | null;
    removeDriverMapping?: boolean | null;
    removeAllDriverReferences?: boolean | null;
    leverType?: LeverType | null;
    sortIndex?: number | null;
    currencyISOCode?: string | null;
    decimalPlaces?: number | null;
    groupId?: string | null;
    actuals?: {
      __typename?: 'ActualsWithNullableTimeSeries';
      formula?: string | null;
      timeSeries?: Array<{
        __typename: 'NullableTimeSeriesPoint';
        time: string;
        value?: string | null;
      }> | null;
    } | null;
    forecast?: { __typename?: 'Forecast'; formula: string } | null;
    rollup?: { __typename?: 'Rollup'; reducer: RollupReducer } | null;
    driverReferences?: Array<{
      __typename?: 'DriverReferenceData';
      blockId: string;
      sortIndex?: number | null;
      groupId?: string | null;
    }> | null;
    driverMapping?: { __typename?: 'DriverMapping'; driverId: string } | null;
    coloring?: {
      __typename?: 'ColoringData';
      row?: string | null;
      cells?: Array<{ __typename?: 'TimeSeriesPoint'; time: string; value: string }> | null;
    } | null;
    newSubDrivers?: Array<{
      __typename?: 'DimensionalSubDriverCreateData';
      attributeIds?: Array<string> | null;
      existingDriverId?: string | null;
      builtInAttributes?: Array<{
        __typename?: 'BuiltInAttributeData';
        type: BuiltInDimensionType;
        value: string;
      }> | null;
      source?: {
        __typename?: 'DimensionalSubDriverSourceData';
        type: DimensionalSubDriverSourceType;
        extQueryId?: string | null;
      } | null;
      driver?: {
        __typename?: 'DriverCreateData';
        id: string;
        name: string;
        format: DriverFormat;
        valueType: ValueType;
        driverType: DriverType;
        submodelId?: string | null;
        leverType?: LeverType | null;
        groupId?: string | null;
        description?: string | null;
        sortIndex?: number | null;
        currencyISOCode?: string | null;
        decimalPlaces?: number | null;
        driverReferences?: Array<{
          __typename?: 'DriverReferenceData';
          blockId: string;
          sortIndex?: number | null;
          groupId?: string | null;
        }> | null;
        coloring?: {
          __typename?: 'ColoringData';
          row?: string | null;
          cells?: Array<{ __typename?: 'TimeSeriesPoint'; time: string; value: string }> | null;
        } | null;
        basic?: {
          __typename?: 'BasicDriverCreateData';
          forecast: { __typename?: 'Forecast'; formula: string };
          actuals: {
            __typename?: 'Actuals';
            formula?: string | null;
            timeSeries?: Array<{
              __typename: 'TimeSeriesPoint';
              time: string;
              value: string;
            }> | null;
          };
          rollup?: { __typename?: 'Rollup'; reducer: RollupReducer } | null;
        } | null;
      } | null;
    }> | null;
    dimensional?: {
      __typename?: 'DimensionalDriverUpdateData';
      dimensionIds?: Array<string> | null;
      builtInDimensions?: Array<BuiltInDimensionType> | null;
      defaultForecast?: { __typename?: 'DefaultFormula'; formula: string } | null;
      defaultActuals?: { __typename?: 'DefaultFormula'; formula: string } | null;
      updateSubDrivers?: Array<{
        __typename?: 'DimensionalSubDriverUpdateData';
        attributeIds?: Array<string> | null;
        newAttributeIds?: Array<string> | null;
        removeAllAttributes?: boolean | null;
        unlink?: boolean | null;
        builtInAttributes?: Array<{
          __typename?: 'BuiltInAttributeData';
          type: BuiltInDimensionType;
          value: string;
        }> | null;
        source?: {
          __typename?: 'DimensionalSubDriverSourceData';
          type: DimensionalSubDriverSourceType;
          extQueryId?: string | null;
        } | null;
        newBuiltInAttributes?: Array<{
          __typename?: 'BuiltInAttributeData';
          type: BuiltInDimensionType;
          value: string;
        }> | null;
      }> | null;
      deleteSubDrivers?: Array<{
        __typename?: 'DimensionalSubDriverDeleteData';
        attributeIds?: Array<string> | null;
        builtInAttributes?: Array<{
          __typename?: 'BuiltInAttributeData';
          type: BuiltInDimensionType;
          value: string;
        }> | null;
      }> | null;
    } | null;
  }> | null;
  deleteDrivers?: Array<{ __typename?: 'DriverDeleteData'; id: string }> | null;
  newEvents?: Array<{
    __typename?: 'EventCreateData';
    id: string;
    name?: string | null;
    description?: string | null;
    ownerName: string;
    driverId?: string | null;
    businessObjectFieldId?: string | null;
    parentId?: string | null;
    start: string;
    end: string;
    modifierType?: ModifierType | null;
    curveType?: CurveType | null;
    sortIndex?: number | null;
    totalImpact?: string | null;
    impactType?: ImpactType | null;
    setValue?: string | null;
    setValueType?: ValueType | null;
    time?: string | null;
    value?: string | null;
    customCurvePoints?: Array<{
      __typename?: 'TimeSeriesPoint';
      time: string;
      value: string;
    }> | null;
  }> | null;
  deleteEvents?: Array<{ __typename?: 'EventDeleteData'; id: string }> | null;
  updateEvents?: Array<{
    __typename?: 'EventUpdateData';
    id: string;
    ownerName?: string | null;
    driverId?: string | null;
    businessObjectFieldId?: string | null;
    parentId?: string | null;
    name?: string | null;
    description?: string | null;
    start?: string | null;
    end?: string | null;
    curveType?: CurveType | null;
    sortIndex?: number | null;
    totalImpact?: string | null;
    impactType?: ImpactType | null;
    setValue?: string | null;
    setValueType?: ValueType | null;
    hidden?: boolean | null;
    time?: string | null;
    value?: string | null;
    customCurvePoints?: Array<{
      __typename?: 'NullableTimeSeriesPoint';
      time: string;
      value?: string | null;
    }> | null;
  }> | null;
  newMilestones?: Array<{
    __typename?: 'MilestoneCreateData';
    id: string;
    type?: MilestoneType | null;
    name?: string | null;
    driverId: string;
    value: string;
    time?: string | null;
    thresholdDirection?: ThresholdDirection | null;
  }> | null;
  deleteMilestones?: Array<{ __typename?: 'MilestoneDeleteData'; id: string }> | null;
  updateMilestones?: Array<{
    __typename?: 'MilestoneUpdateData';
    id: string;
    type?: MilestoneType | null;
    name?: string | null;
    value?: string | null;
    time?: string | null;
    thresholdDirection?: ThresholdDirection | null;
  }> | null;
  newSubmodels?: Array<{
    __typename?: 'SubmodelCreateData';
    id: string;
    name?: string | null;
    description?: string | null;
    sortedDriverGroupIds?: Array<string | null> | null;
  }> | null;
  updateSubmodels?: Array<{
    __typename?: 'SubmodelUpdateData';
    id: string;
    name?: string | null;
    description?: string | null;
    sortedDriverGroupIds?: Array<string | null> | null;
  }> | null;
  deleteSubmodels?: Array<{
    __typename?: 'SubmodelDeleteData';
    id: string;
    deleteDrivers?: boolean | null;
  }> | null;
  newEventGroups?: Array<{
    __typename?: 'EventGroupCreateData';
    id: string;
    name: string;
    description?: string | null;
    ownerName: string;
    ownerId: string;
    eventIds?: Array<string> | null;
    eventGroupIds?: Array<string> | null;
    parentId?: string | null;
    defaultEnd?: string | null;
    defaultStart?: string | null;
    sortIndex?: number | null;
  }> | null;
  deleteEventGroups?: Array<{ __typename?: 'EventGroupDeleteData'; id: string }> | null;
  updateEventGroups?: Array<{
    __typename?: 'EventGroupUpdateData';
    id: string;
    name?: string | null;
    description?: string | null;
    ownerName?: string | null;
    ownerId?: string | null;
    parentId?: string | null;
    eventIds?: Array<string> | null;
    eventGroupIds?: Array<string> | null;
    defaultEnd?: string | null;
    defaultStart?: string | null;
    hidden?: boolean | null;
    sortIndex?: number | null;
  }> | null;
  newLayers?: Array<{
    __typename?: 'LayerCreateData';
    id: string;
    name: string;
    description?: string | null;
    isDraft: boolean;
    parentLayerId?: string | null;
    userId?: string | null;
  }> | null;
  deleteLayers?: Array<{ __typename?: 'LayerDeleteData'; layerId: string }> | null;
  updateLayers?: Array<{
    __typename?: 'LayerUpdateData';
    layerId: string;
    name?: string | null;
    description?: string | null;
    isDraft?: boolean | null;
  }> | null;
  newExports?: Array<{
    __typename?: 'ExportCreateData';
    id: string;
    name: string;
    driverIds: Array<string>;
  }> | null;
  deleteExports?: Array<{ __typename?: 'ExportDeleteData'; id: string }> | null;
  updateExports?: Array<{
    __typename?: 'ExportUpdateData';
    id: string;
    name?: string | null;
    driverIds?: Array<string> | null;
  }> | null;
  newDriverGroups?: Array<{ __typename?: 'DriverGroupCreate'; id: string; name: string }> | null;
  renameDriverGroups?: Array<{ __typename?: 'DriverGroupRename'; id: string; name: string }> | null;
  deleteDriverGroups?: Array<{ __typename?: 'DriverGroupDelete'; id: string }> | null;
  newBusinessObjectSpecs?: Array<{
    __typename?: 'BusinessObjectSpecCreateData';
    id: string;
    name: string;
    extSource?: string | null;
    extSpecKey?: string | null;
    extSpecKeys?: Array<string> | null;
    databaseConfigId?: string | null;
    startFieldId?: string | null;
    isRestricted?: boolean | null;
    fields: Array<{
      __typename?: 'BusinessObjectFieldSpec';
      id: string;
      name: string;
      type: ValueType;
      dimensionId?: string | null;
      numericFormat?: DriverFormat | null;
      currencyISOCode?: string | null;
      decimalPlaces?: number | null;
      extFieldSpecKey?: string | null;
      defaultForecast?: { __typename?: 'Forecast'; formula: string } | null;
      defaultValues?: Array<{
        __typename?: 'BusinessObjectFieldDefaultValueEntry';
        key: string;
        value: {
          __typename?: 'BusinessObjectFieldValue';
          type: ValueType;
          actuals?: {
            __typename?: 'Actuals';
            formula?: string | null;
            timeSeries?: Array<{
              __typename: 'TimeSeriesPoint';
              time: string;
              value: string;
            }> | null;
          } | null;
        };
      }> | null;
    }>;
    defaultNameEntries?: Array<{
      __typename?: 'BusinessObjectDefaultNameEntry';
      key: string;
      value: string;
    }> | null;
    collection?: {
      __typename?: 'CollectionCreateData';
      dimensionalProperties?: Array<{
        __typename?: 'DimensionalPropertyCreateData';
        id: string;
        name: string;
        dimensionId: string;
        isDatabaseKey?: boolean | null;
        extFieldSpecKey?: string | null;
        mapping?: {
          __typename?: 'DimensionalPropertyMapping';
          lookupSpecId: string;
          resultPropertyId: string;
          searchDimensionPropertyId?: string | null;
        } | null;
      }> | null;
      driverProperties?: Array<{
        __typename?: 'DriverPropertyCreateData';
        id: string;
        driverId: string;
      }> | null;
    } | null;
  }> | null;
  updateBusinessObjectSpecs?: Array<{
    __typename?: 'BusinessObjectSpecUpdateData';
    id: string;
    name?: string | null;
    isRestricted?: boolean | null;
    databaseConfigId?: string | null;
    deleteFields?: Array<string> | null;
    updateStartFieldId?: string | null;
    extSpecKey?: string | null;
    extSpecKeys?: Array<string> | null;
    deleteDefaultNameEntries?: Array<string> | null;
    addFields?: Array<{
      __typename?: 'BusinessObjectFieldSpecData';
      id: string;
      name: string;
      type: ValueType;
      dimensionId?: string | null;
      numericFormat?: DriverFormat | null;
      currencyISOCode?: string | null;
      decimalPlaces?: number | null;
      isRestricted?: boolean | null;
      extFieldSpecKey?: string | null;
      defaultForecast?: { __typename?: 'Forecast'; formula: string } | null;
    }> | null;
    updateFields?: Array<{
      __typename?: 'BusinessObjectFieldSpecUpdateData';
      id: string;
      type?: ValueType | null;
      name?: string | null;
      numericFormat?: DriverFormat | null;
      currencyISOCode?: string | null;
      decimalPlaces?: number | null;
      isRestricted?: boolean | null;
      extFieldSpecKey?: string | null;
      propagateIntegrationData?: boolean | null;
      integrationDataOverridesForecast?: boolean | null;
      dimensionId?: string | null;
      removeDefaultValues?: Array<string> | null;
      removeDefaultForecast?: boolean | null;
      defaultForecast?: { __typename?: 'Forecast'; formula: string } | null;
      setDefaultValues?: Array<{
        __typename?: 'BusinessObjectFieldDefaultValueEntry';
        key: string;
        value: {
          __typename?: 'BusinessObjectFieldValue';
          type: ValueType;
          actuals?: {
            __typename?: 'Actuals';
            formula?: string | null;
            timeSeries?: Array<{
              __typename: 'TimeSeriesPoint';
              time: string;
              value: string;
            }> | null;
          } | null;
        };
      }> | null;
    }> | null;
    setDefaultNameEntries?: Array<{
      __typename?: 'BusinessObjectDefaultNameEntry';
      key: string;
      value: string;
    }> | null;
    updateCollection?: {
      __typename?: 'CollectionData';
      removeDimensionalProperties?: Array<string> | null;
      removeDriverProperties?: Array<string> | null;
      addDimensionalProperties?: Array<{
        __typename?: 'DimensionalPropertyCreateData';
        id: string;
        name: string;
        dimensionId: string;
        isDatabaseKey?: boolean | null;
        extQueryId?: string | null;
      }> | null;
      updateDimensionalProperties?: Array<{
        __typename?: 'DimensionalPropertyUpdateData';
        id: string;
        name?: string | null;
        dimensionId?: string | null;
        removeMapping?: boolean | null;
        isDatabaseKey?: boolean | null;
        mapping?: {
          __typename?: 'DimensionalPropertyMapping';
          lookupSpecId: string;
          resultPropertyId: string;
          searchDimensionPropertyId?: string | null;
        } | null;
      }> | null;
      addDriverProperties?: Array<{
        __typename?: 'DriverPropertyCreateData';
        id: string;
        driverId: string;
        extQueryId?: string | null;
      }> | null;
    } | null;
  }> | null;
  deleteBusinessObjectSpecs?: Array<{
    __typename?: 'BusinessObjectSpecDeleteData';
    id: string;
  }> | null;
  newBusinessObjects?: Array<{
    __typename?: 'BusinessObjectCreateData';
    id: string;
    name: string;
    specId: string;
    defaultEventGroupId?: string | null;
    fields: Array<{
      __typename?: 'BusinessObjectFieldData';
      id: string;
      fieldSpecId: string;
      value: {
        __typename?: 'BusinessObjectFieldValue';
        type: ValueType;
        initialValue?: string | null;
        actuals?: {
          __typename?: 'Actuals';
          formula?: string | null;
          timeSeries?: Array<{ __typename: 'TimeSeriesPoint'; time: string; value: string }> | null;
        } | null;
      };
    }>;
    collectionEntry?: {
      __typename?: 'CollectionEntryCreateData';
      attributeProperties?: Array<{
        __typename?: 'AttributePropertyCreateData';
        dimensionalPropertyId: string;
        attributeId: string;
      }> | null;
    } | null;
    metadata?: {
      __typename?: 'BusinessObjectMetadataData';
      remoteId?: string | null;
      extKey?: string | null;
      databaseConfigId?: string | null;
    } | null;
  }> | null;
  updateBusinessObjects?: Array<{
    __typename?: 'BusinessObjectUpdateData';
    id: string;
    name?: string | null;
    defaultEventGroupId?: string | null;
    deleteFields?: Array<string> | null;
    addFields?: Array<{
      __typename?: 'BusinessObjectFieldData';
      id: string;
      fieldSpecId: string;
      value: {
        __typename?: 'BusinessObjectFieldValue';
        type: ValueType;
        initialValue?: string | null;
        actuals?: {
          __typename?: 'Actuals';
          formula?: string | null;
          timeSeries?: Array<{ __typename: 'TimeSeriesPoint'; time: string; value: string }> | null;
        } | null;
      };
    }> | null;
    fields?: Array<{
      __typename?: 'BusinessObjectFieldUpdateData';
      id: string;
      value?: {
        __typename?: 'BusinessObjectFieldValueWithNullableTimeseries';
        type: ValueType;
        initialValue?: string | null;
        actuals?: {
          __typename?: 'ActualsWithNullableTimeSeries';
          formula?: string | null;
          timeSeries?: Array<{
            __typename: 'NullableTimeSeriesPoint';
            time: string;
            value?: string | null;
          }> | null;
        } | null;
      } | null;
    }> | null;
    metadata?: {
      __typename?: 'BusinessObjectMetadataData';
      remoteId?: string | null;
      extKey?: string | null;
      databaseConfigId?: string | null;
    } | null;
    updateCollectionEntry?: {
      __typename?: 'CollectionEntryUpdateData';
      removeAttributeProperties?: Array<string> | null;
      addAttributeProperties?: Array<{
        __typename?: 'AttributePropertyCreateData';
        dimensionalPropertyId: string;
        attributeId: string;
      }> | null;
      updateAttributeProperties?: Array<{
        __typename?: 'AttributePropertyUpdateData';
        dimensionalPropertyId: string;
        attributeId?: string | null;
      }> | null;
    } | null;
  }> | null;
  deleteBusinessObjects?: Array<{ __typename?: 'BusinessObjectDeleteData'; id: string }> | null;
  newBlocksPages?: Array<{
    __typename?: 'BlocksPageCreateData';
    id: string;
    createdByUserId?: string | null;
    name: string;
    internalPageType?: string | null;
    internalPageVersion?: number | null;
    sortIndex?: number | null;
    parent?: string | null;
    dateRange?: { __typename?: 'DateRange'; start?: string | null; end?: string | null } | null;
    layout?: Array<{
      __typename?: 'BlockRow';
      id: string;
      columns: Array<{ __typename?: 'BlockColumn'; id: string; blockIds: Array<string> }>;
    }> | null;
  }> | null;
  updateBlocksPages?: Array<{
    __typename?: 'BlocksPageUpdateData';
    id: string;
    name?: string | null;
    sortIndex?: number | null;
    parent?: string | null;
    dateRange?: { __typename?: 'DateRange'; start?: string | null; end?: string | null } | null;
    layout?: Array<{
      __typename?: 'BlockRow';
      id: string;
      columns: Array<{ __typename?: 'BlockColumn'; id: string; blockIds: Array<string> }>;
    }> | null;
  }> | null;
  deleteBlocksPages?: Array<{ __typename?: 'BlocksPageDeleteData'; id: string }> | null;
  newFolders?: Array<{
    __typename?: 'FolderCreateData';
    id: string;
    name: string;
    type: FolderType;
    parent?: string | null;
    sortIndex?: number | null;
  }> | null;
  updateFolders?: Array<{
    __typename?: 'FolderUpdateData';
    id: string;
    name?: string | null;
    type?: FolderType | null;
    parent?: string | null;
    sortIndex?: number | null;
  }> | null;
  deleteFolders?: Array<{ __typename?: 'FolderDeleteData'; id: string }> | null;
  newBlocks?: Array<{
    __typename?: 'BlockCreateData';
    id: string;
    name: string;
    type: BlockType;
    pageId: string;
    sortIndex?: number | null;
    blockConfig: {
      __typename?: 'BlockConfig';
      idFilter?: Array<string> | null;
      textBlockBody?: string | null;
      videoBlockUrl?: string | null;
      imageBlockUrl?: string | null;
      mediaWidth?: number | null;
      blockAlignment?: BlockAlignment | null;
      hideHeader?: boolean | null;
      businessObjectSpecId?: string | null;
      showRestricted?: boolean | null;
      rollupTypes?: Array<RollupType> | null;
      rollupType?: RollupType | null;
      fiscalYearStartMonth?: number | null;
      objectFieldSpecAsTimeSeriesId?: string | null;
      objectSpecDisplayAs?: ObjectSpecDisplayAsType | null;
      expandedIds?: Array<string> | null;
      fileMetadata?: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        type: string;
        path: string;
        size: number;
      } | null;
      blockViewOptions?: {
        __typename?: 'BlockViewOptions';
        showMilestones?: boolean | null;
        viewAsType?: BlockViewAsType | null;
        chartSize?: ChartSize | null;
        chartGroupingType?: ChartGroupingType | null;
        aggregateValues?: boolean | null;
        chartDisplay?: {
          __typename?: 'ChartDisplay';
          axes: Array<{
            __typename?: 'ChartAxis';
            id: string;
            position: ChartElementPosition;
            name?: string | null;
            showLabel?: boolean | null;
            type: ChartAxisType;
            time?: {
              __typename?: 'TimeAxisExtension';
              rollup: ChartRollupType;
              plotBy: ChartPlotByType;
            } | null;
            driver?: {
              __typename?: 'DriverAxisExtension';
              name?: string | null;
              groupIds: Array<string>;
              showTotals?: boolean | null;
              isNormalized?: boolean | null;
              format?: {
                __typename?: 'DriverAxisFormat';
                type: DriverFormat;
                decimalPlaces?: number | null;
                currencyISOCode?: string | null;
              } | null;
            } | null;
          }>;
          series: Array<{
            __typename?: 'ChartSeries';
            id: string;
            driverId: string;
            type: ChartSeriesType;
            color?: string | null;
            showLabels?: boolean | null;
          }>;
          groups: Array<{
            __typename?: 'ChartGroup';
            id: string;
            seriesIds: Array<string>;
            isDefault: boolean;
            layerId?: string | null;
            name?: string | null;
            showLabels?: boolean | null;
            dateRange?: {
              __typename?: 'DateRange';
              start?: string | null;
              end?: string | null;
            } | null;
          }>;
          legend?: {
            __typename?: 'ChartLegend';
            showLegend?: boolean | null;
            position?: ChartElementPosition | null;
            container?: {
              __typename?: 'LegendContainer';
              maxWidth?: number | null;
              maxHeight?: number | null;
            } | null;
            item?: {
              __typename?: 'LegendItem';
              maxWidth?: number | null;
              paddingX?: number | null;
              paddingY?: number | null;
            } | null;
          } | null;
        } | null;
        dateRangeComparison?: {
          __typename?: 'DateRangeComparison';
          type: DateRangeComparisonType;
          selectedPeriod: { __typename?: 'DateRange'; start?: string | null; end?: string | null };
          comparisonPeriod: {
            __typename?: 'DateRange';
            start?: string | null;
            end?: string | null;
          };
        } | null;
      } | null;
      sortBy?: {
        __typename?: 'BlockSortBy';
        sortType: BlockSortType;
        impact?: {
          __typename?: 'ImpactSortExtension';
          driverId: string;
          startMonthKey: string;
          endMonthKey: string;
        } | null;
        object?: {
          __typename?: 'ObjectSortExtension';
          ops: Array<{
            __typename?: 'ObjectSortOp';
            propertyId: string;
            order: ObjectSortOrder;
            manualOrdering?: Array<string> | null;
          }>;
        } | null;
      } | null;
      filterBy?: Array<{
        __typename?: 'BlockFilter';
        filterType: BlockFilterType;
        businessObjectSpecId?: string | null;
        expression?: string | null;
        filterKey?: string | null;
        filterValues?: Array<string> | null;
        filterOp?: BlockFilterOperator | null;
      }> | null;
      groupBy?: {
        __typename?: 'BlockGroupBy';
        groupByType: BlockGroupByType;
        driverField?: {
          __typename?: 'DriverFieldGroupByExtension';
          driverFieldSpecId: string;
          collapsedAttributeOptionIds?: Array<string> | null;
        } | null;
        objectField?: {
          __typename?: 'ObjectFieldGroupByExtension';
          businessObjectFieldId: string;
          collapsedAttributeIds?: Array<string> | null;
        } | null;
        driverProperty?: {
          __typename?: 'DriverPropertyGroupByExtension';
          driverPropertyId: string;
          collapsedAttributeIds?: Array<string> | null;
        } | null;
        date?: {
          __typename?: 'DateGroupByExtension';
          collapsedMonthKeys?: Array<string> | null;
        } | null;
      } | null;
      driverIndentations?: Array<{
        __typename?: 'DriverIndentation';
        level: number;
        driverId: string;
      }> | null;
      dateRange?: { __typename?: 'DateRange'; start?: string | null; end?: string | null } | null;
      viewAtTime?: {
        __typename?: 'TimePeriod';
        time?: string | null;
        dynamicDateType?: DynamicDate | null;
      } | null;
      comparisons?: {
        __typename?: 'BlockComparisons';
        layerIds: Array<string>;
        columns?: Array<ComparisonColumn> | null;
        layout?: BlockComparisonLayout | null;
        baselineLayerId?: string | null;
      } | null;
      columns?: Array<{
        __typename?: 'BlockColumnOptions';
        key: string;
        width?: number | null;
        visible?: boolean | null;
      }> | null;
      impactDisplay?: { __typename?: 'ImpactDisplay'; displayType: ImpactDisplayType } | null;
    };
  }> | null;
  updateBlocks?: Array<{
    __typename?: 'BlockUpdateData';
    id: string;
    name?: string | null;
    type?: BlockType | null;
    sortIndex?: number | null;
    blockConfig?: {
      __typename?: 'BlockConfig';
      idFilter?: Array<string> | null;
      textBlockBody?: string | null;
      videoBlockUrl?: string | null;
      imageBlockUrl?: string | null;
      mediaWidth?: number | null;
      blockAlignment?: BlockAlignment | null;
      hideHeader?: boolean | null;
      businessObjectSpecId?: string | null;
      showRestricted?: boolean | null;
      rollupTypes?: Array<RollupType> | null;
      rollupType?: RollupType | null;
      fiscalYearStartMonth?: number | null;
      objectFieldSpecAsTimeSeriesId?: string | null;
      objectSpecDisplayAs?: ObjectSpecDisplayAsType | null;
      expandedIds?: Array<string> | null;
      fileMetadata?: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        type: string;
        path: string;
        size: number;
      } | null;
      blockViewOptions?: {
        __typename?: 'BlockViewOptions';
        showMilestones?: boolean | null;
        viewAsType?: BlockViewAsType | null;
        chartSize?: ChartSize | null;
        chartGroupingType?: ChartGroupingType | null;
        aggregateValues?: boolean | null;
        chartDisplay?: {
          __typename?: 'ChartDisplay';
          axes: Array<{
            __typename?: 'ChartAxis';
            id: string;
            position: ChartElementPosition;
            name?: string | null;
            showLabel?: boolean | null;
            type: ChartAxisType;
            time?: {
              __typename?: 'TimeAxisExtension';
              rollup: ChartRollupType;
              plotBy: ChartPlotByType;
            } | null;
            driver?: {
              __typename?: 'DriverAxisExtension';
              name?: string | null;
              groupIds: Array<string>;
              showTotals?: boolean | null;
              isNormalized?: boolean | null;
              format?: {
                __typename?: 'DriverAxisFormat';
                type: DriverFormat;
                decimalPlaces?: number | null;
                currencyISOCode?: string | null;
              } | null;
            } | null;
          }>;
          series: Array<{
            __typename?: 'ChartSeries';
            id: string;
            driverId: string;
            type: ChartSeriesType;
            color?: string | null;
            showLabels?: boolean | null;
          }>;
          groups: Array<{
            __typename?: 'ChartGroup';
            id: string;
            seriesIds: Array<string>;
            isDefault: boolean;
            layerId?: string | null;
            name?: string | null;
            showLabels?: boolean | null;
            dateRange?: {
              __typename?: 'DateRange';
              start?: string | null;
              end?: string | null;
            } | null;
          }>;
          legend?: {
            __typename?: 'ChartLegend';
            showLegend?: boolean | null;
            position?: ChartElementPosition | null;
            container?: {
              __typename?: 'LegendContainer';
              maxWidth?: number | null;
              maxHeight?: number | null;
            } | null;
            item?: {
              __typename?: 'LegendItem';
              maxWidth?: number | null;
              paddingX?: number | null;
              paddingY?: number | null;
            } | null;
          } | null;
        } | null;
        dateRangeComparison?: {
          __typename?: 'DateRangeComparison';
          type: DateRangeComparisonType;
          selectedPeriod: { __typename?: 'DateRange'; start?: string | null; end?: string | null };
          comparisonPeriod: {
            __typename?: 'DateRange';
            start?: string | null;
            end?: string | null;
          };
        } | null;
      } | null;
      sortBy?: {
        __typename?: 'BlockSortBy';
        sortType: BlockSortType;
        impact?: {
          __typename?: 'ImpactSortExtension';
          driverId: string;
          startMonthKey: string;
          endMonthKey: string;
        } | null;
        object?: {
          __typename?: 'ObjectSortExtension';
          ops: Array<{
            __typename?: 'ObjectSortOp';
            propertyId: string;
            order: ObjectSortOrder;
            manualOrdering?: Array<string> | null;
          }>;
        } | null;
      } | null;
      filterBy?: Array<{
        __typename?: 'BlockFilter';
        filterType: BlockFilterType;
        businessObjectSpecId?: string | null;
        expression?: string | null;
        filterKey?: string | null;
        filterValues?: Array<string> | null;
        filterOp?: BlockFilterOperator | null;
      }> | null;
      groupBy?: {
        __typename?: 'BlockGroupBy';
        groupByType: BlockGroupByType;
        driverField?: {
          __typename?: 'DriverFieldGroupByExtension';
          driverFieldSpecId: string;
          collapsedAttributeOptionIds?: Array<string> | null;
        } | null;
        objectField?: {
          __typename?: 'ObjectFieldGroupByExtension';
          businessObjectFieldId: string;
          collapsedAttributeIds?: Array<string> | null;
        } | null;
        driverProperty?: {
          __typename?: 'DriverPropertyGroupByExtension';
          driverPropertyId: string;
          collapsedAttributeIds?: Array<string> | null;
        } | null;
        date?: {
          __typename?: 'DateGroupByExtension';
          collapsedMonthKeys?: Array<string> | null;
        } | null;
      } | null;
      driverIndentations?: Array<{
        __typename?: 'DriverIndentation';
        level: number;
        driverId: string;
      }> | null;
      dateRange?: { __typename?: 'DateRange'; start?: string | null; end?: string | null } | null;
      viewAtTime?: {
        __typename?: 'TimePeriod';
        time?: string | null;
        dynamicDateType?: DynamicDate | null;
      } | null;
      comparisons?: {
        __typename?: 'BlockComparisons';
        layerIds: Array<string>;
        columns?: Array<ComparisonColumn> | null;
        layout?: BlockComparisonLayout | null;
        baselineLayerId?: string | null;
      } | null;
      columns?: Array<{
        __typename?: 'BlockColumnOptions';
        key: string;
        width?: number | null;
        visible?: boolean | null;
      }> | null;
      impactDisplay?: { __typename?: 'ImpactDisplay'; displayType: ImpactDisplayType } | null;
    } | null;
  }> | null;
  deleteBlocks?: Array<{ __typename?: 'BlockDeleteData'; id: string }> | null;
  createExtDrivers?: Array<{
    __typename?: 'ExtDriverCreateData';
    id: string;
    source: string;
    model: ExtModel;
    remoteId?: string | null;
    path: Array<string>;
    integrationQueryId?: string | null;
    accountId?: string | null;
    timeSeries?: Array<{ __typename: 'TimeSeriesPoint'; time: string; value: string }> | null;
    query?: {
      __typename?: 'TransactionFilterQuery';
      predicates: Array<{
        __typename?: 'TransactionFilterPredicate';
        type: TransactionFilterPredicateType;
        multiSelect?: {
          __typename?: 'TransactionFilterMultiSelectPredicate';
          field: TransactionField;
          operator: TransactionFilterMultiSelectOperator;
          values: Array<{
            __typename?: 'TransactionFieldValue';
            type: TransactionFieldValueType;
            stringValue?: string | null;
          }>;
        } | null;
        comparison?: {
          __typename?: 'TransactionFilterComparisonPredicate';
          field: TransactionField;
          operator: TransactionFilterComparisonOperator;
          value: {
            __typename?: 'TransactionFieldValue';
            type: TransactionFieldValueType;
            stringValue?: string | null;
          };
        } | null;
      }>;
    } | null;
  }> | null;
  deleteExtDrivers?: Array<{ __typename?: 'ExtDriverDeleteData'; id: string }> | null;
  updateExtDrivers?: Array<{
    __typename?: 'ExtDriverUpdateData';
    id: string;
    accountId?: string | null;
    timeSeries?: Array<{
      __typename: 'NullableTimeSeriesPoint';
      time: string;
      value?: string | null;
    }> | null;
    query?: {
      __typename?: 'TransactionFilterQuery';
      predicates: Array<{
        __typename?: 'TransactionFilterPredicate';
        type: TransactionFilterPredicateType;
        multiSelect?: {
          __typename?: 'TransactionFilterMultiSelectPredicate';
          field: TransactionField;
          operator: TransactionFilterMultiSelectOperator;
          values: Array<{
            __typename?: 'TransactionFieldValue';
            type: TransactionFieldValueType;
            stringValue?: string | null;
          }>;
        } | null;
        comparison?: {
          __typename?: 'TransactionFilterComparisonPredicate';
          field: TransactionField;
          operator: TransactionFilterComparisonOperator;
          value: {
            __typename?: 'TransactionFieldValue';
            type: TransactionFieldValueType;
            stringValue?: string | null;
          };
        } | null;
      }>;
    } | null;
  }> | null;
  createExtObjectSpecs?: Array<{
    __typename?: 'ExtObjectSpecCreateData';
    extKey: string;
    source: string;
    model: ExtModel;
    startFieldExtKey?: string | null;
    integrationQueryId?: string | null;
    propagateDataForward: boolean;
    overrideForecastData: boolean;
    fields: Array<{
      __typename?: 'ExtObjectFieldSpec';
      extKey: string;
      name: string;
      type: ValueType;
      dimensionId?: string | null;
      numericFormat?: DriverFormat | null;
    }>;
  }> | null;
  deleteExtObjectSpecs?: Array<{ __typename?: 'ExtObjectSpecDeleteData'; extKey: string }> | null;
  updateExtObjectSpecs?: Array<{
    __typename?: 'ExtObjectSpecUpdateData';
    extKey: string;
    propagateDataForward?: boolean | null;
    overrideForecastData?: boolean | null;
  }> | null;
  createExtObjects?: Array<{
    __typename?: 'ExtObjectCreateData';
    extKey: string;
    name: string;
    remoteId: string;
    extSpecKey: string;
    fields: Array<{
      __typename?: 'ExtObjectFieldCreateData';
      extFieldSpecKey: string;
      timeSeries: Array<{ __typename: 'TimeSeriesPoint'; time: string; value: string }>;
    }>;
  }> | null;
  deleteExtObjects?: Array<{ __typename?: 'ExtObjectDeleteData'; extKey: string }> | null;
  createIntegrationQueries?: Array<{
    __typename?: 'IntegrationQueryCreateData';
    id: string;
    name: string;
    source: string;
    enableExtTable?: boolean | null;
    createdAt: string;
    linkedAccountId: string;
    content: { __typename?: 'IntegrationQueryContent'; sqlQuery?: string | null };
  }> | null;
  updateIntegrationQueries?: Array<{
    __typename?: 'IntegrationQueryUpdateData';
    id: string;
    name?: string | null;
    isScheduled?: boolean | null;
    isScheduledExtTable?: boolean | null;
    lastSyncedAt?: string | null;
    enableExtTable?: boolean | null;
    linkedAccountId?: string | null;
    content?: { __typename?: 'IntegrationQueryContent'; sqlQuery?: string | null } | null;
  }> | null;
  deleteIntegrationQueries?: Array<{
    __typename?: 'IntegrationQueryDeleteData';
    id: string;
  }> | null;
  createNamedDatasetVersions?: Array<{
    __typename?: 'NamedDatasetVersionCreateData';
    mutationId: string;
    name: string;
  }> | null;
  updateNamedDatasetVersions?: Array<{
    __typename?: 'NamedDatasetVersionUpdateData';
    mutationId: string;
    name: string;
  }> | null;
  deleteNamedDatasetVersions?: Array<{
    __typename?: 'NamedDatasetVersionDeleteData';
    mutationId: string;
  }> | null;
  createExtTables?: Array<{
    __typename?: 'ExtTableCreateData';
    sourceKey: string;
    queryId?: string | null;
    schema: {
      __typename?: 'ExtTableSchema';
      version: number;
      columns: Array<{ __typename?: 'ExtTableColumn'; id: string }>;
    };
  }> | null;
  updateExtTables?: Array<{
    __typename?: 'ExtTableUpdateData';
    sourceKey: string;
    schema?: {
      __typename?: 'ExtTableSchema';
      version: number;
      columns: Array<{ __typename?: 'ExtTableColumn'; id: string }>;
    } | null;
  }> | null;
  deleteExtTables?: Array<{ __typename?: 'ExtTableDeleteData'; sourceKey: string }> | null;
  createExtTableRuns?: Array<{
    __typename?: 'ExtTableRunCreateData';
    sourceKey: string;
    schemaVersion: number;
    runId: string;
    dataMacro: { __typename?: 'DatasetMutationMacro'; type: DatasetMutationMacroType; id: string };
  }> | null;
  createDatabaseConfigs?: Array<{
    __typename?: 'DatabaseConfig';
    id: string;
    businessObjectSpecId: string;
    source: DatabaseConfigSourceType;
    refreshInfo?: {
      __typename?: 'DatabaseConfigRefreshInfo';
      updatedAt: string;
      status: DatabaseConfigRefreshStatus;
      error?: { __typename?: 'DatabaseConfigRefreshError'; reason?: string | null } | null;
    } | null;
    extTable?: {
      __typename?: 'DatabaseConfigForExtTableSource';
      extTableSourceKey: string;
      timeColumn?: { __typename?: 'TimeColumn'; extTableColumnId: string } | null;
      segmentedByColumns?: Array<{
        __typename: 'ExtTableDimensionColumn';
        dimensionId?: string | null;
        dimensionalPropertyId?: string | null;
        extQueryId?: string | null;
        extTableColumnId: string;
      }> | null;
      metadataColumns?: Array<{
        __typename: 'ExtTableDimensionColumn';
        dimensionId?: string | null;
        dimensionalPropertyId?: string | null;
        extQueryId?: string | null;
        extTableColumnId: string;
      }> | null;
      driverColumns?: Array<{
        __typename: 'ExtTableDriverColumn';
        driverPropertyId?: string | null;
        aggregationType: DatabaseConfigAggregationType;
        extQueryId?: string | null;
        extTableColumnId: string;
      }> | null;
      filters?: Array<{
        __typename: 'ExtTableFilter';
        comparator: ComparatorType;
        compareTo: string;
        extTableColumnId: string;
      }> | null;
    } | null;
    database?: {
      __typename?: 'DatabaseConfigForDatabaseSource';
      sourceBusinessObjectSpecId: string;
      driverColumns?: Array<{
        __typename: 'DatabaseDriverColumn';
        driverPropertyId?: string | null;
        aggregationType: DatabaseConfigAggregationType;
        sourceDriverPropertyId: string;
      }> | null;
      segmentedByColumns?: Array<{
        __typename: 'DatabaseDimensionColumn';
        dimensionalPropertyId?: string | null;
        sourceDimensionalPropertyId: string;
      }> | null;
      metadataColumns?: Array<{
        __typename: 'DatabaseDimensionColumn';
        dimensionalPropertyId?: string | null;
        sourceDimensionalPropertyId: string;
      }> | null;
      filters?: Array<{
        __typename: 'DatabaseFilter';
        comparator: ComparatorType;
        compareTo: Array<string>;
        dimensionalPropertyId: string;
      }> | null;
    } | null;
  }> | null;
  updateDatabaseConfigs?: Array<{
    __typename: 'DatabaseConfig';
    id: string;
    businessObjectSpecId: string;
    source: DatabaseConfigSourceType;
    refreshInfo?: {
      __typename?: 'DatabaseConfigRefreshInfo';
      updatedAt: string;
      status: DatabaseConfigRefreshStatus;
      error?: { __typename?: 'DatabaseConfigRefreshError'; reason?: string | null } | null;
    } | null;
    extTable?: {
      __typename: 'DatabaseConfigForExtTableSource';
      extTableSourceKey: string;
      timeColumn?: { __typename?: 'TimeColumn'; extTableColumnId: string } | null;
      segmentedByColumns?: Array<{
        __typename: 'ExtTableDimensionColumn';
        dimensionId?: string | null;
        dimensionalPropertyId?: string | null;
        extQueryId?: string | null;
        extTableColumnId: string;
      }> | null;
      metadataColumns?: Array<{
        __typename: 'ExtTableDimensionColumn';
        dimensionId?: string | null;
        dimensionalPropertyId?: string | null;
        extQueryId?: string | null;
        extTableColumnId: string;
      }> | null;
      driverColumns?: Array<{
        __typename: 'ExtTableDriverColumn';
        driverPropertyId?: string | null;
        aggregationType: DatabaseConfigAggregationType;
        extQueryId?: string | null;
        extTableColumnId: string;
      }> | null;
      filters?: Array<{
        __typename: 'ExtTableFilter';
        comparator: ComparatorType;
        compareTo: string;
        extTableColumnId: string;
      }> | null;
    } | null;
    database?: {
      __typename: 'DatabaseConfigForDatabaseSource';
      sourceBusinessObjectSpecId: string;
      driverColumns?: Array<{
        __typename: 'DatabaseDriverColumn';
        driverPropertyId?: string | null;
        aggregationType: DatabaseConfigAggregationType;
        sourceDriverPropertyId: string;
      }> | null;
      segmentedByColumns?: Array<{
        __typename: 'DatabaseDimensionColumn';
        dimensionalPropertyId?: string | null;
        sourceDimensionalPropertyId: string;
      }> | null;
      metadataColumns?: Array<{
        __typename: 'DatabaseDimensionColumn';
        dimensionalPropertyId?: string | null;
        sourceDimensionalPropertyId: string;
      }> | null;
      filters?: Array<{
        __typename: 'DatabaseFilter';
        comparator: ComparatorType;
        compareTo: Array<string>;
        dimensionalPropertyId: string;
      }> | null;
    } | null;
  }> | null;
  createExtQueries?: Array<{
    __typename?: 'ExtQueryCreateData';
    id: string;
    source: ExtQuerySource;
    raw: string;
  }> | null;
  updateExtQueries?: Array<{
    __typename?: 'ExtQueryUpdateData';
    id: string;
    source?: ExtQuerySource | null;
    raw?: string | null;
  }> | null;
  deleteExtQueries?: Array<{ __typename?: 'ExtQueryDeleteData'; id: string }> | null;
};

export type DatasetMutatedSubscriptionVariables = Exact<{
  orgId: Scalars['ID'];
  lastMutationId: Scalars['String'];
  clientInfo?: InputMaybe<ClientInfo>;
}>;

export type DatasetMutatedSubscription = {
  __typename?: 'Subscription';
  datasetMutated: {
    __typename?: 'DatasetMutationData';
    id: string;
    layerId?: string | null;
    userId: string;
    createdAt: string;
    deletedAt?: string | null;
    changeId?: string | null;
    prevMutationId?: string | null;
    undoMutationId?: string | null;
    commitLayerId?: string | null;
    updateLastActualsTime?: string | null;
    newDimensions?: Array<{
      __typename?: 'DimensionCreateData';
      id: string;
      name: string;
      color?: string | null;
      attributes?: Array<{
        __typename?: 'AttributeCreateData';
        dimensionId: string;
        id: string;
        value: string;
      }> | null;
    }> | null;
    updateDimensions?: Array<{
      __typename?: 'DimensionUpdateData';
      id: string;
      name?: string | null;
      color?: string | null;
      newAttributes?: Array<{
        __typename?: 'AttributeCreateData';
        id: string;
        dimensionId: string;
        value: string;
      }> | null;
      updateAttributes?: Array<{
        __typename?: 'AttributeUpdateData';
        id: string;
        value?: string | null;
      }> | null;
      deleteAttributes?: Array<{ __typename?: 'AttributeDeleteData'; id: string }> | null;
      restoreAttributes?: Array<{ __typename?: 'AttributeRestoreData'; id: string }> | null;
    }> | null;
    deleteDimensions?: Array<{ __typename?: 'DimensionDeleteData'; id: string }> | null;
    restoreDimensions?: Array<{ __typename?: 'DimensionRestoreData'; id: string }> | null;
    newDrivers?: Array<{
      __typename?: 'DriverCreateData';
      id: string;
      name: string;
      format: DriverFormat;
      driverType: DriverType;
      submodelId?: string | null;
      valueType: ValueType;
      leverType?: LeverType | null;
      groupId?: string | null;
      description?: string | null;
      sortIndex?: number | null;
      currencyISOCode?: string | null;
      decimalPlaces?: number | null;
      driverReferences?: Array<{
        __typename?: 'DriverReferenceData';
        blockId: string;
        sortIndex?: number | null;
        groupId?: string | null;
      }> | null;
      coloring?: {
        __typename?: 'ColoringData';
        row?: string | null;
        cells?: Array<{ __typename?: 'TimeSeriesPoint'; time: string; value: string }> | null;
      } | null;
      driverMapping?: { __typename?: 'DriverMapping'; driverId: string } | null;
      basic?: {
        __typename?: 'BasicDriverCreateData';
        forecast: { __typename?: 'Forecast'; formula: string };
        actuals: {
          __typename?: 'Actuals';
          formula?: string | null;
          timeSeries?: Array<{ __typename: 'TimeSeriesPoint'; time: string; value: string }> | null;
        };
        rollup?: { __typename?: 'Rollup'; reducer: RollupReducer } | null;
      } | null;
      dimensional?: {
        __typename?: 'DimensionalDriverCreateData';
        dimensionIds?: Array<string> | null;
        builtInDimensions?: Array<BuiltInDimensionType> | null;
        subDrivers?: Array<{
          __typename?: 'DimensionalSubDriverCreateData';
          attributeIds?: Array<string> | null;
          existingDriverId?: string | null;
          builtInAttributes?: Array<{
            __typename?: 'BuiltInAttributeData';
            type: BuiltInDimensionType;
            value: string;
          }> | null;
          source?: {
            __typename?: 'DimensionalSubDriverSourceData';
            type: DimensionalSubDriverSourceType;
            extQueryId?: string | null;
          } | null;
          driver?: {
            __typename?: 'DriverCreateData';
            id: string;
            name: string;
            format: DriverFormat;
            valueType: ValueType;
            driverType: DriverType;
            submodelId?: string | null;
            leverType?: LeverType | null;
            groupId?: string | null;
            description?: string | null;
            sortIndex?: number | null;
            currencyISOCode?: string | null;
            decimalPlaces?: number | null;
            driverReferences?: Array<{
              __typename?: 'DriverReferenceData';
              blockId: string;
              sortIndex?: number | null;
              groupId?: string | null;
            }> | null;
            coloring?: {
              __typename?: 'ColoringData';
              row?: string | null;
              cells?: Array<{ __typename?: 'TimeSeriesPoint'; time: string; value: string }> | null;
            } | null;
            basic?: {
              __typename?: 'BasicDriverCreateData';
              forecast: { __typename?: 'Forecast'; formula: string };
              actuals: {
                __typename?: 'Actuals';
                formula?: string | null;
                timeSeries?: Array<{
                  __typename: 'TimeSeriesPoint';
                  time: string;
                  value: string;
                }> | null;
              };
              rollup?: { __typename?: 'Rollup'; reducer: RollupReducer } | null;
            } | null;
          } | null;
        }> | null;
        defaultForecast?: { __typename?: 'DefaultFormula'; formula: string } | null;
        defaultActuals?: { __typename?: 'DefaultFormula'; formula: string } | null;
      } | null;
    }> | null;
    updateDrivers?: Array<{
      __typename?: 'DriverUpdateData';
      id: string;
      name?: string | null;
      description?: string | null;
      format?: DriverFormat | null;
      submodelId?: string | null;
      removeSubmodelId?: boolean | null;
      removeDriverMapping?: boolean | null;
      removeAllDriverReferences?: boolean | null;
      leverType?: LeverType | null;
      sortIndex?: number | null;
      currencyISOCode?: string | null;
      decimalPlaces?: number | null;
      groupId?: string | null;
      actuals?: {
        __typename?: 'ActualsWithNullableTimeSeries';
        formula?: string | null;
        timeSeries?: Array<{
          __typename: 'NullableTimeSeriesPoint';
          time: string;
          value?: string | null;
        }> | null;
      } | null;
      forecast?: { __typename?: 'Forecast'; formula: string } | null;
      rollup?: { __typename?: 'Rollup'; reducer: RollupReducer } | null;
      driverReferences?: Array<{
        __typename?: 'DriverReferenceData';
        blockId: string;
        sortIndex?: number | null;
        groupId?: string | null;
      }> | null;
      driverMapping?: { __typename?: 'DriverMapping'; driverId: string } | null;
      coloring?: {
        __typename?: 'ColoringData';
        row?: string | null;
        cells?: Array<{ __typename?: 'TimeSeriesPoint'; time: string; value: string }> | null;
      } | null;
      newSubDrivers?: Array<{
        __typename?: 'DimensionalSubDriverCreateData';
        attributeIds?: Array<string> | null;
        existingDriverId?: string | null;
        builtInAttributes?: Array<{
          __typename?: 'BuiltInAttributeData';
          type: BuiltInDimensionType;
          value: string;
        }> | null;
        source?: {
          __typename?: 'DimensionalSubDriverSourceData';
          type: DimensionalSubDriverSourceType;
          extQueryId?: string | null;
        } | null;
        driver?: {
          __typename?: 'DriverCreateData';
          id: string;
          name: string;
          format: DriverFormat;
          valueType: ValueType;
          driverType: DriverType;
          submodelId?: string | null;
          leverType?: LeverType | null;
          groupId?: string | null;
          description?: string | null;
          sortIndex?: number | null;
          currencyISOCode?: string | null;
          decimalPlaces?: number | null;
          driverReferences?: Array<{
            __typename?: 'DriverReferenceData';
            blockId: string;
            sortIndex?: number | null;
            groupId?: string | null;
          }> | null;
          coloring?: {
            __typename?: 'ColoringData';
            row?: string | null;
            cells?: Array<{ __typename?: 'TimeSeriesPoint'; time: string; value: string }> | null;
          } | null;
          basic?: {
            __typename?: 'BasicDriverCreateData';
            forecast: { __typename?: 'Forecast'; formula: string };
            actuals: {
              __typename?: 'Actuals';
              formula?: string | null;
              timeSeries?: Array<{
                __typename: 'TimeSeriesPoint';
                time: string;
                value: string;
              }> | null;
            };
            rollup?: { __typename?: 'Rollup'; reducer: RollupReducer } | null;
          } | null;
        } | null;
      }> | null;
      dimensional?: {
        __typename?: 'DimensionalDriverUpdateData';
        dimensionIds?: Array<string> | null;
        builtInDimensions?: Array<BuiltInDimensionType> | null;
        defaultForecast?: { __typename?: 'DefaultFormula'; formula: string } | null;
        defaultActuals?: { __typename?: 'DefaultFormula'; formula: string } | null;
        updateSubDrivers?: Array<{
          __typename?: 'DimensionalSubDriverUpdateData';
          attributeIds?: Array<string> | null;
          newAttributeIds?: Array<string> | null;
          removeAllAttributes?: boolean | null;
          unlink?: boolean | null;
          builtInAttributes?: Array<{
            __typename?: 'BuiltInAttributeData';
            type: BuiltInDimensionType;
            value: string;
          }> | null;
          source?: {
            __typename?: 'DimensionalSubDriverSourceData';
            type: DimensionalSubDriverSourceType;
            extQueryId?: string | null;
          } | null;
          newBuiltInAttributes?: Array<{
            __typename?: 'BuiltInAttributeData';
            type: BuiltInDimensionType;
            value: string;
          }> | null;
        }> | null;
        deleteSubDrivers?: Array<{
          __typename?: 'DimensionalSubDriverDeleteData';
          attributeIds?: Array<string> | null;
          builtInAttributes?: Array<{
            __typename?: 'BuiltInAttributeData';
            type: BuiltInDimensionType;
            value: string;
          }> | null;
        }> | null;
      } | null;
    }> | null;
    deleteDrivers?: Array<{ __typename?: 'DriverDeleteData'; id: string }> | null;
    newEvents?: Array<{
      __typename?: 'EventCreateData';
      id: string;
      name?: string | null;
      description?: string | null;
      ownerName: string;
      driverId?: string | null;
      businessObjectFieldId?: string | null;
      parentId?: string | null;
      start: string;
      end: string;
      modifierType?: ModifierType | null;
      curveType?: CurveType | null;
      sortIndex?: number | null;
      totalImpact?: string | null;
      impactType?: ImpactType | null;
      setValue?: string | null;
      setValueType?: ValueType | null;
      time?: string | null;
      value?: string | null;
      customCurvePoints?: Array<{
        __typename?: 'TimeSeriesPoint';
        time: string;
        value: string;
      }> | null;
    }> | null;
    deleteEvents?: Array<{ __typename?: 'EventDeleteData'; id: string }> | null;
    updateEvents?: Array<{
      __typename?: 'EventUpdateData';
      id: string;
      ownerName?: string | null;
      driverId?: string | null;
      businessObjectFieldId?: string | null;
      parentId?: string | null;
      name?: string | null;
      description?: string | null;
      start?: string | null;
      end?: string | null;
      curveType?: CurveType | null;
      sortIndex?: number | null;
      totalImpact?: string | null;
      impactType?: ImpactType | null;
      setValue?: string | null;
      setValueType?: ValueType | null;
      hidden?: boolean | null;
      time?: string | null;
      value?: string | null;
      customCurvePoints?: Array<{
        __typename?: 'NullableTimeSeriesPoint';
        time: string;
        value?: string | null;
      }> | null;
    }> | null;
    newMilestones?: Array<{
      __typename?: 'MilestoneCreateData';
      id: string;
      type?: MilestoneType | null;
      name?: string | null;
      driverId: string;
      value: string;
      time?: string | null;
      thresholdDirection?: ThresholdDirection | null;
    }> | null;
    deleteMilestones?: Array<{ __typename?: 'MilestoneDeleteData'; id: string }> | null;
    updateMilestones?: Array<{
      __typename?: 'MilestoneUpdateData';
      id: string;
      type?: MilestoneType | null;
      name?: string | null;
      value?: string | null;
      time?: string | null;
      thresholdDirection?: ThresholdDirection | null;
    }> | null;
    newSubmodels?: Array<{
      __typename?: 'SubmodelCreateData';
      id: string;
      name?: string | null;
      description?: string | null;
      sortedDriverGroupIds?: Array<string | null> | null;
    }> | null;
    updateSubmodels?: Array<{
      __typename?: 'SubmodelUpdateData';
      id: string;
      name?: string | null;
      description?: string | null;
      sortedDriverGroupIds?: Array<string | null> | null;
    }> | null;
    deleteSubmodels?: Array<{
      __typename?: 'SubmodelDeleteData';
      id: string;
      deleteDrivers?: boolean | null;
    }> | null;
    newEventGroups?: Array<{
      __typename?: 'EventGroupCreateData';
      id: string;
      name: string;
      description?: string | null;
      ownerName: string;
      ownerId: string;
      eventIds?: Array<string> | null;
      eventGroupIds?: Array<string> | null;
      parentId?: string | null;
      defaultEnd?: string | null;
      defaultStart?: string | null;
      sortIndex?: number | null;
    }> | null;
    deleteEventGroups?: Array<{ __typename?: 'EventGroupDeleteData'; id: string }> | null;
    updateEventGroups?: Array<{
      __typename?: 'EventGroupUpdateData';
      id: string;
      name?: string | null;
      description?: string | null;
      ownerName?: string | null;
      ownerId?: string | null;
      parentId?: string | null;
      eventIds?: Array<string> | null;
      eventGroupIds?: Array<string> | null;
      defaultEnd?: string | null;
      defaultStart?: string | null;
      hidden?: boolean | null;
      sortIndex?: number | null;
    }> | null;
    newLayers?: Array<{
      __typename?: 'LayerCreateData';
      id: string;
      name: string;
      description?: string | null;
      isDraft: boolean;
      parentLayerId?: string | null;
      userId?: string | null;
    }> | null;
    deleteLayers?: Array<{ __typename?: 'LayerDeleteData'; layerId: string }> | null;
    updateLayers?: Array<{
      __typename?: 'LayerUpdateData';
      layerId: string;
      name?: string | null;
      description?: string | null;
      isDraft?: boolean | null;
    }> | null;
    newExports?: Array<{
      __typename?: 'ExportCreateData';
      id: string;
      name: string;
      driverIds: Array<string>;
    }> | null;
    deleteExports?: Array<{ __typename?: 'ExportDeleteData'; id: string }> | null;
    updateExports?: Array<{
      __typename?: 'ExportUpdateData';
      id: string;
      name?: string | null;
      driverIds?: Array<string> | null;
    }> | null;
    newDriverGroups?: Array<{ __typename?: 'DriverGroupCreate'; id: string; name: string }> | null;
    renameDriverGroups?: Array<{
      __typename?: 'DriverGroupRename';
      id: string;
      name: string;
    }> | null;
    deleteDriverGroups?: Array<{ __typename?: 'DriverGroupDelete'; id: string }> | null;
    newBusinessObjectSpecs?: Array<{
      __typename?: 'BusinessObjectSpecCreateData';
      id: string;
      name: string;
      extSource?: string | null;
      extSpecKey?: string | null;
      extSpecKeys?: Array<string> | null;
      databaseConfigId?: string | null;
      startFieldId?: string | null;
      isRestricted?: boolean | null;
      fields: Array<{
        __typename?: 'BusinessObjectFieldSpec';
        id: string;
        name: string;
        type: ValueType;
        dimensionId?: string | null;
        numericFormat?: DriverFormat | null;
        currencyISOCode?: string | null;
        decimalPlaces?: number | null;
        extFieldSpecKey?: string | null;
        defaultForecast?: { __typename?: 'Forecast'; formula: string } | null;
        defaultValues?: Array<{
          __typename?: 'BusinessObjectFieldDefaultValueEntry';
          key: string;
          value: {
            __typename?: 'BusinessObjectFieldValue';
            type: ValueType;
            actuals?: {
              __typename?: 'Actuals';
              formula?: string | null;
              timeSeries?: Array<{
                __typename: 'TimeSeriesPoint';
                time: string;
                value: string;
              }> | null;
            } | null;
          };
        }> | null;
      }>;
      defaultNameEntries?: Array<{
        __typename?: 'BusinessObjectDefaultNameEntry';
        key: string;
        value: string;
      }> | null;
      collection?: {
        __typename?: 'CollectionCreateData';
        dimensionalProperties?: Array<{
          __typename?: 'DimensionalPropertyCreateData';
          id: string;
          name: string;
          dimensionId: string;
          isDatabaseKey?: boolean | null;
          extFieldSpecKey?: string | null;
          mapping?: {
            __typename?: 'DimensionalPropertyMapping';
            lookupSpecId: string;
            resultPropertyId: string;
            searchDimensionPropertyId?: string | null;
          } | null;
        }> | null;
        driverProperties?: Array<{
          __typename?: 'DriverPropertyCreateData';
          id: string;
          driverId: string;
        }> | null;
      } | null;
    }> | null;
    updateBusinessObjectSpecs?: Array<{
      __typename?: 'BusinessObjectSpecUpdateData';
      id: string;
      name?: string | null;
      isRestricted?: boolean | null;
      databaseConfigId?: string | null;
      deleteFields?: Array<string> | null;
      updateStartFieldId?: string | null;
      extSpecKey?: string | null;
      extSpecKeys?: Array<string> | null;
      deleteDefaultNameEntries?: Array<string> | null;
      addFields?: Array<{
        __typename?: 'BusinessObjectFieldSpecData';
        id: string;
        name: string;
        type: ValueType;
        dimensionId?: string | null;
        numericFormat?: DriverFormat | null;
        currencyISOCode?: string | null;
        decimalPlaces?: number | null;
        isRestricted?: boolean | null;
        extFieldSpecKey?: string | null;
        defaultForecast?: { __typename?: 'Forecast'; formula: string } | null;
      }> | null;
      updateFields?: Array<{
        __typename?: 'BusinessObjectFieldSpecUpdateData';
        id: string;
        type?: ValueType | null;
        name?: string | null;
        numericFormat?: DriverFormat | null;
        currencyISOCode?: string | null;
        decimalPlaces?: number | null;
        isRestricted?: boolean | null;
        extFieldSpecKey?: string | null;
        propagateIntegrationData?: boolean | null;
        integrationDataOverridesForecast?: boolean | null;
        dimensionId?: string | null;
        removeDefaultValues?: Array<string> | null;
        removeDefaultForecast?: boolean | null;
        defaultForecast?: { __typename?: 'Forecast'; formula: string } | null;
        setDefaultValues?: Array<{
          __typename?: 'BusinessObjectFieldDefaultValueEntry';
          key: string;
          value: {
            __typename?: 'BusinessObjectFieldValue';
            type: ValueType;
            actuals?: {
              __typename?: 'Actuals';
              formula?: string | null;
              timeSeries?: Array<{
                __typename: 'TimeSeriesPoint';
                time: string;
                value: string;
              }> | null;
            } | null;
          };
        }> | null;
      }> | null;
      setDefaultNameEntries?: Array<{
        __typename?: 'BusinessObjectDefaultNameEntry';
        key: string;
        value: string;
      }> | null;
      updateCollection?: {
        __typename?: 'CollectionData';
        removeDimensionalProperties?: Array<string> | null;
        removeDriverProperties?: Array<string> | null;
        addDimensionalProperties?: Array<{
          __typename?: 'DimensionalPropertyCreateData';
          id: string;
          name: string;
          dimensionId: string;
          isDatabaseKey?: boolean | null;
          extQueryId?: string | null;
        }> | null;
        updateDimensionalProperties?: Array<{
          __typename?: 'DimensionalPropertyUpdateData';
          id: string;
          name?: string | null;
          dimensionId?: string | null;
          removeMapping?: boolean | null;
          isDatabaseKey?: boolean | null;
          mapping?: {
            __typename?: 'DimensionalPropertyMapping';
            lookupSpecId: string;
            resultPropertyId: string;
            searchDimensionPropertyId?: string | null;
          } | null;
        }> | null;
        addDriverProperties?: Array<{
          __typename?: 'DriverPropertyCreateData';
          id: string;
          driverId: string;
          extQueryId?: string | null;
        }> | null;
      } | null;
    }> | null;
    deleteBusinessObjectSpecs?: Array<{
      __typename?: 'BusinessObjectSpecDeleteData';
      id: string;
    }> | null;
    newBusinessObjects?: Array<{
      __typename?: 'BusinessObjectCreateData';
      id: string;
      name: string;
      specId: string;
      defaultEventGroupId?: string | null;
      fields: Array<{
        __typename?: 'BusinessObjectFieldData';
        id: string;
        fieldSpecId: string;
        value: {
          __typename?: 'BusinessObjectFieldValue';
          type: ValueType;
          initialValue?: string | null;
          actuals?: {
            __typename?: 'Actuals';
            formula?: string | null;
            timeSeries?: Array<{
              __typename: 'TimeSeriesPoint';
              time: string;
              value: string;
            }> | null;
          } | null;
        };
      }>;
      collectionEntry?: {
        __typename?: 'CollectionEntryCreateData';
        attributeProperties?: Array<{
          __typename?: 'AttributePropertyCreateData';
          dimensionalPropertyId: string;
          attributeId: string;
        }> | null;
      } | null;
      metadata?: {
        __typename?: 'BusinessObjectMetadataData';
        remoteId?: string | null;
        extKey?: string | null;
        databaseConfigId?: string | null;
      } | null;
    }> | null;
    updateBusinessObjects?: Array<{
      __typename?: 'BusinessObjectUpdateData';
      id: string;
      name?: string | null;
      defaultEventGroupId?: string | null;
      deleteFields?: Array<string> | null;
      addFields?: Array<{
        __typename?: 'BusinessObjectFieldData';
        id: string;
        fieldSpecId: string;
        value: {
          __typename?: 'BusinessObjectFieldValue';
          type: ValueType;
          initialValue?: string | null;
          actuals?: {
            __typename?: 'Actuals';
            formula?: string | null;
            timeSeries?: Array<{
              __typename: 'TimeSeriesPoint';
              time: string;
              value: string;
            }> | null;
          } | null;
        };
      }> | null;
      fields?: Array<{
        __typename?: 'BusinessObjectFieldUpdateData';
        id: string;
        value?: {
          __typename?: 'BusinessObjectFieldValueWithNullableTimeseries';
          type: ValueType;
          initialValue?: string | null;
          actuals?: {
            __typename?: 'ActualsWithNullableTimeSeries';
            formula?: string | null;
            timeSeries?: Array<{
              __typename: 'NullableTimeSeriesPoint';
              time: string;
              value?: string | null;
            }> | null;
          } | null;
        } | null;
      }> | null;
      metadata?: {
        __typename?: 'BusinessObjectMetadataData';
        remoteId?: string | null;
        extKey?: string | null;
        databaseConfigId?: string | null;
      } | null;
      updateCollectionEntry?: {
        __typename?: 'CollectionEntryUpdateData';
        removeAttributeProperties?: Array<string> | null;
        addAttributeProperties?: Array<{
          __typename?: 'AttributePropertyCreateData';
          dimensionalPropertyId: string;
          attributeId: string;
        }> | null;
        updateAttributeProperties?: Array<{
          __typename?: 'AttributePropertyUpdateData';
          dimensionalPropertyId: string;
          attributeId?: string | null;
        }> | null;
      } | null;
    }> | null;
    deleteBusinessObjects?: Array<{ __typename?: 'BusinessObjectDeleteData'; id: string }> | null;
    newBlocksPages?: Array<{
      __typename?: 'BlocksPageCreateData';
      id: string;
      createdByUserId?: string | null;
      name: string;
      internalPageType?: string | null;
      internalPageVersion?: number | null;
      sortIndex?: number | null;
      parent?: string | null;
      dateRange?: { __typename?: 'DateRange'; start?: string | null; end?: string | null } | null;
      layout?: Array<{
        __typename?: 'BlockRow';
        id: string;
        columns: Array<{ __typename?: 'BlockColumn'; id: string; blockIds: Array<string> }>;
      }> | null;
    }> | null;
    updateBlocksPages?: Array<{
      __typename?: 'BlocksPageUpdateData';
      id: string;
      name?: string | null;
      sortIndex?: number | null;
      parent?: string | null;
      dateRange?: { __typename?: 'DateRange'; start?: string | null; end?: string | null } | null;
      layout?: Array<{
        __typename?: 'BlockRow';
        id: string;
        columns: Array<{ __typename?: 'BlockColumn'; id: string; blockIds: Array<string> }>;
      }> | null;
    }> | null;
    deleteBlocksPages?: Array<{ __typename?: 'BlocksPageDeleteData'; id: string }> | null;
    newFolders?: Array<{
      __typename?: 'FolderCreateData';
      id: string;
      name: string;
      type: FolderType;
      parent?: string | null;
      sortIndex?: number | null;
    }> | null;
    updateFolders?: Array<{
      __typename?: 'FolderUpdateData';
      id: string;
      name?: string | null;
      type?: FolderType | null;
      parent?: string | null;
      sortIndex?: number | null;
    }> | null;
    deleteFolders?: Array<{ __typename?: 'FolderDeleteData'; id: string }> | null;
    newBlocks?: Array<{
      __typename?: 'BlockCreateData';
      id: string;
      name: string;
      type: BlockType;
      pageId: string;
      sortIndex?: number | null;
      blockConfig: {
        __typename?: 'BlockConfig';
        idFilter?: Array<string> | null;
        textBlockBody?: string | null;
        videoBlockUrl?: string | null;
        imageBlockUrl?: string | null;
        mediaWidth?: number | null;
        blockAlignment?: BlockAlignment | null;
        hideHeader?: boolean | null;
        businessObjectSpecId?: string | null;
        showRestricted?: boolean | null;
        rollupTypes?: Array<RollupType> | null;
        rollupType?: RollupType | null;
        fiscalYearStartMonth?: number | null;
        objectFieldSpecAsTimeSeriesId?: string | null;
        objectSpecDisplayAs?: ObjectSpecDisplayAsType | null;
        expandedIds?: Array<string> | null;
        fileMetadata?: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
          type: string;
          path: string;
          size: number;
        } | null;
        blockViewOptions?: {
          __typename?: 'BlockViewOptions';
          showMilestones?: boolean | null;
          viewAsType?: BlockViewAsType | null;
          chartSize?: ChartSize | null;
          chartGroupingType?: ChartGroupingType | null;
          aggregateValues?: boolean | null;
          chartDisplay?: {
            __typename?: 'ChartDisplay';
            axes: Array<{
              __typename?: 'ChartAxis';
              id: string;
              position: ChartElementPosition;
              name?: string | null;
              showLabel?: boolean | null;
              type: ChartAxisType;
              time?: {
                __typename?: 'TimeAxisExtension';
                rollup: ChartRollupType;
                plotBy: ChartPlotByType;
              } | null;
              driver?: {
                __typename?: 'DriverAxisExtension';
                name?: string | null;
                groupIds: Array<string>;
                showTotals?: boolean | null;
                isNormalized?: boolean | null;
                format?: {
                  __typename?: 'DriverAxisFormat';
                  type: DriverFormat;
                  decimalPlaces?: number | null;
                  currencyISOCode?: string | null;
                } | null;
              } | null;
            }>;
            series: Array<{
              __typename?: 'ChartSeries';
              id: string;
              driverId: string;
              type: ChartSeriesType;
              color?: string | null;
              showLabels?: boolean | null;
            }>;
            groups: Array<{
              __typename?: 'ChartGroup';
              id: string;
              seriesIds: Array<string>;
              isDefault: boolean;
              layerId?: string | null;
              name?: string | null;
              showLabels?: boolean | null;
              dateRange?: {
                __typename?: 'DateRange';
                start?: string | null;
                end?: string | null;
              } | null;
            }>;
            legend?: {
              __typename?: 'ChartLegend';
              showLegend?: boolean | null;
              position?: ChartElementPosition | null;
              container?: {
                __typename?: 'LegendContainer';
                maxWidth?: number | null;
                maxHeight?: number | null;
              } | null;
              item?: {
                __typename?: 'LegendItem';
                maxWidth?: number | null;
                paddingX?: number | null;
                paddingY?: number | null;
              } | null;
            } | null;
          } | null;
          dateRangeComparison?: {
            __typename?: 'DateRangeComparison';
            type: DateRangeComparisonType;
            selectedPeriod: {
              __typename?: 'DateRange';
              start?: string | null;
              end?: string | null;
            };
            comparisonPeriod: {
              __typename?: 'DateRange';
              start?: string | null;
              end?: string | null;
            };
          } | null;
        } | null;
        sortBy?: {
          __typename?: 'BlockSortBy';
          sortType: BlockSortType;
          impact?: {
            __typename?: 'ImpactSortExtension';
            driverId: string;
            startMonthKey: string;
            endMonthKey: string;
          } | null;
          object?: {
            __typename?: 'ObjectSortExtension';
            ops: Array<{
              __typename?: 'ObjectSortOp';
              propertyId: string;
              order: ObjectSortOrder;
              manualOrdering?: Array<string> | null;
            }>;
          } | null;
        } | null;
        filterBy?: Array<{
          __typename?: 'BlockFilter';
          filterType: BlockFilterType;
          businessObjectSpecId?: string | null;
          expression?: string | null;
          filterKey?: string | null;
          filterValues?: Array<string> | null;
          filterOp?: BlockFilterOperator | null;
        }> | null;
        groupBy?: {
          __typename?: 'BlockGroupBy';
          groupByType: BlockGroupByType;
          driverField?: {
            __typename?: 'DriverFieldGroupByExtension';
            driverFieldSpecId: string;
            collapsedAttributeOptionIds?: Array<string> | null;
          } | null;
          objectField?: {
            __typename?: 'ObjectFieldGroupByExtension';
            businessObjectFieldId: string;
            collapsedAttributeIds?: Array<string> | null;
          } | null;
          driverProperty?: {
            __typename?: 'DriverPropertyGroupByExtension';
            driverPropertyId: string;
            collapsedAttributeIds?: Array<string> | null;
          } | null;
          date?: {
            __typename?: 'DateGroupByExtension';
            collapsedMonthKeys?: Array<string> | null;
          } | null;
        } | null;
        driverIndentations?: Array<{
          __typename?: 'DriverIndentation';
          level: number;
          driverId: string;
        }> | null;
        dateRange?: { __typename?: 'DateRange'; start?: string | null; end?: string | null } | null;
        viewAtTime?: {
          __typename?: 'TimePeriod';
          time?: string | null;
          dynamicDateType?: DynamicDate | null;
        } | null;
        comparisons?: {
          __typename?: 'BlockComparisons';
          layerIds: Array<string>;
          columns?: Array<ComparisonColumn> | null;
          layout?: BlockComparisonLayout | null;
          baselineLayerId?: string | null;
        } | null;
        columns?: Array<{
          __typename?: 'BlockColumnOptions';
          key: string;
          width?: number | null;
          visible?: boolean | null;
        }> | null;
        impactDisplay?: { __typename?: 'ImpactDisplay'; displayType: ImpactDisplayType } | null;
      };
    }> | null;
    updateBlocks?: Array<{
      __typename?: 'BlockUpdateData';
      id: string;
      name?: string | null;
      type?: BlockType | null;
      sortIndex?: number | null;
      blockConfig?: {
        __typename?: 'BlockConfig';
        idFilter?: Array<string> | null;
        textBlockBody?: string | null;
        videoBlockUrl?: string | null;
        imageBlockUrl?: string | null;
        mediaWidth?: number | null;
        blockAlignment?: BlockAlignment | null;
        hideHeader?: boolean | null;
        businessObjectSpecId?: string | null;
        showRestricted?: boolean | null;
        rollupTypes?: Array<RollupType> | null;
        rollupType?: RollupType | null;
        fiscalYearStartMonth?: number | null;
        objectFieldSpecAsTimeSeriesId?: string | null;
        objectSpecDisplayAs?: ObjectSpecDisplayAsType | null;
        expandedIds?: Array<string> | null;
        fileMetadata?: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
          type: string;
          path: string;
          size: number;
        } | null;
        blockViewOptions?: {
          __typename?: 'BlockViewOptions';
          showMilestones?: boolean | null;
          viewAsType?: BlockViewAsType | null;
          chartSize?: ChartSize | null;
          chartGroupingType?: ChartGroupingType | null;
          aggregateValues?: boolean | null;
          chartDisplay?: {
            __typename?: 'ChartDisplay';
            axes: Array<{
              __typename?: 'ChartAxis';
              id: string;
              position: ChartElementPosition;
              name?: string | null;
              showLabel?: boolean | null;
              type: ChartAxisType;
              time?: {
                __typename?: 'TimeAxisExtension';
                rollup: ChartRollupType;
                plotBy: ChartPlotByType;
              } | null;
              driver?: {
                __typename?: 'DriverAxisExtension';
                name?: string | null;
                groupIds: Array<string>;
                showTotals?: boolean | null;
                isNormalized?: boolean | null;
                format?: {
                  __typename?: 'DriverAxisFormat';
                  type: DriverFormat;
                  decimalPlaces?: number | null;
                  currencyISOCode?: string | null;
                } | null;
              } | null;
            }>;
            series: Array<{
              __typename?: 'ChartSeries';
              id: string;
              driverId: string;
              type: ChartSeriesType;
              color?: string | null;
              showLabels?: boolean | null;
            }>;
            groups: Array<{
              __typename?: 'ChartGroup';
              id: string;
              seriesIds: Array<string>;
              isDefault: boolean;
              layerId?: string | null;
              name?: string | null;
              showLabels?: boolean | null;
              dateRange?: {
                __typename?: 'DateRange';
                start?: string | null;
                end?: string | null;
              } | null;
            }>;
            legend?: {
              __typename?: 'ChartLegend';
              showLegend?: boolean | null;
              position?: ChartElementPosition | null;
              container?: {
                __typename?: 'LegendContainer';
                maxWidth?: number | null;
                maxHeight?: number | null;
              } | null;
              item?: {
                __typename?: 'LegendItem';
                maxWidth?: number | null;
                paddingX?: number | null;
                paddingY?: number | null;
              } | null;
            } | null;
          } | null;
          dateRangeComparison?: {
            __typename?: 'DateRangeComparison';
            type: DateRangeComparisonType;
            selectedPeriod: {
              __typename?: 'DateRange';
              start?: string | null;
              end?: string | null;
            };
            comparisonPeriod: {
              __typename?: 'DateRange';
              start?: string | null;
              end?: string | null;
            };
          } | null;
        } | null;
        sortBy?: {
          __typename?: 'BlockSortBy';
          sortType: BlockSortType;
          impact?: {
            __typename?: 'ImpactSortExtension';
            driverId: string;
            startMonthKey: string;
            endMonthKey: string;
          } | null;
          object?: {
            __typename?: 'ObjectSortExtension';
            ops: Array<{
              __typename?: 'ObjectSortOp';
              propertyId: string;
              order: ObjectSortOrder;
              manualOrdering?: Array<string> | null;
            }>;
          } | null;
        } | null;
        filterBy?: Array<{
          __typename?: 'BlockFilter';
          filterType: BlockFilterType;
          businessObjectSpecId?: string | null;
          expression?: string | null;
          filterKey?: string | null;
          filterValues?: Array<string> | null;
          filterOp?: BlockFilterOperator | null;
        }> | null;
        groupBy?: {
          __typename?: 'BlockGroupBy';
          groupByType: BlockGroupByType;
          driverField?: {
            __typename?: 'DriverFieldGroupByExtension';
            driverFieldSpecId: string;
            collapsedAttributeOptionIds?: Array<string> | null;
          } | null;
          objectField?: {
            __typename?: 'ObjectFieldGroupByExtension';
            businessObjectFieldId: string;
            collapsedAttributeIds?: Array<string> | null;
          } | null;
          driverProperty?: {
            __typename?: 'DriverPropertyGroupByExtension';
            driverPropertyId: string;
            collapsedAttributeIds?: Array<string> | null;
          } | null;
          date?: {
            __typename?: 'DateGroupByExtension';
            collapsedMonthKeys?: Array<string> | null;
          } | null;
        } | null;
        driverIndentations?: Array<{
          __typename?: 'DriverIndentation';
          level: number;
          driverId: string;
        }> | null;
        dateRange?: { __typename?: 'DateRange'; start?: string | null; end?: string | null } | null;
        viewAtTime?: {
          __typename?: 'TimePeriod';
          time?: string | null;
          dynamicDateType?: DynamicDate | null;
        } | null;
        comparisons?: {
          __typename?: 'BlockComparisons';
          layerIds: Array<string>;
          columns?: Array<ComparisonColumn> | null;
          layout?: BlockComparisonLayout | null;
          baselineLayerId?: string | null;
        } | null;
        columns?: Array<{
          __typename?: 'BlockColumnOptions';
          key: string;
          width?: number | null;
          visible?: boolean | null;
        }> | null;
        impactDisplay?: { __typename?: 'ImpactDisplay'; displayType: ImpactDisplayType } | null;
      } | null;
    }> | null;
    deleteBlocks?: Array<{ __typename?: 'BlockDeleteData'; id: string }> | null;
    createExtDrivers?: Array<{
      __typename?: 'ExtDriverCreateData';
      id: string;
      source: string;
      model: ExtModel;
      remoteId?: string | null;
      path: Array<string>;
      integrationQueryId?: string | null;
      accountId?: string | null;
      timeSeries?: Array<{ __typename: 'TimeSeriesPoint'; time: string; value: string }> | null;
      query?: {
        __typename?: 'TransactionFilterQuery';
        predicates: Array<{
          __typename?: 'TransactionFilterPredicate';
          type: TransactionFilterPredicateType;
          multiSelect?: {
            __typename?: 'TransactionFilterMultiSelectPredicate';
            field: TransactionField;
            operator: TransactionFilterMultiSelectOperator;
            values: Array<{
              __typename?: 'TransactionFieldValue';
              type: TransactionFieldValueType;
              stringValue?: string | null;
            }>;
          } | null;
          comparison?: {
            __typename?: 'TransactionFilterComparisonPredicate';
            field: TransactionField;
            operator: TransactionFilterComparisonOperator;
            value: {
              __typename?: 'TransactionFieldValue';
              type: TransactionFieldValueType;
              stringValue?: string | null;
            };
          } | null;
        }>;
      } | null;
    }> | null;
    deleteExtDrivers?: Array<{ __typename?: 'ExtDriverDeleteData'; id: string }> | null;
    updateExtDrivers?: Array<{
      __typename?: 'ExtDriverUpdateData';
      id: string;
      accountId?: string | null;
      timeSeries?: Array<{
        __typename: 'NullableTimeSeriesPoint';
        time: string;
        value?: string | null;
      }> | null;
      query?: {
        __typename?: 'TransactionFilterQuery';
        predicates: Array<{
          __typename?: 'TransactionFilterPredicate';
          type: TransactionFilterPredicateType;
          multiSelect?: {
            __typename?: 'TransactionFilterMultiSelectPredicate';
            field: TransactionField;
            operator: TransactionFilterMultiSelectOperator;
            values: Array<{
              __typename?: 'TransactionFieldValue';
              type: TransactionFieldValueType;
              stringValue?: string | null;
            }>;
          } | null;
          comparison?: {
            __typename?: 'TransactionFilterComparisonPredicate';
            field: TransactionField;
            operator: TransactionFilterComparisonOperator;
            value: {
              __typename?: 'TransactionFieldValue';
              type: TransactionFieldValueType;
              stringValue?: string | null;
            };
          } | null;
        }>;
      } | null;
    }> | null;
    createExtObjectSpecs?: Array<{
      __typename?: 'ExtObjectSpecCreateData';
      extKey: string;
      source: string;
      model: ExtModel;
      startFieldExtKey?: string | null;
      integrationQueryId?: string | null;
      propagateDataForward: boolean;
      overrideForecastData: boolean;
      fields: Array<{
        __typename?: 'ExtObjectFieldSpec';
        extKey: string;
        name: string;
        type: ValueType;
        dimensionId?: string | null;
        numericFormat?: DriverFormat | null;
      }>;
    }> | null;
    deleteExtObjectSpecs?: Array<{ __typename?: 'ExtObjectSpecDeleteData'; extKey: string }> | null;
    updateExtObjectSpecs?: Array<{
      __typename?: 'ExtObjectSpecUpdateData';
      extKey: string;
      propagateDataForward?: boolean | null;
      overrideForecastData?: boolean | null;
    }> | null;
    createExtObjects?: Array<{
      __typename?: 'ExtObjectCreateData';
      extKey: string;
      name: string;
      remoteId: string;
      extSpecKey: string;
      fields: Array<{
        __typename?: 'ExtObjectFieldCreateData';
        extFieldSpecKey: string;
        timeSeries: Array<{ __typename: 'TimeSeriesPoint'; time: string; value: string }>;
      }>;
    }> | null;
    deleteExtObjects?: Array<{ __typename?: 'ExtObjectDeleteData'; extKey: string }> | null;
    createIntegrationQueries?: Array<{
      __typename?: 'IntegrationQueryCreateData';
      id: string;
      name: string;
      source: string;
      enableExtTable?: boolean | null;
      createdAt: string;
      linkedAccountId: string;
      content: { __typename?: 'IntegrationQueryContent'; sqlQuery?: string | null };
    }> | null;
    updateIntegrationQueries?: Array<{
      __typename?: 'IntegrationQueryUpdateData';
      id: string;
      name?: string | null;
      isScheduled?: boolean | null;
      isScheduledExtTable?: boolean | null;
      lastSyncedAt?: string | null;
      enableExtTable?: boolean | null;
      linkedAccountId?: string | null;
      content?: { __typename?: 'IntegrationQueryContent'; sqlQuery?: string | null } | null;
    }> | null;
    deleteIntegrationQueries?: Array<{
      __typename?: 'IntegrationQueryDeleteData';
      id: string;
    }> | null;
    createNamedDatasetVersions?: Array<{
      __typename?: 'NamedDatasetVersionCreateData';
      mutationId: string;
      name: string;
    }> | null;
    updateNamedDatasetVersions?: Array<{
      __typename?: 'NamedDatasetVersionUpdateData';
      mutationId: string;
      name: string;
    }> | null;
    deleteNamedDatasetVersions?: Array<{
      __typename?: 'NamedDatasetVersionDeleteData';
      mutationId: string;
    }> | null;
    createExtTables?: Array<{
      __typename?: 'ExtTableCreateData';
      sourceKey: string;
      queryId?: string | null;
      schema: {
        __typename?: 'ExtTableSchema';
        version: number;
        columns: Array<{ __typename?: 'ExtTableColumn'; id: string }>;
      };
    }> | null;
    updateExtTables?: Array<{
      __typename?: 'ExtTableUpdateData';
      sourceKey: string;
      schema?: {
        __typename?: 'ExtTableSchema';
        version: number;
        columns: Array<{ __typename?: 'ExtTableColumn'; id: string }>;
      } | null;
    }> | null;
    deleteExtTables?: Array<{ __typename?: 'ExtTableDeleteData'; sourceKey: string }> | null;
    createExtTableRuns?: Array<{
      __typename?: 'ExtTableRunCreateData';
      sourceKey: string;
      schemaVersion: number;
      runId: string;
      dataMacro: {
        __typename?: 'DatasetMutationMacro';
        type: DatasetMutationMacroType;
        id: string;
      };
    }> | null;
    createDatabaseConfigs?: Array<{
      __typename?: 'DatabaseConfig';
      id: string;
      businessObjectSpecId: string;
      source: DatabaseConfigSourceType;
      refreshInfo?: {
        __typename?: 'DatabaseConfigRefreshInfo';
        updatedAt: string;
        status: DatabaseConfigRefreshStatus;
        error?: { __typename?: 'DatabaseConfigRefreshError'; reason?: string | null } | null;
      } | null;
      extTable?: {
        __typename?: 'DatabaseConfigForExtTableSource';
        extTableSourceKey: string;
        timeColumn?: { __typename?: 'TimeColumn'; extTableColumnId: string } | null;
        segmentedByColumns?: Array<{
          __typename: 'ExtTableDimensionColumn';
          dimensionId?: string | null;
          dimensionalPropertyId?: string | null;
          extQueryId?: string | null;
          extTableColumnId: string;
        }> | null;
        metadataColumns?: Array<{
          __typename: 'ExtTableDimensionColumn';
          dimensionId?: string | null;
          dimensionalPropertyId?: string | null;
          extQueryId?: string | null;
          extTableColumnId: string;
        }> | null;
        driverColumns?: Array<{
          __typename: 'ExtTableDriverColumn';
          driverPropertyId?: string | null;
          aggregationType: DatabaseConfigAggregationType;
          extQueryId?: string | null;
          extTableColumnId: string;
        }> | null;
        filters?: Array<{
          __typename: 'ExtTableFilter';
          comparator: ComparatorType;
          compareTo: string;
          extTableColumnId: string;
        }> | null;
      } | null;
      database?: {
        __typename?: 'DatabaseConfigForDatabaseSource';
        sourceBusinessObjectSpecId: string;
        driverColumns?: Array<{
          __typename: 'DatabaseDriverColumn';
          driverPropertyId?: string | null;
          aggregationType: DatabaseConfigAggregationType;
          sourceDriverPropertyId: string;
        }> | null;
        segmentedByColumns?: Array<{
          __typename: 'DatabaseDimensionColumn';
          dimensionalPropertyId?: string | null;
          sourceDimensionalPropertyId: string;
        }> | null;
        metadataColumns?: Array<{
          __typename: 'DatabaseDimensionColumn';
          dimensionalPropertyId?: string | null;
          sourceDimensionalPropertyId: string;
        }> | null;
        filters?: Array<{
          __typename: 'DatabaseFilter';
          comparator: ComparatorType;
          compareTo: Array<string>;
          dimensionalPropertyId: string;
        }> | null;
      } | null;
    }> | null;
    updateDatabaseConfigs?: Array<{
      __typename: 'DatabaseConfig';
      id: string;
      businessObjectSpecId: string;
      source: DatabaseConfigSourceType;
      refreshInfo?: {
        __typename?: 'DatabaseConfigRefreshInfo';
        updatedAt: string;
        status: DatabaseConfigRefreshStatus;
        error?: { __typename?: 'DatabaseConfigRefreshError'; reason?: string | null } | null;
      } | null;
      extTable?: {
        __typename: 'DatabaseConfigForExtTableSource';
        extTableSourceKey: string;
        timeColumn?: { __typename?: 'TimeColumn'; extTableColumnId: string } | null;
        segmentedByColumns?: Array<{
          __typename: 'ExtTableDimensionColumn';
          dimensionId?: string | null;
          dimensionalPropertyId?: string | null;
          extQueryId?: string | null;
          extTableColumnId: string;
        }> | null;
        metadataColumns?: Array<{
          __typename: 'ExtTableDimensionColumn';
          dimensionId?: string | null;
          dimensionalPropertyId?: string | null;
          extQueryId?: string | null;
          extTableColumnId: string;
        }> | null;
        driverColumns?: Array<{
          __typename: 'ExtTableDriverColumn';
          driverPropertyId?: string | null;
          aggregationType: DatabaseConfigAggregationType;
          extQueryId?: string | null;
          extTableColumnId: string;
        }> | null;
        filters?: Array<{
          __typename: 'ExtTableFilter';
          comparator: ComparatorType;
          compareTo: string;
          extTableColumnId: string;
        }> | null;
      } | null;
      database?: {
        __typename: 'DatabaseConfigForDatabaseSource';
        sourceBusinessObjectSpecId: string;
        driverColumns?: Array<{
          __typename: 'DatabaseDriverColumn';
          driverPropertyId?: string | null;
          aggregationType: DatabaseConfigAggregationType;
          sourceDriverPropertyId: string;
        }> | null;
        segmentedByColumns?: Array<{
          __typename: 'DatabaseDimensionColumn';
          dimensionalPropertyId?: string | null;
          sourceDimensionalPropertyId: string;
        }> | null;
        metadataColumns?: Array<{
          __typename: 'DatabaseDimensionColumn';
          dimensionalPropertyId?: string | null;
          sourceDimensionalPropertyId: string;
        }> | null;
        filters?: Array<{
          __typename: 'DatabaseFilter';
          comparator: ComparatorType;
          compareTo: Array<string>;
          dimensionalPropertyId: string;
        }> | null;
      } | null;
    }> | null;
    createExtQueries?: Array<{
      __typename?: 'ExtQueryCreateData';
      id: string;
      source: ExtQuerySource;
      raw: string;
    }> | null;
    updateExtQueries?: Array<{
      __typename?: 'ExtQueryUpdateData';
      id: string;
      source?: ExtQuerySource | null;
      raw?: string | null;
    }> | null;
    deleteExtQueries?: Array<{ __typename?: 'ExtQueryDeleteData'; id: string }> | null;
  };
};

export type BlockComparisonDatasetQueryVariables = Exact<{
  orgId: Scalars['String'];
  mutationId?: InputMaybe<Scalars['ID']>;
  layerId?: InputMaybe<Scalars['ID']>;
}>;

export type BlockComparisonDatasetQuery = {
  __typename?: 'Query';
  dataset?: {
    __typename?: 'Dataset';
    lastActualsTime?: string | null;
    drivers: Array<{
      __typename: 'Driver';
      id: string;
      name: string;
      valueType: ValueType;
      driverType: DriverType;
      groupId?: string | null;
      format: DriverFormat;
      submodelId?: string | null;
      sortIndex?: number | null;
      basic?: {
        __typename?: 'BasicDriverExtension';
        forecast: { __typename?: 'Forecast'; formula: string };
        actuals: { __typename?: 'Actuals'; formula?: string | null };
        rollup?: { __typename?: 'Rollup'; reducer: RollupReducer } | null;
      } | null;
      dimensional?: {
        __typename: 'DimensionalDriverExtension';
        builtInDimensions?: Array<BuiltInDimensionType> | null;
        defaultForecast?: { __typename?: 'DefaultFormula'; formula: string } | null;
        defaultActuals?: { __typename?: 'DefaultFormula'; formula: string } | null;
        dimensions?: Array<{
          __typename?: 'Dimension';
          id: string;
          name: string;
          deleted: boolean;
          color?: string | null;
          attributes: Array<{
            __typename?: 'Attribute';
            deleted: boolean;
            dimensionId: string;
            id: string;
            value: string;
          }>;
        }> | null;
        subDrivers: Array<{
          __typename?: 'DimensionalSubDriver';
          attributes?: Array<{
            __typename?: 'Attribute';
            id: string;
            dimensionId: string;
            value: string;
            deleted: boolean;
          }> | null;
          builtInAttributes?: Array<{
            __typename?: 'BuiltInAttributeData';
            type: BuiltInDimensionType;
            value: string;
          }> | null;
          driver: {
            __typename?: 'Driver';
            id: string;
            valueType: ValueType;
            driverType: DriverType;
            format: DriverFormat;
            name: string;
          };
          source?: {
            __typename?: 'DimensionalSubDriverSource';
            type: DimensionalSubDriverSourceType;
            extQueryId?: string | null;
          } | null;
        }>;
      } | null;
      driverMapping?: { __typename?: 'DriverMapping'; driverId: string } | null;
      driverReferences?: Array<{
        __typename?: 'DriverReference';
        blockId: string;
        sortIndex?: number | null;
        groupId?: string | null;
      }> | null;
    }>;
    businessObjectSpecs: Array<{
      __typename?: 'BusinessObjectSpec';
      id: string;
      name: string;
      extSource?: string | null;
      extSpecKey?: string | null;
      extSpecKeys?: Array<string> | null;
      startFieldId?: string | null;
      isRestricted?: boolean | null;
      fields?: Array<{
        __typename?: 'BusinessObjectFieldSpec';
        id: string;
        name: string;
        type: ValueType;
        dimensionId?: string | null;
        isRestricted?: boolean | null;
      }> | null;
      collection?: {
        __typename?: 'Collection';
        dimensionalProperties?: Array<{
          __typename?: 'DimensionalProperty';
          id: string;
          name: string;
          dimensionId: string;
          isDatabaseKey?: boolean | null;
          extQueryId?: string | null;
        }> | null;
        driverProperties?: Array<{
          __typename?: 'DriverProperty';
          id: string;
          driverId: string;
          extQueryId?: string | null;
        }> | null;
      } | null;
    }>;
  } | null;
};

export type DatasetQueryVariables = Exact<{
  orgId: Scalars['String'];
  mutationId?: InputMaybe<Scalars['ID']>;
  layerId?: InputMaybe<Scalars['ID']>;
}>;

export type DatasetQuery = {
  __typename?: 'Query';
  dataset?: {
    __typename?: 'Dataset';
    orgId: string;
    lastActualsTime?: string | null;
    layers: Array<{
      __typename: 'Layer';
      id: string;
      name: string;
      description?: string | null;
      isDraft: boolean;
      lockedMutationId?: string | null;
      parentLayerId?: string | null;
      createdByUserId?: string | null;
      createdAt: string;
    }>;
    dimensions?: Array<{ __typename?: 'Dimension'; id: string; name: string }> | null;
    metadata?: {
      __typename?: 'DatasetMetadata';
      lastMutationId?: string | null;
      lastUpdatedAt?: string | null;
    } | null;
    drivers: Array<{
      __typename: 'Driver';
      id: string;
      name: string;
      driverType: DriverType;
      groupId?: string | null;
      submodelId?: string | null;
      sortIndex?: number | null;
      driverReferences?: Array<{
        __typename?: 'DriverReference';
        blockId: string;
        sortIndex?: number | null;
        groupId?: string | null;
      }> | null;
      basic?: {
        __typename?: 'BasicDriverExtension';
        forecast: { __typename?: 'Forecast'; formula: string };
        actuals: { __typename?: 'Actuals'; formula?: string | null };
        rollup?: { __typename?: 'Rollup'; reducer: RollupReducer } | null;
      } | null;
      dimensional?: {
        __typename?: 'DimensionalDriverExtension';
        defaultForecast?: { __typename?: 'DefaultFormula'; formula: string } | null;
        defaultActuals?: { __typename?: 'DefaultFormula'; formula: string } | null;
      } | null;
      driverMapping?: { __typename?: 'DriverMapping'; driverId: string } | null;
    }>;
    eventGroups: Array<{
      __typename: 'EventGroup';
      id: string;
      name: string;
      parentId?: string | null;
      sortIndex?: number | null;
    }>;
    driverGroups: Array<{ __typename?: 'DriverGroup'; id: string; name: string }>;
    submodels: Array<{
      __typename?: 'Submodel';
      id: string;
      name?: string | null;
      description?: string | null;
      sortedDriverGroupIds?: Array<string | null> | null;
      sortIndex?: number | null;
    }>;
    businessObjectSpecs: Array<{
      __typename?: 'BusinessObjectSpec';
      id: string;
      name: string;
      extSource?: string | null;
      extSpecKey?: string | null;
      extSpecKeys?: Array<string> | null;
      startFieldId?: string | null;
      isRestricted?: boolean | null;
      fields?: Array<{
        __typename?: 'BusinessObjectFieldSpec';
        id: string;
        name: string;
        type: ValueType;
        dimensionId?: string | null;
        isRestricted?: boolean | null;
      }> | null;
      collection?: {
        __typename?: 'Collection';
        dimensionalProperties?: Array<{
          __typename?: 'DimensionalProperty';
          id: string;
          name: string;
          dimensionId: string;
          isDatabaseKey?: boolean | null;
          extQueryId?: string | null;
        }> | null;
        driverProperties?: Array<{
          __typename?: 'DriverProperty';
          id: string;
          driverId: string;
          extQueryId?: string | null;
        }> | null;
      } | null;
    }>;
    blocks: Array<{
      __typename?: 'Block';
      id: string;
      name: string;
      type: BlockType;
      pageId: string;
      sortIndex?: number | null;
      explanation?: {
        __typename?: 'BlockExplanation';
        driverGridComparisonExplanations: Array<{
          __typename?: 'BlockDriverGridComparisonExplanation';
          driverId: string;
          explanation: string;
        }>;
      } | null;
      blockConfig: {
        __typename?: 'BlockConfig';
        businessObjectSpecId?: string | null;
        blockViewOptions?: {
          __typename?: 'BlockViewOptions';
          viewAsType?: BlockViewAsType | null;
          chartSize?: ChartSize | null;
          chartGroupingType?: ChartGroupingType | null;
          dateRangeComparison?: {
            __typename?: 'DateRangeComparison';
            type: DateRangeComparisonType;
            selectedPeriod: {
              __typename?: 'DateRange';
              start?: string | null;
              end?: string | null;
            };
            comparisonPeriod: {
              __typename?: 'DateRange';
              start?: string | null;
              end?: string | null;
            };
          } | null;
        } | null;
        comparisons?: { __typename?: 'BlockComparisons'; layerIds: Array<string> } | null;
        dateRange?: { __typename?: 'DateRange'; start?: string | null; end?: string | null } | null;
        columns?: Array<{
          __typename?: 'BlockColumnOptions';
          key: string;
          width?: number | null;
          visible?: boolean | null;
        }> | null;
      };
    }>;
    blocksPages: Array<{
      __typename?: 'BlocksPage';
      id: string;
      createdByUserId?: string | null;
      name: string;
      blockIds: Array<string>;
      internalPageType?: string | null;
      internalPageVersion?: number | null;
      parent?: string | null;
      sortIndex?: number | null;
      dateRange?: { __typename?: 'DateRange'; start?: string | null; end?: string | null } | null;
      layout?: Array<{
        __typename?: 'BlockRow';
        id: string;
        columns: Array<{ __typename?: 'BlockColumn'; id: string; blockIds: Array<string> }>;
      }> | null;
    }>;
    folders: Array<{
      __typename?: 'Folder';
      id: string;
      name: string;
      type: FolderType;
      parent?: string | null;
      sortIndex?: number | null;
    }>;
    namedVersions: Array<{ __typename?: 'NamedDatasetVersion'; mutationId: string; name: string }>;
  } | null;
};

export type DatasetMutationHistoryQueryVariables = Exact<{
  orgId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  layerId?: InputMaybe<Scalars['String']>;
  includeUndo: Scalars['Boolean'];
  beforeMutationId?: InputMaybe<Scalars['ID']>;
}>;

export type DatasetMutationHistoryQuery = {
  __typename?: 'Query';
  datasetMutationHistory: Array<{
    __typename?: 'DatasetMutationData';
    id: string;
    layerId?: string | null;
    userId: string;
    createdAt: string;
    deletedAt?: string | null;
    changeId?: string | null;
    prevMutationId?: string | null;
    undoMutationId?: string | null;
    commitLayerId?: string | null;
    updateLastActualsTime?: string | null;
    newDimensions?: Array<{
      __typename?: 'DimensionCreateData';
      id: string;
      name: string;
      color?: string | null;
      attributes?: Array<{
        __typename?: 'AttributeCreateData';
        dimensionId: string;
        id: string;
        value: string;
      }> | null;
    }> | null;
    updateDimensions?: Array<{
      __typename?: 'DimensionUpdateData';
      id: string;
      name?: string | null;
      color?: string | null;
      newAttributes?: Array<{
        __typename?: 'AttributeCreateData';
        id: string;
        dimensionId: string;
        value: string;
      }> | null;
      updateAttributes?: Array<{
        __typename?: 'AttributeUpdateData';
        id: string;
        value?: string | null;
      }> | null;
      deleteAttributes?: Array<{ __typename?: 'AttributeDeleteData'; id: string }> | null;
      restoreAttributes?: Array<{ __typename?: 'AttributeRestoreData'; id: string }> | null;
    }> | null;
    deleteDimensions?: Array<{ __typename?: 'DimensionDeleteData'; id: string }> | null;
    restoreDimensions?: Array<{ __typename?: 'DimensionRestoreData'; id: string }> | null;
    newDrivers?: Array<{
      __typename?: 'DriverCreateData';
      id: string;
      name: string;
      format: DriverFormat;
      driverType: DriverType;
      submodelId?: string | null;
      valueType: ValueType;
      leverType?: LeverType | null;
      groupId?: string | null;
      description?: string | null;
      sortIndex?: number | null;
      currencyISOCode?: string | null;
      decimalPlaces?: number | null;
      driverReferences?: Array<{
        __typename?: 'DriverReferenceData';
        blockId: string;
        sortIndex?: number | null;
        groupId?: string | null;
      }> | null;
      coloring?: {
        __typename?: 'ColoringData';
        row?: string | null;
        cells?: Array<{ __typename?: 'TimeSeriesPoint'; time: string; value: string }> | null;
      } | null;
      driverMapping?: { __typename?: 'DriverMapping'; driverId: string } | null;
      basic?: {
        __typename?: 'BasicDriverCreateData';
        forecast: { __typename?: 'Forecast'; formula: string };
        actuals: {
          __typename?: 'Actuals';
          formula?: string | null;
          timeSeries?: Array<{ __typename: 'TimeSeriesPoint'; time: string; value: string }> | null;
        };
        rollup?: { __typename?: 'Rollup'; reducer: RollupReducer } | null;
      } | null;
      dimensional?: {
        __typename?: 'DimensionalDriverCreateData';
        dimensionIds?: Array<string> | null;
        builtInDimensions?: Array<BuiltInDimensionType> | null;
        subDrivers?: Array<{
          __typename?: 'DimensionalSubDriverCreateData';
          attributeIds?: Array<string> | null;
          existingDriverId?: string | null;
          builtInAttributes?: Array<{
            __typename?: 'BuiltInAttributeData';
            type: BuiltInDimensionType;
            value: string;
          }> | null;
          source?: {
            __typename?: 'DimensionalSubDriverSourceData';
            type: DimensionalSubDriverSourceType;
            extQueryId?: string | null;
          } | null;
          driver?: {
            __typename?: 'DriverCreateData';
            id: string;
            name: string;
            format: DriverFormat;
            valueType: ValueType;
            driverType: DriverType;
            submodelId?: string | null;
            leverType?: LeverType | null;
            groupId?: string | null;
            description?: string | null;
            sortIndex?: number | null;
            currencyISOCode?: string | null;
            decimalPlaces?: number | null;
            driverReferences?: Array<{
              __typename?: 'DriverReferenceData';
              blockId: string;
              sortIndex?: number | null;
              groupId?: string | null;
            }> | null;
            coloring?: {
              __typename?: 'ColoringData';
              row?: string | null;
              cells?: Array<{ __typename?: 'TimeSeriesPoint'; time: string; value: string }> | null;
            } | null;
            basic?: {
              __typename?: 'BasicDriverCreateData';
              forecast: { __typename?: 'Forecast'; formula: string };
              actuals: {
                __typename?: 'Actuals';
                formula?: string | null;
                timeSeries?: Array<{
                  __typename: 'TimeSeriesPoint';
                  time: string;
                  value: string;
                }> | null;
              };
              rollup?: { __typename?: 'Rollup'; reducer: RollupReducer } | null;
            } | null;
          } | null;
        }> | null;
        defaultForecast?: { __typename?: 'DefaultFormula'; formula: string } | null;
        defaultActuals?: { __typename?: 'DefaultFormula'; formula: string } | null;
      } | null;
    }> | null;
    updateDrivers?: Array<{
      __typename?: 'DriverUpdateData';
      id: string;
      name?: string | null;
      description?: string | null;
      format?: DriverFormat | null;
      submodelId?: string | null;
      removeSubmodelId?: boolean | null;
      removeDriverMapping?: boolean | null;
      removeAllDriverReferences?: boolean | null;
      leverType?: LeverType | null;
      sortIndex?: number | null;
      currencyISOCode?: string | null;
      decimalPlaces?: number | null;
      groupId?: string | null;
      actuals?: {
        __typename?: 'ActualsWithNullableTimeSeries';
        formula?: string | null;
        timeSeries?: Array<{
          __typename: 'NullableTimeSeriesPoint';
          time: string;
          value?: string | null;
        }> | null;
      } | null;
      forecast?: { __typename?: 'Forecast'; formula: string } | null;
      rollup?: { __typename?: 'Rollup'; reducer: RollupReducer } | null;
      driverReferences?: Array<{
        __typename?: 'DriverReferenceData';
        blockId: string;
        sortIndex?: number | null;
        groupId?: string | null;
      }> | null;
      driverMapping?: { __typename?: 'DriverMapping'; driverId: string } | null;
      coloring?: {
        __typename?: 'ColoringData';
        row?: string | null;
        cells?: Array<{ __typename?: 'TimeSeriesPoint'; time: string; value: string }> | null;
      } | null;
      newSubDrivers?: Array<{
        __typename?: 'DimensionalSubDriverCreateData';
        attributeIds?: Array<string> | null;
        existingDriverId?: string | null;
        builtInAttributes?: Array<{
          __typename?: 'BuiltInAttributeData';
          type: BuiltInDimensionType;
          value: string;
        }> | null;
        source?: {
          __typename?: 'DimensionalSubDriverSourceData';
          type: DimensionalSubDriverSourceType;
          extQueryId?: string | null;
        } | null;
        driver?: {
          __typename?: 'DriverCreateData';
          id: string;
          name: string;
          format: DriverFormat;
          valueType: ValueType;
          driverType: DriverType;
          submodelId?: string | null;
          leverType?: LeverType | null;
          groupId?: string | null;
          description?: string | null;
          sortIndex?: number | null;
          currencyISOCode?: string | null;
          decimalPlaces?: number | null;
          driverReferences?: Array<{
            __typename?: 'DriverReferenceData';
            blockId: string;
            sortIndex?: number | null;
            groupId?: string | null;
          }> | null;
          coloring?: {
            __typename?: 'ColoringData';
            row?: string | null;
            cells?: Array<{ __typename?: 'TimeSeriesPoint'; time: string; value: string }> | null;
          } | null;
          basic?: {
            __typename?: 'BasicDriverCreateData';
            forecast: { __typename?: 'Forecast'; formula: string };
            actuals: {
              __typename?: 'Actuals';
              formula?: string | null;
              timeSeries?: Array<{
                __typename: 'TimeSeriesPoint';
                time: string;
                value: string;
              }> | null;
            };
            rollup?: { __typename?: 'Rollup'; reducer: RollupReducer } | null;
          } | null;
        } | null;
      }> | null;
      dimensional?: {
        __typename?: 'DimensionalDriverUpdateData';
        dimensionIds?: Array<string> | null;
        builtInDimensions?: Array<BuiltInDimensionType> | null;
        defaultForecast?: { __typename?: 'DefaultFormula'; formula: string } | null;
        defaultActuals?: { __typename?: 'DefaultFormula'; formula: string } | null;
        updateSubDrivers?: Array<{
          __typename?: 'DimensionalSubDriverUpdateData';
          attributeIds?: Array<string> | null;
          newAttributeIds?: Array<string> | null;
          removeAllAttributes?: boolean | null;
          unlink?: boolean | null;
          builtInAttributes?: Array<{
            __typename?: 'BuiltInAttributeData';
            type: BuiltInDimensionType;
            value: string;
          }> | null;
          source?: {
            __typename?: 'DimensionalSubDriverSourceData';
            type: DimensionalSubDriverSourceType;
            extQueryId?: string | null;
          } | null;
          newBuiltInAttributes?: Array<{
            __typename?: 'BuiltInAttributeData';
            type: BuiltInDimensionType;
            value: string;
          }> | null;
        }> | null;
        deleteSubDrivers?: Array<{
          __typename?: 'DimensionalSubDriverDeleteData';
          attributeIds?: Array<string> | null;
          builtInAttributes?: Array<{
            __typename?: 'BuiltInAttributeData';
            type: BuiltInDimensionType;
            value: string;
          }> | null;
        }> | null;
      } | null;
    }> | null;
    deleteDrivers?: Array<{ __typename?: 'DriverDeleteData'; id: string }> | null;
    newEvents?: Array<{
      __typename?: 'EventCreateData';
      id: string;
      name?: string | null;
      description?: string | null;
      ownerName: string;
      driverId?: string | null;
      businessObjectFieldId?: string | null;
      parentId?: string | null;
      start: string;
      end: string;
      modifierType?: ModifierType | null;
      curveType?: CurveType | null;
      sortIndex?: number | null;
      totalImpact?: string | null;
      impactType?: ImpactType | null;
      setValue?: string | null;
      setValueType?: ValueType | null;
      time?: string | null;
      value?: string | null;
      customCurvePoints?: Array<{
        __typename?: 'TimeSeriesPoint';
        time: string;
        value: string;
      }> | null;
    }> | null;
    deleteEvents?: Array<{ __typename?: 'EventDeleteData'; id: string }> | null;
    updateEvents?: Array<{
      __typename?: 'EventUpdateData';
      id: string;
      ownerName?: string | null;
      driverId?: string | null;
      businessObjectFieldId?: string | null;
      parentId?: string | null;
      name?: string | null;
      description?: string | null;
      start?: string | null;
      end?: string | null;
      curveType?: CurveType | null;
      sortIndex?: number | null;
      totalImpact?: string | null;
      impactType?: ImpactType | null;
      setValue?: string | null;
      setValueType?: ValueType | null;
      hidden?: boolean | null;
      time?: string | null;
      value?: string | null;
      customCurvePoints?: Array<{
        __typename?: 'NullableTimeSeriesPoint';
        time: string;
        value?: string | null;
      }> | null;
    }> | null;
    newMilestones?: Array<{
      __typename?: 'MilestoneCreateData';
      id: string;
      type?: MilestoneType | null;
      name?: string | null;
      driverId: string;
      value: string;
      time?: string | null;
      thresholdDirection?: ThresholdDirection | null;
    }> | null;
    deleteMilestones?: Array<{ __typename?: 'MilestoneDeleteData'; id: string }> | null;
    updateMilestones?: Array<{
      __typename?: 'MilestoneUpdateData';
      id: string;
      type?: MilestoneType | null;
      name?: string | null;
      value?: string | null;
      time?: string | null;
      thresholdDirection?: ThresholdDirection | null;
    }> | null;
    newSubmodels?: Array<{
      __typename?: 'SubmodelCreateData';
      id: string;
      name?: string | null;
      description?: string | null;
      sortedDriverGroupIds?: Array<string | null> | null;
    }> | null;
    updateSubmodels?: Array<{
      __typename?: 'SubmodelUpdateData';
      id: string;
      name?: string | null;
      description?: string | null;
      sortedDriverGroupIds?: Array<string | null> | null;
    }> | null;
    deleteSubmodels?: Array<{
      __typename?: 'SubmodelDeleteData';
      id: string;
      deleteDrivers?: boolean | null;
    }> | null;
    newEventGroups?: Array<{
      __typename?: 'EventGroupCreateData';
      id: string;
      name: string;
      description?: string | null;
      ownerName: string;
      ownerId: string;
      eventIds?: Array<string> | null;
      eventGroupIds?: Array<string> | null;
      parentId?: string | null;
      defaultEnd?: string | null;
      defaultStart?: string | null;
      sortIndex?: number | null;
    }> | null;
    deleteEventGroups?: Array<{ __typename?: 'EventGroupDeleteData'; id: string }> | null;
    updateEventGroups?: Array<{
      __typename?: 'EventGroupUpdateData';
      id: string;
      name?: string | null;
      description?: string | null;
      ownerName?: string | null;
      ownerId?: string | null;
      parentId?: string | null;
      eventIds?: Array<string> | null;
      eventGroupIds?: Array<string> | null;
      defaultEnd?: string | null;
      defaultStart?: string | null;
      hidden?: boolean | null;
      sortIndex?: number | null;
    }> | null;
    newLayers?: Array<{
      __typename?: 'LayerCreateData';
      id: string;
      name: string;
      description?: string | null;
      isDraft: boolean;
      parentLayerId?: string | null;
      userId?: string | null;
    }> | null;
    deleteLayers?: Array<{ __typename?: 'LayerDeleteData'; layerId: string }> | null;
    updateLayers?: Array<{
      __typename?: 'LayerUpdateData';
      layerId: string;
      name?: string | null;
      description?: string | null;
      isDraft?: boolean | null;
    }> | null;
    newExports?: Array<{
      __typename?: 'ExportCreateData';
      id: string;
      name: string;
      driverIds: Array<string>;
    }> | null;
    deleteExports?: Array<{ __typename?: 'ExportDeleteData'; id: string }> | null;
    updateExports?: Array<{
      __typename?: 'ExportUpdateData';
      id: string;
      name?: string | null;
      driverIds?: Array<string> | null;
    }> | null;
    newDriverGroups?: Array<{ __typename?: 'DriverGroupCreate'; id: string; name: string }> | null;
    renameDriverGroups?: Array<{
      __typename?: 'DriverGroupRename';
      id: string;
      name: string;
    }> | null;
    deleteDriverGroups?: Array<{ __typename?: 'DriverGroupDelete'; id: string }> | null;
    newBusinessObjectSpecs?: Array<{
      __typename?: 'BusinessObjectSpecCreateData';
      id: string;
      name: string;
      extSource?: string | null;
      extSpecKey?: string | null;
      extSpecKeys?: Array<string> | null;
      databaseConfigId?: string | null;
      startFieldId?: string | null;
      isRestricted?: boolean | null;
      fields: Array<{
        __typename?: 'BusinessObjectFieldSpec';
        id: string;
        name: string;
        type: ValueType;
        dimensionId?: string | null;
        numericFormat?: DriverFormat | null;
        currencyISOCode?: string | null;
        decimalPlaces?: number | null;
        extFieldSpecKey?: string | null;
        defaultForecast?: { __typename?: 'Forecast'; formula: string } | null;
        defaultValues?: Array<{
          __typename?: 'BusinessObjectFieldDefaultValueEntry';
          key: string;
          value: {
            __typename?: 'BusinessObjectFieldValue';
            type: ValueType;
            actuals?: {
              __typename?: 'Actuals';
              formula?: string | null;
              timeSeries?: Array<{
                __typename: 'TimeSeriesPoint';
                time: string;
                value: string;
              }> | null;
            } | null;
          };
        }> | null;
      }>;
      defaultNameEntries?: Array<{
        __typename?: 'BusinessObjectDefaultNameEntry';
        key: string;
        value: string;
      }> | null;
      collection?: {
        __typename?: 'CollectionCreateData';
        dimensionalProperties?: Array<{
          __typename?: 'DimensionalPropertyCreateData';
          id: string;
          name: string;
          dimensionId: string;
          isDatabaseKey?: boolean | null;
          extFieldSpecKey?: string | null;
          mapping?: {
            __typename?: 'DimensionalPropertyMapping';
            lookupSpecId: string;
            resultPropertyId: string;
            searchDimensionPropertyId?: string | null;
          } | null;
        }> | null;
        driverProperties?: Array<{
          __typename?: 'DriverPropertyCreateData';
          id: string;
          driverId: string;
        }> | null;
      } | null;
    }> | null;
    updateBusinessObjectSpecs?: Array<{
      __typename?: 'BusinessObjectSpecUpdateData';
      id: string;
      name?: string | null;
      isRestricted?: boolean | null;
      databaseConfigId?: string | null;
      deleteFields?: Array<string> | null;
      updateStartFieldId?: string | null;
      extSpecKey?: string | null;
      extSpecKeys?: Array<string> | null;
      deleteDefaultNameEntries?: Array<string> | null;
      addFields?: Array<{
        __typename?: 'BusinessObjectFieldSpecData';
        id: string;
        name: string;
        type: ValueType;
        dimensionId?: string | null;
        numericFormat?: DriverFormat | null;
        currencyISOCode?: string | null;
        decimalPlaces?: number | null;
        isRestricted?: boolean | null;
        extFieldSpecKey?: string | null;
        defaultForecast?: { __typename?: 'Forecast'; formula: string } | null;
      }> | null;
      updateFields?: Array<{
        __typename?: 'BusinessObjectFieldSpecUpdateData';
        id: string;
        type?: ValueType | null;
        name?: string | null;
        numericFormat?: DriverFormat | null;
        currencyISOCode?: string | null;
        decimalPlaces?: number | null;
        isRestricted?: boolean | null;
        extFieldSpecKey?: string | null;
        propagateIntegrationData?: boolean | null;
        integrationDataOverridesForecast?: boolean | null;
        dimensionId?: string | null;
        removeDefaultValues?: Array<string> | null;
        removeDefaultForecast?: boolean | null;
        defaultForecast?: { __typename?: 'Forecast'; formula: string } | null;
        setDefaultValues?: Array<{
          __typename?: 'BusinessObjectFieldDefaultValueEntry';
          key: string;
          value: {
            __typename?: 'BusinessObjectFieldValue';
            type: ValueType;
            actuals?: {
              __typename?: 'Actuals';
              formula?: string | null;
              timeSeries?: Array<{
                __typename: 'TimeSeriesPoint';
                time: string;
                value: string;
              }> | null;
            } | null;
          };
        }> | null;
      }> | null;
      setDefaultNameEntries?: Array<{
        __typename?: 'BusinessObjectDefaultNameEntry';
        key: string;
        value: string;
      }> | null;
      updateCollection?: {
        __typename?: 'CollectionData';
        removeDimensionalProperties?: Array<string> | null;
        removeDriverProperties?: Array<string> | null;
        addDimensionalProperties?: Array<{
          __typename?: 'DimensionalPropertyCreateData';
          id: string;
          name: string;
          dimensionId: string;
          isDatabaseKey?: boolean | null;
          extQueryId?: string | null;
        }> | null;
        updateDimensionalProperties?: Array<{
          __typename?: 'DimensionalPropertyUpdateData';
          id: string;
          name?: string | null;
          dimensionId?: string | null;
          removeMapping?: boolean | null;
          isDatabaseKey?: boolean | null;
          mapping?: {
            __typename?: 'DimensionalPropertyMapping';
            lookupSpecId: string;
            resultPropertyId: string;
            searchDimensionPropertyId?: string | null;
          } | null;
        }> | null;
        addDriverProperties?: Array<{
          __typename?: 'DriverPropertyCreateData';
          id: string;
          driverId: string;
          extQueryId?: string | null;
        }> | null;
      } | null;
    }> | null;
    deleteBusinessObjectSpecs?: Array<{
      __typename?: 'BusinessObjectSpecDeleteData';
      id: string;
    }> | null;
    newBusinessObjects?: Array<{
      __typename?: 'BusinessObjectCreateData';
      id: string;
      name: string;
      specId: string;
      defaultEventGroupId?: string | null;
      fields: Array<{
        __typename?: 'BusinessObjectFieldData';
        id: string;
        fieldSpecId: string;
        value: {
          __typename?: 'BusinessObjectFieldValue';
          type: ValueType;
          initialValue?: string | null;
          actuals?: {
            __typename?: 'Actuals';
            formula?: string | null;
            timeSeries?: Array<{
              __typename: 'TimeSeriesPoint';
              time: string;
              value: string;
            }> | null;
          } | null;
        };
      }>;
      collectionEntry?: {
        __typename?: 'CollectionEntryCreateData';
        attributeProperties?: Array<{
          __typename?: 'AttributePropertyCreateData';
          dimensionalPropertyId: string;
          attributeId: string;
        }> | null;
      } | null;
      metadata?: {
        __typename?: 'BusinessObjectMetadataData';
        remoteId?: string | null;
        extKey?: string | null;
        databaseConfigId?: string | null;
      } | null;
    }> | null;
    updateBusinessObjects?: Array<{
      __typename?: 'BusinessObjectUpdateData';
      id: string;
      name?: string | null;
      defaultEventGroupId?: string | null;
      deleteFields?: Array<string> | null;
      addFields?: Array<{
        __typename?: 'BusinessObjectFieldData';
        id: string;
        fieldSpecId: string;
        value: {
          __typename?: 'BusinessObjectFieldValue';
          type: ValueType;
          initialValue?: string | null;
          actuals?: {
            __typename?: 'Actuals';
            formula?: string | null;
            timeSeries?: Array<{
              __typename: 'TimeSeriesPoint';
              time: string;
              value: string;
            }> | null;
          } | null;
        };
      }> | null;
      fields?: Array<{
        __typename?: 'BusinessObjectFieldUpdateData';
        id: string;
        value?: {
          __typename?: 'BusinessObjectFieldValueWithNullableTimeseries';
          type: ValueType;
          initialValue?: string | null;
          actuals?: {
            __typename?: 'ActualsWithNullableTimeSeries';
            formula?: string | null;
            timeSeries?: Array<{
              __typename: 'NullableTimeSeriesPoint';
              time: string;
              value?: string | null;
            }> | null;
          } | null;
        } | null;
      }> | null;
      metadata?: {
        __typename?: 'BusinessObjectMetadataData';
        remoteId?: string | null;
        extKey?: string | null;
        databaseConfigId?: string | null;
      } | null;
      updateCollectionEntry?: {
        __typename?: 'CollectionEntryUpdateData';
        removeAttributeProperties?: Array<string> | null;
        addAttributeProperties?: Array<{
          __typename?: 'AttributePropertyCreateData';
          dimensionalPropertyId: string;
          attributeId: string;
        }> | null;
        updateAttributeProperties?: Array<{
          __typename?: 'AttributePropertyUpdateData';
          dimensionalPropertyId: string;
          attributeId?: string | null;
        }> | null;
      } | null;
    }> | null;
    deleteBusinessObjects?: Array<{ __typename?: 'BusinessObjectDeleteData'; id: string }> | null;
    newBlocksPages?: Array<{
      __typename?: 'BlocksPageCreateData';
      id: string;
      createdByUserId?: string | null;
      name: string;
      internalPageType?: string | null;
      internalPageVersion?: number | null;
      sortIndex?: number | null;
      parent?: string | null;
      dateRange?: { __typename?: 'DateRange'; start?: string | null; end?: string | null } | null;
      layout?: Array<{
        __typename?: 'BlockRow';
        id: string;
        columns: Array<{ __typename?: 'BlockColumn'; id: string; blockIds: Array<string> }>;
      }> | null;
    }> | null;
    updateBlocksPages?: Array<{
      __typename?: 'BlocksPageUpdateData';
      id: string;
      name?: string | null;
      sortIndex?: number | null;
      parent?: string | null;
      dateRange?: { __typename?: 'DateRange'; start?: string | null; end?: string | null } | null;
      layout?: Array<{
        __typename?: 'BlockRow';
        id: string;
        columns: Array<{ __typename?: 'BlockColumn'; id: string; blockIds: Array<string> }>;
      }> | null;
    }> | null;
    deleteBlocksPages?: Array<{ __typename?: 'BlocksPageDeleteData'; id: string }> | null;
    newFolders?: Array<{
      __typename?: 'FolderCreateData';
      id: string;
      name: string;
      type: FolderType;
      parent?: string | null;
      sortIndex?: number | null;
    }> | null;
    updateFolders?: Array<{
      __typename?: 'FolderUpdateData';
      id: string;
      name?: string | null;
      type?: FolderType | null;
      parent?: string | null;
      sortIndex?: number | null;
    }> | null;
    deleteFolders?: Array<{ __typename?: 'FolderDeleteData'; id: string }> | null;
    newBlocks?: Array<{
      __typename?: 'BlockCreateData';
      id: string;
      name: string;
      type: BlockType;
      pageId: string;
      sortIndex?: number | null;
      blockConfig: {
        __typename?: 'BlockConfig';
        idFilter?: Array<string> | null;
        textBlockBody?: string | null;
        videoBlockUrl?: string | null;
        imageBlockUrl?: string | null;
        mediaWidth?: number | null;
        blockAlignment?: BlockAlignment | null;
        hideHeader?: boolean | null;
        businessObjectSpecId?: string | null;
        showRestricted?: boolean | null;
        rollupTypes?: Array<RollupType> | null;
        rollupType?: RollupType | null;
        fiscalYearStartMonth?: number | null;
        objectFieldSpecAsTimeSeriesId?: string | null;
        objectSpecDisplayAs?: ObjectSpecDisplayAsType | null;
        expandedIds?: Array<string> | null;
        fileMetadata?: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
          type: string;
          path: string;
          size: number;
        } | null;
        blockViewOptions?: {
          __typename?: 'BlockViewOptions';
          showMilestones?: boolean | null;
          viewAsType?: BlockViewAsType | null;
          chartSize?: ChartSize | null;
          chartGroupingType?: ChartGroupingType | null;
          aggregateValues?: boolean | null;
          chartDisplay?: {
            __typename?: 'ChartDisplay';
            axes: Array<{
              __typename?: 'ChartAxis';
              id: string;
              position: ChartElementPosition;
              name?: string | null;
              showLabel?: boolean | null;
              type: ChartAxisType;
              time?: {
                __typename?: 'TimeAxisExtension';
                rollup: ChartRollupType;
                plotBy: ChartPlotByType;
              } | null;
              driver?: {
                __typename?: 'DriverAxisExtension';
                name?: string | null;
                groupIds: Array<string>;
                showTotals?: boolean | null;
                isNormalized?: boolean | null;
                format?: {
                  __typename?: 'DriverAxisFormat';
                  type: DriverFormat;
                  decimalPlaces?: number | null;
                  currencyISOCode?: string | null;
                } | null;
              } | null;
            }>;
            series: Array<{
              __typename?: 'ChartSeries';
              id: string;
              driverId: string;
              type: ChartSeriesType;
              color?: string | null;
              showLabels?: boolean | null;
            }>;
            groups: Array<{
              __typename?: 'ChartGroup';
              id: string;
              seriesIds: Array<string>;
              isDefault: boolean;
              layerId?: string | null;
              name?: string | null;
              showLabels?: boolean | null;
              dateRange?: {
                __typename?: 'DateRange';
                start?: string | null;
                end?: string | null;
              } | null;
            }>;
            legend?: {
              __typename?: 'ChartLegend';
              showLegend?: boolean | null;
              position?: ChartElementPosition | null;
              container?: {
                __typename?: 'LegendContainer';
                maxWidth?: number | null;
                maxHeight?: number | null;
              } | null;
              item?: {
                __typename?: 'LegendItem';
                maxWidth?: number | null;
                paddingX?: number | null;
                paddingY?: number | null;
              } | null;
            } | null;
          } | null;
          dateRangeComparison?: {
            __typename?: 'DateRangeComparison';
            type: DateRangeComparisonType;
            selectedPeriod: {
              __typename?: 'DateRange';
              start?: string | null;
              end?: string | null;
            };
            comparisonPeriod: {
              __typename?: 'DateRange';
              start?: string | null;
              end?: string | null;
            };
          } | null;
        } | null;
        sortBy?: {
          __typename?: 'BlockSortBy';
          sortType: BlockSortType;
          impact?: {
            __typename?: 'ImpactSortExtension';
            driverId: string;
            startMonthKey: string;
            endMonthKey: string;
          } | null;
          object?: {
            __typename?: 'ObjectSortExtension';
            ops: Array<{
              __typename?: 'ObjectSortOp';
              propertyId: string;
              order: ObjectSortOrder;
              manualOrdering?: Array<string> | null;
            }>;
          } | null;
        } | null;
        filterBy?: Array<{
          __typename?: 'BlockFilter';
          filterType: BlockFilterType;
          businessObjectSpecId?: string | null;
          expression?: string | null;
          filterKey?: string | null;
          filterValues?: Array<string> | null;
          filterOp?: BlockFilterOperator | null;
        }> | null;
        groupBy?: {
          __typename?: 'BlockGroupBy';
          groupByType: BlockGroupByType;
          driverField?: {
            __typename?: 'DriverFieldGroupByExtension';
            driverFieldSpecId: string;
            collapsedAttributeOptionIds?: Array<string> | null;
          } | null;
          objectField?: {
            __typename?: 'ObjectFieldGroupByExtension';
            businessObjectFieldId: string;
            collapsedAttributeIds?: Array<string> | null;
          } | null;
          driverProperty?: {
            __typename?: 'DriverPropertyGroupByExtension';
            driverPropertyId: string;
            collapsedAttributeIds?: Array<string> | null;
          } | null;
          date?: {
            __typename?: 'DateGroupByExtension';
            collapsedMonthKeys?: Array<string> | null;
          } | null;
        } | null;
        driverIndentations?: Array<{
          __typename?: 'DriverIndentation';
          level: number;
          driverId: string;
        }> | null;
        dateRange?: { __typename?: 'DateRange'; start?: string | null; end?: string | null } | null;
        viewAtTime?: {
          __typename?: 'TimePeriod';
          time?: string | null;
          dynamicDateType?: DynamicDate | null;
        } | null;
        comparisons?: {
          __typename?: 'BlockComparisons';
          layerIds: Array<string>;
          columns?: Array<ComparisonColumn> | null;
          layout?: BlockComparisonLayout | null;
          baselineLayerId?: string | null;
        } | null;
        columns?: Array<{
          __typename?: 'BlockColumnOptions';
          key: string;
          width?: number | null;
          visible?: boolean | null;
        }> | null;
        impactDisplay?: { __typename?: 'ImpactDisplay'; displayType: ImpactDisplayType } | null;
      };
    }> | null;
    updateBlocks?: Array<{
      __typename?: 'BlockUpdateData';
      id: string;
      name?: string | null;
      type?: BlockType | null;
      sortIndex?: number | null;
      blockConfig?: {
        __typename?: 'BlockConfig';
        idFilter?: Array<string> | null;
        textBlockBody?: string | null;
        videoBlockUrl?: string | null;
        imageBlockUrl?: string | null;
        mediaWidth?: number | null;
        blockAlignment?: BlockAlignment | null;
        hideHeader?: boolean | null;
        businessObjectSpecId?: string | null;
        showRestricted?: boolean | null;
        rollupTypes?: Array<RollupType> | null;
        rollupType?: RollupType | null;
        fiscalYearStartMonth?: number | null;
        objectFieldSpecAsTimeSeriesId?: string | null;
        objectSpecDisplayAs?: ObjectSpecDisplayAsType | null;
        expandedIds?: Array<string> | null;
        fileMetadata?: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
          type: string;
          path: string;
          size: number;
        } | null;
        blockViewOptions?: {
          __typename?: 'BlockViewOptions';
          showMilestones?: boolean | null;
          viewAsType?: BlockViewAsType | null;
          chartSize?: ChartSize | null;
          chartGroupingType?: ChartGroupingType | null;
          aggregateValues?: boolean | null;
          chartDisplay?: {
            __typename?: 'ChartDisplay';
            axes: Array<{
              __typename?: 'ChartAxis';
              id: string;
              position: ChartElementPosition;
              name?: string | null;
              showLabel?: boolean | null;
              type: ChartAxisType;
              time?: {
                __typename?: 'TimeAxisExtension';
                rollup: ChartRollupType;
                plotBy: ChartPlotByType;
              } | null;
              driver?: {
                __typename?: 'DriverAxisExtension';
                name?: string | null;
                groupIds: Array<string>;
                showTotals?: boolean | null;
                isNormalized?: boolean | null;
                format?: {
                  __typename?: 'DriverAxisFormat';
                  type: DriverFormat;
                  decimalPlaces?: number | null;
                  currencyISOCode?: string | null;
                } | null;
              } | null;
            }>;
            series: Array<{
              __typename?: 'ChartSeries';
              id: string;
              driverId: string;
              type: ChartSeriesType;
              color?: string | null;
              showLabels?: boolean | null;
            }>;
            groups: Array<{
              __typename?: 'ChartGroup';
              id: string;
              seriesIds: Array<string>;
              isDefault: boolean;
              layerId?: string | null;
              name?: string | null;
              showLabels?: boolean | null;
              dateRange?: {
                __typename?: 'DateRange';
                start?: string | null;
                end?: string | null;
              } | null;
            }>;
            legend?: {
              __typename?: 'ChartLegend';
              showLegend?: boolean | null;
              position?: ChartElementPosition | null;
              container?: {
                __typename?: 'LegendContainer';
                maxWidth?: number | null;
                maxHeight?: number | null;
              } | null;
              item?: {
                __typename?: 'LegendItem';
                maxWidth?: number | null;
                paddingX?: number | null;
                paddingY?: number | null;
              } | null;
            } | null;
          } | null;
          dateRangeComparison?: {
            __typename?: 'DateRangeComparison';
            type: DateRangeComparisonType;
            selectedPeriod: {
              __typename?: 'DateRange';
              start?: string | null;
              end?: string | null;
            };
            comparisonPeriod: {
              __typename?: 'DateRange';
              start?: string | null;
              end?: string | null;
            };
          } | null;
        } | null;
        sortBy?: {
          __typename?: 'BlockSortBy';
          sortType: BlockSortType;
          impact?: {
            __typename?: 'ImpactSortExtension';
            driverId: string;
            startMonthKey: string;
            endMonthKey: string;
          } | null;
          object?: {
            __typename?: 'ObjectSortExtension';
            ops: Array<{
              __typename?: 'ObjectSortOp';
              propertyId: string;
              order: ObjectSortOrder;
              manualOrdering?: Array<string> | null;
            }>;
          } | null;
        } | null;
        filterBy?: Array<{
          __typename?: 'BlockFilter';
          filterType: BlockFilterType;
          businessObjectSpecId?: string | null;
          expression?: string | null;
          filterKey?: string | null;
          filterValues?: Array<string> | null;
          filterOp?: BlockFilterOperator | null;
        }> | null;
        groupBy?: {
          __typename?: 'BlockGroupBy';
          groupByType: BlockGroupByType;
          driverField?: {
            __typename?: 'DriverFieldGroupByExtension';
            driverFieldSpecId: string;
            collapsedAttributeOptionIds?: Array<string> | null;
          } | null;
          objectField?: {
            __typename?: 'ObjectFieldGroupByExtension';
            businessObjectFieldId: string;
            collapsedAttributeIds?: Array<string> | null;
          } | null;
          driverProperty?: {
            __typename?: 'DriverPropertyGroupByExtension';
            driverPropertyId: string;
            collapsedAttributeIds?: Array<string> | null;
          } | null;
          date?: {
            __typename?: 'DateGroupByExtension';
            collapsedMonthKeys?: Array<string> | null;
          } | null;
        } | null;
        driverIndentations?: Array<{
          __typename?: 'DriverIndentation';
          level: number;
          driverId: string;
        }> | null;
        dateRange?: { __typename?: 'DateRange'; start?: string | null; end?: string | null } | null;
        viewAtTime?: {
          __typename?: 'TimePeriod';
          time?: string | null;
          dynamicDateType?: DynamicDate | null;
        } | null;
        comparisons?: {
          __typename?: 'BlockComparisons';
          layerIds: Array<string>;
          columns?: Array<ComparisonColumn> | null;
          layout?: BlockComparisonLayout | null;
          baselineLayerId?: string | null;
        } | null;
        columns?: Array<{
          __typename?: 'BlockColumnOptions';
          key: string;
          width?: number | null;
          visible?: boolean | null;
        }> | null;
        impactDisplay?: { __typename?: 'ImpactDisplay'; displayType: ImpactDisplayType } | null;
      } | null;
    }> | null;
    deleteBlocks?: Array<{ __typename?: 'BlockDeleteData'; id: string }> | null;
    createExtDrivers?: Array<{
      __typename?: 'ExtDriverCreateData';
      id: string;
      source: string;
      model: ExtModel;
      remoteId?: string | null;
      path: Array<string>;
      integrationQueryId?: string | null;
      accountId?: string | null;
      timeSeries?: Array<{ __typename: 'TimeSeriesPoint'; time: string; value: string }> | null;
      query?: {
        __typename?: 'TransactionFilterQuery';
        predicates: Array<{
          __typename?: 'TransactionFilterPredicate';
          type: TransactionFilterPredicateType;
          multiSelect?: {
            __typename?: 'TransactionFilterMultiSelectPredicate';
            field: TransactionField;
            operator: TransactionFilterMultiSelectOperator;
            values: Array<{
              __typename?: 'TransactionFieldValue';
              type: TransactionFieldValueType;
              stringValue?: string | null;
            }>;
          } | null;
          comparison?: {
            __typename?: 'TransactionFilterComparisonPredicate';
            field: TransactionField;
            operator: TransactionFilterComparisonOperator;
            value: {
              __typename?: 'TransactionFieldValue';
              type: TransactionFieldValueType;
              stringValue?: string | null;
            };
          } | null;
        }>;
      } | null;
    }> | null;
    deleteExtDrivers?: Array<{ __typename?: 'ExtDriverDeleteData'; id: string }> | null;
    updateExtDrivers?: Array<{
      __typename?: 'ExtDriverUpdateData';
      id: string;
      accountId?: string | null;
      timeSeries?: Array<{
        __typename: 'NullableTimeSeriesPoint';
        time: string;
        value?: string | null;
      }> | null;
      query?: {
        __typename?: 'TransactionFilterQuery';
        predicates: Array<{
          __typename?: 'TransactionFilterPredicate';
          type: TransactionFilterPredicateType;
          multiSelect?: {
            __typename?: 'TransactionFilterMultiSelectPredicate';
            field: TransactionField;
            operator: TransactionFilterMultiSelectOperator;
            values: Array<{
              __typename?: 'TransactionFieldValue';
              type: TransactionFieldValueType;
              stringValue?: string | null;
            }>;
          } | null;
          comparison?: {
            __typename?: 'TransactionFilterComparisonPredicate';
            field: TransactionField;
            operator: TransactionFilterComparisonOperator;
            value: {
              __typename?: 'TransactionFieldValue';
              type: TransactionFieldValueType;
              stringValue?: string | null;
            };
          } | null;
        }>;
      } | null;
    }> | null;
    createExtObjectSpecs?: Array<{
      __typename?: 'ExtObjectSpecCreateData';
      extKey: string;
      source: string;
      model: ExtModel;
      startFieldExtKey?: string | null;
      integrationQueryId?: string | null;
      propagateDataForward: boolean;
      overrideForecastData: boolean;
      fields: Array<{
        __typename?: 'ExtObjectFieldSpec';
        extKey: string;
        name: string;
        type: ValueType;
        dimensionId?: string | null;
        numericFormat?: DriverFormat | null;
      }>;
    }> | null;
    deleteExtObjectSpecs?: Array<{ __typename?: 'ExtObjectSpecDeleteData'; extKey: string }> | null;
    updateExtObjectSpecs?: Array<{
      __typename?: 'ExtObjectSpecUpdateData';
      extKey: string;
      propagateDataForward?: boolean | null;
      overrideForecastData?: boolean | null;
    }> | null;
    createExtObjects?: Array<{
      __typename?: 'ExtObjectCreateData';
      extKey: string;
      name: string;
      remoteId: string;
      extSpecKey: string;
      fields: Array<{
        __typename?: 'ExtObjectFieldCreateData';
        extFieldSpecKey: string;
        timeSeries: Array<{ __typename: 'TimeSeriesPoint'; time: string; value: string }>;
      }>;
    }> | null;
    deleteExtObjects?: Array<{ __typename?: 'ExtObjectDeleteData'; extKey: string }> | null;
    createIntegrationQueries?: Array<{
      __typename?: 'IntegrationQueryCreateData';
      id: string;
      name: string;
      source: string;
      enableExtTable?: boolean | null;
      createdAt: string;
      linkedAccountId: string;
      content: { __typename?: 'IntegrationQueryContent'; sqlQuery?: string | null };
    }> | null;
    updateIntegrationQueries?: Array<{
      __typename?: 'IntegrationQueryUpdateData';
      id: string;
      name?: string | null;
      isScheduled?: boolean | null;
      isScheduledExtTable?: boolean | null;
      lastSyncedAt?: string | null;
      enableExtTable?: boolean | null;
      linkedAccountId?: string | null;
      content?: { __typename?: 'IntegrationQueryContent'; sqlQuery?: string | null } | null;
    }> | null;
    deleteIntegrationQueries?: Array<{
      __typename?: 'IntegrationQueryDeleteData';
      id: string;
    }> | null;
    createNamedDatasetVersions?: Array<{
      __typename?: 'NamedDatasetVersionCreateData';
      mutationId: string;
      name: string;
    }> | null;
    updateNamedDatasetVersions?: Array<{
      __typename?: 'NamedDatasetVersionUpdateData';
      mutationId: string;
      name: string;
    }> | null;
    deleteNamedDatasetVersions?: Array<{
      __typename?: 'NamedDatasetVersionDeleteData';
      mutationId: string;
    }> | null;
    createExtTables?: Array<{
      __typename?: 'ExtTableCreateData';
      sourceKey: string;
      queryId?: string | null;
      schema: {
        __typename?: 'ExtTableSchema';
        version: number;
        columns: Array<{ __typename?: 'ExtTableColumn'; id: string }>;
      };
    }> | null;
    updateExtTables?: Array<{
      __typename?: 'ExtTableUpdateData';
      sourceKey: string;
      schema?: {
        __typename?: 'ExtTableSchema';
        version: number;
        columns: Array<{ __typename?: 'ExtTableColumn'; id: string }>;
      } | null;
    }> | null;
    deleteExtTables?: Array<{ __typename?: 'ExtTableDeleteData'; sourceKey: string }> | null;
    createExtTableRuns?: Array<{
      __typename?: 'ExtTableRunCreateData';
      sourceKey: string;
      schemaVersion: number;
      runId: string;
      dataMacro: {
        __typename?: 'DatasetMutationMacro';
        type: DatasetMutationMacroType;
        id: string;
      };
    }> | null;
    createDatabaseConfigs?: Array<{
      __typename?: 'DatabaseConfig';
      id: string;
      businessObjectSpecId: string;
      source: DatabaseConfigSourceType;
      refreshInfo?: {
        __typename?: 'DatabaseConfigRefreshInfo';
        updatedAt: string;
        status: DatabaseConfigRefreshStatus;
        error?: { __typename?: 'DatabaseConfigRefreshError'; reason?: string | null } | null;
      } | null;
      extTable?: {
        __typename?: 'DatabaseConfigForExtTableSource';
        extTableSourceKey: string;
        timeColumn?: { __typename?: 'TimeColumn'; extTableColumnId: string } | null;
        segmentedByColumns?: Array<{
          __typename: 'ExtTableDimensionColumn';
          dimensionId?: string | null;
          dimensionalPropertyId?: string | null;
          extQueryId?: string | null;
          extTableColumnId: string;
        }> | null;
        metadataColumns?: Array<{
          __typename: 'ExtTableDimensionColumn';
          dimensionId?: string | null;
          dimensionalPropertyId?: string | null;
          extQueryId?: string | null;
          extTableColumnId: string;
        }> | null;
        driverColumns?: Array<{
          __typename: 'ExtTableDriverColumn';
          driverPropertyId?: string | null;
          aggregationType: DatabaseConfigAggregationType;
          extQueryId?: string | null;
          extTableColumnId: string;
        }> | null;
        filters?: Array<{
          __typename: 'ExtTableFilter';
          comparator: ComparatorType;
          compareTo: string;
          extTableColumnId: string;
        }> | null;
      } | null;
      database?: {
        __typename?: 'DatabaseConfigForDatabaseSource';
        sourceBusinessObjectSpecId: string;
        driverColumns?: Array<{
          __typename: 'DatabaseDriverColumn';
          driverPropertyId?: string | null;
          aggregationType: DatabaseConfigAggregationType;
          sourceDriverPropertyId: string;
        }> | null;
        segmentedByColumns?: Array<{
          __typename: 'DatabaseDimensionColumn';
          dimensionalPropertyId?: string | null;
          sourceDimensionalPropertyId: string;
        }> | null;
        metadataColumns?: Array<{
          __typename: 'DatabaseDimensionColumn';
          dimensionalPropertyId?: string | null;
          sourceDimensionalPropertyId: string;
        }> | null;
        filters?: Array<{
          __typename: 'DatabaseFilter';
          comparator: ComparatorType;
          compareTo: Array<string>;
          dimensionalPropertyId: string;
        }> | null;
      } | null;
    }> | null;
    updateDatabaseConfigs?: Array<{
      __typename: 'DatabaseConfig';
      id: string;
      businessObjectSpecId: string;
      source: DatabaseConfigSourceType;
      refreshInfo?: {
        __typename?: 'DatabaseConfigRefreshInfo';
        updatedAt: string;
        status: DatabaseConfigRefreshStatus;
        error?: { __typename?: 'DatabaseConfigRefreshError'; reason?: string | null } | null;
      } | null;
      extTable?: {
        __typename: 'DatabaseConfigForExtTableSource';
        extTableSourceKey: string;
        timeColumn?: { __typename?: 'TimeColumn'; extTableColumnId: string } | null;
        segmentedByColumns?: Array<{
          __typename: 'ExtTableDimensionColumn';
          dimensionId?: string | null;
          dimensionalPropertyId?: string | null;
          extQueryId?: string | null;
          extTableColumnId: string;
        }> | null;
        metadataColumns?: Array<{
          __typename: 'ExtTableDimensionColumn';
          dimensionId?: string | null;
          dimensionalPropertyId?: string | null;
          extQueryId?: string | null;
          extTableColumnId: string;
        }> | null;
        driverColumns?: Array<{
          __typename: 'ExtTableDriverColumn';
          driverPropertyId?: string | null;
          aggregationType: DatabaseConfigAggregationType;
          extQueryId?: string | null;
          extTableColumnId: string;
        }> | null;
        filters?: Array<{
          __typename: 'ExtTableFilter';
          comparator: ComparatorType;
          compareTo: string;
          extTableColumnId: string;
        }> | null;
      } | null;
      database?: {
        __typename: 'DatabaseConfigForDatabaseSource';
        sourceBusinessObjectSpecId: string;
        driverColumns?: Array<{
          __typename: 'DatabaseDriverColumn';
          driverPropertyId?: string | null;
          aggregationType: DatabaseConfigAggregationType;
          sourceDriverPropertyId: string;
        }> | null;
        segmentedByColumns?: Array<{
          __typename: 'DatabaseDimensionColumn';
          dimensionalPropertyId?: string | null;
          sourceDimensionalPropertyId: string;
        }> | null;
        metadataColumns?: Array<{
          __typename: 'DatabaseDimensionColumn';
          dimensionalPropertyId?: string | null;
          sourceDimensionalPropertyId: string;
        }> | null;
        filters?: Array<{
          __typename: 'DatabaseFilter';
          comparator: ComparatorType;
          compareTo: Array<string>;
          dimensionalPropertyId: string;
        }> | null;
      } | null;
    }> | null;
    createExtQueries?: Array<{
      __typename?: 'ExtQueryCreateData';
      id: string;
      source: ExtQuerySource;
      raw: string;
    }> | null;
    updateExtQueries?: Array<{
      __typename?: 'ExtQueryUpdateData';
      id: string;
      source?: ExtQuerySource | null;
      raw?: string | null;
    }> | null;
    deleteExtQueries?: Array<{ __typename?: 'ExtQueryDeleteData'; id: string }> | null;
  }>;
};

export type SnapshotForExtTablesQueryVariables = Exact<{
  orgId: Scalars['String'];
  sourceKeys: Array<Scalars['String']> | Scalars['String'];
}>;

export type SnapshotForExtTablesQuery = {
  __typename?: 'Query';
  snapshotForExtTables: Array<{
    __typename?: 'ExtTableSnapshotEntry';
    sourceKey: string;
    snapshot: {
      __typename?: 'ExtTableSnapshot';
      scopedRunIds: Array<string>;
      schema: {
        __typename?: 'ExtTableSchema';
        version: number;
        columns: Array<{ __typename?: 'ExtTableColumn'; id: string }>;
      };
      data: {
        __typename?: 'SqlQueryResult';
        rows: Array<{
          __typename?: 'SqlQueryResultRow';
          columnValues: Array<{
            __typename?: 'SQLColumnValueTuple';
            column: string;
            value: string;
          }>;
        } | null>;
      };
    };
  }>;
};

export type UpdateOrgSettingsMutationVariables = Exact<{
  orgId: Scalars['ID'];
  update: GeneralSettingsUpdateInput;
}>;

export type UpdateOrgSettingsMutation = {
  __typename?: 'Mutation';
  updateOrgSettings?: { __typename?: 'BasicResponse'; success: boolean } | null;
};

export type CreateMergeLinkTokenMutationVariables = Exact<{
  orgId: Scalars['ID'];
  integrationSlug: Scalars['String'];
  linkedAccountId?: InputMaybe<Scalars['String']>;
  category: IntegrationCategory;
}>;

export type CreateMergeLinkTokenMutation = {
  __typename?: 'Mutation';
  createMergeLinkToken?: {
    __typename?: 'MergeLinkTokenResponse';
    token: string;
    endUserOriginId: string;
  } | null;
};

export type CreateFiveTranConnectorMutationVariables = Exact<{
  orgId: Scalars['ID'];
  integrationSlug: Scalars['String'];
  schema?: InputMaybe<Scalars['String']>;
  schemaPrefix?: InputMaybe<Scalars['String']>;
  table?: InputMaybe<Scalars['String']>;
}>;

export type CreateFiveTranConnectorMutation = {
  __typename?: 'Mutation';
  createFiveTranConnector: {
    __typename?: 'FiveTranCreateConnectorResponse';
    connectorCardURI: string;
  };
};

export type ExchangeMergePublicTokenMutationVariables = Exact<{
  orgId: Scalars['ID'];
  publicToken: Scalars['String'];
  endUserOriginId: Scalars['String'];
}>;

export type ExchangeMergePublicTokenMutation = {
  __typename?: 'Mutation';
  exchangeMergePublicToken: { __typename?: 'BasicResponse'; success: boolean };
};

export type DeleteAllMergeLinkedAccountsForIntegrationMutationVariables = Exact<{
  orgId: Scalars['ID'];
  integrationSlug: Scalars['String'];
  category: IntegrationCategory;
}>;

export type DeleteAllMergeLinkedAccountsForIntegrationMutation = {
  __typename?: 'Mutation';
  deleteAllMergeLinkedAccountsForIntegration: { __typename?: 'BasicResponse'; success: boolean };
};

export type RemoveIntegrationMutationVariables = Exact<{
  orgId: Scalars['ID'];
  provider: IntegrationProvider;
  linkedAccountId: Scalars['String'];
}>;

export type RemoveIntegrationMutation = {
  __typename?: 'Mutation';
  removeIntegration: { __typename?: 'BasicResponse'; success: boolean };
};

export type CancelIntegrationQueryRunMutationVariables = Exact<{
  orgId: Scalars['ID'];
  queryId: Scalars['String'];
  runId: Scalars['String'];
}>;

export type CancelIntegrationQueryRunMutation = {
  __typename?: 'Mutation';
  cancelIntegrationQueryRun: { __typename?: 'BasicResponse'; success: boolean };
};

export type DeleteMergeLinkedAccountMutationVariables = Exact<{
  orgId: Scalars['ID'];
  endUserOriginId: Scalars['String'];
}>;

export type DeleteMergeLinkedAccountMutation = {
  __typename?: 'Mutation';
  deleteMergeLinkedAccount: { __typename?: 'BasicResponse'; success: boolean };
};

export type DeleteWorkatoLinkedAccountMutationVariables = Exact<{
  orgId: Scalars['ID'];
  linkedAccountId: Scalars['String'];
}>;

export type DeleteWorkatoLinkedAccountMutation = {
  __typename?: 'Mutation';
  deleteWorkatoLinkedAccount: { __typename?: 'BasicResponse'; success: boolean };
};

export type DeleteFiveTranConnectorMutationVariables = Exact<{
  orgId: Scalars['ID'];
  connectorId: Scalars['String'];
}>;

export type DeleteFiveTranConnectorMutation = {
  __typename?: 'Mutation';
  deleteFiveTranConnector: { __typename?: 'BasicResponse'; success: boolean };
};

export type ForceResyncMutationVariables = Exact<{
  orgId: Scalars['ID'];
  provider: IntegrationProvider;
  linkedAccountId: Scalars['String'];
}>;

export type ForceResyncMutation = {
  __typename?: 'Mutation';
  forceResync: { __typename?: 'BasicResponse'; success: boolean };
};

export type IntegrationFragmentFragment = {
  __typename?: 'Integration';
  name: string;
  slug: string;
  provider: IntegrationProvider;
  image?: string | null;
  categories: Array<IntegrationCategory>;
  linkParams?: {
    __typename?: 'LinkAccountParams';
    currencyISOCode?: string | null;
    workato?: { __typename?: 'WorkatoLinkAccountParams'; connectionId: string } | null;
    fiveTran?: {
      __typename?: 'FiveTranLinkAccountParams';
      requiredFields: Array<FiveTranConfigField>;
      isLiteConnector: boolean;
      serviceStatus: FiveTranServiceStatus;
      docsLink: string;
      schemaInfo: string;
    } | null;
  } | null;
};

export type LinkedAccountFragmentFragment = {
  __typename?: 'LinkedAccount';
  id: string;
  category: IntegrationCategory;
  status: IntegrationStatus;
  lastSyncedAt?: string | null;
  alias?: string | null;
  propagateDataForward: boolean;
  overrideForecastData: boolean;
  integration: {
    __typename?: 'Integration';
    name: string;
    slug: string;
    provider: IntegrationProvider;
    image?: string | null;
    categories: Array<IntegrationCategory>;
    linkParams?: {
      __typename?: 'LinkAccountParams';
      currencyISOCode?: string | null;
      workato?: { __typename?: 'WorkatoLinkAccountParams'; connectionId: string } | null;
      fiveTran?: {
        __typename?: 'FiveTranLinkAccountParams';
        requiredFields: Array<FiveTranConfigField>;
        isLiteConnector: boolean;
        serviceStatus: FiveTranServiceStatus;
        docsLink: string;
        schemaInfo: string;
      } | null;
    } | null;
  };
};

export type AllIntegrationsQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;

export type AllIntegrationsQuery = {
  __typename?: 'Query';
  integrations?: {
    __typename?: 'Integrations';
    linkedAccounts: Array<{
      __typename?: 'LinkedAccount';
      id: string;
      category: IntegrationCategory;
      status: IntegrationStatus;
      lastSyncedAt?: string | null;
      alias?: string | null;
      propagateDataForward: boolean;
      overrideForecastData: boolean;
      integration: {
        __typename?: 'Integration';
        name: string;
        slug: string;
        provider: IntegrationProvider;
        image?: string | null;
        categories: Array<IntegrationCategory>;
        linkParams?: {
          __typename?: 'LinkAccountParams';
          currencyISOCode?: string | null;
          workato?: { __typename?: 'WorkatoLinkAccountParams'; connectionId: string } | null;
          fiveTran?: {
            __typename?: 'FiveTranLinkAccountParams';
            requiredFields: Array<FiveTranConfigField>;
            isLiteConnector: boolean;
            serviceStatus: FiveTranServiceStatus;
            docsLink: string;
            schemaInfo: string;
          } | null;
        } | null;
      };
    }>;
    availableIntegrations: Array<{
      __typename?: 'Integration';
      name: string;
      slug: string;
      provider: IntegrationProvider;
      image?: string | null;
      categories: Array<IntegrationCategory>;
      linkParams?: {
        __typename?: 'LinkAccountParams';
        currencyISOCode?: string | null;
        workato?: { __typename?: 'WorkatoLinkAccountParams'; connectionId: string } | null;
        fiveTran?: {
          __typename?: 'FiveTranLinkAccountParams';
          requiredFields: Array<FiveTranConfigField>;
          isLiteConnector: boolean;
          serviceStatus: FiveTranServiceStatus;
          docsLink: string;
          schemaInfo: string;
        } | null;
      } | null;
    }>;
    merge?: {
      __typename?: 'Merge';
      accountingProperties: {
        __typename?: 'AccountingProperties';
        accounts: Array<{
          __typename?: 'AccountingAccount';
          name: string;
          remoteId: string;
          linkedAccountId: string;
        }>;
        contacts: Array<{
          __typename?: 'AccountingContact';
          name: string;
          remoteId: string;
          linkedAccountId: string;
        }>;
        trackingCategories: Array<{
          __typename?: 'AccountingTrackingCategory';
          name: string;
          remoteId: string;
          linkedAccountId: string;
        }>;
      };
    } | null;
  } | null;
};

export type SyncStatusQueryVariables = Exact<{
  orgId: Scalars['ID'];
  provider: IntegrationProvider;
  linkedAccountId: Scalars['String'];
}>;

export type SyncStatusQuery = {
  __typename?: 'Query';
  integrations?: {
    __typename?: 'Integrations';
    syncStatus?:
      | { __typename: 'SyncStatusError'; error: string }
      | {
          __typename: 'SyncStatusResult';
          status: SyncStatusValue;
          lastSyncTime?: string | null;
          lastSyncStartedTime?: string | null;
          lastRemoteSyncTime?: string | null;
        }
      | null;
  } | null;
};

export type ConnectCardUriQueryVariables = Exact<{
  orgId: Scalars['ID'];
  linkedAccountId: Scalars['String'];
}>;

export type ConnectCardUriQuery = {
  __typename?: 'Query';
  integrations?: {
    __typename?: 'Integrations';
    fivetran?: { __typename?: 'FiveTran'; connectCardURI: string } | null;
  } | null;
};

export type CreatePuzzleLinkTokenMutationVariables = Exact<{
  orgId: Scalars['ID'];
}>;

export type CreatePuzzleLinkTokenMutation = {
  __typename?: 'Mutation';
  createPuzzleLinkToken?: { __typename?: 'PuzzleLinkTokenResponse'; token: string } | null;
};

export type CompletePuzzleLinkMutationVariables = Exact<{
  orgId: Scalars['ID'];
}>;

export type CompletePuzzleLinkMutation = {
  __typename?: 'Mutation';
  completePuzzleLink: { __typename?: 'BasicResponse'; success: boolean };
};

export type CreateRipplingLinkTokenMutationVariables = Exact<{
  orgId: Scalars['ID'];
  authCode: Scalars['String'];
}>;

export type CreateRipplingLinkTokenMutation = {
  __typename?: 'Mutation';
  createRipplingLinkToken: { __typename?: 'RipplingLinkTokenResponse'; redirectURL: string };
};

export type CreateWorkatoLinkTokenMutationVariables = Exact<{
  orgId: Scalars['ID'];
}>;

export type CreateWorkatoLinkTokenMutation = {
  __typename?: 'Mutation';
  createWorkatoLinkToken: { __typename?: 'WorkatoLinkTokenResponse'; token: string };
};

export type StartIntegrationQueryRunSubscriptionVariables = Exact<{
  orgId: Scalars['ID'];
  queryId: Scalars['String'];
  runId: Scalars['String'];
}>;

export type StartIntegrationQueryRunSubscription = {
  __typename?: 'Subscription';
  startIntegrationQueryRun: {
    __typename?: 'IntegrationQueryRun';
    id: string;
    queryId: string;
    status: IntegrationQueryRunStatus;
    result?: {
      __typename?: 'IntegrationQueryRunResult';
      sqlQueryResult?: {
        __typename?: 'SqlQueryResult';
        rows: Array<{
          __typename?: 'SqlQueryResultRow';
          columnValues: Array<{
            __typename?: 'SQLColumnValueTuple';
            column: string;
            value: string;
          }>;
        } | null>;
      } | null;
    } | null;
    error?: {
      __typename?: 'IntegrationQueryRunError';
      code: IntegrationQueryRunErrorCode;
      message: string;
    } | null;
  };
};

export type IntegrationsMetadataQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;

export type IntegrationsMetadataQuery = {
  __typename?: 'Query';
  integrations?: {
    __typename?: 'Integrations';
    metadata?: {
      __typename?: 'IntegrationMetadata';
      workatoId?: string | null;
      fivetranGroupId?: string | null;
    } | null;
  } | null;
};

export type SetLinkedIntegrationMetadataMutationVariables = Exact<{
  orgId: Scalars['ID'];
  provider: IntegrationProvider;
  linkedAccountId: Scalars['String'];
  propagateDataForward: Scalars['Boolean'];
  overrideForecastData: Scalars['Boolean'];
}>;

export type SetLinkedIntegrationMetadataMutation = {
  __typename?: 'Mutation';
  setLinkedIntegrationMetadata: { __typename?: 'BasicResponse'; success: boolean };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = {
  __typename?: 'Mutation';
  logout?: { __typename?: 'BasicResponse'; success: boolean } | null;
};

export type DatasetMutationMutationVariables = Exact<{
  orgId: Scalars['ID'];
  mutationId: Scalars['ID'];
  prevMutationId?: InputMaybe<Scalars['ID']>;
  mutation: DatasetMutationInput;
  layerId?: InputMaybe<Scalars['ID']>;
}>;

export type DatasetMutationMutation = {
  __typename?: 'Mutation';
  datasetMutation?: {
    __typename?: 'DatasetMutationWithLastMutationIdResponse';
    success: boolean;
    lastMutationId?: string | null;
    errors?: Array<
      | { __typename: 'ClientOutOfSyncMutationError'; message: string }
      | { __typename: 'UnableToApplyMutationError'; message: string; ops: Array<string> }
      | null
    > | null;
  } | null;
};

export type UndoMutationVariables = Exact<{
  orgId: Scalars['ID'];
  mutationId: Scalars['ID'];
  prevMutationId: Scalars['ID'];
  undoMutationId: Scalars['ID'];
  undoMutationInput?: InputMaybe<UndoMutationInput>;
}>;

export type UndoMutation = {
  __typename?: 'Mutation';
  undo?: { __typename?: 'BasicResponse'; success: boolean } | null;
};

export type OrgCreateMutationVariables = Exact<{
  orgName: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
  isDemoOrg?: InputMaybe<Scalars['Boolean']>;
}>;

export type OrgCreateMutation = {
  __typename?: 'Mutation';
  orgCreate?: {
    __typename?: 'Org';
    id: string;
    name: string;
    slug: string;
    subscriptionType?: OrgSubscriptionType | null;
  } | null;
};

export type OrgQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OrgQuery = {
  __typename?: 'Query';
  org?: { __typename?: 'Org'; id: string; slug: string } | null;
};

export type OrgImportStartTimeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OrgImportStartTimeQuery = {
  __typename?: 'Query';
  org?: { __typename?: 'Org'; importStartTime?: string | null } | null;
};

export type AccessResourceDataFragmentFragment = {
  __typename?: 'AccessResource';
  id: string;
  resourceId: string;
  parentId?: string | null;
  type: AccessResourceType;
  orgId: string;
  shouldInherit: boolean;
  accessControlList: Array<{
    __typename?: 'AccessControlEntry';
    id: string;
    grantedAt: string;
    grantedBy: string;
    accessRule: AccessRule;
    entityWithAccess: { __typename?: 'AccessorEntity'; id: string; type: AccessorEntityType };
  }>;
};

export type AddControlEntriesMutationVariables = Exact<{
  orgId: Scalars['ID'];
  controlEntries: Array<AddControlEntryInput> | AddControlEntryInput;
}>;

export type AddControlEntriesMutation = {
  __typename?: 'Mutation';
  addControlEntries: Array<{
    __typename?: 'AccessResource';
    id: string;
    resourceId: string;
    parentId?: string | null;
    type: AccessResourceType;
    orgId: string;
    shouldInherit: boolean;
    accessControlList: Array<{
      __typename?: 'AccessControlEntry';
      id: string;
      grantedAt: string;
      grantedBy: string;
      accessRule: AccessRule;
      entityWithAccess: { __typename?: 'AccessorEntity'; id: string; type: AccessorEntityType };
    }>;
  }>;
};

export type RemoveControlEntriesMutationVariables = Exact<{
  orgId: Scalars['ID'];
  controlEntries: Array<RemoveControlEntryInput> | RemoveControlEntryInput;
}>;

export type RemoveControlEntriesMutation = {
  __typename?: 'Mutation';
  removeControlEntries: Array<{
    __typename?: 'AccessResource';
    id: string;
    resourceId: string;
    parentId?: string | null;
    type: AccessResourceType;
    orgId: string;
    shouldInherit: boolean;
    accessControlList: Array<{
      __typename?: 'AccessControlEntry';
      id: string;
      grantedAt: string;
      grantedBy: string;
      accessRule: AccessRule;
      entityWithAccess: { __typename?: 'AccessorEntity'; id: string; type: AccessorEntityType };
    }>;
  }>;
};

export type AccessResourcesQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;

export type AccessResourcesQuery = {
  __typename?: 'Query';
  accessResources: Array<{
    __typename?: 'AccessResource';
    id: string;
    resourceId: string;
    parentId?: string | null;
    type: AccessResourceType;
    orgId: string;
    shouldInherit: boolean;
    accessControlList: Array<{
      __typename?: 'AccessControlEntry';
      id: string;
      grantedAt: string;
      grantedBy: string;
      accessRule: AccessRule;
      entityWithAccess: { __typename?: 'AccessorEntity'; id: string; type: AccessorEntityType };
    }>;
  }>;
};

export type AccessShareLinkMutationVariables = Exact<{
  nonce: Scalars['String'];
}>;

export type AccessShareLinkMutation = {
  __typename?: 'Mutation';
  accessShareLink?: {
    __typename?: 'ShareLinkRedirect';
    orgSlug: string;
    pageId: string;
    layerIds?: Array<string> | null;
  } | null;
};

export type OrgShareLinksQueryVariables = Exact<{
  orgSlug: Scalars['String'];
}>;

export type OrgShareLinksQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'User';
    orgs?: Array<{
      __typename?: 'Org';
      shareLinks: Array<{
        __typename?: 'ShareLink';
        id: string;
        pageId: string;
        nonce: string;
        layerIds?: Array<string> | null;
      }>;
    }> | null;
  } | null;
};

export type GenerateShareLinkMutationVariables = Exact<{
  orgId: Scalars['ID'];
  pageId: Scalars['String'];
  layerIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GenerateShareLinkMutation = {
  __typename?: 'Mutation';
  generateShareLink?: { __typename?: 'ShareLink'; nonce: string } | null;
};

export type DeleteShareLinksMutationVariables = Exact<{
  orgId: Scalars['ID'];
  pageId: Scalars['String'];
}>;

export type DeleteShareLinksMutation = {
  __typename?: 'Mutation';
  deleteShareLinks?: { __typename?: 'BasicResponse'; success: boolean } | null;
};

export type DoesShareLinkExistMutationVariables = Exact<{
  nonce: Scalars['String'];
}>;

export type DoesShareLinkExistMutation = { __typename?: 'Mutation'; doesShareLinkExist: boolean };

export type PublishScenarioMutationVariables = Exact<{
  orgId: Scalars['ID'];
  layerId: Scalars['String'];
}>;

export type PublishScenarioMutation = {
  __typename?: 'Mutation';
  publishScenario: Array<{
    __typename?: 'AccessResource';
    id: string;
    resourceId: string;
    parentId?: string | null;
    type: AccessResourceType;
    orgId: string;
    shouldInherit: boolean;
    accessControlList: Array<{
      __typename?: 'AccessControlEntry';
      id: string;
      grantedAt: string;
      grantedBy: string;
      accessRule: AccessRule;
      entityWithAccess: { __typename?: 'AccessorEntity'; id: string; type: AccessorEntityType };
    }>;
  }>;
};

export type UnpublishScenarioMutationVariables = Exact<{
  orgId: Scalars['ID'];
  layerId: Scalars['String'];
}>;

export type UnpublishScenarioMutation = {
  __typename?: 'Mutation';
  unpublishScenario?: { __typename?: 'BasicResponse'; success: boolean } | null;
};

export type GetTemplateOrgsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTemplateOrgsQuery = {
  __typename?: 'Query';
  templateOrgs: Array<{
    __typename?: 'TemplateOrg';
    id: string;
    name: string;
    description: string;
    orgId: string;
    slug: string;
  }>;
};

export type UserInviteFragmentFragment = {
  __typename: 'UserInvite';
  email: string;
  role?: OrgRole | null;
  accessRule?: AccessRule | null;
  createdAt: string;
  accessResource?: { __typename?: 'AccessResource'; id: string; type: AccessResourceType } | null;
  invitedByUser?: { __typename: 'User'; id: string } | null;
};

export type MeQueryVariables = Exact<{
  orgSlug?: InputMaybe<Scalars['String']>;
}>;

export type MeQuery = {
  __typename?: 'Query';
  me?: {
    __typename: 'User';
    id: string;
    name: string;
    email: string;
    createdAt: string;
    profileImage?: string | null;
    intercomHash?: string | null;
    hasSuperPermission: boolean;
    authenticatedUser?: {
      __typename?: 'AuthenticatedUser';
      id: string;
      name: string;
      email: string;
      createdAt: string;
      intercomHash?: string | null;
      profileImage?: string | null;
      hasSuperPermission: boolean;
    } | null;
    orgs?: Array<{
      __typename: 'Org';
      id: string;
      createdAt: string;
      name: string;
      slug: string;
      isActive: boolean;
      myOrgRole: OrgRole;
      isTemplateOrg: boolean;
      importStartTime?: string | null;
      users?: Array<{
        __typename: 'OrgUser';
        id: string;
        name: string;
        profileImage?: string | null;
        email: string;
        orgRole: OrgRole;
      }> | null;
      invitedUsers?: Array<{
        __typename: 'UserInvite';
        email: string;
        role?: OrgRole | null;
        accessRule?: AccessRule | null;
        createdAt: string;
        accessResource?: {
          __typename?: 'AccessResource';
          id: string;
          type: AccessResourceType;
        } | null;
        invitedByUser?: { __typename: 'User'; id: string } | null;
      }> | null;
      generalSettings: {
        __typename: 'GeneralSettings';
        defaultCurrencyISOCode: string;
        negativeDisplay: NegativeDisplay;
        defaultDecimalPrecision?: number | null;
      };
    }> | null;
  } | null;
};

export type UpdateUserOrgRoleMutationVariables = Exact<{
  orgId: Scalars['ID'];
  userId: Scalars['ID'];
  role: OrgRole;
}>;

export type UpdateUserOrgRoleMutation = {
  __typename?: 'Mutation';
  updateUserOrgRole?: { __typename?: 'BasicResponse'; success: boolean } | null;
};

export type OrgInviteUserToRoleMutationVariables = Exact<{
  orgId: Scalars['ID'];
  invites: Array<InviteRoleInput> | InviteRoleInput;
}>;

export type OrgInviteUserToRoleMutation = {
  __typename?: 'Mutation';
  orgInviteUserToRole: Array<{
    __typename: 'UserInvite';
    email: string;
    role?: OrgRole | null;
    accessRule?: AccessRule | null;
    createdAt: string;
    accessResource?: { __typename?: 'AccessResource'; id: string; type: AccessResourceType } | null;
    invitedByUser?: { __typename: 'User'; id: string } | null;
  }>;
};

export type OrgInviteUserToResourceMutationVariables = Exact<{
  orgId: Scalars['ID'];
  invites: Array<InviteResourceInput> | InviteResourceInput;
}>;

export type OrgInviteUserToResourceMutation = {
  __typename?: 'Mutation';
  orgInviteUserToResource: Array<{
    __typename: 'UserInvite';
    email: string;
    role?: OrgRole | null;
    accessRule?: AccessRule | null;
    createdAt: string;
    accessResource?: { __typename?: 'AccessResource'; id: string; type: AccessResourceType } | null;
    invitedByUser?: { __typename: 'User'; id: string } | null;
  }>;
};

export type OrgDeleteUserResourceInviteMutationVariables = Exact<{
  orgId: Scalars['ID'];
  email: Scalars['String'];
  accessResource: AccessResourceInput;
}>;

export type OrgDeleteUserResourceInviteMutation = {
  __typename?: 'Mutation';
  orgDeleteUserResourceInvite: Array<{
    __typename: 'UserInvite';
    email: string;
    role?: OrgRole | null;
    accessRule?: AccessRule | null;
    createdAt: string;
    accessResource?: { __typename?: 'AccessResource'; id: string; type: AccessResourceType } | null;
    invitedByUser?: { __typename: 'User'; id: string } | null;
  }>;
};

export type RemoveOrgUserMutationVariables = Exact<{
  orgId: Scalars['ID'];
  userId: Scalars['ID'];
}>;

export type RemoveOrgUserMutation = {
  __typename?: 'Mutation';
  removeOrgUser?: { __typename?: 'BasicResponse'; success: boolean } | null;
};

export type RemoveInvitedUserMutationVariables = Exact<{
  orgId: Scalars['ID'];
  email: Scalars['String'];
}>;

export type RemoveInvitedUserMutation = {
  __typename?: 'Mutation';
  removeInvitedUser?: { __typename?: 'BasicResponse'; success: boolean } | null;
};

export type FetchVeltTokenQueryVariables = Exact<{
  orgId: Scalars['String'];
}>;

export type FetchVeltTokenQuery = { __typename?: 'Query'; veltToken?: string | null };

export const BlockConfigFragmentFragmentDoc = gql`
  fragment BlockConfigFragment on BlockConfig {
    idFilter
    textBlockBody
    videoBlockUrl
    imageBlockUrl
    mediaWidth
    blockAlignment
    hideHeader
    fileMetadata {
      id
      name
      type
      path
      size
    }
    businessObjectSpecId
    showRestricted
    blockViewOptions {
      showMilestones
      viewAsType
      chartSize
      chartGroupingType
      chartDisplay {
        axes {
          id
          position
          name
          showLabel
          type
          time {
            rollup
            plotBy
          }
          driver {
            name
            groupIds
            format {
              type
              decimalPlaces
              currencyISOCode
            }
            showTotals
            isNormalized
          }
        }
        series {
          id
          driverId
          type
          color
          showLabels
        }
        groups {
          id
          seriesIds
          isDefault
          layerId
          dateRange {
            start
            end
          }
          name
          showLabels
        }
        legend {
          showLegend
          position
          container {
            maxWidth
            maxHeight
          }
          item {
            maxWidth
            paddingX
            paddingY
          }
        }
      }
      aggregateValues
      dateRangeComparison {
        type
        selectedPeriod {
          start
          end
        }
        comparisonPeriod {
          start
          end
        }
      }
    }
    sortBy {
      sortType
      impact {
        driverId
        startMonthKey
        endMonthKey
      }
      object {
        ops {
          propertyId
          order
          manualOrdering
        }
      }
    }
    filterBy {
      filterType
      businessObjectSpecId
      expression
      filterKey
      filterValues
      filterOp
    }
    groupBy {
      groupByType
      driverField {
        driverFieldSpecId
        collapsedAttributeOptionIds
      }
      objectField {
        businessObjectFieldId
        collapsedAttributeIds
      }
      driverProperty {
        driverPropertyId
        collapsedAttributeIds
      }
      date {
        collapsedMonthKeys
      }
    }
    rollupTypes
    rollupType
    fiscalYearStartMonth
    driverIndentations {
      level
      driverId
    }
    dateRange {
      start
      end
    }
    viewAtTime {
      time
      dynamicDateType
    }
    comparisons {
      layerIds
      columns
      layout
      baselineLayerId
    }
    columns {
      key
      width
      visible
    }
    impactDisplay {
      displayType
    }
    objectFieldSpecAsTimeSeriesId
    objectSpecDisplayAs
    driverIndentations {
      driverId
      level
    }
    expandedIds
  }
`;
export const TransactionFieldValueFragmentFragmentDoc = gql`
  fragment TransactionFieldValueFragment on TransactionFieldValue {
    type
    stringValue
  }
`;
export const TransactionFilterQueryFragmentFragmentDoc = gql`
  fragment TransactionFilterQueryFragment on TransactionFilterQuery {
    predicates {
      type
      multiSelect {
        field
        operator
        values {
          ...TransactionFieldValueFragment
        }
      }
      comparison {
        field
        operator
        value {
          ...TransactionFieldValueFragment
        }
      }
    }
  }
  ${TransactionFieldValueFragmentFragmentDoc}
`;
export const DatasetMutationDataFragmentFragmentDoc = gql`
  fragment DatasetMutationDataFragment on DatasetMutationData {
    id
    layerId
    userId
    createdAt
    deletedAt
    changeId
    prevMutationId
    undoMutationId
    commitLayerId
    newDimensions {
      id
      name
      color
      attributes {
        dimensionId
        id
        value
      }
    }
    updateDimensions {
      id
      name
      color
      newAttributes {
        id
        dimensionId
        value
      }
      updateAttributes {
        id
        value
      }
      deleteAttributes {
        id
      }
      restoreAttributes {
        id
      }
    }
    deleteDimensions {
      id
    }
    restoreDimensions {
      id
    }
    newDrivers {
      id
      name
      format
      driverType
      submodelId
      driverReferences {
        blockId
        sortIndex
        groupId
      }
      valueType
      leverType
      groupId
      description
      sortIndex
      currencyISOCode
      coloring {
        row
        cells {
          time
          value
        }
      }
      driverMapping {
        driverId
      }
      decimalPlaces
      basic {
        forecast {
          formula
        }
        actuals {
          formula
          timeSeries {
            __typename
            time
            value
          }
        }
        rollup {
          reducer
        }
      }
      dimensional {
        dimensionIds
        builtInDimensions
        subDrivers {
          attributeIds
          builtInAttributes {
            type
            value
          }
          source {
            type
            extQueryId
          }
          existingDriverId
          driver {
            id
            name
            format
            valueType
            driverType
            submodelId
            driverReferences {
              blockId
              sortIndex
              groupId
            }
            leverType
            groupId
            description
            sortIndex
            currencyISOCode
            coloring {
              row
              cells {
                time
                value
              }
            }
            decimalPlaces
            basic {
              forecast {
                formula
              }
              actuals {
                formula
                timeSeries {
                  __typename
                  time
                  value
                }
              }
              rollup {
                reducer
              }
            }
          }
        }
        defaultForecast {
          formula
        }
        defaultActuals {
          formula
        }
      }
    }
    updateDrivers {
      id
      name
      description
      actuals {
        formula
        timeSeries {
          __typename
          time
          value
        }
      }
      forecast {
        formula
      }
      format
      rollup {
        reducer
      }
      submodelId
      driverReferences {
        blockId
        sortIndex
        groupId
      }
      driverMapping {
        driverId
      }
      removeSubmodelId
      removeDriverMapping
      removeAllDriverReferences
      leverType
      description
      sortIndex
      currencyISOCode
      coloring {
        row
        cells {
          time
          value
        }
      }
      decimalPlaces
      groupId
      newSubDrivers {
        attributeIds
        builtInAttributes {
          type
          value
        }
        source {
          type
          extQueryId
        }
        existingDriverId
        driver {
          id
          name
          format
          valueType
          driverType
          submodelId
          driverReferences {
            blockId
            sortIndex
            groupId
          }
          leverType
          groupId
          description
          sortIndex
          currencyISOCode
          coloring {
            row
            cells {
              time
              value
            }
          }
          decimalPlaces
          basic {
            forecast {
              formula
            }
            actuals {
              formula
              timeSeries {
                __typename
                time
                value
              }
            }
            rollup {
              reducer
            }
          }
        }
      }
      dimensional {
        defaultForecast {
          formula
        }
        defaultActuals {
          formula
        }
        updateSubDrivers {
          attributeIds
          builtInAttributes {
            type
            value
          }
          source {
            type
            extQueryId
          }
          newAttributeIds
          newBuiltInAttributes {
            type
            value
          }
          removeAllAttributes
          unlink
        }
        deleteSubDrivers {
          attributeIds
          builtInAttributes {
            type
            value
          }
        }
        dimensionIds
        builtInDimensions
      }
    }
    deleteDrivers {
      id
    }
    newEvents {
      id
      name
      description
      ownerName
      driverId
      businessObjectFieldId
      parentId
      start
      end
      modifierType
      curveType
      sortIndex
      customCurvePoints {
        time
        value
      }
      totalImpact
      impactType
      setValue
      setValueType
      impactType
      time
      value
    }
    deleteEvents {
      id
    }
    updateEvents {
      id
      ownerName
      driverId
      businessObjectFieldId
      parentId
      name
      description
      start
      end
      curveType
      sortIndex
      customCurvePoints {
        time
        value
      }
      totalImpact
      impactType
      setValue
      setValueType
      hidden
      time
      value
    }
    newMilestones {
      id
      type
      name
      driverId
      value
      time
      thresholdDirection
    }
    deleteMilestones {
      id
    }
    updateMilestones {
      id
      type
      name
      value
      time
      thresholdDirection
    }
    newSubmodels {
      id
      name
      description
      sortedDriverGroupIds
    }
    updateSubmodels {
      id
      name
      description
      sortedDriverGroupIds
    }
    deleteSubmodels {
      id
      deleteDrivers
    }
    newEventGroups {
      id
      name
      description
      ownerName
      ownerId
      eventIds
      eventGroupIds
      parentId
      defaultEnd
      defaultStart
      sortIndex
    }
    deleteEventGroups {
      id
    }
    updateEventGroups {
      id
      name
      description
      ownerName
      ownerId
      parentId
      eventIds
      eventGroupIds
      defaultEnd
      defaultStart
      hidden
      sortIndex
    }
    newLayers {
      id
      name
      description
      isDraft
      parentLayerId
      userId
    }
    deleteLayers {
      layerId
    }
    updateLayers {
      layerId
      name
      description
      isDraft
    }
    newExports {
      id
      name
      driverIds
    }
    deleteExports {
      id
    }
    updateExports {
      id
      name
      driverIds
    }
    newDriverGroups {
      id
      name
    }
    renameDriverGroups {
      id
      name
    }
    deleteDriverGroups {
      id
    }
    newBusinessObjectSpecs {
      id
      name
      extSource
      extSpecKey
      extSpecKeys
      databaseConfigId
      startFieldId
      isRestricted
      fields {
        id
        name
        type
        dimensionId
        numericFormat
        currencyISOCode
        decimalPlaces
        extFieldSpecKey
        defaultForecast {
          formula
        }
        defaultValues {
          key
          value {
            type
            actuals {
              formula
              timeSeries {
                __typename
                time
                value
              }
            }
          }
        }
        extFieldSpecKey
      }
      defaultNameEntries {
        key
        value
      }
      collection {
        dimensionalProperties {
          id
          name
          dimensionId
          isDatabaseKey
          extFieldSpecKey
          mapping {
            lookupSpecId
            resultPropertyId
            searchDimensionPropertyId
          }
        }
        driverProperties {
          id
          driverId
        }
      }
    }
    updateLastActualsTime
    updateBusinessObjectSpecs {
      id
      name
      isRestricted
      databaseConfigId
      addFields {
        id
        name
        type
        dimensionId
        numericFormat
        currencyISOCode
        decimalPlaces
        isRestricted
        defaultForecast {
          formula
        }
        extFieldSpecKey
      }
      updateFields {
        id
        type
        name
        numericFormat
        currencyISOCode
        decimalPlaces
        isRestricted
        extFieldSpecKey
        propagateIntegrationData
        integrationDataOverridesForecast
        dimensionId
        defaultForecast {
          formula
        }
        setDefaultValues {
          key
          value {
            type
            actuals {
              formula
              timeSeries {
                __typename
                time
                value
              }
            }
          }
        }
        removeDefaultValues
        removeDefaultForecast
      }
      deleteFields
      updateStartFieldId
      extSpecKey
      extSpecKeys
      setDefaultNameEntries {
        key
        value
      }
      deleteDefaultNameEntries
      updateCollection {
        addDimensionalProperties {
          id
          name
          dimensionId
          isDatabaseKey
          extQueryId
        }
        updateDimensionalProperties {
          id
          name
          dimensionId
          mapping {
            lookupSpecId
            resultPropertyId
            searchDimensionPropertyId
          }
          removeMapping
          isDatabaseKey
        }
        removeDimensionalProperties
        addDriverProperties {
          id
          driverId
          extQueryId
        }
        removeDriverProperties
      }
    }
    deleteBusinessObjectSpecs {
      id
    }
    newBusinessObjects {
      id
      name
      specId
      defaultEventGroupId
      fields {
        id
        fieldSpecId
        value {
          type
          actuals {
            formula
            timeSeries {
              __typename
              time
              value
            }
          }
          initialValue
        }
      }
      collectionEntry {
        attributeProperties {
          dimensionalPropertyId
          attributeId
        }
      }
      metadata {
        remoteId
        extKey
        databaseConfigId
      }
    }
    updateBusinessObjects {
      id
      name
      defaultEventGroupId
      deleteFields
      addFields {
        id
        fieldSpecId
        value {
          type
          actuals {
            formula
            timeSeries {
              __typename
              time
              value
            }
          }
          initialValue
        }
      }
      fields {
        id
        value {
          type
          actuals {
            formula
            timeSeries {
              __typename
              time
              value
            }
          }
          initialValue
        }
      }
      metadata {
        remoteId
        extKey
        databaseConfigId
      }
      updateCollectionEntry {
        addAttributeProperties {
          dimensionalPropertyId
          attributeId
        }
        updateAttributeProperties {
          dimensionalPropertyId
          attributeId
        }
        removeAttributeProperties
      }
    }
    deleteBusinessObjects {
      id
    }
    newBlocksPages {
      id
      createdByUserId
      name
      internalPageType
      internalPageVersion
      sortIndex
      parent
      dateRange {
        start
        end
      }
      layout {
        id
        columns {
          id
          blockIds
        }
      }
    }
    updateBlocksPages {
      id
      name
      sortIndex
      parent
      dateRange {
        start
        end
      }
      layout {
        id
        columns {
          id
          blockIds
        }
      }
    }
    deleteBlocksPages {
      id
    }
    newFolders {
      id
      name
      type
      parent
      sortIndex
    }
    updateFolders {
      id
      name
      type
      parent
      sortIndex
    }
    deleteFolders {
      id
    }
    newBlocks {
      id
      name
      type
      pageId
      blockConfig {
        ...BlockConfigFragment
      }
      sortIndex
    }
    updateBlocks {
      id
      name
      type
      blockConfig {
        ...BlockConfigFragment
      }
      sortIndex
    }
    deleteBlocks {
      id
    }
    createExtDrivers {
      id
      source
      model
      remoteId
      path
      timeSeries {
        __typename
        time
        value
      }
      query {
        ...TransactionFilterQueryFragment
      }
      integrationQueryId
      accountId
    }
    deleteExtDrivers {
      id
    }
    updateExtDrivers {
      id
      timeSeries {
        __typename
        time
        value
      }
      query {
        ...TransactionFilterQueryFragment
      }
      accountId
    }
    createExtObjectSpecs {
      extKey
      source
      model
      fields {
        extKey
        name
        type
        dimensionId
        numericFormat
      }
      startFieldExtKey
      integrationQueryId
      propagateDataForward
      overrideForecastData
    }
    deleteExtObjectSpecs {
      extKey
    }
    updateExtObjectSpecs {
      extKey
      propagateDataForward
      overrideForecastData
    }
    createExtObjects {
      extKey
      name
      remoteId
      extSpecKey
      fields {
        extFieldSpecKey
        timeSeries {
          __typename
          time
          value
        }
      }
    }
    deleteExtObjects {
      extKey
    }
    createIntegrationQueries {
      id
      name
      source
      content {
        sqlQuery
      }
      enableExtTable
      createdAt
      linkedAccountId
    }
    updateIntegrationQueries {
      id
      name
      content {
        sqlQuery
      }
      isScheduled
      isScheduledExtTable
      lastSyncedAt
      enableExtTable
      linkedAccountId
    }
    deleteIntegrationQueries {
      id
    }
    createNamedDatasetVersions {
      mutationId
      name
    }
    updateNamedDatasetVersions {
      mutationId
      name
    }
    deleteNamedDatasetVersions {
      mutationId
    }
    createExtTables {
      sourceKey
      queryId
      schema {
        version
        columns {
          id
        }
      }
    }
    updateExtTables {
      sourceKey
      schema {
        version
        columns {
          id
        }
      }
    }
    deleteExtTables {
      sourceKey
    }
    createExtTableRuns {
      sourceKey
      schemaVersion
      runId
      dataMacro {
        type
        id
      }
    }
    createDatabaseConfigs {
      id
      businessObjectSpecId
      refreshInfo {
        updatedAt
        status
        error {
          reason
        }
      }
      source
      extTable {
        extTableSourceKey
        timeColumn {
          extTableColumnId
        }
        segmentedByColumns {
          dimensionId
          dimensionalPropertyId
          extQueryId
          extTableColumnId
          __typename
        }
        metadataColumns {
          dimensionId
          dimensionalPropertyId
          extQueryId
          extTableColumnId
          __typename
        }
        driverColumns {
          driverPropertyId
          aggregationType
          extQueryId
          extTableColumnId
          __typename
        }
        filters {
          comparator
          compareTo
          extTableColumnId
          __typename
        }
      }
      database {
        sourceBusinessObjectSpecId
        driverColumns {
          driverPropertyId
          aggregationType
          sourceDriverPropertyId
          __typename
        }
        segmentedByColumns {
          dimensionalPropertyId
          sourceDimensionalPropertyId
          __typename
        }
        metadataColumns {
          dimensionalPropertyId
          sourceDimensionalPropertyId
          __typename
        }
        filters {
          comparator
          compareTo
          dimensionalPropertyId
          __typename
        }
      }
    }
    updateDatabaseConfigs {
      id
      businessObjectSpecId
      refreshInfo {
        updatedAt
        status
        error {
          reason
        }
      }
      source
      extTable {
        extTableSourceKey
        timeColumn {
          extTableColumnId
        }
        segmentedByColumns {
          dimensionId
          dimensionalPropertyId
          extQueryId
          extTableColumnId
          __typename
        }
        metadataColumns {
          dimensionId
          dimensionalPropertyId
          extQueryId
          extTableColumnId
          __typename
        }
        driverColumns {
          driverPropertyId
          aggregationType
          extQueryId
          extTableColumnId
          __typename
        }
        filters {
          comparator
          compareTo
          extTableColumnId
          __typename
        }
        __typename
      }
      database {
        sourceBusinessObjectSpecId
        driverColumns {
          driverPropertyId
          aggregationType
          sourceDriverPropertyId
          __typename
        }
        segmentedByColumns {
          dimensionalPropertyId
          sourceDimensionalPropertyId
          __typename
        }
        metadataColumns {
          dimensionalPropertyId
          sourceDimensionalPropertyId
          __typename
        }
        filters {
          comparator
          compareTo
          dimensionalPropertyId
          __typename
        }
        __typename
      }
      __typename
    }
    createExtQueries {
      id
      source
      raw
    }
    updateExtQueries {
      id
      source
      raw
    }
    deleteExtQueries {
      id
    }
  }
  ${BlockConfigFragmentFragmentDoc}
  ${TransactionFilterQueryFragmentFragmentDoc}
`;
export const IntegrationFragmentFragmentDoc = gql`
  fragment IntegrationFragment on Integration {
    name
    slug
    provider
    image
    categories
    linkParams {
      currencyISOCode
      workato {
        connectionId
      }
      fiveTran {
        requiredFields
        isLiteConnector
        serviceStatus
        docsLink
        schemaInfo
      }
    }
  }
`;
export const LinkedAccountFragmentFragmentDoc = gql`
  fragment LinkedAccountFragment on LinkedAccount {
    id
    category
    status
    lastSyncedAt
    integration {
      ...IntegrationFragment
    }
    alias
    propagateDataForward
    overrideForecastData
  }
  ${IntegrationFragmentFragmentDoc}
`;
export const AccessResourceDataFragmentFragmentDoc = gql`
  fragment AccessResourceDataFragment on AccessResource {
    id
    resourceId
    parentId
    type
    orgId
    accessControlList {
      id
      grantedAt
      grantedBy
      entityWithAccess {
        id
        type
      }
      accessRule
    }
    shouldInherit
  }
`;
export const UserInviteFragmentFragmentDoc = gql`
  fragment UserInviteFragment on UserInvite {
    __typename
    email
    role
    accessResource {
      id
      type
    }
    accessRule
    invitedByUser {
      __typename
      id
    }
    createdAt
  }
`;
export const AggregatedTransactionsTimeSeriesDocument = gql`
  query aggregatedTransactionsTimeSeries(
    $orgId: String!
    $accountId: String
    $input: AggregatedTransactionsTimeSeriesInput!
  ) {
    aggregatedTransactionsTimeSeries(orgId: $orgId, accountId: $accountId, input: $input) {
      time
      value
    }
  }
`;

export function useAggregatedTransactionsTimeSeriesQuery(
  options: Omit<Urql.UseQueryArgs<AggregatedTransactionsTimeSeriesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    AggregatedTransactionsTimeSeriesQuery,
    AggregatedTransactionsTimeSeriesQueryVariables
  >({ query: AggregatedTransactionsTimeSeriesDocument, ...options });
}
export const GetAccountingTransactionsDocument = gql`
  query getAccountingTransactions(
    $orgId: String!
    $accountId: String
    $input: GetAccountingTransactionsInput!
  ) {
    getAccountingTransactions(orgId: $orgId, accountId: $accountId, input: $input) {
      remoteId
      amount
      date
      memo
      accountRemoteId
      contactRemoteId
      trackingCategoryRemoteIds
      linkedAccountId
    }
  }
`;

export function useGetAccountingTransactionsQuery(
  options: Omit<Urql.UseQueryArgs<GetAccountingTransactionsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetAccountingTransactionsQuery, GetAccountingTransactionsQueryVariables>({
    query: GetAccountingTransactionsDocument,
    ...options,
  });
}
export const AiGroupDriversDocument = gql`
  query aiGroupDrivers($input: AIGroupDriversInput!) {
    aiGroupDrivers(input: $input) {
      __typename
      cursor
      groups {
        name
        drivers {
          id
          name
        }
      }
    }
  }
`;

export function useAiGroupDriversQuery(
  options: Omit<Urql.UseQueryArgs<AiGroupDriversQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AiGroupDriversQuery, AiGroupDriversQueryVariables>({
    query: AiGroupDriversDocument,
    ...options,
  });
}
export const AiGroupEventsDocument = gql`
  query aiGroupEvents($input: AIGroupEventsInput!) {
    aiGroupEvents(input: $input) {
      __typename
      cursor
      groups {
        name
        events {
          id
        }
      }
    }
  }
`;

export function useAiGroupEventsQuery(
  options: Omit<Urql.UseQueryArgs<AiGroupEventsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AiGroupEventsQuery, AiGroupEventsQueryVariables>({
    query: AiGroupEventsDocument,
    ...options,
  });
}
export const ApiSecretDocument = gql`
  query apiSecret($orgSlug: String) {
    me {
      __typename
      orgs(orgSlug: $orgSlug) {
        apiSecret {
          __typename
          apiSecret
        }
      }
    }
  }
`;

export function useApiSecretQuery(
  options?: Omit<Urql.UseQueryArgs<ApiSecretQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ApiSecretQuery, ApiSecretQueryVariables>({
    query: ApiSecretDocument,
    ...options,
  });
}
export const GenerateApiSecretDocument = gql`
  mutation generateApiSecret($orgId: ID!) {
    generateApiSecret(orgId: $orgId) {
      apiSecret
    }
  }
`;

export function useGenerateApiSecretMutation() {
  return Urql.useMutation<GenerateApiSecretMutation, GenerateApiSecretMutationVariables>(
    GenerateApiSecretDocument,
  );
}
export const GenerateUploadStorageSignedUrlDocument = gql`
  mutation generateUploadStorageSignedUrl(
    $orgId: ID!
    $pageId: String!
    $fileName: String!
    $fileType: String!
  ) {
    generateUploadStorageSignedUrl(
      orgId: $orgId
      pageId: $pageId
      fileName: $fileName
      fileType: $fileType
    ) {
      signedUrl
      fileId
      filePath
    }
  }
`;

export function useGenerateUploadStorageSignedUrlMutation() {
  return Urql.useMutation<
    GenerateUploadStorageSignedUrlMutation,
    GenerateUploadStorageSignedUrlMutationVariables
  >(GenerateUploadStorageSignedUrlDocument);
}
export const GenerateDownloadStorageSignedUrlDocument = gql`
  mutation generateDownloadStorageSignedUrl($orgId: ID!, $filePath: String!) {
    generateDownloadStorageSignedUrl(orgId: $orgId, filePath: $filePath) {
      signedUrl
    }
  }
`;

export function useGenerateDownloadStorageSignedUrlMutation() {
  return Urql.useMutation<
    GenerateDownloadStorageSignedUrlMutation,
    GenerateDownloadStorageSignedUrlMutationVariables
  >(GenerateDownloadStorageSignedUrlDocument);
}
export const CalculationUpdatesDocument = gql`
  subscription calculationUpdates($orgId: ID!) {
    calculationUpdates(orgId: $orgId) {
      clientId
      type
      calculationResults {
        requestId
        layerId
        entityId
        entityType
        date
        ignoreEventIDs
        value {
          type
          number
          attributeId
          timestamp
        }
        objectSpecEvaluations {
          specId
          evaluation {
            type
            number
            attributeId
            timestamp
          }
          fieldEvaluations {
            objectId
            fieldSpecId
            subDriverId
            evaluation {
              type
              number
              attributeId
              timestamp
            }
          }
        }
        error {
          type
          originEntity {
            id
            type
          }
        }
      }
      blockEvalResults {
        requestId
        layerId
        blockId
        cacheKey
        data {
          meta {
            key
            attributeId
            dimensionId
            fieldSpecId
          }
          ids
        }
      }
    }
  }
`;

export function useCalculationUpdatesSubscription<TData = CalculationUpdatesSubscription>(
  options: Omit<Urql.UseSubscriptionArgs<CalculationUpdatesSubscriptionVariables>, 'query'>,
  handler?: Urql.SubscriptionHandler<CalculationUpdatesSubscription, TData>,
) {
  return Urql.useSubscription<
    CalculationUpdatesSubscription,
    TData,
    CalculationUpdatesSubscriptionVariables
  >({ query: CalculationUpdatesDocument, ...options }, handler);
}
export const EnqueueCalculationsDocument = gql`
  mutation enqueueCalculations(
    $orgId: ID!
    $clientId: String!
    $requestBatch: CalculationRequestBatch!
  ) {
    enqueueCalculations(orgId: $orgId, clientId: $clientId, requestBatch: $requestBatch) {
      success
    }
  }
`;

export function useEnqueueCalculationsMutation() {
  return Urql.useMutation<EnqueueCalculationsMutation, EnqueueCalculationsMutationVariables>(
    EnqueueCalculationsDocument,
  );
}
export const EnqueueBlockEvalDocument = gql`
  mutation enqueueBlockEval($orgId: ID!, $clientId: String!, $request: BlockEvalRequest!) {
    enqueueBlockEval(orgId: $orgId, clientId: $clientId, request: $request) {
      success
    }
  }
`;

export function useEnqueueBlockEvalMutation() {
  return Urql.useMutation<EnqueueBlockEvalMutation, EnqueueBlockEvalMutationVariables>(
    EnqueueBlockEvalDocument,
  );
}
export const CalculatorStatusDocument = gql`
  query calculatorStatus($orgId: ID!) {
    calculatorStatus(orgId: $orgId)
  }
`;

export function useCalculatorStatusQuery(
  options: Omit<Urql.UseQueryArgs<CalculatorStatusQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CalculatorStatusQuery, CalculatorStatusQueryVariables>({
    query: CalculatorStatusDocument,
    ...options,
  });
}
export const ExportMutationsDocument = gql`
  query exportMutations($orgId: String!, $query: ExportMutationInput!) {
    exportMutations(orgId: $orgId, query: $query) {
      success
    }
  }
`;

export function useExportMutationsQuery(
  options: Omit<Urql.UseQueryArgs<ExportMutationsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ExportMutationsQuery, ExportMutationsQueryVariables>({
    query: ExportMutationsDocument,
    ...options,
  });
}
export const ImportMutationsDocument = gql`
  mutation importMutations(
    $orgId: ID!
    $append: Boolean!
    $exportedMutations: String
    $signedUrl: String
  ) {
    importMutations(
      orgId: $orgId
      append: $append
      exportedMutations: $exportedMutations
      signedUrl: $signedUrl
    ) {
      success
    }
  }
`;

export function useImportMutationsMutation() {
  return Urql.useMutation<ImportMutationsMutation, ImportMutationsMutationVariables>(
    ImportMutationsDocument,
  );
}
export const ImportDatasetForPresetTypeDocument = gql`
  mutation importDatasetForPresetType($orgId: ID!, $datasetPresetType: DatasetPresetType!) {
    importDatasetForPresetType(orgId: $orgId, datasetPresetType: $datasetPresetType) {
      success
    }
  }
`;

export function useImportDatasetForPresetTypeMutation() {
  return Urql.useMutation<
    ImportDatasetForPresetTypeMutation,
    ImportDatasetForPresetTypeMutationVariables
  >(ImportDatasetForPresetTypeDocument);
}
export const RestoreToMutationDocument = gql`
  mutation restoreToMutation($orgId: ID!, $mutationId: ID!) {
    restoreToMutation(orgId: $orgId, restoreToMutationId: $mutationId) {
      success
    }
  }
`;

export function useRestoreToMutationMutation() {
  return Urql.useMutation<RestoreToMutationMutation, RestoreToMutationMutationVariables>(
    RestoreToMutationDocument,
  );
}
export const DatasetMutatedDocument = gql`
  subscription datasetMutated($orgId: ID!, $lastMutationId: String!, $clientInfo: ClientInfo) {
    datasetMutated(orgId: $orgId, lastMutationId: $lastMutationId, clientInfo: $clientInfo) {
      ...DatasetMutationDataFragment
    }
  }
  ${DatasetMutationDataFragmentFragmentDoc}
`;

export function useDatasetMutatedSubscription<TData = DatasetMutatedSubscription>(
  options: Omit<Urql.UseSubscriptionArgs<DatasetMutatedSubscriptionVariables>, 'query'>,
  handler?: Urql.SubscriptionHandler<DatasetMutatedSubscription, TData>,
) {
  return Urql.useSubscription<
    DatasetMutatedSubscription,
    TData,
    DatasetMutatedSubscriptionVariables
  >({ query: DatasetMutatedDocument, ...options }, handler);
}
export const BlockComparisonDatasetDocument = gql`
  query blockComparisonDataset($orgId: String!, $mutationId: ID, $layerId: ID) {
    dataset(orgId: $orgId, mutationId: $mutationId, layerId: $layerId) {
      drivers {
        __typename
        basic {
          forecast {
            formula
          }
          actuals {
            formula
          }
          rollup {
            reducer
          }
        }
        dimensional {
          __typename
          builtInDimensions
          defaultForecast {
            formula
          }
          defaultActuals {
            formula
          }
          dimensions {
            id
            name
            attributes {
              deleted
              dimensionId
              id
              value
            }
            deleted
            color
          }
          subDrivers {
            attributes {
              id
              dimensionId
              value
              deleted
            }
            builtInAttributes {
              type
              value
            }
            driver {
              id
              valueType
              driverType
              format
              name
            }
            source {
              type
              extQueryId
            }
          }
        }
        driverMapping {
          driverId
        }
        id
        name
        valueType
        driverType
        groupId
        format
        submodelId
        driverReferences {
          blockId
          sortIndex
          groupId
        }
        sortIndex
      }
      businessObjectSpecs {
        id
        name
        extSource
        extSpecKey
        extSpecKeys
        startFieldId
        isRestricted
        fields {
          id
          name
          type
          dimensionId
          isRestricted
        }
        collection {
          dimensionalProperties {
            id
            name
            dimensionId
            isDatabaseKey
            extQueryId
          }
          driverProperties {
            id
            driverId
            extQueryId
          }
        }
      }
      lastActualsTime
    }
  }
`;

export function useBlockComparisonDatasetQuery(
  options: Omit<Urql.UseQueryArgs<BlockComparisonDatasetQueryVariables>, 'query'>,
) {
  return Urql.useQuery<BlockComparisonDatasetQuery, BlockComparisonDatasetQueryVariables>({
    query: BlockComparisonDatasetDocument,
    ...options,
  });
}
export const DatasetDocument = gql`
  query dataset($orgId: String!, $mutationId: ID, $layerId: ID) {
    dataset(orgId: $orgId, mutationId: $mutationId, layerId: $layerId) {
      orgId
      layers {
        __typename
        id
        name
        description
        isDraft
        lockedMutationId
        parentLayerId
        createdByUserId
        createdAt
      }
      dimensions {
        id
        name
      }
      metadata {
        lastMutationId
        lastUpdatedAt
      }
      drivers {
        __typename
        id
        name
        driverType
        groupId
        submodelId
        driverReferences {
          blockId
          sortIndex
          groupId
        }
        sortIndex
        basic {
          forecast {
            formula
          }
          actuals {
            formula
          }
          rollup {
            reducer
          }
        }
        dimensional {
          defaultForecast {
            formula
          }
          defaultActuals {
            formula
          }
        }
        driverMapping {
          driverId
        }
      }
      eventGroups {
        __typename
        id
        name
        parentId
        sortIndex
      }
      driverGroups {
        id
        name
      }
      submodels {
        id
        name
        description
        sortedDriverGroupIds
        sortIndex
      }
      businessObjectSpecs {
        id
        name
        extSource
        extSpecKey
        extSpecKeys
        startFieldId
        isRestricted
        fields {
          id
          name
          type
          dimensionId
          isRestricted
        }
        collection {
          dimensionalProperties {
            id
            name
            dimensionId
            isDatabaseKey
            extQueryId
          }
          driverProperties {
            id
            driverId
            extQueryId
          }
        }
      }
      blocks {
        id
        name
        type
        pageId
        explanation {
          driverGridComparisonExplanations {
            driverId
            explanation
          }
        }
        blockConfig {
          blockViewOptions {
            viewAsType
            chartSize
            chartGroupingType
            dateRangeComparison {
              type
              selectedPeriod {
                start
                end
              }
              comparisonPeriod {
                start
                end
              }
            }
          }
          businessObjectSpecId
          comparisons {
            layerIds
          }
          dateRange {
            start
            end
          }
          columns {
            key
            width
            visible
          }
        }
        sortIndex
      }
      blocksPages {
        id
        createdByUserId
        name
        blockIds
        internalPageType
        internalPageVersion
        parent
        sortIndex
        dateRange {
          start
          end
        }
        layout {
          id
          columns {
            id
            blockIds
          }
        }
      }
      folders {
        id
        name
        type
        parent
        sortIndex
      }
      lastActualsTime
      namedVersions {
        mutationId
        name
      }
    }
  }
`;

export function useDatasetQuery(options: Omit<Urql.UseQueryArgs<DatasetQueryVariables>, 'query'>) {
  return Urql.useQuery<DatasetQuery, DatasetQueryVariables>({ query: DatasetDocument, ...options });
}
export const DatasetMutationHistoryDocument = gql`
  query datasetMutationHistory(
    $orgId: String!
    $limit: Int
    $layerId: String
    $includeUndo: Boolean!
    $beforeMutationId: ID
  ) {
    datasetMutationHistory(
      orgId: $orgId
      query: {
        limit: $limit
        layerId: $layerId
        includeUndo: $includeUndo
        beforeMutationId: $beforeMutationId
      }
    ) {
      ...DatasetMutationDataFragment
    }
  }
  ${DatasetMutationDataFragmentFragmentDoc}
`;

export function useDatasetMutationHistoryQuery(
  options: Omit<Urql.UseQueryArgs<DatasetMutationHistoryQueryVariables>, 'query'>,
) {
  return Urql.useQuery<DatasetMutationHistoryQuery, DatasetMutationHistoryQueryVariables>({
    query: DatasetMutationHistoryDocument,
    ...options,
  });
}
export const SnapshotForExtTablesDocument = gql`
  query snapshotForExtTables($orgId: String!, $sourceKeys: [String!]!) {
    snapshotForExtTables(orgId: $orgId, sourceKeys: $sourceKeys) {
      sourceKey
      snapshot {
        schema {
          version
          columns {
            id
          }
        }
        scopedRunIds
        data {
          rows {
            columnValues {
              column
              value
            }
          }
        }
      }
    }
  }
`;

export function useSnapshotForExtTablesQuery(
  options: Omit<Urql.UseQueryArgs<SnapshotForExtTablesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<SnapshotForExtTablesQuery, SnapshotForExtTablesQueryVariables>({
    query: SnapshotForExtTablesDocument,
    ...options,
  });
}
export const UpdateOrgSettingsDocument = gql`
  mutation updateOrgSettings($orgId: ID!, $update: GeneralSettingsUpdateInput!) {
    updateOrgSettings(orgId: $orgId, update: $update) {
      success
    }
  }
`;

export function useUpdateOrgSettingsMutation() {
  return Urql.useMutation<UpdateOrgSettingsMutation, UpdateOrgSettingsMutationVariables>(
    UpdateOrgSettingsDocument,
  );
}
export const CreateMergeLinkTokenDocument = gql`
  mutation createMergeLinkToken(
    $orgId: ID!
    $integrationSlug: String!
    $linkedAccountId: String
    $category: IntegrationCategory!
  ) {
    createMergeLinkToken(
      orgId: $orgId
      integrationSlug: $integrationSlug
      linkedAccountId: $linkedAccountId
      category: $category
    ) {
      token
      endUserOriginId
    }
  }
`;

export function useCreateMergeLinkTokenMutation() {
  return Urql.useMutation<CreateMergeLinkTokenMutation, CreateMergeLinkTokenMutationVariables>(
    CreateMergeLinkTokenDocument,
  );
}
export const CreateFiveTranConnectorDocument = gql`
  mutation createFiveTranConnector(
    $orgId: ID!
    $integrationSlug: String!
    $schema: String
    $schemaPrefix: String
    $table: String
  ) {
    createFiveTranConnector(
      orgId: $orgId
      slug: $integrationSlug
      schema: $schema
      schemaPrefix: $schemaPrefix
      table: $table
    ) {
      connectorCardURI
    }
  }
`;

export function useCreateFiveTranConnectorMutation() {
  return Urql.useMutation<
    CreateFiveTranConnectorMutation,
    CreateFiveTranConnectorMutationVariables
  >(CreateFiveTranConnectorDocument);
}
export const ExchangeMergePublicTokenDocument = gql`
  mutation exchangeMergePublicToken($orgId: ID!, $publicToken: String!, $endUserOriginId: String!) {
    exchangeMergePublicToken(
      orgId: $orgId
      publicToken: $publicToken
      endUserOriginId: $endUserOriginId
    ) {
      success
    }
  }
`;

export function useExchangeMergePublicTokenMutation() {
  return Urql.useMutation<
    ExchangeMergePublicTokenMutation,
    ExchangeMergePublicTokenMutationVariables
  >(ExchangeMergePublicTokenDocument);
}
export const DeleteAllMergeLinkedAccountsForIntegrationDocument = gql`
  mutation deleteAllMergeLinkedAccountsForIntegration(
    $orgId: ID!
    $integrationSlug: String!
    $category: IntegrationCategory!
  ) {
    deleteAllMergeLinkedAccountsForIntegration(
      orgId: $orgId
      integrationSlug: $integrationSlug
      category: $category
    ) {
      success
    }
  }
`;

export function useDeleteAllMergeLinkedAccountsForIntegrationMutation() {
  return Urql.useMutation<
    DeleteAllMergeLinkedAccountsForIntegrationMutation,
    DeleteAllMergeLinkedAccountsForIntegrationMutationVariables
  >(DeleteAllMergeLinkedAccountsForIntegrationDocument);
}
export const RemoveIntegrationDocument = gql`
  mutation removeIntegration(
    $orgId: ID!
    $provider: IntegrationProvider!
    $linkedAccountId: String!
  ) {
    removeIntegration(orgId: $orgId, provider: $provider, linkedAccountId: $linkedAccountId) {
      success
    }
  }
`;

export function useRemoveIntegrationMutation() {
  return Urql.useMutation<RemoveIntegrationMutation, RemoveIntegrationMutationVariables>(
    RemoveIntegrationDocument,
  );
}
export const CancelIntegrationQueryRunDocument = gql`
  mutation cancelIntegrationQueryRun($orgId: ID!, $queryId: String!, $runId: String!) {
    cancelIntegrationQueryRun(orgId: $orgId, queryId: $queryId, runId: $runId) {
      success
    }
  }
`;

export function useCancelIntegrationQueryRunMutation() {
  return Urql.useMutation<
    CancelIntegrationQueryRunMutation,
    CancelIntegrationQueryRunMutationVariables
  >(CancelIntegrationQueryRunDocument);
}
export const DeleteMergeLinkedAccountDocument = gql`
  mutation deleteMergeLinkedAccount($orgId: ID!, $endUserOriginId: String!) {
    deleteMergeLinkedAccount(orgId: $orgId, endUserOriginId: $endUserOriginId) {
      success
    }
  }
`;

export function useDeleteMergeLinkedAccountMutation() {
  return Urql.useMutation<
    DeleteMergeLinkedAccountMutation,
    DeleteMergeLinkedAccountMutationVariables
  >(DeleteMergeLinkedAccountDocument);
}
export const DeleteWorkatoLinkedAccountDocument = gql`
  mutation deleteWorkatoLinkedAccount($orgId: ID!, $linkedAccountId: String!) {
    deleteWorkatoLinkedAccount(orgId: $orgId, linkedAccountId: $linkedAccountId) {
      success
    }
  }
`;

export function useDeleteWorkatoLinkedAccountMutation() {
  return Urql.useMutation<
    DeleteWorkatoLinkedAccountMutation,
    DeleteWorkatoLinkedAccountMutationVariables
  >(DeleteWorkatoLinkedAccountDocument);
}
export const DeleteFiveTranConnectorDocument = gql`
  mutation deleteFiveTranConnector($orgId: ID!, $connectorId: String!) {
    deleteFiveTranConnector(orgId: $orgId, connectorId: $connectorId) {
      success
    }
  }
`;

export function useDeleteFiveTranConnectorMutation() {
  return Urql.useMutation<
    DeleteFiveTranConnectorMutation,
    DeleteFiveTranConnectorMutationVariables
  >(DeleteFiveTranConnectorDocument);
}
export const ForceResyncDocument = gql`
  mutation forceResync($orgId: ID!, $provider: IntegrationProvider!, $linkedAccountId: String!) {
    forceResync(orgId: $orgId, provider: $provider, linkedAccountId: $linkedAccountId) {
      success
    }
  }
`;

export function useForceResyncMutation() {
  return Urql.useMutation<ForceResyncMutation, ForceResyncMutationVariables>(ForceResyncDocument);
}
export const AllIntegrationsDocument = gql`
  query allIntegrations($orgId: ID!) {
    integrations {
      linkedAccounts(orgId: $orgId) {
        ...LinkedAccountFragment
      }
      availableIntegrations(orgId: $orgId) {
        ...IntegrationFragment
      }
      merge {
        accountingProperties(orgId: $orgId) {
          accounts {
            name
            remoteId
            linkedAccountId
          }
          contacts {
            name
            remoteId
            linkedAccountId
          }
          trackingCategories {
            name
            remoteId
            linkedAccountId
          }
        }
      }
    }
  }
  ${LinkedAccountFragmentFragmentDoc}
  ${IntegrationFragmentFragmentDoc}
`;

export function useAllIntegrationsQuery(
  options: Omit<Urql.UseQueryArgs<AllIntegrationsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AllIntegrationsQuery, AllIntegrationsQueryVariables>({
    query: AllIntegrationsDocument,
    ...options,
  });
}
export const SyncStatusDocument = gql`
  query syncStatus($orgId: ID!, $provider: IntegrationProvider!, $linkedAccountId: String!) {
    integrations {
      syncStatus(orgId: $orgId, provider: $provider, linkedAccountId: $linkedAccountId) {
        __typename
        ... on SyncStatusResult {
          status
          lastSyncTime
          lastSyncStartedTime
          lastRemoteSyncTime
        }
        ... on SyncStatusError {
          error
        }
      }
    }
  }
`;

export function useSyncStatusQuery(
  options: Omit<Urql.UseQueryArgs<SyncStatusQueryVariables>, 'query'>,
) {
  return Urql.useQuery<SyncStatusQuery, SyncStatusQueryVariables>({
    query: SyncStatusDocument,
    ...options,
  });
}
export const ConnectCardUriDocument = gql`
  query connectCardURI($orgId: ID!, $linkedAccountId: String!) {
    integrations {
      fivetran {
        connectCardURI(orgId: $orgId, linkedAccountId: $linkedAccountId)
      }
    }
  }
`;

export function useConnectCardUriQuery(
  options: Omit<Urql.UseQueryArgs<ConnectCardUriQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ConnectCardUriQuery, ConnectCardUriQueryVariables>({
    query: ConnectCardUriDocument,
    ...options,
  });
}
export const CreatePuzzleLinkTokenDocument = gql`
  mutation createPuzzleLinkToken($orgId: ID!) {
    createPuzzleLinkToken(orgId: $orgId) {
      token
    }
  }
`;

export function useCreatePuzzleLinkTokenMutation() {
  return Urql.useMutation<CreatePuzzleLinkTokenMutation, CreatePuzzleLinkTokenMutationVariables>(
    CreatePuzzleLinkTokenDocument,
  );
}
export const CompletePuzzleLinkDocument = gql`
  mutation completePuzzleLink($orgId: ID!) {
    completePuzzleLink(orgId: $orgId) {
      success
    }
  }
`;

export function useCompletePuzzleLinkMutation() {
  return Urql.useMutation<CompletePuzzleLinkMutation, CompletePuzzleLinkMutationVariables>(
    CompletePuzzleLinkDocument,
  );
}
export const CreateRipplingLinkTokenDocument = gql`
  mutation createRipplingLinkToken($orgId: ID!, $authCode: String!) {
    createRipplingLinkToken(orgId: $orgId, authCode: $authCode) {
      redirectURL
    }
  }
`;

export function useCreateRipplingLinkTokenMutation() {
  return Urql.useMutation<
    CreateRipplingLinkTokenMutation,
    CreateRipplingLinkTokenMutationVariables
  >(CreateRipplingLinkTokenDocument);
}
export const CreateWorkatoLinkTokenDocument = gql`
  mutation createWorkatoLinkToken($orgId: ID!) {
    createWorkatoLinkToken(orgId: $orgId) {
      token
    }
  }
`;

export function useCreateWorkatoLinkTokenMutation() {
  return Urql.useMutation<CreateWorkatoLinkTokenMutation, CreateWorkatoLinkTokenMutationVariables>(
    CreateWorkatoLinkTokenDocument,
  );
}
export const StartIntegrationQueryRunDocument = gql`
  subscription startIntegrationQueryRun($orgId: ID!, $queryId: String!, $runId: String!) {
    startIntegrationQueryRun(orgId: $orgId, queryId: $queryId, runId: $runId) {
      id
      queryId
      status
      result {
        sqlQueryResult {
          rows {
            columnValues {
              column
              value
            }
          }
        }
      }
      error {
        code
        message
      }
    }
  }
`;

export function useStartIntegrationQueryRunSubscription<
  TData = StartIntegrationQueryRunSubscription,
>(
  options: Omit<Urql.UseSubscriptionArgs<StartIntegrationQueryRunSubscriptionVariables>, 'query'>,
  handler?: Urql.SubscriptionHandler<StartIntegrationQueryRunSubscription, TData>,
) {
  return Urql.useSubscription<
    StartIntegrationQueryRunSubscription,
    TData,
    StartIntegrationQueryRunSubscriptionVariables
  >({ query: StartIntegrationQueryRunDocument, ...options }, handler);
}
export const IntegrationsMetadataDocument = gql`
  query integrationsMetadata($orgId: ID!) {
    integrations {
      metadata(orgId: $orgId) {
        workatoId
        fivetranGroupId
      }
    }
  }
`;

export function useIntegrationsMetadataQuery(
  options: Omit<Urql.UseQueryArgs<IntegrationsMetadataQueryVariables>, 'query'>,
) {
  return Urql.useQuery<IntegrationsMetadataQuery, IntegrationsMetadataQueryVariables>({
    query: IntegrationsMetadataDocument,
    ...options,
  });
}
export const SetLinkedIntegrationMetadataDocument = gql`
  mutation setLinkedIntegrationMetadata(
    $orgId: ID!
    $provider: IntegrationProvider!
    $linkedAccountId: String!
    $propagateDataForward: Boolean!
    $overrideForecastData: Boolean!
  ) {
    setLinkedIntegrationMetadata(
      orgId: $orgId
      provider: $provider
      linkedAccountId: $linkedAccountId
      propagateDataForward: $propagateDataForward
      overrideForecastData: $overrideForecastData
    ) {
      success
    }
  }
`;

export function useSetLinkedIntegrationMetadataMutation() {
  return Urql.useMutation<
    SetLinkedIntegrationMetadataMutation,
    SetLinkedIntegrationMetadataMutationVariables
  >(SetLinkedIntegrationMetadataDocument);
}
export const LogoutDocument = gql`
  mutation logout {
    logout {
      success
    }
  }
`;

export function useLogoutMutation() {
  return Urql.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument);
}
export const DatasetMutationDocument = gql`
  mutation datasetMutation(
    $orgId: ID!
    $mutationId: ID!
    $prevMutationId: ID
    $mutation: DatasetMutationInput!
    $layerId: ID
  ) {
    datasetMutation(
      orgId: $orgId
      mutationId: $mutationId
      prevMutationId: $prevMutationId
      mutation: $mutation
      layerId: $layerId
    ) {
      ... on DatasetMutationWithLastMutationIdResponse {
        success
        lastMutationId
        errors {
          ... on ClientOutOfSyncMutationError {
            message
            __typename
          }
          ... on UnableToApplyMutationError {
            message
            ops
            __typename
          }
        }
      }
    }
  }
`;

export function useDatasetMutationMutation() {
  return Urql.useMutation<DatasetMutationMutation, DatasetMutationMutationVariables>(
    DatasetMutationDocument,
  );
}
export const UndoDocument = gql`
  mutation undo(
    $orgId: ID!
    $mutationId: ID!
    $prevMutationId: ID!
    $undoMutationId: ID!
    $undoMutationInput: UndoMutationInput
  ) {
    undo(
      orgId: $orgId
      mutationId: $mutationId
      prevMutationId: $prevMutationId
      undoMutationId: $undoMutationId
      undoMutationInput: $undoMutationInput
    ) {
      success
    }
  }
`;

export function useUndoMutation() {
  return Urql.useMutation<UndoMutation, UndoMutationVariables>(UndoDocument);
}
export const OrgCreateDocument = gql`
  mutation orgCreate($orgName: String!, $slug: String, $isDemoOrg: Boolean) {
    orgCreate(orgName: $orgName, slug: $slug, isDemoOrg: $isDemoOrg) {
      id
      name
      slug
      subscriptionType
    }
  }
`;

export function useOrgCreateMutation() {
  return Urql.useMutation<OrgCreateMutation, OrgCreateMutationVariables>(OrgCreateDocument);
}
export const OrgDocument = gql`
  query org($id: ID!) {
    org(orgId: $id) {
      id
      slug
    }
  }
`;

export function useOrgQuery(options: Omit<Urql.UseQueryArgs<OrgQueryVariables>, 'query'>) {
  return Urql.useQuery<OrgQuery, OrgQueryVariables>({ query: OrgDocument, ...options });
}
export const OrgImportStartTimeDocument = gql`
  query orgImportStartTime($id: ID!) {
    org(orgId: $id) {
      importStartTime
    }
  }
`;

export function useOrgImportStartTimeQuery(
  options: Omit<Urql.UseQueryArgs<OrgImportStartTimeQueryVariables>, 'query'>,
) {
  return Urql.useQuery<OrgImportStartTimeQuery, OrgImportStartTimeQueryVariables>({
    query: OrgImportStartTimeDocument,
    ...options,
  });
}
export const AddControlEntriesDocument = gql`
  mutation addControlEntries($orgId: ID!, $controlEntries: [AddControlEntryInput!]!) {
    addControlEntries(orgId: $orgId, controlEntries: $controlEntries) {
      ...AccessResourceDataFragment
    }
  }
  ${AccessResourceDataFragmentFragmentDoc}
`;

export function useAddControlEntriesMutation() {
  return Urql.useMutation<AddControlEntriesMutation, AddControlEntriesMutationVariables>(
    AddControlEntriesDocument,
  );
}
export const RemoveControlEntriesDocument = gql`
  mutation removeControlEntries($orgId: ID!, $controlEntries: [RemoveControlEntryInput!]!) {
    removeControlEntries(orgId: $orgId, controlEntries: $controlEntries) {
      ...AccessResourceDataFragment
    }
  }
  ${AccessResourceDataFragmentFragmentDoc}
`;

export function useRemoveControlEntriesMutation() {
  return Urql.useMutation<RemoveControlEntriesMutation, RemoveControlEntriesMutationVariables>(
    RemoveControlEntriesDocument,
  );
}
export const AccessResourcesDocument = gql`
  query accessResources($orgId: ID!) {
    accessResources(orgId: $orgId) {
      ...AccessResourceDataFragment
    }
  }
  ${AccessResourceDataFragmentFragmentDoc}
`;

export function useAccessResourcesQuery(
  options: Omit<Urql.UseQueryArgs<AccessResourcesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AccessResourcesQuery, AccessResourcesQueryVariables>({
    query: AccessResourcesDocument,
    ...options,
  });
}
export const AccessShareLinkDocument = gql`
  mutation accessShareLink($nonce: String!) {
    accessShareLink(nonce: $nonce) {
      orgSlug
      pageId
      layerIds
    }
  }
`;

export function useAccessShareLinkMutation() {
  return Urql.useMutation<AccessShareLinkMutation, AccessShareLinkMutationVariables>(
    AccessShareLinkDocument,
  );
}
export const OrgShareLinksDocument = gql`
  query orgShareLinks($orgSlug: String!) {
    me {
      orgs(orgSlug: $orgSlug) {
        shareLinks {
          id
          pageId
          nonce
          layerIds
        }
      }
    }
  }
`;

export function useOrgShareLinksQuery(
  options: Omit<Urql.UseQueryArgs<OrgShareLinksQueryVariables>, 'query'>,
) {
  return Urql.useQuery<OrgShareLinksQuery, OrgShareLinksQueryVariables>({
    query: OrgShareLinksDocument,
    ...options,
  });
}
export const GenerateShareLinkDocument = gql`
  mutation generateShareLink($orgId: ID!, $pageId: String!, $layerIds: [String!]) {
    generateShareLink(orgId: $orgId, pageId: $pageId, layerIds: $layerIds) {
      nonce
    }
  }
`;

export function useGenerateShareLinkMutation() {
  return Urql.useMutation<GenerateShareLinkMutation, GenerateShareLinkMutationVariables>(
    GenerateShareLinkDocument,
  );
}
export const DeleteShareLinksDocument = gql`
  mutation deleteShareLinks($orgId: ID!, $pageId: String!) {
    deleteShareLinks(orgId: $orgId, pageId: $pageId) {
      success
    }
  }
`;

export function useDeleteShareLinksMutation() {
  return Urql.useMutation<DeleteShareLinksMutation, DeleteShareLinksMutationVariables>(
    DeleteShareLinksDocument,
  );
}
export const DoesShareLinkExistDocument = gql`
  mutation doesShareLinkExist($nonce: String!) {
    doesShareLinkExist(nonce: $nonce)
  }
`;

export function useDoesShareLinkExistMutation() {
  return Urql.useMutation<DoesShareLinkExistMutation, DoesShareLinkExistMutationVariables>(
    DoesShareLinkExistDocument,
  );
}
export const PublishScenarioDocument = gql`
  mutation publishScenario($orgId: ID!, $layerId: String!) {
    publishScenario(orgId: $orgId, layerId: $layerId) {
      ...AccessResourceDataFragment
    }
  }
  ${AccessResourceDataFragmentFragmentDoc}
`;

export function usePublishScenarioMutation() {
  return Urql.useMutation<PublishScenarioMutation, PublishScenarioMutationVariables>(
    PublishScenarioDocument,
  );
}
export const UnpublishScenarioDocument = gql`
  mutation UnpublishScenario($orgId: ID!, $layerId: String!) {
    unpublishScenario(orgId: $orgId, layerId: $layerId) {
      success
    }
  }
`;

export function useUnpublishScenarioMutation() {
  return Urql.useMutation<UnpublishScenarioMutation, UnpublishScenarioMutationVariables>(
    UnpublishScenarioDocument,
  );
}
export const GetTemplateOrgsDocument = gql`
  query getTemplateOrgs {
    templateOrgs {
      id
      name
      description
      orgId
      slug
    }
  }
`;

export function useGetTemplateOrgsQuery(
  options?: Omit<Urql.UseQueryArgs<GetTemplateOrgsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetTemplateOrgsQuery, GetTemplateOrgsQueryVariables>({
    query: GetTemplateOrgsDocument,
    ...options,
  });
}
export const MeDocument = gql`
  query me($orgSlug: String) {
    me {
      __typename
      id
      name
      email
      createdAt
      profileImage
      intercomHash
      hasSuperPermission
      authenticatedUser {
        id
        name
        email
        createdAt
        intercomHash
        profileImage
        hasSuperPermission
      }
      orgs(orgSlug: $orgSlug) {
        __typename
        id
        createdAt
        name
        slug
        isActive
        users {
          __typename
          id
          name
          profileImage
          email
          orgRole
        }
        invitedUsers {
          ...UserInviteFragment
        }
        myOrgRole
        isTemplateOrg
        generalSettings {
          __typename
          defaultCurrencyISOCode
          negativeDisplay
          defaultDecimalPrecision
        }
        importStartTime
      }
    }
  }
  ${UserInviteFragmentFragmentDoc}
`;

export function useMeQuery(options?: Omit<Urql.UseQueryArgs<MeQueryVariables>, 'query'>) {
  return Urql.useQuery<MeQuery, MeQueryVariables>({ query: MeDocument, ...options });
}
export const UpdateUserOrgRoleDocument = gql`
  mutation updateUserOrgRole($orgId: ID!, $userId: ID!, $role: OrgRole!) {
    updateUserOrgRole(orgId: $orgId, userId: $userId, role: $role) {
      success
    }
  }
`;

export function useUpdateUserOrgRoleMutation() {
  return Urql.useMutation<UpdateUserOrgRoleMutation, UpdateUserOrgRoleMutationVariables>(
    UpdateUserOrgRoleDocument,
  );
}
export const OrgInviteUserToRoleDocument = gql`
  mutation orgInviteUserToRole($orgId: ID!, $invites: [InviteRoleInput!]!) {
    orgInviteUserToRole(orgId: $orgId, invites: $invites) {
      ...UserInviteFragment
    }
  }
  ${UserInviteFragmentFragmentDoc}
`;

export function useOrgInviteUserToRoleMutation() {
  return Urql.useMutation<OrgInviteUserToRoleMutation, OrgInviteUserToRoleMutationVariables>(
    OrgInviteUserToRoleDocument,
  );
}
export const OrgInviteUserToResourceDocument = gql`
  mutation orgInviteUserToResource($orgId: ID!, $invites: [InviteResourceInput!]!) {
    orgInviteUserToResource(orgId: $orgId, invites: $invites) {
      ...UserInviteFragment
    }
  }
  ${UserInviteFragmentFragmentDoc}
`;

export function useOrgInviteUserToResourceMutation() {
  return Urql.useMutation<
    OrgInviteUserToResourceMutation,
    OrgInviteUserToResourceMutationVariables
  >(OrgInviteUserToResourceDocument);
}
export const OrgDeleteUserResourceInviteDocument = gql`
  mutation orgDeleteUserResourceInvite(
    $orgId: ID!
    $email: String!
    $accessResource: AccessResourceInput!
  ) {
    orgDeleteUserResourceInvite(orgId: $orgId, email: $email, accessResource: $accessResource) {
      ...UserInviteFragment
    }
  }
  ${UserInviteFragmentFragmentDoc}
`;

export function useOrgDeleteUserResourceInviteMutation() {
  return Urql.useMutation<
    OrgDeleteUserResourceInviteMutation,
    OrgDeleteUserResourceInviteMutationVariables
  >(OrgDeleteUserResourceInviteDocument);
}
export const RemoveOrgUserDocument = gql`
  mutation removeOrgUser($orgId: ID!, $userId: ID!) {
    removeOrgUser(orgId: $orgId, userId: $userId) {
      success
    }
  }
`;

export function useRemoveOrgUserMutation() {
  return Urql.useMutation<RemoveOrgUserMutation, RemoveOrgUserMutationVariables>(
    RemoveOrgUserDocument,
  );
}
export const RemoveInvitedUserDocument = gql`
  mutation removeInvitedUser($orgId: ID!, $email: String!) {
    removeInvitedUser(orgId: $orgId, email: $email) {
      success
    }
  }
`;

export function useRemoveInvitedUserMutation() {
  return Urql.useMutation<RemoveInvitedUserMutation, RemoveInvitedUserMutationVariables>(
    RemoveInvitedUserDocument,
  );
}
export const FetchVeltTokenDocument = gql`
  query fetchVeltToken($orgId: String!) {
    veltToken(orgId: $orgId)
  }
`;

export function useFetchVeltTokenQuery(
  options: Omit<Urql.UseQueryArgs<FetchVeltTokenQueryVariables>, 'query'>,
) {
  return Urql.useQuery<FetchVeltTokenQuery, FetchVeltTokenQueryVariables>({
    query: FetchVeltTokenDocument,
    ...options,
  });
}
