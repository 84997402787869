import { Flex } from '@chakra-ui/react';
import { deepEqual } from 'fast-equals';
import { isNumber } from 'lodash';
import React, { useContext } from 'react';

import { DataArbiter } from 'components/AgGridComponents/helpers/gridDatasource/DataArbiter';
import DriverTimeSeriesCellLight from 'components/DriverTimeSeriesCell/DriverTimeSeriesCellLight';
import LayerDriverTimeSeriesCell, {
  DriverCellValueCacheContext,
  getCacheKey,
} from 'components/DriverTimeSeriesRow/LayerDriverTimeSeriesCell';
import {
  useErrorTimeSeriesBaseline,
  useIsLoadingByMonthKeyBaseline,
  useSourceByMonthKeyBaseline,
  useThresholdDirection,
  useTimeSeriesBaseline,
} from 'components/DriverTimeSeriesRow/useDriverCellValue';
import { RenderModeContext } from 'components/List/enhanceComponent';
import TimeSeriesRowContainer from 'components/TimeSeriesRowContainer/TimeSeriesRowContainer';
import { CELL_HEIGHT_IN_PX_STR } from 'config/cells';
import { DriverRowContext } from 'config/driverRowContext';
import { ComparisonColumn } from 'generated/graphql';
import {
  getCellColumnKey,
  getMonthColumnKey,
  stringifyCellColumnKey,
  stringifyMonthColumnKey,
} from 'helpers/cells';
import { toPxString } from 'helpers/styles';
import useAppSelector from 'hooks/useAppSelector';
import { useResolvedBlockConfiguration } from 'hooks/useResolvedBlockConfiguration';
import { EventId } from 'reduxStore/models/events';
import { LayerId } from 'reduxStore/models/layers';
import { ErrorTimeSeries, NumericTimeSeries } from 'reduxStore/models/timeSeries';
import { entityLoadingByMonthKeySelector } from 'selectors/calculationsSelector';
import {
  driverErrorTimeSeriesForLayerSelector,
  driverTimeSeriesForLayerSelector,
  driverTimeSeriesSourceByMonthKeySelector,
} from 'selectors/driverTimeSeriesSelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import { CalculationError, isCalculationError, isCalculationValue } from 'types/dataset';
import { MonthKey } from 'types/datetime';

interface Props {
  eventIdsByMonthKey?: Record<MonthKey, EventId>;
}

const DriverTimeSeriesRow: React.FC<Props> = ({ eventIdsByMonthKey }) => {
  const renderMode = useContext(RenderModeContext);
  if (renderMode !== 'FULL') {
    return <DriverTimeSeriesRowLight />;
  }

  return <DriverTimeSeriesRowFull eventIdsByMonthKey={eventIdsByMonthKey} />;
};

const DriverTimeSeriesRowFull: React.FC<Props> = ({ eventIdsByMonthKey }) => {
  const {
    driverId,
    comparisonRowLayerId,
    monthKeys: baselineMonthKeys,
    comparisonTimePeriod,
    timePeriodMonthKeys,
  } = useContext(DriverRowContext);

  const { comparisonLayerIds, timeseriesColumns } = useResolvedBlockConfiguration();

  const monthKeys = timePeriodMonthKeys ?? baselineMonthKeys;

  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const timeSeriesBaseline = useTimeSeriesBaseline();

  const layerIds = comparisonLayerIds.length === 0 ? [currentLayerId] : comparisonLayerIds;

  const timeSeriesByComparisonLayerId = useAppSelector((state) => {
    const res: Record<LayerId, NumericTimeSeries> = {};

    layerIds.forEach((layerId) => {
      const timeSeries = driverTimeSeriesForLayerSelector(state, {
        id: driverId,
        layerId,
        start: comparisonTimePeriod != null ? monthKeys[0] : undefined,
        end: comparisonTimePeriod != null ? monthKeys[monthKeys.length - 1] : undefined,
      });
      res[layerId] = timeSeries;
    });

    return res;
  }, deepEqual);

  const isLoadingByMonthKeyBaseline = useIsLoadingByMonthKeyBaseline();
  const isLoadingByMonthKeyLayer = useAppSelector((state) => {
    const res: Record<LayerId, Record<MonthKey, boolean>> = {};

    layerIds.forEach((layerId) => {
      const isLoading = entityLoadingByMonthKeySelector(state, {
        id: driverId,
        layerId,
        monthKeys,
      });

      res[layerId] = isLoading;
    });

    return res;
  }, deepEqual);

  const sourceByMonthKeyBaseline = useSourceByMonthKeyBaseline();
  const sourceByMonthKeyLayer = useAppSelector((state) => {
    const res: Record<LayerId, Record<MonthKey, 'actuals' | 'forecast'>> = {};
    layerIds.forEach((layerId) => {
      const source = driverTimeSeriesSourceByMonthKeySelector(state, {
        id: driverId,
        layerId,
      });

      res[layerId] = source;
    });

    return res;
  }, deepEqual);

  const thresholdDirection = useThresholdDirection();

  const errorTimeSeriesBaseline = useErrorTimeSeriesBaseline();
  const errorTimeSeriesByComparisonLayerId = useAppSelector((state) => {
    const res: Record<LayerId, ErrorTimeSeries> = {};
    layerIds.forEach((layerId) => {
      const timeSeries = driverErrorTimeSeriesForLayerSelector(state, {
        id: driverId,
        layerId,
        start: monthKeys[0],
        end: monthKeys[monthKeys.length - 1],
      });
      res[layerId] = timeSeries;
    });
    return res;
  }, deepEqual);

  return (
    <TimeSeriesRowContainer>
      {timeseriesColumns.map(({ label, uniqueLabel, mks, subLabel, rollupType }) => {
        // The cell's layer ID either comes from the column or the row, depending on the comparison layout.
        // If neither exists, we should use the current layer ID.
        const cellLayerId = subLabel?.layerId ?? comparisonRowLayerId ?? currentLayerId;

        return (
          <LayerDriverTimeSeriesCell
            key={stringifyCellColumnKey(
              getCellColumnKey(mks[0], label ?? uniqueLabel, rollupType, subLabel),
            )}
            label={label}
            uniqueLabel={uniqueLabel}
            monthKeys={mks}
            subLabel={subLabel}
            eventIdsByMonthKey={eventIdsByMonthKey}
            rollupType={rollupType}
            layerId={cellLayerId}
            sourceByMonthKeyBaseline={sourceByMonthKeyBaseline}
            sourceByMonthKeyLayer={sourceByMonthKeyLayer[cellLayerId]}
            thresholdDirection={thresholdDirection}
            timeSeriesBaseline={timeSeriesBaseline}
            timeSeriesLayer={timeSeriesByComparisonLayerId[cellLayerId]}
            errorTimeSeriesBaseline={errorTimeSeriesBaseline}
            errorTimeSeriesLayer={errorTimeSeriesByComparisonLayerId[cellLayerId]}
            isLoadingByMonthKeyBaseline={isLoadingByMonthKeyBaseline}
            isLoadingByMonthKeyLayer={isLoadingByMonthKeyLayer[cellLayerId]}
          />
        );
      })}
    </TimeSeriesRowContainer>
  );
};

const DriverTimeSeriesRowLight: React.FC = () => {
  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const cacheContext = useContext(DriverCellValueCacheContext);
  const { driverId, comparisonRowLayerId } = useContext(DriverRowContext);
  const { timeseriesColumns: columns, columnWidthsByType } = useResolvedBlockConfiguration();

  return (
    <Flex flexDirection="row" height={CELL_HEIGHT_IN_PX_STR}>
      {columns.map(({ label, uniqueLabel, mks, subLabel, rollupType }, index) => {
        const monthKey = mks[0];
        const cellLayerId = subLabel?.layerId ?? comparisonRowLayerId ?? currentLayerId;
        const width = columnWidthsByType[monthKey];
        const cache = cacheContext?.cache ?? {};
        const cacheKey = getCacheKey({
          driverId,
          layerId: currentLayerId,
          label: label ?? uniqueLabel,
          monthKey,
        });
        const comparisonColumn = subLabel?.column;
        const cacheEntry = cache[cacheKey];
        const color = cacheEntry?.color ?? 'gray.500';
        let value = cacheEntry?.value;
        let error: CalculationError | undefined;

        if (
          mks.length === 1 &&
          (comparisonColumn == null ||
            comparisonColumn === ComparisonColumn.BaselineVersion ||
            comparisonColumn === ComparisonColumn.LatestVersion ||
            comparisonColumn === ComparisonColumn.RowVersion)
        ) {
          const dataCachedValueOrErr = DataArbiter.get().getCachedValue({
            id: driverId,
            layerId: cellLayerId,
            monthKey,
          });

          if (isCalculationError(dataCachedValueOrErr)) {
            error = dataCachedValueOrErr;
          } else if (
            isCalculationValue(dataCachedValueOrErr) &&
            isNumber(dataCachedValueOrErr.value)
          ) {
            value = dataCachedValueOrErr.value;
          }
        }
        const columnKey = getMonthColumnKey(mks[0], rollupType, subLabel);

        return value != null || error != null ? (
          <DriverTimeSeriesCellLight
            key={stringifyMonthColumnKey(columnKey)}
            monthKey={monthKey}
            comparisonColumn={comparisonColumn}
            subColumn={subLabel}
            rollupType={rollupType}
            value={value}
            error={error}
            color={color}
          />
        ) : (
          <Flex
            key={`${monthKey}-${index}`}
            width={toPxString(width)}
            justifyContent="flex-end"
            pr={2}
            height="full"
          >
            <Flex alignSelf="center" width="50%" height={3} backgroundColor="gray.200" />
          </Flex>
        );
      })}
    </Flex>
  );
};

export default React.memo(DriverTimeSeriesRow);
