import classNames from 'classnames';
import React, { memo, useContext, useMemo } from 'react';

import { useComparisonBorderState } from 'components/CellBorder/useComparisonBorderState';
import { CellSelectionState, CellSelectionStateContext, CellType } from 'config/cells';
import { CELL_BORDER_CLASS } from 'config/driverGridView';
import { isColumnKeyEqual, isMonthColumnKey } from 'helpers/cells';
import useAppSelector from 'hooks/useAppSelector';
import { useOptionalBlockId } from 'hooks/useBlockContext';
import { useCellRef } from 'hooks/useCellRefContext';
import { useCellSelectionStateContext } from 'hooks/useCellSelectionStateContext';
import { useResolvedBlockConfiguration } from 'hooks/useResolvedBlockConfiguration';
import { isModelViewColumnType } from 'reduxStore/reducers/helpers/submodels';
import { lastActualsMonthKeyForLayerSelector } from 'selectors/lastActualsSelector';
import { driverPropertyColumnSelector } from 'selectors/tableColumnsSelector';

import styles from './CellBorder.module.scss';

export function getCellBorderClasses(
  {
    isSelected,
    isCopied,
    isActive,
    isLeftCellSelected,
    isRightCellSelected,
    isAboveCellSelected,
    isBelowCellSelected,
    hasRightBorder,
    hasSolidRightBorder,
    hasBottomBorder,
    hasSolidBottomBorder,
    isForecastCell,
  }: CellSelectionState & {
    hasRightBorder: boolean;
    hasSolidRightBorder: boolean;
    hasBottomBorder: boolean;
    hasSolidBottomBorder: boolean;
    isForecastCell?: boolean;
  },
  isCopiedAndSelected: boolean,
) {
  const commonStyles = {
    [CELL_BORDER_CLASS]: true,
    [styles.cellBorder]: true,
    [styles.hasBottomBorder]: hasBottomBorder,
    [styles.hasRightBorder]: hasRightBorder,
    [styles.hasSolidBottomBorder]: hasSolidBottomBorder,
    [styles.hasSolidRightBorder]: hasSolidRightBorder,
    [styles.isAboveCellSelected]: isAboveCellSelected,
    [styles.isBelowCellSelected]: isBelowCellSelected,
    [styles.isLeftCellSelected]: isLeftCellSelected,
    [styles.isRightCellSelected]: isRightCellSelected,
    [styles.isForecastCell]: isForecastCell,
  };
  if (isCopied && !isCopiedAndSelected) {
    // draw copied borders independent of other cells; the copied borders can coexist with other borders
    return classNames(styles.copiedAndNotSelected, commonStyles);
  } else if (isActive) {
    return classNames(styles.active, commonStyles, {
      [styles.isCopiedAndSelected]: isCopiedAndSelected,
    });
  } else if (isSelected) {
    return classNames(styles.selected, commonStyles);
  }
  return classNames(styles.default, commonStyles);
}

interface BorderProps {
  isCopiedAndSelected?: boolean;
  isRowHeader?: boolean;
}

const Border: React.FC<BorderProps> = memo(
  ({ isCopiedAndSelected = false, isRowHeader = false }) => {
    const cellState = useCellSelectionStateContext();
    const {
      isObjectTimeseriesView,
      blockCellColumnKeys: columnKeys,
      hasVisibleFieldSpecTimeSeries: hasTimeSeries,
      businessObjectSpecStartFieldId: objectSpecStartFieldId,
      lastStickyColumnType,
    } = useResolvedBlockConfiguration();

    const { cellRef } = useCellRef();
    const blockId = useOptionalBlockId();

    const driverPropertyColumn = useAppSelector((state) =>
      blockId != null &&
      'columnType' in cellRef.columnKey &&
      isModelViewColumnType(cellRef.columnKey.columnType)
        ? driverPropertyColumnSelector(state, { blockId, columnType: cellRef.columnKey.columnType })
        : null,
    );

    const lastCloseMonthKey = useAppSelector(lastActualsMonthKeyForLayerSelector);

    const isDriverPropertyColumnSticky =
      driverPropertyColumn != null &&
      driverPropertyColumn.type === lastStickyColumnType &&
      // If comparison layer columns are being shown, only the last comparison layer subcolumn should be sticky
      'columnLayerId' in cellRef.columnKey &&
      driverPropertyColumn.layerIds[driverPropertyColumn.layerIds.length - 1] ===
        cellRef.columnKey.columnLayerId;

    const isObjectFieldColumnSticky =
      'objectFieldSpecId' in cellRef.columnKey &&
      cellRef.columnKey.objectFieldSpecId === lastStickyColumnType;
    const isLastStickyColumn = isDriverPropertyColumnSticky || isObjectFieldColumnSticky;

    const isAddColumnButton =
      'columnType' in cellRef.columnKey && cellRef.columnKey.columnType === 'addNewColumn';

    const isNameColumn =
      'columnType' in cellRef.columnKey && cellRef.columnKey.columnType === 'name';

    const isAddNewPropertyButton =
      cellRef.type === CellType.ObjectFieldTimeSeries &&
      cellRef.rowKey.fieldSpecId == null &&
      'columnType' in cellRef.columnKey &&
      cellRef.columnKey.columnType === 'property';

    const isStartField =
      'objectFieldSpecId' in cellRef.columnKey &&
      cellRef.columnKey.objectFieldSpecId === objectSpecStartFieldId;

    const isLastCloseMonth = useMemo(() => {
      if (isMonthColumnKey(cellRef.columnKey)) {
        return cellRef.columnKey.monthKey === lastCloseMonthKey;
      }
      return false;
    }, [cellRef.columnKey, lastCloseMonthKey]);

    const isLastColumnForMonth = useMemo(() => {
      let isLastColumn = true;
      if (isMonthColumnKey(cellRef.columnKey)) {
        const mk = cellRef.columnKey.monthKey;
        const rollupType = cellRef.columnKey.rollupType;
        const sameMonthColKeys = columnKeys.filter(
          (colKey) =>
            isMonthColumnKey(colKey) && colKey.monthKey === mk && colKey.rollupType === rollupType,
        );
        if (
          sameMonthColKeys.length > 0 &&
          !isColumnKeyEqual(sameMonthColKeys[sameMonthColKeys.length - 1], cellRef.columnKey)
        ) {
          isLastColumn = false;
        }
      }
      return isLastColumn;
    }, [cellRef.columnKey, columnKeys]);

    const hasSolidRightBorder =
      (isAddColumnButton && hasTimeSeries) ||
      isLastStickyColumn ||
      (isLastCloseMonth && isLastColumnForMonth);

    let borderState = {
      hasSolidRightBorder,
      hasRightBorder: hasSolidRightBorder || !isRowHeader,
      hasBottomBorder: true,
      hasSolidBottomBorder: isRowHeader,
    };

    if (isObjectTimeseriesView) {
      if (isNameColumn || isStartField) {
        borderState = {
          hasSolidRightBorder: false,
          hasRightBorder: false,
          hasBottomBorder: false,
          hasSolidBottomBorder: false,
        };
      } else if (isAddNewPropertyButton) {
        borderState = {
          hasSolidRightBorder: false,
          hasRightBorder: false,
          hasBottomBorder: true,
          hasSolidBottomBorder: false,
        };
      }
    }

    const comparisonBorderState = useComparisonBorderState({
      borderState,
      blockId,
      cellRef,
      driverPropertyColumn,
      isRowHeader,
      lastStickyColumnType,
      isLastStickyColumn,
    });

    borderState = { ...borderState, ...comparisonBorderState };

    return (
      <div
        className={getCellBorderClasses(
          {
            ...cellState,
            ...borderState,
          },
          isCopiedAndSelected,
        )}
        data-testid={
          cellState.isActive
            ? 'active-cell-border'
            : cellState.isSelected
              ? 'selected-cell-border'
              : 'cell-border'
        }
      />
    );
  },
);

const CellBorder: React.FC<{ isRowHeader?: boolean }> = ({ isRowHeader = false }) => {
  const { isCopied, isSelected } = useContext(CellSelectionStateContext);
  return (
    <>
      <Border isRowHeader={isRowHeader} />
      {/* if necessary, draw both copied & selected borders  */}
      {isCopied && isSelected && <Border isCopiedAndSelected />}
    </>
  );
};

export default React.memo(CellBorder);
