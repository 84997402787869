import { Flex } from '@chakra-ui/react';
import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { useComparedLayers } from '@features/CompareScenarios';
import { ToggleSection } from '@features/CompareScenarios/blocks/common/ToggleSection';
import { useCalculations } from '@features/CompareScenarios/blocks/common/useCalculations';
import { KpiCharts } from 'components/CompareScenariosModalContent/KpisComparison';
import DriverCharts from 'components/DriverCharts/DriverCharts';
import { DriverType, FormulaEntityType, LeverType } from 'generated/graphql';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useRequestDriverGridDataView } from 'hooks/useRequestDriverGridDataView';
import { Layer, LayerId } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { enableAgChartsSelector } from 'selectors/launchDarklySelector';
import { currentLayerSelector } from 'selectors/layerSelector';
import { GroupWithDrivers } from 'selectors/submodelTableGroupsSelector';

export const KPIBlock = () => {
  const { blockId } = useBlockContext();

  const { currentLayer, mergeLayer } = useComparedLayers();
  const allKpiIds = useAppSelector((state) =>
    kpiDriverIdsSelector(state, { layerId: currentLayer?.id }),
  );
  const enabledAgCharts = useAppSelector(enableAgChartsSelector);
  useCalculations({ type: FormulaEntityType.Driver, ids: allKpiIds }, currentLayer, mergeLayer);

  const groups: GroupWithDrivers[] = useMemo(() => {
    return [{ name: '', driverIds: allKpiIds }];
  }, [allKpiIds]);

  useRequestDriverGridDataView({
    groups,
    blockId,
    pageSize: allKpiIds.length,
  });

  if (allKpiIds.length === 0) {
    return null;
  }

  return (
    <Flex flexDirection="column" data-testid="kpi-comparison">
      <ToggleSection
        isDefaultOpen
        heading="KPIs impacted"
        textProps={{ fontSize: 'md', fontWeight: '600' }}
        sectionProps={{ marginTop: 1, marginBottom: 2, minWidth: '1026px' }}
      >
        {enabledAgCharts ? (
          <KpiCharts driverIds={allKpiIds} hasLegend />
        ) : (
          <DriverCharts driverIds={allKpiIds} isEditable={false} showEventImpact={false} />
        )}
      </ToggleSection>
    </Flex>
  );
};

const kpiDriverIdsSelector = createSelector(
  (state: RootState, { layerId }: { layerId?: LayerId }): Layer =>
    layerId != null ? state.dataset.layers[layerId] : currentLayerSelector(state),
  (currentLayer) => {
    return Object.values(currentLayer.drivers.byId).flatMap((driver) => {
      if (driver.type !== DriverType.Basic || driver.leverType !== LeverType.Kpi) {
        return [];
      }
      return driver.id;
    });
  },
);
