import min from 'lodash/min';
import { createCachedSelector } from 're-reselect';
import { shallowEqual } from 'react-redux';
import { createSelectorCreator, lruMemoize } from 'reselect';

import {
  COMPARISON_LAYER_COLORS,
  COMPARISON_LAYER_HIGHLIGHT_COLORS,
  DEFAULT_COMPARISON_LAYER_COLOR,
  DEFAULT_COMPARISON_LAYER_HIGHLIGHT_COLOR,
} from 'config/scenarioComparison';
import {
  BlockComparisonLayout,
  BlockType,
  ComparisonColumn,
  DriverFormat,
  DriverType,
  RollupReducer,
  RollupSortType,
  RollupType,
} from 'generated/graphql';
import {
  ComparisonLayout,
  DEFAULT_COMPARISON_COLUMNS,
  addComparisonColumns,
  comparisonColumnSort,
} from 'helpers/blockComparisons';
import { extractMonthKey, getMonthKeysForRange } from 'helpers/dates';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { layerParamMemo } from 'helpers/layerSelectorFactory';
import { isNamedVersion } from 'helpers/namedVersions';
import { TimeSeriesColumn, defaultRollupReducerForFormat, rollupTimePeriod } from 'helpers/rollups';
import { BlockId } from 'reduxStore/models/blocks';
import { DriverId } from 'reduxStore/models/drivers';
import { Layer, LayerId } from 'reduxStore/models/layers';
import { NumericTimeSeries } from 'reduxStore/models/timeSeries';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { baselineLayerIdForBlockSelector } from 'selectors/baselineLayerSelector';
import {
  blockConfigFiscalYearStartMonthSelector,
  blockConfigObjectFieldSpecAsTimeSeriesIdSelector,
  blockConfigSelector,
  blockTypeSelector,
} from 'selectors/blocksSelector';
import { isCompareScenariosModalOpenSelector } from 'selectors/compareScenariosModalSelector';
import { comparisonTimePeriodsForBlockSelector } from 'selectors/comparisonTimePeriodsSelector';
import { blockIdSelector, fieldSelector } from 'selectors/constSelectors';
import {
  DriverForLayerProps,
  cacheKeyForDriverForLayerSelector,
  driversByIdForLayerSelector,
} from 'selectors/driversSelector';
import {
  datasetLastActualsMonthKeySelector,
  datasetLastActualsTimeSelector,
} from 'selectors/lastActualsSelector';
import { currentLayerIdSelector, layersSelector } from 'selectors/layerSelector';
import {
  blockDateRangeDateTimeSelector,
  blockDateRangeTranslatedViewAtTimeSelector,
} from 'selectors/pageDateRangeSelector';
import { comparisonLayerIdsForBlockSelector } from 'selectors/scenarioComparisonSelector';
import { ParametricSelector } from 'types/redux';

const EMPTY_COMPARISON_COLUMNS: ComparisonColumn[] = [];
const EMPTY_TIMESERIES_COLUMNS: TimeSeriesColumn[] = [];
const EMPTY_OBJECT_COLOR: Record<string, string> = {};

export const driverRollupReducerSelector: ParametricSelector<DriverForLayerProps, RollupReducer> =
  createCachedSelector(
    (state: RootState, { layerId }: DriverForLayerProps) =>
      driversByIdForLayerSelector(state, layerParamMemo(layerId)),
    fieldSelector('id'),
    (driversById, maybeGhostDriverId) => {
      const [driverId, ..._rest] = maybeGhostDriverId.split(';');
      const driver = driversById[driverId];
      if (driver == null || driver.type !== DriverType.Basic) {
        return defaultRollupReducerForFormat(DriverFormat.Auto);
      }
      return driver.rollup?.reducer ?? defaultRollupReducerForFormat(driver.format);
    },
  )(cacheKeyForDriverForLayerSelector);

export const rollupTypeForBlockSelector = createCachedSelector(
  blockConfigSelector,
  (blockConfig) => blockConfig?.rollupTypes || [blockConfig?.rollupType ?? RollupType.Month],
)({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

export const comparisonLayerColorByIdForBlockSelector: ParametricSelector<
  BlockId,
  Record<LayerId, string>
> = createCachedSelector(
  comparisonLayerIdsForBlockSelector,
  baselineLayerIdForBlockSelector,
  (comparisonLayerIds, baselineLayerId) => {
    if (comparisonLayerIds.length === 0) {
      // If we aren't comparing anything, don't return any comparison colors
      return EMPTY_OBJECT_COLOR;
    }

    const colorByLayerId: Record<LayerId, string> = {};
    colorByLayerId[baselineLayerId] = DEFAULT_COMPARISON_LAYER_COLOR;
    const compareLayers = comparisonLayerIds.filter((layerId) => layerId !== baselineLayerId);
    compareLayers.forEach((layerId, idx) => {
      colorByLayerId[layerId] = COMPARISON_LAYER_COLORS[idx % compareLayers.length];
    });

    return colorByLayerId;
  },
)({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

export const comparisonLayerHighlightColorByIdForBlockSelector: ParametricSelector<
  BlockId,
  Record<LayerId, string>
> = createCachedSelector(
  comparisonLayerIdsForBlockSelector,
  baselineLayerIdForBlockSelector,
  (comparisonLayerIds, baselineLayerId) => {
    const colorByLayerId: Record<LayerId, string> = {};
    colorByLayerId[baselineLayerId] = DEFAULT_COMPARISON_LAYER_HIGHLIGHT_COLOR;
    const compareLayers = comparisonLayerIds.filter((layerId) => layerId !== baselineLayerId);
    compareLayers.forEach((layerId, idx) => {
      colorByLayerId[layerId] = COMPARISON_LAYER_HIGHLIGHT_COLORS[idx % compareLayers.length];
    });
    return colorByLayerId;
  },
)({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

const namedVersionComparisonLayerIdsForBlockSelector: ParametricSelector<BlockId, LayerId[]> =
  createCachedSelector(layersSelector, comparisonLayerIdsForBlockSelector, (layers, layerIds) =>
    layerIds.filter((layerId) => layers[layerId] != null && isNamedVersion(layers[layerId])),
  )({
    keySelector: blockIdSelector,
    selectorCreator: createDeepEqualSelector,
  });

export const allComparisonLayersAreNamedVersionsForBlockSelector: ParametricSelector<
  BlockId,
  boolean
> = createCachedSelector(
  namedVersionComparisonLayerIdsForBlockSelector,
  comparisonLayerIdsForBlockSelector,
  (namedVersionLayerIds, layerIds) =>
    namedVersionLayerIds.length > 0 && namedVersionLayerIds.length === layerIds.length,
)(blockIdSelector);

/**
 *
 * Returns the default comparison columns if the block config doesn't have any.
 */
export const comparisonColumnsForBlockSelector = createCachedSelector(
  blockConfigSelector,
  (blockConfig) => {
    const columns =
      blockConfig?.comparisons?.columns != null && blockConfig.comparisons.columns.length > 0
        ? [...blockConfig.comparisons.columns]
        : [...DEFAULT_COMPARISON_COLUMNS];

    return [...columns].sort(comparisonColumnSort);
  },
)({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

export const selectedComparisonColumnsForBlockSelector = createCachedSelector(
  blockConfigSelector,
  (blockConfig) => {
    const columns = blockConfig?.comparisons?.columns ?? [];
    return [...columns].sort(comparisonColumnSort);
  },
)({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

export const comparisonLayoutForBlockSelector: ParametricSelector<
  BlockId,
  BlockComparisonLayout | undefined
> = createCachedSelector(blockConfigSelector, (blockConfig) => {
  return blockConfig?.comparisons?.layout ?? BlockComparisonLayout.Columns;
})(blockIdSelector);

export const comparisonLayerCountSelector: ParametricSelector<BlockId, number> =
  createCachedSelector(
    comparisonLayerIdsForBlockSelector,
    (comparisonLayerIds) => comparisonLayerIds.length,
  )(blockIdSelector);

export const isBlockRowComparisonLayoutSelector: ParametricSelector<BlockId, boolean> =
  createCachedSelector(
    comparisonLayoutForBlockSelector,
    comparisonLayerCountSelector,
    comparisonTimePeriodsForBlockSelector,
    (comparisonLayout, comparisonLayerCount, comparisonTimePeriods) => {
      const isComparingTimePeriods = comparisonTimePeriods.length > 0;
      const isComparingLayers = comparisonLayerCount > 0;
      const isComparing = isComparingLayers || isComparingTimePeriods;

      if (!isComparing) {
        return false;
      }
      if (isComparingTimePeriods) {
        return true;
      }
      return comparisonLayout === BlockComparisonLayout.Rows;
    },
  )(blockIdSelector);

export const isBlockColumnComparisonLayoutSelector: ParametricSelector<BlockId, boolean> =
  createCachedSelector(
    comparisonLayoutForBlockSelector,
    comparisonLayerCountSelector,
    comparisonTimePeriodsForBlockSelector,
    (comparisonLayout, comparisonLayerCount, comparisonTimePeriods) => {
      const isComparingTimePeriods = comparisonTimePeriods.length > 0;
      const isComparingLayers = comparisonLayerCount > 0;
      const isComparing = isComparingLayers || isComparingTimePeriods;
      if (!isComparing) {
        return false;
      }
      if (isComparingTimePeriods) {
        return false;
      }
      return comparisonLayout === BlockComparisonLayout.Columns;
    },
  )(blockIdSelector);

export const comparisonLayoutSelector: ParametricSelector<BlockId, ComparisonLayout> =
  createCachedSelector(
    isBlockRowComparisonLayoutSelector,
    isBlockColumnComparisonLayoutSelector,
    comparisonLayerCountSelector,
    (isBlockRowComparisonLayout, isBlockColumnComparisonLayout, comparisonLayerCount) => {
      if (isBlockRowComparisonLayout) {
        return 'row';
      }

      if (isBlockColumnComparisonLayout) {
        if (comparisonLayerCount === 2) {
          return 'column-compact';
        }
        return 'column-default';
      }

      return null;
    },
  )(blockIdSelector);

export const isShowingComparisonDriverHeaderRowSelector = createCachedSelector(
  comparisonLayerIdsForBlockSelector,
  isBlockColumnComparisonLayoutSelector,
  comparisonTimePeriodsForBlockSelector,
  (blockLayerComparison, isColumnComparisonLayout, comparisonTimePeriods) => {
    if (comparisonTimePeriods.length > 0) {
      return true;
    }
    // In column comparison layout, we use the "Row Version" comparison type as the header row.
    return blockLayerComparison.length > 0 && !isColumnComparisonLayout;
  },
)(blockIdSelector);

export const timeSeriesByDriverByLayerIdCreator = createSelectorCreator(lruMemoize, {
  resultEqualityCheck: (
    previousTimeSeriesMap: Record<LayerId, Record<DriverId, NumericTimeSeries>>,
    currTimeSeriesMap: Record<LayerId, Record<DriverId, NumericTimeSeries>>,
  ) => {
    const prevLayerIds = Object.keys(previousTimeSeriesMap);
    const currLayerIds = Object.keys(currTimeSeriesMap);
    if (prevLayerIds.length !== currLayerIds.length) {
      return false;
    }
    return currLayerIds.every((layerId) => {
      return shallowEqual(previousTimeSeriesMap[layerId], currTimeSeriesMap[layerId]);
    });
  },
});

const timeSeriesComparisonColumnsForBlockSelector = createCachedSelector(
  comparisonLayerIdsForBlockSelector,
  comparisonColumnsForBlockSelector,
  isCompareScenariosModalOpenSelector,
  comparisonTimePeriodsForBlockSelector,
  (compareLayerIds, compareColumns, isMergeModalOpen, comparisonTimePeriods) => {
    if (isMergeModalOpen) {
      //  right now the logic for displaying comparison columns is implicit, determined by the number of compare columns returned by this selector
      // we dont display the compare columns on the merge page, so we just return an empty array
      return [];
    }
    const isComparing = compareLayerIds.length > 0 || comparisonTimePeriods.length > 0;
    return isComparing ? compareColumns : EMPTY_COMPARISON_COLUMNS;
  },
)(blockIdSelector);

export const blockConfigRollupSortTypeSelector: ParametricSelector<BlockId, RollupSortType> =
  createCachedSelector(blockConfigSelector, (blockConfig) => {
    return blockConfig?.rollupSortType ?? RollupSortType.Chronological;
  })(blockIdSelector);

export const blockDateRangeStartMonthAndRollupSelector = createCachedSelector(
  blockDateRangeDateTimeSelector,
  blockDateRangeTranslatedViewAtTimeSelector,
  blockConfigFiscalYearStartMonthSelector,
  rollupTypeForBlockSelector,
  blockConfigRollupSortTypeSelector,
  (dateRange, startOverride, fiscalYearStartMonth, rollupType, rollupSortType) => {
    return { dateRange, startOverride, fiscalYearStartMonth, rollupType, rollupSortType };
  },
)(blockIdSelector);

const timeSeriesColumnsWithoutComparisonsForBlockSelector = createCachedSelector(
  blockDateRangeStartMonthAndRollupSelector,
  datasetLastActualsTimeSelector,
  comparisonTimePeriodsForBlockSelector,
  function timeSeriesColumnsWithoutComparisonsForBlockSelector(
    { dateRange, fiscalYearStartMonth, rollupType, rollupSortType },
    lastActualsTime,
  ): TimeSeriesColumn[] {
    const monthKeys = getMonthKeysForRange(dateRange[0], dateRange[1]);

    return rollupTimePeriod(
      monthKeys,
      rollupType,
      fiscalYearStartMonth,
      lastActualsTime,
      rollupSortType,
    );
  },
)(blockIdSelector);

const getNamedVersionActualsEndMonthKey = (
  layersById: Record<LayerId, Layer>,
  compareLayerIds: LayerId[],
  lastActualsTime: string,
) => {
  const namedVersionLastClose = min(
    compareLayerIds.map((layerId) => layersById[layerId].lastActualsTime ?? lastActualsTime),
  );
  return namedVersionLastClose != null ? extractMonthKey(namedVersionLastClose) : undefined;
};

const timeSeriesColumnsWithComparisonsForBlockSelector = createCachedSelector(
  blockDateRangeStartMonthAndRollupSelector,
  comparisonLayerIdsForBlockSelector,
  timeSeriesComparisonColumnsForBlockSelector,
  datasetLastActualsMonthKeySelector,
  layersSelector,
  datasetLastActualsTimeSelector,
  comparisonLayoutSelector,
  currentLayerIdSelector,
  comparisonTimePeriodsForBlockSelector,
  // eslint-disable-next-line max-params
  function timeSeriesColumnsWithComparisonsForBlockSelector(
    { dateRange, fiscalYearStartMonth, rollupType },
    compareLayerIds,
    comparisons,
    actualsEndMonthKey,
    layersById,
    lastActualsTime,
    comparisonLayout,
    currentLayerId,
    comparisonTimePeriods,
  ): TimeSeriesColumn[] {
    const monthKeys = getMonthKeysForRange(dateRange[0], dateRange[1]);
    return addComparisonColumns({
      oldColumns: rollupTimePeriod(monthKeys, rollupType, fiscalYearStartMonth, lastActualsTime),
      actualsEndMonthKey,
      namedVersionActualsEndMonthKey: getNamedVersionActualsEndMonthKey(
        layersById,
        compareLayerIds,
        lastActualsTime,
      ),
      comparisons,
      compareLayerIds,
      comparisonLayout,
      currentLayerId,
      comparisonTimePeriods,
    });
  },
)(blockIdSelector);

export const timeSeriesColumnsForBlockSelector: ParametricSelector<BlockId, TimeSeriesColumn[]> =
  createCachedSelector(
    (state: RootState) => state,
    blockIdSelector,
    comparisonLayerIdsForBlockSelector,
    layersSelector,
    datasetLastActualsTimeSelector,
    blockConfigObjectFieldSpecAsTimeSeriesIdSelector,
    blockTypeSelector,
    comparisonTimePeriodsForBlockSelector,
    // eslint-disable-next-line max-params
    function timeSeriesColumnsForBlockSelector(
      state,
      blockId,
      compareLayerIds,
      layersById,
      lastActualsTime,
      asTimeSeriesFieldSpecId,
      blockType,
      comparisonTimePeriods,
    ): TimeSeriesColumn[] {
      if (blockType === BlockType.ObjectTable && asTimeSeriesFieldSpecId == null) {
        return EMPTY_TIMESERIES_COLUMNS;
      }

      const isComparingLayers = compareLayerIds.length > 0;
      const isComparingTimePeriods = comparisonTimePeriods.length > 0;
      const namedVersionLastCloseMonthKey = getNamedVersionActualsEndMonthKey(
        layersById,
        compareLayerIds,
        lastActualsTime,
      );

      // Same short-circuit logic as `addComparisonColumns`
      if (!isComparingTimePeriods) {
        if (!isComparingLayers || namedVersionLastCloseMonthKey == null) {
          return timeSeriesColumnsWithoutComparisonsForBlockSelector(state, blockId);
        }
      }

      return timeSeriesColumnsWithComparisonsForBlockSelector(state, blockId);
    },
  )({
    keySelector: blockIdSelector,
    selectorCreator: createDeepEqualSelector,
  });
