import { Box, Flex } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';
import { useDrop } from 'react-dnd';

import DriverGroupHeaderColumn from 'components/DriverGroupHeaderRows/DriverGroupHeaderColumn';
import DriverGroupNameHeaderRow, {
  DRIVER_GROUP_DRAG_ITEM_TYPE,
} from 'components/DriverGroupHeaderRows/DriverGroupNameHeaderRow';
import { DriverDragItem } from 'components/DriverNameTableCell/DriverNameTableCell';
import Droppable from 'components/Droppable/Droppable';
import ExpandControl from 'components/ExpandControl/ExpandControl';
import Reorderable from 'components/Reorderable/Reorderable';
import StickyColumnsAndTimeSeriesHeader from 'components/StickyColumnsAndTimeSeriesHeader/StickyColumnsAndTimeSeriesHeader';
import { DRIVER_DRAG_ITEM_TYPE } from 'config/drivers';
import { COLUMN_TYPE_TO_NAME, NAME_COLUMN_TYPE } from 'config/modelView';
import { TimeSeriesColumn } from 'helpers/rollups';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { BaseDragItem, ReorderFunc } from 'hooks/useReorderable';
import { BlockId } from 'reduxStore/models/blocks';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { isDataVisibleSelector } from 'selectors/isDataVisibleSelector';
import { isBlockIdInModelPageSelector } from 'selectors/modelViewSelector';
import { timeSeriesColumnsForBlockSelector } from 'selectors/rollupSelector';
import { driverPropertyColumnsSelector } from 'selectors/visibleColumnTypesSelector';

interface Props {
  groupId?: DriverGroupId;
  groupName: string;
  isCollapsed?: boolean;
  onDriverGroupNameCollapse?: () => void;
  onDriverColumnCollapse?: () => void;
  isLastGroup?: boolean;
  isReorderable?: boolean;
  onDropGroup?: ReorderFunc<DriverGroupDragItem>;
  onDropOnGroup?: (
    driverId: string,
    groupId: string | undefined,
    position: 'start' | 'end',
  ) => void;
}

const ALLOWED_DROP_ITEM_TYPES_ON_GROUP = [DRIVER_DRAG_ITEM_TYPE];
const EMPTY_COLUMNS: TimeSeriesColumn[] = [];

type DriverGroupDragItem = BaseDragItem & { groupId: DriverGroupId; blockId: BlockId };

const DriverGroupHeaderRows: React.FC<Props> = ({
  groupId,
  groupName,
  onDriverGroupNameCollapse,
  onDriverColumnCollapse,
  isLastGroup = false,
  isCollapsed = false,
  isReorderable = false,
  onDropGroup,
  onDropOnGroup,
}) => {
  const { blockId, gutterWidthInPxString } = useBlockContext();
  const propertyColumns = useAppSelector((state) => driverPropertyColumnsSelector(state, blockId));
  const columns = useAppSelector((state) => timeSeriesColumnsForBlockSelector(state, blockId));

  const isDataVisible = useAppSelector((state) => isDataVisibleSelector(state, blockId));
  const isModelPage = useAppSelector((state) => isBlockIdInModelPageSelector(state, blockId));

  const dragItem: DriverGroupDragItem = useMemo(
    () => ({ type: DRIVER_GROUP_DRAG_ITEM_TYPE, groupId: groupId ?? '', blockId }),
    [blockId, groupId],
  );

  const onDropItem = useCallback(
    (
      dropItem: DriverGroupDragItem,
      relativeTo: DriverGroupDragItem,
      position: 'before' | 'after',
    ) => {
      onDropGroup?.(dropItem, relativeTo, position);
    },
    [onDropGroup],
  );

  const onDropDriverDragItemOnGroup = useCallback(
    (droppedItem: DriverDragItem) => {
      onDropOnGroup?.(droppedItem.driverId, groupId, 'start');
    },
    [groupId, onDropOnGroup],
  );

  const [{ isOver }, dropRef] = useDrop<DriverDragItem, unknown, { isOver: boolean }>(
    () => ({
      accept: ALLOWED_DROP_ITEM_TYPES_ON_GROUP,
      collect: (monitor) => ({
        isOver: monitor.isOver() && monitor.canDrop(),
      }),
    }),
    [],
  );

  return (
    <Flex flexDir="column" position="sticky" left={0} width="fit-content">
      {groupId != null && (
        <Box position="relative" ref={dropRef}>
          <Reorderable
            orientation="vertical"
            item={dragItem}
            itemType={DRIVER_GROUP_DRAG_ITEM_TYPE}
            isLast={isLastGroup}
            onDrop={onDropItem}
            disabled={!isReorderable}
          >
            <Droppable
              dropItemType={ALLOWED_DROP_ITEM_TYPES_ON_GROUP}
              onDrop={onDropDriverDragItemOnGroup}
            >
              <DriverGroupNameHeaderRow
                groupId={groupId}
                groupName={groupName}
                isCollapsed={isCollapsed}
                onCollapse={onDriverGroupNameCollapse}
                containerBgColor={isOver ? 'gray.200' : undefined}
              />
            </Droppable>
          </Reorderable>
        </Box>
      )}
      {!isCollapsed && (
        <StickyColumnsAndTimeSeriesHeader columns={isDataVisible ? columns : EMPTY_COLUMNS}>
          <Flex
            role="row"
            pl={onDriverColumnCollapse != null ? 0 : gutterWidthInPxString}
            data-testid="driver-property-column-headers"
          >
            {onDriverColumnCollapse != null && (
              <Flex w={gutterWidthInPxString} justifyContent="flex-end" alignItems="center" mt={1}>
                <ExpandControl expanded onToggle={onDriverColumnCollapse} />
              </Flex>
            )}
            {propertyColumns.map(({ type, layerIds }, idx) => {
              const isNameColumn = type === NAME_COLUMN_TYPE;
              const label =
                isNameColumn && isModelPage
                  ? 'Driver'
                  : isNameColumn
                    ? groupName
                    : COLUMN_TYPE_TO_NAME[type] ?? '';
              return (
                <DriverGroupHeaderColumn
                  groupId={groupId}
                  key={type}
                  type={type}
                  disabled={isNameColumn}
                  label={label}
                  isLast={idx === propertyColumns.length - 1}
                  columnLayerIds={layerIds}
                />
              );
            })}
          </Flex>
        </StickyColumnsAndTimeSeriesHeader>
      )}
    </Flex>
  );
};

export default React.memo(DriverGroupHeaderRows);
