import { DriverType, ValueType } from 'generated/graphql';
import {
  DimensionalDriver,
  Driver,
  getDriverActualsFormulaForCalculation,
  getDriverForecastFormulaForCalculation,
} from 'reduxStore/models/drivers';
import { convertToValueTimeSeries } from 'reduxStore/models/timeSeries';

import { FormulaEntity, FormulaEntityTypedId } from './formulaEvaluation/ReferenceEvaluator';

export type FormulaEntities = Record<FormulaEntityTypedId['id'], FormulaEntity>;

export function mapDriverFormulaEntities(
  drivers: Driver[],
  dimensionalDriverBySubdriverId: NullableRecord<string, DimensionalDriver>,
): FormulaEntities {
  return drivers.reduce((map: FormulaEntities, driver) => {
    if (driver.type === DriverType.Basic) {
      map[driver.id] = {
        ...driver,
        id: { type: 'driver', id: driver.id },
        valueType: driver.valueType ?? ValueType.Number,
        forecast: {
          formula: getDriverForecastFormulaForCalculation(
            driver,
            dimensionalDriverBySubdriverId[driver.id],
          ).formula,
        },
        actuals: {
          formula: driver.actuals.formula,
          formulaForCalculations: getDriverActualsFormulaForCalculation(
            driver,
            dimensionalDriverBySubdriverId[driver.id],
          ).formula,
          timeSeries:
            driver.actuals.timeSeries != null
              ? convertToValueTimeSeries(
                  driver.actuals.timeSeries,
                  driver.valueType ?? ValueType.Number,
                )
              : undefined,
        },
      };
    }
    return map;
  }, {});
}
