import { createContext, ReactNode, useContext, useMemo } from 'react';

import { BLOCK_TYPES_WITH_INLINE_CONTROLS } from 'config/block';
import { CellColumnKey } from 'config/cells';
import { ColumnOrMonthKey } from 'config/modelView';
import { ComparisonColumn, ComparisonTimePeriod } from 'generated/graphql';
import { ComparisonLayout } from 'helpers/blockComparisons';
import { TimeSeriesColumn } from 'helpers/rollups';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { blockCellColumnKeysSelector } from 'selectors/activeCellSelectionSelector';
import {
  blockConfigBusinessObjectSpecStartFieldIdSelector,
  blockTypeSelector,
  isObjectTimeseriesViewSelector,
} from 'selectors/blocksSelector';
import { comparisonTimePeriodsForBlockSelector } from 'selectors/comparisonTimePeriodsSelector';
import {
  comparisonColumnsForBlockSelector,
  comparisonLayerHighlightColorByIdForBlockSelector,
  comparisonLayoutSelector,
  timeSeriesColumnsForBlockSelector,
} from 'selectors/rollupSelector';
import {
  comparisonLayerIdsForBlockSelector,
  scenarioComparisonBaselineLayerIdSelector,
} from 'selectors/scenarioComparisonSelector';
import {
  columnWidthsByTypeSelector,
  lastStickyColumnTypeSelector,
} from 'selectors/tableColumnsSelector';
import { visibleFieldSpecTimeSeriesSelector } from 'selectors/visibleFieldSpecTimeSeriesSelector';

interface ResolvedBlockConfiguration {
  baselineLayerId: string | null;
  timeseriesColumns: TimeSeriesColumn[];
  layerHighlightColorByLayerId: Record<string, string>;
  isObjectTimeseriesView: boolean;
  blockCellColumnKeys: CellColumnKey[];
  hasVisibleFieldSpecTimeSeries: boolean;
  businessObjectSpecStartFieldId: string | undefined;
  lastStickyColumnType: ColumnOrMonthKey | null;
  comparisonLayout: ComparisonLayout;
  comparisonLayerIds: string[];
  displayedComparisonColumns: ComparisonColumn[];
  columnWidthsByType: Record<string, number>;
  displayedComparisonTimePeriods: ComparisonTimePeriod[];
}

const EMPTY_BLOCK_CONFIGURATION: ResolvedBlockConfiguration = {
  baselineLayerId: null,
  timeseriesColumns: [],
  layerHighlightColorByLayerId: {},
  isObjectTimeseriesView: false,
  blockCellColumnKeys: [],
  hasVisibleFieldSpecTimeSeries: false,
  businessObjectSpecStartFieldId: undefined,
  lastStickyColumnType: null,
  comparisonLayout: null,
  comparisonLayerIds: [],
  displayedComparisonColumns: [],
  columnWidthsByType: {},
  displayedComparisonTimePeriods: [],
};

const useCreateResolvedBlockConfiguration = (): ResolvedBlockConfiguration => {
  const { blockId } = useBlockContext();

  const timeseriesColumns = useAppSelector((state) =>
    timeSeriesColumnsForBlockSelector(state, blockId),
  );

  const displayedComparisonTimePeriods = useAppSelector((state) =>
    comparisonTimePeriodsForBlockSelector(state, blockId),
  );

  const isObjectTimeseriesView = useAppSelector((state) =>
    isObjectTimeseriesViewSelector(state, blockId),
  );
  const blockCellColumnKeys = useAppSelector((state) =>
    blockCellColumnKeysSelector(state, blockId),
  );
  const hasVisibleFieldSpecTimeSeries = useAppSelector(
    (state) => visibleFieldSpecTimeSeriesSelector(state, blockId) != null,
  );
  const businessObjectSpecStartFieldId = useAppSelector((state) =>
    blockConfigBusinessObjectSpecStartFieldIdSelector(state, blockId),
  );
  const comparisonLayout = useAppSelector((state) => comparisonLayoutSelector(state, blockId));

  const comparisonLayerIds = useAppSelector((state) =>
    comparisonLayerIdsForBlockSelector(state, blockId),
  );
  const displayedComparisonColumns = useAppSelector((state) =>
    comparisonColumnsForBlockSelector(state, blockId),
  );
  const layerHighlightColorByLayerId = useAppSelector((state) =>
    comparisonLayerHighlightColorByIdForBlockSelector(state, blockId),
  );
  const lastStickyColumnType = useAppSelector((state: RootState) =>
    lastStickyColumnTypeSelector(state, blockId),
  );

  const baselineLayerId = useAppSelector(scenarioComparisonBaselineLayerIdSelector);
  const columnWidthsByType = useAppSelector((state) => columnWidthsByTypeSelector(state, blockId));

  return useMemo(
    (): ResolvedBlockConfiguration => ({
      baselineLayerId,
      timeseriesColumns,
      isObjectTimeseriesView,
      blockCellColumnKeys,
      layerHighlightColorByLayerId,
      hasVisibleFieldSpecTimeSeries,
      businessObjectSpecStartFieldId,
      comparisonLayout,
      comparisonLayerIds,
      displayedComparisonColumns,
      lastStickyColumnType,
      columnWidthsByType,
      displayedComparisonTimePeriods,
    }),
    [
      baselineLayerId,
      timeseriesColumns,
      isObjectTimeseriesView,
      blockCellColumnKeys,
      layerHighlightColorByLayerId,
      hasVisibleFieldSpecTimeSeries,
      businessObjectSpecStartFieldId,
      comparisonLayout,
      comparisonLayerIds,
      displayedComparisonColumns,
      lastStickyColumnType,
      columnWidthsByType,
      displayedComparisonTimePeriods,
    ],
  );
};

const ResolvedBlockConfigurationContext =
  createContext<ResolvedBlockConfiguration>(EMPTY_BLOCK_CONFIGURATION);

export const useResolvedBlockConfiguration = () => {
  return useContext(ResolvedBlockConfigurationContext);
};

interface Props {
  children: ReactNode;
}

const ChildrenWithContext: React.FC<Props> = ({ children }) => {
  const blockConfiguration = useCreateResolvedBlockConfiguration();
  return (
    <ResolvedBlockConfigurationContext.Provider value={blockConfiguration}>
      {children}
    </ResolvedBlockConfigurationContext.Provider>
  );
};

export const ResolvedBlockConfigurationProvider: React.FC<Props> = ({ children }) => {
  const { blockId } = useBlockContext();
  const blockType = useAppSelector((state: RootState) => blockTypeSelector(state, blockId));
  if (blockType == null || BLOCK_TYPES_WITH_INLINE_CONTROLS.includes(blockType)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return <ChildrenWithContext>{children}</ChildrenWithContext>;
};
