import { DateTime } from 'luxon';

import { ComparisonTimePeriod } from 'generated/graphql';
import { MonthKey } from 'types/datetime';

export function getTimePeriodMonthKeysFromBaselineMonthKeys(
  monthKeys: MonthKey[],
  comparisonTimePeriod: ComparisonTimePeriod | undefined,
): MonthKey[] {
  if (comparisonTimePeriod == null) {
    return monthKeys;
  }
  return monthKeys.map((mk) => getTimePeriodMonthKeyFromBaselineMonthKey(mk, comparisonTimePeriod));
}

/**
 * For a given time period and baseline month key, returns the month key corresponding to the time period .
 * i.e. previous-period + 2022-02  => 2022-01 (shifted by 1 month)
 */
export function getTimePeriodMonthKeyFromBaselineMonthKey(
  monthKey: string,
  timePeriod: ComparisonTimePeriod,
): string {
  const monthKeyDatetime = DateTime.fromISO(monthKey);
  let equivalentMonth = monthKeyDatetime;
  switch (timePeriod) {
    case ComparisonTimePeriod.PreviousPeriod:
      equivalentMonth = monthKeyDatetime.minus({ months: 1 });
      break;
    case ComparisonTimePeriod.ThreeMonthsAgo:
      equivalentMonth = monthKeyDatetime.minus({ months: 3 });
      break;
    case ComparisonTimePeriod.SixMonthsAgo:
      equivalentMonth = monthKeyDatetime.minus({ months: 6 });
      break;
    case ComparisonTimePeriod.TwelveMonthsAgo:
      equivalentMonth = monthKeyDatetime.minus({ months: 12 });
      break;
    default:
      equivalentMonth = monthKeyDatetime;
  }
  return equivalentMonth.toFormat('yyyy-MM');
}

export function getDateRangeForTimePeriod(
  timePeriod: ComparisonTimePeriod,
  start: DateTime,
  end: DateTime,
) {
  switch (timePeriod) {
    case ComparisonTimePeriod.PreviousPeriod:
      return [start.minus({ months: 1 }), end.minus({ months: 1 })];
    case ComparisonTimePeriod.ThreeMonthsAgo:
      return [start.minus({ months: 3 }), end.minus({ months: 3 })];
    case ComparisonTimePeriod.SixMonthsAgo:
      return [start.minus({ months: 6 }), end.minus({ months: 6 })];
    case ComparisonTimePeriod.TwelveMonthsAgo:
      return [start.minus({ months: 12 }), end.minus({ months: 12 })];
    case ComparisonTimePeriod.CurrentPeriod:
    default:
      return [start, end];
  }
}

const timePeriodComparisonSortOrder: Record<ComparisonTimePeriod, number> = {
  [ComparisonTimePeriod.CurrentPeriod]: 1,
  [ComparisonTimePeriod.PreviousPeriod]: 2,
  [ComparisonTimePeriod.ThreeMonthsAgo]: 3,
  [ComparisonTimePeriod.SixMonthsAgo]: 4,
  [ComparisonTimePeriod.TwelveMonthsAgo]: 5,
};

export const timePeriodComparisonSortComparator = (
  a: ComparisonTimePeriod,
  b: ComparisonTimePeriod,
) => {
  return timePeriodComparisonSortOrder[a] - timePeriodComparisonSortOrder[b];
};
