import { Box, Flex } from '@chakra-ui/react';
import noop from 'lodash/noop';
import React, { useCallback, useContext, useMemo } from 'react';

import CellContextProvider from 'components/CellContextProvider/CellContextProvider';
import ClickableFormulaReferenceWrapper from 'components/ClickableFormulaReferenceWrapper/ClickableFormulaReferenceWrapper';
import ComparisonNameCell from 'components/DriverNameTableCell/ColumnLayoutComparisonTypeCell';
import DriverName from 'components/DriverNameTableCell/DriverName';
import { useDriverName } from 'components/DriverNameTableCell/useDriverName';
import DriverRowOptions from 'components/DriverRowOptions/DriverRowOptions';
import FormulaButton from 'components/FormulaButton/FormulaButton';
import KPIIcon from 'components/KPIIcon/KPIIcon';
import LayerNameCellContents from 'components/LayerNameCellContents/LayerNameCellContents';
import NameCellWrapper from 'components/NameCellWrapper/NameCellWrapper';
import OpenDetailsModalButton from 'components/OpenDetailsModalButton/OpenDetailsModalButton';
import Reorderable from 'components/Reorderable/Reorderable';
import RowActionsContainer from 'components/RowActionsContainer/RowActionsContainer';
import EditDriverPopover from 'components/SubmodelTable/EditDriverPopover';
import TableCell from 'components/Table/TableCell';
import { DriverGridContext } from 'config/driverGridContext';
import { DriverRowContext } from 'config/driverRowContext';
import { DRIVER_DRAG_ITEM_TYPE } from 'config/drivers';
import { NAME_COLUMN_TYPE } from 'config/modelView';
import { ComparisonColumn } from 'generated/graphql';
import { ColorLocation, formatColorForUsage } from 'helpers/color';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useCellRef } from 'hooks/useCellRefContext';
import { useCellSelectionStateContext } from 'hooks/useCellSelectionStateContext';
import useDriverCellRef from 'hooks/useDriverCellRef';
import { BaseDragItem } from 'hooks/useReorderable';
import useSelectMouseHandlers, { SelectHandler } from 'hooks/useSelectMouseHandlers';
import { selectCell } from 'reduxStore/actions/cellNavigation';
import { selectCellIfUnselected } from 'reduxStore/actions/cellSelection';
import { BlockId } from 'reduxStore/models/blocks';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { PaneType } from 'reduxStore/reducers/detailPaneSlice';
import { isAccessibleDriverSelector } from 'selectors/accessibleDriversSelector';
import { baselineLayerIdForBlockSelector } from 'selectors/baselineLayerSelector';
import { blockConfigDriverIndentsSelector } from 'selectors/blocksSelector';
import { comparisonTimePeriodsForBlockSelector } from 'selectors/comparisonTimePeriodsSelector';
import { driverIsKpiSelector, driverRowColorSelector } from 'selectors/driversSelector';
import { driverHasFormulaErrorSelector } from 'selectors/formulaDisplaySelector';
import { isBlockRowComparisonLayoutSelector } from 'selectors/rollupSelector';
import {
  comparisonLayerIdsForBlockSelector,
  scenarioComparisonShouldShowDriverDiffs,
} from 'selectors/scenarioComparisonSelector';
import { columnOffsetSelector, columnWidthSelector } from 'selectors/tableColumnsSelector';

interface Props {
  groupId?: DriverGroupId;
  isLast: boolean;
}

export type DriverDragItem = BaseDragItem & {
  driverId: DriverId;
  groupId?: DriverGroupId;
  blockId: BlockId;
};

const DriverNameTableCell = React.forwardRef<HTMLDivElement, Props>(
  ({ groupId, isLast }, passedRef) => {
    const dispatch = useAppDispatch();

    const { blockId, gutterWidthInPxString, readOnly } = useBlockContext();

    const { driverId, comparisonRowLayerId, comparisonType, comparisonTimePeriod } =
      useContext(DriverRowContext);

    const isAccessibleDriver = useAppSelector((state) =>
      isAccessibleDriverSelector(state, { id: driverId }),
    );
    const isRowComparisonLayout = useAppSelector((state) =>
      isBlockRowComparisonLayoutSelector(state, blockId),
    );

    const comparisonLayerIds = useAppSelector((state) =>
      comparisonLayerIdsForBlockSelector(state, blockId),
    );

    const isComparingLayers = comparisonLayerIds.length > 0;

    const comparisonTimePeriods = useAppSelector((state) =>
      comparisonTimePeriodsForBlockSelector(state, blockId),
    );
    const isComparingTimePeriods = comparisonTimePeriods.length > 0;

    const isComparisonRow = comparisonType != null || comparisonTimePeriod != null;

    // In merge screen, this is the layer's parent.
    // On page comparison, this is the current layer.
    const baselineLayerId = useAppSelector((state) =>
      baselineLayerIdForBlockSelector(state, blockId),
    );

    const indentations = useAppSelector((state) =>
      blockConfigDriverIndentsSelector(state, blockId),
    );

    const driverIndentLevel =
      indentations.find((indentation) => indentation.driverId === driverId)?.level ?? 0;

    const shouldShowDriverDiffs = useAppSelector((state) =>
      scenarioComparisonShouldShowDriverDiffs(state, blockId),
    );

    const { baselineDriverName, updatedDriverNamesByLayerId } = useDriverName({
      driverId,
      baselineLayerId,
      comparisonLayerIds,
      shouldShowDriverDiffs,
    });

    const numUpdatedDriverNames = Object.keys(updatedDriverNamesByLayerId).length;

    const showFormulaError =
      useAppSelector((state) => driverHasFormulaErrorSelector(state, driverId)) && !readOnly;

    const isEditable = !readOnly && comparisonRowLayerId == null;
    const { cellRef } = useCellRef();
    const { isSelected } = useCellSelectionStateContext();
    const width = useAppSelector((state) =>
      columnWidthSelector(state, { columnType: NAME_COLUMN_TYPE, blockId }),
    );
    const left = useAppSelector((state) =>
      columnOffsetSelector(state, { columnType: NAME_COLUMN_TYPE, blockId }),
    );

    const { onReorder } = useContext(DriverGridContext);
    const isKpi = useAppSelector((state) => driverIsKpiSelector(state, driverId));

    const onSelect: SelectHandler = useCallback(
      ({ range, toggle }) => {
        // N.B. need to let previous selection save before selecting a new driver
        // TODO: maybe this should only happen if there is a dim driver editing state?
        setTimeout(() => {
          dispatch(selectCell(blockId, cellRef, { range, toggle }));
        }, 0);
      },
      [blockId, dispatch, cellRef],
    );

    const { onClick, onMouseDown } = useSelectMouseHandlers(isSelected, onSelect, {
      formulaReferenceEntityId: { type: 'driver', id: driverId },
    });

    const selectIfUnselected = useCallback(() => {
      dispatch(selectCellIfUnselected(blockId, cellRef));
    }, [blockId, dispatch, cellRef]);

    const dragItem: DriverDragItem = useMemo(
      () => ({ type: DRIVER_DRAG_ITEM_TYPE, driverId, groupId, blockId }),
      [blockId, driverId, groupId],
    );

    const DriverNameCell = useMemo(
      () => (
        <>
          <Box flex={numUpdatedDriverNames > 0 ? 0 : 1} minWidth={0}>
            <DriverName
              fontSize={isComparingLayers || isComparingTimePeriods ? 'xxs' : 'xs'}
              name={baselineDriverName}
              indents={driverIndentLevel}
              as={
                // strikethrough driver name if it was updated in other layers
                numUpdatedDriverNames > 0 ? 's' : undefined
              }
            />
          </Box>
          {/* Show the baseline driver name and what it was updated to in other layers */}
          {Object.entries(updatedDriverNamesByLayerId).map(([lId, name], i) => (
            <Flex key={lId} marginLeft="8px">
              <DriverName
                fontSize="xxs"
                name={`${name}${i !== numUpdatedDriverNames - 1 ? ',' : ''}`}
                indents={driverIndentLevel}
              />
            </Flex>
          ))}
          {isAccessibleDriver && (
            <RowActionsContainer
              alwaysVisibleChildren={
                showFormulaError || isKpi ? (
                  <>
                    {showFormulaError && !readOnly ? <FormulaButton driverId={driverId} /> : null}
                    {isKpi ? <KPIIcon id={driverId} /> : null}
                  </>
                ) : null
              }
            >
              <OpenDetailsModalButton type={PaneType.Driver} id={driverId} />
              {isEditable && !showFormulaError && !readOnly && (
                <FormulaButton driverId={driverId} />
              )}
              {!isKpi && !readOnly && <KPIIcon id={driverId} />}
            </RowActionsContainer>
          )}
        </>
      ),
      [
        numUpdatedDriverNames,
        isComparingLayers,
        isComparingTimePeriods,
        baselineDriverName,
        driverIndentLevel,
        updatedDriverNamesByLayerId,
        isAccessibleDriver,
        showFormulaError,
        isKpi,
        readOnly,
        driverId,
        isEditable,
      ],
    );

    const DriverOrLayerNameCell = useMemo(() => {
      if (isComparingLayers && comparisonRowLayerId != null) {
        const isBaselineLayer = comparisonRowLayerId === baselineLayerId;
        return (
          <LayerNameCellContents
            layerId={comparisonRowLayerId}
            driverId={driverId}
            isBaselineLayer={isBaselineLayer}
            isComparisonLayout={isComparingLayers}
          />
        );
      }
      return DriverNameCell;
    }, [isComparingLayers, comparisonRowLayerId, DriverNameCell, baselineLayerId, driverId]);

    const DriverOrComparisonTypeCell = useMemo(() => {
      // We in-line the RowVersion values next to the driver name, so for this row just render the driver name.
      if (
        (comparisonType != null && comparisonType !== ComparisonColumn.RowVersion) ||
        comparisonTimePeriod != null
      ) {
        return <ComparisonNameCell />;
      }
      return DriverNameCell;
    }, [DriverNameCell, comparisonTimePeriod, comparisonType]);

    return (
      <NameCellWrapper ref={passedRef} testId={`driver-name-${baselineDriverName}`}>
        <Reorderable
          item={dragItem}
          itemType={DRIVER_DRAG_ITEM_TYPE}
          isLast={isLast}
          onDragStart={selectIfUnselected}
          onDrop={onReorder ?? noop}
          disabled={readOnly || onReorder == null || isComparisonRow}
          leftOffset={gutterWidthInPxString}
        >
          <DriverRowOptions draggable={!isComparisonRow} />
          <EditDriverPopover driverId={driverId} isEditable={isEditable} openOn="doubleClick">
            <Box height="full" position="relative">
              {!isSelected && <DriverRowBackgroundColorIndicator />}
              <TableCell
                isSticky
                onClick={onClick}
                onMouseDown={onMouseDown}
                left={left}
                width={width}
              >
                <ClickableFormulaReferenceWrapper
                  height="full"
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  width="full"
                  overflow="hidden"
                  position="relative"
                >
                  {isComparingTimePeriods
                    ? DriverOrComparisonTypeCell
                    : isRowComparisonLayout
                      ? DriverOrLayerNameCell
                      : DriverOrComparisonTypeCell}
                </ClickableFormulaReferenceWrapper>
              </TableCell>
            </Box>
          </EditDriverPopover>
        </Reorderable>
      </NameCellWrapper>
    );
  },
);

DriverNameTableCell.displayName = 'DriverNameTableCell';

const DriverRowBackgroundColorIndicator: React.FC = () => {
  const { driverId } = useContext(DriverRowContext);
  const rowBgColor = useAppSelector((state) => driverRowColorSelector(state, { id: driverId }));
  return rowBgColor != null ? (
    <Box
      position="absolute"
      top="0"
      bottom="0"
      left="0"
      width="2px"
      zIndex="12"
      background={formatColorForUsage(rowBgColor, ColorLocation.Accessory)}
    />
  ) : null;
};

const DriverNameTableCellWrapper = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const cellRef = useDriverCellRef({ columnType: NAME_COLUMN_TYPE, columnLayerId: undefined });
  return (
    <CellContextProvider cellRef={cellRef}>
      <DriverNameTableCell ref={ref} {...props} />
    </CellContextProvider>
  );
});

DriverNameTableCellWrapper.displayName = 'DriverNameTableCellWrapper';

export default React.memo(DriverNameTableCellWrapper);
