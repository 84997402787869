import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import {
  CellColumnKey,
  CellRowKey,
  DatabaseTraceRowKey,
  DriverRowKey,
  ObjectTraceRowKey,
} from 'config/cells';
import {
  DRIVER_DETAILS_DIRECT_REFERENCE_GROUP_ID,
  DRIVER_DETAILS_INDIRECT_REFERENCE_GROUP_ID,
  DRIVER_DETAILS_USED_BY_GROUP_ID,
} from 'config/detailPane';
import { OBJECT_INITIAL_VALUE_COLUMN_KEY, OBJECT_PROPERTY_COLUMN_KEY } from 'config/objectGridView';
import { BlockType } from 'generated/graphql';
import { getMonthColumnKey, isRowKeyEqual } from 'helpers/cells';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { getOrderedRowKeysForDrivers } from 'helpers/orderedDriverRowKeys';
import { BlockId } from 'reduxStore/models/blocks';
import { LayerId } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { blockTypeSelector } from 'selectors/blocksSelector';
import { comparisonTimePeriodsForBlockSelector } from 'selectors/comparisonTimePeriodsSelector';
import { blockIdSelector, fieldSelector } from 'selectors/constSelectors';
import { orderedExtDriverColumnKeysSelector } from 'selectors/dataSourcesSelector';
import {
  driverDetailPaneShowIndirectInputsSelector,
  driverDetailPaneTabSelector,
  mustDetailPaneDriverIdSelector,
} from 'selectors/driverDetailPaneSelector';
import {
  orderedDriverGridBlockColumnKeysSelector,
  orderedDriverGridBlockDriversSelector,
} from 'selectors/driverGridBlockSelector';
import {
  isViewingExtDriversTableSelector,
  orderedVisibleExtDriverRowKeysSelector,
} from 'selectors/extDriversSelector';
import { currentLayerIdSelector } from 'selectors/layerSelector';
import {
  isBlockDriverDetailPageSelector,
  isBlockPlanDetailPageSelector,
  submodelPageIdForBlockIdSelector,
} from 'selectors/modelViewSelector';
import {
  orderedObjectGridBlockRowKeysSelector,
  orderedObjectTableBlockColumnKeysSelector,
  orderedObjectTableBlockRowKeysSelector,
} from 'selectors/objectTableBlockSelector';
import { planDetailPaneDriverIdsSelector } from 'selectors/planDetailPaneSelector';
import {
  prevailingCellSelectionBlockIdSelector,
  prevailingCellSelectionSelector,
} from 'selectors/prevailingCellSelectionSelector';
import {
  comparisonColumnsForBlockSelector,
  comparisonLayoutSelector,
  isShowingComparisonDriverHeaderRowSelector,
  timeSeriesColumnsForBlockSelector,
} from 'selectors/rollupSelector';
import {
  ObjectFieldDiff,
  comparisonLayerIdsForBlockSelector,
  isScenarioComparisonDatabaseBlockSelector,
  isScenarioComparisonDriverBlockSelector,
  scenarioComparisonDriverIdsSelector,
  scenarioComparisonFieldsByObjectIdByObjectSpecIdSelector,
} from 'selectors/scenarioComparisonSelector';
import {
  directDriverDependenciesSelector,
  directDriverDependentsSelector,
  driverObjectDependencyItemIdsSelector,
  indirectDriverDependenciesSelector,
} from 'selectors/spotCheckDriverGridSelectors';
import { submodelGroupsSelector } from 'selectors/submodelTableGroupsSelector';
import { ParametricSelector, Selector } from 'types/redux';

type OrderedDriverRowKeysSelectorProps = {
  layerComparisons: Array<LayerId | undefined>;
  blockId: BlockId;
};

const orderedDriverRowKeysSelector: ParametricSelector<
  OrderedDriverRowKeysSelectorProps,
  CellRowKey[]
> = createDeepEqualSelector(
  mustDetailPaneDriverIdSelector,
  (state: RootState) => driverObjectDependencyItemIdsSelector(state, { layerId: undefined }),
  directDriverDependenciesSelector,
  indirectDriverDependenciesSelector,
  directDriverDependentsSelector,
  driverDetailPaneTabSelector,
  driverDetailPaneShowIndirectInputsSelector,
  currentLayerIdSelector,
  (state: RootState, { blockId }: OrderedDriverRowKeysSelectorProps) =>
    comparisonTimePeriodsForBlockSelector(state, blockId),
  fieldSelector<OrderedDriverRowKeysSelectorProps, 'layerComparisons'>('layerComparisons'),
  (state: RootState, { blockId }: OrderedDriverRowKeysSelectorProps) =>
    comparisonColumnsForBlockSelector(state, blockId),
  (state: RootState, { blockId }: OrderedDriverRowKeysSelectorProps) =>
    comparisonLayoutSelector(state, blockId),
  (state: RootState, { blockId }: OrderedDriverRowKeysSelectorProps) =>
    isShowingComparisonDriverHeaderRowSelector(state, blockId),
  (
    primaryId,
    driverObjectDependencyItems,
    directDependencyIds,
    indirectDependencyIds,
    directDependentIds,
    currTab,
    showingIndirectInputs,
    currentLayerId,
    comparisonTimePeriods,
    layerComparisons,
    comparisonColumns,
    comparisonLayout,
    isShowingComparisonDriverHeaderRow,
    // eslint-disable-next-line max-params
  ) => {
    const primaryRowKeys = getOrderedRowKeysForDrivers({
      driverIds: [primaryId],
      layerComparisons,
      comparisonColumns,
      comparisonLayout,
      isShowingComparisonDriverHeaderRow,
      currentLayerId,
      comparisonTimePeriods,
    });

    if (currTab === 'inputs') {
      const objectDepRowKeys = Array.from(driverObjectDependencyItems.values()).flatMap((item) => {
        const databaseTraceRowKey: DatabaseTraceRowKey = {
          specId: item.specId,
          fieldSpecId: item.fieldSpecId,
        };
        return [
          databaseTraceRowKey,
          ...(item.subDriverIds != null
            ? item.subDriverIds.map(
                (subDriverId): DriverRowKey => ({
                  driverId: subDriverId,
                  groupId: undefined,
                  layerId: undefined,
                }),
              )
            : item.objectIds.map(
                (objectId): ObjectTraceRowKey => ({
                  objectId,
                  specId: item.specId,
                  fieldSpecId: item.fieldSpecId,
                }),
              )),
        ];
      });
      const directDependencyRowKeys = getOrderedRowKeysForDrivers({
        driverIds: directDependencyIds,
        layerComparisons,
        groupId: DRIVER_DETAILS_DIRECT_REFERENCE_GROUP_ID,
        comparisonColumns,
        comparisonLayout,
        isShowingComparisonDriverHeaderRow,
        currentLayerId,
        comparisonTimePeriods,
      });
      const indirectDependencyRowKeys = showingIndirectInputs
        ? getOrderedRowKeysForDrivers({
            driverIds: indirectDependencyIds,
            layerComparisons,
            groupId: DRIVER_DETAILS_INDIRECT_REFERENCE_GROUP_ID,
            comparisonColumns,
            comparisonLayout,
            isShowingComparisonDriverHeaderRow,
            currentLayerId,
            comparisonTimePeriods,
          })
        : [];

      return [
        ...primaryRowKeys,
        ...objectDepRowKeys,
        ...directDependencyRowKeys,
        ...indirectDependencyRowKeys,
      ];
    }

    if (currTab === 'usedBy') {
      const directDependentRowKeys = getOrderedRowKeysForDrivers({
        driverIds: directDependentIds,
        layerComparisons,
        groupId: DRIVER_DETAILS_USED_BY_GROUP_ID,
        comparisonColumns,
        comparisonLayout,
        isShowingComparisonDriverHeaderRow,
        currentLayerId,
        comparisonTimePeriods,
      });
      return [...primaryRowKeys, ...directDependentRowKeys];
    }

    // The primary rows are still visible on the plans tab
    return primaryRowKeys;
  },
);

const blockCellRowKeysSelector: ParametricSelector<BlockId, CellRowKey[]> = createCachedSelector(
  (state: RootState) => state,
  blockIdSelector,
  blockTypeSelector,
  comparisonColumnsForBlockSelector,
  comparisonLayoutSelector,
  isShowingComparisonDriverHeaderRowSelector,
  comparisonTimePeriodsForBlockSelector,
  currentLayerIdSelector,
  // eslint-disable-next-line max-params
  function blockCellRowKeysSelector(
    state,
    blockId,
    blockType,
    comparisonColumns,
    comparisonLayout,
    isShowingComparisonDriverHeaderRow,
    comparisonTimePeriods,
    currentLayerId,
  ) {
    const layerComparisons = comparisonLayerIdsForBlockSelector(state, blockId);

    if (isBlockDriverDetailPageSelector(state, blockId)) {
      return orderedDriverRowKeysSelector(state, { layerComparisons, blockId });
    }

    if (isBlockPlanDetailPageSelector(state, blockId)) {
      return getOrderedRowKeysForDrivers({
        driverIds: planDetailPaneDriverIdsSelector(state),
        layerComparisons,
        comparisonColumns,
        comparisonLayout,
        isShowingComparisonDriverHeaderRow,
        currentLayerId,
        comparisonTimePeriods,
      });
    }

    if (isViewingExtDriversTableSelector(state)) {
      return orderedVisibleExtDriverRowKeysSelector(state);
    }

    // blockId is in a submodel page
    const submodelId = submodelPageIdForBlockIdSelector(state, blockId);
    if (submodelId != null) {
      return submodelGroupsSelector(state, { submodelId, layerId: currentLayerId }).flatMap(
        (groupWithDrivers) => {
          const driverRows: CellRowKey[] = getOrderedRowKeysForDrivers({
            driverIds: groupWithDrivers.driverIds,
            layerComparisons,
            groupId: groupWithDrivers.id,
            comparisonColumns,
            comparisonLayout,
            isShowingComparisonDriverHeaderRow,
            currentLayerId,
            comparisonTimePeriods,
          });
          driverRows.push({
            driverId: undefined,
            layerId: undefined,
            groupId: groupWithDrivers.id,
          });
          return driverRows;
        },
      );
    }

    if (isScenarioComparisonDriverBlockSelector(state, blockId)) {
      return getOrderedRowKeysForDrivers({
        driverIds: scenarioComparisonDriverIdsSelector(state),
        layerComparisons,
        comparisonColumns,
        comparisonLayout,
        isShowingComparisonDriverHeaderRow,
        currentLayerId,
        comparisonTimePeriods,
      });
    } else if (isScenarioComparisonDatabaseBlockSelector(state, blockId)) {
      return getOrderedRowKeysForObjectFields({
        fields: scenarioComparisonFieldsByObjectIdByObjectSpecIdSelector(state),
        layerComparisons,
      });
    }

    if (blockType === BlockType.ObjectTable) {
      return orderedObjectTableBlockRowKeysSelector(state, blockId);
    }

    if (blockType === BlockType.ObjectGrid) {
      return orderedObjectGridBlockRowKeysSelector(state, blockId);
    }

    const driverRows: CellRowKey[] = getOrderedRowKeysForDrivers({
      driverIds: orderedDriverGridBlockDriversSelector(state, blockId),
      layerComparisons,
      comparisonColumns,
      comparisonLayout,
      isShowingComparisonDriverHeaderRow,
      currentLayerId,
      comparisonTimePeriods,
    });
    driverRows.push({
      driverId: undefined,
      layerId: undefined,
      groupId: undefined,
    });

    return driverRows;
  },
)({
  keySelector: blockIdSelector,
  selectorCreator: createDeepEqualSelector,
});

const EMPTY_ARRAY: CellRowKey[] = [];
const selectedBlockCellRowKeysSelector = createSelector(
  prevailingCellSelectionBlockIdSelector,
  (state: RootState) => state,
  (blockId, state) => {
    if (blockId == null) {
      return EMPTY_ARRAY;
    }
    return blockCellRowKeysSelector(state, blockId);
  },
);

export const blockCellColumnKeysSelector: ParametricSelector<BlockId, CellColumnKey[]> =
  createCachedSelector(
    blockIdSelector,
    blockTypeSelector,
    (state: RootState) => state,
    function blockCellColumnKeysSelector(blockId, blockType, state) {
      const isScenarioComparisonDatabaseBlock = isScenarioComparisonDatabaseBlockSelector(
        state,
        blockId,
      );

      if (isScenarioComparisonDatabaseBlock) {
        const timeSeriesColumns = timeSeriesColumnsForBlockSelector(state, blockId);
        return [
          OBJECT_PROPERTY_COLUMN_KEY,
          OBJECT_INITIAL_VALUE_COLUMN_KEY,
          ...timeSeriesColumns.map((col) =>
            getMonthColumnKey(col.mks[0], col.rollupType, col.subLabel),
          ),
        ];
      }

      if (blockType === BlockType.ObjectTable) {
        return orderedObjectTableBlockColumnKeysSelector(state, blockId);
      } else if (isViewingExtDriversTableSelector(state)) {
        return orderedExtDriverColumnKeysSelector(state);
      }
      return orderedDriverGridBlockColumnKeysSelector(state, blockId);
    },
  )({
    keySelector: blockIdSelector,
    selectorCreator: createDeepEqualSelector,
  });

export const blockCellKeysSelector: ParametricSelector<
  BlockId,
  { orderedRowKeys: CellRowKey[]; orderedColumnKeys: CellColumnKey[] }
> = createCachedSelector(
  blockCellRowKeysSelector,
  blockCellColumnKeysSelector,
  (orderedRowKeys, orderedColumnKeys) => {
    return {
      orderedRowKeys,
      orderedColumnKeys,
    };
  },
)({
  keySelector: blockIdSelector,
});

/**
 * The selected cell block keys are the keys around the cell where the user has an active selection.
 * This is useful for things like rendering selection styles.
 */
const EMPTY = {
  orderedRowKeys: [],
  orderedColumnKeys: [],
};
export const selectedBlockCellKeysSelector = createSelector(
  prevailingCellSelectionBlockIdSelector,
  (state: RootState) => state,
  (blockId, state) => {
    if (blockId == null) {
      return EMPTY;
    }

    return blockCellKeysSelector(state, blockId);
  },
);

export const selectedBlockOrderedSelectedRowKeys: Selector<CellRowKey[]> = createDeepEqualSelector(
  selectedBlockCellRowKeysSelector,
  prevailingCellSelectionSelector,
  (orderedRowKeys, selection) => {
    const selectedCellsRowKeys: CellRowKey[] = selection?.selectedCells.map((c) => c.rowKey) ?? [];
    return orderedRowKeys.filter((rowKey) =>
      selectedCellsRowKeys.some((rk) => isRowKeyEqual(rk, rowKey)),
    );
  },
);

const getOrderedRowKeysForObjectFields = ({
  fields,
  layerComparisons,
}: {
  fields: ReturnType<typeof scenarioComparisonFieldsByObjectIdByObjectSpecIdSelector>;
  layerComparisons: Array<LayerId | undefined>;
}) => {
  const hasFieldHeaderRow = layerComparisons.length > 1;
  const orderedFieldSpecIds = Object.values(fields)
    .flatMap((r) => Object.values(r).flat())
    .filter((diff) => 'fieldSpecId' in diff) as ObjectFieldDiff[];

  return orderedFieldSpecIds.flatMap(({ objectId, fieldSpecId }) => {
    return [
      ...(hasFieldHeaderRow
        ? [
            {
              objectId,
              layerId: undefined,
              fieldSpecId,
            },
          ]
        : []),
      ...layerComparisons.map((layerId) => ({
        objectId,
        layerId,
        fieldSpecId,
      })),
    ];
  });
};
