import { isEqual, uniqWith } from 'lodash';
import { DateTime } from 'luxon';

import { DEFAULT_PLAN_NAME, WithEventGroup, eventGroupIdFromWithEventGroup } from '@features/Plans';
import {
  DriverEventEntity,
  EventEntity,
  ObjectFieldEventEntity,
} from '@features/Plans/EventEntity';
import {
  BusinessObjectUpdateInput,
  CurveType,
  DatasetMutationInput,
  EventCreateInput,
  EventDeleteInput,
  EventGroupCreateInput,
  EventGroupDeleteInput,
  EventGroupUpdateInput,
  EventUpdateInput,
  ImpactType,
  Maybe,
  ModifierType,
  ValueType,
} from 'generated/graphql';
import { deepClone } from 'helpers/clone';
import {
  extractMonthKey,
  getDateTimeFromMonthKey,
  getISOTimeWithoutMs,
  getISOTimeWithoutMsFromMonthKey,
  getStartAndEndMonthKeys,
} from 'helpers/dates';
import { getAllNestedEventGroups, getAllNestedEvents } from 'helpers/eventGroups';
import {
  getCustomCurvePoints,
  getCustomCurvePointsTimeRange,
  getDeltaMonthImpact,
  getSortedEventGroupsForEntityAtMonthKeys,
  getUpdateFromEvents,
  isEventSettingStartDate,
} from 'helpers/events';
import { convertNumericTimeSeriesToGql, convertTimeSeriesToGql } from 'helpers/gqlDataset';
import { mergeAllMutations, mergeMutations } from 'helpers/mergeMutations';
import { getNameWithCopySuffix } from 'helpers/naming';
import { getNumber } from 'helpers/number';
import { getObjectFieldUUID } from 'helpers/object';
import {
  backfillSiblingSortIndexMutations,
  peekMutationStateChange,
  sortIndexMutationsForInsertion,
  stripInsertBeforeId,
} from 'helpers/sortIndex';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { updateEventGroups } from 'reduxStore/actions/eventGroupMutations';
import { selectPlanTimelineEntityRowForEvent } from 'reduxStore/actions/liveEdit';
import {
  getMutationThunkAction,
  submitAutoLayerizedMutations,
} from 'reduxStore/actions/submitDatasetMutation';
import { BlockId } from 'reduxStore/models/blocks';
import { BusinessObjectFieldSpecId } from 'reduxStore/models/businessObjectSpecs';
import { BusinessObjectFieldId, BusinessObjectId } from 'reduxStore/models/businessObjects';
import {
  CurvePointTyped,
  DEFAULT_EVENT_GROUP_ID,
  Event,
  EventGroupId,
  EventId,
  PopulatedEventGroup,
  isDeltaImpactEvent,
  isDriverEvent,
  isObjectFieldEvent,
  isSetImpactEvent,
} from 'reduxStore/models/events';
import {
  NonValueTimeSeries,
  NumericTimeSeries,
  ValueTimeSeries,
  ValueTimeSeriesWithEmpty,
  convertToValueTimeSeries,
} from 'reduxStore/models/timeSeries';
import { Value } from 'reduxStore/models/value';
import bulkCreateEventsAndGroupsMutations, {
  getDeleteEventsToClearStackedEvents,
} from 'reduxStore/reducers/helpers/bulkCreateEventsAndGroupsMutations';
import { defaultValueForField } from 'reduxStore/reducers/helpers/businessObjectSpecs';
import { NO_PARENT_ID, WILL_CLEAR_CURVE_POINTS_WARNING } from 'reduxStore/reducers/helpers/events';
import { LiveEditType, setEventLiveEditWarning } from 'reduxStore/reducers/liveEditSlice';
import { selectPlanTimelineItemRef } from 'reduxStore/reducers/pageSlice';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { AppThunk } from 'reduxStore/store';
import {
  businessObjectFieldByFieldIdSelector,
  businessObjectFieldSelector,
  businessObjectFieldSpecByIdSelector,
  businessObjectFieldSpecForFieldIdSelector,
  businessObjectFieldSpecSelector,
} from 'selectors/businessObjectFieldSpecsSelector';
import { businessObjectFieldValueForMonthKeySelector } from 'selectors/businessObjectTimeSeriesSelector';
import {
  businessObjectsByIdForLayerSelector,
  materializedFieldForSpecIdAndObjectIdSelector,
} from 'selectors/businessObjectsSelector';
import {
  driverTimeSeriesSelector,
  driverValueForMonthKeySelector,
} from 'selectors/driverTimeSeriesSelector';
import { driverSelector } from 'selectors/driversSelector';
import {
  defaultEventGroupSelector,
  eventGroupNamesForCurrentLayerSetSelector,
  eventGroupSelector,
  eventLiveEditWillClearCurvePointsSelector,
  eventSelector,
  eventWithoutLiveEditsSelector,
  eventsByIdForLayerSelector,
  eventsForDriverSelector,
  eventsForObjectFieldSelector,
  parentIdsByEventAndGroupIdsSelector,
  populatedEventGroupSelector,
  populatedEventGroupsByIdForLayerSelector,
  visibleEventsByObjectFieldIdForLayerSelector,
} from 'selectors/eventsAndGroupsSelector';
import { liveEditSelector, liveEditingSingleEventSelector } from 'selectors/liveEditSelector';
import { authenticatedUserSelector } from 'selectors/loginSelector';
import {
  selectedEventGroupIdsWithoutSelectedParentSelector,
  selectedEventIdsWithoutSelectedParentSelector,
} from 'selectors/selectedEventSelector';
import { prevailingSelectionBlockIdSelector } from 'selectors/selectionSelector';
import { MonthKey } from 'types/datetime';

function cleanTimestamps<T extends { start?: Maybe<string>; end?: Maybe<string> }>(orig: T) {
  // N.B. we clean these timestamps to avoid having milliseconds because it causes
  // problems in serialization with the backend.
  const copy = { ...orig };
  if (orig.start != null) {
    copy.start = getISOTimeWithoutMs(orig.start);
  }

  if (orig.end != null) {
    copy.end = getISOTimeWithoutMs(orig.end);
  }
  return copy;
}

type InsertBeforeId = EventId | EventGroupId | 'end';

// These are the core mutations that everything else should use if possible
const mutationActions = {
  bulkCreateEventsAndGroups: getMutationThunkAction<{
    events: EventCreateInput[];
    groups: EventGroupCreateInput[];
    // Any extra mutation actions that need to be in the same batch
    extras?: DatasetMutationInput;
  }>((newItems, getState) => {
    return bulkCreateEventsAndGroupsMutations(getState(), newItems);
  }),
  deleteEventsAndGroups: getMutationThunkAction<{
    events: EventDeleteInput[];
    groups: EventGroupDeleteInput[];
  }>(({ events, groups }) => getDeleteMutation(events, groups)),
  updateEventsAndGroupsVisibility: getMutationThunkAction<{
    eventUpdates: Array<Pick<EventUpdateInput, 'id' | 'hidden'>>;
    eventGroupUpdates: Array<Pick<EventGroupUpdateInput, 'id' | 'hidden'>>;
  }>(({ eventUpdates, eventGroupUpdates }) => {
    return {
      updateEvents: eventUpdates,
      updateEventGroups: eventGroupUpdates,
    };
  }),
  updateEventsAndGroups: getMutationThunkAction<{
    events: Array<EventUpdateInput & { insertBeforeId?: InsertBeforeId }>;
    groups: Array<EventGroupUpdateInput & { insertBeforeId?: InsertBeforeId }>;
  }>(({ events, groups }, getState) => {
    return getUpdateMutation(events, groups, getState());
  }),
  deleteAndUpdateEventsAndGroups: getMutationThunkAction<{
    deleteEvents: EventDeleteInput[];
    deleteGroups: EventGroupDeleteInput[];
    updateEvents: Array<EventUpdateInput & { insertBeforeId?: InsertBeforeId }>;
    updateGroups: Array<EventGroupUpdateInput & { insertBeforeId?: InsertBeforeId }>;
  }>(({ updateEvents, updateGroups, deleteEvents, deleteGroups }, getState) => {
    const hasUpdate = updateEvents.length !== 0;
    const hasDelete = deleteEvents.length !== 0;

    if (hasUpdate && hasDelete) {
      const updateMutation = getUpdateMutation(updateEvents, updateGroups, getState());
      const deleteMutation = getDeleteMutation(deleteEvents, deleteGroups);
      return mergeMutations(updateMutation, deleteMutation);
    } else if (hasUpdate) {
      return getUpdateMutation(updateEvents, updateGroups, getState());
    }

    return getDeleteMutation(deleteEvents, deleteGroups);
  }),
};
export const updateEvents =
  (updates: Array<EventUpdateInput & { insertBeforeId?: InsertBeforeId }>): AppThunk =>
  (dispatch) => {
    dispatch(updateEventsAndGroups({ events: updates, groups: [] }));
  };

const updateEvent =
  (update: EventUpdateInput & { insertBeforeId?: InsertBeforeId }): AppThunk =>
  (dispatch) => {
    dispatch(updateEvents([update]));
  };

const getUpdateMutation = (
  events: Array<EventUpdateInput & { insertBeforeId?: InsertBeforeId }>,
  groups: Array<EventGroupUpdateInput & { insertBeforeId?: InsertBeforeId }>,
  beforeState: RootState,
): DatasetMutationInput => {
  const eventsCopy = events.map(cleanTimestamps);

  const deleteEvents = getDeleteEventsToClearStackedEvents(beforeState, eventsCopy);

  let mutations: DatasetMutationInput = {
    updateEvents: eventsCopy,
    updateEventGroups: groups,
    deleteEvents,
  };

  // In order to bulk backfill sortIndexes, it is easier to update the state
  // and then apply sort index updates (if necessary) afterwards.
  let state = peekMutationStateChange(beforeState, mutations);

  // N.B. mutations for events are handled before mutations for event groups.
  [...events, ...groups].forEach((update) => {
    const { id, insertBeforeId } = update;

    // If we are not updating the ordering, then we just need to make sure
    // that siblings are backfilled. Otherwise, we also update the relevant
    // entity. We differentiate here to avoid overriding the sort index every
    // time.
    const backfillData =
      insertBeforeId == null
        ? backfillSiblingSortIndexMutations(state, id)
        : sortIndexMutationsForInsertion(state, id, insertBeforeId);
    state = peekMutationStateChange(state, backfillData);
    mutations = mergeMutations(mutations, backfillData);
  });

  return stripInsertBeforeId(mutations);
};

const getDeleteMutation = (
  events: Array<EventUpdateInput & { insertBeforeId?: InsertBeforeId }>,
  groups: Array<EventGroupUpdateInput & { insertBeforeId?: InsertBeforeId }>,
): DatasetMutationInput => {
  return {
    deleteEvents: events,
    deleteEventGroups: groups,
  };
};

export const saveNewOrExistingEventLiveEdit = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const liveEditingEvent = liveEditingSingleEventSelector(state);

    // TODO: (T-6673) Handle non driver events
    if (liveEditingEvent == null || !isDriverEvent(liveEditingEvent)) {
      return;
    }

    dispatch(saveLiveEditedEvents());
  };
};

type NewEventBase = {
  customCurvePoints: NonValueTimeSeries;
  impactType: ImpactType;
};

export type NewEventForDriver = NewEventBase & DriverEventEntity;

export type NewEventForObjectField = NewEventBase & ObjectFieldEventEntity;

// If newEvents includes an event on an object field that doesn't exist yet, we need to add the field manually
function getAddFieldMutations(
  state: RootState,
  newEvents: Array<NewEventForDriver | NewEventForObjectField>,
): DatasetMutationInput {
  const addFieldMutations: Array<Pick<BusinessObjectUpdateInput, 'id' | 'addFields'>> = [];
  // We only need to add each field once, otherwise we'll get an error
  const alreadyAddedFieldIds = new Set();

  newEvents.forEach((e) => {
    if (e.type !== 'objectField') {
      return;
    }

    if (alreadyAddedFieldIds.has(e.objectFieldId)) {
      return;
    }

    const existingField = businessObjectFieldSelector(state, { id: e.objectFieldId });
    if (existingField != null) {
      return;
    }

    const fieldSpec = businessObjectFieldSpecSelector(state, { id: e.fieldSpecId });
    if (fieldSpec == null) {
      return;
    }

    const addFieldMutation = {
      id: e.objectId,
      addFields: [
        {
          fieldSpecId: e.fieldSpecId,
          id: e.objectFieldId,
          value: {
            type: fieldSpec.type,
          },
        },
      ],
    };

    addFieldMutations.push(addFieldMutation);
    alreadyAddedFieldIds.add(e.objectFieldId);
  });

  if (addFieldMutations.length === 0) {
    return {};
  }

  return {
    updateBusinessObjects: addFieldMutations,
  };
}

export const createNewEventsWithEventGroup = ({
  newEvents,
  withEventGroup,
}: {
  newEvents: Array<NewEventForDriver | NewEventForObjectField>;
  withEventGroup: WithEventGroup;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    const authenticatedUser = authenticatedUserSelector(state);
    if (!authenticatedUser) {
      return;
    }

    const createEvents = newEvents
      .map((newEvent) => {
        const newEventId = uuidv4();

        const { type, impactType, customCurvePoints } = newEvent;

        const monthKeys = Object.keys(customCurvePoints);

        const { startMonthKey, endMonthKey } = getStartAndEndMonthKeys(monthKeys);
        if (startMonthKey == null || endMonthKey == null) {
          return null;
        }

        const startDateTime = getDateTimeFromMonthKey(startMonthKey);
        const endDateTime = getDateTimeFromMonthKey(endMonthKey).endOf('month').startOf('second');

        let valueType: ValueType;
        if (type === 'driver') {
          const driver = driverSelector(state, { id: newEvent.driverId });
          valueType = driver?.valueType ?? ValueType.Number;
        } else {
          const objectSpec = businessObjectFieldSpecForFieldIdSelector(state, {
            id: newEvent.objectFieldId,
          });
          valueType = objectSpec?.type ?? ValueType.Number;
        }

        const createEvent: EventCreateInput = {
          id: newEventId,
          driverId: type === 'driver' ? newEvent.driverId : undefined,
          businessObjectFieldId: type === 'driver' ? undefined : newEvent.objectFieldId,
          start: startDateTime.toISO(),
          end: endDateTime.toISO(),
          modifierType: ModifierType.Add,
          impactType,
          curveType: CurveType.Custom,
          ownerName: authenticatedUser?.name ?? '',
          customCurvePoints: convertTimeSeriesToGql(
            convertToValueTimeSeries(customCurvePoints, valueType),
          ),
          setValueType: valueType,
        };

        return createEvent;
      })
      .filter(isNotNull);

    const updateObjectsMutation = getAddFieldMutations(state, newEvents);

    const mutation = createNewEventMutationsBatched({
      state,
      newEvents: createEvents,
      withEventGroup,
      extras: updateObjectsMutation,
    });

    if (mutation == null) {
      return;
    }

    dispatch(submitAutoLayerizedMutations('create-events-and-group', [mutation]));
  };
};

export const createNewEventMutations = ({
  state,
  newEvent,
  withEventGroup,
  extras,
}: {
  state: RootState;
  newEvent: EventCreateInput;
  withEventGroup: WithEventGroup;
  // These mutations will be returned along with the newEvent mutations.
  // These could be important because bulkCreateEventsAndGroupsMutations
  // needs to know the up-to-date state of the dataset in order to generate
  // the mutation properly (e.g. knowing about newly added fields to objects).
  extras?: DatasetMutationInput;
}) => {
  const authenticatedUser = authenticatedUserSelector(state);
  if (!authenticatedUser) {
    return undefined;
  }

  // Copy to avoid modifying input
  const createEvent = deepClone(newEvent);

  if (withEventGroup.type === 'existing') {
    createEvent.parentId = withEventGroup.eventGroupId;
    return bulkCreateEventsAndGroupsMutations(state, { events: [createEvent], groups: [], extras });
  }

  if (withEventGroup.type === 'new') {
    const { newEventGroup } = withEventGroup;
    createEvent.parentId = newEventGroup.id;
    return bulkCreateEventsAndGroupsMutations(state, {
      events: [createEvent],
      groups: [
        {
          id: newEventGroup.id,
          name: newEventGroup.name,
          ownerName: authenticatedUser.name,
          ownerId: authenticatedUser.id,
          eventIds: [newEvent.id],
        },
      ],
      extras,
    });
  }

  // Default event group
  return createNewEventInDefaultGroupMutation({ state, newEvent: createEvent, extras });
};

export const createNewEventMutationsBatched = ({
  state,
  newEvents,
  withEventGroup,
  extras,
}: {
  state: RootState;
  newEvents: EventCreateInput[];
  withEventGroup: WithEventGroup;
  extras?: DatasetMutationInput;
}) => {
  const newEventsMutations = newEvents
    .map((newEvent) => createNewEventMutations({ state, newEvent, withEventGroup, extras }))
    .filter(isNotNull);
  const mutations = mergeAllMutations(newEventsMutations);

  // If withEventGroup creates a new event group, then mutations.newEventGroups
  // will contain multiple of the same mutation. We could just keep the first
  // one, but we use uniqWith to be safe. Similarly, updateBusinessObjects could
  // contain multiple of the same addField mutations.
  mutations.newEventGroups = uniqWith(mutations.newEventGroups, isEqual);
  mutations.updateBusinessObjects = uniqWith(mutations.updateBusinessObjects, isEqual);

  return mutations;
};

// Returns a mutation with the new event, and also the mutation to create the default
// event group if it doesn't already exist. In order to link the two, it overrides
// the parentId of the new event regardless of what was passed in.
const createNewEventInDefaultGroupMutation = ({
  state,
  newEvent,
  extras,
}: {
  state: RootState;
  newEvent: EventCreateInput;
  extras?: DatasetMutationInput;
}) => {
  const defaultEventGroup = defaultEventGroupSelector(state);

  const newEventWithUpdatedEventGroup = {
    ...newEvent,
    parentId: DEFAULT_EVENT_GROUP_ID,
  };
  const newDefaultEventGroupMutation: EventGroupCreateInput | undefined =
    defaultEventGroup == null
      ? {
          id: DEFAULT_EVENT_GROUP_ID,
          name: DEFAULT_PLAN_NAME,
          ownerId: '',
          ownerName: '',
          eventIds: [newEvent.id],
        }
      : undefined;

  return bulkCreateEventsAndGroupsMutations(state, {
    events: [newEventWithUpdatedEventGroup],
    groups: newDefaultEventGroupMutation ? [newDefaultEventGroupMutation] : [],
    extras,
  });
};

function createNewBusinessObjectFieldEventMutation({
  state,
  newEvent: newEventInput,
  withEventGroup,
}: {
  state: RootState;
  newEvent: {
    fieldIdOrUndefined?: BusinessObjectFieldId;
    businessObjectId: BusinessObjectId;
    businessObjectFieldSpecId: BusinessObjectFieldSpecId;
    values: ValueTimeSeriesWithEmpty;
  };
  withEventGroup: WithEventGroup;
}) {
  const { fieldIdOrUndefined, businessObjectId, businessObjectFieldSpecId, values } = newEventInput;

  const authenticatedUser = authenticatedUserSelector(state);
  if (authenticatedUser == null) {
    return undefined;
  }
  if (Object.keys(values).length === 0) {
    return undefined;
  }
  const ownerName = authenticatedUser.name;

  const businessObject = businessObjectsByIdForLayerSelector(state)[businessObjectId];
  const businessObjectFieldSpec =
    businessObjectFieldSpecByIdSelector(state)[businessObjectFieldSpecId];
  if (businessObjectFieldSpec == null) {
    throw new Error(
      `Error creating field event for deleted field spec ${businessObjectFieldSpecId}`,
    );
  }

  const [start, end] = getCustomCurvePointsTimeRange(values);

  let newFieldId: BusinessObjectFieldId | undefined;
  if (fieldIdOrUndefined == null) {
    newFieldId = getObjectFieldUUID(businessObject.id, businessObjectFieldSpecId);
  } else {
    const field = businessObjectFieldSelector(state, { id: fieldIdOrUndefined });
    if (field == null) {
      newFieldId = fieldIdOrUndefined;
    }
  }

  const firstMonth = Object.keys(values)[0];
  const newEvent: EventCreateInput = {
    id: uuidv4(),
    businessObjectFieldId: fieldIdOrUndefined ?? newFieldId,
    start,
    end,
    ownerName,
    customCurvePoints: convertTimeSeriesToGql(values),
    setValueType: values[firstMonth]?.type,
  };

  let updateBusinessObject: BusinessObjectUpdateInput | undefined;

  // If no field exists for the given business object and field spec, then we need to
  // create one that will correspond to the newly created event.
  if (newFieldId != null) {
    const defaultValue = defaultValueForField(businessObjectFieldSpec);
    updateBusinessObject = {
      id: businessObject.id,
      ...(newFieldId != null
        ? {
            addFields: [
              {
                id: newFieldId,
                fieldSpecId: businessObjectFieldSpecId,
                value: {
                  type: businessObjectFieldSpec.type,
                  initialValue:
                    defaultValue?.value != null ? String(defaultValue.value) : undefined,
                },
              },
            ],
          }
        : {}),
    };
  }

  return createNewEventMutations({
    state,
    newEvent,
    withEventGroup,
    extras: {
      updateBusinessObjects: updateBusinessObject != null ? [updateBusinessObject] : [],
    },
  });
}

const createNewBusinessObjectFieldEvent =
  ({
    newGroup,
    ...props
  }: {
    fieldIdOrUndefined?: BusinessObjectFieldId;
    businessObjectId: BusinessObjectId;
    businessObjectFieldSpecId: BusinessObjectFieldSpecId;
    monthKey: MonthKey;
    value: Value;
    newGroup?: {
      name: string;
      id: EventGroupId;
    };
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const withEventGroup: WithEventGroup =
      newGroup != null
        ? {
            type: 'new',
            newEventGroup: newGroup,
          }
        : {
            type: 'default',
          };
    const mutation = createNewBusinessObjectFieldEventMutation({
      state,
      newEvent: {
        values: { [props.monthKey]: props.value },
        ...props,
      },
      withEventGroup,
    });
    if (mutation == null) {
      return;
    }
    dispatch(submitAutoLayerizedMutations('create-business-object-field', [mutation]));
  };

export const updateObjectFieldForecastMutation = (
  state: RootState,
  id: BusinessObjectFieldId,
  values: ValueTimeSeriesWithEmpty,
  eventGroupId?: EventGroupId,
): DatasetMutationInput | undefined => {
  const fieldsById = businessObjectFieldByFieldIdSelector(state);
  const field = fieldsById[id];
  if (field == null) {
    return undefined;
  }

  const groupIds = getSortedEventGroupsForEntityAtMonthKeys(
    visibleEventsByObjectFieldIdForLayerSelector(state),
    [id],
    Object.keys(values),
    DEFAULT_EVENT_GROUP_ID,
  );

  const eventGroupIdToUse = eventGroupId ?? safeObjGet(groupIds[0]);

  const eventGroup =
    eventGroupIdToUse == null ? null : populatedEventGroupSelector(state, eventGroupIdToUse);
  const existingEventOnField = eventGroup?.events.find(
    (ev) => isObjectFieldEvent(ev) && ev.businessObjectFieldId === id,
  );

  if (existingEventOnField != null) {
    return {
      updateEvents: [updateEventImpactsMutation(state, existingEventOnField.id, values, 'impact')],
    };
  } else {
    return createNewBusinessObjectFieldEventMutation({
      state,
      newEvent: {
        fieldIdOrUndefined: id,
        businessObjectId: field.objectId,
        businessObjectFieldSpecId: field.fieldSpecId,
        values,
      },
      withEventGroup:
        eventGroupId != null ? { type: 'existing', eventGroupId } : { type: 'default' },
    });
  }
};

export const updateObjectEventImpactDiffFromCell = ({
  businessObjectId,
  businessObjectFieldSpecId,
  monthKey,
  value,
}: {
  businessObjectId: BusinessObjectId;
  businessObjectFieldSpecId: BusinessObjectFieldSpecId;
  monthKey: MonthKey;
  value: Value;
}): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    const field = materializedFieldForSpecIdAndObjectIdSelector(state, {
      businessObjectId,
      businessObjectFieldSpecId,
    });

    const groupIds =
      field == null
        ? []
        : getSortedEventGroupsForEntityAtMonthKeys(
            visibleEventsByObjectFieldIdForLayerSelector(state),
            [field?.id],
            [monthKey],
            DEFAULT_EVENT_GROUP_ID,
          );

    // TODO(T-15297) choose event in a more sensible way, e.g. most recent updated event/plan
    const eventGroupId = safeObjGet(groupIds[0]);

    const eventGroup =
      eventGroupId == null ? null : populatedEventGroupSelector(state, eventGroupId);
    const existingEventOnField = eventGroup?.events.find(
      (ev) => isObjectFieldEvent(ev) && ev.businessObjectFieldId === field?.id,
    );

    if (existingEventOnField != null) {
      dispatch(updateEventFromCell(existingEventOnField.id, monthKey, value));
    } else {
      dispatch(
        createNewBusinessObjectFieldEvent({
          fieldIdOrUndefined: field?.id,
          businessObjectId,
          businessObjectFieldSpecId,
          monthKey,
          value,
        }),
      );
    }
  };
};

// Uses a given Event as the template from which to create a new Event with a
// single curve point using the given monthKey and value.
function createEventInputWithSingleCurvePointFromBase(
  baseEvent: Event,
  monthKey: MonthKey,
  value: Value,
): EventCreateInput {
  const { version: _, monthKey: _monthKey, value: _value, ...baseEventProperties } = baseEvent;

  const start = getISOTimeWithoutMsFromMonthKey(monthKey);
  const end = DateTime.fromISO(start).endOf('month').toISO();

  const curvePointRelatedFields = {
    start,
    end,
    customCurvePoints: convertTimeSeriesToGql({
      [monthKey]: value,
    }),
  };

  if (baseEventProperties.impactType === ImpactType.Delta) {
    return {
      ...baseEventProperties,
      ...curvePointRelatedFields,
      totalImpact:
        baseEventProperties.totalImpact != null ? `${baseEventProperties.totalImpact}` : undefined,
    };
  }

  const { valueType: _valueType, ...rest } = baseEventProperties;

  return {
    ...rest,
    ...curvePointRelatedFields,
  };
}

function getNewStretchEvents(
  originalEvent: Event,
  updatedEvent: Event,
): { newEvents: EventCreateInput[]; newValue: Value } {
  const originalCurvePointMonthKeys = Object.keys(originalEvent.customCurvePoints ?? {});
  const updatedCurvePointsLength = Object.keys(updatedEvent.customCurvePoints ?? {}).length;
  const originalCurvePointMonthKey = originalCurvePointMonthKeys[0];
  const originalValue = (originalEvent.customCurvePoints ?? {})[originalCurvePointMonthKey];

  if (originalValue.type !== ValueType.Number) {
    return { newEvents: [], newValue: originalValue };
  }

  // For deltas we spread the original value over the # of newly stretched months.
  // For sets, we just use the original value
  const newValue: Value = {
    type: ValueType.Number,
    value:
      originalEvent.impactType === ImpactType.Delta
        ? originalValue.value / updatedCurvePointsLength
        : originalValue.value,
  };

  const newEvents = Object.keys(updatedEvent.customCurvePoints ?? {})
    .map((monthKey) => {
      if (originalEvent.customCurvePoints?.[monthKey] != null) {
        // Skip months that are already in the original event
        return null;
      }

      // Add new events for the newly stretched months. Use the properties from the
      // original curve point
      const newEvent: EventCreateInput = {
        ...createEventInputWithSingleCurvePointFromBase(originalEvent, monthKey, newValue),
        id: uuidv4(),
      };

      return newEvent;
    })
    .filter(isNotNull);

  return { newEvents, newValue };
}

export const saveLiveEditedEvents = ({
  shouldWarnToRemoveCurvePoints = false,
}: {
  shouldWarnToRemoveCurvePoints?: boolean;
} = {}): AppThunk => {
  return (dispatch, getState) => {
    let state = getState();
    const liveEdit = liveEditSelector(state);
    if (
      liveEdit == null ||
      liveEdit.type === LiveEditType.Forecast ||
      liveEdit.type === LiveEditType.BlockColumnResize
    ) {
      return;
    }

    if (liveEdit.type === LiveEditType.EventGroup) {
      dispatch(updateEventGroups(liveEdit.updates));
      return;
    }

    const updatedEvents =
      liveEdit.type === LiveEditType.MultiEvent ? liveEdit.events : [liveEdit.event];

    if (shouldWarnToRemoveCurvePoints) {
      const willClearCurvePoints = eventLiveEditWillClearCurvePointsSelector(state);

      if (willClearCurvePoints) {
        dispatch(
          setEventLiveEditWarning({
            warning: WILL_CLEAR_CURVE_POINTS_WARNING,
          }),
        );
        return;
      }
    }

    const mutationBatch = updatedEvents.reduce(
      (batch, updatedEvent) => {
        const originalEvent = eventWithoutLiveEditsSelector(state, updatedEvent.id);
        if (updatedEvent == null || originalEvent == null) {
          return batch;
        }

        const mutation = getUpdateFromEvents(originalEvent, updatedEvent);
        if (mutation == null) {
          return batch;
        }

        if ('update' in mutation) {
          const originalCurvePointMonthKeys = Object.keys(originalEvent.customCurvePoints ?? {});
          const updatedCurvePointsLength = Object.keys(updatedEvent.customCurvePoints ?? {}).length;
          const shouldCreateNewEvent =
            originalCurvePointMonthKeys.length === 1 &&
            updatedCurvePointsLength > originalCurvePointMonthKeys.length;

          if (shouldCreateNewEvent) {
            // This handles "stretching" events on the plan timeline, and we only want to use this behavior
            //  when the event only spans 1 month. If the event spans multiple months, we just use the
            // original "stretching" behavior.
            const { newEvents, newValue } = getNewStretchEvents(originalEvent, updatedEvent);

            if (newEvents.length > 0) {
              batch.creates.push(...newEvents);
              // We also need to update the original curve point's value to be the new value.
              const originalCurvePointMonthKey = originalCurvePointMonthKeys[0];
              const modifiedUpdate: EventUpdateInput = {
                ...mutation.update,
                customCurvePoints: convertTimeSeriesToGql({
                  [originalCurvePointMonthKey]: newValue,
                }),
              };
              batch.updates.push(modifiedUpdate);
            }
          } else {
            batch.updates.push(mutation.update);
          }
        }

        return batch;
      },
      {
        updates: [],
        creates: [],
      } as {
        updates: EventUpdateInput[];
        creates: EventCreateInput[];
      },
    );

    const createMutations =
      mutationBatch.creates.length > 0
        ? bulkCreateEventsAndGroupsMutations(getState(), {
            events: mutationBatch.creates,
            groups: [],
          })
        : {};
    const updateMutations =
      mutationBatch.updates.length > 0
        ? getUpdateMutation(mutationBatch.updates, [], getState())
        : {};

    dispatch(
      submitAutoLayerizedMutations('update-and-create-events', [createMutations, updateMutations]),
    );

    // If a new event was created, select the entire entity

    const createdEventIds = createMutations.newEvents?.map((event) => event.id) ?? [];

    if (createdEventIds.length === 0) {
      return;
    }

    state = getState();

    const blockId = prevailingSelectionBlockIdSelector(state);
    if (blockId == null) {
      return;
    }

    dispatch(
      selectPlanTimelineEntityRowForEvent({
        blockId,
        // All the new events should belong to the same entity, so just use the first one
        eventId: createdEventIds[0],
      }),
    );
  };
};

export const updateEventFromCell =
  (eventId: EventId, monthKey: MonthKey, value: Value | undefined): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const event = eventWithoutLiveEditsSelector(state, eventId);
    if (event == null) {
      return;
    }

    dispatch(updateEventImpacts(eventId, { [monthKey]: value }, 'impact'));
  };

const updateEventImpactsMutation = (
  state: RootState,
  eventId: EventId,
  values: ValueTimeSeriesWithEmpty,
  valueType: 'impact' | 'impactDriver',
) => {
  const eventsById = eventsByIdForLayerSelector(state);
  const event = eventsById[eventId];

  const makeCustom = isDeltaImpactEvent(event) && event.curveType !== CurveType.Custom;
  const eventUpdateInput: EventUpdateInput = {
    id: event.id,
    curveType: makeCustom ? CurveType.Custom : undefined,
    impactType: event.impactType,
  };

  if (valueType === 'impact' || isSetImpactEvent(event)) {
    const updatedImpacts: Record<MonthKey, Value> = { ...event.customCurvePoints };
    Object.entries(values).forEach(([monthKey, value]) => {
      if (value != null) {
        updatedImpacts[monthKey] = value;
      } else {
        delete updatedImpacts[monthKey];
      }
    });
    eventUpdateInput.customCurvePoints = convertTimeSeriesToGql(updatedImpacts);
  } else if (isDeltaImpactEvent(event)) {
    const { customCurvePoints, driverId } = event;
    const driverTimeSeries = driverTimeSeriesSelector(state, { id: driverId });

    const updatedImpacts: ValueTimeSeries = makeCustom
      ? getCustomCurvePoints(event)
      : { ...customCurvePoints };

    Object.entries(values).forEach(([monthKey, value]) => {
      if (value == null) {
        delete updatedImpacts[monthKey];
      } else if (value.type === ValueType.Number) {
        const currentValue = driverTimeSeries[monthKey];
        const currentImpact = getDeltaMonthImpact(event, monthKey) ?? 0;
        const currentValueWithoutImpact = currentValue - currentImpact;
        const newValue = value.value - currentValueWithoutImpact;
        updatedImpacts[monthKey] = { value: newValue, type: ValueType.Number };
      }
    });
    eventUpdateInput.customCurvePoints = convertTimeSeriesToGql(updatedImpacts);
  }

  if (isObjectFieldEvent(event) && isEventSettingStartDate(state, event)) {
    const newStartDate = Object.entries(values).find(([_mk, val]) => val?.value != null)?.[1];
    if (newStartDate != null && newStartDate.type === ValueType.Timestamp) {
      const newStartMonthKey = extractMonthKey(newStartDate.value);
      const updatedImpacts: ValueTimeSeriesWithEmpty = {
        [newStartMonthKey]: newStartDate,
      };
      eventUpdateInput.customCurvePoints = convertTimeSeriesToGql(updatedImpacts);
    }
  }

  return eventUpdateInput;
};

export const updateEventImpacts = (
  eventId: EventId,
  values: ValueTimeSeriesWithEmpty,
  valueType: 'impact' | 'impactDriver',
): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch(updateEvent(updateEventImpactsMutation(state, eventId, values, valueType)));
  };
};

function eventToEventCreateInput(event: Event): EventCreateInput | undefined {
  const newId = uuidv4();
  const { description, name, start, end, ownerName, parentId, sortIndex, hidden, impactType } =
    event;
  const common = {
    id: newId,
    description,
    hidden,
    name,
    start,
    end,
    parentId,
    ownerName,
    sortIndex,
    impactType,
  };

  let eventInput: EventCreateInput | undefined;
  if (isDriverEvent(event)) {
    eventInput = {
      ...common,
      driverId: event.driverId,
    };
    if (isDeltaImpactEvent(event)) {
      eventInput = {
        ...eventInput,
        modifierType: event.modifierType,
        customCurvePoints: convertTimeSeriesToGql(event.customCurvePoints),
        curveType: event.curveType,
        totalImpact: event.totalImpact != null ? String(event.totalImpact) : undefined,
      };
    }
  } else if (isObjectFieldEvent(event)) {
    eventInput = {
      ...common,
      businessObjectFieldId: event.businessObjectFieldId,
      customCurvePoints: convertTimeSeriesToGql(event.customCurvePoints),
      setValueType: event.valueType,
    };
  }

  return eventInput;
}

function eventGroupToEventGroupCreateInput(
  group: PopulatedEventGroup,
  idMapper: (oldId: string) => string,
  namesSet?: Set<string>,
): EventGroupCreateInput {
  const { description, defaultEnd, defaultStart, ownerName, ownerId, sortIndex, hidden } = group;
  const isDuplicatedGroup = namesSet != null;
  const name = isDuplicatedGroup ? getNameWithCopySuffix(group.name, [...namesSet]) : group.name;
  return {
    id: idMapper(group.id),
    description,
    name,
    hidden,
    ownerId,
    defaultEnd,
    defaultStart,
    eventIds: group.events.map((ev) => idMapper(ev.id)),
    eventGroupIds: group.eventGroups.map((g) => idMapper(g.id)),
    parentId: isDuplicatedGroup
      ? group.parentId
      : group.parentId != null
        ? idMapper(group.parentId)
        : undefined,
    ownerName,
    sortIndex,
  };
}

function getIdMapper() {
  const map: Record<string, string> = {};
  return (oldId: string) => {
    if (!(oldId in map)) {
      map[oldId] = uuidv4();
    }
    return map[oldId];
  };
}

export const duplicateGroup = (id: EventGroupId): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const blockId = prevailingSelectionBlockIdSelector(state);
    const eventGroupNamesSet = eventGroupNamesForCurrentLayerSetSelector(state);
    const eventGroupsById = populatedEventGroupsByIdForLayerSelector(state);
    const group = eventGroupsById[id];
    if (group == null) {
      return;
    }

    const idMapper = getIdMapper();
    const nestedEvents = getAllNestedEvents(group);
    const nestedEventGroups = getAllNestedEventGroups(group);

    const eventCreateInputs = nestedEvents
      .map((ev) => {
        const input = eventToEventCreateInput(ev);
        return input
          ? {
              ...input,
              id: idMapper(ev.id),
              parentId: ev.parentId != null ? idMapper(ev.parentId) : undefined,
            }
          : undefined;
      })
      .filter(isNotNull);
    const eventGroupCreateInputs = [
      eventGroupToEventGroupCreateInput(group, idMapper, eventGroupNamesSet),
      ...nestedEventGroups
        .filter(isNotNull)
        .map((g) => eventGroupToEventGroupCreateInput(g, idMapper)),
    ];

    const newState = peekMutationStateChange(state, {
      newEvents: eventCreateInputs,
      newEventGroups: eventGroupCreateInputs,
    });
    const sortIndexUpdates = sortIndexMutationsForInsertion(newState, idMapper(group.id), group.id);
    dispatch(
      bulkCreateEventsAndGroups({
        events: eventCreateInputs,
        groups: eventGroupCreateInputs,
        extras: sortIndexUpdates,
      }),
    );
    if (blockId != null) {
      dispatch(
        selectPlanTimelineItemRef({ type: 'group', id: eventGroupCreateInputs[0].id, blockId }),
      );
    }
  };
};

export const deleteEventImpacts = (
  deleteInputs: Array<{ eventId: EventId; monthKey: MonthKey }>,
): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    const updatedEvents: Record<EventId, Event> = {};
    deleteInputs.forEach(({ eventId, monthKey }) => {
      let eventCopy = updatedEvents[eventId];
      const event = eventCopy ?? eventSelector(state, eventId);

      if (event == null || event.customCurvePoints == null) {
        return;
      }

      eventCopy = eventCopy ?? deepClone<Event>(event);

      // This shouldn't happen but TS won't know because it's a copy
      if (eventCopy.customCurvePoints != null) {
        delete eventCopy.customCurvePoints[monthKey];
      }
      updatedEvents[eventId] = eventCopy;
    });

    const updates: Array<EventUpdateInput & { insertBeforeId?: string | undefined }> = [];
    const deletes: EventDeleteInput[] = [];
    Object.values(updatedEvents).forEach((eventCopy) => {
      if (
        eventCopy.customCurvePoints == null ||
        Object.keys(eventCopy.customCurvePoints).length === 0
      ) {
        deletes.push({ id: eventCopy.id });
        return;
      }
      const event = eventSelector(state, eventCopy.id);
      if (event == null) {
        return;
      }
      const updateOrDelete = getUpdateFromEvents(event, eventCopy);

      if (updateOrDelete?.update != null) {
        updates.push(updateOrDelete.update);
      }
    });

    if (deletes.length !== 0 || updates.length !== 0) {
      dispatch(
        mutationActions.deleteAndUpdateEventsAndGroups({
          deleteEvents: deletes,
          updateEvents: updates,
          deleteGroups: [],
          updateGroups: [],
        }),
      );
    }
  };
};

export const reparentSelectedEventAndEventGroups = (
  newParentGroupId: EventGroupId | undefined,
  insertBeforeId?: InsertBeforeId,
): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    // Trim to only ids that don't have a parent selected. If the parent is
    // moved, the child should follow and not break the hierarchy.
    const selectedEventIds = selectedEventIdsWithoutSelectedParentSelector(state);
    const selectedEventGroupIds = selectedEventGroupIdsWithoutSelectedParentSelector(state);

    const parentIdByChildId = parentIdsByEventAndGroupIdsSelector(state);

    const setHidden =
      newParentGroupId != null && eventGroupSelector(state, newParentGroupId)?.hidden === true;

    const updates: {
      events: Array<EventUpdateInput & { insertBeforeId?: InsertBeforeId }>;
      groups: Array<EventGroupUpdateInput & { insertBeforeId?: InsertBeforeId }>;
    } = { events: [], groups: [] };

    const groupFields = {
      parentId: newParentGroupId ?? NO_PARENT_ID,
      hidden: setHidden ? true : undefined,
    };

    selectedEventIds.forEach((eventId) => {
      updates.events.push({
        id: eventId,
        insertBeforeId,
        ...(newParentGroupId !== parentIdByChildId[eventId] ? groupFields : {}),
      });
    });
    selectedEventGroupIds.forEach((groupId) => {
      updates.groups.push({
        id: groupId,
        insertBeforeId,
        ...(newParentGroupId !== parentIdByChildId[groupId] ? groupFields : {}),
      });
    });
    dispatch(updateEventsAndGroups(updates));
  };
};

// If an event doesn't already exists, creates one
// Otherwise, it updates the existing one.
// If an undefined curvePoint is passed in, removes the existing curve point
type SetCurvePointOnEventEntityProps = {
  eventEntity: EventEntity;
  monthKey: MonthKey;
  curvePoint: CurvePointTyped | undefined;
  blockId: BlockId;
};
export function setCurvePointOnEventEntity({
  eventEntity,
  monthKey,
  curvePoint,
  withEventGroup,
  blockId,
}: SetCurvePointOnEventEntityProps & { withEventGroup?: WithEventGroup }): AppThunk {
  return (dispatch, getState) => {
    const state = getState();
    const eventsForEntity =
      eventEntity.type === 'driver'
        ? eventsForDriverSelector(state, { id: eventEntity.driverId })
        : eventsForObjectFieldSelector(state, { id: eventEntity.objectFieldId });
    const existingEvent = eventsForEntity.find(
      (event) => event.customCurvePoints?.[monthKey] != null,
    );

    if (curvePoint == null) {
      // Remove existing curve point, if exists
      if (existingEvent != null) {
        const deleteEventMutation: Pick<DatasetMutationInput, 'deleteEvents'> = {
          deleteEvents: [{ id: existingEvent.id }],
        };
        dispatch(submitAutoLayerizedMutations('delete-events', [deleteEventMutation]));
      } else if (withEventGroup != null) {
        // If there was no existing curve point but there is an eventGroupId,
        // create a new Set curve point with the existing value.
        let value: number | undefined;

        if (eventEntity.type === 'driver') {
          value = driverValueForMonthKeySelector(state, {
            driverId: eventEntity.driverId,
            monthKey,
          });
        } else {
          const { objectId, fieldSpecId } = eventEntity;
          const val = businessObjectFieldValueForMonthKeySelector(state, {
            businessObjectId: objectId,
            businessObjectFieldSpecId: fieldSpecId,
            monthKey,
            blockId,
          });
          if (val?.type !== ValueType.Number) {
            return;
          }
          value = val.value;
        }

        if (value == null) {
          return;
        }

        dispatch(
          createNewEventsWithEventGroup({
            newEvents: [
              {
                ...eventEntity,
                customCurvePoints: { [monthKey]: value },
                impactType: ImpactType.Set,
              },
            ],
            withEventGroup,
          }),
        );
      }
      return;
    }

    if (Number.isNaN(curvePoint.value)) {
      // ignore non-number curve points for now
      return;
    }

    if (existingEvent != null) {
      // The updatedCurvePoints include the existing curve points of the event
      // and the new curve point, which overrides the existing curve point on
      // same month key
      const updatedCurvePoints: NumericTimeSeries = {};
      Object.entries(existingEvent.customCurvePoints ?? {}).forEach(([month, val]) => {
        // This is primarily a type-guard
        if (val.type !== ValueType.Number) {
          return;
        }
        updatedCurvePoints[month] = val.value;
      });
      updatedCurvePoints[monthKey] = getNumber(`${curvePoint.value}`);

      const eventGroupId = eventGroupIdFromWithEventGroup(withEventGroup);

      // Only send an update mutation if something has changed
      const hasUpdatedCurvePoints =
        existingEvent.customCurvePoints?.[monthKey]?.value !== curvePoint.value;
      // All events must have an parentId/eventGroupId, so if eventGroupId is empty
      // we consider it as "not updated" and fallback to the existing event's parentId
      const hasUpdatedEventGroup = eventGroupId != null && existingEvent.parentId !== eventGroupId;
      const hasUpdatedImpactType = existingEvent.impactType !== curvePoint.impactType;
      if (!(hasUpdatedCurvePoints || hasUpdatedEventGroup || hasUpdatedImpactType)) {
        return;
      }

      const updateEventMutation: Pick<DatasetMutationInput, 'updateEvents'> = {
        updateEvents: [
          {
            id: existingEvent.id,
            customCurvePoints: convertNumericTimeSeriesToGql(updatedCurvePoints),
            impactType: curvePoint.impactType,
            parentId: eventGroupId ?? existingEvent.parentId,
          },
        ],
      };

      // Create new event group if necessary
      const authenticatedUser = authenticatedUserSelector(state);
      if (authenticatedUser == null) {
        return;
      }
      const newEventGroupMutation: DatasetMutationInput =
        withEventGroup?.type === 'new'
          ? {
              newEventGroups: [
                {
                  id: withEventGroup.newEventGroup.id,
                  name: withEventGroup.newEventGroup.name,
                  ownerName: authenticatedUser.name,
                  ownerId: authenticatedUser.id,
                  eventIds: [existingEvent.id],
                },
              ],
            }
          : {};

      dispatch(
        submitAutoLayerizedMutations('update-events', [updateEventMutation, newEventGroupMutation]),
      );
      return;
    }

    // create a new one
    dispatch(
      createNewEventsWithEventGroup({
        newEvents: [
          {
            ...eventEntity,
            customCurvePoints: { [monthKey]: getNumber(`${curvePoint.value}`) },
            impactType: curvePoint.impactType,
          },
        ],
        withEventGroup: withEventGroup ?? { type: 'default' },
      }),
    );
  };
}

export const {
  bulkCreateEventsAndGroups,
  deleteEventsAndGroups,
  updateEventsAndGroups,
  updateEventsAndGroupsVisibility,
} = mutationActions;
