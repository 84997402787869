import {
  EditableCallback,
  EditableCallbackParams,
  SuppressNavigableCallbackParams,
} from 'ag-grid-community';
import { Dictionary, fromPairs } from 'lodash';
import { DateTime } from 'luxon';

import CustomTooltip from 'components/AgGridComponents/Tooltip/Tooltip';
import {
  ACTUALS_FORMULA_KEY,
  ClassName,
  FORECAST_FORMULA_KEY,
  isAddItemRowId,
} from 'components/AgGridComponents/config/grid';
import {
  databaseRowToolTipValueGetter,
  timeSeriesRowToolTipValueGetter,
} from 'components/AgGridComponents/helpers/tooltip';
import {
  BackingType,
  ColumnDef,
  ColumnGroupDef,
  DatabaseObjectRow,
} from 'components/AgGridComponents/types/DatabaseColumnDef';
import { TimeseriesColumnDef } from 'components/AgGridComponents/types/TimeseriesColumnDef';
import { OBJECT_NAME_FIELD_NAME } from 'config/businessObjects';
import {
  ACTUALS_FORMULA_COLUMN_KEY,
  FORECAST_FORMULA_COLUMN_KEY,
  MonthColumnKey,
  OBJECT_FIELD_ADD_NEW_COLUMN_COLUMN_KEY,
  OBJECT_FIELD_NAME_COLUMN_KEY,
  OBJECT_FIELD_OPTIONS_COLUMN_KEY,
} from 'config/cells';
import {
  ACTUALS_FORMULA_COLUMN_TYPE,
  COLUMN_TYPE_TO_NAME,
  FORECAST_FORMULA_COLUMN_TYPE,
  INITIAL_VALUE_COLUMN_TYPE,
  NAME_COLUMN_TYPE,
  OPTIONS_COLUMN_TYPE,
  PROPERTY_COLUMN_TYPE,
} from 'config/modelView';
import { OBJECT_INITIAL_VALUE_COLUMN_KEY, OBJECT_PROPERTY_COLUMN_KEY } from 'config/objectGridView';
import { DATA_COLUMN_WIDTH, NAME_COLUMN_WIDTH } from 'config/pasteData';
import {
  BlockColumnOptions,
  BlockComparisonLayout,
  BlockComparisons,
  ComparisonColumn,
  DriverType,
  ValueType,
} from 'generated/graphql';
import { AccessCapabilitiesProvider } from 'helpers/accessCapabilities/AccessCapabilitiesProvider';
import { getMonthColumnKey } from 'helpers/cells';
import { isCypress } from 'helpers/environment';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import { AccessResource } from 'reduxStore/models/accessResources';
import { BlockId } from 'reduxStore/models/blocks';
import {
  BusinessObjectFieldSpec,
  BusinessObjectFieldSpecId,
  BusinessObjectSpec,
} from 'reduxStore/models/businessObjectSpecs';
import { DimensionalProperty, DriverProperty } from 'reduxStore/models/collections';
import { Dimension, DimensionId } from 'reduxStore/models/dimensions';
import { DimensionalDriver, Driver } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { DisplayConfiguration } from 'reduxStore/models/value';
import { isResourceRestrictedForUser } from 'selectors/restrictedResourcesSelector';
import { MonthKey } from 'types/datetime';

export type ColumnDefInputParams = {
  objectFieldSpecId: string;
  fieldSpecsByIds: Dictionary<BusinessObjectFieldSpec>;
  objectSpec: BusinessObjectSpec;
  visibleFieldSpecTimeSeries: string | null;
  editable: boolean;
  dimensionsById: Record<DimensionId, Dimension>;
  displayConfigurations: NullableRecord<string, DisplayConfiguration>;
  blockMonthKeys: MonthKey[];
  blockId: BlockId;
  groupByObjectFieldSpecId: string | undefined | null;
  dimensionalPropertiesById: Dictionary<DimensionalProperty>;
  driverPropertiesById: Dictionary<DriverProperty>;
  driversById: Record<string, Driver | undefined>;
  width?: number;
  lastActualsMonthKey: MonthKey;
  restrictedFieldIds: BusinessObjectFieldSpecId[];
  isNewDimensionalTable: boolean;
  displayAsTimeSeries: boolean;
  blockConfigColumns: BlockColumnOptions[];
  accessResourcesById: NullableRecord<string, AccessResource>;
  showRestrictedForBlock: boolean;
  accessCapabilitiesProvider: AccessCapabilitiesProvider;
  blockDateRangeDateTime: [DateTime, DateTime];
  comparisons: BlockComparisons | null | undefined;
};

function shouldSuppressNavigation({ node }: SuppressNavigableCallbackParams) {
  const row = node as unknown as DatabaseObjectRow;
  return row.data.name === 'addItem';
}

/**
 * AG grid complains if we don't pass in these functions for an "object" type, even though we don't need it in the
 * code so pass in dummy functions
 */
function getDummyValueFormatterAndParser(cellDataTypeIsObject: boolean) {
  const valueFormatter = cellDataTypeIsObject ? () => '' : undefined;
  const valueParser = cellDataTypeIsObject ? () => undefined : undefined;
  return { valueFormatter, valueParser };
}

export const COMPARISON_TYPES_FOR_DATABASE = [
  ComparisonColumn.Variance,
  ComparisonColumn.VariancePercentage,
];

export function isValidComparisonType(
  comparisonType: ComparisonColumn | undefined,
): comparisonType is ComparisonColumn.Variance | ComparisonColumn.VariancePercentage {
  return comparisonType != null && COMPARISON_TYPES_FOR_DATABASE.includes(comparisonType);
}

export function filterValidComparisonTypes(
  comparisonTypes: ComparisonColumn[] | null | undefined,
): ComparisonColumn[] {
  return (comparisonTypes ?? []).filter(isValidComparisonType);
}

type CreateColumnDefInput = ColumnDefInputParams & {
  monthKey?: string;
  layerId?: LayerId;
  comparisonType?: ComparisonColumn;
};
export const ADD_COLUMN_TYPE = 'addColumn';
export type AddColumnKey = { objectFieldSpecId: 'addColumn' };

const UNIVERSAL_SETTINGS = {
  lockVisible: true,
  lockPinned: true,
} as const;

export function appendSuffix(suffix: string | undefined, separator = ':') {
  return suffix != null ? `${separator}${suffix}` : '';
}

export function getDriverColId(
  params: Pick<
    CreateColumnDefInput,
    'objectFieldSpecId' | 'monthKey' | 'layerId' | 'comparisonType'
  >,
) {
  const { objectFieldSpecId, monthKey, layerId, comparisonType } = params;
  return `${objectFieldSpecId}${monthKey != null ? `:${monthKey}` : ''}${appendSuffix(layerId)}${appendSuffix(comparisonType)}`;
}

export function getDriverFormulaColId(
  params: Pick<CreateColumnDefInput, 'objectFieldSpecId' | 'layerId' | 'comparisonType'> & {
    formulaType: 'forecast' | 'actuals';
  },
) {
  const { objectFieldSpecId, formulaType, layerId, comparisonType } = params;
  return `${objectFieldSpecId}:${formulaType === 'forecast' ? FORECAST_FORMULA_KEY : ACTUALS_FORMULA_KEY}${appendSuffix(layerId)}${appendSuffix(comparisonType)}`;
}

function createTimeSeriesColumnDefs({
  backingType,
  ...params
}: { backingType: BackingType } & CreateColumnDefInput): ColumnGroupDef | undefined {
  const {
    objectFieldSpecId,
    fieldSpecsByIds,
    driverPropertiesById,
    driversById,
    blockMonthKeys,
    accessResourcesById,
    showRestrictedForBlock,
    accessCapabilitiesProvider,
    comparisons,
  } = params;
  const dimensionalDriverId = driverPropertiesById[objectFieldSpecId]?.driverId;
  const dimensionalDriver = dimensionalDriverId == null ? null : driversById[dimensionalDriverId];
  const headerName = dimensionalDriver?.name ?? fieldSpecsByIds[objectFieldSpecId]?.name;
  if (headerName == null) {
    return undefined;
  }

  const isRestricted = isResourceRestrictedForUser(
    accessCapabilitiesProvider,
    accessResourcesById,
    objectFieldSpecId,
    showRestrictedForBlock,
  );

  const addCommonTimeseriesFields = (colDef: ColumnDef | undefined): ColumnDef | undefined => {
    return colDef == null
      ? undefined
      : {
          ...colDef,
          suppressMovable: true,
          suppressNavigable: shouldSuppressNavigation,
          lockPosition: 'right',
          resizable: colDef.fieldSpec.valueType === 'formula',
          width: colDef.fieldSpec.valueType === 'formula' ? colDef.width : DATA_COLUMN_WIDTH,
          fieldSpec: {
            ...colDef.fieldSpec,
            cellAlignment: colDef.fieldSpec.valueType === 'formula' ? 'left' : 'right',
            afterLastActuals:
              colDef.fieldSpec.monthKey != null
                ? colDef.fieldSpec.monthKey > params.lastActualsMonthKey
                : undefined,
          },
          cellClassRules: {
            [ClassName.LastCloseCell]: () =>
              colDef.fieldSpec.monthKey === params.lastActualsMonthKey,
            [ClassName.TimeseriesCellHardCodedActual]: ({ data }) =>
              colDef.fieldSpec.monthKey != null &&
              data != null &&
              data.hardCodedActuals.has(colDef.fieldSpec.monthKey),
            [ClassName.TimeseriesCell]: () =>
              !colDef.colId.includes(ACTUALS_FORMULA_KEY) &&
              !colDef.colId.includes(FORECAST_FORMULA_KEY),
          },
          // Used for Cypress selectors.
          headerClass:
            isCypress() &&
            !colDef.colId.includes(ACTUALS_FORMULA_KEY) &&
            !colDef.colId.includes(FORECAST_FORMULA_KEY)
              ? ClassName.TimeseriesHeaderCell
              : undefined,
        };
  };

  const timeseriesColumns: Array<ColumnDef | ColumnGroupDef | undefined> = blockMonthKeys.map(
    (monthKey) => {
      switch (backingType) {
        case 'objectField':
          return addCommonTimeseriesFields(createObjectFieldColumnDef({ ...params, monthKey }));
        case 'driver': {
          return createDriverColumnDefWithComparisons({
            comparisons,
            colId: getDriverColId({ ...params, monthKey }),
            headerName: monthKey,
            createChild: ({ layerId, comparisonType }) =>
              addCommonTimeseriesFields(
                createDriverColumnDef({ ...params, monthKey, layerId, comparisonType }),
              ),
          });
        }
        default:
          throw new Error(`Unsupported backing type "${backingType}"`);
      }
    },
  );

  const formulaColumns: Array<ColumnDef | ColumnGroupDef | undefined> = [];
  if (dimensionalDriver?.id != null && dimensionalDriver.type === DriverType.Dimensional) {
    formulaColumns.push(
      createDriverColumnDefWithComparisons({
        headerName: 'Actuals Formula',
        comparisons,
        colId: getDriverFormulaColId({ ...params, formulaType: 'actuals' }),
        supportsRowView: false,
        createChild: ({ layerId, comparisonType }) =>
          addCommonTimeseriesFields(
            createDriverActualsFormulaColumnDef({
              ...params,
              backingType,
              dimensionalDriver,
              isRestricted,
              layerId,
              comparisonType,
            }),
          ),
      }),
    );
    formulaColumns.push(
      createDriverColumnDefWithComparisons({
        headerName: 'Forecast Formula',
        comparisons,
        colId: getDriverFormulaColId({ ...params, formulaType: 'forecast' }),
        supportsRowView: false,
        createChild: ({ layerId, comparisonType }) =>
          addCommonTimeseriesFields(
            createDriverForecastFormulaColumnDef({
              ...params,
              backingType,
              dimensionalDriver,
              isRestricted,
              layerId,
              comparisonType,
            }),
          ),
      }),
    );
  }

  return {
    colId: objectFieldSpecId,
    headerGroupType: 'timeseries',
    headerName,
    marryChildren: true,
    children: [...formulaColumns.filter(isNotNull), ...timeseriesColumns.filter(isNotNull)],
  };
}

function createObjectFieldColumnDef({ monthKey, ...params }: CreateColumnDefInput) {
  const {
    objectSpec,
    objectFieldSpecId,
    fieldSpecsByIds,
    displayAsTimeSeries,
    dimensionsById,
    blockDateRangeDateTime,
    width,
  } = params;
  const canBeTimeSeries = objectSpec.startFieldId !== objectFieldSpecId;
  const fieldSpec = fieldSpecsByIds[objectFieldSpecId];
  if (fieldSpec == null) {
    return undefined;
  }
  const cellDataTypeIsObject = monthKey == null;
  const isRestricted = params.restrictedFieldIds.includes(objectFieldSpecId);
  const editable = isRestricted ? false : params.editable;

  const dimensionName = getDimensionName(fieldSpec.dimensionId, dimensionsById);

  return withBaseColumnDefs(params, {
    // Do not use `.` as a delimiter because AG Grid will interpret that as a nested object lookup.
    colId: monthKey != null ? `${objectFieldSpecId}:${monthKey}` : objectFieldSpecId,
    field: monthKey != null ? `data.${objectFieldSpecId}:${monthKey}` : `data.${objectFieldSpecId}`,
    headerName: monthKey != null ? monthKey : fieldSpec.name,
    cellDataType: cellDataTypeIsObject
      ? 'object'
      : fieldSpec.type === ValueType.Number
        ? 'number'
        : 'text',
    fieldSpec: {
      dimensionId: fieldSpec.dimensionId,
      dimensionName,
      valueType: fieldSpec.type,
      backingType: 'objectField',
      isDatabaseKey: false,
      monthKey,
      isRestricted,
      isIntegration: fieldSpec.extFieldSpecKey != null,
      blockDateRangeDateTime,
    },
    columnKey: { objectFieldSpecId, monthKey },
    editable,
    hide: (canBeTimeSeries && displayAsTimeSeries) || undefined,
    blockId: params.blockId,
    cellClass: monthKey == null ? ClassName.CumulativeCell : undefined,
    width: width ?? DATA_COLUMN_WIDTH,
    ...getDummyValueFormatterAndParser(cellDataTypeIsObject),
  });
}

function getDimensionName(
  dimensionId: string | undefined | null,
  dimensionsById: Record<string, Dimension>,
) {
  let dimensionName =
    dimensionId != null ? safeObjGet(dimensionsById[dimensionId])?.name : undefined;
  if (dimensionName == null) {
    dimensionName = 'Dimension';
  }
  return dimensionName;
}

function createDimensionColumnDef({
  monthKey,
  ...params
}: CreateColumnDefInput): ColumnDef | undefined {
  const {
    objectFieldSpecId,
    dimensionalPropertiesById,
    accessResourcesById,
    showRestrictedForBlock,
    accessCapabilitiesProvider,
    blockDateRangeDateTime,
  } = params;
  const property = dimensionalPropertiesById[objectFieldSpecId];
  if (property == null) {
    return undefined;
  }

  const isDatabaseKey = Boolean(property.isDatabaseKey);
  const isRestricted = isResourceRestrictedForUser(
    accessCapabilitiesProvider,
    accessResourcesById,
    objectFieldSpecId,
    showRestrictedForBlock,
  );
  const editable = isRestricted ? false : params.editable;

  return withBaseColumnDefs(params, {
    colId: objectFieldSpecId,
    field: `data.${objectFieldSpecId}`,
    headerName: property.name,
    pinned: isDatabaseKey ? 'left' : null,
    editable,
    fieldSpec: {
      dimensionId: property.dimension.id,
      dimensionName: property.dimension.name,
      dimensionColor: property.dimension.color,
      valueType: ValueType.Attribute,
      backingType: 'dimension',
      isDatabaseKey,
      isMapped: property.mapping != null,
      isIntegration: property.extFieldSpecKey != null,
      monthKey,
      canShowAsTimeSeries: false,
      displayAs: 'value',
      isRestricted: isResourceRestrictedForUser(
        accessCapabilitiesProvider,
        accessResourcesById,
        objectFieldSpecId,
        showRestrictedForBlock,
      ),
      blockDateRangeDateTime,
    },
    columnKey: {
      objectFieldSpecId,
      monthKey,
    },
    blockId: params.blockId,
    cellClass: isDatabaseKey ? ClassName.SegmentByCell : undefined,
  });
}

function getFormulaColumnDefFieldSpec(
  params: CreateColumnDefInput & {
    backingType: BackingType;
    dimensionalDriver: DimensionalDriver;
    isRestricted: boolean;
  },
): ColumnDef['fieldSpec'] {
  const {
    objectFieldSpecId,
    objectSpec,
    dimensionsById,
    blockDateRangeDateTime,
    dimensionalDriver,
    backingType,
    isRestricted,
    layerId,
  } = params;

  const colDefFieldSpec: ColumnDef['fieldSpec'] = {
    objectFieldSpecId,
    valueType: 'formula',
    backingType,
    isDatabaseKey: false,
    canShowAsTimeSeries: false,
    displayAs: 'value',
    objectSpecId: objectSpec.id,
    dimensionalDriverId: dimensionalDriver.id,
    dimensionId: dimensionalDriver?.dimensionId ?? undefined,
    dimensionName: getDimensionName(dimensionalDriver?.dimensionId, dimensionsById),
    isRestricted,
    blockDateRangeDateTime,
    layerId,
  };

  return colDefFieldSpec;
}

function createDriverForecastFormulaColumnDef(
  params: CreateColumnDefInput & {
    backingType: BackingType;
    dimensionalDriver: DimensionalDriver;
    isRestricted: boolean;
  },
): ColumnDef {
  const { displayAsTimeSeries, blockId, blockConfigColumns, dimensionalDriver, isRestricted } =
    params;

  const driverValueType = dimensionalDriver.valueType ?? ValueType.Number;
  const isFormula = driverValueType === ValueType.Number;
  const colDefFieldSpec: ColumnDef['fieldSpec'] = getFormulaColumnDefFieldSpec({
    ...params,
    isRestricted,
  });

  const forecastFormulaColKey = getDriverFormulaColId({
    ...params,
    formulaType: 'forecast',
  });
  const forecastFormulaFormulaWidth =
    blockConfigColumns.find((col) => col.key === forecastFormulaColKey)?.width ?? NAME_COLUMN_WIDTH;
  const forecastFormulaColDef: ColumnDef = {
    colId: forecastFormulaColKey,
    field: `data.${forecastFormulaColKey}`,
    headerName: 'Forecast Formula',
    // Make always editable; the formula editor popover checks for editability.
    // TODO(van): we are temporarily disabling editing formulas for nonnumeric drivers
    editable: isFormula,
    fieldSpec: colDefFieldSpec,
    cellDataType: 'object',
    columnKey: FORECAST_FORMULA_COLUMN_KEY,
    width: forecastFormulaFormulaWidth,
    hide: displayAsTimeSeries,
    blockId,
    ...getDummyValueFormatterAndParser(true),
  };

  return forecastFormulaColDef;
}

function createDriverActualsFormulaColumnDef(
  params: CreateColumnDefInput & {
    backingType: BackingType;
    dimensionalDriver: DimensionalDriver;
    isRestricted: boolean;
  },
): ColumnDef {
  const { displayAsTimeSeries, blockId, blockConfigColumns, dimensionalDriver, isRestricted } =
    params;

  const driverValueType = dimensionalDriver.valueType ?? ValueType.Number;
  const isFormula = driverValueType === ValueType.Number;
  const colDefFieldSpec: ColumnDef['fieldSpec'] = getFormulaColumnDefFieldSpec({
    ...params,
    isRestricted,
  });
  const actualsFormulaColKey = getDriverFormulaColId({
    ...params,
    formulaType: 'actuals',
  });
  const actualsFormulaWidth =
    blockConfigColumns.find((col) => col.key === actualsFormulaColKey)?.width ?? NAME_COLUMN_WIDTH;
  const actualsFormulaColDef: ColumnDef = {
    colId: actualsFormulaColKey,
    field: `data.${actualsFormulaColKey}`,
    headerName: 'Actuals Formula',
    // Make always editable; the formula editor popover checks for editability.
    editable: true,
    fieldSpec: colDefFieldSpec,
    // TODO(van): we are temporarily disabling editing formulas for nonnumeric drivers, but
    // supporting the actuals formula with a singular value (attribute or date)
    cellDataType: isFormula ? 'object' : 'string',
    columnKey: ACTUALS_FORMULA_COLUMN_KEY,
    width: actualsFormulaWidth,
    hide: displayAsTimeSeries,
    blockId,
    ...getDummyValueFormatterAndParser(isFormula),
  };

  return withAttributeRefData(params, actualsFormulaColDef);
}

function createDriverColumnDefWithComparisons({
  comparisons,
  colId,
  headerName,
  supportsRowView = true,
  createChild,
}: {
  comparisons: BlockComparisons | null | undefined;
  colId: string;
  headerName: string;
  createChild: (props: {
    layerId?: LayerId;
    comparisonType?: ComparisonColumn;
  }) => ColumnDef | undefined;
  supportsRowView?: boolean;
}): ColumnGroupDef | ColumnDef | undefined {
  if (comparisons == null || comparisons.layerIds.length === 0) {
    return createChild({});
  }
  const comparisonColumns: Array<{ layerId?: LayerId; comparisonType?: ComparisonColumn }> =
    // Column layer is default
    comparisons.layout == null || comparisons.layout === BlockComparisonLayout.Columns
      ? [undefined, ...comparisons.layerIds].map((layerId) => ({ layerId }))
      : supportsRowView
        ? [
            {},
            ...filterValidComparisonTypes(comparisons.columns).map((comparisonType) => ({
              comparisonType,
            })),
          ]
        : [{}];
  return {
    colId,
    headerGroupType: 'comparison',
    headerName,
    marryChildren: true,
    children: comparisonColumns.map(createChild).filter(isNotNull),
  };
}

function createDriverColumnDef({
  monthKey,
  layerId,
  comparisonType,
  ...params
}: CreateColumnDefInput): ColumnDef | undefined {
  const {
    objectFieldSpecId,
    driverPropertiesById,
    displayAsTimeSeries,
    driversById,
    accessResourcesById,
    showRestrictedForBlock,
    accessCapabilitiesProvider,
    dimensionsById,
    blockDateRangeDateTime,
  } = params;

  const dimensionalDriverId = driverPropertiesById[objectFieldSpecId]?.driverId;
  const dimensionalDriver = dimensionalDriverId == null ? null : driversById[dimensionalDriverId];

  if (dimensionalDriver == null) {
    return undefined;
  }

  const cellDataTypeIsObject = monthKey == null;
  const isRestricted = isResourceRestrictedForUser(
    accessCapabilitiesProvider,
    accessResourcesById,
    objectFieldSpecId,
    showRestrictedForBlock,
  );
  const editable = isRestricted ? false : params.editable;

  const colId = getDriverColId({ ...params, layerId, comparisonType, monthKey });

  return withBaseColumnDefs(params, {
    colId,
    field: `data.${colId}`,
    headerName: dimensionalDriver.name,
    cellDataType: cellDataTypeIsObject
      ? 'object'
      : dimensionalDriver.valueType === ValueType.Number
        ? 'number'
        : 'string',
    fieldSpec: {
      valueType: dimensionalDriver.valueType ?? ValueType.Number,
      dimensionId: dimensionalDriver?.dimensionId ?? undefined,
      dimensionName: getDimensionName(dimensionalDriver?.dimensionId, dimensionsById),
      backingType: 'driver',
      isDatabaseKey: false,
      monthKey,
      layerId,
      comparisonType,
      afterLastActuals: monthKey != null ? monthKey > params.lastActualsMonthKey : undefined,
      isRestricted,
      blockDateRangeDateTime,
    },
    columnKey: monthKey == null ? { objectFieldSpecId } : getMonthColumnKey(monthKey),
    editable,
    hide: displayAsTimeSeries || undefined,
    blockId: params.blockId,
    cellClass: monthKey == null ? ClassName.CumulativeCell : undefined,
    ...getDummyValueFormatterAndParser(cellDataTypeIsObject),
  });
}

export function createOptionsColumnDef<TType extends 'database' | 'timeseries'>(
  type: TType,
  {
    width,
    objectSpec,
    isNewDimensionalTable,
  }: Pick<ColumnDef, 'width'> &
    Partial<Pick<ColumnDefInputParams, 'objectSpec' | 'isNewDimensionalTable'>>,
): TType extends 'database' ? ColumnDef : TimeseriesColumnDef {
  return (
    type === 'database'
      ? {
          colId: OPTIONS_COLUMN_TYPE,
          headerName: 'Options',
          lockPosition: 'left',
          pinned: 'left',
          editable: false,
          width,
          resizable: false,
          suppressNavigable: true,
          cellClass: [ClassName.UnclickableCell, ClassName.OptionsCell],
          headerClass: ClassName.OptionsCell,
          fieldSpec: {
            objectFieldSpecId: OPTIONS_COLUMN_TYPE,
            valueType: OPTIONS_COLUMN_TYPE,
            isDatabaseKey: false,
            canShowAsTimeSeries: false,
            objectSpecId: objectSpec?.id,
            isRestricted: false,
            isNewDimensionalTable,
          },
          columnKey: OBJECT_FIELD_OPTIONS_COLUMN_KEY,
          ...UNIVERSAL_SETTINGS,
        }
      : {
          colId: OPTIONS_COLUMN_TYPE,
          headerName: 'Options',
          lockPosition: 'left',
          pinned: 'left',
          editable: false,
          width,
          resizable: false,
          suppressNavigable: true,
          cellClass: [ClassName.UnclickableCell, ClassName.OptionsCell],
          headerClass: ClassName.OptionsCell,
          columnKey: OBJECT_FIELD_OPTIONS_COLUMN_KEY,
          meta: {
            afterLastActuals: false,
          },
          ...UNIVERSAL_SETTINGS,
        }
  ) as TType extends 'database' ? ColumnDef : TimeseriesColumnDef;
}

export function createNameColumnDef(
  params: Omit<ColumnDefInputParams, 'objectFieldSpecId'> & { hasSegmentBy: boolean },
): ColumnDef {
  return {
    colId: NAME_COLUMN_TYPE,
    field: NAME_COLUMN_TYPE,
    headerName: OBJECT_NAME_FIELD_NAME,
    lockPosition: 'left',
    pinned: 'left',
    editable: params.editable && isObjectEditable,
    width: params.width,
    cellClass: [ClassName.NameCell, ...(params.hasSegmentBy ? [ClassName.SegmentByCell] : [])],
    suppressNavigable: shouldSuppressNavigation,
    fieldSpec: {
      objectFieldSpecId: NAME_COLUMN_TYPE,
      valueType: NAME_COLUMN_TYPE,
      backingType: 'objectField',
      // TODO: this isn't true for dimensional drivers
      isDatabaseKey: true,
      canShowAsTimeSeries: false,
      displayAs: 'value',
      objectSpecId: params.objectSpec.id,
      isRestricted: params.restrictedFieldIds.includes(NAME_COLUMN_TYPE),
      blockDateRangeDateTime: params.blockDateRangeDateTime,
    },
    columnKey: OBJECT_FIELD_NAME_COLUMN_KEY,
    blockId: params.blockId,
    ...UNIVERSAL_SETTINGS,
  };
}

export function createPropertyColumnDef({
  width,
}: {
  width: number | null | undefined;
}): TimeseriesColumnDef {
  return {
    colId: PROPERTY_COLUMN_TYPE,
    field: `data.${PROPERTY_COLUMN_TYPE}`,
    headerName: COLUMN_TYPE_TO_NAME[PROPERTY_COLUMN_TYPE],
    lockPosition: 'left',
    pinned: 'left',
    editable: false,
    resizable: true,
    suppressNavigable: true,
    cellClass: [ClassName.NameCell],
    width: width ?? DATA_COLUMN_WIDTH,
    columnKey: OBJECT_PROPERTY_COLUMN_KEY,
    meta: {
      afterLastActuals: false,
      isInitialValue: false,
    },
    ...UNIVERSAL_SETTINGS,
  };
}

export function createInitialValueColumnDef({
  editable,
  width,
  visible,
}: {
  editable: boolean;
  width: number | null | undefined;
  visible: boolean | null | undefined;
}): TimeseriesColumnDef {
  return {
    colId: INITIAL_VALUE_COLUMN_TYPE,
    field: `data.${INITIAL_VALUE_COLUMN_TYPE}`,
    // Since the data types of each column are not uniform,
    // treat the column as an opaque object to prevent AG Grid from throwing on edit.
    cellDataType: 'object',
    headerName: COLUMN_TYPE_TO_NAME[INITIAL_VALUE_COLUMN_TYPE],
    lockPosition: 'left',
    pinned: 'left',
    hide: !visible,
    editable: (params) => {
      // First check if the column is directly editable.
      if (!editable) {
        return false;
      }
      // Fallback to use the TimeseriesRow's editability.
      return (
        params.data?.meta.isEditable === true &&
        params.data?.meta.isStartDateField === false &&
        params.data?.meta.isDimensionalProperty === false &&
        params.data?.driverId == null
      );
    },
    resizable: true,
    cellClass: ClassName.NameCell,
    cellClassRules: {
      [ClassName.UnclickableCell]: (params) =>
        Boolean(
          params.data?.meta.isStartDateField ||
            params.data?.meta.isDimensionalProperty ||
            params.data?.driverId != null ||
            params.data?.driverPropertyId != null,
        ),
    },
    width: width ?? DATA_COLUMN_WIDTH,
    columnKey: OBJECT_INITIAL_VALUE_COLUMN_KEY,
    meta: {
      afterLastActuals: false,
      isInitialValue: true,
    },
    ...UNIVERSAL_SETTINGS,
    ...getDummyValueFormatterAndParser(true),
  };
}

export function createFormulaColumnDef({
  editable,
  width,
  type,
}: {
  editable: boolean;
  width: number | null | undefined;
  type: 'actuals' | 'forecast';
}): TimeseriesColumnDef {
  const columnType =
    type === 'actuals' ? ACTUALS_FORMULA_COLUMN_TYPE : FORECAST_FORMULA_COLUMN_TYPE;
  const columnKey = type === 'actuals' ? ACTUALS_FORMULA_COLUMN_KEY : FORECAST_FORMULA_COLUMN_KEY;

  return {
    colId: columnType,
    field: columnType,
    columnKey,
    cellDataType: 'object',
    headerName: COLUMN_TYPE_TO_NAME[columnType],
    lockPosition: 'left',
    pinned: 'left',
    editable: (params) => {
      // First check if the column is directly editable.
      if (!editable) {
        return false;
      }
      // Fallback to use the TimeseriesRow's editability.
      return params.data?.meta.isEditable ?? true;
    },
    width: width ?? DATA_COLUMN_WIDTH,
    resizable: true,
    meta: {
      afterLastActuals: false,
      isInitialValue: false,
    },
    cellClassRules: {
      [ClassName.UnclickableCell]: (params) =>
        Boolean(
          params.data?.meta.isStartDateField ||
            params.data?.meta.isDimensionalProperty ||
            params.data?.fieldId != null,
        ),
    },
    ...UNIVERSAL_SETTINGS,
    ...getDummyValueFormatterAndParser(true),
  };
}

export function createObjectIdColumnDef(): TimeseriesColumnDef {
  return {
    colId: 'objectId',
    field: 'objectId',
    // TODO: tricky
    editable: true,
    // This informs AG Grid that we're group by this field; despite it being invisible.
    rowGroup: true,
    rowGroupIndex: 1,
    hide: true,
    columnKey: null,
    meta: {
      afterLastActuals: false,
      isInitialValue: false,
    },
  };
}

export function createObjectGroupByColumnDef(): TimeseriesColumnDef {
  return {
    colId: 'groupByAttributeId',
    field: 'groupByAttributeId',
    editable: false,
    // This informs AG Grid that we're group by this field; despite it being invisible.
    rowGroup: true,
    rowGroupIndex: 0,
    hide: true,
    columnKey: null,
    meta: {
      afterLastActuals: false,
      isInitialValue: false,
    },
  };
}

export function createTimeseriesMonthKeyColumnDef({
  monthColumnKey,
  lastActualsMonthKey,
  editable,
  dateRangeSpan,
}: {
  monthColumnKey: MonthColumnKey;
  lastActualsMonthKey: MonthKey;
  editable: boolean;
  dateRangeSpan: number;
}): TimeseriesColumnDef {
  const { monthKey } = monthColumnKey;

  return {
    colId: monthKey,
    field: `data.${monthKey}`,
    // Since the data types of each column are not uniform,
    // treat the column as an opaque object to prevent AG Grid from throwing on edit.
    cellDataType: 'object',
    colSpan: (params) =>
      params.data?.meta.isStartDateField || params.data?.meta.isDimensionalProperty
        ? dateRangeSpan
        : 1,
    headerName: monthKey,
    editable: (params) => {
      // First check if the column is directly editable.
      if (!editable) {
        return false;
      }
      // Fallback to use the TimeseriesRow's editability.
      return params.data?.meta.isEditable ?? true;
    },
    cellClass: ClassName.NameCell,
    lockPosition: 'right',
    suppressMovable: true,
    resizable: false,
    width: DATA_COLUMN_WIDTH,
    columnKey: monthColumnKey,
    meta: {
      afterLastActuals: monthKey > lastActualsMonthKey,
      monthKey,
      isInitialValue: false,
    },
    ...UNIVERSAL_SETTINGS,
    ...getDummyValueFormatterAndParser(true),
    tooltipComponent: CustomTooltip,
    tooltipValueGetter: timeSeriesRowToolTipValueGetter,
  };
}

const ADD_COLUMN_WIDTH = 36;
function createAddPropertyColumnDef(
  params: Omit<ColumnDefInputParams, 'objectFieldSpecId'>,
): ColumnDef {
  return {
    colId: ADD_COLUMN_TYPE,
    headerName: 'Add Column',
    resizable: false,
    width: ADD_COLUMN_WIDTH,
    lockPosition: 'right',
    suppressNavigable: true,
    cellClass: [ClassName.UnclickableCell, ClassName.AddCell],
    headerClass: ClassName.AddCell,
    fieldSpec: {
      valueType: 'addColumn' as const,
      objectFieldSpecId: ADD_COLUMN_TYPE,
      objectSpecId: params.objectSpec.id,
      isDatabaseKey: false,
      canShowAsTimeSeries: false,
      blockDateRangeDateTime: params.blockDateRangeDateTime,
    },
    columnKey: OBJECT_FIELD_ADD_NEW_COLUMN_COLUMN_KEY,
    blockId: params.blockId,
    ...UNIVERSAL_SETTINGS,
  };
}

const isObjectEditable: EditableCallback<DatabaseObjectRow, any> = (
  params: EditableCallbackParams<DatabaseObjectRow>,
) => {
  const rowId = params.data?.id ?? '';
  const colDef = params.colDef as ColumnDef;

  return !isAddItemRowId(rowId) && !colDef.fieldSpec.isMapped;
};

function withBaseColumnDefs(
  params: ColumnDefInputParams,
  columnDef: Omit<ColumnDef, 'fieldSpec' | 'rowGroup'> & {
    fieldSpec: Omit<
      ColumnDef['fieldSpec'],
      | 'displayAs'
      | 'canShowAsTimeSeries'
      | 'displayConfiguration'
      | 'objectFieldSpecId'
      | 'objectSpecId'
    > &
      Partial<Pick<ColumnDef['fieldSpec'], 'displayAs' | 'canShowAsTimeSeries'>>;
  },
): ColumnDef {
  const {
    objectFieldSpecId,
    visibleFieldSpecTimeSeries,
    objectSpec,
    groupByObjectFieldSpecId,
    displayConfigurations,
    fieldSpecsByIds,
    width,
  } = params;

  const displayAs = visibleFieldSpecTimeSeries === objectFieldSpecId ? 'timeseries' : 'value';
  const canShowAsTimeSeries = objectSpec.startFieldId !== objectFieldSpecId;
  const isGrouped = groupByObjectFieldSpecId === objectFieldSpecId;
  const fieldSpec = fieldSpecsByIds[objectFieldSpecId];

  return withAttributeRefData(params, {
    editable: params.editable ? isObjectEditable : false,
    rowGroup: isGrouped,
    width,
    ...columnDef,
    hide: columnDef.hide ?? (!columnDef.fieldSpec.isDatabaseKey && isGrouped),
    suppressNavigable: shouldSuppressNavigation,
    fieldSpec: {
      displayAs,
      canShowAsTimeSeries,
      displayConfiguration: displayConfigurations[objectFieldSpecId],
      objectFieldSpecId,
      objectSpecId: objectSpec.id,
      isRestricted: false,
      isIntegration: false,
      isMapped: false,
      isFormula: fieldSpec?.isFormula ?? false,
      ...columnDef.fieldSpec,
    },
    ...UNIVERSAL_SETTINGS,
    tooltipComponent: CustomTooltip,
    tooltipValueGetter: databaseRowToolTipValueGetter,
  });
}

function withAttributeRefData(params: ColumnDefInputParams, columnDef: ColumnDef) {
  // add dimensional ref data if a dimension is included
  const attributeRefData =
    columnDef.fieldSpec.dimensionId != null
      ? fromPairs(
          (params.dimensionsById[columnDef.fieldSpec.dimensionId]?.attributes ?? []).map((attr) => [
            attr.id,
            { name: attr.value as string, deleted: attr.deleted },
          ]),
        )
      : undefined;

  return attributeRefData != null ? { ...columnDef, attributeRefData } : columnDef;
}

export default function deriveColumnDef(params: ColumnDefInputParams) {
  const {
    objectSpec,
    objectFieldSpecId,
    fieldSpecsByIds,
    visibleFieldSpecTimeSeries,
    dimensionalPropertiesById,
    driverPropertiesById,
    displayAsTimeSeries,
  } = params;
  const canShowAsTimeSeries = objectSpec.startFieldId !== objectFieldSpecId;
  const displayAs =
    canShowAsTimeSeries && (displayAsTimeSeries || visibleFieldSpecTimeSeries === objectFieldSpecId)
      ? 'timeseries'
      : 'value';

  if (objectFieldSpecId === ADD_COLUMN_TYPE) {
    return createAddPropertyColumnDef(params);
  }

  if (objectFieldSpecId in driverPropertiesById) {
    if (displayAs === 'timeseries') {
      return createTimeSeriesColumnDefs({ ...params, backingType: 'driver' });
    }

    return createDriverColumnDef(params);
  }

  if (objectFieldSpecId in fieldSpecsByIds) {
    if (displayAs === 'timeseries') {
      return createTimeSeriesColumnDefs({ ...params, backingType: 'objectField' });
    }

    return createObjectFieldColumnDef(params);
  }

  if (objectFieldSpecId in dimensionalPropertiesById) {
    return createDimensionColumnDef(params);
  }

  return null;
}

export function isAddColumn(column: ColumnDef | TimeseriesColumnDef | undefined) {
  return column?.colId === ADD_COLUMN_TYPE;
}

export function isOptionsColumn(column: ColumnDef | TimeseriesColumnDef | undefined) {
  return column?.colId === OPTIONS_COLUMN_TYPE;
}

export function isNameColumn(column: ColumnDef | undefined) {
  return column?.colId === NAME_COLUMN_TYPE;
}

export function isFormulaColumn(column: ColumnDef | undefined) {
  return column?.fieldSpec.valueType === 'formula';
}

export function isDimensionalPropertyColumn(column: ColumnDef | undefined) {
  return column?.fieldSpec.backingType === 'dimension';
}
