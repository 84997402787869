import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { WithEventGroup } from '@features/Plans';
import { EventEntity } from '@features/Plans/EventEntity';
import { EVENT_GROUP_TAG_BUTTON_ID } from 'components/CellPalette/EventGroupTag';
import { EditorRef, OnChangeFormulaArgs } from 'components/FormulaInput/FormulaInput';
import { getActiveCellFormulaDisplay } from 'components/NumericActiveCell/getActiveCellFormulaDisplay';
import { SEARCH_INPUT_ID } from 'components/SearchInput/SearchInput';
import { ImpactType } from 'generated/graphql';
import { FormulaDisplay } from 'helpers/formulaEvaluation/ForecastCalculator/FormulaDisplayListener';
import { parseImpact, parseNumber } from 'helpers/formulaEvaluation/ImpactParser/ImpactParser';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { setCurvePointOnEventEntity } from 'reduxStore/actions/eventMutations';
import { CurvePointTyped, EventGroupId } from 'reduxStore/models/events';
import { DisplayConfiguration } from 'reduxStore/models/value';
import {
  isPlanPickerOpenSelector,
  planPickerSelectedWithEventGroupSelector,
} from 'selectors/cellPaletteSelector';
import { selectedInitiativeSelector } from 'selectors/planTimelineSelector';
import { MonthKey } from 'types/datetime';

type UseEnhancedCellEditingProps = {
  eventEntity: EventEntity | null | undefined;
  monthKey: MonthKey | undefined;

  value: number | null | undefined;
  curvePoint: CurvePointTyped | undefined;
  displayConfiguration: DisplayConfiguration;

  // Existing event group, currently only relevant for the impact inspector case
  eventGroupId?: EventGroupId;

  isForecast: boolean;

  startEditingKey: string | null;

  // Callback for saving cells that aren't driver/object field forecast impacts
  // e.g. actuals, cumulative cells, etc.
  onSaveFallback: (actual: number | null) => void;

  onSaveFromKeyboard: (props: OnChangeFormulaArgs) => void;
  onSaveFromBlur?: (props: OnChangeFormulaArgs) => void;
};

type UseEnhancedCellEditingReturn = {
  formulaDisplay: FormulaDisplay | null;
  formulaError: string | undefined;
  onSaveFromKeyboard: (props: OnChangeFormulaArgs) => void;
  onSaveImperative: () => void;
  onBlur: (e: SyntheticEvent, onSaveProps: OnChangeFormulaArgs) => void;
  formulaInputRef: React.RefObject<EditorRef>;
  onResetFormulaError: () => void;
};

export function useEnhancedCellEditing({
  eventEntity,
  monthKey,
  value,
  curvePoint,
  eventGroupId,
  displayConfiguration,
  isForecast,
  startEditingKey,
  onSaveFallback,
  onSaveFromKeyboard: onSaveFromKeyboardProp,
  onSaveFromBlur,
}: UseEnhancedCellEditingProps): UseEnhancedCellEditingReturn {
  const { blockId } = useBlockContext();
  const dispatch = useAppDispatch();
  const planPickerEventGroup = useAppSelector(planPickerSelectedWithEventGroupSelector);
  // When cell editing in the live edit inspector, we don't use the plan picker to decide
  // which event group new events should be added to. Instead, we use the event group of the
  // selected initiative
  const liveEditInspectorInitiative = useAppSelector((state) =>
    selectedInitiativeSelector(state, blockId),
  );

  const formulaInputRef = useRef<EditorRef | null>(null);
  const [formulaError, setFormulaError] = useState<string | undefined>();

  const formulaDisplay = useMemo(
    () =>
      getActiveCellFormulaDisplay({
        eventEntity,
        isForecast,
        value,
        curvePoint,
        displayConfiguration,
        startEditingKey,
      }),
    [curvePoint, displayConfiguration, eventEntity, isForecast, value, startEditingKey],
  );

  const saveForecastImpact = useCallback(
    (e: EventEntity, m: MonthKey, newFormula: string | null) => {
      const newCurvePoint = parseImpact(newFormula);

      if (e.type === 'objectField' && newCurvePoint?.impactType === ImpactType.Delta) {
        throw new Error('Delta impacts are not supported on legacy fields');
      }

      // Only use the event group of the selected live edit initiative if the value has actually changed and
      // there is not an existing eventGroupId.
      const liveEditParentId = liveEditInspectorInitiative?.parentId;
      const useLiveEditInspectorEventGroup =
        newCurvePoint != null && liveEditParentId != null && eventGroupId == null;

      const withEventGroup: WithEventGroup | undefined = useLiveEditInspectorEventGroup
        ? { type: 'existing', eventGroupId: liveEditParentId }
        : planPickerEventGroup;

      dispatch(
        setCurvePointOnEventEntity({
          eventEntity: e,
          monthKey: m,
          curvePoint: newCurvePoint,
          blockId,
          withEventGroup,
        }),
      );
    },
    [dispatch, blockId, eventGroupId, liveEditInspectorInitiative, planPickerEventGroup],
  );

  // Handles saving non-impact numbers.
  const saveNormalNumber = useCallback(
    (newFormula: string | null) => {
      const newNumber = parseNumber(newFormula);

      if (newNumber === value) {
        // If value didn't change, do nothing
        return;
      }

      onSaveFallback(newNumber);
    },
    [onSaveFallback, value],
  );

  const onSaveFromSource = useCallback(
    (args: OnChangeFormulaArgs, source: 'keyboard' | 'blur') => {
      try {
        const { newFormula } = args;

        if (isForecast && eventEntity != null && monthKey != null) {
          saveForecastImpact(eventEntity, monthKey, newFormula);
        } else {
          saveNormalNumber(newFormula);
        }

        setFormulaError(undefined);

        if (source === 'keyboard') {
          onSaveFromKeyboardProp(args);
        } else {
          onSaveFromBlur?.(args);
        }
      } catch (err) {
        setFormulaError(`${(err as Error).message}`);
      }
    },
    [
      isForecast,
      eventEntity,
      monthKey,
      saveForecastImpact,
      saveNormalNumber,
      onSaveFromKeyboardProp,
      onSaveFromBlur,
    ],
  );

  const onSaveFromKeyboard = useCallback(
    (args: OnChangeFormulaArgs) => onSaveFromSource(args, 'keyboard'),
    [onSaveFromSource],
  );

  const onSaveImperative = useCallback(() => {
    formulaInputRef?.current?.save();
  }, []);

  const onBlur = useCallback(
    (e: SyntheticEvent, onSaveProps: OnChangeFormulaArgs) => {
      // We get passed a SyntheticEvent which is a base class for all events,
      // so we narrow the type to the specific event we care about
      if (!('relatedTarget' in e)) {
        return;
      }

      // Do not save when clicking the "Tag Plan" button or when
      // Plan Picker appears (has a search bar that triggers focus)
      const relatedTarget = e.relatedTarget as HTMLElement;
      if (
        relatedTarget?.id === EVENT_GROUP_TAG_BUTTON_ID ||
        relatedTarget?.id === SEARCH_INPUT_ID
      ) {
        return;
      }

      onSaveFromSource(onSaveProps, 'blur');
    },
    [onSaveFromSource],
  );

  const onResetFormulaError = useCallback(() => {
    setFormulaError(undefined);
  }, [setFormulaError]);

  // Focus the formula input when the plan picker closes
  const isPlanPickerOpen = useAppSelector(isPlanPickerOpenSelector);
  useEffect(() => {
    if (!isPlanPickerOpen) {
      formulaInputRef?.current?.focus();
    }
  }, [isPlanPickerOpen]);

  return {
    formulaDisplay,
    formulaError,
    onSaveFromKeyboard,
    onBlur,
    formulaInputRef,
    onSaveImperative,
    onResetFormulaError,
  };
}
