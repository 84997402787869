import { DataChanges } from '@features/CompareScenarios/blocks/DriverBlock/DataChangesBlock';
import { DriverPropertyChangesBlock } from '@features/CompareScenarios/blocks/DriverBlock/PropertyChangesBlock';
import { ToggleSection } from '@features/CompareScenarios/blocks/common/ToggleSection';
import { DriverChange } from '@features/CompareScenarios/comparators/DriverComparator';

export const DriversBlock = ({ changes }: { changes: DriverChange[] }) => {
  if (changes.length === 0) {
    return null;
  }

  return (
    <ToggleSection
      heading="Driver updates"
      textProps={{ fontSize: 'md', fontWeight: '600' }}
      isDefaultOpen
      data-testid="drivers-block-toggle-section"
    >
      <DriverPropertyChangesBlock changes={changes} />
      <DataChanges changes={changes} />
    </ToggleSection>
  );
};
