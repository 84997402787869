import { DriverRowKey } from 'config/cells';
import { ComparisonColumn, ComparisonTimePeriod } from 'generated/graphql';
import { ComparisonLayout } from 'helpers/blockComparisons';
import { DriverGroupId } from 'reduxStore/models/driverGroup';
import { DriverId } from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';

export const getOrderedRowKeysForDrivers = ({
  driverIds,
  layerComparisons,
  groupId,
  isShowingComparisonDriverHeaderRow,
  comparisonColumns,
  comparisonLayout,
  currentLayerId,
  comparisonTimePeriods,
}: {
  driverIds: DriverId[];
  layerComparisons: Array<LayerId | undefined>;
  groupId?: DriverGroupId;
  isShowingComparisonDriverHeaderRow: boolean;
  comparisonColumns: ComparisonColumn[];
  comparisonLayout: ComparisonLayout;
  currentLayerId: LayerId | undefined;
  comparisonTimePeriods: ComparisonTimePeriod[];
}): Array<DriverRowKey & { driverId: DriverId }> => {
  const isComparingTimePeriods = comparisonTimePeriods.length > 0;

  return driverIds.flatMap((driverId) => {
    if (!isComparingTimePeriods && layerComparisons.length === 0) {
      // No comparison layers
      return [
        {
          driverId,
          layerId: undefined,
          groupId,
        },
      ];
    }

    const comparisonLayoutRows = [];

    if (isShowingComparisonDriverHeaderRow) {
      comparisonLayoutRows.push({
        driverId,
        layerId: undefined,
        groupId,
      });
    }

    if (comparisonLayout === 'column-compact') {
      comparisonLayoutRows.push({
        driverId,
        // The first layer in the list is the baseline layer, so make the layerId of the driver
        // row the second layer in order to get the correct variance/variance %.
        layerId: layerComparisons[1],
        groupId,
      });
    } else if (comparisonLayout === 'column-default') {
      comparisonColumns.forEach((comparisonType) => {
        if (
          comparisonType === ComparisonColumn.LatestVersion ||
          comparisonType === ComparisonColumn.BaselineVersion
        ) {
          return;
        }
        comparisonLayoutRows.push({
          driverId,
          layerId: undefined,
          groupId,
          comparisonType,
        });
      });
    } else if (isComparingTimePeriods) {
      comparisonTimePeriods.forEach((comparisonTimePeriod) => {
        comparisonLayoutRows.push({
          driverId,
          layerId: currentLayerId,
          groupId,
          comparisonTimePeriod,
        });
      });
    } else {
      layerComparisons.forEach((layerId) => {
        comparisonLayoutRows.push({
          driverId,
          layerId,
          groupId,
        });
      });
    }

    return comparisonLayoutRows;
  });
};
