import React, { useContext, useMemo } from 'react';

import CellContextProviderLight from 'components/CellContextProvider/CellContextProviderLight';
import { useDisplayConfiguration } from 'components/DriverTimeSeriesCell/useDisplayConfiguration';
import LoadingNumericTimeSeriesCell from 'components/DriverTimeSeriesRow/LoadingNumericTimeSeriesCell';
import NumericTimeSeriesCellLight from 'components/TimeSeriesCell/NumericTimeSeriesCellLight';
import { CellType, DriverCellRef } from 'config/cells';
import { DriverRowContext } from 'config/driverRowContext';
import { ComparisonColumn, RollupType } from 'generated/graphql';
import { getMonthColumnKey } from 'helpers/cells';
import { TimeSeriesComparisonSubColumn } from 'helpers/rollups';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { columnWidthSelector } from 'selectors/tableColumnsSelector';
import { CalculationError } from 'types/dataset';
import { MonthKey } from 'types/datetime';

interface Props {
  monthKey: MonthKey;
  comparisonColumn: ComparisonColumn | undefined;
  subColumn: TimeSeriesComparisonSubColumn | undefined;
  rollupType: RollupType;
  color: string;
  value: number | undefined;
  error: CalculationError | undefined;
}

const DriverTimeSeriesCellLight: React.FC<Props> = ({
  monthKey,
  subColumn,
  rollupType,
  color,
  value,
  error,
}) => {
  const {
    driverId,
    comparisonRowLayerId,
    groupId,
    comparisonType: rowComparisonType,
    comparisonTimePeriod,
  } = useContext(DriverRowContext);
  const { blockId } = useBlockContext();

  const cellRef: DriverCellRef = useMemo(
    () => ({
      type: CellType.Driver,
      rowKey: {
        driverId,
        layerId: comparisonRowLayerId,
        groupId,
        comparisonType: rowComparisonType,
        comparisonTimePeriod,
      },
      columnKey: getMonthColumnKey(monthKey, rollupType, subColumn),
    }),
    [
      driverId,
      comparisonRowLayerId,
      groupId,
      rowComparisonType,
      comparisonTimePeriod,
      monthKey,
      rollupType,
      subColumn,
    ],
  );

  const width = useAppSelector((state) =>
    columnWidthSelector(state, { columnType: monthKey, blockId }),
  );

  const displayConfiguration = useDisplayConfiguration({
    driverId,
    comparisonColumn: subColumn?.column ?? rowComparisonType,
  });

  return (
    <CellContextProviderLight cellRef={cellRef}>
      {value == null && error == null ? (
        <LoadingNumericTimeSeriesCell width={width} color={color} />
      ) : (
        <NumericTimeSeriesCellLight
          width={width}
          displayConfiguration={displayConfiguration}
          value={value}
          error={error}
          color={color}
          isPlaceholder={false}
        />
      )}
    </CellContextProviderLight>
  );
};

export default React.memo(DriverTimeSeriesCellLight);
