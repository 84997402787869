import Fuse, { FuseIndex, IFuseOptions } from 'fuse.js';
import { useMemo } from 'react';

interface Opts<T> {
  query: string;
  items: T[];
  searchKeys: string[];
  defaultResults?: T[];
  index?: FuseIndex<T>;
}

export function useListSearch<T extends { id: string }>({
  query,
  items,
  searchKeys,
  index,
  defaultResults,
}: Opts<T>) {
  const fuse = useMemo(() => {
    // see options here: https://www.fusejs.io/api/options.html#fuzzy-matching-options
    // the exact paramaters here are not sacrosanct and should be updated as needed
    const options: IFuseOptions<T> = {
      keys: searchKeys,
      threshold: 0.2,
      distance: 200,
    };

    return new Fuse(items, options, index);
  }, [searchKeys, items, index]);

  if (query.length === 0) {
    return defaultResults ?? items;
  }

  return fuse.search(query, { limit: 250 }).map((result) => result.item);
}
