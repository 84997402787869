import { ComparisonTimePeriod } from 'generated/graphql';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import { timePeriodComparisonSortComparator } from 'helpers/timeperiodComparisons';
import { BlockId } from 'reduxStore/models/blocks';
import { blockConfigSelector } from 'selectors/blocksSelector';
import { ParametricSelector } from 'types/redux';

export const comparisonTimePeriodsForBlockSelector: ParametricSelector<
  BlockId,
  ComparisonTimePeriod[]
> = createDeepEqualSelector(blockConfigSelector, (blockConfig) => {
  // current time period should always be selected
  let timePeriods = blockConfig?.comparisons?.timePeriods ?? [];
  if (timePeriods.length > 0 && !timePeriods.includes(ComparisonTimePeriod.CurrentPeriod)) {
    timePeriods = [ComparisonTimePeriod.CurrentPeriod, ...timePeriods];
  }
  return [...timePeriods].sort(timePeriodComparisonSortComparator);
});
