import { deepEqual } from 'fast-equals';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import { createCachedSelector } from 're-reselect';
import { createSelector, createSelectorCreator, lruMemoize } from 'reselect';

import { LeverType } from 'generated/graphql';
import { getColorFromCellRef } from 'helpers/cells';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import {
  getAttributeValueString,
  getDimensionalDriverBySubDriverIdMap,
  getDimensionalDrivers,
  getSubDriverName,
} from 'helpers/dimensionalDrivers';
import { attributesBySubDriverIdForDimensionalDrivers } from 'helpers/drivers';
import { EvaluatorDriver } from 'helpers/formulaEvaluation/ReferenceEvaluator';
import {
  SelectorWithLayerParam,
  addLayerParams,
  getCacheKeyForLayerSelector,
  layerParamMemo,
} from 'helpers/layerSelectorFactory';
import { NameDeduper, newNameDeduper } from 'helpers/naming';
import { isNotNull } from 'helpers/typescript';
import { uuidv4 } from 'helpers/uuidv4';
import { Coloring } from 'reduxStore/models/common';
import { Attribute, AttributeId, PseudoAttribute } from 'reduxStore/models/dimensions';
import {
  BasicDriver,
  DimensionalDriver,
  DimensionalSubDriver,
  Driver,
  DriverId,
  DriverType,
  getDriverActualsFormulaForCalculation,
  getDriverForecastFormulaForCalculation,
} from 'reduxStore/models/drivers';
import { LayerId } from 'reduxStore/models/layers';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { driverIdSelector, fieldSelector } from 'selectors/constSelectors';
import { attributesSelector } from 'selectors/dimensionsSelector';
import { driverEntityTableForLayerSelector } from 'selectors/driverEntityTableSelector';
import { driversWithoutStandInForLayerSelector } from 'selectors/driversWithoutStandInSelector';
import { launchDarklySelector } from 'selectors/launchDarklySelector';
import { getGivenOrCurrentLayerId } from 'selectors/layerSelector';
import { driverCellSelectionSelector } from 'selectors/prevailingCellSelectionSelector';
import { MonthKey } from 'types/datetime';
import { RawFormula } from 'types/formula';
import { ParametricSelector, Selector } from 'types/redux';

export const allDriverIdsSelector = createSelector(
  driverEntityTableForLayerSelector,
  (drivers) => drivers.allIds,
);

// TODO: Clean this up
export const driversForLayerSelector: SelectorWithLayerParam<Driver[]> =
  driversWithoutStandInForLayerSelector;

export const kpiTypeDriverIdsForLayerSelector: SelectorWithLayerParam<DriverId[]> =
  createCachedSelector(addLayerParams(driversForLayerSelector), (drivers) => {
    return drivers
      .filter((driver) => driver.leverType === LeverType.Kpi)
      .map((driver) => driver.id);
  })({
    keySelector: getCacheKeyForLayerSelector,
    selectorCreator: createDeepEqualSelector,
  });

export const driversByIdForLayerSelector: SelectorWithLayerParam<
  Record<DriverId, Driver | undefined>
> = createCachedSelector(
  addLayerParams(driversForLayerSelector),
  function driversByIdForLayerSelector(drivers) {
    return keyBy(drivers, 'id');
  },
)(getCacheKeyForLayerSelector);

export const driversByIdForCurrentLayerSelector = (state: RootState) =>
  driversByIdForLayerSelector(state);

const evaluatorDriverSelectorCreator = createSelectorCreator(lruMemoize, {
  // check that there's a meaningful difference in the drivers list by asserting that
  // something material to the calculation changed. we ignore pins, names, and other
  // fields that don't affect evaluation
  resultEqualityCheck: (
    prevDriversById: Record<DriverId, EvaluatorDriver>,
    currDriversById: Record<DriverId, EvaluatorDriver>,
  ) => {
    // this gets run very frequently with identical inputs, so ensure we check for the
    // simplest case first
    if (prevDriversById === currDriversById) {
      return true;
    }

    const prevIds = Object.keys(prevDriversById);
    const currIds = Object.keys(currDriversById);
    if (prevIds.length !== currIds.length) {
      return false;
    }

    return currIds.every((id) => {
      const curr = currDriversById[id];
      const prev = prevDriversById[id];
      return deepEqual(curr, prev);
    });
  },
});

/**
 * Grab the parts of a driver that impact formula evaluation; we exclude things like a
 * driver's name and lever type, which don't impact the way it's evaluated.
 */
export const evaluatorDriversByIdForLayerSelector: SelectorWithLayerParam<
  Record<DriverId, EvaluatorDriver | undefined>
> = createCachedSelector(addLayerParams(driversByIdForLayerSelector), (driversById) => {
  return mapValues(driversById, (driver) => {
    if (driver == null) {
      return undefined;
    }
    const evaluatorDriver = {
      id: driver.id,
      name: driver.name,
      format: driver.format,
      driverReferences: driver.driverReferences,
      driverMapping: driver.driverMapping,
    };

    if (driver.type === DriverType.Basic) {
      const { type, actuals, forecast } = driver;
      return { ...evaluatorDriver, type, actuals, forecast };
    } else if (driver.type === DriverType.Dimensional) {
      const { type, subdrivers, dimensions } = driver;
      return { ...evaluatorDriver, type, subdrivers, dimensions };
    }

    throw new Error(`unexpected driver type`);
  });
})({
  keySelector: getCacheKeyForLayerSelector,
  selectorCreator: evaluatorDriverSelectorCreator,
});

export const evaluatorDriverSelector: ParametricSelector<DriverId, EvaluatorDriver | undefined> =
  createCachedSelector(
    (state) => evaluatorDriversByIdForLayerSelector(state),
    driverIdSelector,
    (driversById, driverId) => {
      return driversById[driverId];
    },
  )((_state, driverId) => driverId);

export const evaluatorDriversForLayerSelector: SelectorWithLayerParam<EvaluatorDriver[]> =
  createCachedSelector(
    evaluatorDriversByIdForLayerSelector,
    function evaluatorDriversForLayerSelector(driversById) {
      return Object.values(driversById).filter(isNotNull);
    },
  )(getCacheKeyForLayerSelector);

export const driverNamesByIdSelector: SelectorWithLayerParam<Record<DriverId, string>> =
  createCachedSelector(
    addLayerParams(driversByIdForLayerSelector),
    function driverNamesByIdSelector(driversById) {
      return mapValues(driversById, 'name');
    },
  )(getCacheKeyForLayerSelector);

export const allDriverNamesSelector: SelectorWithLayerParam<string[]> = createCachedSelector(
  addLayerParams(driverNamesByIdSelector),
  (driverNamesById: Record<string, string>) =>
    Object.entries(driverNamesById)
      .map(([_id, name]) => name)
      .filter(isNotNull),
)(getCacheKeyForLayerSelector);

export const driverNameDeduperSelector: SelectorWithLayerParam<NameDeduper['dedupe']> =
  createCachedSelector(
    addLayerParams(allDriverNamesSelector),
    (allDriverNames) => newNameDeduper(allDriverNames).dedupe,
  )(getCacheKeyForLayerSelector);

export const attributeNamesByIdSelector: SelectorWithLayerParam<Record<AttributeId, string>> =
  createCachedSelector(addLayerParams(attributesSelector), (attributes) =>
    Object.fromEntries(attributes.map((attr) => [attr.id, getAttributeValueString(attr)])),
  )(getCacheKeyForLayerSelector);

export const allDimensionalDriversSelector: SelectorWithLayerParam<DimensionalDriver[]> =
  createCachedSelector(
    addLayerParams(driversForLayerSelector),
    function allDimensionalDriversSelector(drivers) {
      return getDimensionalDrivers(drivers);
    },
  )(getCacheKeyForLayerSelector);

export const dimensionalDriversBySubDriverIdSelector: SelectorWithLayerParam<
  Record<DriverId, DimensionalDriver>
> = createCachedSelector(
  addLayerParams(allDimensionalDriversSelector),
  function dimensionalDriversBySubDriverIdSelector(dimDrivers) {
    return getDimensionalDriverBySubDriverIdMap(dimDrivers);
  },
)(getCacheKeyForLayerSelector);

export const dimensionalDriversByIdSelector: SelectorWithLayerParam<
  Record<DriverId, DimensionalDriver>
> = createCachedSelector(
  addLayerParams(allDimensionalDriversSelector),
  function dimensionalDriversByIdSelector(dimDrivers) {
    return keyBy(dimDrivers, 'id');
  },
)(getCacheKeyForLayerSelector);

// There may be multiple driver ids per name due to the fact that dimensional
// drivers share their name with their sub drivers.
export const driverIdsByNameSelector: SelectorWithLayerParam<Record<string, DriverId[]>> =
  createCachedSelector(addLayerParams(driverNamesByIdSelector), (namesById) => {
    const result: Record<string, DriverId[]> = {};
    Object.entries(namesById).map(([id, name]) => {
      if (result[name] == null) {
        result[name] = [];
      }
      result[name].push(id);
    });
    return result;
  })(getCacheKeyForLayerSelector);

export const attributesBySubDriverIdSelector: SelectorWithLayerParam<
  Record<DriverId, Attribute[]>
> = createCachedSelector(
  allDimensionalDriversSelector,
  function attributesBySubDriverIdSelector(dimDrivers: DimensionalDriver[]) {
    return attributesBySubDriverIdForDimensionalDrivers(dimDrivers);
  },
)(getCacheKeyForLayerSelector);

export const allBasicDriversSelector: SelectorWithLayerParam<
  Array<BasicDriver & { attributes: Attribute[] }>
> = createCachedSelector(
  addLayerParams(driversForLayerSelector),
  attributesBySubDriverIdSelector,
  function allBasicDriversSelector(drivers, allAttributesBySubDriverId) {
    return drivers
      .filter((driver): driver is BasicDriver => driver.type === DriverType.Basic)
      .map((driver) => ({ ...driver, attributes: allAttributesBySubDriverId[driver.id] ?? [] }));
  },
)(getCacheKeyForLayerSelector);

export const basicDriverResolvedNamesByIdSelector: SelectorWithLayerParam<
  Record<DriverId, string>
> = createCachedSelector(addLayerParams(allBasicDriversSelector), (basicDrivers) => {
  const basicDriversById = keyBy(basicDrivers, 'id');
  return mapValues(basicDriversById, (driver) => {
    return getSubDriverName(
      driver.name,
      driver.attributes.map((a) => getAttributeValueString(a)),
    );
  });
})(getCacheKeyForLayerSelector);

export const dimensionalSubDriverIdsSelector: Selector<DriverId[]> = createDeepEqualSelector(
  allDimensionalDriversSelector,
  function dimensionalSubDriverIdsSelector(dimDrivers) {
    return dimDrivers.flatMap((dimDriver) => dimDriver.subdrivers.map((d) => d.driverId));
  },
);

const topLevelBasicDriversSelector: Selector<Array<BasicDriver & { attributes: Attribute[] }>> =
  createSelector(
    dimensionalSubDriverIdsSelector,
    allBasicDriversSelector,
    (allSubDriverIds, allBasicSubDrivers) => {
      const allSubDriverIdsSet = new Set(allSubDriverIds);
      return allBasicSubDrivers.filter((driver) => !allSubDriverIdsSet.has(driver.id));
    },
  );

export const topLevelDriversSelector: Selector<
  Array<(BasicDriver & { attributes: Attribute[] }) | DimensionalDriver>
> = createSelector(
  topLevelBasicDriversSelector,
  dimensionalDriversByIdSelector,
  (allTopLevelBasicDrivers, dimensionalDriversById) => {
    return [...allTopLevelBasicDrivers, ...Object.values(dimensionalDriversById)];
  },
);

export const topLevelDimensionalDriversSelector: Selector<
  Array<(BasicDriver & { attributes: Attribute[] }) | DimensionalDriver>
> = createSelector(dimensionalDriversByIdSelector, (dimensionalDriversById) => {
  const allDimDrivers = Object.values(dimensionalDriversById);
  const validDimDrivers = allDimDrivers.filter((dimDriver) => {
    return dimDriver.dimensions.length > 0;
  });
  return validDimDrivers;
});

export const isDimensionalSubDriverSelector = createCachedSelector(
  dimensionalSubDriverIdsSelector,
  driverIdSelector,
  (dimensionalSubDriverIds, driverId) => {
    return dimensionalSubDriverIds.includes(driverId);
  },
)((_state, driverId) => driverId);

export const cacheKeyForDriverForLayerSelector = (
  state: RootState,
  { layerId, id }: DriverForLayerProps,
) => `${getGivenOrCurrentLayerId(state, { layerId })}.${id}`;

export const driverSelector: ParametricSelector<DriverForLayerProps, Driver | undefined> =
  createCachedSelector(
    (state: RootState, params: DriverForLayerProps) => driversByIdForLayerSelector(state, params),
    fieldSelector('id'),
    (driversById, driverId) => {
      return driversById[driverId];
    },
  )(cacheKeyForDriverForLayerSelector);

export const driverDescriptionSelector: ParametricSelector<
  DriverForLayerProps,
  string | undefined | null
> = createCachedSelector(
  (state: RootState, params: DriverForLayerProps) => driversByIdForLayerSelector(state, params),
  fieldSelector('id'),
  launchDarklySelector,
  (driversById, driverId, { enableAiExplanationsInFrontend }) => {
    const driver = driversById[driverId];
    if (driver == null) {
      return undefined;
    }
    if (driver.description != null) {
      return driver.description;
    }
    if (enableAiExplanationsInFrontend) {
      return driver.generatedExplanation;
    }
    return null;
  },
)(cacheKeyForDriverForLayerSelector);

export const dimDriverSelector: ParametricSelector<
  DriverForLayerProps,
  DimensionalDriver | undefined
> = createCachedSelector(driverSelector, (driver) => {
  return driver?.type === DriverType.Dimensional ? driver : undefined;
})(cacheKeyForDriverForLayerSelector);

export const mustDriverSelector: ParametricSelector<DriverForLayerProps, Driver> =
  createCachedSelector(
    (state: RootState, params: DriverForLayerProps) =>
      driversByIdForLayerSelector(state, { layerId: params.layerId }),
    fieldSelector('id'),
    (driversById, driverId) => {
      const driver = driversById[driverId];
      if (driver == null) {
        throw Error(`Driver not found ${driverId}`);
      }
      return driver;
    },
  )(cacheKeyForDriverForLayerSelector);

export type DriverForLayerProps = {
  layerId?: LayerId;
  id: DriverId;
};

const driverForLayerSelector: ParametricSelector<DriverForLayerProps, Driver | undefined> =
  createCachedSelector(
    (state: RootState, { layerId }: DriverForLayerProps) =>
      driversByIdForLayerSelector(state, layerParamMemo(layerId)),
    fieldSelector('id'),
    function driverForLayerSelector(driversById, driverId) {
      return driversById[driverId.split(';')[0]];
    },
  )(cacheKeyForDriverForLayerSelector);

export const driverNameSelector: ParametricSelector<DriverForLayerProps, string | undefined> =
  createCachedSelector(
    (state: RootState, { layerId }: DriverForLayerProps) =>
      driverNamesByIdSelector(state, { layerId }),
    fieldSelector('id'),
    (driverNamesById, maybeGhostDriverId) => {
      const [driverId, ..._rest] = maybeGhostDriverId.split(';');
      return driverNamesById[driverId];
    },
  )(cacheKeyForDriverForLayerSelector);

export const basicDriverResolvedNameSelector: ParametricSelector<DriverForLayerProps, string> =
  createCachedSelector(
    (state: RootState, params: DriverForLayerProps) =>
      basicDriverResolvedNamesByIdSelector(state, { layerId: params.layerId }),
    fieldSelector('id'),
    (driverNamesById, driverId) => {
      return driverNamesById[driverId];
    },
  )(cacheKeyForDriverForLayerSelector);

export const driverIsKpiSelector: ParametricSelector<DriverId, boolean> = createCachedSelector(
  (state: RootState, driverId: DriverId) => driverSelector(state, { id: driverId }),
  (driver) => {
    return driver?.leverType === LeverType.Kpi;
  },
)((_state, driverId) => driverId);

export type DriverFormulaProps = DriverForLayerProps & { forCalculation?: boolean };

export const cacheKeyForDriverFormulaSelector = (state: RootState, props: DriverFormulaProps) =>
  `${cacheKeyForDriverForLayerSelector(state, props)}.${props.forCalculation ?? false}`;

export const parentDimDriverForSubDriverSelector: ParametricSelector<
  DriverFormulaProps,
  DimensionalDriver | null
> = createCachedSelector(
  driverForLayerSelector,
  dimensionalDriversBySubDriverIdSelector,
  (driver, dimensionalDriversBySubDriverId) => {
    if (driver == null || driver.type !== DriverType.Basic) {
      return null;
    }
    return dimensionalDriversBySubDriverId[driver.id] ?? null;
  },
)(cacheKeyForDriverForLayerSelector);

export const driverForecastFormulaSelector: ParametricSelector<DriverFormulaProps, string | null> =
  createCachedSelector(
    driverForLayerSelector,
    parentDimDriverForSubDriverSelector,
    fieldSelector<DriverFormulaProps, 'forCalculation'>('forCalculation'),
    (driver, parentDimDriver, forCalculation) => {
      if (driver == null) {
        return null;
      }
      if (forCalculation) {
        return getDriverForecastFormulaForCalculation(driver, parentDimDriver).formula ?? null;
      }
      if (driver.type !== DriverType.Basic) {
        return driver.defaultForecast?.formula ?? null;
      }
      return driver.forecast.formula ?? null;
    },
  )(cacheKeyForDriverFormulaSelector);

export const driverActualsFormulaSelector: ParametricSelector<DriverFormulaProps, string | null> =
  createCachedSelector(
    driverForLayerSelector,
    parentDimDriverForSubDriverSelector,
    fieldSelector<DriverFormulaProps, 'forCalculation'>('forCalculation'),
    (driver, parentDimDriver, forCalculation) => {
      if (driver == null) {
        return null;
      }
      if (forCalculation) {
        return getDriverActualsFormulaForCalculation(driver, parentDimDriver).formula ?? null;
      }
      if (driver.type !== DriverType.Basic) {
        return driver.defaultActuals?.formula ?? null;
      }
      return driver.actuals.formula ?? null;
    },
  )(cacheKeyForDriverFormulaSelector);

export const driverFormulaSelector: ParametricSelector<
  DriverForLayerProps & { type: 'forecast' | 'actuals' },
  string | null
> = createSelector(
  fieldSelector<DriverForLayerProps & { type: 'forecast' | 'actuals' }, 'type'>('type'),
  (state: RootState, props: DriverForLayerProps) => driverForecastFormulaSelector(state, props),
  (state: RootState, props: DriverForLayerProps) => driverActualsFormulaSelector(state, props),
  (type, forecastFormula, actualsFormula) => {
    return type === 'forecast' ? forecastFormula : actualsFormula;
  },
);

export const driverHasActualsFormulaSelector: ParametricSelector<DriverForLayerProps, boolean> =
  createCachedSelector(driverForLayerSelector, function driverHasActualsFormulaSelector(driver) {
    return driver != null && driver.type === DriverType.Basic && driver.actuals.formula != null;
  })(cacheKeyForDriverForLayerSelector);

export const driverActualsOverrideMonthKeysSelector: ParametricSelector<
  DriverForLayerProps,
  Set<MonthKey>
> = createCachedSelector(
  driverForLayerSelector,
  function driverActualsOverrideMonthKeysSelector(driver) {
    return driver != null && driver.type === DriverType.Basic
      ? new Set(Object.keys(driver.actuals.timeSeries ?? {}))
      : new Set<MonthKey>();
  },
)({
  keySelector: cacheKeyForDriverForLayerSelector,
  selectorCreator: createDeepEqualSelector,
});

export const basicDriverForLayerSelector: ParametricSelector<
  DriverForLayerProps,
  BasicDriver | undefined
> = createCachedSelector(
  (s: RootState, props: DriverForLayerProps) =>
    driversByIdForLayerSelector(s, layerParamMemo(props.layerId ?? s.dataset.currentLayerId)),
  fieldSelector('id'),
  function basicDriverForLayerSelector(driversById, driverId) {
    const driver = driversById[driverId];
    if (driver == null) {
      return undefined;
    }
    if (driver.type === DriverType.Basic) {
      return driver;
    }
    return undefined;
  },
)(cacheKeyForDriverForLayerSelector);

export const requireBasicDriverForLayerSelector: ParametricSelector<
  DriverForLayerProps,
  BasicDriver
> = createCachedSelector(basicDriverForLayerSelector, fieldSelector('id'), (driver, driverId) => {
  if (driver == null) {
    throw new Error(`No basic driver with ID ${driverId} exists`);
  }
  return driver;
})(cacheKeyForDriverForLayerSelector);

const EMPTY_ARRAY: Attribute[] = [];
export const attributesForSubDriverIdSelector: ParametricSelector<DriverId, Attribute[]> =
  createCachedSelector(
    (state: RootState) => attributesBySubDriverIdSelector(state),
    driverIdSelector,
    (attributesBySubDriverId, driverId) => {
      const attrs = attributesBySubDriverId[driverId];
      if (attrs == null) {
        return EMPTY_ARRAY;
      }

      return attrs;
    },
  )((_state, driverId) => driverId);

export const pseudoAttributeSelectorBySubDriverIdSelector: ParametricSelector<
  DriverId,
  Attribute[] | undefined
> = createCachedSelector(
  (state: RootState) => dimensionalDriversBySubDriverIdSelector(state),
  (_state: RootState, driverId: DriverId) => driverId,
  function getPseudoAttributes(dimDrivers, driverId) {
    if (dimDrivers[driverId] == null) {
      return undefined;
    }

    const dimDriver = dimDrivers[driverId];

    const attributesForDimensionalDrivers = attributesBySubDriverIdForDimensionalDrivers([
      dimDriver,
    ]);

    const driverAttributes = attributesForDimensionalDrivers[driverId];
    const possibleDriverDimensions = dimDriver.dimensions.map((dim) => ({
      id: dim.id,
      name: dim.name,
    }));

    const pseudoAttributes: PseudoAttribute[] = possibleDriverDimensions
      .filter((dim) => !driverAttributes.map((attr) => attr.dimensionId).includes(dim.id))
      ?.map((partialDim) => ({
        id: uuidv4(),
        dimensionId: partialDim.id,
        deleted: false,
        type: 'Pseudo',
        value: `No ${partialDim.name}`,
      }));

    return pseudoAttributes;
  },
)((_state, driverId) => driverId);

export const dimensionalSubDriverSelector: ParametricSelector<
  DriverId,
  DimensionalSubDriver | undefined
> = createCachedSelector(
  (state: RootState) => dimensionalDriversBySubDriverIdSelector(state),
  driverIdSelector,
  (dimDriverBySubDriverId, driverId) => {
    const dimDriver = dimDriverBySubDriverId[driverId];
    if (dimDriver == null) {
      return undefined;
    }
    const subdriver = dimDriver.subdrivers.find((s) => s.driverId === driverId);
    return subdriver;
  },
)((_state, driverId) => driverId);

export const subDriversByDriverIdSelector: SelectorWithLayerParam<
  Record<DriverId, DimensionalSubDriver>
> = createCachedSelector(allDimensionalDriversSelector, (dimDrivers) =>
  Object.fromEntries(
    dimDrivers.flatMap((dimDriver) =>
      dimDriver.subdrivers.map((subdriver) => [subdriver.driverId, subdriver]),
    ),
  ),
)(getCacheKeyForLayerSelector);

export const subDriversByDimDriverIdSelector = createSelector(
  allDimensionalDriversSelector,
  (dimDrivers) =>
    dimDrivers.reduce(
      (acc, dimDriver) => {
        dimDriver.subdrivers.forEach((subdriver) => {
          acc[dimDriver.id] = [...(acc[dimDriver.id] ?? []), subdriver];
        });
        return acc;
      },
      {} as Record<DriverId, DimensionalSubDriver[]>,
    ),
);

export const driverColorSelector: ParametricSelector<DriverForLayerProps, Coloring | undefined> =
  createCachedSelector(driverSelector, (driver) => {
    return driver?.coloring;
  })(cacheKeyForDriverForLayerSelector);

export const driverRowColorSelector: ParametricSelector<DriverForLayerProps, string | undefined> =
  createCachedSelector(driverSelector, (driver) => {
    return driver?.coloring?.row;
  })(cacheKeyForDriverForLayerSelector);

export const allDriverFormulasSelector: SelectorWithLayerParam<RawFormula[]> = createCachedSelector(
  addLayerParams(allBasicDriversSelector),
  (basicDrivers) => {
    const allFormulas: Array<RawFormula | undefined> = [];
    basicDrivers.forEach((driver) => {
      allFormulas.push(driver.actuals.formula);
      allFormulas.push(driver.forecast.formula);
    });
    return allFormulas.filter(
      (formula): formula is RawFormula => formula != null && formula !== '',
    );
  },
)(getCacheKeyForLayerSelector);

export const uniqueDriverCellSelectionColorsSelector: Selector<Set<string | null>> = createSelector(
  driverCellSelectionSelector,
  driversByIdForLayerSelector,
  (cellSelection, driversById) => {
    const colors = new Set<string | null>();

    cellSelection?.selectedCells.forEach((cell) => {
      const driverId = cell.rowKey.driverId;

      if (driverId == null) {
        return;
      }

      const driverColor = driversById[driverId]?.coloring;
      const cellColor = getColorFromCellRef(cell, driverColor);

      colors.add(cellColor ?? null);
    });

    return colors;
  },
);
