import { Flex, ModalBody, ModalContent } from '@chakra-ui/react';
import { createSelector } from '@reduxjs/toolkit';
import {
  Dispatch,
  ForwardedRef,
  SetStateAction,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Header } from '@features/CompareScenarios/Header';
import { ActivityLogBlock } from '@features/CompareScenarios/blocks/ActivityLogBlock';
import { DatabasesBlock } from '@features/CompareScenarios/blocks/DatabasesBlock';
import { DriversBlock } from '@features/CompareScenarios/blocks/DriversBlock';
import { IdenticalLayersBlock } from '@features/CompareScenarios/blocks/IdenticalLayersBlock';
import { KPIBlock } from '@features/CompareScenarios/blocks/KPIBlock';
import { compareLayers } from '@features/CompareScenarios/compareLayers';
import BlockContext from 'components/BlockContainer/BlockContext';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useScenarioComparisonBlock } from 'hooks/useScenarioComparisonBlock';
import { closeScenarioComparisonModal } from 'reduxStore/actions/navigateTo';
import { updateComparisonLayersOnScenarioComparisonBlock } from 'reduxStore/actions/scenarioComparison';
import { Layer } from 'reduxStore/models/layers';
import { currentLayerSelector, defaultLayerSelector } from 'selectors/layerSelector';
import { scenarioComparisonPageDriverBlockIdSelector } from 'selectors/scenarioComparisonSelector';

export const CompareScenariosModal = forwardRef((_, ref: ForwardedRef<HTMLButtonElement>) => {
  // TODO: support new layer selection
  const { currentLayer, mergeLayer } = useAppSelector(comparedLayersSelector);
  const [comparedLayers, setComparedLayers] = useInitializeComparedLayers(currentLayer, mergeLayer);
  const dispatch = useAppDispatch();

  const onClose = useCallback(() => {
    dispatch(closeScenarioComparisonModal());
  }, [dispatch]);

  const onLayerChange = useCallback(
    (newCurrentLayer: Layer) => {
      dispatch(
        updateComparisonLayersOnScenarioComparisonBlock({
          additionalLayerIdsToCompare: [newCurrentLayer.id],
        }),
      );
      setComparedLayers({
        currentLayer: newCurrentLayer,
        mergeLayer: comparedLayers.mergeLayer,
      });
    },
    [dispatch, comparedLayers.mergeLayer, setComparedLayers],
  );

  return (
    <ModalContent
      width="fit-content"
      maxWidth="1360px"
      minWidth="720px"
      overflowX="visible"
      data-testid="scenario-comparison-modal"
    >
      <Header
        ref={ref}
        currentLayer={comparedLayers.currentLayer}
        mergeLayer={comparedLayers.mergeLayer}
        onLayerChange={onLayerChange}
        onClose={onClose}
        allowsSelect={currentLayer.id === mergeLayer.id}
      />
      <ModalBody padding={6} paddingTop={0} marginTop={0} overflowY="scroll">
        {comparedLayers.currentLayer != null ? (
          <ComparedLayerContext.Provider value={comparedLayers as ComparedLayers}>
            <WithChangeComparison />
          </ComparedLayerContext.Provider>
        ) : (
          <IdenticalLayersBlock
            currentLayer={comparedLayers.currentLayer}
            mergeLayer={comparedLayers.mergeLayer}
          />
        )}
      </ModalBody>
    </ModalContent>
  );
});

const WithChangeComparison = () => {
  const comparedLayers = useComparedLayers();
  const changes = useMemo(() => {
    if (comparedLayers.currentLayer == null) {
      return null;
    }
    return compareLayers(comparedLayers.currentLayer, comparedLayers.mergeLayer);
  }, [comparedLayers.currentLayer, comparedLayers.mergeLayer]);
  const driverBlockId = useAppSelector(scenarioComparisonPageDriverBlockIdSelector);
  const driverBlockCtx = useScenarioComparisonBlock(driverBlockId ?? '');

  if (changes == null) {
    return (
      <IdenticalLayersBlock
        currentLayer={comparedLayers.currentLayer}
        mergeLayer={comparedLayers.mergeLayer}
      />
    );
  }

  return (
    <BlockContext {...driverBlockCtx}>
      <Flex flexDirection="column" rowGap={2} overflowY="scroll">
        {(changes.drivers.length > 0 || changes.databases.length > 0) && <KPIBlock />}
        <DatabasesBlock changes={changes.databases} />
        <DriversBlock changes={changes.drivers} />
        <ActivityLogBlock changes={changes.activity} />
        {changes.drivers.length === 0 && changes.databases.length === 0 && <KPIBlock />}
      </Flex>
    </BlockContext>
  );
};

type PartialComparedLayers = { currentLayer: Layer | null; mergeLayer: Layer };
function useInitializeComparedLayers(
  currentLayer: Layer,
  mergeLayer: Layer,
): [PartialComparedLayers, Dispatch<SetStateAction<PartialComparedLayers>>] {
  const [comparedLayers, setComparedLayers] = useState(() => {
    if (currentLayer.id === mergeLayer.id) {
      return { currentLayer: null, mergeLayer };
    }
    return { currentLayer, mergeLayer };
  });

  useEffect(() => {
    setComparedLayers((layers) => {
      if (currentLayer.id === mergeLayer.id) {
        if (layers.mergeLayer.id !== mergeLayer.id) {
          return { currentLayer: null, mergeLayer };
        }
      } else if (
        layers.currentLayer?.id !== currentLayer.id ||
        layers.mergeLayer.id !== mergeLayer.id
      ) {
        return { currentLayer, mergeLayer };
      }
      return layers;
    });
  }, [currentLayer, mergeLayer]);

  return [comparedLayers, setComparedLayers];
}

const comparedLayersSelector = createSelector(
  [currentLayerSelector, defaultLayerSelector],
  (currentLayer, mergeLayer) => {
    return { currentLayer, mergeLayer };
  },
);

type ComparedLayers = { currentLayer: Layer; mergeLayer: Layer };
const ComparedLayerContext = createContext<ComparedLayers>({
  currentLayer: {} as Layer,
  mergeLayer: {} as Layer,
});
export function useComparedLayers() {
  return useContext(ComparedLayerContext);
}
