import { createSelector } from '@reduxjs/toolkit';
import { keyBy, sortBy } from 'lodash';
import { createCachedSelector } from 're-reselect';

import { getObjectSpecIdFromInternalPageType } from 'config/internalPages/databasePage';
import { ValueType } from 'generated/graphql';
import { createDeepEqualSelector } from 'helpers/deepEqualSelector';
import {
  SelectorWithLayerParam,
  addLayerParams,
  getCacheKeyForLayerSelector,
} from 'helpers/layerSelectorFactory';
import { isNotNull, nullSafeEqual } from 'helpers/typescript';
import { Attribute } from 'reduxStore/models/dimensions';
import { DriverGroupIdOrNull } from 'reduxStore/models/driverGroup';
import {
  BasicDriver,
  DimensionalDriver,
  Driver,
  DriverId,
  DriverType,
} from 'reduxStore/models/drivers';
import { SubmodelId } from 'reduxStore/models/submodels';
import { RootState } from 'reduxStore/reducers/sliceReducers';
import { accessCapabilitiesSelector } from 'selectors/accessCapabilitiesSelector';
import {
  accessiblePagesSelector,
  blocksPageInternalPageTypeByBlockIdSelector,
} from 'selectors/blocksPagesSelector';
import { businessObjectSpecIdsBySubDriverIdSelector } from 'selectors/collectionSelector';
import { fieldSelector } from 'selectors/constSelectors';
import {
  DriverForLayerProps,
  attributesBySubDriverIdSelector,
  cacheKeyForDriverForLayerSelector,
  driversForLayerSelector,
} from 'selectors/driversSelector';
import { accessibleDatabasesSelector } from 'selectors/navigationSelector';
import {
  blockIdBySubmodelIdSelector,
  submodelIdByBlockIdSelector,
} from 'selectors/submodelPageSelector';
import {
  driversBySubmodelIdSelector,
  sortedAccessibleSubmodelIdsSelector,
} from 'selectors/submodelSelector';
import { ParametricSelector } from 'types/redux';

export const accessibleDriversByIdForLayerSelector: SelectorWithLayerParam<
  Record<DriverId, Driver | undefined>
> = createCachedSelector(
  addLayerParams(driversForLayerSelector),
  businessObjectSpecIdsBySubDriverIdSelector,
  accessiblePagesSelector,
  accessibleDatabasesSelector,
  blocksPageInternalPageTypeByBlockIdSelector,
  sortedAccessibleSubmodelIdsSelector,
  submodelIdByBlockIdSelector,
  (state) => accessCapabilitiesSelector(state),
  (
    drivers,
    specIdsBySubDriverId,
    accessiblePages,
    accessibleDatabases,
    blocksPageInternalPageTypeByBlockId,
    accessibleSubmodelIds,
    submodelIdByBlockId,
    { isOrgAdmin },
    // eslint-disable-next-line max-params
  ) => {
    const submodelIdsSet = new Set(accessibleSubmodelIds);
    const accessibleDatabasesSet = new Set(accessibleDatabases);
    const accessibleBlockIds = new Set(accessiblePages.flatMap((page) => page.blockIds));
    const accessibleDrivers = drivers.filter((driver) => {
      if (isOrgAdmin) {
        return true;
      }

      const specIds = specIdsBySubDriverId[driver.id] ?? [];
      if (specIds.some((id) => accessibleDatabasesSet.has(id))) {
        return true;
      }

      const { driverReferences } = driver;
      const submodelIds =
        driverReferences?.map((ref) => submodelIdByBlockId[ref.blockId]).filter(isNotNull) ?? [];
      // filter out any database blocks since we've already checked databases above
      const filteredDriverReferences =
        driverReferences?.filter((ref) => {
          const internalPageType = blocksPageInternalPageTypeByBlockId[ref.blockId];
          return getObjectSpecIdFromInternalPageType(internalPageType ?? '') === '';
        }) ?? [];

      return (
        submodelIds.some((submodelId) => submodelIdsSet.has(submodelId)) ||
        filteredDriverReferences.some((ref) => accessibleBlockIds.has(ref.blockId))
      );
    });
    return keyBy(accessibleDrivers, 'id');
  },
)(getCacheKeyForLayerSelector);

export const isAccessibleDriverSelector: ParametricSelector<DriverForLayerProps, boolean> =
  createCachedSelector(
    (state: RootState, { layerId }: DriverForLayerProps) =>
      accessibleDriversByIdForLayerSelector(state, { layerId }),
    fieldSelector('id'),
    (driversById, driverId) => {
      return driversById[driverId] != null;
    },
  )(cacheKeyForDriverForLayerSelector);

const accessibleDriversSelector: SelectorWithLayerParam<Driver[]> = createCachedSelector(
  addLayerParams(accessibleDriversByIdForLayerSelector),
  (driversById) => Object.values(driversById).filter(isNotNull),
)(getCacheKeyForLayerSelector);

export const accessibleBasicDriversSelector: SelectorWithLayerParam<
  Array<BasicDriver & { attributes: Attribute[] }>
> = createCachedSelector(
  addLayerParams(accessibleDriversSelector),
  (state) => attributesBySubDriverIdSelector(state),
  function accessibleBasicDriversSelector(drivers, allAttributesBySubDriverId) {
    return drivers
      .filter((driver): driver is BasicDriver => driver.type === DriverType.Basic)
      .map((driver) => ({ ...driver, attributes: allAttributesBySubDriverId[driver.id] ?? [] }));
  },
)(getCacheKeyForLayerSelector);

const accessibleDimDriversSelector: SelectorWithLayerParam<DimensionalDriver[]> =
  createCachedSelector(
    addLayerParams(accessibleDriversSelector),
    function accessibleDimDriversSelector(drivers) {
      return drivers.filter(
        (driver): driver is DimensionalDriver => driver.type === DriverType.Dimensional,
      );
    },
  )(getCacheKeyForLayerSelector);

export const accessibleDimDriversByIdSelector: SelectorWithLayerParam<
  NullableRecord<DriverId, DimensionalDriver>
> = createSelector(accessibleDimDriversSelector, (drivers) => keyBy(drivers, 'id'));

export const numericDriversForLayerSelector: SelectorWithLayerParam<
  ReturnType<typeof accessibleBasicDriversSelector>
> = createCachedSelector(addLayerParams(accessibleBasicDriversSelector), (drivers) => {
  return drivers.filter((driver) => {
    return driver.valueType == null || driver.valueType === ValueType.Number;
  });
})(getCacheKeyForLayerSelector);

export const numericDimDriversForLayerSelector: SelectorWithLayerParam<
  ReturnType<typeof accessibleDimDriversSelector>
> = createCachedSelector(addLayerParams(accessibleDimDriversSelector), (drivers) => {
  return drivers.filter((driver) => {
    return driver.valueType == null || driver.valueType === ValueType.Number;
  });
})(getCacheKeyForLayerSelector);

export const sortedAccessibleBasicDriverIdsByGroupBySubmodelIdSelector: SelectorWithLayerParam<
  NullableRecord<SubmodelId, Array<{ groupId: DriverGroupIdOrNull; driverIds: DriverId[] }>>
> = createCachedSelector(
  addLayerParams(accessibleDriversByIdForLayerSelector),
  addLayerParams(driversBySubmodelIdSelector),
  addLayerParams(blockIdBySubmodelIdSelector),
  (accessibleDriversById, driversBySubmodelId, blockIdBySubmodelId) => {
    const result: NullableRecord<
      SubmodelId,
      Array<{ groupId: DriverGroupIdOrNull; driverIds: DriverId[] }>
    > = {};

    Object.entries(driversBySubmodelId).forEach(([submodelId, drivers]) => {
      const submodelBlockId = blockIdBySubmodelId[submodelId];
      if (submodelBlockId == null) {
        return;
      }
      drivers?.forEach((driver) => {
        if (accessibleDriversById[driver.id] == null || driver.type !== DriverType.Basic) {
          return;
        }
        const groupId =
          driver.driverReferences?.find((ref) => ref.blockId === submodelBlockId)?.groupId ?? null;

        const submodelGroups = result[submodelId] ?? [];
        const groupDrivers = submodelGroups.find((g) => nullSafeEqual(g.groupId, groupId));
        if (groupDrivers == null) {
          submodelGroups.push({ groupId, driverIds: [driver.id] });
        } else {
          groupDrivers.driverIds.push(driver.id);
        }
        result[submodelId] = submodelGroups;
      });
    });

    // order drivers by sortIndex
    Object.entries(result).forEach(([submodelId, groups]) => {
      const submodelBlockId = blockIdBySubmodelId[submodelId];
      if (submodelBlockId == null) {
        return;
      }

      // Sometimes groupId can show up as '', these should be treated as null
      const groupIdsEqual = (
        a: DriverGroupIdOrNull | undefined,
        b: DriverGroupIdOrNull | undefined,
      ) => {
        return nullSafeEqual(a === '' ? null : a, b === '' ? null : b);
      };

      groups?.forEach((g) => {
        g.driverIds = sortBy(g.driverIds, (id) => {
          return accessibleDriversById[id]?.driverReferences?.find(
            (ref) => ref.blockId === submodelBlockId && groupIdsEqual(ref.groupId, g.groupId),
          )?.sortIndex;
        });
      });
    });

    return result;
  },
)({ keySelector: getCacheKeyForLayerSelector, selectorCreator: createDeepEqualSelector });
