import { Box, Fade, Flex, List, StyleProps, Text } from '@chakra-ui/react';
import Tippy, { TippyProps } from '@tippyjs/react';
import Fuse from 'fuse.js';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import partition from 'lodash/partition';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';

import { Tooltip } from 'chakra/tooltip';
import CustomSelectMenuResult from 'components/SelectMenu/CustomSelectMenuResult';
import { SelectMenuContext, SelectMenuContextState } from 'components/SelectMenu/SelectMenuContext';
import SelectMenuFooter from 'components/SelectMenu/SelectMenuFooter';
import SelectMenuSection from 'components/SelectMenu/SelectMenuSection';
import ShowMoreSelectMenuResult from 'components/SelectMenu/ShowMoreSelectMenuResult';
import { SELECT_MENU_IGNORE_KEYSTROKE_CLASS, SELECT_MENU_SUBMENU } from 'config/selectMenu';
import { KeyboardShortcut } from 'config/shortcuts';
import theme from 'config/theme';
import { isInputKeyboardEvent, preventEventDefault } from 'helpers/browserEvent';
import { isPrintableChar } from 'helpers/string';
import { isNotNull } from 'helpers/typescript';
import useAttachedToDOM from 'hooks/useAttachedToDOM';
import { useIsScrolling } from 'hooks/useIsScrolling';
import { useListSearch } from 'hooks/useListSearch';
import usePreviousValue from 'hooks/usePreviousValue';
import MoreHorizontalIcon from 'vectors/MoreHorizontal';

type SectionId = string;
type SectionState = { isExpanded: boolean };

const OFFSET: [number, number] = [0, 6];
const DEFAULT_SECTION_STATE: SectionState = { isExpanded: false };
const MIN_SHOW_MORE = 3;
const MAX_IN_SECTION = 100;

const FADE_TRANSITION = { enter: { duration: 0 }, exit: { duration: 0.3 } };

const SUBMENU_POPPER_OPTIONS: TippyProps['popperOptions'] = {
  modifiers: [
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['right', 'left-start', 'left'],
      },
    },
  ],
};

const EMPTY_SEARCH_KEYS: string[] = [];

export type SelectItem = {
  id: string;
  name: string | React.ReactNode | null;
  key?: string;
  footer?: JSX.Element;
  hasNextMenu?: boolean;
  icon?: JSX.Element;
  iconColor?: StyleProps['color'];
  isChecked?: boolean;
  checkedStyle?: 'check' | 'switch' | 'none';
  isDisabled?: boolean;
  disabledTooltipLabel?: string;
  meta?: JSX.Element | string;
  isMetaLoading?: boolean;
  sectionId?: SectionId;
  shortcut?: KeyboardShortcut;
  submenu?: () => React.ReactElement;
  submenuType?: 'submenu' | 'options';
  selectBehavior?: 'always' | 'without-submenu';
};

export interface SelectMenuItemWithSelect extends SelectItem {
  onSelect?: () => void;
}

export type CustomOption = {
  id: string;
  onSelect: (query: string) => void;
  render: (query: string) => React.ReactNode;
  submenu?: () => React.ReactElement;
  submenuType?: 'submenu' | 'options';
  icon?: JSX.Element;
  errorMessage?: (query: string) => string | null;
  showFirst?: boolean;
  shortcutHint?: KeyboardShortcut | null;
  alwaysShowHint?: boolean;
  footer?: JSX.Element;
  onlyOnEmpty?: boolean;
  isDisabled?: boolean;
};

export interface SelectMenuItemProps<T> {
  item: T;
  isFocused: boolean;
  idx: number;
}

export interface Section {
  id: SectionId;
  name?: string;
  maxResults?: number;
  showMore?: boolean;
}

export interface SelectMenuProps<T> {
  items: T[];
  className?: string;
  sections?: Section[];
  query?: string;
  searchKeys?: string[];
  width?: StyleProps['width'];
  minWidth?: StyleProps['minWidth'];
  maxHeight?: StyleProps['maxHeight'];
  // pass null to start with no focus
  startFocusIdx?: number | null;
  // if true, the focus will be switched to the first exact match; this is reevaluated every time the query changes
  switchFocusToExactMatch?: boolean;
  clearFocusOnMouseLeave?: boolean;
  disableKeyDown?: boolean;
  dismissIfEmpty?: boolean;
  omitPadding?: boolean;
  allowEmptyQuery?: boolean;
  tabToSelect?: boolean;
  variant?: 'select' | 'dropdown' | 'metaSelect';
  hasPopoverOpen?: boolean;
  footer?: JSX.Element | ((isEmpty: boolean) => JSX.Element | null);

  onClose?: () => void;
  onSelect: (item: T, ev: React.MouseEvent | KeyboardEvent) => Promise<void> | void;

  children: (props: SelectMenuItemProps<T>) => React.ReactElement | null;
  customOptions?: CustomOption[];

  onFocusChange?: (focusedResult: SelectResult<T> | null, query: string) => void;
  onUnmount?: () => void;
}

type CustomOptionResult = { type: 'customOption'; item: CustomOption; idx: number };

type SectionToggle = {
  type: 'sectionToggle';
  sectionId: SectionId;
  extraResults: number;
};

type SectionToggleWithIdx = SectionToggle & { idx: number };
type Item<T> = {
  item: T;
  type: 'item';
  sectionId: SectionId | undefined;
};
type ItemWithIdx<T> = Item<T> & { idx: number };
export type SelectResult<T> = ItemWithIdx<T> | CustomOptionResult | SectionToggleWithIdx;

const SelectMenu = <T extends SelectItem>(props: SelectMenuProps<T>) => {
  const isTest = 'Cypress' in window;

  const {
    items,
    searchKeys = EMPTY_SEARCH_KEYS,
    query = '',
    sections,
    dismissIfEmpty = false,
    onClose,
    onSelect,
    children,
    customOptions,
    omitPadding = false,
    startFocusIdx,
    switchFocusToExactMatch = false,
    clearFocusOnMouseLeave = false,
    disableKeyDown = false,
    width = '28rem',
    minWidth,
    maxHeight,
    allowEmptyQuery = false,
    className,
    variant = 'select',
    hasPopoverOpen = false,
    tabToSelect = false,
    onFocusChange,
  } = props;

  const menuFooter = props.footer;
  const searchIndex = useMemo(() => {
    // N.B. it's unclear whether we should do this in all cases or only for "considerably large" lists;
    // I'm also not quite sure what Fuse regards that threshold as. though it seems that
    // Fuse creates an index on instantiation anyway by defult so this is likely fine.
    // see https://www.fusejs.io/api/indexing.html#fuse-createindex
    return Fuse.createIndex<T>(searchKeys, items);
  }, [searchKeys, items]);

  const results: Array<Item<T>> = useListSearch({
    query,
    items,
    searchKeys,
    index: searchIndex,
  }).map((item) => ({ item, sectionId: item.sectionId, type: 'item' as const }));

  const [sectionsState, setSectionsState] = useState<Record<SectionId, SectionState>>({});
  // if this is a submenu, we want to let the parent select menu know when we're showing a submenu
  // so that it doesn't steal keyboard events
  const setParentHasChildShowingSubmenu = useContext(SelectMenuContext).setIsChildShowingSubmenu;
  const [isChildShowingSubmenu, setIsChildShowingSubmenu] = useState(false);
  const [showSubmenu, setShowSubmenu] = useState(false);

  const showSections = sections != null;
  const isEmptyQuery = query.length === 0;
  const wasEmptyQuery = usePreviousValue(isEmptyQuery);
  const { sectionIds, sectionsById } = useMemo(() => {
    return {
      sectionIds: showSections ? sections.map((s) => s.id) : [],
      sectionsById: showSections ? keyBy(sections, 'id') : {},
    };
  }, [sections, showSections]);

  useEffect(() => {
    if (isEmptyQuery && !wasEmptyQuery) {
      setSectionsState((s) =>
        Object.fromEntries(
          sectionIds.map((id) => [
            id,
            isEmptyQuery ? DEFAULT_SECTION_STATE : s[id] ?? DEFAULT_SECTION_STATE,
          ]),
        ),
      );
    }
  }, [wasEmptyQuery, sectionIds, isEmptyQuery]);

  const hasCustomOptions =
    customOptions != null && customOptions.length > 0 && (!isEmptyQuery || allowEmptyQuery);
  const submenuAnchorRef = useRef<HTMLDivElement>(null);
  const [submenuAnchor, setSubmenuAnchor] = useState<HTMLDivElement | null>(null);

  const resultsWithCustom = useMemo(() => {
    const groupedResults: Record<SectionId, Array<Item<T> | SectionToggle>> = showSections
      ? mapValues(
          groupBy(results, (r) => r.item.sectionId),
          (sectionResults, sectionId) => {
            const section = sectionsById[sectionId];
            const maxResults = section?.maxResults;
            const sectionHasShowMore = Boolean(section?.showMore);
            const limitSectionResults =
              maxResults != null &&
              sectionHasShowMore &&
              !sectionsState[sectionId]?.isExpanded &&
              sectionResults.length - maxResults >= MIN_SHOW_MORE;

            if (!sectionHasShowMore) {
              return sectionResults.slice(0, maxResults ?? MAX_IN_SECTION);
            } else if (!limitSectionResults) {
              return sectionResults.slice(0, MAX_IN_SECTION);
            }

            return [
              ...sectionResults.slice(0, maxResults),
              {
                type: 'sectionToggle' as const,
                extraResults: sectionResults.length - maxResults,
                sectionId,
              },
            ];
          },
        )
      : {};

    // account for results that don't have sections
    let filteredResults: Array<Omit<SelectResult<T>, 'idx'>> = showSections
      ? [
          ...sectionIds.flatMap((id) => groupedResults[id]),
          ...results.filter((r) => r.item.sectionId == null),
        ].filter(isNotNull)
      : results;

    if (hasCustomOptions) {
      const visibleCustomOptions =
        filteredResults.length > 0 ? customOptions.filter((o) => !o.onlyOnEmpty) : customOptions;
      const [customShowFirst, customShowLast] = partition(
        visibleCustomOptions.map((o) => ({
          type: 'customOption' as const,
          item: o,
        })),
        (o) => o.item.showFirst,
      );
      filteredResults = [...customShowFirst, ...filteredResults, ...customShowLast];
    }

    return filteredResults.map<SelectResult<T>>((result, idx) => {
      return {
        ...result,
        idx,
      } as SelectResult<T>;
    });
  }, [
    results,
    showSections,
    sectionIds,
    sectionsById,
    sectionsState,
    hasCustomOptions,
    customOptions,
  ]);

  const getNextValidIdx = useCallback(
    (startIdx: number, direction: 'up' | 'down'): number => {
      const numItems = resultsWithCustom.length;
      const isDisabled = (item: SelectResult<T>) => item.type === 'item' && item.item.isDisabled;
      // guard against looping forever due to everything being disabled
      if (resultsWithCustom.every(isDisabled)) {
        return startIdx;
      }

      let nextIdx = startIdx;

      do {
        nextIdx = direction === 'up' ? nextIdx - 1 : nextIdx + 1;

        if (direction === 'down' && nextIdx >= numItems) {
          nextIdx = 0;
        } else if (direction === 'up' && nextIdx < 0) {
          nextIdx = numItems - 1;
        }

        const next = resultsWithCustom[nextIdx];
        if (next == null) {
          continue;
        } else if (!isDisabled(next)) {
          break;
        }
      } while (nextIdx !== startIdx);

      return nextIdx;
    },
    [resultsWithCustom],
  );

  const numResults = resultsWithCustom.length;
  const adjustedStartFocusIdx =
    startFocusIdx === undefined ? getNextValidIdx(-1, 'down') : startFocusIdx;

  const [charsSinceNoResults, setCharsSinceNoResults] = useState(0);
  const [focusIdx, setFocusIdx] = useState(adjustedStartFocusIdx);

  // fingerprint the items by ID so we don't reset the focus index when
  // properties change.
  const itemsFingerprint = useMemo(
    () =>
      items
        .map((item) => item.id)
        .sort()
        .join(' '),
    [items],
  );

  useEffect(() => {
    setFocusIdx(adjustedStartFocusIdx);
  }, [adjustedStartFocusIdx, itemsFingerprint, query]);

  const firstResultIdx = resultsWithCustom.findIndex((r) => r.type === 'item');
  const firstResultItem =
    firstResultIdx >= 0 ? (resultsWithCustom[firstResultIdx] as Item<T>).item : null;
  const previousQuery = usePrevious(query);
  useEffect(() => {
    if (query === previousQuery) {
      return;
    }
    if (switchFocusToExactMatch && firstResultItem != null && firstResultIdx != null) {
      const isExactMatch = (searchKeys as Array<keyof T>).some((k) => {
        if (k in firstResultItem) {
          const searchTerm = firstResultItem[k];
          if (searchTerm != null && typeof searchTerm === 'string') {
            return searchTerm.toLowerCase() === query.toLowerCase();
          }
        }
        return false;
      });
      if (isExactMatch) {
        setFocusIdx(firstResultIdx);
      }
    }
  }, [firstResultItem, firstResultIdx, previousQuery, query, searchKeys, switchFocusToExactMatch]);

  const [ref, isAttachedToDOM] = useAttachedToDOM<HTMLUListElement>();

  const onSelectIdx = useCallback(
    (idx: number, ev: React.MouseEvent | KeyboardEvent) => {
      const result = resultsWithCustom[idx];
      if (result == null) {
        return;
      }

      switch (result.type) {
        case 'item': {
          const canSelectItem =
            result.item.selectBehavior === 'always' ||
            (!result.item.isDisabled &&
              (result.item.submenu == null || result.item.submenuType === 'options'));

          if (!canSelectItem) {
            return;
          }
          onSelect(result.item, ev);
          break;
        }
        case 'customOption': {
          const canSelectItem =
            !result.item.isDisabled &&
            (result.item.submenu == null || result.item.submenuType === 'options');

          if (!canSelectItem) {
            return;
          }

          if (result.item != null && result.item.errorMessage?.(query) == null) {
            result.item.onSelect(query);
          }
          break;
        }
        case 'sectionToggle': {
          const { sectionId } = result;
          setSectionsState((s) => ({ ...s, [sectionId]: { isExpanded: true } }));
          break;
        }
        default: {
          break;
        }
      }
    },
    [onSelect, query, resultsWithCustom],
  );

  const hasFocusedItem =
    focusIdx != null && focusIdx >= 0 && focusIdx <= resultsWithCustom.length - 1;
  const focusedResult = hasFocusedItem ? resultsWithCustom[focusIdx] : null;
  const hasSubmenu =
    (focusedResult?.type === 'item' &&
      !focusedResult.item.isDisabled &&
      focusedResult.item.submenu != null) ||
    (focusedResult?.type === 'customOption' && focusedResult.item.submenu != null);
  const hasOpenSubmenu = hasSubmenu && showSubmenu;
  const ignoreKeyDown = (hasOpenSubmenu && isChildShowingSubmenu) || disableKeyDown;

  useEffect(() => {
    // every time we show a submenu, let a potential parent select menu know
    setParentHasChildShowingSubmenu(hasOpenSubmenu);
  }, [hasOpenSubmenu, setParentHasChildShowingSubmenu]);

  useEffect(() => {
    if (hasOpenSubmenu) {
      setSubmenuAnchor(submenuAnchorRef.current);
    } else {
      setSubmenuAnchor(null);
      setParentHasChildShowingSubmenu(false);
    }
  }, [hasOpenSubmenu, focusIdx, itemsFingerprint, setParentHasChildShowingSubmenu]);

  const submenuType =
    (focusedResult != null && 'item' in focusedResult
      ? focusedResult.item.submenuType
      : undefined) ?? 'submenu';

  // If the selected item changes, and the submenu is an options submenu, we want it to close
  const focusedItemKey =
    focusedResult == null
      ? null
      : 'item' in focusedResult
        ? focusedResult.item.id
        : focusedResult.idx;

  useEffect(() => {
    if (submenuType === 'options') {
      setShowSubmenu(false);
    }
  }, [submenuType, focusedItemKey]);

  const { setIsScrolling, getIsScrolling } = useIsScrolling();
  const isKeyboardEventsBlockedRef = useRef(false);
  const setIsKeyboardEventsBlocked = useCallback((isBlocked: boolean) => {
    isKeyboardEventsBlockedRef.current = isBlocked;
  }, []);

  const onKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      if (isKeyboardEventsBlockedRef.current || ignoreKeyDown || !isAttachedToDOM()) {
        return;
      }

      const target = ev.target as HTMLElement | null;
      const isInputEventOnChildOrIgnoreKeystrokeClass = () => {
        return (
          isInputKeyboardEvent(ev) &&
          (ref.current?.contains(target) ||
            target?.closest(`.${SELECT_MENU_IGNORE_KEYSTROKE_CLASS}`) != null)
        );
      };

      switch (ev.key) {
        case 'ArrowLeft': {
          if (isInputEventOnChildOrIgnoreKeystrokeClass()) {
            return;
          }
          if (hasOpenSubmenu) {
            preventEventDefault(ev);
            setShowSubmenu(false);
          }
          break;
        }
        case 'ArrowRight': {
          if (isInputEventOnChildOrIgnoreKeystrokeClass()) {
            return;
          }
          if (hasSubmenu && !hasOpenSubmenu) {
            preventEventDefault(ev);
            setShowSubmenu(true);
          }
          break;
        }
        case 'ArrowUp':
        case 'ArrowDown': {
          if (hasOpenSubmenu || hasPopoverOpen) {
            return;
          }

          preventEventDefault(ev);

          const resolvedFocusIdx = focusIdx == null ? (ev.key === 'ArrowUp' ? 0 : -1) : focusIdx;
          setFocusIdx(getNextValidIdx(resolvedFocusIdx, ev.key === 'ArrowUp' ? 'up' : 'down'));
          setShowSubmenu(false);
          setIsScrolling();
          break;
        }
        case 'Escape': {
          if (isInputEventOnChildOrIgnoreKeystrokeClass()) {
            return;
          }

          if (hasOpenSubmenu) {
            preventEventDefault(ev);
            setShowSubmenu(false);
            return;
          }
          if (onClose != null) {
            preventEventDefault(ev);
            onClose();
          }
          break;
        }

        case 'Tab': {
          if (
            !tabToSelect ||
            isInputEventOnChildOrIgnoreKeystrokeClass() ||
            ev.defaultPrevented ||
            ev.metaKey ||
            ev.ctrlKey ||
            ev.shiftKey ||
            focusIdx == null
          ) {
            return;
          }

          preventEventDefault(ev);
          if (focusIdx === -1) {
            setFocusIdx(0);
          } else {
            onSelectIdx(focusIdx, ev);
          }
          break;
        }

        case 'Enter': {
          if (hasPopoverOpen || focusIdx == null) {
            return;
          }
          if (
            isInputEventOnChildOrIgnoreKeystrokeClass() ||
            focusIdx < 0 ||
            ev.metaKey ||
            ev.ctrlKey ||
            ev.shiftKey
          ) {
            return;
          }

          if (hasSubmenu && !hasOpenSubmenu && submenuType === 'submenu') {
            setShowSubmenu(true);
          }

          preventEventDefault(ev);
          onSelectIdx(focusIdx, ev);
          break;
        }
        default: {
          if (numResults === 0 && onClose != null && dismissIfEmpty) {
            if (charsSinceNoResults >= 3) {
              onClose();
            }

            if (isPrintableChar(ev.key)) {
              setCharsSinceNoResults(charsSinceNoResults + 1);
            }
          } else if (numResults > 0 && charsSinceNoResults > 0) {
            setCharsSinceNoResults(0);
          }
          break;
        }
      }
    },
    [
      ignoreKeyDown,
      isAttachedToDOM,
      ref,
      hasOpenSubmenu,
      hasSubmenu,
      hasPopoverOpen,
      getNextValidIdx,
      focusIdx,
      setIsScrolling,
      onClose,
      tabToSelect,
      onSelectIdx,
      submenuType,
      numResults,
      dismissIfEmpty,
      charsSinceNoResults,
    ],
  );

  // N.B. this is a document-level event listener using capture because there may not
  // be an input in focus, or the input in focus is not one owned by this component.
  // since this component is typically rendered as a popover, it should generally
  // have precendence intercepting certain keys that control for navigation within the
  // menu
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, true);

    return () => {
      document.removeEventListener('keydown', onKeyDown, true);
    };
  }, [onKeyDown]);

  const previousFocusedItemKey = usePrevious(focusedItemKey);
  useEffect(() => {
    if (focusedItemKey !== previousFocusedItemKey) {
      onFocusChange?.(focusedResult, query);
    }
  }, [focusedResult, onFocusChange, previousFocusedItemKey, query, focusedItemKey]);

  const renderResult = (result: SelectResult<T>) => {
    switch (result.type) {
      case 'item': {
        const { idx, item } = result;
        const isFocused = focusIdx === idx;
        if (item.disabledTooltipLabel == null) {
          return (
            <Box
              key={item.key ?? item.id}
              ref={isFocused && item.submenu != null ? submenuAnchorRef : undefined}
            >
              {children({
                item,
                isFocused,
                idx,
              })}
            </Box>
          );
        }

        return (
          <Tooltip key={item.key ?? item.id} label={item.disabledTooltipLabel}>
            <Box ref={isFocused && item.submenu != null ? submenuAnchorRef : undefined}>
              {children({
                item,
                isFocused,
                idx,
              })}
            </Box>
          </Tooltip>
        );
      }
      case 'customOption': {
        if (!hasCustomOptions) {
          return null;
        }

        const { idx, item } = result;
        const { id, alwaysShowHint, shortcutHint, errorMessage, render, isDisabled } = item;
        const isFocused = focusIdx === idx;
        return (
          <Box key={id} ref={isFocused && item.submenu != null ? submenuAnchorRef : undefined}>
            <CustomSelectMenuResult
              idx={idx}
              isFocused={isFocused}
              errorMessage={errorMessage?.(query)}
              alwaysShowHint={alwaysShowHint}
              shortcutHint={shortcutHint}
              hasNextMenu={item.submenu != null}
              icon={item.icon}
              isDisabled={isDisabled}
            >
              {render(query)}
            </CustomSelectMenuResult>
          </Box>
        );
      }
      case 'sectionToggle': {
        const { sectionId, extraResults, idx } = result;
        return (
          <ShowMoreSelectMenuResult
            key={`show-more-${sectionId}`}
            idx={idx}
            isFocused={focusIdx === idx}
            extraResults={extraResults}
          />
        );
      }
      default: {
        break;
      }
    }

    return null;
  };

  const contextValue: SelectMenuContextState = useMemo(() => {
    return {
      setFocusIdx,
      setShowSubmenu,
      closeParentSelectMenu: onClose,
      setIsChildShowingSubmenu,
      showSubmenu,
      onSelectIdx,
      getIsScrolling,
      setIsKeyboardEventsBlocked,
    };
  }, [onClose, showSubmenu, onSelectIdx, getIsScrolling, setIsKeyboardEventsBlocked]);

  const onMouseLeave = useCallback(() => {
    if (clearFocusOnMouseLeave) {
      setFocusIdx(-1);
    }
  }, [clearFocusOnMouseLeave, setFocusIdx]);

  const submenuRender = useCallback(
    () =>
      hasOpenSubmenu &&
      submenuAnchor != null && (
        <Box className={SELECT_MENU_SUBMENU}>{focusedResult.item.submenu?.()}</Box>
      ),
    [focusedResult, hasOpenSubmenu, submenuAnchor],
  );

  const itemFooter =
    focusedResult?.type === 'item' || focusedResult?.type === 'customOption'
      ? focusedResult.item.footer
      : null;
  const showMoreFooter =
    focusedResult?.type === 'sectionToggle' && items.some((i) => i.footer != null) ? (
      <SelectMenuFooter
        icon={<MoreHorizontalIcon />}
        title="Show more results"
        subtitle="Expands results to display all matching items in a category."
      />
    ) : null;

  let footer = menuFooter ?? itemFooter ?? showMoreFooter;

  const noResults = numResults === 0 && items.length + (customOptions?.length ?? 0) !== 0;
  footer = typeof footer === 'function' ? footer(noResults) : footer;

  if (noResults && query.length === 0) {
    return null;
  }

  return (
    <SelectMenuContext.Provider value={contextValue}>
      <List
        data-testid="select-menu"
        className={className}
        bg="white"
        variant={variant}
        overflowX="hidden"
        overflowY="auto"
        width={width}
        minWidth={minWidth}
        maxHeight={maxHeight}
        padding={omitPadding ? 0 : undefined}
        ref={ref}
        onMouseLeave={onMouseLeave}
      >
        {numResults > 0 &&
          Object.entries(groupBy(resultsWithCustom, 'sectionId')).map(
            ([sectionId, sectionResults], idx, arr) => {
              const renderedResults = sectionResults.map(renderResult);
              return sectionId != null ? (
                <SelectMenuSection
                  key={sectionId}
                  name={sectionsById[sectionId]?.name}
                  isLast={idx === arr.length - 1}
                >
                  <div data-testid={`select-menu-section-${sectionId}`}>{renderedResults}</div>
                </SelectMenuSection>
              ) : (
                renderedResults
              );
            },
          )}
        {noResults && (
          <Text color="gray.400" fontWeight="medium" fontSize="xs" padding={1}>
            No results
          </Text>
        )}
      </List>
      <Fade in={footer != null} transition={FADE_TRANSITION}>
        {footer != null && (
          <Flex
            padding={3}
            alignItems="center"
            borderTopWidth="px"
            borderTopColor="gray.300"
            bgColor="gray.100"
            borderBottomRadius="md"
            overflow="hidden"
            width={width}
            fontSize="xxs"
          >
            {footer}
          </Flex>
        )}
      </Fade>
      {hasOpenSubmenu && submenuAnchor != null && (
        <Tippy
          interactive
          placement="right-start"
          offset={OFFSET}
          visible
          // Cypress is unable to find the submenu itesm unless appended to body.
          // Consideration for a potential rewrite of this component.
          appendTo={isTest ? document.body : submenuAnchor}
          reference={submenuAnchor}
          zIndex={theme.zIndices.popover + 1}
          render={submenuRender}
          popperOptions={SUBMENU_POPPER_OPTIONS}
        />
      )}
    </SelectMenuContext.Provider>
  );
};

export const useBlockParentSelectMenuKeyboardEvents = () => {
  const { setIsKeyboardEventsBlocked } = useContext(SelectMenuContext);

  useEffect(() => {
    setIsKeyboardEventsBlocked(true);
    return () => {
      return setIsKeyboardEventsBlocked(false);
    };
  }, [setIsKeyboardEventsBlocked]);
};

// see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087
export default React.memo(SelectMenu) as typeof SelectMenu;
